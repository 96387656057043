




































import Vue from "vue";
import {
  ApiConfiguration,
  LoadActionPermission,
  LoadLockDto,
  LoadsClient,
  LockStatus
} from "@/scripts/cld.api";
import { LoadAction } from "@/scripts/types";
import { pdfPrompt } from "@/scripts/misc/filePrompts";
import { actions, gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    loadId: Number,
    loadIds: Array as () => number[]
  },
  computed: {
    loading(): boolean {
      return this.actions.length === 0 || this.loadLocks.length === 0;
    },
    loadLocks(): LoadLockDto[] {
      return this.loadIds
        .map(id => this.$store.getters[gets.lock](id))
        .filter(l => l !== undefined);
    },
    anyLocked(): boolean {
      return this.loadLocks.some(
        l => l.lockStatus === LockStatus.LockedBySomeoneElse
      );
    },
    thisLocked(): boolean {
      const lock = this.$store.getters[gets.lock](this.loadId);
      return (
        lock !== undefined && lock.lockStatus === LockStatus.LockedBySomeoneElse
      );
    },
    menuIcon(): string {
      return !this.loading && this.thisLocked
        ? "mdi-lock"
        : "mdi-dots-horizontal";
    },
    actions(): LoadAction[] {
      return this.loadIds.flatMap(id =>
        this.$store.getters[gets.loadActions](id)
      );
    },
    disabled(): boolean {
      return (
        this.loadIds.length > 0 && !this.loadIds.some(id => id === this.loadId)
      );
    },
    visibleActions(): LoadAction[] {
      const allowedActions = this.actions.filter(a => a.allowed);
      if (this.loadIds.length === 1) {
        return allowedActions;
      }
      const multiActions = [
        LoadActionPermission.Book,
        LoadActionPermission.Load,
        LoadActionPermission.Unbook,
        LoadActionPermission.Annul,
        LoadActionPermission.Reorder,
        LoadActionPermission.Resign
      ];
      return multiActions
        .filter(
          a =>
            allowedActions.filter(aa => aa.id === a).length ===
            this.loadIds.length
        )
        .map(a => allowedActions.find(aa => aa.id === a)!);
    }
  },
  methods: {
    actionLocked(actionId: LoadActionPermission): boolean {
      return (
        this.$store.getters[gets.lockNeeded](actionId, this.loadIds) &&
        this.anyLocked
      );
    },
    actionIcon(action: LoadAction): string {
      return this.actionLocked(action.id) ? "mdi-lock" : action.icon!;
    },
    exportContainerSpec() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .exportContainerPdf(this.loadIds[0])
        .then(res => {
          pdfPrompt(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    open(actionId: LoadActionPermission) {
      if (actionId === LoadActionPermission.ContainerExport) {
        this.exportContainerSpec();
      } else {
        this.$router.push({
          path: this.$route.path,
          query: {
            action: actionId.toString(),
            ids: "[" + this.loadIds.toString() + "]"
          }
        });
      }
    }
  },
  data: (): {
    actionEnum: typeof LoadActionPermission;
    actionId?: LoadActionPermission;
  } => ({
    actionEnum: LoadActionPermission,
    actionId: undefined
  })
});
