var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"no-vertical-padding",attrs:{"cols":"auto"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.description))])]),_c('v-spacer'),_c('v-col',{staticClass:"no-vertical-padding",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","text":""},on:{"click":_vm.showHidePrevAgreementRow}},[_vm._v(" "+_vm._s(_vm.showHideText)+" ")])],1)],1),(_vm.showPrevAgreementRow)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{attrs:{"color":"background-pale"}},[(!_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.agreement.truckTypes || []),function(truckType){return _c('v-col',{key:truckType.id},[_c('v-list',{staticClass:"no-padding",attrs:{"color":"background-pale"}},_vm._l(((truckType.agreementRows || []).slice(
                  0,
                  3
                )),function(agreementRow){return _c('v-list-item',{key:agreementRow.supplierName,staticClass:"extra-dense-list-item"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-col',{staticClass:"caption no-padding d-inline-block text-truncate",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(agreementRow.ranking)+". "+_vm._s(agreementRow.supplierName)+" ")]),_c('v-col',{staticClass:"caption no-padding",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(_vm.money(agreementRow.freightPrice, 0))+" "+_vm._s(_vm.secondaryValue(agreementRow))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.prevAgreementRowTooltip(agreementRow)))])])],1)}),1)],1)}),1):_vm._e()],1),_c('v-expansion-panel-content',{attrs:{"color":"background-pale"}},[(!_vm.loading)?_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.agreement.truckTypes || []),function(truckType){return _c('v-col',{key:truckType.id},[_c('v-list',{staticClass:"no-padding",attrs:{"color":"background-pale"}},_vm._l(((truckType.agreementRows || []).slice(
                  3
                )),function(agreementRow){return _c('v-list-item',{key:agreementRow.supplierName,staticClass:"extra-dense-list-item"},[_c('v-tooltip',{attrs:{"bottom":"","open-delay":0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({},'v-row',attrs,false),on),[_c('v-col',{staticClass:"caption no-padding d-inline-block text-truncate",attrs:{"cols":"7"}},[_vm._v(" "+_vm._s(agreementRow.ranking)+". "+_vm._s(agreementRow.supplierName)+" ")]),_c('v-col',{staticClass:"caption no-padding",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(_vm.money(agreementRow.basePrice, 0))+" "+_vm._s(_vm.secondaryValue(agreementRow))+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.prevAgreementRowTooltip(agreementRow)))])])],1)}),1)],1)}),1):_vm._e()],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }