






















import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import ActorDialogTabs from "@/components/web/actor/ActorDialogTabs.vue";
import { ActorType, UserLevel } from "@/scripts/cld.api";
import { actorTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DialogCard,
    ActorDialogTabs
  },
  props: {
    actorIdToView: Number as () => number | undefined,
    actorTypeToView: Number as () => ActorType
  },
  computed: {
    userLevel(): UserLevel | undefined {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    atleastUser(): boolean {
      return (
        this.userLevel !== undefined &&
        [UserLevel.User, UserLevel.Admin, UserLevel.SuperAdmin].includes(
          this.userLevel
        )
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    documentsVisible(): boolean {
      return (
        this.actorTypeToView === ActorType.Client &&
        this.actorType === ActorType.Supplier &&
        this.atleastUser
      );
    }
  },
  methods: {
    actorTypeName: actorTypeName,
    openDocuments() {
      window.open("/documents?clientId=" + this.actorIdToView, "_blank");
    },
    close() {
      this.$emit("close");
    }
  }
});
