




















































import {
  ApiConfiguration,
  LoadContactDto,
  LoadsClient
} from "@/scripts/cld.api";
import { contactTypeName, cultureName } from "@/scripts/misc/enumNames";
import { actions } from "@/scripts/store/constants";
import Vue from "vue";
export default Vue.extend({
  props: {
    loadId: Number as () => number | undefined,
    editable: Boolean
  },
  watch: {
    loadId: {
      handler() {
        this.fetchContacts();
      },
      immediate: true
    }
  },
  methods: {
    cultureName: cultureName,
    contactTypeName: contactTypeName,
    fetchContacts() {
      if (!this.loadId) {
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .contacts(this.loadId)
        .then(res => {
          this.contacts = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    contacts: LoadContactDto[] | undefined;
  } => ({
    contacts: undefined
  })
});
