



























import Vue from "vue";
import { InfoMenuObject } from "@/scripts/types";
import SearchCard from "@/components/shared/ui/SearchCard.vue";

export default Vue.extend({
  components: {
    SearchCard
  },
  props: {
    title: String,
    infoMenu: Object as () => InfoMenuObject
  },
  watch: {
    infoMenu: {
      handler() {
        if (this.infoMenu.visible) {
          this.closable = false;
          this.getDimensions();
          setTimeout(() => {
            this.closable = true;
          }, 100);
        }
      },
      deep: true
    }
  },
  computed: {
    x(): number {
      return this.infoMenu.x - this.width / 2;
    },
    y(): number {
      return this.infoMenu.y - this.height - 5;
    }
  },
  methods: {
    clickOutside(event: any) {
      if (!event.target.className.includes("v-list")) {
        if (this.closable) {
          this.close();
        }
      }
    },
    close() {
      this.$emit("close");
    },
    getDimensions() {
      setTimeout(() => {
        const elem = this.$refs.wrapper as any;
        if (elem && elem.clientHeight) {
          this.width = elem.clientWidth;
          this.height = elem.clientHeight;
        }
      }, 50);
    }
  },
  data: (): { closable: boolean; width: number; height: number } => ({
    closable: true,
    width: -5000,
    height: -5000
  })
});
