












import Vue from "vue";
import { CargoType, RepositoryDto } from "@/scripts/cld.api";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";

export default Vue.extend({
  props: {
    cargoType: Number as () => CargoType
  },
  computed: {
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.cargoType);
    }
  }
});
