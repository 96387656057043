



































import Vue from "vue";
import {
  ApiConfiguration,
  DocType,
  DocumentGroupDto,
  DocumentsClient,
  UserLevel
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DocumentDialog from "@/components/web/documents/DocumentDialog.vue";
import DocumentTable from "@/components/web/documents/DocumentTable.vue";

export default Vue.extend({
  components: {
    SearchCard,
    DocumentDialog,
    DocumentTable
  },
  computed: {
    userLevel(): UserLevel {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    allowedToEditDocumentGroups(): boolean {
      return (
        this.userLevel === UserLevel.Admin ||
        this.userLevel === UserLevel.SuperAdmin
      );
    }
  },
  methods: {
    fetchDocuments() {
      this.documentGroups = [];
      const docTypes = [DocType.InvoicingInformation, DocType.TermsOfAgreement];
      for (const docType of docTypes) {
        this.loadingSemaphore++;
        new DocumentsClient(new ApiConfiguration(this.$store))
          .list(docType, true)
          .then(res => {
            this.documentGroups = [...this.documentGroups, ...res];
            this.loadingSemaphore--;
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    },
    closeDocumentDialog() {
      this.documentGroupId = undefined;
      this.fetchDocuments();
    }
  },
  mounted() {
    this.fetchDocuments();
  },
  data: (): {
    loadingSemaphore: number;
    documentGroups: DocumentGroupDto[];
    documentGroupId: number | undefined;
  } => ({
    loadingSemaphore: 0,
    documentGroups: [],
    documentGroupId: undefined
  })
});
