

















































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import ActorEditForm from "@/components/support-admin/actor/ActorEditForm.vue";
import {
  ActorDto,
  SupportAdminActorListDto,
  ActorsClient,
  ActorType,
  ApiConfiguration
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import { pushNotification } from "@/scripts/misc/notifications";

export default Vue.extend({
  components: {
    DialogCard,
    ActorEditForm
  },
  props: {
    actorFromList: SupportAdminActorListDto,
    dialog: Boolean
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      return (
        this.$t("AdminActor").toString() +
        " - " +
        this.actorFromList.actorName +
        " (" +
        this.actorFromList.actorId +
        ")"
      );
    }
  },
  methods: {
    init() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .actorForSupportAdmin(this.actorFromList.actorId)
        .then(res => {
          this.actor = res;
          this.actorOriginal = this.copy(this.actor);
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    editActor() {
      this.saveInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .editForSupportAdmin(this.actor!)
        .then(() => {
          pushNotification(
            this.actorFromList.actorName + " har sparats.",
            "mdi-check-circle"
          );
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.saveInProgress = false;
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    toggleActorEnabled(enabled: boolean) {
      this.saveInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .enable(this.actorFromList.actorId, enabled)
        .then(() => {
          pushNotification(
            this.actorFromList.actorName +
              " har " +
              (enabled ? "aktiverats." : "inaktiverats."),
            "mdi-check-circle"
          );
          if (enabled && this.actor!.actorType === ActorType.Supplier) {
            pushNotification(
              'Klicka här för att komma till "Lägg till i pågående upphandling"',
              "mdi-check-circle",
              "/support-admin/add-to-procurements",
              true
            );
          }
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.saveInProgress = false;
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    lockUsers() {
      this.saveInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .lockUsers(
          this.actorFromList.actorId,
          !this.actorFromList.allUsersDisabled
        )
        .then(() => {
          pushNotification(
            this.actorFromList.actorName + "s användare har låsts.",
            "mdi-check-circle"
          );
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.saveInProgress = false;
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    close() {
      this.$emit("close");
    },
    copy(a: ActorDto): ActorDto {
      return new ActorDto({
        id: a.id,
        name: a.name,
        orgNo: a.orgNo,
        regDate: a.regDate,
        activatedDate: a.activatedDate,
        inactivatedDate: a.inactivatedDate,
        lastActive: a.lastActive,
        mainContact: a.mainContact,
        mainContactEmail: a.mainContactEmail,
        mainContactPhone: a.mainContactPhone,
        contractManager: a.contractManager,
        contractManagerMail: a.contractManagerMail,
        contractManagerPhone: a.contractManagerPhone,
        contractAddress: a.contractAddress,
        country: a.country,
        cargoTypes: [...a.cargoTypes!],
        active: a.active,
        internalNote: a.internalNote,
        actorType: a.actorType,
        connectedClients: [...a.connectedClients!]
      });
    }
  },
  data: (): {
    actor: ActorDto | undefined;
    actorOriginal: ActorDto | undefined;
    saveInProgress: boolean;
    validChanges: boolean;
  } => ({
    actor: undefined,
    actorOriginal: undefined,
    saveInProgress: false,
    validChanges: false
  })
});
