

































































































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  QualityPointsSearchCriteria,
  QualityPointsContainerDto,
  QualityPointsOrderBy,
  ActorType,
  QualityPointsRowDto,
  QualityPointsReportSplit
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";
import { isoName, truckTypeName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: QualityPointsContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 248);
    },
    searchCriteria(): QualityPointsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.QualityPoints,
        1
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: QualityPointsOrderBy.PickupLocation,
        width: 180
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: QualityPointsOrderBy.DeliveryLocation,
        width: 230
      });
      if (this.actorType === ActorType.Client) {
        if (
          this.searchCriteria.split === QualityPointsReportSplit.ActorAndUnit
        ) {
          headers.push({
            value: "unit",
            text: "Unit",
            width: 90
          });
        }
        headers.push({
          value: "supplierName",
          text: "Supplier",
          sortValue: QualityPointsOrderBy.Supplier,
          width: 120
        });
      } else {
        headers.push({
          value: "clientName",
          text: "Client",
          sortValue: QualityPointsOrderBy.Client,
          width: 200
        });
      }
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: QualityPointsOrderBy.TruckType
      });
      headers.push({
        value: "pointsTotal",
        text: t("TotalPoints"),
        tooltip: t("TotalPointsDesc"),
        untranslated: true
      });
      headers.push({
        value: "numLoadsTotal",
        text: "NumberOfLoads",
        multiLine: true
      });
      headers.push({
        value: "numLoadsOffered",
        text: "LoadStatisticsOfferedLong",
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsRejected",
        text: t("LoadStatisticsDeclinedShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsDeclinedLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsLoaded",
        text: t("LoadStatisticsBookedShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsBookedLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsLoadedWithinRequestedTime",
        text: t("LoadStatisticsLoadedWithinReqShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsLoadedWithinReqLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsLoadedWithStatedTime",
        text: t("LoadStatisticsLoadedWithStatedShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsLoadedWithStatedLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsLoadedWithinStatedTime",
        text: t("LoadStatisticsLoadedWithinStatedShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsLoadedWithinStatedLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsDelivered",
        text: t("LoadStatisticsDeliveredShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsDeliveredLong"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      headers.push({
        value: "pointsLoadsDeliveredUsingApp",
        text: t("LoadStatisticsDeliveredUsingAppShort"),
        tooltip: `${t("PointsFor")} ${t(
          "LoadStatisticsDeliveredUsingAppShort"
        ).toLowerCase()}`,
        untranslated: true,
        multiLine: true
      });
      return headers;
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName,
    search() {
      this.$emit("search");
    },
    pickupLocation(item: QualityPointsRowDto): string {
      if (!item.pickupCountry) {
        return "---";
      }
      return (
        item.pickupCounty +
        ", " +
        item.pickupZipCode +
        ", " +
        isoName(item.pickupCountry)
      );
    },
    deliveryLocation(item: QualityPointsRowDto): string {
      if (!item.deliveryCountry) {
        return "---";
      }
      return (
        item.deliveryCounty +
        ", " +
        item.deliveryZipCode +
        ", " +
        isoName(item.deliveryCountry)
      );
    },
    pointsTotalTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("PerformancePoints")}: ${item.pointsPerformance}\n` +
        `${t("BusinessQualitypoints")}: ${item.pointsSubjective}\n`
      );
    },
    rejectedTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsOfferedLong")}: ${item.numLoadsOffered}\n` +
        `${t("LoadStatisticsBookedLong")}: ${item.numLoadsLoaded}\n` +
        `${t("LoadStatisticsDeclinedLong")}: ${item.numLoadsRejected}\n` +
        `= ${percentage(item.partLoadsRejected, 0)} (${
          item.pointsLoadsRejected
        } ${t("Points")})`
      );
    },
    loadedTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("NumberOfLoads")}: ${item.numLoadsTotal}\n` +
        `${t("LoadStatisticsBookedLong")}: ${item.numLoadsLoaded}\n` +
        `= ${percentage(item.partLoadsLoaded, 0)} (${
          item.pointsLoadsLoaded
        } ${t("Points")})`
      );
    },
    loadedWithinRequestedTime(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsLoadedLong")}: ${item.numLoadsLoaded}\n` +
        `${t("LoadStatisticsLoadedWithinReqLong")}: ${
          item.numLoadsLoadedWithinRequestedTime
        }\n` +
        `= ${percentage(item.partLoadsLoadedWithinRequestedTime, 0)} (${
          item.pointsLoadsLoadedWithinRequestedTime
        } ${t("Points")})`
      );
    },
    loadedWithStatedTimeTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsLoadedLong")}: ${item.numLoadsLoaded}\n` +
        `${t("LoadStatisticsLoadedWithStatedLong")}: ${
          item.numLoadsLoadedWithStatedTime
        }\n` +
        `= ${percentage(item.partLoadsLoadedWithStatedTime, 0)} (${
          item.pointsLoadsLoadedWithStatedTime
        } ${t("Points")})`
      );
    },
    loadedWithinStatedTimeTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsLoadedWithStatedLong")}: ${
          item.numLoadsLoadedWithStatedTime
        }\n` +
        `${t("LoadStatisticsLoadedWithinStatedLong")}: ${
          item.numLoadsLoadedWithinStatedTime
        }\n` +
        `= ${percentage(item.partLoadsLoadedWithinStatedTime, 0)} (${
          item.pointsLoadsLoadedWithinStatedTime
        } ${t("Points")})`
      );
    },
    deliveredTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsLoadedLong")}: ${item.numLoadsLoaded}\n` +
        `${t("LoadStatisticsDeliveredLong")}: ${item.numLoadsDelivered}\n` +
        `= ${percentage(item.partLoadsDelivered, 0)} (${
          item.pointsLoadsDelivered
        } ${t("Points")})`
      );
    },
    deliveredUsingAppTooltip(item: QualityPointsRowDto): string {
      return (
        `${t("LoadStatisticsDeliveredLong")}: ${
          item.numLoadsLoadedWithStatedTime
        }\n` +
        `${t("LoadStatisticsDeliveredUsingAppLong")}: ${
          item.numLoadsDeliveredUsingApp
        }\n` +
        `= ${percentage(item.partLoadsDeliveredUsingApp, 0)} (${
          item.pointsLoadsDeliveredUsingApp
        } ${t("Points")})`
      );
    }
  }
});
