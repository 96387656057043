

















































































import Vue from "vue";
import {
  ApiConfiguration,
  InvoiceClient,
  InvoiceSearchDto,
  InvoiceRowSupportAdminDto,
  UserConfigurationType,
  ActorType,
  Currency,
  FeeType,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import InvoiceGeneratorTable from "@/components/support-admin/invoice/InvoiceGeneratorTable.vue";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import { textPrompt } from "@/scripts/misc/filePrompts";
import money from "@/scripts/misc/money";
import { currencyName } from "@/scripts/misc/enumNames";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import MomentX from "@/scripts/misc/momentX";
import neverHappens from "@/scripts/misc/neverHappens";

export type InvoiceGeneratorFilter = {
  actorType: ActorType | undefined;
  actorId: string;
  actorName: string;
  invoiceRecipient: string;
  feeType: FeeType | undefined;
  currency: Currency | undefined;
};

const emptyFilter = (): InvoiceGeneratorFilter => ({
  actorType: undefined,
  actorId: "",
  actorName: "",
  invoiceRecipient: "",
  feeType: undefined,
  currency: undefined
});

const bookingFees = [
  FeeType.RoadShort,
  FeeType.RoadLong,
  FeeType.Railroad,
  FeeType.Container,
  FeeType.MachineTransport
];

const monthlyFees = [
  FeeType.ClientLight,
  FeeType.ClientSmall,
  FeeType.ClientMedium,
  FeeType.ClientLarge,
  FeeType.ClientPremium
];

const annualFees = [
  FeeType.SupplierEntranceFee,
  FeeType.SupplierFirstUser,
  FeeType.SupplierAdditionalUser
];

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    ReadOnlyText,
    InvoiceGeneratorTable
  },
  computed: {
    searchCriteria(): InvoiceSearchDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoiceGenerator,
        1
      );
    },
    totals(): { amount: string; currency: string }[] {
      const currencies = [...new Set(this.rows.map(r => r.currency))];
      const res = [];
      for (let c of currencies) {
        res.push({
          currency: currencyName(c),
          amount: money(
            this.rows
              .filter(r => r.currency === c)
              .map(r => r.priceWithDiscount * r.numberOfItems)
              .reduce((t, c) => t + c),
            2
          )
        });
      }
      return res;
    },
    feeTypeGroups(): RepositoryDto[] {
      return [
        new RepositoryDto({ id: 1, text: "Alla" }),
        new RepositoryDto({ id: 2, text: "Bokningsavgifter" }),
        new RepositoryDto({ id: 3, text: "Månadsavgifter" }),
        new RepositoryDto({ id: 4, text: "Årsavgifter" })
      ];
    }
  },
  methods: {
    scrollToTop() {
      scrollTableToTop(this);
    },
    getFeeTypes(): FeeType[] {
      switch (this.feeTypeGroup) {
        case 1:
          return [];
        case 2:
          return bookingFees;
        case 3:
          return monthlyFees;
        case 4:
          return annualFees;
        default:
          return neverHappens(this.feeTypeGroup);
      }
    },
    feeTypeGroupChanged() {
      this.searchCriteria.feeTypes = this.getFeeTypes();
      this.search();
    },
    search() {
      this.loading = true;
      new InvoiceClient(new ApiConfiguration(this.$store))
        .generate(this.searchCriteria)
        .then(res => {
          this.loading = false;
          this.rows = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    markAsInvoiced() {
      popupDialog({
        title: "Fakturera",
        body:
          "Är du säker på att du vill markera alla fakturarader som fakturerade?",
        btnText1: "Ja",
        btnColor1: "success",
        btnCallback1: () => {
          this.markInProgress = true;
          new InvoiceClient(new ApiConfiguration(this.$store))
            .markAsInvoiced(this.searchCriteria)
            .then(() => {
              this.markInProgress = false;
              this.search();
            })
            .catch(error => {
              this.$store.dispatch(actions.handleApiError, error);
            });
        },
        btnText2: "Nej",
        btnColor2: "error",
        btnCallback2: () => {}
      });
    },
    exportToFile() {
      this.exportInProgress = true;
      new InvoiceClient(new ApiConfiguration(this.$store))
        .exportInvoice(this.searchCriteria)
        .then(res => {
          this.exportInProgress = false;
          textPrompt(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.InvoiceGenerator,
          subTypeId: 1,
          actorType: 0
        })
        .then(() => this.search());
      this.filter = emptyFilter();
    },
    dateRule(date: MomentX | undefined): string | boolean {
      if (!date) {
        return true;
      }
      if (date.startOfDay().isSame(date.startOfMonth())) {
        return true;
      }
      return "Man måste välja den första i månaden.";
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: boolean;
    markInProgress: boolean;
    exportInProgress: boolean;
    rows: InvoiceRowSupportAdminDto[];
    filter: InvoiceGeneratorFilter;
    feeTypeGroup: 1 | 2 | 3 | 4;
  } => ({
    loading: false,
    markInProgress: false,
    exportInProgress: false,
    rows: [],
    filter: emptyFilter(),
    feeTypeGroup: 1
  })
});
