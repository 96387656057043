




















































import Vue from "vue";
import ProcurementDocumentDialog from "@/components/web/agreements/supplier/ProcurementDocumentDialog.vue";
import {
  ApiConfiguration,
  DocType,
  DocumentGroupDto,
  DocumentsClient,
  SupplierProcurementRowDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import { ReadDocuments } from "@/scripts/types";
import { actions, gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    ProcurementDocumentDialog
  },
  props: {
    loading: Boolean,
    unsavedChanges: Boolean,
    saveInProgress: Boolean,
    interactable: Boolean,
    changedRows: Array as () => SupplierProcurementRowDto[],
    validPrices: Boolean
  },
  computed: {
    readAgreementDocuments(): ReadDocuments {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ReadDocuments,
        1
      );
    },
    agreementDocuments(): DocumentGroupDto[] {
      return this.documentGroups.filter(g =>
        this.changedRows.map(r => r.documentGroupId).includes(g.id)
      );
    },
    unviewedDocuments(): DocumentGroupDto[] {
      return this.agreementDocuments.filter(
        g => !this.readAgreementDocuments.agreements.includes(g.id)
      );
    }
  },
  methods: {
    fetchDocuments() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .clients(DocType.TermsOfAgreement)
        .then(res => {
          this.documentGroups = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchDocuments();
  },
  data: (): {
    dialog: boolean;
    documentGroups: DocumentGroupDto[];
  } => ({
    dialog: false,
    documentGroups: []
  })
});
