


































































import Vue from "vue";
import { LoadDetailsDeviationDto } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import {
  deviationCauseName,
  otherPriceCauseName
} from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    deviationValues: Object as () => LoadDetailsDeviationDto | undefined
  },
  components: {
    ReadOnlyText
  },
  computed: {
    otherPriceCauseName(): string {
      if (!this.deviationValues || !this.deviationValues.otherPriceCause) {
        return "";
      }
      return otherPriceCauseName(this.deviationValues.otherPriceCause);
    },
    deviationCauseName(): string {
      if (!this.deviationValues || !this.deviationValues.deviationCause) {
        return "";
      }
      return deviationCauseName(this.deviationValues.deviationCause);
    },
    disabled(): boolean {
      if (!this.deviationValues) {
        return true;
      }
      return (
        !this.deviationValues.otherPriceCause &&
        !this.deviationValues.deviationCause &&
        !this.deviationValues.otherPrice &&
        !this.deviationValues.otherPriceDesc &&
        !this.deviationValues.deviationDesc
      );
    }
  }
});
