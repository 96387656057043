


































































import Vue from "vue";
import { LoadStatus, LoadDashboardDto } from "@/scripts/cld.api";
import { loadStatusIcon, loadStatusName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    item: LoadDashboardDto,
    clickable: Boolean
  },
  computed: {
    statusOrdered(): boolean {
      return this.item.loadStatus === LoadStatus.Ordered;
    }
  },
  methods: {
    loadStatusName: loadStatusName,
    loadStatusIcon: loadStatusIcon,
    routeTo(loadStatus: LoadStatus) {
      this.$router.push("/loads?tab=" + loadStatus);
    }
  }
});
