












































































































import Vue from "vue";
import {
  UserConfigurationType,
  ClientMonitoringSearchCriteria,
  ClientMonitoringContainerDto,
  ClientMonitoringOrderBy,
  CargoType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: ClientMonitoringContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 300);
    },
    searchCriteria(): ClientMonitoringSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientMonitoring,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: ClientMonitoringOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: ClientMonitoringOrderBy.DeliveryLocation
      });
      headers.push({
        value: "unit",
        text: "Unit",
        sortValue: ClientMonitoringOrderBy.Unit
      });
      headers.push({
        value: "loadedCount",
        text: "LoadedLoads",
        sortValue: ClientMonitoringOrderBy.Loaded
      });
      headers.push({
        value: "daysRegToPickupEarliest",
        text: "TimeFromPublishedToPickupEarliest",
        multiLine: true,
        sortValue: ClientMonitoringOrderBy.DaysRegToPickup
      });
      headers.push({
        value: "daysRegToDelivLatest",
        text: "TimeFromPublishedToDeliveryLatest",
        multiLine: true,
        sortValue: ClientMonitoringOrderBy.DaysRegToDeliv
      });
      headers.push({
        value: "canceledCount",
        text: "CanceledLoads",
        sortValue: ClientMonitoringOrderBy.Cancelled
      });
      headers.push({
        value: "directBookedCount",
        text: "BookedByClient",
        sortValue: ClientMonitoringOrderBy.DirectBooked
      });
      if (this.searchCriteria.cargoType === CargoType.Road) {
        headers.push({
          value: "spotTenderCount",
          text: "SpotTender",
          sortValue: ClientMonitoringOrderBy.SpotTender
        });
      }
      headers.push({
        value: "deviatedCount",
        text: "NumberOfDeviatedLoads",
        sortValue: ClientMonitoringOrderBy.Deviated
      });
      headers.push({
        value: "broadcastCount",
        text: "NumberOfBroadcastLoads",
        sortValue: ClientMonitoringOrderBy.Broadcast
      });
      headers.push({
        value: "averageRanking",
        text: "AverageRank",
        sortValue: ClientMonitoringOrderBy.Rank
      });
      return headers;
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName,
    search() {
      this.$emit("search");
    }
  }
});
