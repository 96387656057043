





















































































import Vue from "vue";

export default Vue.extend({
  props: {
    value: Array as () => number[] | string[] | boolean[],
    label: String,
    placeholder: String,
    items: Array as () => { id: any; text: string }[],
    tabindex: String,
    innerClass: String,
    disabled: Boolean,
    loading: Boolean,
    clearable: Boolean,
    rules: Array,
    asterisk: Boolean,
    noCheckAll: Boolean,
    emptyMeansAll: Boolean
  },
  computed: {
    allChecked(): boolean | undefined {
      if (this.value.length === 0) {
        return this.emptyMeansAll;
      }
      if (this.value.length !== this.items.length) {
        return undefined;
      }
      return !this.emptyMeansAll;
    }
  },
  methods: {
    input(val: any[]) {
      this.$emit("input", val);
    },
    checkAll() {
      if (this.allChecked === true) {
        this.input([]);
      } else {
        this.input(
          this.emptyMeansAll
            ? []
            : this.items.map(i => (typeof i === "object" ? i.id : i))
        );
      }
    }
  }
});
