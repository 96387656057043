
































































































































import Vue from "vue";
import {
  ActorType,
  DeviationCause,
  LoadActionPermission,
  LoadClientEditAssortedValuesDto,
  LoadClientEditContainersDto,
  LoadClientEditInfoTextsDto,
  LoadClientEditSubLocations,
  LoadClientEditSupplierBookingInfoDto,
  LoadClientPublishValues,
  LoadDetailsContentDto,
  LoadDetailsDeviationDto,
  LoadDocumentDto,
  LoadStatus,
  CargoType,
  PublishSpeed,
  RelatedLoadsDto
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import AccordionPrices from "@/components/web/loads/client/accordion/AccordionPrices.vue";
import AccordionRankings from "@/components/web/loads/client/accordion/AccordionRankings.vue";
import AccordionContent from "@/components/web/loads/client/accordion/AccordionContent.vue";
import AccordionSupplierBookingInfo from "@/components/web/loads/client/accordion/AccordionSupplierBookingInfo.vue";
import AccordionSubLocations from "@/components/web/loads/client/accordion/AccordionSubLocations.vue";
import AccordionSubLocationReadOnly from "@/components/web/loads/client/accordion/AccordionSubLocationReadOnly.vue";
import AccordionRelatedLoads from "@/components/web/loads/client/accordion/AccordionRelatedLoads.vue";
import AccordionContainers from "@/components/web/loads/client/accordion/AccordionContainers.vue";
import AccordionAppendix from "@/components/web/loads/client/accordion/AccordionAppendix.vue";
import AccordionAdditionalDeliveryInfo from "@/components/web/loads/client/accordion/AccordionAdditionalDeliveryInfo.vue";
import AccordionSpecialRequirements from "@/components/web/loads/client/accordion/AccordionSpecialRequirements.vue";
import AccordionLoadHistory from "@/components/web/loads/client/accordion/AccordionLoadHistory.vue";
import AccordionSpotTender from "@/components/web/loads/client/accordion/AccordionSpotTender.vue";
import AccordionDeviation from "@/components/web/loads/client/accordion/AccordionDeviation.vue";
import AccordionDuplicateLoad from "@/components/web/loads/client/accordion/AccordionDuplicateLoad.vue";
import AccordionContacts from "@/components/web/loads/client/accordion/AccordionContacts.vue";
import AccordionDocuments from "@/components/web/loads/client/accordion/AccordionDocuments.vue";
import AccordionCustomsInfo from "@/components/web/loads/client/accordion/AccordionCustomsInfo.vue";
import { LoadAction } from "@/scripts/types";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    AccordionPrices,
    AccordionRankings,
    AccordionContent,
    AccordionSupplierBookingInfo,
    AccordionSubLocations,
    AccordionSubLocationReadOnly,
    AccordionRelatedLoads,
    AccordionContainers,
    AccordionAppendix,
    AccordionAdditionalDeliveryInfo,
    AccordionSpecialRequirements,
    AccordionLoadHistory,
    AccordionDeviation,
    AccordionSpotTender,
    AccordionDuplicateLoad,
    AccordionContacts,
    AccordionDocuments,
    AccordionCustomsInfo
  },
  props: {
    publishValues: LoadClientPublishValues,
    infoTexts: Object as () =>
      | LoadClientEditInfoTextsDto
      | LoadDetailsContentDto,
    assortedValues: Object as () =>
      | LoadClientEditAssortedValuesDto
      | LoadDetailsContentDto,
    supplierBookingInfo: LoadClientEditSupplierBookingInfoDto,
    subLocations: Object as () => LoadClientEditSubLocations | undefined,
    relatedLoads: Object as () => RelatedLoadsDto | undefined,
    containerValues: Object as () => LoadClientEditContainersDto | undefined,
    cargoType: Number as () => CargoType,
    agreementRouteId: Number,
    truckType: Number,
    agreementDate: MomentX,
    publishSpeed: Number as () => PublishSpeed,
    loadStatus: Number as () => LoadStatus | undefined,
    loadId: Number as () => number | undefined,
    deviations: Object as () => LoadDetailsDeviationDto | undefined,
    duplicateTransportNos: Array as () => string[],
    documents: Array as () => LoadDocumentDto[] | undefined,
    customsInfoRequired: Boolean,
    editable: Boolean
  },
  watch: {
    loadStatus: {
      handler() {
        if (
          this.actorType === ActorType.Client &&
          (this.loadStatus === undefined ||
            this.loadStatus === LoadStatus.Ordered ||
            this.loadStatus === LoadStatus.MissingAgreement)
        ) {
          this.expandedPanel = 1; //Rankingflustret
        } else if (
          this.loadStatus === LoadStatus.Ordered &&
          this.deviations &&
          this.deviations.deviationCause === DeviationCause.SpotTender
        ) {
          this.expandedPanel = -1; //Ingen panel
        } else {
          this.expandedPanel = 0; //Prisflustret
        }
      },
      immediate: true
    }
  },
  computed: {
    agreementDetermined(): boolean {
      return !!this.agreementRouteId && !!this.truckType;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    priceVisible(): boolean {
      return (
        this.actorType === ActorType.Client ||
        this.actorType === ActorType.Supplier
      );
    },
    rankingVisible(): boolean {
      return this.actorType === ActorType.Client;
    },
    historyVisible(): boolean {
      return !this.editable;
    },
    supplierBookingInfoVisible(): boolean {
      return (
        this.actorType === ActorType.Client &&
        this.editable &&
        this.loadStatus !== undefined &&
        this.loadStatus !== LoadStatus.Ordered &&
        this.loadStatus !== LoadStatus.MissingAgreement
      );
    },
    subLocationsVisible(): boolean {
      return this.editable;
    },
    subLocationsReadOnlyVisible(): boolean {
      return !this.editable;
    },
    containerVisible(): boolean {
      return this.cargoType === CargoType.Container;
    },
    additionalDeliveryInfoVisible(): boolean {
      return (
        this.loadStatus === LoadStatus.Delivered &&
        (this.actorType === ActorType.Client ||
          this.actorType === ActorType.Supplier)
      );
    },
    appendixVisible(): boolean {
      return this.loadStatus !== undefined;
    },
    clientSpotTenderVisible(): boolean {
      return (
        this.cargoType === CargoType.Road &&
        !!this.deviations &&
        this.deviations.deviationCause === DeviationCause.SpotTender
      );
    },
    deviationsVisible(): boolean {
      return this.loadStatus !== undefined;
    },
    duplicateVisible(): boolean {
      return this.loadStatus === undefined;
    },
    contactsVisible(): boolean {
      const actions: LoadAction[] = this.$store.getters[gets.loadActions](
        this.loadId
      );
      return actions.some(
        a => a.id === LoadActionPermission.LoadingSlotEdit && a.allowed
      );
    },
    customsInfoVisible(): boolean {
      return this.customsInfoRequired;
    }
  },
  methods: {
    isExpanded(panel: number) {
      if (panel > 17 && !this.customsInfoVisible) {
        panel--;
      }
      if (panel > 15 && !this.contactsVisible) {
        panel--;
      }
      if (panel > 14 && !this.duplicateVisible) {
        panel--;
      }
      if (panel > 13 && !this.clientSpotTenderVisible) {
        panel--;
      }
      if (panel > 12 && !this.deviationsVisible) {
        panel--;
      }
      if (panel > 11 && !this.appendixVisible) {
        panel--;
      }
      if (panel > 10 && !this.additionalDeliveryInfoVisible) {
        panel--;
      }
      if (panel > 9 && !this.containerVisible) {
        panel--;
      }
      if (panel > 7 && !this.subLocationsReadOnlyVisible) {
        panel--;
      }
      if (panel > 6 && !this.subLocationsVisible) {
        panel--;
      }
      if (panel > 5 && !this.supplierBookingInfoVisible) {
        panel--;
      }
      if (panel > 2 && !this.historyVisible) {
        panel--;
      }
      if (panel > 1 && !this.rankingVisible) {
        panel--;
      }
      if (panel > 0 && !this.priceVisible) {
        panel--;
      }
      return this.expandedPanel === panel;
    }
  },
  data: (): {
    expandedPanel: number;
  } => ({
    expandedPanel: -1
  })
});
