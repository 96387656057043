





















































































































import Vue from "vue";
import {
  SupportAdminDashboardLoadDto,
  LoadStatus,
  TruckType,
  ExplorerNodeDto
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import ExplorerDialogLoadDetails from "@/components/support-admin/explorer/ExplorerDialogLoadDetails.vue";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import { gets } from "@/scripts/store/constants";
import { TableOptions, TableHeader } from "@/scripts/types";
import { loadStatusName, truckTypeName } from "@/scripts/misc/enumNames";

const emptyFilter = () => ({
  loadId: "",
  transportNo: "",
  clientName: "",
  loadStatus: undefined,
  truckType: undefined
});

export default Vue.extend({
  components: {
    DialogCard,
    CustomTableHeader,
    CustomFilter,
    ExplorerDialogLoadDetails
  },
  props: {
    loads: Array as () => SupportAdminDashboardLoadDto[]
  },
  watch: {
    dialog() {
      this.filters = emptyFilter();
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 310);
    },
    dialog(): boolean {
      return this.loads.length > 0;
    },
    selectedLoadAsExplorerNodeDto(): ExplorerNodeDto | undefined {
      if (!this.selectedLoad) {
        return undefined;
      }
      const res = new ExplorerNodeDto();
      res.guid = this.selectedLoad.loadId.toString();
      res.id = this.selectedLoad.loadId;
      return res;
    },
    sortedRows(): SupportAdminDashboardLoadDto[] {
      let res = [...this.filteredRows];
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      let c = (
        a: SupportAdminDashboardLoadDto,
        b: SupportAdminDashboardLoadDto
      ) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      res = res.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) || stringCompare(a.loadId, b.loadId)
      );
      return res;
    },
    filteredRows(): SupportAdminDashboardLoadDto[] {
      return this.loads
        .filter(
          l =>
            !this.filters.loadId ||
            stringSearchHit(this.filters.loadId, l.loadId.toString(), true)
        )
        .filter(
          l =>
            !this.filters.transportNo ||
            stringSearchHit(this.filters.transportNo, l.transportNo, true)
        )
        .filter(
          l =>
            !this.filters.clientName ||
            stringSearchHit(this.filters.clientName, l.clientName, true)
        )
        .filter(
          l =>
            !this.filters.loadStatus || l.loadStatus === this.filters.loadStatus
        )
        .filter(
          l => !this.filters.truckType || l.truckType === this.filters.truckType
        );
    }
  },
  methods: {
    loadStatusName: loadStatusName,
    truckTypeName: truckTypeName,
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    selectedLoad: SupportAdminDashboardLoadDto | undefined;
    filters: {
      loadId: string;
      transportNo: string;
      clientName: string;
      loadStatus: LoadStatus | undefined;
      truckType: TruckType | undefined;
    };
    tableOptions: TableOptions;
    headers: TableHeader[];
  } => ({
    selectedLoad: undefined,
    filters: emptyFilter(),
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: ["loadStatus"],
      sortDesc: [false]
    }),
    headers: [
      { value: "viewDetails", width: 1 },
      {
        value: "loadStatus",
        text: "Status",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "loadId",
        text: "LassId",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "transportNo",
        text: "TransportNo",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "clientName",
        text: "Beställare",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "truckType",
        text: "TruckType",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "pickupLocation",
        text: "Hämtort",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "deliveryLocation",
        text: "Leveransort",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "regDate",
        text: "Skapad",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "pickupEarliest",
        text: "Hämtas tidigast",
        sortValue: true,
        untranslated: true,
        width: 1
      },
      {
        value: "deliverLatest",
        text: "Lämnas senast",
        sortValue: true,
        untranslated: true,
        width: 1
      }
    ]
  })
});
