var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"no-padding",attrs:{"cols":"12"}},[_c('SearchCard',{attrs:{"title":_vm.title,"no-padding-sides":""},scopedSlots:_vm._u([(_vm.agreement)?{key:"head-right",fn:function(){return [_c('v-btn',{on:{"click":_vm.gotoQPWeights}},[_c('v-icon',[_vm._v("mdi-weight")])],1)]},proxy:true}:null,{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredVisRows,"height":_vm.tableHeight,"items-per-page":-1,"loading":_vm.loading,"sort-by":"truckTypeId","sort-desc":"","must-sort":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.supplierName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"Leverantör"},model:{value:(_vm.supplierFilter),callback:function ($$v) {_vm.supplierFilter=$$v},expression:"supplierFilter"}})]},proxy:true}],null,true)})]}},{key:"item.truckType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.truckTypeName(item.truckType)))])]}},{key:"item.supplierName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.supplierName))])]}},{key:"item.rankingType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.rankingTypeName(item.rankingType))+" ")]}},{key:"item.factoredParameterPoints",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(((item.factoredParameterPoints) + " (" + (item.factor * 100) + "% av " + (item.baseParameterPoints) + ")")))])]}},{key:"item.priceForRanking",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(((item.priceForRanking) + " (baspris: " + (item.basePrice) + ")")))])]}},{key:"item.rankingTypeNames",fn:function(){return [_vm._v(" "+_vm._s(_vm.rankingTypeName(1))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.rankingTypeName(2))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.rankingTypeName(3))+" ")]},proxy:true},{key:"item.points",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "baseParameterPoints")))])]}},{key:"item.numLoads",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsTotal")))])]}},{key:"item.offered",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsOffered")))])]}},{key:"item.rejected",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsRejected")))])]}},{key:"item.loaded",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsLoaded")))])]}},{key:"item.loadedWithinRequestedTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsLoadedWithinRequestedTime")))])]}},{key:"item.loadedWithStatedTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsLoadedWithStatedTime")))])]}},{key:"item.loadedWithinStatedTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsLoadedWithinStatedTime")))])]}},{key:"item.delivered",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsDelivered")))])]}},{key:"item.deliveredUsingApp",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"render-newlines"},[_vm._v(_vm._s(_vm.triforce(item, "numLoadsDeliveredUsingApp")))])]}}],null,true)})]},proxy:true}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }