




























































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DocumentButton from "@/components/shared/ui/DocumentButton.vue";
import {
  DocumentGroupDto,
  SupplierProcurementRowDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import { ReadDocuments } from "@/scripts/types";
import { actions, gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DialogCard,
    DocumentButton
  },
  watch: {
    dialog() {
      this.panel = 0;
    }
  },
  props: {
    dialog: Boolean,
    agreementDocuments: Array as () => DocumentGroupDto[],
    unviewedDocuments: Array as () => DocumentGroupDto[],
    changedRows: Array as () => SupplierProcurementRowDto[]
  },
  computed: {
    readAgreementDocuments(): ReadDocuments {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ReadDocuments,
        1
      );
    }
  },
  methods: {
    markDocumentAsRead(id: number) {
      if (this.readAgreementDocuments.agreements.includes(id)) {
        return;
      }
      this.readAgreementDocuments.agreements.push(id);
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.ReadDocuments,
        subTypeId: 1
      });
    },
    routeNames(id: number) {
      return this.changedRows
        .filter(r => r.documentGroupId === id)
        .map(r => r.pickupLocation + " " + r.deliveryLocation);
    },
    unread(id: number): boolean {
      return this.unviewedDocuments.some(d => d.id === id);
    },
    close(accept: boolean) {
      if (accept) {
        this.$emit("accept");
      }
      this.$emit("close");
    }
  },
  data: (): { panel: number } => ({
    panel: 0
  })
});
