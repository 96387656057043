































































































































































































import Vue from "vue";
import {
  SupportAdminActorListDto,
  ActorType,
  CargoType,
  Country
} from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import ActorEditDialog from "@/components/support-admin/actor/ActorEditDialog.vue";
import InvoiceDialog from "@/components/support-admin/actor/InvoiceDialog.vue";
import UsersDialog from "@/components/support-admin/actor/user/UsersDialog.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import {
  actorTypeName,
  cargoTypeName,
  isoName
} from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export enum ActorListDialogType {
  ActorDialog = 1,
  UsersDialog = 2,
  InvoiceDialog = 3
}

export default Vue.extend({
  props: {
    actors: Array as () => SupportAdminActorListDto[],
    loading: Boolean
  },
  components: {
    EllipsisTableItem,
    CustomTableHeader,
    CustomFilter,
    ActorEditDialog,
    UsersDialog,
    InvoiceDialog
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 190);
    },
    filteredActors(): SupportAdminActorListDto[] {
      const filteredActorsList = this.actors
        .filter(a => stringSearchHit(this.actorNameFilter, a.actorName, false))
        .filter(
          a => !this.actorTypeFilter || a.actorType! === this.actorTypeFilter
        )
        .filter(
          a =>
            !this.cargoTypeFilter ||
            a.cargoTypes!.includes(this.cargoTypeFilter)
        )
        .filter(a => !this.countryFilter || a.country! === this.countryFilter)
        .filter(
          a =>
            this.disabledFilter === undefined ||
            this.disabledFilter === null ||
            a.allUsersDisabled! === this.disabledFilter
        )
        .filter(
          a =>
            this.activeFilter === undefined ||
            this.activeFilter === null ||
            a.active === this.activeFilter
        )
        .filter(a => this.filteredUserDescriptions(a).length)
        .filter(a =>
          stringSearchHit(this.mainContactName, a.mainContactName, false)
        )
        .filter(a =>
          stringSearchHit(this.mainContactEmail, a.mainContactEmail, false)
        )
        .filter(a =>
          stringSearchHit(this.mainContactPhone, a.mainContactPhone, false)
        );
      this.$emit("filteredActorsList", filteredActorsList);
      return filteredActorsList;
    },
    actor(): SupportAdminActorListDto | undefined {
      return this.actors.find(a => a.actorId === this.actorId);
    },
    actorId: {
      get(): number | undefined {
        const id = Number(this.$route.query.id);
        if (!id || isNaN(id)) {
          return undefined;
        }
        return id;
      },
      set(id: number | undefined) {
        this.$router.push({
          query: { ...this.$route.query, id: id ? id.toString() : undefined },
          path: this.$route.path
        });
      }
    },
    dialog: {
      get(): ActorListDialogType | undefined {
        const dialog = Number(this.$route.query.dialog);
        if (!dialog || isNaN(dialog)) {
          return undefined;
        }
        return dialog;
      },
      set(dialog: ActorListDialogType | undefined) {
        this.$router.push({
          query: {
            ...this.$route.query,
            dialog: dialog ? dialog.toString() : undefined
          },
          path: this.$route.path
        });
      }
    }
  },
  methods: {
    actorTypeName: actorTypeName,
    isoName: isoName,
    numUsers(item: SupportAdminActorListDto): string {
      let text = this.filteredUserDescriptions(item).length.toString();
      if (this.userFilter) {
        text += ` (${item.users!.length})`;
      }
      return text;
    },
    filteredUserDescriptions(item: SupportAdminActorListDto): string[] {
      return item
        .users!.map(u => u.userName + ", " + u.name + ", " + u.email)
        .filter(r => stringSearchHit(this.userFilter, r, false))
        .sort(stringCompare);
    },
    openEditDialog(actorId: number) {
      this.actorId = actorId;
      this.dialog = 1;
    },
    openEditUsersDialog(actorId: number) {
      this.actorId = actorId;
      this.dialog = 2;
    },
    openInvoiceDialog(actorId: number) {
      this.actorId = actorId;
      this.dialog = 3;
    },
    close() {
      this.actorId = undefined;
      this.dialog = undefined;
      this.$emit("search");
    },
    cargoTypesText(cargoTypes: CargoType[]): string {
      return cargoTypes.map(ct => cargoTypeName(ct).slice(0, 3)).join(", ");
    }
  },
  data: (): {
    actorNameFilter: string;
    actorTypeFilter: ActorType | undefined;
    cargoTypeFilter: CargoType | undefined;
    countryFilter: Country | undefined;
    disabledFilter: boolean | undefined;
    activeFilter: boolean | undefined;
    userFilter: string;
    mainContactName: string;
    mainContactEmail: string;
    mainContactPhone: string;
    ActorListDialogType: typeof ActorListDialogType;
    headers: TableHeader[];
  } => ({
    actorNameFilter: "",
    actorTypeFilter: undefined,
    cargoTypeFilter: undefined,
    countryFilter: undefined,
    disabledFilter: undefined,
    activeFilter: true,
    userFilter: "",
    mainContactName: "",
    mainContactEmail: "",
    mainContactPhone: "",
    ActorListDialogType: ActorListDialogType,
    headers: [
      {
        value: "edit",
        width: 1
      },
      {
        value: "actorId",
        text: "ID",
        sortValue: true
      },
      {
        value: "actorName",
        text: "Actor",
        sortValue: true
      },
      {
        value: "active",
        text: "Status",
        sortValue: true,
        width: 1
      },
      {
        value: "allUsersDisabled",
        text: "Anv. Inakt.",
        sortValue: true,
        untranslated: true,
        tooltip:
          "Samtliga användare inaktiverade. Görs typiskt om leverantören inte betalar sina avgifter.",
        width: 1
      },
      {
        value: "regDate",
        text: "Registrerad",
        untranslated: true,
        sortValue: true,
        width: 1
      },
      {
        value: "cargoTypes",
        text: "CargoType"
      },
      {
        value: "actorType",
        text: "ActorType",
        sortValue: true,
        width: 1
      },
      {
        value: "country",
        text: "Country",
        sortValue: true,
        width: 1
      },
      {
        value: "users",
        text: "Användare",
        sortValue: true,
        untranslated: true,
        width: 130
      },
      {
        value: "mainContactName",
        text: "Huvudkontakt",
        untranslated: true,
        sortValue: true
      },
      {
        value: "mainContactEmail",
        text: "Huvudkontakt email",
        untranslated: true,
        sortValue: true,
        multiLine: true
      },
      {
        value: "mainContactPhone",
        text: "Huvudkontakt telefon",
        untranslated: true,
        sortValue: true,
        multiLine: true
      }
    ]
  })
});
