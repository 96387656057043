



























































import Vue from "vue";
import { TableHeader, TableOptions } from "@/scripts/types";

interface TableHeaderProps {
  headers: TableHeader[];
  options: TableOptions;
}

export default Vue.extend({
  props: {
    props: Object as () => TableHeaderProps,
    on: Object
  },
  computed: {
    estimatedNoOfLoadsTextShort(): string {
      let text: string = this.$t("NumberOfEstimatedLoads").toString();
      if (text.length > 7) {
        text = `${text.substring(0, 5)}...`;
      }
      return text;
    },
    sortedDesc(): boolean {
      return this.props.options.sortDesc[0];
    }
  },
  methods: {
    sortable(h: TableHeader): boolean {
      return h.sortValue !== undefined;
    },
    sortedBy(h: TableHeader): boolean {
      if (!this.sortable(h)) {
        return false;
      }
      if (h.sortValue === true) {
        return h.value === this.props.options.sortBy[0];
      } else {
        return h.sortValue === this.props.options.sortBy[0];
      }
    },
    sort(h: TableHeader) {
      if (!this.sortable(h)) {
        return;
      }
      if (h.sortValue === true) {
        this.on.sort(h.value);
      } else {
        this.on.sort(h.sortValue);
      }
    },
    hovered(h: TableHeader): boolean {
      return this.hoveredHeader === h.value;
    }
  },
  data: (): { hoveredHeader?: string } => ({
    hoveredHeader: undefined
  })
});
