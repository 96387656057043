










































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import TimePickerDialog from "@/components/shared/input/TimePickerDialog.vue";

export default Vue.extend({
  components: {
    TimePickerDialog
  },
  props: {
    value: MomentX,
    timeOk: (Function as any) as () => { (date: MomentX): boolean },
    disabled: Boolean
  },
  methods: {},
  data: (): {
    menu: boolean;
  } => ({
    menu: false
  })
});
