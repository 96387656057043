































import Vue from "vue";
import { ApiConfiguration, Culture, UsersClient } from "@/scripts/cld.api";
import { i18n, langs } from "@/scripts/language/i18n";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    unauthenticatedPage: Boolean
  },
  computed: {
    languages() {
      return langs;
    },
    langImg(): any {
      return this.languages.find(l => l.locale === Number(i18n.locale))!.image;
    }
  },
  methods: {
    changeLang(lang: Culture) {
      if (this.unauthenticatedPage) {
        i18n.locale = lang.toString();
        window.localStorage.setItem("lang", lang.toString());
      } else {
        new UsersClient(new ApiConfiguration(this.$store))
          .saveLanguage(lang)
          .then(() => {
            location.reload();
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    }
  },
  mounted() {
    if (this.unauthenticatedPage) {
      const lang = window.localStorage.getItem("lang");
      if (!!lang) {
        i18n.locale = lang;
      }
    }
  }
});
