var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"small-padding-top no-padding-bottom",attrs:{"cols":"12"}},[_c('GreyTitle',{staticClass:"small-margin-bottom",attrs:{"text":_vm.$t('Dates')}}),_c('v-data-table',{attrs:{"headers":[
        {
          value: 'pickupEarliest',
          text: 'PickupEarliest',
          width: 1
        },
        {
          value: 'pickupLatest',
          text: 'PickupLatest',
          width: 1
        },
        {
          value: 'deliverEarliest',
          text: 'DeliveryEarliest',
          width: 1
        },
        {
          value: 'deliverLatest',
          text: 'DeliveryLatest',
          width: 1
        }
      ],"items":[_vm.saveDto.dates],"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}}])})],1),_c('v-col',{staticClass:"small-vertical-padding",attrs:{"cols":"6"}},[_c('GreyTitle',{staticClass:"tiny-margin-bottom",attrs:{"text":_vm.$t('AdditionalInformation')}}),_c('v-row',[_c('v-col',{staticClass:"small-padding-right small-vertical-padding",attrs:{"cols":"6"}},[_c('div',{staticClass:"tiniest-margin-left"},[_c('ReadOnlyText',{attrs:{"label":_vm.$t('RequiredPickupDate'),"value":_vm.saveDto.mandatoryBookedPickupDate}})],1)]),_c('v-col',{staticClass:"small-padding-left small-vertical-padding",attrs:{"cols":"6"}},[_c('ReadOnlyText',{attrs:{"label":_vm.$t('RequiredDeliveryDate'),"value":_vm.saveDto.mandatoryBookedDeliveryDate}})],1)],1),_c('div',{staticClass:"tiniest-margin-left small-margin-bottom"},[_c('ReadOnlyText',{attrs:{"label":_vm.$t('SpotTenderEnds'),"value":_vm.endDateInfo}})],1),_c('div',{staticClass:"tiniest-margin-left small-margin-bottom"},[_c('ReadOnlyText',{attrs:{"label":_vm.$t('TruckType'),"value":_vm.truckTypeNames}})],1),_c('div',{staticClass:"tiniest-margin-left medium-margin-bottom"},[_c('DocumentDropdown',{attrs:{"value":_vm.saveDto.documentGroupId,"label":_vm.$t('TermsOfAgreement'),"document-groups":_vm.documentGroups,"readonly":""}})],1),_c('TextMulti',{attrs:{"rows":3,"label":_vm.$t('MessageToSuppliers'),"only-on-blur":""},model:{value:(_vm.saveDto.note),callback:function ($$v) {_vm.$set(_vm.saveDto, "note", $$v)},expression:"saveDto.note"}})],1),_c('v-col',{staticClass:"small-vertical-padding no-padding-left",attrs:{"cols":"6"}},[_c('GreyTitle',{staticClass:"tiniest-margin-bottom",attrs:{"text":_vm.$t('Suppliers')}}),_c('v-list',{staticClass:"scrollable no-vertical-padding",attrs:{"height":300}},_vm._l((_vm.suppliers.filter(function (s) { return (_vm.saveDto.supplierIds || []).includes(s.id); }
        )),function(s){return _c('div',{key:s.id},[_c('v-list-item',[_c('v-list-item-content',[_c('span',{staticClass:"body-2"},[_vm._v(_vm._s(s.text))])])],1),_c('v-divider')],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }