var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"elevation":"1","rounded":""}},[_c('v-data-table',{attrs:{"items":_vm.users,"headers":_vm.headers,"fixed-header":"","disable-pagination":"","hide-default-footer":"","height":"700px","item-class":function (item) { return (item.loadUserId === _vm.selectedLoadUserId ? 'light-grey-row' : ''); }},on:{"click:row":function (item) { return _vm.$emit('selectUser', item.loadUserId); }},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable"},[_vm._v(_vm._s(item.username))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"clickable"},[_vm._v(_vm._s(item.name))])]}},{key:"item.userLevel",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userLevelName(item.userLevel))+" ")]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [(!item.isEnabled)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"small-padding-right",attrs:{"size":"20"}},on),[_vm._v(" mdi-account-cancel-outline ")])]}}],null,true)},[_c('span',[_vm._v("Inaktiv")])]):_vm._e(),(item.lockoutTimeLeft !== undefined)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20"}},on),[_vm._v(" mdi-lock-outline ")])]}}],null,true)},[_c('span',[_vm._v("Låst")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }