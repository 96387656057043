



























































































































































































































import { RepositoryDto } from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import Vue from "vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import TimePicker from "@/components/shared/input/TimePicker.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import {
  CargoLoadingMobileEventsContainer,
  LoadWithCargoLoadingContainer
} from "@/scripts/types";

export default Vue.extend({
  components: {
    Dropdown,
    TimePicker,
    ReadOnlyText,
    TextSingle
  },
  props: {
    bookedSiloOne: MomentX,
    extraMinutesSiloOne: Number,
    eventsToBook: Array as () => CargoLoadingMobileEventsContainer[],
    events: Array as () => CargoLoadingMobileEventsContainer[],
    timeIsFree: Function,
    currentDate: MomentX,
    pickupEarliest: MomentX,
    pickupLatest: MomentX,
    selectedCargoLoadingContainerId: Number,
    load: Object as () => LoadWithCargoLoadingContainer
  },
  computed: {
    extraSecondsDropdownItems(): RepositoryDto[] {
      return [...Array(10).keys()].map(
        x =>
          new RepositoryDto({
            id: x * 300,
            text: `${x * 5} ${this.$t("LoweredMinutes")}`
          })
      );
    },
    defaultTime() {
      return this.currentDate;
    }
  },
  methods: {
    chooseTime(date: MomentX, event: CargoLoadingMobileEventsContainer) {
      event.start = date.shortDateTimePrintSeparated();
      event.end = date
        .addSeconds(event.cargoLoading!.cargoLoading.seconds)
        .addSeconds(event.cargoLoading!.extraSeconds)
        .shortDateTimePrintSeparated();
      this.addTempSlot(event);
    },
    addSeconds(seconds: number, event: CargoLoadingMobileEventsContainer) {
      if (seconds !== undefined) {
        event.cargoLoading!.extraSeconds = seconds;
        this.$emit("addSeconds", seconds);
      }
    },
    toMoment(dstring: string): MomentX {
      return new MomentX(dstring, "YYYY-MM-DD HH:mm");
    },
    addTempSlot(event: CargoLoadingMobileEventsContainer) {
      this.$emit("addTempSlot", event);
    },
    eventClicked(cc: CargoLoadingMobileEventsContainer) {
      this.$emit("selectedCargoLoadingContainerIdChanged", cc.cargoLoading!.id);
      if (cc.cargoLoading!.booking) {
        this.$emit("removeBooking", cc.cargoLoading!.id);
      }
    },
    doBooking(event: CargoLoadingMobileEventsContainer) {
      this.$emit("doBooking", event);
      this.panel = undefined;
    },
    timeOkGenerator(
      event: CargoLoadingMobileEventsContainer
    ): { (time: MomentX): boolean } {
      return (time: MomentX) => {
        return this.timeIsFree(
          time,
          time.addSeconds(
            event.cargoLoading!.cargoLoading.seconds +
              event.cargoLoading!.extraSeconds
          ),
          this.events.filter(e => e.category === event.category && e !== event)
        );
      };
    },
    cancel() {
      this.$emit("selectedCargoLoadingContainerIdChanged", 0);
      this.$emit("fetchSchedule");
    }
  },
  data: (): {
    panel: any;
  } => ({
    panel: undefined
  })
});
