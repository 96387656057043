export const ble = (error: any): boolean => {
  return error && error.status && error.status === 418;
};

export const clientOutdated = (error: any): boolean => {
  return error && error.status && error.status === 406;
};

export const unauthorized = (error: any): boolean => {
  return error && error.status && error.status === 401;
};

export const endpointNotFound = (error: any): boolean => {
  return error && error.status && error.status === 404;
};

export const internalServerError = (error: any): boolean => {
  return error && error.status && error.status === 500;
};

export const frameWindowTimedOutError = (error: any): boolean => {
  return error && error.message && error.message === "Frame window timed out";
};

export const networkError = (error: any): boolean => {
  return error && error.message && error.message === "Network Error";
};
