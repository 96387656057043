


























import Vue from "vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import {
  ExplorerClient,
  ExplorerNodeDto,
  LoadCompleteDto,
  ApiConfiguration,
  LoadStatus
} from "@/scripts/cld.api";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto
  },
  components: {
    Spinner
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        new ExplorerClient(new ApiConfiguration(this.$store))
          .load(this.item.id)
          .then(res => {
            this.load = res;
            this.loading = false;
          });
      }
    }
  },
  computed: {
    hasStatusLoaded(): boolean {
      return this.load.statusId === LoadStatus.Loaded;
    }
  },
  methods: {
    undoLoading() {
      this.undoLoadingInProgress = true;
      new ExplorerClient(new ApiConfiguration(this.$store))
        .undoloadingload(this.load.id)
        .then(() => {
          popupDialog({
            noCloseButton: true,
            title: "Ångra lastning",
            body: `Ångra lastningen gick bra!`,
            btnColor1: "success",
            btnText1: "OK",
            btnCallback1: () => {
              this.$emit("search");
              this.undoLoadingInProgress = false;
            }
          });
        });
    }
  },
  data: (): {
    loading: boolean;
    undoLoadingInProgress: boolean;
    load: LoadCompleteDto;
  } => ({
    loading: true,
    undoLoadingInProgress: false,
    load: new LoadCompleteDto()
  })
});
