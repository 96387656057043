































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import { RepositoryDto } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    DialogCard,
    DropdownMulti
  },
  props: {
    dialog: Boolean,
    filter: Array as () => number[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchClients() {
      cached("clients", [true, true, false])
        .then(res => {
          this.clients = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.draft = [...this.filter];
      this.fetchClients();
    },
    keepChangesAndClose() {
      this.filter.length = 0;
      this.filter.push(...this.draft);
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    draft: number[];
    clients: RepositoryDto[];
  } => ({
    draft: [],
    clients: []
  })
});
