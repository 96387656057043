
































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";

interface TextPart {
  img: boolean;
  link: boolean;
  text: string;
  bold: boolean;
  italic: boolean;
  size: number;
}

export default Vue.extend({
  props: {
    body: String,
    displayDate: MomentX
  },
  watch: {
    body: {
      handler() {
        this.generateParts();
      },
      immediate: true
    }
  },
  methods: {
    generateParts(): void {
      if (!this.body) {
        this.parts = [];
      }
      const parts: TextPart[] = this.body
        .replaceAll("\\\\", "¤")
        .replaceAll("\n", "¤\n¤")
        .replaceAll("\\p", "¤\\p")
        .replaceAll("\\", "¤\\")
        .replaceAll("\\0¤\\", "\\0\\")
        .replaceAll("\\1¤\\", "\\1\\")
        .replaceAll("\\2¤\\", "\\2\\")
        .replaceAll("\\b¤\\", "\\b\\")
        .replaceAll("\\i¤\\", "\\i\\")
        .replaceAll("\\l¤\\", "\\l\\")
        .split("¤")
        .map(t => ({
          img: false,
          link: false,
          text: t,
          bold: false,
          italic: false,
          size: 0
        }));

      parts.map(r => {
        if (r.text.includes("\\b")) {
          r.bold = true;
          r.text = r.text.replace("\\b", "");
        }
        if (r.text.includes("\\i")) {
          r.italic = true;
          r.text = r.text.replace("\\i", "");
        }
        if (r.text.includes("\\l")) {
          r.link = true;
          r.text = r.text.replace("\\l", "");
        }
        if (r.text.includes("\\0")) {
          r.size = 0;
          r.text = r.text.replace("\\0", "");
        }
        if (r.text.includes("\\1")) {
          r.size = 1;
          r.text = r.text.replace("\\1", "");
        }
        if (r.text.includes("\\2")) {
          r.size = 2;
          r.text = r.text.replace("\\2", "");
        }
        if (r.text.includes("\\p")) {
          r.img = true;
          r.text = r.text.replace("\\p", "");
          this.fetchImage(r.text);
        }
      });
      this.parts = parts;
    },
    imageData(name: string) {
      const img = this.images.find(i => i.name === name);
      return img === undefined ? undefined : img.data;
    },
    fetchImage(name: string) {
      if (this.images.some(i => i.name === name)) {
        return;
      }
      const headers = new Headers();
      headers.set("Authorization", `Bearer ${this.$store.getters.token}`);
      headers.set(
        "CurrentClientVersion",
        this.$store.getters.currentClientVersion
      );
      fetch(`/api/files/${name}`, { headers })
        .then(res => {
          return res.arrayBuffer();
        })
        .then(res => {
          let binary = "";
          const bytes = new Uint8Array(res);
          const len = bytes.byteLength;
          for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          const base64 = window.btoa(binary);
          this.images.push({
            name: name,
            data: `data:image/png;base64,${base64}`
          });
        });
    },
    openImage(fileName: string) {
      viewDocument(fileName);
    }
  },
  data: (): {
    parts: TextPart[];
    images: { name: string; data: string }[];
  } => ({
    parts: [],
    images: []
  })
});
