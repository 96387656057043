var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SearchCard',{attrs:{"title":_vm.title,"no-padding-sides":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.filteredActors,"height":600,"mobile-breakpoint":"0","disable-pagination":"","fixed-header":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.text",fn:function(){return [_c('CustomFilter',{attrs:{"value":_vm.filter,"config":{ subtype: 'text' },"placeholder":_vm.$t('Supplier')},on:{"input":function (f) { return _vm.$emit('filter', f); }}})]},proxy:true}],null,true)})]}},{key:"item.include",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clickable medium-padding-right",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v(" mdi-chevron-double-left ")])],1)]}},{key:"item.text",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"clickable small-vertical-padding",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item.exclude",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"clickable medium-padding-sides",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v(" mdi-chevron-double-right ")])],1)]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }