var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reportContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.reportContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationMulti'
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryIds: _vm.searchCriteria.deliveryCountries
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountries = val.countryIds;
              },"search":_vm.search}})]},proxy:true},{key:"filter.truckType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'truckTypeMulti',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('TruckType')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.truckTypes),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "truckTypes", $$v)},expression:"searchCriteria.truckTypes"}})]},proxy:true},{key:"filter.supplierName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'supplier',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Supplier')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.actorId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "actorId", $$v)},expression:"searchCriteria.actorId"}})]},proxy:true},{key:"filter.clientName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'client',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Client')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.actorId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "actorId", $$v)},expression:"searchCriteria.actorId"}})]},proxy:true}],null,true)})]}},{key:"group.header",fn:function(){return [_c('td',{staticClass:"font-weight-bold",attrs:{"colspan":"5"}},[_vm._v(" "+_vm._s(_vm.$t("Total"))+" ")]),_vm._l((_vm.valueHeaders),function(h){return _c('td',{key:h,staticClass:"font-weight-bold",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.totalValue(h))+" ")])})]},proxy:true},{key:"item.agreementPeriod",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.fromDate.datePrint())+" - "+_vm._s(item.toDate.datePrint())+" ")])]}},{key:"item.pickupLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.pickupLocation,"width":"165"}})]}},{key:"item.deliveryLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.deliveryLocation,"width":"180"}})]}},{key:"item.truckType",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.truckTypeName(item.truckType),"width":"140"}})]}},{key:"item.supplierName",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.supplierName,"width":"150"}})]}},{key:"item.clientName",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.clientName,"width":"150"}})]}},{key:"item.loadsTotal",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.loadsTotal, 0))+" ")])]}},{key:"item.offered",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.offered, 0))+" ")])]}},{key:"item.booked",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.booked, 0))+" ")])]}},{key:"item.shareOffered",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.percentage(item.shareOffered, 0))+" ")])]}},{key:"item.shareBooked",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.percentage(item.shareBooked, 0))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }