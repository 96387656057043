var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reportContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.reportContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationMulti'
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryIds: _vm.searchCriteria.deliveryCountries
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountries = val.countryIds;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deviationCause",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'deviationCauseMulti'
            },"placeholder":_vm.$t('DeviationCause')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.deviationCauses),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "deviationCauses", $$v)},expression:"searchCriteria.deviationCauses"}})]},proxy:true},{key:"filter.otherPriceCause",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'otherPriceCauseMulti'
            },"placeholder":_vm.$t('OtherPriceCause')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.otherPriceCauses),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "otherPriceCauses", $$v)},expression:"searchCriteria.otherPriceCauses"}})]},proxy:true},{key:"filter.supplier",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'supplier',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Supplier')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.supplierId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "supplierId", $$v)},expression:"searchCriteria.supplierId"}})]},proxy:true}],null,true)})]}},{key:"item.loadInfo",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.loadClick(item.loadId)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-truck ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.loadInfo(item))+" ")])]}},{key:"item.pickupLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.pickupLocation,"width":"150"}})]}},{key:"item.deliveryLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.deliveryLocation,"width":"150"}})]}},{key:"item.truckType",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.truckTypeName(item.truckType)))])]}},{key:"item.deviationCause",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":!item.deviationDesc,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines"},on),[_vm._v(_vm._s(item.deviationCause && _vm.deviationCauseName(item.deviationCause)))])]}}],null,true)},[_vm._v(" "+_vm._s(item.deviationDesc)+" ")])]}},{key:"item.otherPriceCause",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"disabled":!item.otherPriceDesc,"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines"},on),[_vm._v(_vm._s(item.otherPriceCause && _vm.otherPriceCauseName(item.otherPriceCause)))])]}}],null,true)},[_vm._v(" "+_vm._s(item.otherPriceDesc)+" ")])]}},{key:"item.agreementSupplier",fn:function(ref){
            var item = ref.item;
return [(item.agreementFreightPrice)?_c('EllipsisTableItem',{attrs:{"text":item.supplierName,"width":"95"}}):_vm._e(),_c('span',{staticClass:"dont-render-newlines font-weight-medium"},[_vm._v(_vm._s(_vm.money(item.agreementFreightPrice, 0)))])]}},{key:"item.supplier",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.supplierName,"width":"95"}}),_c('span',{staticClass:"dont-render-newlines font-weight-medium"},[_vm._v(_vm._s(_vm.money(item.freightPrice, 0)))])]}},{key:"item.topRankedSupplier",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.topRankedSupplierName,"width":"100"}}),_c('span',{staticClass:"dont-render-newlines font-weight-medium"},[_vm._v(_vm._s(_vm.money(item.topRankedFreightPrice, 0)))])]}},{key:"item.priceDifference",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines font-weight-medium",class:_vm.priceDifferenceColor(item.freightPriceDifference)},[_vm._v(_vm._s(_vm.money(item.freightPriceDifference, 0)))])]}},{key:"item.currency",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}},{key:"item.bookingDate",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.bookingDate.datePrint()))])]}},{key:"item.bookedBy",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.loadUserName,"width":"100"}}),_c('span',{class:{ transparent: !item.loadUserPhone }},[_c('EllipsisTableItem',{attrs:{"text":item.loadUserPhone
              ? item.loadUserPhone
              : '<' + _vm.$t('EmptyValue') + '>',"width":"100"}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }