

































































































import Vue from "vue";
import { TableHeader } from "@/scripts/types";
import {
  ApiConfiguration,
  SurveyClientClient,
  SurveySupplierDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import SurveyDialog from "@/components/web/survey/client/SurveyDialog.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    CustomTableHeader,
    CustomFilter,
    SurveyDialog
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 140);
    },
    title(): string {
      return (
        t("Survey_SustainabilitySurvey") +
        " / " +
        t("FairTransportCertification")
      );
    },
    suppliers(): SurveySupplierDto[] {
      return this.unfilteredSuppliers.filter(
        s =>
          this.supplierFilter === null ||
          s
            .supplierName!.toLowerCase()
            .includes(this.supplierFilter.toLowerCase())
      );
    }
  },
  mounted() {
    new SurveyClientClient(new ApiConfiguration(this.$store))
      .suppliers()
      .then(res => {
        this.unfilteredSuppliers = res;
        this.loading = false;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    loading: boolean;
    selectedSupplierId?: number;
    supplierFilter: string | null;
    unfilteredSuppliers: SurveySupplierDto[];
    headers: TableHeader[];
    ftLogo: any;
  } => ({
    loading: true,
    selectedSupplierId: undefined,
    supplierFilter: null,
    unfilteredSuppliers: [],
    headers: [
      { value: "leaf", width: 57 },
      {
        value: "supplierName",
        text: "Survey_Supplier",
        sortValue: true,
        width: 310
      },
      {
        value: "percentage",
        text: "ResultPercentage",
        sortValue: true,
        width: 105
      },
      {
        value: "answerDate",
        text: "ResponseDate",
        sortValue: true,
        width: 135
      },
      { value: "respondentName", text: "AuthorizedSignatory", sortValue: true },
      { value: "respondentRole", text: "SignatoryRole", sortValue: true },
      { value: "iteration", text: "SurveyVersion", sortValue: true, width: 135 }
    ],
    ftLogo: require("@/assets/img/fair-transport-logo.png")
  })
});
