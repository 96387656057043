










































































import Vue from "vue";
import {
  UserConfigurationType,
  QualityPointsSearchCriteria,
  QualityPointsContainerDto,
  RepositoryDto,
  QualityPointsReportSplit,
  ActorType
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: QualityPointsContainerDto
  },
  computed: {
    cargoTypes: actorCargoTypes,
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    searchCriteria(): QualityPointsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.QualityPoints,
        1
      );
    },
    splits(): RepositoryDto[] {
      const list = [
        new RepositoryDto({
          id: QualityPointsReportSplit.Route,
          text: t("AgreementRoute")
        })
      ];
      if (this.actorType === ActorType.Client) {
        list.push(
          ...[
            new RepositoryDto({
              id: QualityPointsReportSplit.Actor,
              text: t("Supplier")
            }),
            new RepositoryDto({
              id: QualityPointsReportSplit.ActorAndUnit,
              text: `${t("Supplier")} & ${t("Unit")}`
            })
          ]
        );
      } else {
        list.push(
          new RepositoryDto({
            id: QualityPointsReportSplit.Actor,
            text: t("Client")
          })
        );
      }
      return list;
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    routeTo(url: string) {
      window.open(url, "_blank");
    }
  }
});
