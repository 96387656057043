import { render, staticRenderFns } from "./SwitchDouble.vue?vue&type=template&id=b6c35338&scoped=true&"
import script from "./SwitchDouble.vue?vue&type=script&lang=ts&"
export * from "./SwitchDouble.vue?vue&type=script&lang=ts&"
import style0 from "./SwitchDouble.vue?vue&type=style&index=0&id=b6c35338&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6c35338",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VSwitch,VTooltip})
