






































import Vue from "vue";

export default Vue.extend({
  props: {
    show: Boolean,
    message: String,
    secondsLeft: Number,
    closable: Boolean,
    error: Boolean
  },
  watch: {
    secondsLeft: {
      handler() {
        if (this.secondsLeft === undefined) {
          return;
        }
        const hours = Math.floor(this.secondsLeft / 3600);
        const minutes = Math.floor((this.secondsLeft - hours * 3600) / 60);
        const seconds = this.secondsLeft - hours * 3600 - minutes * 60;
        this.countdown = `${hours}:${minutes < 10 ? "0" : ""}${minutes}:${
          seconds < 10 ? "0" : ""
        }${seconds}`;
      },
      immediate: true
    }
  },
  methods: {
    hide() {
      this.$emit("hide");
    }
  },
  data: (): { countdown: String | undefined } => ({
    countdown: undefined
  })
});
