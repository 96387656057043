








































































































































































import Vue from "vue";
import {
  AgreementRouteForProcurementDto,
  DocumentGroupDto,
  RepositoryDto,
  SaveProcurementDto
} from "@/scripts/cld.api";
import CreateProcurementAgreementRoutesTable from "@/components/web/agreements/client/CreateProcurementAgreementRoutesTable.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import { rankingMethodName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    procurement: SaveProcurementDto,
    agreementRoutes: Array as () => AgreementRouteForProcurementDto[],
    suppliers: Array as () => RepositoryDto[],
    documentGroups: Array as () => DocumentGroupDto[],
    baseIndexText: String
  },
  components: {
    GreyTitle,
    TextMulti,
    CreateProcurementAgreementRoutesTable,
    CustomTableHeader,
    DocumentDropdown
  },
  computed: {
    listHeight(): number {
      return Math.max(
        200,
        (this.$store.getters[gets.dimensions].height - 353) / 2
      );
    },
    tagGroups(): { tag: string; count: number }[] {
      const agreementRoutes = this.agreementRoutes.filter(r =>
        this.procurement.agreementRouteIds!.includes(r.id)
      );
      const tags = [...new Set(agreementRoutes.map(r => r.tag))];
      return tags.map(x => ({
        tag: x || t("NoTag"),
        count: agreementRoutes.filter(r => r.tag === x).length
      }));
    },
    supplierIds(): number[] {
      return this.procurement.supplierIds!;
    },
    agreementRouteIds(): number[] {
      return this.procurement.agreementRouteIds!;
    }
  },
  methods: {
    rankingMethodName: rankingMethodName,
    routesWithTagText(count: number, tag: string): string {
      return t("XRoutesWithTag")
        .replace("{0}", count.toString())
        .replace("{1}", tag);
    }
  }
});
