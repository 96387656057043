














import { LoadListTab, loadListTabName } from "@/scripts/types";
import Vue from "vue";
export default Vue.extend({
  props: {
    tabs: Array as () => LoadListTab[],
    selectedTab: LoadListTab
  },
  computed: {
    selectedTabIndex: {
      get(): number {
        return (this.tabs as LoadListTab[]).findIndex(
          t => t.id === this.selectedTab.id
        );
      },
      set(index: number) {
        this.$emit("selectTabId", (this.tabs as LoadListTab[])[index].id);
      }
    }
  },
  methods: {
    loadListTabName: loadListTabName
  }
});
