




















import Vue from "vue";
import { ActorType, DocumentGroupDto } from "@/scripts/cld.api";
import DocumentButton from "@/components/shared/ui/DocumentButton.vue";
import { docTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DocumentButton
  },
  props: {
    documentGroup: DocumentGroupDto,
    compact: Boolean
  },
  computed: {
    text(): string | undefined {
      if (!this.documentGroup) {
        return undefined;
      }
      if (this.actorType === ActorType.Supplier) {
        return docTypeName(this.documentGroup.docType);
      }
      return this.documentGroup.description;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  }
});
