




































































































import Vue from "vue";
import {
  RankingAgreementDto,
  RankingAgreementRowDto,
  CargoType
} from "@/scripts/cld.api";
import money from "@/scripts/misc/money";

export default Vue.extend({
  props: {
    cargoTypeId: Number,
    agreement: RankingAgreementDto,
    loading: Boolean,
    secondaryValue: Function
  },
  computed: {
    description(): string {
      let text = this.$t("PreviousAgreementRanking").toString();
      try {
        let from = this.agreement.agreementFrom.datePrint();
        let to = this.agreement.agreementTo.datePrint();
        return `${text} (${from} - ${to})`;
      } catch {
        return text;
      }
    },
    showHideText(): string {
      if (this.showPrevAgreementRow) {
        return this.$t("Hidden").toString();
      } else {
        return this.$t("Show").toString();
      }
    }
  },
  methods: {
    money: money,
    prevAgreementRowTooltip(agreementRow: RankingAgreementRowDto) {
      let tooltip = "";
      tooltip += agreementRow.supplierName;
      tooltip += [
        CargoType.Road,
        CargoType.Railroad,
        CargoType.Container
      ].includes(this.cargoTypeId)
        ? `\n${this.$t("OfferedPrice")}: ${
            agreementRow.basePrice ? agreementRow.basePrice : 0
          }`
        : "";
      tooltip += [CargoType.MachineTransport].includes(this.cargoTypeId)
        ? `\n${this.$t("PricePerKm")}: ${
            agreementRow.basePrice ? agreementRow.basePrice : 0
          }`
        : "";
      tooltip += [CargoType.MachineTransport].includes(this.cargoTypeId)
        ? `\n${this.$t("TruckTypeFee")}: ${
            agreementRow.initialFee ? agreementRow.initialFee : 0
          }`
        : "";
      tooltip += [CargoType.Road, CargoType.MachineTransport].includes(
        this.cargoTypeId
      )
        ? `\n${this.$t("StopPrice")}: ${
            agreementRow.stopPrice ? agreementRow.stopPrice : 0
          }`
        : "";
      tooltip += [CargoType.Container].includes(this.cargoTypeId)
        ? `\n${this.$t("ServiceFee")}: ${
            agreementRow.serviceFee ? agreementRow.serviceFee : 0
          }`
        : "";
      tooltip += agreementRow.note
        ? `\n${this.$t("Note")}: ${agreementRow.note}`
        : "";
      return tooltip;
    },
    showHidePrevAgreementRow() {
      this.showPrevAgreementRow = !this.showPrevAgreementRow;
    }
  },
  data(): {
    showPrevAgreementRow: boolean;
  } {
    return {
      showPrevAgreementRow: true
    };
  }
});
