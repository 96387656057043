import Vue from "vue";
import en from "./dev/cld.json";
import sv from "./sv-SE/cld.json";
import fi from "./fi-FI/cld.json";
import no from "./nb-NO/cld.json";
import VueI18n from "vue-i18n";
import { Culture } from "../cld.api";

Vue.use(VueI18n);
const defaultLocale = Culture.English;
const messages = Object.assign({
  [Culture.English]: en,
  [Culture.Swedish]: sv,
  [Culture.Finnish]: fi,
  [Culture.Norwegian]: no
});
const i18n = new VueI18n({
  locale: defaultLocale.toString(),
  fallbackLocale: defaultLocale.toString(),
  messages
});

interface Lang {
  locale: Culture;
  image: any;
  code: string;
  shortCode: string;
  priorities: Culture[];
}

const langs: Lang[] = [
  {
    locale: Culture.English,
    image: require("@/assets/img/en-US.svg"),
    code: "en-US",
    shortCode: "EN",
    priorities: [
      Culture.English,
      Culture.Swedish,
      Culture.Norwegian,
      Culture.Finnish
    ]
  },
  {
    locale: Culture.Swedish,
    image: require("@/assets/img/sv-SE.svg"),
    code: "sv-SE",
    shortCode: "SV",
    priorities: [
      Culture.Swedish,
      Culture.English,
      Culture.Norwegian,
      Culture.Finnish
    ]
  },
  {
    locale: Culture.Finnish,
    image: require("@/assets/img/fi-FI.svg"),
    code: "fi-FI",
    shortCode: "FI",
    priorities: [
      Culture.Finnish,
      Culture.English,
      Culture.Swedish,
      Culture.Norwegian
    ]
  },
  {
    locale: Culture.Norwegian,
    image: require("@/assets/img/nb-NO.svg"),
    code: "nb-NO",
    shortCode: "NO",
    priorities: [
      Culture.Norwegian,
      Culture.English,
      Culture.Swedish,
      Culture.Finnish
    ]
  }
];

function localeCode(): string {
  return langs.find(l => l.locale.toString() === i18n.locale)!.code;
}

function t(key: string): string {
  return i18n.t(key).toString();
}

const bestLang = (locales: Culture[]): Lang => {
  const lang = langs.find(l => l.locale.toString() === i18n.locale)!;
  let bestLocale = Culture.English;
  for (const p of lang.priorities) {
    if (locales.includes(p)) {
      bestLocale = p;
      break;
    }
  }
  return langs.find(l => l.locale === bestLocale)!;
};

export { i18n, t, langs, defaultLocale, localeCode, bestLang };
