






























































import Vue from "vue";
import {
  LoadDetailsContentDto,
  LoadDetailsShippingInfoDto
} from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    values: LoadDetailsShippingInfoDto,
    loadId: Number,
    ordered: Boolean,
    content: LoadDetailsContentDto
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    ranking(): string {
      return this.values.ranking === 0 ? "" : this.values.ranking.toString();
    }
  }
});
