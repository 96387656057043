




















import Vue from "vue";
import {
  ActorType,
  DeviationCause,
  LoadActionPermission,
  LoadListDto,
  LoadStatus,
  PublishSpeed
} from "@/scripts/cld.api";
import { LoadAction } from "@/scripts/types";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import MomentX from "@/scripts/misc/momentX";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

interface Info {
  icon: string;
  color: string;
  tooltip: string;
}

export default Vue.extend({
  components: {
    CheckboxTiny
  },
  props: {
    load: LoadListDto,
    checked: Boolean
  },
  computed: {
    infos(): Info[] {
      const infos: (Info | undefined)[] = [
        this.missingAgreement,
        this.noSupplierLeft,
        this.resigned,
        this.broadcast,
        this.economy,
        this.containerExport,
        this.spotTenderPrice,
        this.spotTenderHasEnded
      ];
      return infos.filter(i => i !== undefined) as Info[];
    },
    missingAgreement(): Info | undefined {
      if (
        this.actorType === ActorType.Client &&
        this.load.status === LoadStatus.MissingAgreement &&
        this.load.deviationCause !== DeviationCause.SpotTender
      ) {
        return {
          icon: "mdi-alert-octagon",
          color: "error",
          tooltip: t("MissingAgreement")
        };
      }
      return undefined;
    },
    noSupplierLeft(): Info | undefined {
      if (
        this.actorType === ActorType.Client &&
        this.load.status === LoadStatus.Ordered &&
        this.load.deviationCause !== DeviationCause.SpotTender &&
        this.load.noSupplierLeftToNotify
      ) {
        return {
          icon: "mdi-alert-octagon",
          color: "warning",
          tooltip: t("AllSuppliersHaveBeenOffered")
        };
      }
      return undefined;
    },
    resigned(): Info | undefined {
      if (
        this.actorType === ActorType.Supplier &&
        this.load.resigned &&
        this.load.status === LoadStatus.Ordered &&
        this.load.deviationCause !== DeviationCause.SpotTender
      ) {
        return {
          icon: "mdi-cancel",
          color: "error",
          tooltip: t("YouHaveDeclined")
        };
      }
      return undefined;
    },
    broadcast(): Info | undefined {
      if (
        (this.actorType === ActorType.Supplier ||
          this.actorType === ActorType.Client) &&
        this.load.status === LoadStatus.Ordered &&
        this.load.deviationCause !== DeviationCause.SpotTender &&
        this.load.publishSpeed === PublishSpeed.Broadcast
      ) {
        return {
          icon: "mdi-rabbit",
          color: "error",
          tooltip: t("Broadcast")
        };
      }
      return undefined;
    },
    economy(): Info | undefined {
      if (
        (this.actorType === ActorType.Supplier ||
          this.actorType === ActorType.Client) &&
        this.load.status === LoadStatus.Ordered &&
        this.load.deviationCause !== DeviationCause.SpotTender &&
        this.load.publishSpeed === PublishSpeed.Economy
      ) {
        return {
          icon: "mdi-snail",
          color: "success",
          tooltip: t("Economy")
        };
      }
      return undefined;
    },
    containerExport(): Info | undefined {
      if (
        this.actions.some(
          a => a.id === LoadActionPermission.ContainerExport && a.allowed
        )
      ) {
        return {
          icon: "mdi-file-pdf-box",
          color: "error",
          tooltip: t("ContainerExport")
        };
      }
      return undefined;
    },
    spotTenderPrice(): Info | undefined {
      if (this.load.spotTenderPriceTooltipDtos!.length > 0) {
        return {
          icon: "mdi-currency-usd",
          color: "success",
          tooltip: this.load
            .spotTenderPriceTooltipDtos!.map(
              x => `${truckTypeName(x.truckType)}: ${x.price}`
            )
            .join("\n")
        };
      }
      return undefined;
    },
    spotTenderHasEnded(): Info | undefined {
      if (
        this.load.deviationCause === DeviationCause.SpotTender &&
        this.load.status === LoadStatus.Ordered &&
        this.load.spotTenderEndDate &&
        this.load.spotTenderEndDate.isBefore(new MomentX())
      ) {
        return {
          icon: "mdi-clock-alert",
          color: "warning",
          tooltip: t("SpotTenderTimePassed")
        };
      }
      return undefined;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    actions(): LoadAction[] {
      return this.$store.getters[gets.loadActions](this.load.id);
    }
  }
});
