var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return ((_vm.truckType.agreementRows || []).length > 0)?_c('v-sheet',{staticClass:"table-sheet",attrs:{"elevation":"3","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.truckType.agreementRows,"mobile-breakpoint":0,"items-per-page":-1,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([(!_vm.expanded)?{key:"body",fn:function(){return undefined},proxy:true}:null,{key:"header.expand",fn:function(){return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',{class:{ flipped: _vm.expanded }},[_vm._v(" mdi-chevron-down ")])],1)]},proxy:true},{key:"item.truckType",fn:function(){return [_vm._v(" "+_vm._s(_vm.truckTypeName(_vm.truckType.id))+" ")]},proxy:true},{key:"item.ranking",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ranking === 0 ? "" : item.ranking)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.money(item.price, 0))+" ")]}},{key:"item.note",fn:function(ref){
var item = ref.item;
return [(item.note)?_c('v-tooltip',{attrs:{"top":"","open-delay":0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"icon-yellow"}},on),[_vm._v(" mdi-note ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])]):_vm._e()]}},{key:"item.share",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.share)+"% ")]}}],null,true)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }