














































import Vue from "vue";
import { stringSearchHit } from "@/scripts/misc/stringUtils";
import { isoName } from "@/scripts/misc/enumNames";
import { RepositoryDto } from "@/scripts/cld.api";

export default Vue.extend({
  props: {
    value: Array as () => number[],
    label: String,
    items: Array as () => RepositoryDto[],
    tabindex: String,
    rules: Array,
    disabled: Boolean,
    loading: Boolean
  },
  methods: {
    isoName: isoName,
    input(val: number | null | undefined) {
      if (val) {
        this.$nextTick(() => {
          this.internalValue = null;
          (this.$refs.autocomplete as any).reset();
        });
        if (!this.value.includes(val)) {
          this.$emit("input", [...this.value, val]);
        }
      }
    },
    filter(_: any, queryText: string, itemText: string) {
      return stringSearchHit(queryText, itemText, true);
    }
  },
  data: (): { internalValue: any } => ({
    internalValue: null
  })
});
