






































































































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import {
  UserLevel,
  ApiConfiguration,
  RepositoryClient,
  RepositoryDto,
  UsersClient,
  ActorsClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { cargoTypeIcon } from "@/scripts/misc/enumNames";
import { UserInfo } from "@/scripts/types";
import { userLevels, yesNoItems } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    Dropdown
  },
  watch: {
    actorId() {
      if (this.actorId) {
        this.fetchUsers();
      }
    },
    "$route.query.actorid": {
      handler() {
        const id = Number(this.$route.query.actorid);
        if (!isNaN(id) && id) {
          this.actorId = id;
          this.mutateActor();
        }
      },
      immediate: true
    }
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.getters[gets.userInfo];
    }
  },
  methods: {
    cargoTypeIcon: cargoTypeIcon,
    userLevels: userLevels,
    yesNoItems: yesNoItems,
    speedSet(actorId: number) {
      this.actorId = actorId;
      this.mutateActor();
    },
    mutateActor() {
      this.mutationInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .mutateActor(this.actorId!)
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    setUserConf() {
      this.mutationInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .copyConfigurations(this.loadUserId!)
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    mutateUserLevel() {
      this.mutationInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .mutateUserLevel(this.userLevel!)
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuppliers() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .actorsForMutation()
        .then(res => {
          this.actors = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchUsers() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .usersSupportAdmin(this.actorId!)
        .then(res => {
          this.users = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.actorId = this.userInfo.actorId;
    this.userLevel = this.userInfo.userLevel;
    this.fetchSuppliers();
  },
  data: (): {
    mutationInProgress: boolean;
    actorId: number | undefined;
    userLevel: UserLevel | undefined;
    actors: RepositoryDto[];
    loadUserId: number | undefined;
    users: RepositoryDto[];
  } => ({
    mutationInProgress: false,
    actorId: undefined,
    userLevel: undefined,
    actors: [],
    loadUserId: undefined,
    users: []
  })
});
