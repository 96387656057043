














































































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import {
  ActorsClient,
  ActorType,
  ApiConfiguration,
  RepositoryDto,
  UserDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { validEmail } from "@/scripts/misc/stringUtils";
import { cultures, userLevels } from "@/scripts/misc/enumLists";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  props: {
    user: UserDto,
    actorType: Number as () => ActorType
  },
  components: {
    TextSingle,
    TextMulti,
    Dropdown,
    ReadOnlyText
  },
  computed: {
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    }
  },
  methods: {
    validEmail: validEmail,
    userLevels: userLevels,
    cultures: cultures,
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchActorInvoiceInformations() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .actorInvoiceInformationForSupportAdmin(this.user.actorId)
        .then(res => {
          this.actorInvoiceInformations = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchCountries();
    this.fetchActorInvoiceInformations();
  },
  data: (): {
    countries: RepositoryDto[];
    actorInvoiceInformations: RepositoryDto[];
  } => ({
    countries: [],
    actorInvoiceInformations: []
  })
});
