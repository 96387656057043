




















import Vue from "vue";
import {
  ApiConfiguration,
  SurveySubmissionDto,
  SurveySupplierClient,
  SurveyDto
} from "@/scripts/cld.api";
import Spinner from "@/components/shared/ui/Spinner.vue";
import SurveyIterationCard from "@/components/web/survey/supplier/SurveyIterationCard.vue";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    Spinner,
    SurveyIterationCard
  },
  methods: {
    submissionClicked(
      surveyId: number,
      submissionId?: number,
      readonly?: boolean
    ) {
      setTimeout(() => {
        this.$router.push({
          path: "/survey",
          query: {
            surveyId: surveyId.toString(),
            submissionId:
              submissionId !== undefined ? submissionId.toString() : undefined,
            readonly: readonly !== undefined ? readonly.toString() : undefined
          }
        });
      }, 100);
    }
  },
  mounted() {
    let pending = 2;
    new SurveySupplierClient(new ApiConfiguration(this.$store))
      .surveys()
      .then(res => {
        res.sort((a, b) => b.id - a.id);
        this.surveys = res;
        pending--;
        if (pending === 0) {
          this.loading = false;
        }
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    new SurveySupplierClient(new ApiConfiguration(this.$store))
      .submissions()
      .then(res => {
        res.sort((a, b) => b.answerDate.unix() - a.answerDate.unix());
        this.submissions = res;
        pending--;
        if (pending === 0) {
          this.loading = false;
        }
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    loading: boolean;
    surveys: SurveyDto[];
    submissions: SurveySubmissionDto[];
  } => ({
    loading: true,
    surveys: [],
    submissions: []
  })
});
