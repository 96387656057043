













































import Vue from "vue";
import { ConfirmationOptions } from "@/scripts/types";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    confirmationOptions: Object as () => ConfirmationOptions
  },
  watch: {
    confirmationOptions: {
      handler(newValue: ConfirmationOptions) {
        this.key++;
        this.initDialog(newValue);
      }
    }
  },
  methods: {
    initDialog(o: ConfirmationOptions) {
      this.waitingForCallback = false;
      this.options.dialog = o.dialog !== undefined ? o.dialog : true;
      this.options.width = o.width !== undefined ? o.width : 500;
      this.options.noCloseButton = o.noCloseButton;
      this.options.closeButtonCallback = o.closeButtonCallback
        ? () => {
            this.closeDialogAfterCallback(o.closeButtonCallback!);
          }
        : this.closeDialog;
      this.options.warning = o.warning;
      this.options.title = o.title;
      this.options.body = o.body;

      this.options.btnText1 =
        o.btnText1 !== undefined ? o.btnText1 : this.$t("Continue").toString();
      this.options.btnText2 =
        o.btnText2 !== undefined ? o.btnText2 : this.$t("Cancel").toString();
      this.options.btnColor1 = o.btnColor1;
      this.options.btnColor2 = o.btnColor2;
      this.options.btnCallback1 = () => {
        this.closeDialogAfterCallback(o.btnCallback1);
      };
      this.options.btnCallback2 = o.btnCallback2
        ? () => {
            this.closeDialogAfterCallback(o.btnCallback2!);
          }
        : undefined;
    },
    closeDialogAfterCallback(callback: Function) {
      this.waitingForCallback = true;
      let callbackPromise = callback();
      if (callbackPromise !== undefined) {
        callbackPromise.then(() => {
          this.closeDialog();
        });
      } else {
        this.closeDialog();
      }
    },
    closeDialog() {
      this.options.dialog = false;
    }
  },
  data: (): {
    waitingForCallback: boolean;
    options: ConfirmationOptions;
    key: number;
  } => ({
    waitingForCallback: false,
    options: {
      dialog: false,
      btnCallback1: () => {},
      closeButtonCallback: () => {}
    },
    key: 0
  })
});
