




























































































import Vue from "vue";
import { ActorType, CargoType, RepositoryDto } from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";
import {
  actorCargoTypes,
  actorTypes,
  cargoTypeByTruckType,
  cargoTypes,
  currencies,
  deviationCauses,
  feeTypes,
  loadStatuses,
  otherPriceCauses,
  routingExactnesses,
  truckTypes
} from "@/scripts/misc/enumLists";
import ifNotTabbing from "@/scripts/misc/ifNotTabbing";
import neverHappens from "@/scripts/misc/neverHappens";
import LocationFilter from "@/components/shared/input/LocationFilter.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import { t } from "@/scripts/language/i18n";

enum CustomFilterType {
  text = "text",
  autocomplete = "autocomplete",
  dropdown = "dropdown",
  dropdownMulti = "dropdownMulti",
  advanced = "advanced"
}

enum CustomFilterSubtype {
  activity = "activity",
  actorType = "actorType",
  baseIndexDate = "baseIndexDate",
  cargoType = "cargoType",
  client = "client",
  country = "country",
  currency = "currency",
  customer = "customer",
  deviationCauseMulti = "deviationCauseMulti",
  feeType = "feeType",
  location = "location",
  locationMulti = "locationMulti",
  locationCargoType = "locationCargotype",
  locationClient = "locationClient",
  loadStatus = "loadStatus",
  lockStatus = "lockStatus",
  otherPriceCauseMulti = "otherPriceCauseMulti",
  routingExactness = "routingExactness",
  supplier = "supplier",
  tag = "tag",
  terminal = "terminal",
  text = "text",
  truckType = "truckType",
  truckTypeMulti = "truckTypeMulti",
  unit = "unit",
  withWithout = "withWithout",
  yesNo = "yesNo"
}

interface FilterConfig {
  subtype: CustomFilterSubtype;
  cargoType?: CargoType;
  pickup?: boolean;
  agreementEndDates?: number[];
  includeInactive?: boolean;
}

export default Vue.extend({
  components: {
    Autocomplete,
    Dropdown,
    DropdownMulti,
    TextSingle,
    LocationFilter
  },
  props: {
    value: [Number, String, Boolean, Object, Array],
    placeholder: String,
    config: Object as () => FilterConfig
  },
  watch: {
    config: {
      handler() {
        switch (this.config.subtype) {
          case this.subtypes.baseIndexDate:
            this.fetchbaseIndexDates();
            break;
          case this.subtypes.client:
            this.fetchClients();
            break;
          case this.subtypes.country:
            this.fetchCountries();
            break;
          case this.subtypes.customer:
            this.fetchCustomers();
            break;
          case this.subtypes.location:
          case this.subtypes.locationMulti:
            this.fetchCounties();
            this.fetchCountries();
            break;
          case this.subtypes.locationClient:
            this.fetchCountiesClient();
            this.fetchCountries();
            break;
          case this.subtypes.locationCargoType:
            this.fetchCountiesCargoType();
            this.fetchCountries();
            break;
          case this.subtypes.supplier:
            this.fetchSuppliers();
            break;
          case this.subtypes.tag:
            this.fetchTags();
            break;
          case this.subtypes.terminal:
            this.fetchTerminals();
            break;
          case this.subtypes.unit:
            this.fetchUnits();
            break;
          case this.subtypes.activity:
          case this.subtypes.actorType:
          case this.subtypes.cargoType:
          case this.subtypes.currency:
          case this.subtypes.deviationCauseMulti:
          case this.subtypes.feeType:
          case this.subtypes.loadStatus:
          case this.subtypes.lockStatus:
          case this.subtypes.otherPriceCauseMulti:
          case this.subtypes.routingExactness:
          case this.subtypes.truckType:
          case this.subtypes.truckTypeMulti:
          case this.subtypes.text:
          case this.subtypes.withWithout:
          case this.subtypes.yesNo:
            break;
          default:
            neverHappens(this.config.subtype);
        }
      },
      immediate: true
    }
  },
  computed: {
    dropdownItems(): { id: any; text: any }[] {
      switch (this.config.subtype) {
        case this.subtypes.activity:
          return this.items.activity;
        case this.subtypes.actorType:
          return this.items.actorTypes;
        case this.subtypes.baseIndexDate:
          return this.items.baseIndexDates;
        case this.subtypes.cargoType:
          return this.items.cargoTypes;
        case this.subtypes.client:
          return this.items.clients;
        case this.subtypes.country:
          return this.items.countries;
        case this.subtypes.currency:
          return this.items.currencies;
        case this.subtypes.customer:
          return this.items.customers;
        case this.subtypes.deviationCauseMulti:
          return this.items.deviationCauses;
        case this.subtypes.feeType:
          return this.items.feeTypes;
        case this.subtypes.loadStatus:
          return this.items.loadStatuses;
        case this.subtypes.lockStatus:
          return this.items.lockStatuses;
        case this.subtypes.otherPriceCauseMulti:
          return this.items.otherPriceCauses;
        case this.subtypes.routingExactness:
          return this.items.routingExactnessItems;
        case this.subtypes.supplier:
          return this.items.suppliers;
        case this.subtypes.tag:
          return this.items.tags;
        case this.subtypes.terminal:
          return this.items.terminals;
        case this.subtypes.truckType:
        case this.subtypes.truckTypeMulti:
          return this.truckTypes;
        case this.subtypes.unit:
          return this.items.units;
        case this.subtypes.withWithout:
          return this.items.withWithout;
        case this.subtypes.yesNo:
          return this.items.yesNo;
        case this.subtypes.text:
        case this.subtypes.location:
        case this.subtypes.locationMulti:
        case this.subtypes.locationCargoType:
        case this.subtypes.locationClient:
          return [];
        default:
          return neverHappens(this.config.subtype);
      }
    },
    type(): string {
      switch (this.config.subtype) {
        case this.subtypes.client:
        case this.subtypes.country:
        case this.subtypes.customer:
        case this.subtypes.supplier:
        case this.subtypes.terminal:
          return this.types.autocomplete;
        case this.subtypes.location:
        case this.subtypes.locationMulti:
        case this.subtypes.locationCargoType:
        case this.subtypes.locationClient:
          return this.types.advanced;
        case this.subtypes.text:
          return this.types.text;
        case this.subtypes.activity:
        case this.subtypes.actorType:
        case this.subtypes.baseIndexDate:
        case this.subtypes.cargoType:
        case this.subtypes.currency:
        case this.subtypes.feeType:
        case this.subtypes.loadStatus:
        case this.subtypes.lockStatus:
        case this.subtypes.routingExactness:
        case this.subtypes.tag:
        case this.subtypes.truckType:
        case this.subtypes.unit:
        case this.subtypes.withWithout:
        case this.subtypes.yesNo:
          return this.types.dropdown;
        case this.subtypes.truckTypeMulti:
        case this.subtypes.deviationCauseMulti:
        case this.subtypes.otherPriceCauseMulti:
          return this.types.dropdownMulti;
        default:
          return neverHappens(this.config.subtype);
      }
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    truckTypes(): RepositoryDto[] {
      return truckTypes().filter(tt =>
        actorCargoTypes()
          .filter(c => !this.config.cargoType || this.config.cargoType === c.id)
          .some(c => cargoTypeByTruckType(tt.id) === c.id)
      );
    }
  },
  methods: {
    ifNotTabbing: ifNotTabbing,
    input(value: any) {
      this.$emit("input", value);
    },
    search() {
      this.$emit("search");
    },
    clearSelection() {
      this.$emit("search");
    },
    fetchbaseIndexDates() {
      cached("baseIndexPeriods")
        .then(res => {
          this.items.baseIndexDates = res.map(
            p =>
              new RepositoryDto({
                id: p.indexDate.dateTimePrint() as any,
                text: p.displayName
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      cached("clients", [
        true,
        true,
        this.config.includeInactive ? true : false
      ])
        .then(res => {
          this.items.clients = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCustomers() {
      cached("customers")
        .then(res => {
          this.items.customers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCounties() {
      cached("counties", null)
        .then(res => {
          res.sort((r1, r2) => {
            return (r1.text as String).localeCompare(r2.text!);
          });
          this.items.counties = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountiesClient() {
      cached(
        "countiesOnAgreementroutes",
        this.config.cargoType!,
        this.config.pickup!
      )
        .then(res => {
          res.sort((r1, r2) => {
            return (r1.text as String).localeCompare(r2.text!);
          });
          this.items.counties = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountiesCargoType() {
      cached("countiesByCargotype", this.config.cargoType!, this.config.pickup!)
        .then(res => {
          res.sort((r1, r2) => {
            return (r1.text as String).localeCompare(r2.text!);
          });
          this.items.counties = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.items.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuppliers() {
      cached("suppliers", [this.config.cargoType || null, true, true])
        .then(res => {
          this.items.suppliers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchTags() {
      cached("tags", [this.config.cargoType || null])
        .then(res => {
          this.items.tags = res.sort().map(t => ({ id: t, text: t }));
          this.items.tags.unshift({ id: "null", text: t("NoTag") });
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchTerminals() {
      cached("terminals")
        .then(res => {
          this.items.terminals = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchUnits() {
      cached("units")
        .then(res => {
          this.items.units = res.sort().map(u => ({ id: u, text: u }));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    types: typeof CustomFilterType;
    subtypes: typeof CustomFilterSubtype;
    items: {
      activity: RepositoryDto[];
      actorTypes: RepositoryDto[];
      baseIndexDates: RepositoryDto[];
      cargoTypes: RepositoryDto[];
      clients: RepositoryDto[];
      counties: RepositoryDto[];
      countries: RepositoryDto[];
      currencies: RepositoryDto[];
      customers: RepositoryDto[];
      deviationCauses: RepositoryDto[];
      feeTypes: RepositoryDto[];
      loadStatuses: RepositoryDto[];
      lockStatuses: RepositoryDto[];
      otherPriceCauses: RepositoryDto[];
      routingExactnessItems: RepositoryDto[];
      suppliers: RepositoryDto[];
      withWithout: RepositoryDto[];
      yesNo: RepositoryDto[];
      tags: { id: string; text: string }[];
      terminals: RepositoryDto[];
      units: { id: string; text: string }[];
    };
  } => ({
    types: CustomFilterType,
    subtypes: CustomFilterSubtype,
    items: {
      activity: [
        new RepositoryDto({
          id: true as any,
          text: "Aktiv"
        }),
        new RepositoryDto({
          id: false as any,
          text: "Inaktiv"
        })
      ],
      actorTypes: actorTypes(),
      baseIndexDates: [],
      cargoTypes: cargoTypes(),
      clients: [],
      counties: [],
      countries: [],
      currencies: currencies(),
      customers: [],
      deviationCauses: [
        new RepositoryDto({
          id: null as any,
          text: "<" + t("NoDeviations") + ">"
        }),
        ...deviationCauses()
      ],
      feeTypes: feeTypes(),
      loadStatuses: loadStatuses(),
      lockStatuses: [
        new RepositoryDto({
          id: true as any,
          text: "Inaktiverade"
        }),
        new RepositoryDto({
          id: false as any,
          text: "Aktiverade"
        })
      ],
      otherPriceCauses: [
        new RepositoryDto({
          id: null as any,
          text: "<" + t("NoDeviations") + ">"
        }),
        ...otherPriceCauses()
      ],
      routingExactnessItems: routingExactnesses(),
      suppliers: [],
      withWithout: [
        new RepositoryDto({
          id: true as any,
          text: t("With")
        }),
        new RepositoryDto({
          id: false as any,
          text: t("Without")
        })
      ],
      yesNo: [
        new RepositoryDto({
          id: true as any,
          text: t("Survey_Yes")
        }),
        new RepositoryDto({
          id: false as any,
          text: t("Survey_No")
        })
      ],
      tags: [],
      terminals: [],
      units: []
    }
  })
});
