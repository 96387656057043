






















































































































































import Vue from "vue";
import { LoadActionPermission, CargoType } from "@/scripts/cld.api";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions, gets } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import Auth from "@/scripts/auth";
import LoadDetailsDialog from "@/components/web/loads/shared/details/LoadDetailsDialog.vue";
import LoadEditDialog from "@/components/web/loads/client/edit/LoadEditDialog.vue";
import LoadBookDialog from "@/components/web/loads/shared/LoadBookDialog.vue";
import LoadLoadDialog from "@/components/web/loads/client/LoadLoadDialog.vue";
import LoadAnnulDialog from "@/components/web/loads/client/LoadAnnulDialog.vue";
import LoadDeliverDialog from "@/components/web/loads/shared/LoadDeliverDialog.vue";
import LoadUnbookDialog from "@/components/web/loads/client/LoadUnbookDialog.vue";
import LoadReorderDialog from "@/components/web/loads/client/LoadReorderDialog.vue";
import CreateSpotTenderDialog from "@/components/web/loads/client/spot/CreateSpotTenderDialog.vue";
import ClientEditSpotTenderDialog from "@/components/web/loads/client/spot/ClientEditSpotTenderDialog.vue";
import SupplierEditSpotTenderDialog from "@/components/web/loads/supplier/SupplierEditSpotTenderDialog.vue";
import LoadResignDialog from "@/components/web/loads/supplier/LoadResignDialog.vue";
import LoadSupplierEditDialog from "@/components/web/loads/supplier/LoadSupplierEditDialog.vue";
import MobileLoadDetails from "@/components/mobile/details/MobileLoadDetails.vue";
import LoadDeviationEditDialog from "@/components/web/loads/client/LoadDeviationEditDialog.vue";
import LoadEditContainersDialog from "@/components/web/loads/supplier/LoadEditContainersDialog.vue";
import LoadSupplierDocumentsDialog from "@/components/web/loads/supplier/LoadSupplierDocumentsDialog.vue";
import SlotDialog from "@/components/web/slots/SlotDialog.vue";

export default Vue.extend({
  components: {
    MobileLoadDetails,
    LoadDetailsDialog,
    LoadEditDialog,
    LoadBookDialog,
    LoadLoadDialog,
    LoadAnnulDialog,
    LoadDeliverDialog,
    LoadUnbookDialog,
    LoadReorderDialog,
    CreateSpotTenderDialog,
    ClientEditSpotTenderDialog,
    SupplierEditSpotTenderDialog,
    LoadResignDialog,
    LoadSupplierEditDialog,
    LoadDeviationEditDialog,
    LoadEditContainersDialog,
    LoadSupplierDocumentsDialog,
    SlotDialog
  },
  watch: {
    "$route.query": {
      handler() {
        const query = { ...this.$route.query };
        const actionId = query.action ? Number(query.action) : undefined;
        const cargoType = query.cargoType ? Number(query.cargoType) : undefined;
        if (!actionId) {
          return;
        }
        let loadIds: number[] = [];
        try {
          const arr: number[] = JSON.parse(query.ids as string);
          if (arr.every(x => typeof x === "number")) {
            loadIds = arr;
          }
        } catch (_) {}
        delete query.action;
        delete query.ids;
        delete query.cargoType;
        this.lockAndOpen(actionId, loadIds, cargoType);
        this.$router.push({
          query: query,
          path: this.$route.path
        });
      },
      immediate: true
    }
  },
  methods: {
    dialogVisible(actionId: LoadActionPermission): boolean {
      return this.actionId === actionId;
    },
    lockIfNeeded(
      actionId: LoadActionPermission | undefined,
      loadIds: number[]
    ): Promise<boolean> {
      if (this.$store.getters[gets.lockNeeded](actionId, loadIds)) {
        return this.$store.dispatch(actions.lock, loadIds);
      }
      return new Promise(resolve => resolve(true));
    },
    lockAndOpen(
      actionId: LoadActionPermission | undefined,
      loadIds: number[],
      cargoType: CargoType | undefined
    ) {
      this.key += 100;
      this.lockIfNeeded(actionId, loadIds)
        .then(success => {
          if (success) {
            this.actionId = actionId;
            this.loadIds = loadIds;
            this.cargoType = cargoType;
          }
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.actionId = undefined;
              this.$emit("search");
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    unlockAndClose(actionId: LoadActionPermission) {
      this.actionId = undefined;
      if (!this.$store.getters[gets.lockNeeded](actionId, this.loadIds)) {
        this.$emit("search");
        this.loadIds = [];
        return;
      }
      this.$store
        .dispatch(actions.unlock, this.loadIds)
        .then(() => {
          this.$emit("search");
          this.loadIds = [];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  computed: {
    mobile() {
      return Auth.browsingMobile();
    }
  },
  mounted() {
    this.$nextTick(function() {
      setInterval(() => {
        this.lockIfNeeded(this.actionId, this.loadIds);
      }, 33000);
    });
  },
  data: (): {
    loadIds: number[];
    actionId?: LoadActionPermission;
    cargoType?: CargoType;
    actionEnum: typeof LoadActionPermission;
    key: number;
  } => ({
    loadIds: [],
    actionId: undefined,
    cargoType: undefined,
    actionEnum: LoadActionPermission,
    key: 0
  })
});
