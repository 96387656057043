












































































import Vue from "vue";
import { cargoTypes } from "@/scripts/misc/enumLists";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import {
  ActorsClient,
  AgreementSearchCriteriaDto,
  ApiConfiguration,
  RepositoryDto
} from "@/scripts/cld.api";
import cached from "@/scripts/misc/cached";
import { actions } from "@/scripts/store/constants";
import { Toggles } from "@/views/support-admin/AgreementList.vue";

export default Vue.extend({
  props: {
    criteria: Object as () => AgreementSearchCriteriaDto,
    toggles: Object as () => Toggles,
    exporting: Boolean
  },
  components: {
    SearchCard,
    Dropdown,
    DatePicker,
    Autocomplete,
    SwitchDouble
  },
  watch: {
    "criteria.cargoType": {
      handler() {
        cached("suppliersForSupportAdmin", this.criteria.cargoType)
          .then(res => (this.suppliers = res))
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      },
      immediate: true
    }
  },
  computed: {
    exportDisabled(): boolean {
      return !this.criteria.clientId && !this.criteria.supplierId;
    }
  },
  mounted() {
    new ActorsClient(new ApiConfiguration(this.$store))
      .clientsForSupportAdmin(true)
      .then(res => {
        this.clients = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    cached("countries")
      .then(res => {
        this.countries = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    cargoTypes: RepositoryDto[];
    suppliers: RepositoryDto[];
    clients: RepositoryDto[];
    countries: RepositoryDto[];
  } => ({
    cargoTypes: cargoTypes(),
    suppliers: [],
    clients: [],
    countries: []
  })
});
