


































































































import Vue from "vue";
import {
  LoadsClient,
  ApiConfiguration,
  LoadClientEditSubLocations
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  components: {
    GreyTitle
  },
  props: {
    loadId: Number
  },
  watch: {
    loadId: {
      handler() {
        this.fetchSubLocations();
      },
      immediate: true
    }
  },
  computed: {
    disabled(): boolean {
      return (
        !this.subLocations ||
        (this.subLocations.subPickupLocations!.length === 0 &&
          this.subLocations.subDeliveryLocations!.length === 0)
      );
    },
    numLocations(): string {
      if (!this.subLocations) {
        return "";
      }
      return `(${this.subLocations.subPickupLocations!.length +
        this.subLocations.subDeliveryLocations!.length})`;
    }
  },
  methods: {
    fetchSubLocations() {
      this.subLocations = undefined;
      if (!this.loadId) {
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .sublocations(this.loadId)
        .then(res => {
          this.subLocations = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    subLocations: LoadClientEditSubLocations | undefined;
  } => ({
    subLocations: undefined
  })
});
