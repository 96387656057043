








































































import Vue from "vue";
import { ChartType, MegaPreset } from "@/scripts/types";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import { actions, gets, mutations } from "@/scripts/store/constants";
import {
  ActorConfigurationType,
  ApiConfiguration,
  MegaReportSearchCriteria,
  RepositoryDto,
  UsersClient
} from "@/scripts/cld.api";
import { ActorConfigurationEntry } from "@/scripts/store/actorConfModule";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    Autocomplete,
    DialogCard,
    TextSingle
  },
  props: {
    criteria: MegaReportSearchCriteria,
    chartType: Number as () => ChartType
  },
  watch: {
    presetId: {
      handler() {
        const entry = this.entries.find(e => e.subTypeId === this.presetId);
        if (entry) {
          const preset = entry.conf as MegaPreset;
          this.$emit(
            "useCriteria",
            this.copyCriteria(preset.criteria),
            preset.chartType
          );
        }
      },
      immediate: true
    }
  },
  computed: {
    entries(): ActorConfigurationEntry[] {
      return this.$store.getters[gets.allActorConfs](
        ActorConfigurationType.MegaPresets
      );
    },
    items(): RepositoryDto[] {
      return this.entries.map(
        e =>
          new RepositoryDto({
            id: e.subTypeId,
            text: `[${(e.conf as MegaPreset).creator}] ${
              (e.conf as MegaPreset).title
            }`
          })
      );
    }
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.dialogPresetId = this.presetId;
      this.title = "";
    },
    remove() {
      //TODO: ta bort från db
      this.saveInProgress = true;
      this.$store
        .dispatch(actions.deleteActorConf, {
          type: ActorConfigurationType.MegaPresets,
          subTypeId: this.dialogPresetId
        })
        .then(() => {
          return new UsersClient(new ApiConfiguration(this.$store)).profile();
        })
        .then(profile => {
          return this.$store.dispatch(actions.initActorConf, {
            actorType: profile.actorType,
            confs: profile.actorConfigurations
          });
        })
        .then(() => {
          this.saveInProgress = false;
          this.dialogPresetId = undefined;
        });
    },
    save() {
      this.saveInProgress = true;
      let preset: MegaPreset;
      if (this.dialogPresetId) {
        preset = this.$store.getters[gets.actorConf](
          ActorConfigurationType.MegaPresets,
          this.dialogPresetId
        );
      } else {
        this.dialogPresetId =
          nextUnique() + Math.max(0, ...this.entries.map(e => e.subTypeId));
        preset = {
          creator: this.$store.getters[gets.username],
          title: this.title,
          chartType: this.chartType,
          criteria: this.copyCriteria(this.criteria)
        };
      }
      this.$store.commit(mutations.setActorConf, {
        type: ActorConfigurationType.MegaPresets,
        subTypeId: this.dialogPresetId,
        conf: preset
      } as ActorConfigurationEntry);
      this.$store
        .dispatch(actions.saveActorConf, {
          type: ActorConfigurationType.MegaPresets,
          subTypeId: this.dialogPresetId
        })
        .then(() => {
          this.saveInProgress = false;
          this.dialogPresetId = undefined;
          this.dialog = false;
        });
    },
    copyCriteria(criteria: MegaReportSearchCriteria): MegaReportSearchCriteria {
      return new MegaReportSearchCriteria({
        fromDate: criteria.fromDate,
        toDate: criteria.toDate,
        fromCountries: [...criteria.fromCountries!],
        toCountries: [...criteria.toCountries!],
        fromCounties: [...criteria.fromCounties!],
        units: [...criteria.units!],
        accumulate: criteria.accumulate,
        monthsInGroup: criteria.monthsInGroup,
        clientIds: [...criteria.clientIds!],
        supplierIds: [...criteria.supplierIds!],
        cargoTypes: [...criteria.cargoTypes!],
        truckTypes: [...criteria.truckTypes!],
        loadStatuses: [...criteria.loadStatuses!],
        deviationCauses: [...criteria.deviationCauses!],
        otherPriceCauses: [...criteria.otherPriceCauses!],
        numExtraStops: [...criteria.numExtraStops!],
        splitBookingFeeType: criteria.splitBookingFeeType,
        stackYears: criteria.stackYears
      });
    }
  },
  data: (): {
    dialog: boolean;
    saveInProgress: boolean;
    presetId: number | undefined;
    dialogPresetId: number | undefined;
    title: string;
  } => ({
    dialog: false,
    saveInProgress: false,
    presetId: undefined,
    dialogPresetId: undefined,
    title: ""
  })
});
