import "core-js";
import Vue from "vue";
import App from "@/App.vue";
import router from "@/scripts/router";
import store from "@/scripts/store/store";
import vuetify from "@/scripts/vuetify";
import { i18n } from "@/scripts/language/i18n";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
