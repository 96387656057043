
























































import Vue from "vue";
import { CreateSpotTenderDto } from "@/scripts/cld.api";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import {
  pickupDeliveryRules,
  PickupDeliveryRules
} from "@/scripts/misc/dateUtils";

export default Vue.extend({
  components: {
    Checkbox,
    DatePicker
  },
  props: {
    saveDto: CreateSpotTenderDto
  },
  watch: {
    saveDto: {
      handler() {
        this.$nextTick(() => {
          let form: any = this.$refs.form;
          if (form !== undefined) {
            form.validate();
          }
          this.$nextTick(() => {
            this.$emit("change", this.valid);
          });
        });
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    dateRules(): PickupDeliveryRules {
      return pickupDeliveryRules(
        this.saveDto.dates!.pickupEarliest,
        this.saveDto.dates!.pickupLatest,
        this.saveDto.dates!.deliverEarliest
      );
    }
  },
  data: (): {
    valid: boolean;
  } => ({
    valid: false
  })
});
