import Vue from "vue";
import Router, { NavigationGuardNext, Route } from "vue-router";
import store from "@/scripts/store/store";
import { mutations, actions, gets } from "@/scripts/store/constants";
import Auth from "@/scripts/auth";

/***** Unauthenticated ***/
import PageNotFound from "@/views/unauthenticated/PageNotFound.vue";
import ErrorPage from "@/views/unauthenticated/ErrorPage.vue";
import InternetExplorerNotSupported from "@/views/unauthenticated/InternetExplorerNotSupported.vue";
import IntegrationUser from "@/views/unauthenticated/IntegrationUser.vue";
import MobileDeliver from "@/views/unauthenticated/MobileDeliver.vue";
import AnonymousMobileDashboard from "@/views/unauthenticated/AnonymousMobileDashboard.vue";

/***** Shared ************/
import Dashboard from "@/views/shared/Dashboard.vue";
import UserMutation from "@/views/shared/UserMutation.vue";
import Loads from "@/views/shared/Loads.vue";
import Search from "@/views/shared/Search.vue";
import PriceList from "@/views/shared/PriceList.vue";
import Indexes from "@/views/shared/Indexes.vue";
import About from "@/views/shared/About.vue";
import News from "@/views/shared/News.vue";
import SupportForm from "@/views/shared/SupportForm.vue";
import ExcludedActors from "@/views/shared/ExcludedActors.vue";
import Slots from "@/views/shared/Slots.vue";
import InvoiceReport from "@/views/shared/reports/InvoiceReport.vue";
import LoadStatistics from "@/views/shared/reports/LoadStatisticsReport.vue";
import AgreementStatistics from "@/views/shared/reports/AgreementStatisticsReport.vue";
import DistanceReport from "@/views/shared/reports/DistanceReport.vue";
import QualityPointsReport from "@/views/shared/reports/QualityPointsReport.vue";
import Messages from "@/views/shared/Messages.vue";
import MyActor from "@/views/shared/MyActor.vue";
import MySettings from "@/views/shared/MySettings.vue";
import Actors from "@/views/shared/Actors.vue";
import AdministrateActor from "@/views/shared/AdministrateActor.vue";
import AdministrateUsers from "@/views/shared/AdministrateUsers.vue";

/***** Client ************/
import AgreementRoutes from "@/views/client/AgreementRoutes.vue";
import CreateProcurement from "@/views/client/CreateProcurement.vue";
import ClientProcurements from "@/views/client/Procurements.vue";
import ProcurementOverview from "@/views/client/ProcurementOverview.vue";
import ClientAgreements from "@/views/client/Agreements.vue";
import ClientSurveyOverview from "@/views/client/SurveyOverview.vue";
import CostReport from "@/views/client/reports/CostReport.vue";
import DeviationReport from "@/views/client/reports/DeviationReport.vue";
import ClientMonitoring from "@/views/client/reports/ClientMonitoring.vue";
import AnnulledLoadsReport from "@/views/client/reports/AnnulledLoadsReport.vue";
import ClientSubjectiveQualityPoints from "@/views/client/SubjectiveQualityPoints.vue";
import ClientQualityPointsWeights from "@/views/client/QualityPointsWeights.vue";
import ClientDocuments from "@/views/client/Documents.vue";
import SetraExport from "@/views/client/SetraExport.vue";
import ProcurementAnalytics from "@/views/client/reports/ProcurementAnalytics.vue";
import CostForecast from "@/views/client/reports/CostForecast.vue";
import StandardPhrases from "@/views/client/StandardPhrases.vue";

/***** Supplier **********/
import SupplierProcurements from "@/views/supplier/Procurements.vue";
import SupplierAgreements from "@/views/supplier/Agreements.vue";
import SupplierSurveyOverview from "@/views/supplier/SurveyOverview.vue";
import Survey from "@/views/supplier/Survey.vue";
import SupplierSubjectiveQualityPoints from "@/views/supplier/SubjectiveQualityPoints.vue";
import SupplierQualityPointsWeights from "@/views/supplier/QualityPointsWeights.vue";
import PublishRules from "@/views/client/PublishRules.vue";
import SupplierDocuments from "@/views/supplier/Documents.vue";

/***** SupportAdmin ******/
import SupportAdminDashboard from "@/views/support-admin/Dashboard.vue";
import ActorList from "@/views/support-admin/ActorList.vue";
import AgreementList from "@/views/support-admin/AgreementList.vue";
import Explorer from "@/views/support-admin/Explorer.vue";
import MailViewer from "@/views/support-admin/Mailviewer.vue";
import QualityPointsVis from "@/views/support-admin/QualityPointsVis.vue";
import QualityPointsWeights from "@/views/support-admin/QualityPointsWeights.vue";
import Distance from "@/views/support-admin/Distance.vue";
import InvoiceGenerator from "@/views/support-admin/InvoiceGenerator.vue";
import PowerButtons from "@/views/support-admin/PowerButtons.vue";
import BackgroundTasks from "@/views/support-admin/BackgroundTasks.vue";
import SupportAdminNews from "@/views/support-admin/News.vue";
import EditNews from "@/views/support-admin/EditNews.vue";
import MailTemplate from "@/views/support-admin/MailTemplate.vue";
import Playground from "@/views/support-admin/Playground.vue";
import ButWhy from "@/views/support-admin/ButWhy.vue";
import IndexComponents from "@/views/support-admin/IndexComponents.vue";
import SupportAdminDocuments from "@/views/support-admin/Documents.vue";
import AddToProcurements from "@/views/support-admin/AddToProcurements.vue";
import AddLocation from "@/views/support-admin/AddLocation.vue";
import ActorSigning from "@/views/support-admin/ActorSigning.vue";

/***** SupportAdmin Statistics */
import MegaReport from "@/views/support-admin/statistics/MegaReport.vue";
import HeatMap from "@/views/support-admin/statistics/HeatMap.vue";
import SupportAdminActors from "@/views/support-admin/statistics/Actors.vue";
import InvoicedFeesReport from "@/views/support-admin/statistics/InvoicedFeesReport.vue";
import ActiveUsers from "@/views/support-admin/statistics/ActiveUsers.vue";

/***** Mobile *****/
import MobileLoads from "@/views/mobile/MobileLoads.vue";
import MobileSlots from "@/views/mobile/MobileSlots.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    /***** Authentication *****/
    {
      path: "/auth-signin",
      name: "auth-signin"
    },
    {
      path: "/support-admin/auth-signin",
      name: "auth-signin-sa"
    },
    {
      path: "/mobile/auth-signin",
      name: "auth-signin-mo"
    },
    {
      path: "/auth-signout",
      name: "auth-signout"
    },
    {
      path: "/support-admin/auth-signout",
      name: "auth-signout-sa"
    },
    {
      path: "/mobile/auth-signout",
      name: "auth-signout-mo"
    },
    {
      path: "/auth-silent",
      name: "auth-silent"
    },
    {
      path: "/support-admin/auth-silent",
      name: "auth-silent-sa"
    },
    {
      path: "/mobile/auth-silent",
      name: "auth-silent-mo"
    },
    /***** Unauthenticated ****/
    {
      path: "*",
      component: PageNotFound,
      name: "404",
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/error",
      component: ErrorPage,
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/internet-explorer-not-supported",
      component: InternetExplorerNotSupported,
      name: "ie",
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/integration-user",
      component: IntegrationUser,
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/mobile/deliver",
      component: MobileDeliver,
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/mobile/anon-dashboard",
      component: AnonymousMobileDashboard,
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/mobile/slots",
      component: MobileSlots,
      meta: {
        unauthenticated: true
      }
    },
    {
      path: "/sb",
      meta: {
        redirect: true
      }
    },
    {
      path: "/d",
      meta: {
        redirect: true
      }
    },
    /***** Shared ************/
    {
      path: "/",
      name: "dashboard",
      component: Dashboard
    },
    {
      path: "/user-mutation",
      component: UserMutation
    },
    {
      path: "/loads",
      component: Loads
    },
    {
      path: "/search",
      component: Search
    },
    {
      path: "/slots",
      component: Slots
    },
    {
      path: "/procurements",
      components: {
        Client: ClientProcurements,
        Supplier: SupplierProcurements
      }
    },
    {
      path: "/agreements",
      components: {
        Client: ClientAgreements,
        Supplier: SupplierAgreements
      }
    },
    {
      path: "/documents",
      components: {
        Client: ClientDocuments,
        Supplier: SupplierDocuments
      }
    },
    {
      path: "/load-statistics",
      component: LoadStatistics
    },
    {
      path: "/agreement-statistics",
      component: AgreementStatistics
    },
    {
      path: "/invoice-report",
      component: InvoiceReport
    },
    {
      path: "/distance-report",
      component: DistanceReport
    },
    {
      path: "/quality-points",
      component: QualityPointsReport
    },
    {
      path: "/quality-points-weights",
      components: {
        Client: ClientQualityPointsWeights,
        Supplier: SupplierQualityPointsWeights
      }
    },
    {
      path: "/surveys",
      components: {
        Client: ClientSurveyOverview,
        Supplier: SupplierSurveyOverview
      }
    },
    {
      path: "/actors",
      component: Actors
    },
    {
      path: "/price-list",
      component: PriceList
    },
    {
      path: "/messages",
      component: Messages
    },
    {
      path: "/indexes",
      component: Indexes
    },
    {
      path: "/about",
      component: About
    },
    {
      path: "/news",
      component: News
    },
    {
      path: "/support-form",
      component: SupportForm
    },
    {
      path: "/excluded-actors",
      component: ExcludedActors
    },
    {
      path: "/administrate-actor",
      component: AdministrateActor
    },
    {
      path: "/administrate-users",
      component: AdministrateUsers
    },
    {
      path: "/my-actor",
      component: MyActor
    },
    {
      path: "/my-settings",
      component: MySettings
    },
    /***** Client ************/
    {
      path: "/agreement-routes",
      component: AgreementRoutes
    },
    {
      path: "/create-procurement",
      component: CreateProcurement
    },
    {
      path: "/procurement-overview",
      component: ProcurementOverview
    },
    {
      path: "/subjective-quality-points",
      components: {
        Client: ClientSubjectiveQualityPoints,
        Supplier: SupplierSubjectiveQualityPoints
      }
    },
    {
      path: "/standard-phrases",
      component: StandardPhrases
    },
    {
      path: "/publish-rules",
      component: PublishRules
    },
    {
      path: "/cost-report",
      component: CostReport
    },
    {
      path: "/deviation-report",
      component: DeviationReport
    },
    {
      path: "/client-monitoring",
      component: ClientMonitoring
    },
    {
      path: "/annulled-loads",
      component: AnnulledLoadsReport
    },
    {
      path: "/setra-export",
      component: SetraExport
    },
    {
      path: "/cost-forecast",
      component: CostForecast
    },
    {
      path: "/procurement-analytics",
      component: ProcurementAnalytics
    },
    /***** Supplier **********/
    {
      path: "/survey",
      component: Survey
    },
    /***** Mobile *****/
    {
      path: "/mobile",
      component: MobileLoads
    },
    {
      path: "/mobile/about",
      component: About
    },
    {
      path: "/mobile/support-form",
      component: SupportForm
    },
    /***** SupportAdmin ******/
    {
      path: "/support-admin",
      component: SupportAdminDashboard
    },
    {
      path: "/support-admin/explorer",
      component: Explorer
    },
    {
      path: "/support-admin/actors",
      component: ActorList
    },
    {
      path: "/support-admin/agreements",
      component: AgreementList
    },
    {
      path: "/support-admin/mailviewer",
      component: MailViewer
    },
    {
      path: "/support-admin/qp-vis",
      component: QualityPointsVis
    },
    {
      path: "/support-admin/qp-weights",
      component: QualityPointsWeights
    },
    {
      path: "/support-admin/documents",
      component: SupportAdminDocuments
    },
    {
      path: "/support-admin/invoicing",
      component: InvoiceGenerator
    },
    {
      path: "/support-admin/distance",
      component: Distance
    },
    {
      path: "/support-admin/power-buttons",
      component: PowerButtons
    },
    {
      path: "/support-admin/background-tasks",
      component: BackgroundTasks
    },
    {
      path: "/support-admin/edit-news",
      component: EditNews
    },
    {
      path: "/support-admin/news",
      component: SupportAdminNews
    },
    {
      path: "/support-admin/mail-template",
      component: MailTemplate
    },
    {
      path: "/support-admin/playground",
      component: Playground
    },
    {
      path: "/support-admin/but-why",
      component: ButWhy
    },
    {
      path: "/support-admin/index-components",
      component: IndexComponents
    },
    {
      path: "/support-admin/add-to-procurements",
      component: AddToProcurements
    },
    {
      path: "/support-admin/add-location",
      component: AddLocation
    },
    {
      path: "/support-admin/actor-signing",
      component: ActorSigning
    },
    {
      path: "/support-admin/mega-report",
      component: MegaReport
    },
    {
      path: "/support-admin/heat-map",
      component: HeatMap
    },
    {
      path: "/support-admin/actors-statistics",
      component: SupportAdminActors
    },
    {
      path: "/support-admin/invoiced-fees-report",
      component: InvoicedFeesReport
    },
    {
      path: "/support-admin/active-users-statistics",
      component: ActiveUsers
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (store.getters[gets.preventLeaving]) {
    store.commit(mutations.setTryingToLeave, next);
  } else if (internetExplorerRedirect(to)) {
    next({ name: "ie" });
  } else if (to.meta && to.meta.redirect) {
    redirect(to);
  } else if (to.name && to.name.startsWith("auth-")) {
    handleAuthCallbacks(to, next);
  } else if (to.meta && to.meta.unauthenticated) {
    next();
  } else if (
    store.getters[gets.username] === "" &&
    !store.getters[gets.autoRenewing]
  ) {
    Auth.removeUser().then(() => {
      store.commit(mutations.setAutoRenewing, true);
      store.dispatch(actions.autoTokenRenew, to.fullPath);
      next();
    });
  } else if (needsSiteSwitch(from.path, to.path)) {
    window.location.href = to.path;
  } else {
    next();
  }
});

const needsSiteSwitch = (from: string, to: string): boolean => {
  const paths = [from, to];
  if (paths.filter(path => path.startsWith("/support-admin")).length === 1) {
    return true;
  }
  if (paths.filter(path => path.startsWith("/mobile")).length === 1) {
    return true;
  }
  return false;
};

const redirect = (to: Route) => {
  if (to.path.startsWith("/sb")) {
    window.location.href = "/mobile/slots?id=" + to.query.id;
  } else if (to.path.startsWith("/d")) {
    window.location.href = "/mobile/deliver?tn=" + to.query.tn;
  }
};

const internetExplorerRedirect = (to: Route) => {
  const ua = navigator.userAgent;
  const msie = ua.indexOf("MSIE ");
  const trident = ua.indexOf("Trident/");
  if (
    to.name !== "ie" &&
    to.name !== "dashboard" &&
    (trident > 0 || msie > 0)
  ) {
    return true;
  }
  return false;
};

const handleAuthCallbacks = (to: Route, next: NavigationGuardNext<Vue>) => {
  if (
    to.name === "auth-signout" ||
    to.name === "auth-signout-sa" ||
    to.name === "auth-signout-mo"
  ) {
    store.dispatch(actions.endSignout).then(() => {
      next(store.getters[gets.pathBeforeAuthentication]);
    });
  } else if (
    to.name === "auth-signin" ||
    to.name === "auth-signin-sa" ||
    to.name === "auth-signin-mo"
  ) {
    store.dispatch(actions.endSignin).then(() => {
      next(store.getters[gets.pathBeforeAuthentication]);
    });
  } else if (
    to.name === "auth-silent" ||
    to.name === "auth-silent-sa" ||
    to.name === "auth-silent-mo"
  ) {
    store.dispatch(actions.endSigninSilent);
  }
};

export default router;
