






















































































import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import Vue from "vue";
import {
  ApiConfiguration,
  Currency,
  LoadActionPermission,
  LoadsClientClient,
  RepositoryDto,
  SaveDeviationDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { currencyName } from "@/scripts/misc/enumNames";
import { MultiLoad } from "@/scripts/types";
import MomentX from "@/scripts/misc/momentX";
import { deviationCauses, otherPriceCauses } from "@/scripts/misc/enumLists";
export default Vue.extend({
  components: {
    DialogCard,
    Dropdown,
    TextMulti,
    NumberInput,
    LoadLock,
    LoadChips
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    },
    "saveDto.otherPrice"() {
      if (!this.saveDto!.otherPrice) {
        this.saveDto!.otherPriceCauseId = undefined;
        this.saveDto!.noteOtherPrice = undefined;
      }
    }
  },
  computed: {
    loadId(): number {
      return this.loadIds[0]!;
    },
    otherPrice: {
      get(): number | undefined {
        return this.saveDto!.otherPrice;
      },
      set(val: number) {
        this.saveDto!.otherPrice = val;
        this.otherPriceCauseDisabled = !val;
        this.validateForm();
      }
    },
    otherPriceCauses(): RepositoryDto[] {
      return otherPriceCauses();
    },
    deviationCauses(): RepositoryDto[] {
      return deviationCauses();
    }
  },
  methods: {
    currencyName: currencyName,
    init() {
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .preDeviation(this.loadId)
        .then(res => {
          this.saveDto = new SaveDeviationDto({
            otherPrice: res.otherPrice,
            otherPriceCauseId: res.otherPriceCauseId,
            deviationCauseId: res.deviationCauseId,
            noteOtherPrice: res.noteOtherPrice,
            noteDeviation: res.noteDeviation
          });
          this.currency = res.currency;
          this.supplierAndPrice = res.supplierAndPrice;
          this.deviationCauseDisabled = res.deviationCauseId === null;

          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: new MomentX(),
            deliverLatest: new MomentX(),
            truckType: res.truckType,
            transportNo: res.transportNo,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: res.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          };
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    async validateForm() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        if (form !== undefined) {
          form.validate();
        }
      });
    },
    close() {
      this.$emit("close");
    },
    async save() {
      await this.validateForm();
      if (!this.valid) {
        return;
      }
      this.saveInProgress = true;
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .saveDeviation(this.loadId, this.saveDto!)
        .then(() => {
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    load: MultiLoad | undefined;
    supplierAndPrice?: string;
    saveDto?: SaveDeviationDto | undefined;
    otherPriceCauseDisabled: boolean;
    deviationCauseDisabled: boolean;
    currency: Currency;
  } => ({
    valid: false,
    saveInProgress: false,
    load: undefined,
    supplierAndPrice: undefined,
    saveDto: undefined,
    otherPriceCauseDisabled: true,
    deviationCauseDisabled: false,
    currency: undefined as any
  })
});
