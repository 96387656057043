











































































import Vue from "vue";
import surveyQuestions, {
  SurveyQuestion,
  SurveyAnswerRowDtoX
} from "@/scripts/misc/surveyQuestions";
import TextMulti from "@/components/shared/input/TextMulti.vue";

export default Vue.extend({
  components: {
    TextMulti
  },
  props: {
    augmentedAnswerRow: SurveyAnswerRowDtoX,
    readonly: Boolean
  },
  computed: {
    surveyQuestion(): SurveyQuestion {
      return surveyQuestions(this.augmentedAnswerRow.questionId);
    },
    question(): string {
      return this.surveyQuestion.question;
    },
    options(): [number, string][] | undefined {
      let options = this.surveyQuestion.options;
      if (options !== undefined) {
        return Object.entries(options).map(entry => [
          Number(entry[0]),
          entry[1]
        ]);
      }
      return undefined;
    },
    showFreetext(): boolean {
      return this.surveyQuestion.freetext ? true : false;
    },
    selectedOption: {
      get(): number | undefined {
        return this.augmentedAnswerRow.selectedOption;
      },
      set(val: number) {
        this.augmentedAnswerRow.selectedOption = val;
        this.augmentedAnswerRow.valid = this.inputValid();
      }
    },
    text: {
      get(): string | undefined {
        return this.augmentedAnswerRow.text;
      },
      set(val: string | undefined) {
        this.augmentedAnswerRow.text = val;
        this.augmentedAnswerRow.valid = this.inputValid();
      }
    }
  },
  methods: {
    translateIfNeeded(key: number, text: string): string {
      if (text === "") {
        text = `${this.question}_${key}`;
      }
      if (text.includes("Survey_")) {
        text = this.$t(text).toString();
      }
      return text;
    },
    freetextValid(value: string | undefined) {
      return (
        !this.showFreetext ||
        this.options !== undefined ||
        (value !== undefined && value !== "")
      );
    },
    selectedOptionValid(value: number | undefined) {
      return this.options === undefined || value !== undefined;
    },
    inputValid(): boolean {
      return (
        this.freetextValid(this.augmentedAnswerRow.text) &&
        this.selectedOptionValid(this.augmentedAnswerRow.selectedOption)
      );
    },
    validateEntireCard() {
      this.$emit("changeMade");
      let elem: any = this.$refs.selectedOption as any;
      if (elem !== undefined) elem.hasFocused = true;
      elem = this.$refs.freetext as any;
      if (elem !== undefined) elem.hasFocused = true;
    }
  },
  mounted() {
    //Needed to trigger validation when resuming a survey.
    this.text = this.text;
  }
});
