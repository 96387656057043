















































import Vue from "vue";
import { LoadListTab, spotTenderTabId } from "@/scripts/types";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import {
  ActorType,
  LoadSearchCriteriaDto,
  LoadStatus,
  UserConfigurationType
} from "@/scripts/cld.api";
import {
  daysBack,
  daysForward,
  filterDateTypes
} from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    Dropdown
  },
  props: {
    selectedTab: LoadListTab
  },
  computed: {
    searchCriteria(): LoadSearchCriteriaDto {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadList,
        this.selectedTab.id
      );
    },
    noFilters(): boolean {
      return this.selectedTab.id === spotTenderTabId;
    },
    showResignedCheckbox(): boolean {
      return (
        this.$store.getters[gets.userInfo].actorType === ActorType.Supplier &&
        this.selectedTab.id === LoadStatus.Ordered
      );
    }
  },
  methods: {
    daysBack: daysBack,
    daysForward: daysForward,
    filterDateTypes: filterDateTypes,
    search() {
      this.$emit("search");
    }
  }
});
