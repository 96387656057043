

























import Vue from "vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import DashboardNewsBox from "@/components/web/dashboard/DashboardNewsBox.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import { ApiConfiguration, DashboardClient, NewsDto } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    GreyTitle,
    DashboardNewsBox,
    SkeletonLoader
  },
  methods: {
    init() {
      this.loading = true;
      new DashboardClient(new ApiConfiguration(this.$store))
        .news()
        .then(res => {
          this.boxes = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    loading: boolean;
    boxes: NewsDto[];
  } => ({
    loading: true,
    boxes: []
  })
});
