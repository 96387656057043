











































































































































































































import Vue from "vue";
import { IndexComponents } from "@/views/shared/Indexes.vue";
import money, { percentage } from "@/scripts/misc/money";
import { FuelIndexDto, FuelIndexType } from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  props: {
    from: String,
    to: MomentX,
    indexComponents: Object as () => IndexComponents,
    fuelIndexesForDate: Array as () => FuelIndexDto[]
  },
  computed: {
    dmtSePercentage(): string {
      return percentage(
        this.fuelIndexesForDate.find(
          i => i.type === FuelIndexType.NordicFuelIndex
        )!.factor - 1,
        2
      );
    },
    dmtContPercentage(): string {
      return percentage(
        this.fuelIndexesForDate.find(
          i => i.type === FuelIndexType.ContinentalFuelInternalIndex
        )!.factor - 1,
        2
      );
    },
    dmtVltContPercentage(): string {
      return percentage(
        this.fuelIndexesForDate.find(
          i => i.type === FuelIndexType.ContinentalFuelIndex
        )!.factor - 1,
        2
      );
    }
  },
  methods: {
    money: money,
    percentage: percentage
  }
});
