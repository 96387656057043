



























































import Vue from "vue";
import {
  ApiConfiguration,
  CargoType,
  FileParameter,
  ImportInfoDto,
  ProcurementsSupplierClient
} from "@/scripts/cld.api";
import nextUnique from "@/scripts/misc/nextUnique";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import { alertDialog } from "@/scripts/misc/popupDialogs";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    dialog: Boolean,
    cargoType: Number as () => CargoType
  },
  methods: {
    close() {
      this.$emit("close");
      this.loading = false;
    },
    closeErrorDialog() {
      this.errorDialog = false;
      this.close();
    },
    chooseFile() {
      (this.$refs.fileUpload as any).click();
    },
    importFromExcel(e: any) {
      this.loading = true;
      const uploadedFile = e.target.files[0];
      if (!uploadedFile) {
        return;
      }
      const fileParam: FileParameter = {
        data: uploadedFile,
        fileName: uploadedFile.name
      };
      this.$emit("importStarted");
      new ProcurementsSupplierClient(new ApiConfiguration(this.$store))
        .importFromExcel(fileParam as any)
        .then(res => {
          this.uploadKey = nextUnique();
          this.importInfo = res.importInfo!;
          if (this.cargoType !== this.importInfo.cargoType) {
            alertDialog(t("Error"), t("ExcelImportWrongCargoType"));
            this.$emit("refresh");
            this.close();
            return;
          }
          this.$emit("importFromExcel", res);
          if (this.importInfo.errors!.length > 0) {
            this.errorDialog = true;
          } else {
            this.close();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    uploadKey: number;
    errorDialog: boolean;
    importInfo: ImportInfoDto;
  } => ({
    loading: false,
    uploadKey: nextUnique(),
    errorDialog: false,
    importInfo: new ImportInfoDto({ errors: [], cargoType: undefined })
  })
});
