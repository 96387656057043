

















import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { LoadActionPermission } from "@/scripts/cld.api";
export default Vue.extend({
  props: {
    loading: Boolean
  },
  components: {
    SearchCard
  },
  methods: {
    calendarView() {
      this.$router.push(
        "/slots?action=" + LoadActionPermission.LoadingSlotEdit
      );
    },
    search() {
      this.$emit("search");
    }
  }
});
