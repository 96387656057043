
















































import Vue from "vue";
import {
  UserConfigurationType,
  AnnulledLoadsReportSearchCriteria,
  AnnulledLoadsReportContainerDto
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: AnnulledLoadsReportContainerDto
  },
  computed: {
    searchCriteria(): AnnulledLoadsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AnnulledLoadsReport,
        1
      );
    },
    cargoTypes: actorCargoTypes
  },
  methods: {
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
