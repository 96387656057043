































import Vue from "vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { NotificationNode } from "@/components/web/settings/NotificationSettings.vue";
import NotificationSettingsNode from "@/components/web/settings/NotificationSettingsNode.vue";
import NotificationSettingsFilters from "@/components/web/settings/NotificationSettingsFilters.vue";

export default Vue.extend({
  components: {
    NotificationSettingsFilters,
    CheckboxTiny
  },
  props: {
    node: Object as () => NotificationNode
  },
  computed: {
    expandable(): boolean {
      return this.node.children.length > 0;
    }
  },
  data: (): { self: any } => ({ self: NotificationSettingsNode })
});
