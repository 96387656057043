
































import auth from "@/scripts/auth";
import Vue from "vue";

export default Vue.extend({
  props: {
    size: Number,
    topMargin: Boolean,
    bottomMargin: Boolean,
    smallTopMargin: Boolean,
    smallBottomMargin: Boolean
  },
  computed: {
    color(): string {
      return auth.browsingSupportAdmin() ? "cld-red" : "icon-cyan";
    }
  }
});
