

































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import { t } from "@/scripts/language/i18n";
import { CargoType } from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    DialogCard,
    DatePicker
  },
  props: {
    dialog: Boolean,
    cargoType: Number as () => CargoType
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.fetchInProgress = false;
      }
    }
  },
  computed: {
    dialogDescription(): string {
      return this.cargoType === CargoType.Container
        ? t("FetchHistoricalValuesDescriptionContainer")
        : t("FetchHistoricalValuesDescriptionNonContainer");
    }
  },
  methods: {
    fetch() {
      this.fetchInProgress = true;
      this.$emit("fetch", this.from, this.to);
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    fetchInProgress: boolean;
    from: MomentX;
    to: MomentX;
  } => ({
    fetchInProgress: true,
    from: new MomentX().startOfDay().addYears(-1),
    to: new MomentX().startOfDay()
  })
});
