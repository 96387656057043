













import Vue from "vue";
import { LoadActionPermission } from "@/scripts/cld.api";
import LoadSearchInput from "@/components/web/loads/shared/search/LoadSearchInput.vue";
import LoadDialogs from "@/components/web/loads/shared/LoadDialogs.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    LoadSearchInput,
    LoadDialogs
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 266);
    }
  },
  methods: {
    viewDetails(loadId: number) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.Details.toString(),
          ids: "[" + loadId.toString() + "]"
        }
      });
    }
  }
});
