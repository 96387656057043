

































































































import Vue from "vue";
import NewsSheet from "@/components/shared/ui/NewsSheet.vue";
import DashboardNewsBox from "@/components/web/dashboard/DashboardNewsBox.vue";
import {
  ApiConfiguration,
  Culture,
  NewsClient,
  NewsDto,
  NewsSupportAdminDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { actorTypes, cultures } from "@/scripts/misc/enumLists";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    NewsSheet,
    DashboardNewsBox
  },
  methods: {
    actorTypes(id: number): RepositoryDto[] {
      return actorTypes().filter(t =>
        this.newsById(id).actorTypes!.includes(t.id)
      );
    },
    createNews() {
      this.$router.push("/support-admin/edit-news");
    },
    newsDto(news: NewsSupportAdminDto): NewsDto {
      return new NewsDto({
        summary: news.rows!.find(
          r =>
            r.culture === this.cultures.find(nc => nc.id === news.id)!.culture
        )!.summary,
        body: "",
        displayDate: news.displayDate,
        read: false
      });
    },
    newsById(id: number): NewsSupportAdminDto {
      return this.news.find(n => n.id === id)!;
    },
    cultureIndex(id: number): number {
      const culture = this.cultures.find(nc => nc.id === id)!.culture;
      return this.newsById(id).rows!.findIndex(r => r.culture === culture);
    },
    setCulture(id: number, culture: Culture) {
      this.cultures.find(nc => nc.id === id)!.culture = culture;
    },
    cultureName(culture: Culture): string {
      return cultures().find(c => c.id === culture)!.text!;
    },
    hasEnglish(news: NewsSupportAdminDto): boolean {
      return news.rows!.some(r => r.culture === Culture.English);
    },
    edit(id: number) {
      this.$router.push({
        path: "/support-admin/edit-news",
        query: { id: id.toString() }
      });
    },
    isVisible(news: NewsSupportAdminDto): boolean {
      return (
        news.visibleDate !== undefined && news.visibleDate <= new MomentX()
      );
    },
    publish(dto: NewsSupportAdminDto) {
      popupDialog({
        warning: true,
        title: this.isVisible(dto) ? "Dölj" : "Publicera",
        body: `Är du säker på att du vill ${
          this.isVisible(dto) ? "dölja" : "publicera"
        } den här nyheten?`,
        btnText1: this.isVisible(dto) ? "Dölj" : "Publicera",
        btnColor1: "warning",
        btnCallback1: () => {
          this.actuallyPublish(dto.id);
        }
      });
    },
    actuallyPublish(id: number) {
      this.saveInProgress = true;
      new NewsClient(new ApiConfiguration(this.$store))
        .publish(id)
        .then(() => {
          this.saveInProgress = false;
          this.init();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    remove(id: number) {
      popupDialog({
        warning: true,
        title: "Ta bort",
        body: "Är du säker på att du vill ta bort den här nyheten?",
        btnText1: "Ta bort",
        btnColor1: "error",
        btnCallback1: () => {
          this.actuallyRemove(id);
        }
      });
    },
    actuallyRemove(id: number) {
      this.saveInProgress = true;
      new NewsClient(new ApiConfiguration(this.$store))
        .delete(id)
        .then(() => {
          this.saveInProgress = false;
          this.init();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      new NewsClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.news = res;
          this.cultures = this.news.map(n => ({
            id: n.id,
            culture: n.rows![0].culture
          }));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    saveInProgress: boolean;
    news: NewsSupportAdminDto[];
    cultures: { id: number; culture: Culture }[];
  } => ({
    saveInProgress: false,
    news: [],
    cultures: []
  })
});
