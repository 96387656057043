var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiny-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.slots,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"options":_vm.tableOptions,"custom-group":_vm.customGroup,"disable-pagination":"","hide-default-footer":"","hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"group.header",fn:function(ref){
var items = ref.items;
return [_c('td',{staticClass:"group-header",attrs:{"colspan":"1"}},[_c('SlotDots',{attrs:{"loadId":items[0].loadId}})],1),_c('td',{attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(items[0].loadId))])]),_c('td',{attrs:{"colspan":"6"}},[(items[0].booked)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.openSlotTimeCalender(items[0].loadId)}}},[_vm._v("mdi-calendar-check")]):_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.openSlotTimeCalender(items[0].loadId)}}},[_vm._v("mdi-calendar-blank")])],1),_c('td',{attrs:{"colspan":"1"}},[_c('EllipsisTableItem',{attrs:{"text":items[0].note,"width":"120"}})],1),_c('td',{staticClass:"dont-render-newlines",attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(items[0].deliveryLatest)+" ")])]),_c('td',{attrs:{"colspan":"1"}},[_c('EllipsisTableItem',{attrs:{"text":_vm.routeName(items[0])}})],1),_c('td',{attrs:{"colspan":"1"}},[_c('span',[_vm._v(_vm._s(items[0].truckNo))])]),_c('td',{attrs:{"colspan":"1"}},[_c('EllipsisTableItem',{attrs:{"text":items[0].bookingNo}})],1),_c('td',{attrs:{"colspan":"1"}},[_c('EllipsisTableItem',{attrs:{"text":items[0].transportNo}})],1),_c('td',{attrs:{"colspan":"1"}},[(_vm.isSupplier)?_c('EllipsisTableItem',{attrs:{"text":items[0].clientName}}):_vm._e(),(_vm.isClient)?_c('EllipsisTableItem',{attrs:{"text":items[0].supplierName}}):_vm._e()],1)]}},{key:"item.loadId",fn:function(){return undefined},proxy:true},{key:"item.ton",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertToTon(item.kilograms))+" ")]}},{key:"item.minutes",fn:function(ref){
var item = ref.item;
return [(!item.booked)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('span',[_vm._v(_vm._s(item.minutes))]),_c('span',{staticStyle:{"color":"orange"}},[_vm._v(" *")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Preliminary")))])]):_c('span',[_vm._v(_vm._s(item.minutes))])]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.startDate,"width":"130"}})]}},{key:"item.endDate",fn:function(ref){
var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.endDate,"width":"130"}})]}},{key:"item.note",fn:function(){return undefined},proxy:true},{key:"item.alterDate",fn:function(){return undefined},proxy:true},{key:"item.truckNo",fn:function(){return undefined},proxy:true},{key:"item.bookingNo",fn:function(){return undefined},proxy:true},{key:"item.transportNo",fn:function(){return undefined},proxy:true},{key:"item.supplierName",fn:function(){return undefined},proxy:true},{key:"item.clientName",fn:function(){return undefined},proxy:true}],null,true)}),_c('LoadDialogs',{on:{"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }