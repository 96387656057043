





















































































import Vue from "vue";
import { LoadingDockSchedule, LoadingSlotBox } from "@/scripts/types";
import { LoadingSlotType } from "@/scripts/cld.api";

export default Vue.extend({
  props: {
    disabled: Boolean,
    loading: Boolean,
    readonly: Boolean,
    schedule: Object as () => LoadingDockSchedule,
    secondsPerBox: Number,
    boxStyle: Function,
    boxesNeededForBooking: Number
  },
  watch: {
    anyBoxesHovered() {
      setTimeout(() => {
        if (!this.anyBoxesHovered) {
          this.$root.$emit("confirmTimeTouchCallback", undefined);
        }
      }, 100);
    }
  },
  computed: {
    anyBoxesHovered(): boolean {
      return this.schedule.boxes.filter(b => b.id === -1).some(b => b.hovered);
    }
  },
  methods: {
    highlightedBoxes(box: LoadingSlotBox): LoadingSlotBox[] {
      const index = this.schedule.boxes.indexOf(box);
      const boxes = this.schedule.boxes.slice(
        index,
        index + this.boxesNeededForBooking
      );
      if (boxes.slice(0, 1).some(b => b.type !== LoadingSlotType.Open)) {
        return boxes.slice(0, 1);
      } else if (boxes.some(b => b.type !== LoadingSlotType.Open)) {
        //Collides with a booked slot
        const bookedIndex = boxes.findIndex(
          b => b.type !== LoadingSlotType.Open
        );
        return boxes.slice(0, bookedIndex);
      } else {
        return boxes;
      }
    },
    hover(box: LoadingSlotBox): void {
      const boxes = this.highlightedBoxes(box);
      if (this.readonly) {
        if (
          boxes.some(
            b =>
              b.type === LoadingSlotType.AutoBlocking ||
              b.type === LoadingSlotType.ManualBlocking
          )
        ) {
          this.error = true;
        }
      } else {
        if (
          boxes.length < this.boxesNeededForBooking ||
          boxes.some(b => b.type !== LoadingSlotType.Open)
        ) {
          this.error = true;
        }
      }
      boxes.map(b => {
        b.hovered = true;
      });
    },
    unhover(box: LoadingSlotBox): void {
      this.error = false;
      this.highlightedBoxes(box).map(b => {
        b.hovered = false;
      });
    },
    tooltip(box: LoadingSlotBox): string {
      const boxes = this.highlightedBoxes(box);
      const startDate = boxes[0].startDate.shortTimePrint();
      let endDate = boxes[0].endDate.shortTimePrint();
      if (boxes.length > 1) {
        endDate = boxes[boxes.length - 1].endDate.shortTimePrint();
      }
      let tooltipText = `${startDate} - ${endDate}`;
      if (box.truckNo) {
        tooltipText += `\n ${box.truckNo}`;
      }
      return tooltipText;
    },
    click(e: PointerEvent, box: LoadingSlotBox): void {
      const startDate = this.highlightedBoxes(box)[0].startDate;
      const endDate = this.highlightedBoxes(box).slice(-1)[0].endDate;
      const callback = () => {
        this.$emit(
          "boxClicked",
          e.clientX,
          e.clientY - e.offsetY,
          this.schedule.id,
          startDate,
          endDate,
          box,
          this.error
        );
      };
      if (e.pointerType !== "mouse" && box.id === -1) {
        this.$root.$emit(
          "confirmTimeTouchCallback",
          this.error ? undefined : callback
        );
      } else {
        callback();
      }
    },
    boxClasses(box: LoadingSlotBox): string {
      return [
        box.hovered
          ? this.error
            ? "red"
            : "success"
          : box.type === LoadingSlotType.AutoBlocking
          ? "blocking"
          : box.color,
        box.id === 0 ? "darken-4" : "",
        box.roundedLeft ? "rounded-left" : "",
        box.roundedRight ? "rounded-right" : ""
      ].join(" ");
    }
  },
  data: (): { error: boolean } => ({ error: false })
});
