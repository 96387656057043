





































































































































































































































































import Vue from "vue";
import {
  Container as drop,
  Draggable as drag,
  DropResult
} from "vue-smooth-dnd";
import {
  ApiConfiguration,
  RankingAgreementDto,
  RankingAgreementRowDto,
  RankingTruckTypesDto,
  CargoType,
  RankingType,
  RankingAgreementSaveDto,
  RankingAgreementRowSaveDto,
  TruckType,
  ProcurementsClientClient,
  AgreementsClientClient,
  AgreementLimitingDatesDto,
  QualityPointsClientClient
} from "@/scripts/cld.api";
import {
  popupDialog,
  saveBeforeContinueDialog,
  errorDialog
} from "@/scripts/misc/popupDialogs";
import { ble } from "@/scripts/misc/apiErrors";
import MomentX from "@/scripts/misc/momentX";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Chip from "@/components/shared/ui/Chip.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import RankingDialogPrevAgreement from "@/components/web/agreements/client/RankingDialogPrevAgreement.vue";
import RankingDialogCard from "@/components/web/agreements/client/RankingDialogCard.vue";
import RankingDialogActions from "@/components/web/agreements/client/RankingDialogActions.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { SupplierDeleted } from "@/scripts/types";

export default Vue.extend({
  components: {
    drag,
    drop,
    DialogCard,
    Chip,
    DatePicker,
    NumberInput,
    RankingDialogPrevAgreement,
    RankingDialogCard,
    RankingDialogActions
  },
  props: {
    type: String,
    dialog: Boolean,
    selectedAgreementIds: Array as () => number[],
    selectedAgreementIndex: Number,
    cargoTypeId: Number,
    maxRanking: Number
  },
  watch: {
    selectedAgreementIndex: {
      async handler() {
        this.populate();
      },
      immediate: true
    }
  },
  computed: {
    agreementId(): number {
      return this.selectedAgreementIds[this.selectedAgreementIndex];
    },
    procurementPeriods(): string {
      if (!this.agreement.procurementPeriods) {
        return "";
      }
      return this.agreement.procurementPeriods
        .map(p => `${p.from.datePrint()} - ${p.to.datePrint()}`)
        .join("\n");
    },
    supplierList(): SupplierDeleted[] {
      const suppliers: SupplierDeleted[] = [];
      if (this.agreement.truckTypes === undefined) {
        return suppliers;
      }

      let agreementRows: RankingAgreementRowDto[] = [];
      for (let truckType of this.agreement.truckTypes) {
        agreementRows = agreementRows.concat(truckType.agreementRows!);
      }
      for (let agreementRow of agreementRows) {
        if (
          agreementRow.supplierId !== -1 &&
          !suppliers.filter(e => e.id === agreementRow.supplierId).length
        ) {
          suppliers.push({
            id: agreementRow.supplierId,
            name: agreementRow.supplierName!,
            deleted:
              agreementRows.filter(
                e =>
                  e.supplierId === agreementRow.supplierId && e.ranking !== -1
              ).length === 0
          });
        }
      }
      suppliers.sort((e1, e2) => e1.name.localeCompare(e2.name));
      return suppliers;
    },
    pendingChanges(): boolean {
      return this.rankingState.length > 0;
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    closeDialog() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.saveAgreement(() => {
              this.$emit("closeDialog");
            });
          },
          () => {
            this.$emit("closeDialog");
          }
        );
      } else {
        this.$emit("closeDialog");
      }
    },
    changeAgreement(forward: boolean) {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.saveAgreement(() => {
              this.$emit("changeAgreement", forward);
            });
          },
          () => {
            this.$emit("changeAgreement", forward);
          }
        );
      } else {
        this.$emit("changeAgreement", forward);
      }
    },
    beforeModification() {
      let agreementCopy: RankingAgreementDto = new RankingAgreementDto(
        this.agreement
      );
      agreementCopy.truckTypes = [];
      for (let truckType of this.agreement.truckTypes!) {
        let truckTypeCopy = new RankingTruckTypesDto(truckType);
        agreementCopy.truckTypes.push(truckTypeCopy);
        truckTypeCopy.agreementRows = [];
        for (let agreementRow of truckType.agreementRows!) {
          truckTypeCopy.agreementRows.push(
            new RankingAgreementRowDto(agreementRow)
          );
        }
      }
      this.rankingState.push(agreementCopy);
    },
    afterModification(markManualRankings: boolean) {
      this.respectMaxRanking();
      if (markManualRankings) {
        this.markManualRankings();
      }
    },
    respectMaxRanking() {
      for (let truckType of this.agreement.truckTypes!) {
        for (let agreementRow of truckType.agreementRows!) {
          agreementRow.ranking = Math.min(
            agreementRow.ranking,
            this.maxRanking
          );
        }
      }
    },
    markManualRankings() {
      let prevState = this.rankingState[this.rankingState.length - 1];
      for (let truckType of this.agreement.truckTypes!) {
        let prevTruckType = prevState.truckTypes!.filter(
          tt => tt.id === truckType.id
        )[0];
        for (let agreementRow of truckType.agreementRows!) {
          if (agreementRow.supplierId === -1) {
            continue;
          }
          let prevAgreementRow = prevTruckType.agreementRows!.filter(
            ar => ar.supplierId === agreementRow.supplierId
          )[0];
          if (agreementRow.ranking !== prevAgreementRow.ranking) {
            agreementRow.currentRankingType = RankingType.Manual;
          }
        }
      }
    },
    undo() {
      let agreement = this.rankingState.pop();
      if (agreement !== undefined) {
        this.agreement = agreement;
      }
    },
    removeAgreement() {
      popupDialog({
        title: this.$t("DeleteProcurement").toString(),
        body: this.$t("DeleteProcurementDialogText").toString(),
        btnText1: this.$t("Delete").toString(),
        btnColor1: "error",
        btnCallback1: this.removeAgreementCallback
      });
    },
    removeAgreementCallback() {
      this.loading = true;
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .removeProcurement(this.agreementId)
        .then(res => {
          this.$emit("removeAgreementFromSelection");
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    finishProcurement() {
      popupDialog({
        title: this.$t("CreateAgreementSingular").toString(),
        body: this.$t("CreateAgreementDialogText").toString(),
        btnCallback1: this.finishProcurementCallback
      });
    },
    finishProcurementCallback() {
      this.loading = true;
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .finishProcurement([this.agreementId])
        .then(res => {
          this.$emit("removeAgreementFromSelection");
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.populate();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    saveAgreement(callback?: Function) {
      let agreementRows = this.getAllAgreementRows();
      agreementRows = agreementRows.filter(ar => ar.supplierId !== -1); //Ta inte med tomma kort
      if (agreementRows.some(ar => ar.ranking === -1)) {
        popupDialog({
          title: this.$t("Save").toString(),
          body: this.$t("SaveProcurementText").toString(),
          btnColor1: "error",
          btnCallback1: () => {
            return this.saveAgreementCallback(agreementRows).then(() => {
              if (callback !== undefined) {
                callback();
              }
            });
          }
        });
      } else {
        this.saveAgreementCallback(agreementRows).then(() => {
          if (callback !== undefined) {
            callback();
          }
        });
      }
    },
    async saveAgreementCallback(agreementRows: RankingAgreementRowSaveDto[]) {
      this.loading = true;
      return new AgreementsClientClient(new ApiConfiguration(this.$store))
        .saveRanking(
          new RankingAgreementSaveDto({
            agreementId: this.agreement.id,
            agreementFrom: this.agreement.agreementFrom,
            agreementTo: this.agreement.agreementTo,
            estimatedNoOfLoads: this.agreement.estimatedNoOfLoads,
            agreementRows: agreementRows
          })
        )
        .then(() => {
          this.populate();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    async populate() {
      this.resetDialog();
      if (this.selectedAgreementIndex === -1) {
        return;
      }
      let truckTypesFetched = this.fetchAgreement();
      this.fetchQP(truckTypesFetched);
      this.fetchLimitingDates();
      this.fetchPrevAgreement();
    },
    resetDialog() {
      this.loading = true;
      this.prevAgreementLoading = true;
      this.qualityPointsLoading = true;
      this.agreement = new RankingAgreementDto();
      this.prevAgreement = new RankingAgreementDto();
      this.prevAgreement.truckTypes = [];
      this.rankingState = [];
    },
    async fetchAgreement() {
      return new AgreementsClientClient(new ApiConfiguration(this.$store))
        .byAgreementId(this.agreementId)
        .then(res => {
          this.agreement = res;
          return this.fetchTruckTypes(false);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPrevAgreement() {
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .byPreviousAgreementId(this.agreementId)
        .then(res => {
          this.prevAgreement = res;
          if (res === null) {
            this.prevAgreement = new RankingAgreementDto();
          } else {
            this.fetchTruckTypes(true);
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    async fetchTruckTypes(previous: boolean) {
      return new AgreementsClientClient(new ApiConfiguration(this.$store))
        .trucktypesByAgreementId(
          previous ? this.prevAgreement.id : this.agreement.id,
          null,
          null
        )
        .then(res => {
          if (previous) {
            this.prevAgreement.truckTypes = res;
            this.prevAgreementLoading = false;
          } else {
            this.agreement.truckTypes = res;
            for (let truckType of this.agreement.truckTypes) {
              this._fillRanking(truckType.id, "cards");
            }
            this.loading = false;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchQP(truckTypesFetched: Promise<void>) {
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .byAgreementid(this.agreementId)
        .then(res => {
          truckTypesFetched.then(() => {
            if (this.agreement.truckTypes === undefined) {
              //Kan ske om man är snabb att stänga dialogen
              return;
            }
            for (let truckType of this.agreement.truckTypes!) {
              for (let agreementRow of truckType.agreementRows!) {
                let qp = res.find(
                  r =>
                    r.supplierId === agreementRow.supplierId &&
                    r.truckType === truckType.id
                );
                if (qp !== undefined) {
                  agreementRow.numLoadsOffered = qp.numLoadsOffered;
                  agreementRow.numLoadsLoaded = qp.numLoadsLoaded;
                  agreementRow.qualityPointsRankingType = qp.rankingType;
                  agreementRow.baseParameterPoints = qp.baseParameterPoints;
                  agreementRow.parameterFactor = qp.factor;
                  agreementRow.subjectivePoints = qp.subjectivePoints;
                  agreementRow.pricePoints = qp.pricePoints;
                  agreementRow.factoredParameterPoints =
                    qp.factoredParameterPoints;
                  agreementRow.totalPoints = qp.totalPoints;
                }
              }
            }
            this.qualityPointsLoading = false;
          });
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLimitingDates() {
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .agreementLimitingDates(this.agreementId)
        .then(res => {
          this.limitingDates = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    handleDrop(drop: DropResult) {
      if (drop === null) {
        return; //Kan ske ibland om man är för snabb.
      }
      this.beforeModification();
      let truckTypeId = drop.payload;
      let fromIndex = drop.removedIndex;
      let toIndex = drop.addedIndex;
      let movedRow = this.getAgreementRowByIndex(truckTypeId, fromIndex);
      this._moveAgreementRow(truckTypeId, fromIndex, toIndex);

      let aboveRow = this.getAgreementRowByIndex(truckTypeId, toIndex - 1);
      let belowRow = this.getAgreementRowByIndex(truckTypeId, toIndex + 1);
      let newRanking = -1;
      let cascadeDown = true;
      if (aboveRow === undefined) {
        newRanking = 1;
      } else if (aboveRow.ranking === -1) {
        newRanking = -1;
        cascadeDown = false;
      } else if (
        belowRow === undefined ||
        aboveRow.ranking !== belowRow.ranking
      ) {
        newRanking = aboveRow.ranking + 1;
      } else {
        //Insatt mellan två kort med samma ranking. Även denna får du samma ranking och nedanstående puttas inte ned.
        newRanking = aboveRow.ranking;
        cascadeDown = false;
      }

      let oldRanking = movedRow.ranking;
      if (cascadeDown) {
        let agreementRowsToDown = this.getAgreementRowsByRankingAndBelow(
          truckTypeId,
          newRanking
        );
        this._downRankingCascade(agreementRowsToDown);
      }
      movedRow.ranking = newRanking;
      this._fillRanking(truckTypeId, "compact");
      this.afterModification(true);
    },
    getTruckType(truckTypeId: number) {
      let truckType = this.agreement.truckTypes!.filter(
        tt => tt.id === truckTypeId
      )[0];
      if (truckType === undefined) {
        throw new Error("Could not find trucktype with id " + truckTypeId);
      }
      return truckType;
    },
    getAllAgreementRows() {
      let agreementRows: RankingAgreementRowSaveDto[] = [];
      for (let truckType of this.agreement!.truckTypes!) {
        agreementRows = agreementRows.concat(
          truckType.agreementRows!.map(
            ar =>
              new RankingAgreementRowSaveDto({
                truckTypeId: truckType.id,
                supplierId: ar.supplierId,
                ranking: ar.ranking,
                rankingType: ar.currentRankingType
              })
          )
        );
      }
      return agreementRows;
    },
    getAgreementRowByIndex(truckTypeId: number, index: number) {
      let truckType = this.getTruckType(truckTypeId);
      let agreementRow = truckType.agreementRows![index];
      return agreementRow;
    },
    getAgreementRowByIndexAndBelow(truckTypeId: number, index: number) {
      let truckType = this.getTruckType(truckTypeId);
      let agreementRows = truckType
        .agreementRows!.slice(index)
        .filter(ar => ar.ranking > 0);
      return agreementRows;
    },
    getAgreementRowsByRankingAndBelow(truckTypeId: number, ranking: number) {
      let truckType = this.getTruckType(truckTypeId);
      return truckType.agreementRows!.filter(ar => ar.ranking >= ranking);
    },
    _moveAgreementRow(truckTypeId: number, from: number, to: number) {
      let truckType = this.getTruckType(truckTypeId);
      truckType.agreementRows!.splice(
        to,
        0,
        truckType.agreementRows!.splice(from, 1)[0]
      );
    },
    removeAgreementRow(
      truckTypeId: number,
      index: number,
      compact: boolean = true
    ) {
      this.beforeModification();
      this._removeAgreementRow(truckTypeId, index, compact);
      this.afterModification(true);
    },
    _removeAgreementRow(
      truckTypeId: number,
      index: number,
      compact: boolean = true
    ) {
      let agreementRow = this.getAgreementRowByIndex(truckTypeId, index);
      agreementRow.ranking = -1;
      let truckType = this.getTruckType(truckTypeId);
      let lastIndex = truckType.agreementRows!.length - 1;
      this._moveAgreementRow(truckTypeId, index, lastIndex);

      if (truckType.agreementRows![lastIndex].supplierId === -1) {
        //Om kortet va tomt tar vi bort det helt.
        truckType.agreementRows!.pop();
      }
      if (compact) {
        this._fillRanking(truckTypeId, "compact"); //Tryck ihop om det blev hål.
      }
    },
    removeAllAgreementRows(truckTypeId: number) {
      this.beforeModification();
      if (this.getTruckType(truckTypeId).agreementRows!.length === 0) {
        return;
      }
      while (this.getTruckType(truckTypeId).agreementRows![0].ranking >= 0) {
        this._removeAgreementRow(truckTypeId, 0);
      }
      this.afterModification(true);
    },
    removeSupplier(supplierId: number) {
      this.beforeModification();
      for (let truckType of this.agreement.truckTypes!) {
        let agreementRow = truckType.agreementRows!.find(
          ar => ar.supplierId === supplierId
        );
        if (agreementRow !== undefined) {
          let index = truckType.agreementRows!.indexOf(agreementRow);
          this._removeAgreementRow(truckType.id, index);
        }
      }
      this.afterModification(true);
    },
    restoreAgreementRow(truckTypeId: number, fromIndex: number) {
      this.beforeModification();
      let truckType = this.getTruckType(truckTypeId);
      for (
        let toIndex = truckType.agreementRows!.length - 1;
        toIndex >= 0;
        toIndex--
      ) {
        let ranking = truckType.agreementRows![toIndex].ranking;
        if (ranking > 0 || toIndex === 0) {
          if (toIndex === 0 && ranking <= 0) {
            //Specialare om ingen är rankad.
            ranking = 0;
            toIndex = -1;
          }
          let newRanking = ranking + 1;
          let restoredAgreementRow = this.getAgreementRowByIndex(
            truckTypeId,
            fromIndex
          );
          restoredAgreementRow.ranking = newRanking;
          this._moveAgreementRow(truckTypeId, fromIndex, toIndex + 1);
          break;
        }
      }
      this.afterModification(true);
    },
    rankByPrice(truckTypeId: number | undefined) {
      this.beforeModification();
      let truckTypeIds: number[] = [];
      if (truckTypeId !== undefined) {
        truckTypeIds.push(truckTypeId);
      } else {
        truckTypeIds = truckTypeIds.concat(
          this.agreement.truckTypes!.map(t => t.id)
        );
      }
      for (let id of truckTypeIds) {
        this._rank(id, "price");
      }
      this.afterModification(false);
    },
    rankByQP(truckTypeId: number | undefined) {
      this.beforeModification();
      let truckTypeIds: number[] = [];
      if (truckTypeId !== undefined) {
        truckTypeIds.push(truckTypeId);
      } else {
        truckTypeIds = truckTypeIds.concat(
          this.agreement.truckTypes!.map(t => t.id)
        );
      }
      for (let id of truckTypeIds) {
        this._rank(id, "qp");
      }
      this.afterModification(false);
    },
    _rank(truckTypeId: number, method: string) {
      let truckType = this.getTruckType(truckTypeId);
      let removed = truckType.agreementRows!.filter(ar => ar.ranking === -1);
      let rankable = truckType.agreementRows!.filter(
        ar => ar.ranking >= 0 && ar.supplierId !== -1
      );
      let rowsAndWeight = rankable.map(ar => {
        return {
          ar: ar,
          priceWeight: this.priceWeight(this.cargoTypeId, ar),
          qpWeight: -ar.totalPoints!,
          rankingType:
            method === "qp" ? ar.qualityPointsRankingType : RankingType.Price
        };
      });
      rowsAndWeight.sort((ar1, ar2) => {
        let weight1 = ar1.priceWeight - ar2.priceWeight;
        let weight2 = ar1.qpWeight - ar2.qpWeight;
        let weight3 = Math.random();
        if (method === "qp") {
          [weight1, weight2] = [weight2, weight1];
        }
        return weight1 || weight2 || weight3;
      });
      let ranking = 1;
      for (let raw of rowsAndWeight) {
        raw.ar.ranking = ranking++;
        raw.ar.currentRankingType = raw.rankingType;
      }
      truckType.agreementRows = [
        ...rowsAndWeight.map(raw => raw.ar),
        ...removed
      ];
    },
    priceWeight(cargoTypeId: number, agreementRow: RankingAgreementRowDto) {
      //Viktigt att denna alltid stämmer överäns exakt med backends version av prisranking
      let price = this.ensureGoodValue(agreementRow.freightPrice);
      let serviceFee = this.ensureGoodValue(agreementRow.serviceFee);
      let initialFee = this.ensureGoodValue(agreementRow.initialFee);
      switch (cargoTypeId) {
        case CargoType.Road:
          return price;
        case CargoType.Railroad:
          return price;
        case CargoType.Container:
          return price + serviceFee / 10;
        case CargoType.MachineTransport:
          return price + initialFee / 100;
        default:
          return 0;
      }
    },
    ensureGoodValue(value: number | undefined) {
      if (value === undefined || value === null) {
        return 0;
      } else if (value < 0) {
        this.$store.dispatch(
          actions.handleBasicError,
          "Agreement " +
            this.agreement.id +
            " contained negative value " +
            value
        );
        return 0;
      } else {
        return value;
      }
    },
    _createEmptyRankingCard(ranking: number) {
      return new RankingAgreementRowDto({
        truckType: TruckType.TruckAndWagon,
        supplierId: -1,
        supplierName: Math.random()
          .toString(36)
          .substring(2, 12),
        basePrice: -1,
        unitBasePrice: -1,
        freightPrice: -1,
        stopPrice: undefined,
        serviceFee: undefined,
        initialFee: undefined,
        share: 0,
        note: undefined,
        ranking: ranking,
        totalPoints: undefined,
        currentRankingType: RankingType.Manual,
        qualityPointsRankingType: RankingType.Manual,
        numLoadsLoaded: 0,
        numLoadsOffered: 0,
        baseParameterPoints: undefined,
        parameterFactor: undefined,
        factoredParameterPoints: undefined,
        subjectivePoints: undefined,
        pricePoints: undefined,
        indexFactor: 0,
        baseIndexName: undefined
      });
    },
    _removeUnnecessaryEmptyCards(truckTypeId: number) {
      let truckType = this.getTruckType(truckTypeId);
      for (let i = 0; i < truckType.agreementRows!.length; i++) {
        let agreementRow = truckType.agreementRows![i];
        if (
          agreementRow.supplierId === -1 &&
          truckType.agreementRows!.filter(
            ar => ar.ranking === agreementRow.ranking
          ).length > 1
        ) {
          this._removeAgreementRow(truckTypeId, i, false);
          this._removeUnnecessaryEmptyCards(truckTypeId);
          break;
        }
      }
    },
    _fillRanking(truckTypeId: number, strategy: string) {
      this._removeUnnecessaryEmptyCards(truckTypeId);
      let truckType = this.getTruckType(truckTypeId);
      let prevRanking = 0;
      for (let i = 0; i < truckType.agreementRows!.length; i++) {
        let ranking = truckType.agreementRows![i].ranking;
        if (ranking > prevRanking + 1) {
          if (strategy === "cards") {
            truckType.agreementRows!.splice(
              i,
              0,
              this._createEmptyRankingCard(prevRanking + 1)
            );
          } else if (strategy === "compact") {
            let agreementRowsToUp = this.getAgreementRowsByRankingAndBelow(
              truckTypeId,
              ranking
            );
            this._upRankingCascade(agreementRowsToUp);
          }
          this._fillRanking(truckTypeId, strategy);
          break;
        } else {
          prevRanking = ranking;
        }
      }
    },
    _upRankingCascade(agreementRows: RankingAgreementRowDto[]) {
      for (let agreementRow of agreementRows) {
        if (agreementRow.ranking > 1) {
          agreementRow.ranking--;
        }
      }
    },
    _downRankingCascade(agreementRows: RankingAgreementRowDto[]) {
      for (let agreementRow of agreementRows) {
        agreementRow.ranking++;
      }
    },
    explode(truckTypeId: number, index: number) {
      this.beforeModification();
      let agreementRows = this.getAgreementRowByIndexAndBelow(
        truckTypeId,
        index
      );
      this._downRankingCascade(agreementRows);
      this._fillRanking(truckTypeId, "cards");
      this.afterModification(true);
    },
    implode(truckTypeId: number, index: number) {
      this.beforeModification();
      if (index > 0) {
        let cardRanking = this.getAgreementRowByIndex(truckTypeId, index)
          .ranking;
        let aboveRanking = this.getAgreementRowByIndex(truckTypeId, index - 1)
          .ranking;
        if (cardRanking === aboveRanking && cardRanking !== -1) {
          //Inget ska ske om de redan har samma ranking
          return;
        }
      }
      let agreementRows = this.getAgreementRowByIndexAndBelow(
        truckTypeId,
        index
      );
      this._upRankingCascade(agreementRows);
      this._fillRanking(truckTypeId, "cards");
      this.afterModification(true);
    },
    secondaryValue(agreementRow: RankingAgreementRowDto) {
      let value: number | undefined;
      switch (this.cargoTypeId) {
        case CargoType.Road:
          value = agreementRow.stopPrice;
          break;
        case CargoType.Railroad:
          value = undefined;
          break;
        case CargoType.Container:
          value = agreementRow.serviceFee;
          break;
        case CargoType.MachineTransport:
          value = agreementRow.initialFee;
          break;
      }
      if (value) {
        return "(" + value + ")";
      } else {
        return "";
      }
    },
    validateDateForm() {
      this.$nextTick(() => {
        let form: any = this.$refs.form;
        form.validate();
      });
    }
  },
  data: (): {
    loading: boolean;
    prevAgreementLoading: boolean;
    qualityPointsLoading: boolean;
    datesValid: boolean;
    agreement: RankingAgreementDto;
    prevAgreement: RankingAgreementDto;
    limitingDates: AgreementLimitingDatesDto;
    rankingState: RankingAgreementDto[];
  } => ({
    loading: true,
    prevAgreementLoading: true,
    qualityPointsLoading: true,
    datesValid: true,
    agreement: new RankingAgreementDto(),
    prevAgreement: new RankingAgreementDto(),
    limitingDates: new AgreementLimitingDatesDto({
      prevAgreementEnd: MomentX.minDate(),
      nextAgreementStart: MomentX.maxDate()
    }),
    rankingState: []
  })
});
