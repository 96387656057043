











































































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  AgreementRouteDistanceSearchCriteria,
  AgreementRouteDistanceOrderBy,
  AgreementRouteDistanceContainerDto,
  AgreementRouteDistanceDto,
  Country,
  RoutingExactness
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { gets } from "@/scripts/store/constants";
import { UpdatingRoute } from "@/views/support-admin/Distance.vue";
import {
  cargoTypeName,
  isoName,
  routingExactnessName
} from "@/scripts/misc/enumNames";
import money from "@/scripts/misc/money";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    CheckboxTiny
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    routesContainer: AgreementRouteDistanceContainerDto,
    toggled: Array as () => number[],
    updating: Array as () => UpdatingRoute[]
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 250);
    },
    searchCriteria(): AgreementRouteDistanceSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.Distance,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    allToggled(): boolean | undefined {
      if (this.toggled.length === 0) {
        return false;
      }
      if (this.toggled.length === this.routesContainer.rows!.length) {
        return true;
      }
      return undefined;
    }
  },
  methods: {
    money: money,
    cargoTypeName: cargoTypeName,
    itemClass(r: AgreementRouteDistanceDto): string {
      const route = this.updating.find(u => u.id === r.id);
      if (!route) {
        return "";
      }
      return route.color;
    },
    locationName(county: string, zipCode: string, country: Country): string {
      return `${county}, ${zipCode}, ${isoName(country)}`;
    },
    search() {
      this.$emit("search");
    },
    toggle(id: number) {
      this.$emit("toggle", id);
    },
    toggleAll() {
      this.$emit("toggleAll", this.allToggled);
    },
    routingExactnessName(e: RoutingExactness | undefined) {
      if (e === RoutingExactness.Fail) {
        return "FAILED";
      }
      return routingExactnessName(e ? e : RoutingExactness.Unknown);
    },
    oldDistance(id: number): number | undefined {
      const route = this.updating.find(u => u.id === id);
      if (route) {
        return route.distance;
      }
      return undefined;
    }
  },
  data: (): { headers: TableHeader[] } => ({
    headers: [
      {
        value: "action",
        text: "",
        width: 1,
        untranslated: true
      },
      {
        value: "hidden",
        text: "Hidden",
        width: 85
      },
      {
        value: "numAgreements",
        text: "#Avtal",
        sortValue: AgreementRouteDistanceOrderBy.NumAgreements,
        width: 1,
        untranslated: true
      },
      {
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: AgreementRouteDistanceOrderBy.PickupLocation
      },
      {
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: AgreementRouteDistanceOrderBy.DeliveryLocation
      },
      {
        value: "clientName",
        text: "Client",
        sortValue: AgreementRouteDistanceOrderBy.ClientName
      },
      {
        value: "cargoType",
        text: "CargoType"
      },
      {
        value: "distance",
        text: "Distance",
        sortValue: AgreementRouteDistanceOrderBy.Distance,
        width: 100
      },
      {
        value: "oldDistance",
        text: "Tidigare avstånd",
        untranslated: true,
        width: 1
      },
      {
        value: "routingExactnessPickup",
        text: "Exakthet hämt.",
        untranslated: true,
        width: 200
      },
      {
        value: "routingExactnessDelivery",
        text: "Exakthet lev.",
        untranslated: true,
        width: 200
      }
    ]
  })
});
