var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogCard',{attrs:{"dialog":_vm.dialog,"title":_vm.$t('Agreements'),"width":900},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredRoutes,"height":620,"items-per-page":20,"footer-props":{
        'items-per-page-text': _vm.$t('Quantity') + ':',
        'items-per-page-all-text': _vm.$t('MobileAll'),
        'items-per-page-options': [20],
        'page-text': _vm.$t('ShowingItemsXofY')
      },"mobile-breakpoint":"0","disable-sort":"","hide-default-header":"","fixed-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('PickupLocation')},model:{value:(_vm.pickupFilter),callback:function ($$v) {_vm.pickupFilter=$$v},expression:"pickupFilter"}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('DeliveryLocation')},model:{value:(_vm.deliveryFilter),callback:function ($$v) {_vm.deliveryFilter=$$v},expression:"deliveryFilter"}})]},proxy:true}],null,true)})]}},{key:"item.from",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.from.datePrint()))])]}},{key:"item.to",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.to.datePrint()))])]}}],null,true)})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }