



































































import Vue from "vue";
import { ActorType, DirectMessageDto } from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import MessageRecipientDialog from "@/components/web/messages/MessageRecipientDialog.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    CustomTableHeader,
    MessageRecipientDialog,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    selectedId: Number,
    messages: Array as () => DirectMessageDto[]
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 188);
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [];
      if (this.actorType === ActorType.Client) {
        headers.push({
          value: "recipient",
          text: "",
          width: 40
        });
        headers.push({
          value: "regLoadUserName",
          text: "User"
        });
      } else if (this.actorType === ActorType.Supplier) {
        headers.push({
          value: "clientName",
          text: "Client"
        });
      }
      headers.push({
        value: "subject",
        text: "Subject"
      });
      headers.push({
        value: "regDate",
        text: "Date",
        width: 138
      });
      return headers;
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    clicked(row: DirectMessageDto) {
      this.$emit("messageClicked", row.id);
    }
  },
  data: (): { recipients: string[]; tableOptions: TableOptions } => ({
    recipients: [],
    tableOptions: new TableOptions({
      itemsPerPage: 50,
      sortBy: ["regDate"],
      sortDesc: [true]
    })
  })
});
