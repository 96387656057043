




import Vue from "vue";
import { Chart, ChartData, ChartItem, registerables, Title } from "chart.js";
Chart.register(...registerables);

export default Vue.extend({
  props: {
    chartData: Object as () => ChartData<"bar", number[], string>
  },
  watch: {
    chartData() {
      this.key++;
      this.$nextTick(() => {
        this.render();
      });
    }
  },
  methods: {
    render() {
      const ctx: ChartItem = document.getElementById("myChart") as any;
      this.chartData.datasets.map(d => {
        (d.backgroundColor = [
          "#c62107",
          "#4d4d4d",
          "#dfd5bb",
          "#65c8e6",
          "#ffa500"
        ]),
          (d.borderColor = [
            "#c62107",
            "#4d4d4d",
            "#dfd5bb",
            "#65c8e6",
            "#ffa500"
          ]),
          (d.borderWidth = 1);
      });
      const myChart = new Chart(ctx, {
        type: "bar",
        data: this.chartData,
        options: {
          plugins: {
            legend: {
              display: false
            }
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true
              }
            },
            y: {
              display: true,
              title: {
                display: true
              }
            }
          }
        }
      });
    }
  },
  data: (): { key: number } => ({ key: 1 })
});
