import { localeCode } from "@/scripts/language/i18n";

export default (
  v: number | undefined | null,
  decimals: number,
  shorterIfPossible: boolean = false
): string => {
  if (v === undefined || v === null) {
    return "";
  }
  const formatter = new Intl.NumberFormat(localeCode(), {
    maximumFractionDigits: decimals,
    minimumFractionDigits: shorterIfPossible ? 0 : decimals
  });
  let res = formatter.format(v);
  if (localeCode() === "en-US") {
    res = res.replaceAll(",", " ");
  }
  return res;
};

export const percentage = (
  factor: number | undefined | null,
  decimals: number
): string => {
  if (factor === undefined || factor === null) {
    return "";
  }
  let res = `${(factor * 100).toLocaleString(localeCode(), {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })}%`;
  if (localeCode() === "en-US") {
    res = res.replaceAll(",", " ");
  }
  return res;
};
