


























import { LoadClientEditSubLocations, CargoType } from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import AccordionSubLocationsTable from "@/components/web/loads/client/accordion/AccordionSubLocationsTable.vue";
import Vue from "vue";
export default Vue.extend({
  props: {
    agreementRouteId: Number,
    cargoType: Number as () => CargoType,
    agreementDate: MomentX,
    subLocations: Object as () => LoadClientEditSubLocations
  },
  components: {
    AccordionSubLocationsTable
  }
});
