



































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  AgreementStatisticsReportSearchCriteria,
  AgreementStatisticsReportContainerDto,
  AgreementStatisticsReportOrderBy,
  ActorType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    showPercentage: Boolean,
    reportContainer: AgreementStatisticsReportContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 252);
    },
    searchCriteria(): AgreementStatisticsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementStatisticsReport,
        1
      );
    },
    isClient(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    valueHeaders(): string[] {
      const excluded = [
        "agreementPeriod",
        "pickupLocation",
        "deliveryLocation",
        "truckType",
        "supplierName",
        "clientName"
      ];
      return this.headers.map(h => h.value).filter(h => !excluded.includes(h));
    },
    headers(): TableHeader[] {
      const p = this.showPercentage;
      const headers: TableHeader[] = [];
      headers.push({
        value: "agreementPeriod",
        text: "AgreementPeriod",
        sortValue: AgreementStatisticsReportOrderBy.AgreementPeriod,
        width: 180
      });
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: AgreementStatisticsReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: AgreementStatisticsReportOrderBy.DeliveryLocation
      });
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: AgreementStatisticsReportOrderBy.TruckType
      });
      headers.push({
        value: this.isClient ? "supplierName" : "clientName",
        text: this.isClient ? "Supplier" : "Client",
        sortValue: AgreementStatisticsReportOrderBy.Actor
      });

      headers.push({
        value: "rank",
        text: "Ranking",
        sortValue: AgreementStatisticsReportOrderBy.Rank
      });
      headers.push({
        value: "loadsTotal",
        text: "NumberOfLoads",
        tooltip: "NumberOfLoadsOnTheRoute",
        sortValue: AgreementStatisticsReportOrderBy.LoadsTotal
      });
      headers.push({
        value: p ? "shareOffered" : "offered",
        text: "LoadStatisticsOfferedLong",
        sortValue: p
          ? AgreementStatisticsReportOrderBy.ShareOffered
          : AgreementStatisticsReportOrderBy.Offered
      });
      headers.push({
        value: p ? "shareBooked" : "booked",
        text: "LoadStatisticsBookedLong",
        sortValue: p
          ? AgreementStatisticsReportOrderBy.ShareBooked
          : AgreementStatisticsReportOrderBy.Booked
      });
      return headers;
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    truckTypeName: truckTypeName,
    search() {
      this.$emit("search");
    },
    totalValue(h: string): string {
      const val = (this.reportContainer.total as any)[h] as number;
      if (h === "rank") {
        return "";
      }
      return this.showPercentage && h !== "loadsTotal"
        ? percentage(val, 0)
        : money(val, 0);
    }
  }
});
