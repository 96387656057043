import { Module } from "vuex";
import { RootState } from "@/scripts/store/store";
import Auth from "@/scripts/auth";
import { actions, gets, mutations } from "@/scripts/store/constants";

export interface AuthState {
  username: String;
  token: String;
  pathBeforeAuthentication: String;
  autoRenewing: boolean;
}

const state: AuthState = {
  username: "",
  token: "",
  pathBeforeAuthentication: "",
  autoRenewing: false
};

export const authModule: Module<AuthState, RootState> = {
  state: state,
  getters: {
    [gets.username]: (state): String => {
      return state.username;
    },
    [gets.token]: (state): String => {
      return state.token;
    },
    [gets.pathBeforeAuthentication]: (state): String => {
      return state.pathBeforeAuthentication;
    },
    [gets.autoRenewing]: (state): boolean => {
      return state.autoRenewing;
    }
  },
  mutations: {
    [mutations.setUsername]: (state, username: string) => {
      state.username = username;
    },
    [mutations.setToken]: (state, token: string) => {
      state.token = token;
    },
    [mutations.setPathBeforeAuthentication]: (state, path: string) => {
      state.pathBeforeAuthentication = path;
    },
    [mutations.setAutoRenewing]: (state, value: boolean) => {
      state.autoRenewing = value;
    }
  },
  actions: {
    [actions.autoTokenRenew]: async (
      context,
      pathBeforeAuthentication: string
    ) => {
      Auth.getUser()
        .then(user => {
          let msUntilNextCheck = 2000;
          if (user === null || user!.expires_in < 15) {
            context.dispatch(
              actions.startSigninSilent,
              pathBeforeAuthentication
            );
            msUntilNextCheck = 10000;
          } else {
            context.commit(
              mutations.setUsername,
              user.profile.preferred_username
            );
            context.commit(mutations.setToken, user.id_token);
          }
          let browsingRoute = "/";
          if (Auth.browsingMobile()) {
            browsingRoute = "/mobile";
          }
          if (Auth.browsingSupportAdmin()) {
            browsingRoute = "/support-admin";
          }
          setTimeout(
            () => context.dispatch(actions.autoTokenRenew, browsingRoute),
            msUntilNextCheck
          );
        })
        .catch(error => {
          context.dispatch(actions.handleLoginError, error);
        });
    },
    [actions.startSignin]: async (
      context,
      pathBeforeAuthentication: string
    ) => {
      return Auth.startSigninMainWindow(pathBeforeAuthentication).catch(
        error => {
          context.dispatch(actions.handleLoginError, error);
        }
      );
    },
    [actions.endSignin]: async context => {
      return Auth.endSigninMainWindow()
        .then(user => {
          context.commit(
            mutations.setPathBeforeAuthentication,
            user.state.pathBeforeAuthentication
          );
          context.commit(
            mutations.setUsername,
            user.profile.preferred_username
          );
          context.commit(mutations.setToken, user.id_token);
          if (!context.getters[gets.autoRenewing]) {
            context.commit(mutations.setAutoRenewing, true);
            let browsingRoute = "/";
            if (Auth.browsingMobile()) {
              browsingRoute = "/mobile";
            }
            if (Auth.browsingSupportAdmin()) {
              browsingRoute = "/support-admin";
            }
            context.dispatch(actions.autoTokenRenew, browsingRoute);
          }
        })
        .catch(error => {
          context.dispatch(actions.handleLoginError, error);
        });
    },
    [actions.startSignout]: async (context, path: string) => {
      return Auth.startSignoutMainWindow(path).catch(error => {
        context.dispatch(actions.handleLoginError, error);
      });
    },
    [actions.endSignout]: async context => {
      return Auth.endSignoutMainWindow()
        .then(res => {
          context.commit(mutations.setUsername, "");
          context.commit(mutations.setToken, "");
          context.commit(
            "setPathBeforeAuthentication",
            res.state.pathBeforeAuthentication
          );
        })
        .catch(error => {
          context.dispatch(actions.handleLoginError, error);
        });
    },
    [actions.startSigninSilent]: (
      context,
      pathBeforeAuthentication: string
    ) => {
      Auth.startSigninSilent()
        .then(user => {
          context.commit(
            mutations.setUsername,
            user.profile.preferred_username
          );
          context.commit(mutations.setToken, user.id_token);
        })
        .catch(error => {
          if (error !== undefined && error.error === "login_required") {
            context.dispatch(actions.startSignin, pathBeforeAuthentication);
          } else {
            context.dispatch(actions.handleLoginError, error);
          }
        });
    },
    [actions.endSigninSilent]: async context => {
      return Auth.endSigninSilent().catch(error => {
        context.dispatch(actions.handleLoginError, error);
      });
    }
  }
};
