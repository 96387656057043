




































import Vue from "vue";

export default Vue.extend({
  props: {
    alternative: Boolean
  }
});
