
















































































































import Vue from "vue";
import {
  CreateSpotTenderDto,
  DocumentGroupDto,
  RepositoryDto
} from "@/scripts/cld.api";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    GreyTitle,
    TextMulti,
    DocumentDropdown,
    ReadOnlyText
  },
  props: {
    saveDto: CreateSpotTenderDto,
    suppliers: Array as () => RepositoryDto[],
    documentGroups: Array as () => DocumentGroupDto[]
  },
  computed: {
    endDateInfo(): string {
      return new MomentX().shortDateTimePrintWithTo(
        this.saveDto.endDate,
        localeCode()
      );
    },
    truckTypeNames(): string {
      return this.saveDto.truckTypes!.map(tt => truckTypeName(tt)).join(", ");
    }
  }
});
