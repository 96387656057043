


































































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { ActorType, LoadDetailsDatesDto } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    values: LoadDetailsDatesDto,
    supplierRank: Number
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    nextVisible(): string {
      if (this.values.visibleToNextSupplier !== undefined) {
        if (new MomentX().isSameOrAfter(this.values.visibleToNextSupplier!)) {
          return this.$t("AlreadyVisible").toString();
        } else {
          return this.values.visibleToNextSupplier! === undefined
            ? ""
            : this.values.visibleToNextSupplier!.shortDateTimePrint();
        }
      }
      return "";
    },
    loadingSlotsText(): string {
      let text = "";
      this.values.loadingSlots!.forEach(x => {
        text +=
          x.loadingDockName +
          "\n" +
          x.startTime.shortDateTimePrintSeparated() +
          " - " +
          x.endTime.shortDateTimePrintSeparated() +
          "\n";
      });
      return text;
    }
  }
});
