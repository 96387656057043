var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"item-key":"loadId","custom-group":_vm.customGroup,"group-by":"oviktigt","disable-pagination":"","hide-default-header":"","hide-default-footer":"","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"group.header",fn:function(ref){
var group = ref.group;
return [_c('td',{staticClass:"font-weight-bold group-header",attrs:{"colspan":"99"}},[_vm._v(" "+_vm._s(group)+" ")])]}},{key:"item.loadStatus",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.loadStatusName(item.loadStatus))+" ")]}},{key:"item.loadedDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('loadedDate', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.loadedDate))+" ")])]}},{key:"item.bookedPickupDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('bookedPickupDate', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.bookedPickupDate))+" ")])]}},{key:"item.pickupLatest",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('pickupLatest', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.pickupLatest))+" ")])]}},{key:"item.pickupEarliest",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('pickupEarliest', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.pickupEarliest))+" ")])]}},{key:"item.deliveryDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('deliveryDate', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.deliveryDate))+" ")])]}},{key:"item.bookedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('bookedDeliveryDate', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.bookedDeliveryDate))+" ")])]}},{key:"item.deliveryLatest",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.dateStyling('deliveryLatest', item)},[_vm._v(" "+_vm._s(_vm.presentDate(item.deliveryLatest))+" ")])]}},{key:"item.truckType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.truckTypeName(item.truckType))+" ")]}},(_vm.editable)?{key:"item.remove",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20"},on:{"click":function($event){return _vm.remove(item)}}},on),[_vm._v(" mdi-close ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("Remove")))])])]}}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }