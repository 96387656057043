

















import Vue from "vue";
import { AgreementRouteForProcurementDto, CargoType } from "@/scripts/cld.api";
import CreateProcurementAgreementRoutesTable from "@/components/web/agreements/client/CreateProcurementAgreementRoutesTable.vue";

export default Vue.extend({
  props: {
    loading: Boolean,
    cargoType: Number as () => CargoType,
    agreementRouteIds: Array as () => number[],
    agreementRoutes: Array as () => AgreementRouteForProcurementDto[],
    height: Number
  },
  components: {
    CreateProcurementAgreementRoutesTable
  },
  watch: {
    agreementRouteIds: {
      handler() {
        this.$emit(
          "change",
          this.agreementRouteIds.length > 0,
          this.agreementRouteIds.length
        );
      },
      immediate: true
    }
  }
});
