













































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    label: String,
    value: [String, Number, Boolean, Object],
    clickable: Boolean
  },
  computed: {
    displayValue(): string | undefined {
      if (this.value === true) {
        return t("Survey_Yes");
      }
      if (this.value === false) {
        return t("Survey_No");
      }
      if (!this.value) {
        return undefined;
      }
      if (MomentX.isMoment(this.value)) {
        return (this.value as MomentX).shortDateTimePrint();
      }
      return this.value.toString();
    }
  },
  data: (): {
    menu: boolean;
  } => ({ menu: false })
});
