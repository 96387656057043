














































import Vue from "vue";
import { SupplierProcurementRowDto } from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    item: SupplierProcurementRowDto
  },
  computed: {
    dateAsText(): string {
      const now = new MomentX();
      let currentPeriod = this.item.procurementPeriods!.find(
        p => p.from <= now && p.to >= now
      );
      if (currentPeriod === undefined) {
        currentPeriod = this.item
          .procurementPeriods!.filter(p => p.to <= now)
          .sort((a, b) => b.to.unix() - a.to.unix())[0];
      }
      return currentPeriod!.to.shortDateTextPrint(localeCode());
    },
    agreementDate(): string {
      return `${this.item.agreementPeriod!.from.datePrint()} - ${this.item.agreementPeriod!.to.datePrint()}`;
    },
    procurementDates(): string {
      return this.item
        .procurementPeriods!.map(
          p => `${p.from.datePrint()} - ${p.to.datePrint()}`
        )
        .join("\n");
    }
  }
});
