





















import Vue from "vue";
import SurveyQuestion from "@/components/web/survey/SurveyQuestion.vue";
import { SurveyAnswerRowDtoXGroup } from "@/scripts/misc/surveyQuestions";

export default Vue.extend({
  components: {
    SurveyQuestion
  },
  props: {
    readonly: Boolean,
    answerGroups: Array as () => SurveyAnswerRowDtoXGroup[]
  }
});
