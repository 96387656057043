


















































































import Vue from "vue";
import {
  ApiConfiguration,
  SurveySupplierClient,
  SurveyDto,
  SurveyAnswerRowDto,
  SurveySubmissionSaveDto
} from "@/scripts/cld.api";
import {
  getAnswerGroups,
  SurveyAnswerRowDtoX,
  SurveyAnswerRowDtoXGroup
} from "@/scripts/misc/surveyQuestions";
import {
  popupDialog,
  saveBeforeContinueDialog
} from "@/scripts/misc/popupDialogs";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ContentContainer from "@/components/shared/ui/ContentContainer.vue";
import SurveyContent from "@/components/web/survey/SurveyContent.vue";
import SubmitSurveyDialog from "@/components/web/survey/supplier/SubmitSurveyDialog.vue";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    Spinner,
    SurveyContent,
    ContentContainer,
    SubmitSurveyDialog
  },
  computed: {
    numInvalidAnswers(): number {
      return this.answerRowsX.filter(a => a.valid === false).length;
    },
    readonly(): boolean {
      return this.$route.query.readonly === "true";
    },
    answerRowsX(): SurveyAnswerRowDtoX[] {
      let answers: SurveyAnswerRowDtoX[] = [];
      for (let group of this.answerGroups) {
        answers = answers.concat(group.answers);
      }
      return answers;
    }
  },
  methods: {
    progress(questionId: number): number {
      return (
        100 *
        this.answerRowsX.filter(a => a.questionId === questionId && a.valid)
          .length
      );
    },
    goBack() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(() => {
          return this.saveCallback().then(() => {
            this.goBackCallback();
          });
        }, this.goBackCallback);
      } else {
        this.goBackCallback();
      }
    },
    goBackCallback() {
      this.$router.push({
        path: "/surveys"
      });
    },
    submitSurvey() {
      this.submission.completed = true;
      this.saveCallback().then(() => {
        this.submitSurveyDialog = false;
        this.$router.push("/surveys");
      });
    },
    save() {
      popupDialog({
        title: this.$t("Survey_Save").toString(),
        body: this.$t("Survey_SaveText").toString(),
        btnText1: this.$t("Save").toString(),
        btnColor1: "success",
        btnCallback1: this.saveCallback
      });
    },
    saveCallback() {
      this.submission.surveyAnswerRows = this.answerRowsX
        .filter(r => r.valid)
        .map(
          r =>
            new SurveyAnswerRowDto({
              questionId: r.questionId,
              selectedOption: r.selectedOption,
              text: r.text
            })
        );
      this.pendingChanges = false;
      return new SurveySupplierClient(new ApiConfiguration(this.$store))
        .submit(this.submission)
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  async mounted() {
    const surveyId = Number(this.$route.query.surveyId);
    await new SurveySupplierClient(new ApiConfiguration(this.$store))
      .surveys()
      .then(res => {
        this.survey = res.filter(s => s.id === surveyId)[0];
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    const submissionId = Number(this.$route.query.submissionId);
    if (!isNaN(submissionId)) {
      new SurveySupplierClient(new ApiConfiguration(this.$store))
        .submission(submissionId)
        .then(res => {
          this.submission = res;
          this.answerGroups = getAnswerGroups(
            res.surveyAnswerRows!,
            this.survey.questionGroups!
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    } else {
      this.submission = new SurveySubmissionSaveDto({
        survey: surveyId,
        respondentName: "",
        respondentRole: "",
        completed: false,
        surveyAnswerRows: undefined
      });
      this.answerGroups = getAnswerGroups([], this.survey.questionGroups!);
      this.loading = false;
    }
  },
  data: (): {
    loading: boolean;
    pendingChanges: boolean;
    submitSurveyDialog: boolean;
    survey: SurveyDto;
    submission: SurveySubmissionSaveDto;
    answerGroups: SurveyAnswerRowDtoXGroup[];
  } => ({
    loading: true,
    pendingChanges: false,
    submitSurveyDialog: false,
    survey: new SurveyDto(),
    submission: new SurveySubmissionSaveDto(),
    answerGroups: []
  })
});
