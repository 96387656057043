






















































































































import Vue from "vue";
import {
  Country,
  CountyWithZipcodeDto,
  CargoType,
  RepositoryDto,
  SubLocationDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import cached from "@/scripts/misc/cached";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import AddressCombobox from "@/components/shared/input/AddressCombobox.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

interface CountryLocation {
  country: Country;
  locations: CountyWithZipcodeDto[];
  locationsConcated: RepositoryDto[];
}

export default Vue.extend({
  components: {
    Autocomplete,
    Dropdown,
    AddressCombobox,
    GreyTitle
  },
  props: {
    pickup: Boolean,
    shortVersion: Boolean,
    agreementRouteId: Number,
    cargoType: Number as () => CargoType,
    agreementDate: MomentX,
    subLocations: Array as () => SubLocationDto[]
  },
  methods: {
    locationTextById(locationId: number, country: Country): string | undefined {
      const countryLocation = this.countryLocation(country);
      const location = countryLocation.locationsConcated.find(
        l => l.id === locationId
      );
      if (location !== undefined) {
        return location.text;
      }
      return;
    },
    locationSpecifics(
      locationId: number,
      country: Country
    ): { county?: string; zipcode?: string } {
      const countryLocation = this.countryLocation(country);
      const location = countryLocation.locations.find(l => l.id === locationId);
      if (location !== undefined) {
        return { county: location.county, zipcode: location.zipcode };
      }
      return {};
    },
    countryLocation(country: Country): CountryLocation {
      let countryLocation:
        | CountryLocation
        | undefined = this.countryLocations.find(cl => cl.country === country);
      if (countryLocation === undefined) {
        countryLocation = {
          country: country,
          locations: [],
          locationsConcated: []
        };
        this.countryLocations.push(countryLocation);
        this.fetchLocations(country);
      }
      return countryLocation;
    },
    changeZipcode(zipcode: string, subLocation: SubLocationDto) {
      const countryLocation = this.countryLocation(subLocation.country);
      const currentLocation = countryLocation.locations.find(
        l => l.id === subLocation.locationId
      );
      const newLocation = countryLocation!.locations.find(
        l => l.zipcode === zipcode
      );
      if (
        newLocation &&
        (!currentLocation || newLocation.zipcode !== currentLocation.zipcode)
      ) {
        subLocation.locationId = newLocation.id;
      }
    },
    fetchLocations(country: Country) {
      cached("countiesAndZipcode", country)
        .then(res => {
          const countryLocation = this.countryLocations.find(
            cl => cl.country === country
          )!;
          countryLocation.locations = res;
          countryLocation.locationsConcated = res.map(
            x =>
              new RepositoryDto({
                id: x.id,
                text: `${x.county}, ${x.zipcode}`
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchCountries();
  },
  data: (): {
    countryLocations: CountryLocation[];
    countries: RepositoryDto[];
  } => ({
    countryLocations: [],
    countries: []
  })
});
