


























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  QualityPointsOrderBy,
  QualityPointsSearchCriteriaContainer,
  QualityPointsSearchCriteria,
  QualityPointsContainerDto,
  QualityPointsSupplierClient,
  QualityPointsClientClient,
  ActorType
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import QualityPointsReportSearch from "@/components/web/reports/shared/QualityPointsReportSearch.vue";
import QualityPointsReportTable from "@/components/web/reports/shared/QualityPointsReportTable.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    QualityPointsReportSearch,
    QualityPointsReportTable
  },
  computed: {
    sorting(): { orderBy: QualityPointsOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as QualityPointsOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): QualityPointsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.QualityPoints,
        1
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    apiClient(): QualityPointsClientClient | QualityPointsSupplierClient {
      if (this.actorType === ActorType.Client) {
        return new QualityPointsClientClient(new ApiConfiguration(this.$store));
      }
      return new QualityPointsSupplierClient(new ApiConfiguration(this.$store));
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.QualityPoints,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [QualityPointsOrderBy.PickupLocation];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.fromDate || !this.searchCriteria.toDate) {
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      this.apiClient
        .report(
          new QualityPointsSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.QualityPoints,
        subTypeId: 1
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      this.apiClient
        .exportToExcel(
          new QualityPointsSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    exportLoading: boolean;
    tableOptions: TableOptions;
    reportContainer: QualityPointsContainerDto;
  } => ({
    loading: 0,
    exportLoading: false,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [QualityPointsOrderBy.PickupLocation],
      sortDesc: [false]
    }),
    reportContainer: new QualityPointsContainerDto({
      rows: [],
      count: 0
    })
  })
});
