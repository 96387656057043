





































































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import FileInput from "@/components/shared/input/FileInput.vue";
import CreateMessageRecipientDialog from "@/components/web/messages/CreateMessageRecipientDialog.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import DocumentDropZone from "@/components/web/documents/DocumentDropZone.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import {
  ApiConfiguration,
  CreateDirectMessageDto,
  DirectMessagingClient,
  MessageType,
  FileParameter
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { messageTypes } from "@/scripts/misc/enumLists";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";
import AccordionDocumentAddDialog, {
  DocumentIdDiskNameDisplayName
} from "@/components/web/loads/client/accordion/AccordionDocumentAddDialog.vue";

export default Vue.extend({
  components: {
    DialogCard,
    Dropdown,
    TextSingle,
    TextMulti,
    FileInput,
    CreateMessageRecipientDialog,
    EllipsisTableItem,
    InfoTooltip,
    AccordionDocumentAddDialog,
    DocumentDropZone
  },
  props: {
    dialog: Boolean
  },
  watch: {
    dialog() {
      if (this.dialog) {
        this.sendInProgress = false;
        this.uploadInProgress = false;
        this.documents = [];
        this.saveDto = new CreateDirectMessageDto({
          subject: undefined,
          body: undefined,
          documentIds: [],
          supplierIds: [],
          messageType: MessageType.General
        });
      }
    },
    "saveDto.messageType": {
      handler() {
        this.fetchSuppliersWithNotifications();
      },
      immediate: true
    }
  },
  methods: {
    messageTypes: messageTypes,
    addDocument(document: DocumentIdDiskNameDisplayName) {
      this.documents.push(document);
    },
    removeDocument(id: number) {
      this.documents = this.documents.filter(d => d.id !== id);
    },
    send() {
      this.sendInProgress = true;
      this.saveDto.documentIds = this.documents.map(d => d.id);
      new DirectMessagingClient(new ApiConfiguration(this.$store))
        .create(this.saveDto)
        .then(() => {
          this.sendInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    viewDocument(id: number) {
      const document = this.documents!.find(d => d.id === id)!;
      viewDocument(document.diskName, undefined);
    },
    fetchSuppliersWithNotifications() {
      new DirectMessagingClient(new ApiConfiguration(this.$store))
        .actorsWithSubscribingRecipients(this.saveDto.messageType)
        .then(res => {
          this.subscribedSuppliers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    valid: boolean;
    sendInProgress: boolean;
    uploadInProgress: boolean;
    recipientDialog: boolean;
    uploadedFile: FileParameter | undefined;
    saveDto: CreateDirectMessageDto;
    documents: DocumentIdDiskNameDisplayName[];
    subscribedSuppliers: number[];
  } => ({
    valid: false,
    sendInProgress: false,
    uploadInProgress: false,
    recipientDialog: false,
    uploadedFile: undefined,
    saveDto: new CreateDirectMessageDto({
      subject: undefined,
      body: undefined,
      documentIds: [],
      supplierIds: [],
      messageType: MessageType.General
    }),
    documents: [],
    subscribedSuppliers: []
  })
});
