















































import Vue from "vue";
import {
  ApiConfiguration,
  DashboardClient,
  LoadStatus,
  SpotTenderDashboardDto
} from "@/scripts/cld.api";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import { actions } from "@/scripts/store/constants";
import { spotTenderTabId } from "@/scripts/types";
import { loadStatusIcon } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    GreyTitle,
    SkeletonLoader
  },
  computed: {
    icon(): string {
      return loadStatusIcon(LoadStatus.Ordered, true);
    }
  },
  methods: {
    init() {
      this.loading = true;
      new DashboardClient(new ApiConfiguration(this.$store))
        .spotTenders()
        .then(res => {
          this.spotTenders = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    routeToSpotTenders() {
      this.$router.push("/loads?tab=" + spotTenderTabId);
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    loading: boolean;
    spotTenders: SpotTenderDashboardDto[];
  } => ({
    loading: true,
    spotTenders: []
  })
});
