var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding-top",attrs:{"elevation":"2","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sortedRows,"height":_vm.tableHeight,"loading":_vm.loading,"options":_vm.tableOptions,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [100, 500, -1],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.actorType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'actorType' },"placeholder":_vm.$t('ActorType')},model:{value:(_vm.filter.actorType),callback:function ($$v) {_vm.$set(_vm.filter, "actorType", $$v)},expression:"filter.actorType"}})]},proxy:true},{key:"filter.actorId",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"Id"},model:{value:(_vm.filter.actorId),callback:function ($$v) {_vm.$set(_vm.filter, "actorId", $$v)},expression:"filter.actorId"}})]},proxy:true},{key:"filter.actorName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Actor')},model:{value:(_vm.filter.actorName),callback:function ($$v) {_vm.$set(_vm.filter, "actorName", $$v)},expression:"filter.actorName"}})]},proxy:true},{key:"filter.invoiceRecipient",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"Mottagare"},model:{value:(_vm.filter.invoiceRecipient),callback:function ($$v) {_vm.$set(_vm.filter, "invoiceRecipient", $$v)},expression:"filter.invoiceRecipient"}})]},proxy:true},{key:"filter.feeType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'feeType' },"placeholder":"Typ"},model:{value:(_vm.filter.feeType),callback:function ($$v) {_vm.$set(_vm.filter, "feeType", $$v)},expression:"filter.feeType"}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},model:{value:(_vm.filter.currency),callback:function ($$v) {_vm.$set(_vm.filter, "currency", $$v)},expression:"filter.currency"}})]},proxy:true}],null,true)})]}},{key:"item.loadIds",fn:function(ref){
    var item = ref.item;
return [(item.loadIds.length > 0)?_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.selectedRow = item}}},[_c('v-icon',[_vm._v(" mdi-truck ")])],1):_vm._e()]}},{key:"item.actorType",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.actorTypeName(item.actorType))+" ")])]}},{key:"item.actorId",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.actorId))])]}},{key:"item.actorName",fn:function(ref){
    var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"width":"140","text":item.actorName}})]}},{key:"item.invoiceRecipient",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.invoiceRecipientName(item)))])]}},{key:"item.unit",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"item.feeType",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.feeTypeName(item.feeType))+" ")])]}},{key:"item.discountText",fn:function(ref){
    var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"width":"110","text":item.discountText}})]}},{key:"item.period",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.fromDate.datePrint())+" - "+_vm._s(item.toDate.datePrint())+" ")])]}},{key:"item.numberOfItems",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.numberOfItems))])]}},{key:"item.priceWithDiscount",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines"},on),[_vm._v(" "+_vm._s(_vm.money(item.priceWithDiscount, 2))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(item.price)+" - "+_vm._s(item.discount)+" ")])]}},{key:"item.total",fn:function(ref){
    var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines"},on),[_vm._v(" "+_vm._s(_vm.money(item.priceWithDiscount * item.numberOfItems, 2))+" ")])]}}],null,true)},[_vm._v(" ("+_vm._s(item.price)+" - "+_vm._s(item.discount)+") * "+_vm._s(item.numberOfItems)+" ")])]}},{key:"item.currency",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}}],null,true)}),_c('InvoiceGeneratorLoadIdsDialog',{attrs:{"row":_vm.selectedRow},on:{"close":function($event){_vm.selectedRow = undefined}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }