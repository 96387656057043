var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.reportContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.reportContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.feeType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'feeType' },"placeholder":_vm.$t('Type')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.feeType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "feeType", $$v)},expression:"searchCriteria.feeType"}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currency),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currency", $$v)},expression:"searchCriteria.currency"}})]},proxy:true},{key:"filter.unit",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"Fakt. Enhet"},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.unit),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "unit", $$v)},expression:"searchCriteria.unit"}})]},proxy:true},{key:"filter.loadId",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('LoadID')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.loadId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "loadId", $$v)},expression:"searchCriteria.loadId"}})]},proxy:true},{key:"filter.transportNo",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('TransportNo')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.transportNo),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "transportNo", $$v)},expression:"searchCriteria.transportNo"}})]},proxy:true},{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true}],null,true)})]}},{key:"item.feeType",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.feeTypeName(item.feeType))+" ")])]}},{key:"item.priceTotal",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.price(item))+" ")])]}},{key:"item.currency",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}},{key:"item.fromDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.fromDate.datePrint())+" ")])]}},{key:"item.toDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.toDate.datePrint())+" ")])]}},{key:"item.unit",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.unit)+" ")])]}},{key:"item.pickupLocation",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.pickup(item),"width":"190"}})]}},{key:"item.deliveryLocation",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.delivery(item),"width":"210"}})]}},{key:"item.loadedDate",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.loadedDate)+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }