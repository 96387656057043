import { Module } from "vuex";
import {
  ActorType,
  ApiConfiguration,
  ActorConfigurationDto,
  ActorConfigurationType,
  ActorsClient
} from "@/scripts/cld.api";
import { RootState } from "@/scripts/store/store";
import { mutations, actions, gets } from "@/scripts/store/constants";
import createActorConf from "@/scripts/store/createActorConf";
import neverHappens from "@/scripts/misc/neverHappens";

export interface ActorConfigurationEntry {
  type: ActorConfigurationType;
  subTypeId: number;
  conf: Object;
}

export interface ActorConfigurationState {
  confs: ActorConfigurationEntry[];
}

const state: ActorConfigurationState = {
  confs: []
};

export const actorConfModule: Module<ActorConfigurationState, RootState> = {
  state: state,
  getters: {
    [gets.actorConf]: state => {
      return (type: ActorConfigurationType, subTypeId: number): Object => {
        return state.confs.find(
          c => c.type === type && c.subTypeId === subTypeId
        )!.conf;
      };
    },
    [gets.allActorConfs]: state => {
      return (type: ActorConfigurationType): ActorConfigurationEntry[] => {
        return [...state.confs.filter(c => c.type === type)].sort(
          (a, b) => a.subTypeId - b.subTypeId
        );
      };
    }
  },
  mutations: {
    [mutations.setActorConf]: (state, conf: ActorConfigurationEntry) => {
      state.confs = [
        conf,
        ...state.confs.filter(
          c => c.type !== conf.type || c.subTypeId !== conf.subTypeId
        )
      ];
    },
    [mutations.emptyActorConf]: state => {
      state.confs = [];
    }
  },
  actions: {
    [actions.resetActorConf]: (
      context,
      data: {
        type: ActorConfigurationType;
        subTypeId: number;
        actorType: ActorType;
      }
    ) => {
      const conf = createActorConf(data.type, data.subTypeId, data.actorType);
      context.commit(mutations.setActorConf, conf);
    },
    [actions.initActorConf]: (
      context,
      data: { actorType: ActorType; confs: ActorConfigurationDto[] }
    ) => {
      context.commit(mutations.emptyActorConf);
      const confs: ActorConfigurationEntry[] = [];
      Object.values(ActorConfigurationType)
        .filter(t => !isNaN(Number(t)))
        .map(t => t as ActorConfigurationType)
        .map(t => {
          switch (t) {
            case ActorConfigurationType.ZipcodeByCounty:
              confs.push(createActorConf(t, 1, data.actorType));
              break;
            case ActorConfigurationType.MegaPresets:
              break;
            default:
              neverHappens(t);
          }
        });
      for (const conf of confs) {
        context.commit(mutations.setActorConf, conf);
      }
      return new Promise<void>(resolve => {
        for (const conf of data.confs) {
          context.commit(
            mutations.setActorConf,
            createActorConf(
              conf.type,
              conf.subTypeId,
              data.actorType,
              conf.configuration
            )
          );
        }
        resolve();
      });
    },
    [actions.saveActorConf]: (
      context,
      data: { type: ActorConfigurationType; subTypeId: number }
    ) => {
      const configuration = context.getters[gets.actorConf](
        data.type,
        data.subTypeId
      );
      return new ActorsClient(new ApiConfiguration(context))
        .actorConfiguration(
          new ActorConfigurationDto({
            type: data.type,
            subTypeId: data.subTypeId,
            configuration: JSON.stringify(configuration)
          })
        )
        .then(() => {})
        .catch(error => {
          context.dispatch(actions.handleApiError, error);
        });
    },
    [actions.deleteActorConf]: (
      context,
      data: { type: ActorConfigurationType; subTypeId: number }
    ) => {
      return new ActorsClient(new ApiConfiguration(context))
        .deleteActorConfiguration(
          new ActorConfigurationDto({
            type: data.type,
            subTypeId: data.subTypeId,
            configuration: undefined
          })
        )
        .then(() => {})
        .catch(error => {
          context.dispatch(actions.handleApiError, error);
        });
    }
  }
};
