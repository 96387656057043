



























































import Vue from "vue";
import {
  ApiConfiguration,
  CargoType,
  CostForecastRankMethod,
  CostForecastSearchCriteria,
  CostForecatReportSearchCriteriaContainer,
  ReportsClient
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { actions } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import { cargoTypes, costForecastRankMethods } from "@/scripts/misc/enumLists";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";

const createNewCritiera = (): CostForecatReportSearchCriteriaContainer =>
  new CostForecatReportSearchCriteriaContainer({
    searchCriteria: new CostForecastSearchCriteria({
      costForecastRankMethod: CostForecastRankMethod.All,
      cargoType: CargoType.Road,
      agreementDate: new MomentX()
    }),
    itemsPerPage: 200,
    pageNumber: 0
  });

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    InfoTooltip
  },
  props: {
    dialog: Boolean
  },
  methods: {
    cargoTypes: cargoTypes,
    costForecastRankMethods: costForecastRankMethods,
    clearSelection() {
      this.criteria = createNewCritiera();
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .costForecastExportToExcel(this.criteria)
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            // TODO: Ta bort errorObject när vi har en fungerande lösning där felet hämtas in ordentligt
            const errorObject = {
              response: {
                errors: {
                  "The export to excel failed because there was nothing to export.": null
                }
              }
            };
            //TODO: Ersätt errorObject med error när lösning finns
            errorDialog(errorObject, () => {
              this.exportLoading = false;
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    exportLoading: boolean;
    criteria: CostForecatReportSearchCriteriaContainer;
  } => ({
    exportLoading: false,
    criteria: createNewCritiera()
  })
});
