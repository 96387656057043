





















































































































































import Vue from "vue";
import {
  ActorDto,
  ActorOrgNoDto,
  ActorsClient,
  ActorType,
  ApiConfiguration,
  CargoType,
  RepositoryDto
} from "@/scripts/cld.api";
import cached from "@/scripts/misc/cached";
import { actions } from "@/scripts/store/constants";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import objectsEqual from "@/scripts/misc/objectsEqual";
import { cargoTypes } from "@/scripts/misc/enumLists";
import { validEmail } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  props: {
    actor: ActorDto,
    actorOriginal: Object as () => ActorDto | undefined
  },
  components: {
    TextSingle,
    TextMulti,
    DropdownMulti,
    Autocomplete,
    ReadOnlyText,
    InfoTooltip
  },
  mounted() {
    this.fetchCountries();
    this.fetchClients();
    this.fetchOrgNumbers();
  },
  watch: {
    validChanges: {
      handler() {
        this.$emit("validChanges", this.validChanges);
      },
      immediate: true
    },
    actor: {
      handler() {
        //Validera direkt för att fel sedan längesen ska highlightas
        this.$nextTick(() => {
          let form: any = this.$refs.editForm;
          form.validate();
        });
      },
      immediate: true
    }
  },
  computed: {
    isSupplier(): boolean {
      return this.actor.actorType === ActorType.Supplier;
    },
    isCustomer(): boolean {
      return this.actor.actorType === ActorType.Customer;
    },
    validChanges(): boolean {
      return !objectsEqual(this.actor, this.actorOriginal) && this.valid;
    }
  },
  methods: {
    validEmail: validEmail,
    orgNoRules(orgNo: string | undefined): string | boolean {
      if (!orgNo || !/^[A-Z0-9]+$/.test(orgNo)) {
        return "Felaktigt format";
      }
      const match = this.orgNumbers.find(
        n =>
          n.actorId !== this.actor.id &&
          n.orgNo &&
          n.orgNo.toLocaleUpperCase() === orgNo
      );
      if (match) {
        return `För likt ${match.orgNo} (${match.actorName})`;
      }
      return true;
    },
    contractAddressRules(a: string): string | boolean {
      if (!a) {
        return "Obligatorisk";
      }
      const parts = a.split(",");
      if (parts.length !== 2) {
        return "Ska innehålla ett komma";
      }
      if (parts.some(p => p.length <= 3)) {
        return "Nånting är för kort";
      }
      if (!parts[1].startsWith(" ")) {
        return "Ska ha mellanslag efter komma";
      }
      if (
        !parts[1].substring(1).includes(" ") ||
        !parts[1]
          .substring(1)
          .split(" ")
          .every(p => p.length > 0)
      ) {
        return "Ska innehålla adress, postnummer och postort";
      }
      if (parts[1].toLocaleUpperCase() !== parts[1]) {
        return "Postkod och postort får inte ha små bokstäver";
      }
      return true;
    },
    cargoTypeRules(cargoTypes: CargoType[]): boolean | string {
      if (cargoTypes.length === 0) {
        return "Obligatorisk";
      } else if (
        this.actorOriginal &&
        this.actorOriginal.cargoTypes!.some(c => !cargoTypes.includes(c))
      ) {
        return "Fraktsätt får inte tas bort";
      }
      return true;
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .clientsForSupportAdmin(false)
        .then(res => {
          this.clients = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchOrgNumbers() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .orgNo()
        .then(res => {
          this.orgNumbers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    valid: boolean;
    countries: RepositoryDto[];
    cargoTypes: RepositoryDto[];
    clients: RepositoryDto[];
    orgNumbers: ActorOrgNoDto[];
  } => ({
    valid: false,
    countries: [],
    cargoTypes: cargoTypes(),
    clients: [],
    orgNumbers: []
  })
});
