















































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import SearchCard from "@/components/shared/ui/SearchCard.vue";

export default Vue.extend({
  components: {
    SearchCard
  },
  props: {
    originalValue: Object as () => MomentX | MomentX.Duration,
    moreMinutes: Boolean,
    updateOnClose: Boolean,
    timeOk: (Function as any) as () => { (date: MomentX): boolean }
  },
  watch: {
    originalValue: {
      handler() {
        if ((this.originalValue as MomentX).datePrint !== undefined) {
          this.date = this.originalValue as MomentX;
        } else {
          this.date = new MomentX()
            .startOfDay()
            .withHours(this.originalValue.hours())
            .withMinutes(this.originalValue.minutes());
        }
      },
      immediate: true
    }
  },
  computed: {
    hours(): number {
      return this.date ? this.date.hours() : NaN;
    },
    minutes(): number {
      return this.date ? this.date.minutes() : NaN;
    },
    hourOptions(): string[] {
      return Array.from(Array(24).keys()).map((_, index) => index.toString());
    },
    minuteOptions(): string[] {
      return Array.from(
        Array(this.moreMinutes ? 12 : 4).keys()
      ).map((_, index) =>
        (index * (this.moreMinutes ? 5 : 15)).toString().padStart(2, "0")
      );
    }
  },
  methods: {
    hourClass(h: string): string {
      const hour = parseInt(h);
      const date = this.date ? this.date : new MomentX().startOfDay();
      const hourMinutes = this.minuteOptions.map(m =>
        date.withHours(hour).withMinutes(Number(m))
      );
      return [
        this.hours === hour ? "cyan-background" : "",
        hourMinutes.some(hm => this.timeOk(hm))
          ? ""
          : "red-border red-background"
      ].join(" ");
    },
    minuteClass(m: string): string {
      const minute = parseInt(m);
      const date = this.date ? this.date : new MomentX().startOfDay();
      return [
        this.minutes === minute ? "cyan-background" : "",
        this.timeOk(date.withHours(this.hours).withMinutes(minute))
          ? ""
          : "red-border red-background"
      ].join(" ");
    },
    setHours(h: string) {
      if (!this.date) {
        this.date = new MomentX().startOfDay();
      }
      this.date = this.date.withHours(parseInt(h));
      if (!this.updateOnClose) {
        this.$emit("input", this.date);
      }
    },
    setMinutes(m: string) {
      if (!this.date) {
        this.date = new MomentX().startOfDay();
      }
      this.date = this.date.withMinutes(parseInt(m));
      if (!this.updateOnClose) {
        this.$emit("input", this.date);
      }
      this.close();
    },
    close() {
      if (this.updateOnClose) {
        this.$emit("input", this.date ? this.date : this.originalValue);
      }
      this.$emit("close");
    }
  },
  data: (): { date?: MomentX } => ({
    date: undefined
  })
});
