

























import Vue from "vue";
import DashboardLoadsBox from "@/components/web/dashboard/DashboardLoadsBox.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import {
  ApiConfiguration,
  DashboardClient,
  LoadDashboardDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DashboardLoadsBox,
    SkeletonLoader,
    GreyTitle
  },
  methods: {
    init() {
      this.loading = true;
      new DashboardClient(new ApiConfiguration(this.$store))
        .relatedLoads()
        .then(res => {
          this.boxes = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    loading: boolean;
    boxes: LoadDashboardDto[];
  } => ({
    loading: true,
    boxes: []
  })
});
