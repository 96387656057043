






























import Vue from "vue";
import {
  ApiConfiguration,
  SurveyClientClient,
  SurveyDto,
  SurveySubmissionDto
} from "@/scripts/cld.api";
import {
  getAnswerGroups,
  SurveyAnswerRowDtoXGroup
} from "@/scripts/misc/surveyQuestions";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import SurveyContent from "@/components/web/survey/SurveyContent.vue";

export default Vue.extend({
  components: {
    DialogCard,
    SurveyContent
  },
  props: {
    supplierId: Number
  },
  watch: {
    supplierId() {
      if (this.supplierId !== undefined) {
        this.populate();
      }
    }
  },
  computed: {
    title(): string {
      const supplier = this.submission
        ? this.submission.supplierName + " -"
        : "";
      const iteration = this.survey ? this.survey.iteration : "";
      const text = this.$t("Survey_SustainabilitySurvey").toString();
      return `${supplier} ${text} ${iteration}`;
    }
  },
  methods: {
    populate() {
      this.loading = true;
      new SurveyClientClient(new ApiConfiguration(this.$store))
        .latestSubmission(this.supplierId)
        .then(res => {
          this.submission = res;
          return;
        })
        .then(() => {
          return new SurveyClientClient(
            new ApiConfiguration(this.$store)
          ).surveys();
        })
        .then(res => {
          this.survey = res.find(s => s.id === this.submission!.survey)!;
          this.answerGroups = getAnswerGroups(
            this.submission!.surveyAnswerRows!,
            this.survey.questionGroups!
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
      this.submission = undefined;
      this.survey = undefined;
      this.answerGroups = [];
    }
  },
  data: (): {
    loading: boolean;
    submission?: SurveySubmissionDto;
    survey?: SurveyDto;
    answerGroups: SurveyAnswerRowDtoXGroup[];
  } => ({
    loading: true,
    submission: undefined,
    survey: undefined,
    answerGroups: []
  })
});
