















































































import Vue from "vue";
import { LoadDetailsBaseValuesDto } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { t } from "@/scripts/language/i18n";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    values: LoadDetailsBaseValuesDto,
    ordered: Boolean
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    priceText(): String {
      if (this.ordered) {
        return t("JustPreliminaryPrice").toString();
      } else {
        return t("Price").toString();
      }
    }
  },
  methods: {
    truckTypeName: truckTypeName
  }
});
