







































import Vue from "vue";
import {
  RepositoryClient,
  ApiConfiguration,
  ActorType,
  RepositoryDto,
  ActorsClient
} from "@/scripts/cld.api";
import ExcludedActorsCard from "@/components/web/settings/ExcludedActorsCard.vue";
import { actions, gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    ExcludedActorsCard
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    includedActors(): RepositoryDto[] {
      return this.actors.filter(a => !this.excludedActorIds.includes(a.id));
    },
    excludedActors(): RepositoryDto[] {
      return this.actors.filter(a => this.excludedActorIds.includes(a.id));
    },
    unchanged(): boolean {
      return (
        this.excludedActorIdsOriginal.every(id =>
          this.excludedActorIds.includes(id)
        ) &&
        this.excludedActorIds.every(id =>
          this.excludedActorIdsOriginal.includes(id)
        )
      );
    }
  },
  methods: {
    include(id: number) {
      this.excludedActorIds = this.excludedActorIds.filter(e => e !== id);
    },
    exclude(id: number) {
      this.excludedActorIds.push(id);
    },
    fetchAllActors() {
      const api = new RepositoryClient(new ApiConfiguration(this.$store));
      const call =
        this.actorType === ActorType.Supplier
          ? api.clients(true, true, false)
          : api.suppliers(null, true, true);
      call
        .then(res => {
          this.actors = res;
          this.fetchExcludedActors();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchExcludedActors() {
      const api = new RepositoryClient(new ApiConfiguration(this.$store));
      const call =
        this.actorType === ActorType.Supplier
          ? api.clients(false, true, false)
          : api.suppliers(null, false, true);
      call
        .then(res => {
          this.excludedActorIds = this.actors
            .map(a => a.id)
            .filter(id => !res.map(r => r.id).includes(id));
          this.excludedActorIdsOriginal = [...this.excludedActorIds];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .saveExcludedActors(this.excludedActorIds)
        .then(() => {
          this.saveInProgress = false;
          this.excludedActorIdsOriginal = [...this.excludedActorIds];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchAllActors();
  },
  data: (): {
    saveInProgress: boolean;
    actors: RepositoryDto[];
    excludedActorIds: number[];
    excludedActorIdsOriginal: number[];
    includedFilter: string;
    excludedFilter: string;
  } => ({
    saveInProgress: false,
    actors: [],
    excludedActorIds: [],
    excludedActorIdsOriginal: [],
    includedFilter: "",
    excludedFilter: ""
  })
});
