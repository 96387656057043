
















































































































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    windowStart: MomentX,
    windowEnd: MomentX,
    secondsPerBox: Number,
    numSchedules: Number,
    boxStyle: String
  },
  computed: {
    numBoxes(): number {
      return (
        (this.windowEnd.unix() - this.windowStart.unix()) / this.secondsPerBox
      );
    },
    locale(): string {
      return localeCode();
    },
    currentTimeBox(): number {
      const nowish = new MomentX().loadingSlotNowish();
      const diff = nowish.unix() - this.windowStart.unix();
      const boxNum = diff / 300;
      return boxNum;
    }
  },
  methods: {
    time(x: number): string | undefined {
      if (x % 12 === 0) {
        return this.windowStart
          .addSeconds(x * this.secondsPerBox)
          .shortTimePrint();
      }
      return;
    },
    changeDay(change: number) {
      this.$emit("changeDate", change * 3600 * 24);
    },
    changeHour(change: number) {
      this.$emit("changeDate", change * 3600);
    }
  }
});
