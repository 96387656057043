


























































































import Vue from "vue";
import {
  UserConfigurationType,
  ClientMonitoringSearchCriteria,
  ClientMonitoringContainerDto,
  ClientMoitoringReportSumsDto,
  CargoType
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";
import money, { percentage } from "@/scripts/misc/money";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    ReadOnlyText
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: ClientMonitoringContainerDto
  },
  computed: {
    searchCriteria(): ClientMonitoringSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientMonitoring,
        1
      );
    },
    sums(): ClientMoitoringReportSumsDto {
      return this.reportContainer.sums!;
    },
    cargoTypes: actorCargoTypes,
    isRoad(): boolean {
      return this.searchCriteria.cargoType === CargoType.Road;
    }
  },
  methods: {
    money: money,
    sum(num: number, share: number): string {
      if (num === undefined) {
        return "";
      }
      if (num === 0) {
        return "0 (0%)";
      }
      return `${money(num, 0)} (${percentage(share, 0)})`;
    },
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
