














































import Vue from "vue";
import { RelatedLoadSummaryDto } from "@/scripts/cld.api";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";

export default Vue.extend({
  components: {
    EllipsisTableItem
  },
  props: {
    items: Array as () => RelatedLoadSummaryDto[]
  }
});
