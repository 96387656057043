





















































































































































import Vue from "vue";
import {
  UserConfigurationType,
  InvoicedFeesSearchCriteria,
  ActorType,
  InvoicedFeesOrderBy,
  InvoicedFeesContainerDto,
  InvoicedFeeRowDto,
  Country
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { currencyName, feeTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";
import { isoName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: InvoicedFeesContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 250);
    },
    searchCriteria(): InvoicedFeesSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoicedFees,
        1
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "feeType",
        text: "Type",
        width: 1,
        sortValue: InvoicedFeesOrderBy.FeeType
      });
      headers.push({
        value: "priceTotal",
        text: "Price",
        width: 1,
        sortValue: InvoicedFeesOrderBy.Price
      });
      headers.push({
        value: "currency",
        text: "Currency",
        width: 1,
        sortValue: InvoicedFeesOrderBy.Currency
      });
      headers.push({
        value: "fromDate",
        text: "FromDate",
        width: 1,
        sortValue: InvoicedFeesOrderBy.FromDate
      });
      headers.push({
        value: "toDate",
        text: "ToDate",
        width: 1,
        sortValue: InvoicedFeesOrderBy.ToDate
      });
      headers.push({
        value: "unit",
        text: "Fakt. Enhet",
        untranslated: true,
        width: 1,
        sortValue: InvoicedFeesOrderBy.Unit
      });
      headers.push({
        value: "loadId",
        text: "LoadID",
        width: 1,
        sortValue: InvoicedFeesOrderBy.LoadId
      });
      headers.push({
        value: "transportNo",
        text: "TransportNo",
        width: 1,
        sortValue: InvoicedFeesOrderBy.TransportNo
      });
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        width: 1,
        sortValue: InvoicedFeesOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        width: 1,
        sortValue: InvoicedFeesOrderBy.DeliveryLocation
      });
      headers.push({
        value: "loadedDate",
        text: "LoadedDate",
        width: 1,
        sortValue: InvoicedFeesOrderBy.LoadedDate
      });

      return headers;
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    feeTypeName: feeTypeName,
    pickup(item: InvoicedFeeRowDto): string {
      if (!item.pickupCounty) {
        return "N/A";
      }
      return (
        item.pickupCounty +
        ", " +
        item.pickupZipCode +
        ", " +
        this.countryIso(item.pickupCountry)
      );
    },
    delivery(item: InvoicedFeeRowDto): string {
      if (!item.deliveryCounty) {
        return "N/A";
      }
      return (
        item.deliveryCounty +
        ", " +
        item.deliveryZipCode +
        ", " +
        this.countryIso(item.deliveryCountry)
      );
    },
    countryIso(country: Country | undefined): string {
      if (country) {
        return `${isoName(country)}`;
      }
      return "";
    },
    price(item: InvoicedFeeRowDto): string {
      if (item.numberOfItems > 1) {
        return (
          item.priceTotalWithDiscount +
          " " +
          "(" +
          item.numberOfItems +
          " * " +
          item.priceEach +
          ")"
        );
      }

      return item.priceTotalWithDiscount.toString();
    },
    search() {
      this.$emit("search");
    }
  }
});
