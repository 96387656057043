


































































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  DeviationReportSearchCriteria,
  DeviationReportContainerDto,
  DeviationReportOrderBy,
  DeviationReportRowDto,
  LoadActionPermission
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import {
  currencyName,
  deviationCauseName,
  otherPriceCauseName,
  truckTypeName
} from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: DeviationReportContainerDto
  },
  computed: {
    tableHeight(): number {
      const numCurrencies = this.reportContainer.sums!.length;
      return Math.max(
        400,
        this.$store.getters[gets.dimensions].height - 250 - 52 * numCurrencies
      );
    },
    searchCriteria(): DeviationReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DeviationReport,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "loadInfo",
        text: "",
        untranslated: true
      });
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: DeviationReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: DeviationReportOrderBy.DeliveryLocation
      });
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: DeviationReportOrderBy.TruckType
      });
      headers.push({
        value: "deviationCause",
        text: "DeviationCause",
        sortValue: DeviationReportOrderBy.DeviationCause
      });
      headers.push({
        value: "otherPriceCause",
        text: "OtherPriceCause",
        sortValue: DeviationReportOrderBy.OtherPriceCause
      });
      headers.push({
        value: "agreementSupplier",
        text: "TotalPriceByAgreement"
      });
      headers.push({
        value: "supplier",
        text: "Supplier"
      });
      headers.push({
        value: "topRankedSupplier",
        text: "TopRankedSupplier"
      });
      headers.push({
        value: "priceDifference",
        text: "Difference",
        sortValue: DeviationReportOrderBy.PriceDifference
      });
      headers.push({
        value: "currency",
        text: "Currency",
        sortValue: DeviationReportOrderBy.Currency
      });
      headers.push({
        value: "bookingDate",
        text: "BookingDate",
        sortValue: DeviationReportOrderBy.BookingDate
      });
      headers.push({
        value: "bookedBy",
        text: "BookingLoadUserID",
        sortValue: DeviationReportOrderBy.BookedBy
      });
      return headers;
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    truckTypeName: truckTypeName,
    deviationCauseName: deviationCauseName,
    otherPriceCauseName: otherPriceCauseName,
    search() {
      this.$emit("search");
    },
    loadInfo(item: DeviationReportRowDto): string {
      return `${t("LoadID")}: ${item.loadId}\n${t("TransportNo")}: ${
        item.transportNo
      }`;
    },
    loadClick(loadId: number) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.Details.toString(),
          ids: `[${loadId}]`
        }
      });
    },
    priceDifferenceColor(diff: number | undefined): string {
      if (!diff) {
        return "";
      }
      if (diff > 0) {
        return "red-text";
      }
      return "green-text";
    }
  }
});
