




































import { LoadActionPermission } from "@/scripts/cld.api";
import Vue from "vue";
export default Vue.extend({
  props: {
    loadId: Number
  },
  methods: {
    openDetails() {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.Details.toString(),
          ids: "[" + this.loadId.toString() + "]"
        }
      });
    },
    openSlotTimeCalender() {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.LoadingSlotEdit.toString(),
          ids: "[" + this.loadId.toString() + "]"
        }
      });
    }
  }
});
