










































import Vue from "vue";
import { DocumentGroupDto } from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import { gets } from "@/scripts/store/constants";
import { docTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    loading: Boolean,
    documentGroups: Array as () => DocumentGroupDto[],
    allowedToEditDocumentGroups: Boolean
  },
  components: {
    CustomTableHeader
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 143);
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "icon",
          text: ""
        },
        {
          value: "docType",
          text: "Type",
          sortValue: true
        },
        {
          value: "description",
          text: "Name",
          sortValue: true
        },
        {
          value: "regDate",
          text: "Created",
          sortValue: true
        }
      ];
      return headers;
    }
  },
  methods: {
    docTypeName: docTypeName,
    search() {
      this.$emit("search");
    },
    clicked(row: DocumentGroupDto) {
      this.$emit("click", row.id);
    }
  },
  data: (): { tableOptions: TableOptions } => ({
    tableOptions: new TableOptions({
      itemsPerPage: -1,
      sortBy: ["regDate"],
      sortDesc: [true]
    })
  })
});
