
















































































































































































































































import Vue from "vue";
import { RankingAgreementRowDto, RankingType } from "@/scripts/cld.api";
import money from "@/scripts/misc/money";
import { t } from "@/scripts/language/i18n";
import { rankingTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    agreementRow: RankingAgreementRowDto,
    agreementRowIndex: Number,
    truckTypeId: Number,
    cargoTypeId: Number,
    secondaryValue: Function,
    removeAgreementRow: Function,
    restoreAgreementRow: Function,
    explode: Function,
    implode: Function
  },
  computed: {
    rankingColor(): string {
      if (this.agreementRow.ranking === -1) {
        return "icon-red";
      } else if (this.agreementRow.ranking === 0) {
        return "icon-yellow";
      } else if (this.agreementRow.ranking % 2 === 0) {
        return "icon-cyan";
      } else {
        return "icon-gold";
      }
    },
    flagColor(): string {
      switch (this.agreementRow.currentRankingType) {
        case RankingType.Route:
          return "icon-green";
        case RankingType.Client:
          return "icon-yellow";
        case RankingType.System:
          return "warning";
        case RankingType.NoLoads:
          return "icon-red";
        default:
          return "";
      }
    },
    qualityPointsTooltip(): string {
      let tooltip = `${t("SourceForStatistics")}: ${rankingTypeName(
        this.agreementRow.currentRankingType
      )}`;
      tooltip += `\n${t("NumberOfOfferedLoads")}: ${
        this.agreementRow.numLoadsOffered
      }`;
      tooltip += `\n${t("BookedLoads")}: ${this.agreementRow.numLoadsLoaded}`;
      tooltip += `\n${t("MeasuredQualityPoints")} (${this.agreementRow
        .parameterFactor! * 100}% ${t("Of")} ${
        this.agreementRow.baseParameterPoints
      }): ${this.agreementRow.factoredParameterPoints}`;
      tooltip += `\n${t("PricePoints")}: ${this.agreementRow.pricePoints}`;
      tooltip += `\n${t("BusinessQualitypoints")}: ${
        this.agreementRow.subjectivePoints
      }`;
      tooltip += `\n${t("TotalPoints")}: ${this.agreementRow.totalPoints}`;
      return tooltip;
    }
  },
  methods: {
    money: money
  },
  data: () => ({
    rankingType: RankingType
  })
});
