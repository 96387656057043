




































































































































import Vue from "vue";
import {
  UserConfigurationType,
  AnnulledLoadsReportSearchCriteria,
  AnnulledLoadsReportContainerDto,
  AnnulledLoadsReportOrderBy,
  CargoType,
  AnnulledLoadsReportRowDto
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: AnnulledLoadsReportContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 300);
    },
    searchCriteria(): AnnulledLoadsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AnnulledLoadsReport,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName,
    search() {
      this.$emit("search");
    }
  },
  data: (): { headers: TableHeader[] } => ({
    headers: [
      {
        value: "loadId",
        text: "LoadID",
        sortValue: AnnulledLoadsReportOrderBy.LoadId,
        width: 75
      },
      {
        value: "transportNo",
        text: "TransportNo",
        sortValue: AnnulledLoadsReportOrderBy.TransportNo,
        width: 100
      },
      {
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: AnnulledLoadsReportOrderBy.PickupLocation,
        width: 220
      },
      {
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: AnnulledLoadsReportOrderBy.DeliveryLocation,
        width: 240
      },
      {
        value: "truckType",
        text: "TruckType",
        sortValue: AnnulledLoadsReportOrderBy.TruckType
      },
      {
        value: "unit",
        text: "Unit",
        sortValue: AnnulledLoadsReportOrderBy.Unit
      },
      {
        value: "annulledDate",
        text: "Annulled",
        sortValue: AnnulledLoadsReportOrderBy.AnnulledDate,
        width: 145
      },
      {
        value: "why",
        text: "Why",
        sortValue: AnnulledLoadsReportOrderBy.Why
      }
    ]
  })
});
