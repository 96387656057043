








































































import Vue from "vue";
import {
  UserConfigurationType,
  DeviationReportSearchCriteria,
  DeviationReportContainerDto,
  RepositoryDto
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import money from "@/scripts/misc/money";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { currencyName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    ReadOnlyText
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: DeviationReportContainerDto
  },
  computed: {
    searchCriteria(): DeviationReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DeviationReport,
        1
      );
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
