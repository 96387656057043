







































import Vue from "vue";
import Chip from "@/components/shared/ui/Chip.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { MultiLoad } from "@/scripts/types";

export default Vue.extend({
  components: {
    Chip
  },
  props: {
    load: Object as () => MultiLoad,
    noPaddingTop: Boolean
  },
  computed: {
    truckTypeName(): string {
      return (
        truckTypeName(this.load.truckType!) +
        (this.load.numContainers > 0 ? ` (${this.load.numContainers})` : "")
      );
    }
  }
});
