







































































import Vue from "vue";
import BarChart from "@/components/shared/charts/BarChart.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import {
  ActorRegDto,
  ActorSearchCritera,
  ApiConfiguration,
  Country,
  RepositoryDto,
  StatisticsClient
} from "@/scripts/cld.api";
import cached from "@/scripts/misc/cached";
import { actorTypes } from "@/scripts/misc/enumLists";
import { actorTypeName, isoName } from "@/scripts/misc/enumNames";
import MomentX from "@/scripts/misc/momentX";
import translatedDropdown from "@/scripts/misc/translatedDropdown";
import { actions, gets } from "@/scripts/store/constants";
import { ChartData } from "chart.js";

export default Vue.extend({
  components: {
    SearchCard,
    BarChart,
    Dropdown,
    Autocomplete,
    DatePicker
  },
  watch: {
    criteria: {
      handler() {
        this.fetchStatistics();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 300);
    },
    options(): ChartData<"bar", number[], string> {
      return {
        labels: this.labels,
        datasets: [
          {
            label: "Antal",
            data: this.data
          }
        ]
      };
    },
    actorTypes() {
      return actorTypes();
    },
    actorInfos() {
      return this.statistics.flatMap(x => x.actors!);
    }
  },
  methods: {
    actorTypeName: actorTypeName,
    fetchStatistics() {
      this.loading = true;
      new StatisticsClient(new ApiConfiguration(this.$store))
        .actors(this.criteria)
        .then(res => {
          this.statistics = res;
          this.labels = this.statistics.map(c =>
            c.regDate.yearMonthNamePrint("sv-SE")
          );
          this.data = this.statistics.map(c => c.actorsCount);
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    presentCountry(country: Country): string {
      return isoName(country);
    }
  },
  mounted() {
    this.fetchCountries();
  },
  data: (): {
    loading: boolean;
    statistics: ActorRegDto[];
    data: number[];
    labels: string[];
    countries: RepositoryDto[];
    criteria: ActorSearchCritera;
  } => ({
    loading: true,
    statistics: [],
    data: [],
    labels: [],
    countries: [],
    criteria: new ActorSearchCritera({
      fromDate: new MomentX(
        new MomentX().startOfDay().yearPrint() + "-01-" + "01"
      ),
      toDate: new MomentX(
        new MomentX().startOfDay().yearPrint() + "-12-" + "31"
      ),
      actorType: undefined,
      country: undefined
    })
  })
});
