var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === _vm.types.text)?_c('TextSingle',{staticClass:"filter-input",attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"tabindex":"2","clearable":"","only-on-blur":""},on:{"input":function (val) {
        _vm.input(val || null);
        _vm.search();
      }}}):_vm._e(),(_vm.type === _vm.types.autocomplete)?_c('Autocomplete',{attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"items":_vm.dropdownItems,"tabindex":"2","innerClass":"filter-input"},on:{"input":function (val) {
        _vm.input(val);
        _vm.search();
      }}}):_vm._e(),(_vm.type === _vm.types.dropdown)?_c('Dropdown',{attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"items":_vm.dropdownItems,"tabindex":"2","innerClass":"filter-input","clearable":""},on:{"input":function (val) {
        _vm.input(val);
        _vm.search();
      }}}):_vm._e(),(_vm.type === _vm.types.dropdownMulti)?_c('DropdownMulti',{attrs:{"value":_vm.value,"placeholder":_vm.placeholder,"items":_vm.dropdownItems,"tabindex":"2","innerClass":"filter-input","clearable":""},on:{"input":function (val) {
        _vm.input(val);
        _vm.search();
      }}}):_vm._e(),(
      [
        _vm.subtypes.location,
        _vm.subtypes.locationMulti,
        _vm.subtypes.locationCargoType,
        _vm.subtypes.locationClient
      ].includes(_vm.config.subtype)
    )?_c('LocationFilter',{attrs:{"multi":_vm.config.subtype === _vm.subtypes.locationMulti,"inner-class":"filter-input","location":_vm.value,"placeholder":_vm.placeholder,"counties":_vm.items.counties,"countries":_vm.items.countries},on:{"input":_vm.input,"search":_vm.search,"clearSelection":_vm.clearSelection}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }