var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiny-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.messages,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"options":_vm.tableOptions,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [50],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"item-class":function (r) { return (r.id === _vm.selectedId ? 'grey-row' : ''); },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.recipient",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"small":"","icon":"","width":"40"},on:{"click":function($event){_vm.recipients = item.recipients}}},[_c('v-icon',[_vm._v("mdi-account-eye")])],1)]}},{key:"item.clientName",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': _vm.isSupplier && !item.viewed }},[_c('EllipsisTableItem',{attrs:{"text":item.clientName,"width":"185"}})],1)]}},{key:"item.regLoadUserName",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': _vm.isSupplier && !item.viewed }},[_c('EllipsisTableItem',{attrs:{"text":item.regLoadUserName,"width":"145"}})],1)]}},{key:"item.subject",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:{ 'font-weight-bold': _vm.isSupplier && !item.viewed }},[_c('EllipsisTableItem',{attrs:{"text":item.subject,"width":"210"}})],1)]}},{key:"item.regDate",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines",class:{ 'font-weight-bold': _vm.isSupplier && !item.viewed }},[_vm._v(" "+_vm._s(item.regDate)+" ")])]}}],null,true)}),_c('MessageRecipientDialog',{attrs:{"dialog":_vm.recipients.length > 0,"recipients":_vm.recipients},on:{"close":function($event){_vm.recipients = []}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }