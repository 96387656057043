






























































































import Vue from "vue";
import {
  CargoType,
  UserConfigurationType,
  CostReportSearchCriteria,
  CostReportContainerDto,
  RepositoryDto,
  CostReportPrices
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { currencyName } from "@/scripts/misc/enumNames";
import money from "@/scripts/misc/money";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    ReadOnlyText
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    reportContainer: CostReportContainerDto
  },
  computed: {
    searchCriteria(): CostReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.CostReport,
        1
      );
    },
    isContainer(): boolean {
      return this.searchCriteria.cargoType === CargoType.Container;
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    },
    prices(): CostReportPrices[] {
      return this.reportContainer.prices!;
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
