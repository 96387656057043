





















































import Vue from "vue";

export default Vue.extend({
  props: {
    value: Boolean,
    disabled: Boolean,
    labelTrue: String,
    labelFalse: String,
    tooltip: String,
    noBorder: Boolean
  },
  computed: {
    proxyValue: {
      get(): boolean {
        return this.value;
      },
      set(val: boolean) {
        this.$emit("input", val);
      }
    }
  }
});
