var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"no-padding",attrs:{"cols":"12"}},[_c('SearchCard',{attrs:{"title":_vm.title,"no-padding-sides":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.suppliers,"height":_vm.tableHeight,"loading":_vm.loading,"mobile-breakpoint":"0","sort-by":"percentage","sort-desc":"","must-sort":"","disable-pagination":"","hide-default-header":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.supplierName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Survey_Supplier')},model:{value:(_vm.supplierFilter),callback:function ($$v) {_vm.supplierFilter=$$v},expression:"supplierFilter"}})]},proxy:true}],null,true)})]}},{key:"item.leaf",fn:function(ref){
var item = ref.item;
return [(item.fairTransportCertified)?_c('div',{staticClass:"small-padding-left"},[_c('v-img',{attrs:{"src":_vm.ftLogo,"height":"24","width":"24"}})],1):(item.answerDate)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.selectedSupplierId = item.actorId}}},[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-leaf ")])],1):_vm._e()]}},{key:"item.percentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fairTransportCertified ? "-" : item.percentage || "-")+" ")]}},{key:"item.answerDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fairTransportCertified ? item.fairTransportCertifiedDate.datePrint() : item.answerDate ? item.answerDate.datePrint() : undefined)+" ")]}},{key:"item.respondentName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fairTransportCertified ? _vm.$t("FairTransport") : item.respondentName)+" ")]}},{key:"item.respondentRole",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fairTransportCertified ? _vm.$t("FairTransport") : item.respondentRole)+" ")]}},{key:"item.iteration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fairTransportCertified ? "-" : item.iteration)+" ")]}}],null,true)})]},proxy:true}])})],1)],1),_c('SurveyDialog',{attrs:{"supplierId":_vm.selectedSupplierId},on:{"close":function($event){_vm.selectedSupplierId = undefined}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }