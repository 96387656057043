




































































import Vue from "vue";
import {
  RelatedLoadsDto,
  RelatedLoadSummaryDto,
  ApiConfiguration,
  LoadsClientClient
} from "@/scripts/cld.api";
import { saveBeforeContinueDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import objectsEqual from "@/scripts/misc/objectsEqual";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import LoadSearchInput from "@/components/web/loads/shared/search/LoadSearchInput.vue";
import LoadRelatedTable from "@/components/web/loads/shared/search/LoadRelatedTable.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  components: {
    DialogCard,
    LoadSearchInput,
    LoadRelatedTable,
    GreyTitle
  },
  props: {
    dialog: Boolean,
    relatedLoads: Object as () => RelatedLoadsDto,
    editable: Boolean
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.parentLoadsDraft = this.relatedLoads.parentLoads!.map(x =>
            Object.assign({}, x)
          );
          this.childLoadsDraft = this.relatedLoads.childLoads!.map(x =>
            Object.assign({}, x)
          );
        }
      },
      immediate: true
    }
  },
  computed: {
    pendingChanges(): boolean {
      return (
        !objectsEqual(this.relatedLoads.parentLoads, this.parentLoadsDraft) ||
        !objectsEqual(this.relatedLoads.childLoads, this.childLoadsDraft)
      );
    },
    excludedLoadIds(): number[] {
      return [...this.parentLoadsDraft, ...this.childLoadsDraft].map(
        l => l.loadId
      );
    }
  },
  methods: {
    close() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.saveDraft();
          },
          () => {
            this.closeCallback();
          }
        );
      } else {
        this.closeCallback();
      }
    },
    closeCallback() {
      this.$emit("close");
    },
    saveDraft() {
      this.relatedLoads.parentLoads = this.parentLoadsDraft
        .slice(0, this.parentLoadsDraft.length)
        .map(x => this.copy(x));
      this.relatedLoads.childLoads = this.childLoadsDraft
        .slice(0, this.childLoadsDraft.length)
        .map(x => this.copy(x));
      this.closeCallback();
    },
    copy(relatedLoad: RelatedLoadSummaryDto): RelatedLoadSummaryDto {
      return new RelatedLoadSummaryDto(relatedLoad);
    },
    addRelatedLoad(child: boolean, loadId: number) {
      return new LoadsClientClient(new ApiConfiguration(this.$store))
        .relatedLoadDetails(loadId)
        .then(res => {
          if (child) {
            this.childLoadsDraft.push(res);
          } else {
            this.parentLoadsDraft.push(res);
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    removeParent(load: RelatedLoadSummaryDto) {
      this.parentLoadsDraft = this.parentLoadsDraft.filter(
        l => l.loadId !== load.loadId
      );
    },
    removeChild(load: RelatedLoadSummaryDto) {
      this.childLoadsDraft = this.childLoadsDraft.filter(
        l => l.loadId !== load.loadId
      );
    }
  },
  data: (): {
    parentLoadsDraft: RelatedLoadSummaryDto[];
    childLoadsDraft: RelatedLoadSummaryDto[];
  } => ({
    parentLoadsDraft: [],
    childLoadsDraft: []
  })
});
