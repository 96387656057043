










































































































import Vue from "vue";
import {
  CargoType,
  Currency,
  FeeType,
  PriceListDto,
  RepositoryClient,
  ApiConfiguration
} from "@/scripts/cld.api";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import { t } from "@/scripts/language/i18n";
import {
  freightPriceFormula,
  invoicePriceFormulaShort
} from "@/scripts/misc/enumNames";
import MomentX from "@/scripts/misc/momentX";
import { currencies } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    SearchCard,
    SkeletonLoader,
    DatePicker
  },
  watch: {
    date: {
      handler() {
        this.fetchPrices();
      },
      immediate: true
    }
  },
  computed: {
    isMegaUser(): boolean {
      return this.$store.getters[gets.userInfo].supportAdminLevel >= 2;
    },
    startOfYear(): string {
      return this.date.startOfYear().datePrint();
    },
    rows(): any[][] {
      return [
        ["heading", t("GeneralMessageType")],
        [
          "datarow",
          t("FeeTypeSupplierEntranceFee"),
          ...this.prices(FeeType.SupplierEntranceFee)
        ],
        [
          "datarow",
          t("FeeTypeSupplierFirstUser"),
          ...this.prices(FeeType.SupplierFirstUser)
        ],
        [
          "datarow",
          t("FeeTypeSupplierAdditionalUser"),
          ...this.prices(FeeType.SupplierAdditionalUser)
        ],
        ["datarow", t("SmsPricePerYear"), 1500, 150, 130, 1500, 150],
        ["datarow", t("SmsPricePerSms"), 1, 0.1, 0.1, 1, 0.1],
        [
          "datarow",
          t("AdditionalServices") + " *",
          ...this.prices(FeeType.AdditionalServices)
        ],
        ["datarow", t("TravelTime") + " *", ...this.prices(FeeType.TravelTime)],
        ["textrow", "* " + t("AdditionalServicesDesc")],
        ["textrow", "* " + t("ForLargerAssignments")],
        ["textrow", " "],

        ["heading", t("Road")],
        ["datarow", t("BookingFee"), ...this.prices(FeeType.RoadLong)],
        [
          "datarow",
          `${t("BookingFee")} (< 200km)`,
          ...this.prices(FeeType.RoadShort)
        ],
        ["textrow", freightPriceFormula(CargoType.Road)],
        ["textrow", invoicePriceFormulaShort(CargoType.Road)],
        ["textrow", " "],

        ["heading", t("Railroad")],
        ["datarow", t("BookingFee"), ...this.prices(FeeType.Railroad)],
        ["textrow", freightPriceFormula(CargoType.Railroad)],
        ["textrow", invoicePriceFormulaShort(CargoType.Railroad)],
        ["textrow", " "],

        ["heading", t("Container")],
        [
          "datarow",
          `${t("BookingFee")} per container`,
          ...this.prices(FeeType.Container)
        ],
        ["textrow", freightPriceFormula(CargoType.Container)],
        ["textrow", invoicePriceFormulaShort(CargoType.Container)],
        ["textrow", " "]
      ];
    }
  },
  methods: {
    prices(feeType: FeeType): (null | number)[] {
      const res: (null | number)[] = [];
      for (let c of currencies()) {
        let price = this.allPrices.find(
          p => p.type === feeType && p.currency === c.id
        );
        res.push(price?.value !== undefined ? price!.value : null);
      }
      return res;
    },
    fetchPrices() {
      this.loading = true;
      new RepositoryClient(new ApiConfiguration(this.$store))
        .prices(this.date)
        .then(res => {
          this.allPrices = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { loading: boolean; date: MomentX; allPrices: PriceListDto[] } => ({
    loading: true,
    date: new MomentX(),
    allPrices: []
  })
});
