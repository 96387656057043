
























import Vue from "vue";
import { LoadDocumentDto } from "@/scripts/cld.api";
import LoadDocumentsTable from "@/components/web/loads/shared/LoadDocumentsTable.vue";

export default Vue.extend({
  components: {
    LoadDocumentsTable
  },
  props: {
    expanded: Boolean,
    editable: Boolean,
    loadId: Number,
    documents: Array as () => LoadDocumentDto[] | undefined
  },
  computed: {
    disabled(): boolean {
      return !this.documents;
    },
    numDocuments(): string {
      if (!this.documents) {
        return "";
      }
      return `(${this.documents.length})`;
    }
  }
});
