







































import Vue from "vue";
import {
  ActorType,
  CargoType,
  ProcurementDashboardDto,
  UserLevel
} from "@/scripts/cld.api";
import { cargoTypeName, cargoTypeIcon } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";
import { UserInfo } from "@/scripts/types";

export default Vue.extend({
  props: {
    item: ProcurementDashboardDto
  },
  computed: {
    userInfo(): UserInfo {
      return this.$store.getters[gets.userInfo];
    },
    actorType(): ActorType {
      return this.userInfo.actorType!;
    },
    atleastAdmin(): boolean {
      return (
        this.userInfo.userLevel === UserLevel.Admin ||
        this.userInfo.userLevel === UserLevel.SuperAdmin
      );
    }
  },
  methods: {
    cargoTypeName: cargoTypeName,
    cargoTypeIcon: cargoTypeIcon,
    translate(text: string | undefined): string {
      if (!text) {
        return "";
      }
      if (this.actorType === ActorType.Client) {
        return t(text);
      }
      return text;
    },
    routeTo(cargoType: CargoType) {
      this.$router.push("/procurements?cargoType=" + cargoType);
    }
  }
});
