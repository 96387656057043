import {
  ActorConfigurationType,
  ActorType,
  RepositoryDto
} from "@/scripts/cld.api";
import { ActorConfigurationEntry } from "@/scripts/store/actorConfModule";
import neverHappens from "@/scripts/misc/neverHappens";
import {
  ChartType,
  createMegaReportCriteria,
  MegaPreset
} from "@/scripts/types";
import { momentFromString } from "@/scripts/store/createUserConf";

export default (
  type: ActorConfigurationType,
  subTypeId: number,
  actorType: ActorType,
  json?: string
): ActorConfigurationEntry => {
  let conf;
  switch (type) {
    case ActorConfigurationType.ZipcodeByCounty:
      conf = createZipcodeByCountyConf(json);
      break;
    case ActorConfigurationType.MegaPresets:
      conf = createMegaPreset(json);
      break;
    default:
      neverHappens(type);
  }

  return {
    type: type,
    subTypeId: subTypeId,
    conf: conf
  } as ActorConfigurationEntry;
};

function createZipcodeByCountyConf(json?: string): RepositoryDto[] {
  let conf: RepositoryDto[] = [];
  try {
    const parsed = JSON.parse(json!);
    conf = parsed;
  } catch (error) {}
  return conf;
}

function createMegaPreset(json?: string): MegaPreset {
  let conf: MegaPreset = {
    title: "untitled",
    creator: "unknown",
    chartType: ChartType.Loads,
    criteria: createMegaReportCriteria()
  };

  try {
    const parsed = JSON.parse(json!);
    if (parsed.fromDate !== undefined) {
      parsed.fromDate = momentFromString(parsed.fromDate);
    }
    if (parsed.toDate !== undefined) {
      parsed.toDate = momentFromString(parsed.toDate);
    }
    conf = Object.assign(conf, parsed);
  } catch (error) {}
  return conf;
}
