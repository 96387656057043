
































































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { PublishSpeed, RankedSupplierDto } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import money from "@/scripts/misc/money";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  props: {
    agreementRouteId: Number,
    truckType: Number,
    agreementDate: MomentX,
    publishSpeed: Number as () => PublishSpeed,
    loadId: Number as () => number | undefined,
    expanded: Boolean
  },
  watch: {
    agreementRouteId: {
      handler() {
        this.fetchRankings();
      },
      immediate: true
    },
    truckType: {
      handler() {
        this.fetchRankings();
      },
      immediate: true
    },
    agreementDate: {
      handler() {
        this.fetchRankings();
      },
      immediate: true
    },
    publishSpeed: {
      handler() {
        this.fetchRankings();
      },
      immediate: true
    }
  },
  computed: {
    missingAgreement(): boolean {
      return !this.rankings || this.rankings!.length === 0;
    },
    disabled(): boolean {
      return !this.rankings || this.missingAgreement;
    }
  },
  methods: {
    money: money,
    fetchRankings() {
      if (!this.agreementRouteId || !this.truckType || !this.publishSpeed) {
        return;
      }
      this.rankings = undefined;
      cached("rankedSuppliersByAgreementroute", [
        this.agreementRouteId,
        this.truckType,
        this.agreementDate,
        this.publishSpeed,
        this.loadId
      ])
        .then(res => {
          this.rankings = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    time(supplier: RankedSupplierDto): string {
      return supplier.time.toString() + "h";
    }
  },
  data: (): { rankings?: RankedSupplierDto[] } => ({
    rankings: undefined
  })
});
