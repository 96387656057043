























































































import Vue from "vue";
import {
  ApiConfiguration,
  LoadingDockCalendarsSearchCriteria,
  LoadingDockDto,
  LoadingSlotsClient,
  CargoLoadingDto,
  LoadingSlotSaveDtoContainer,
  LoadingSlotDto,
  LoadActionPermission,
  LoadingSlotType,
  LoadingSlotSaveDto,
  BlockingSaveDto,
  ActorType,
  BlockingDeleteDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import {
  CalendarConfiguration,
  CargoLoadingContainer,
  LoadWithCargoLoadingContainer,
  Sound
} from "@/scripts/types";
import MomentX from "@/scripts/misc/momentX";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import SlotCalendarInfo from "@/components/web/slots/SlotCalendarInfo.vue";
import SlotCalendar from "@/components/web/slots/SlotCalendar.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  props: {
    loadIds: Array as () => number[],
    dialog: Boolean
  },
  components: {
    DialogCard,
    SlotCalendarInfo,
    SlotCalendar,
    SwitchDouble
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.calculateSecondsUntilNowish();
          if (this.$route.query.date !== undefined) {
            this.calendarDate = new MomentX(this.$route.query.date as string);
            this.$router.push("/slots");
          } else {
            this.slideCalendarDate();
          }
          if (this.loadId === undefined) {
            this.fetchSchedule(false);
          } else {
            this.fetchLoad();
          }
        }
      },
      immediate: true
    },
    valueToWatch: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchSchedule(false);
        }
      },
      deep: true
    },
    calendarDate(newVal: MomentX, oldVal: MomentX) {
      if (!newVal.startOfDay().isSame(oldVal.startOfDay())) {
        this.fetchSchedule(false);
      } else {
        this.loadingDocks = [...this.loadingDocks]; //Triggers recalculation of boxes
      }
    },
    secondsUntilRefreshTime() {
      if (this.secondsUntilRefreshTime <= 0) {
        if (this.load) {
          this.close();
        } else {
          this.fetchSchedule(true);
          this.refreshTime = new MomentX().loadingSlotNowish();
          if (
            this.calendarConfiguration.calendarAutoDateSliding &&
            this.isClient
          ) {
            this.slideCalendarDate();
          }
        }
      }
    }
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    },
    loadId(): number {
      return this.loadIds[0]!;
    },
    title(): string {
      if (this.workingWithBlocking) {
        return "Skapa blockering";
      }
      return t("LoadingSlots");
    },
    autocloseMessage(): string | undefined {
      if (this.secondsUntilRefreshTime > 100 || !this.load) {
        return;
      }
      return t("WindowCloseXSeconds").replace(
        "{0}",
        this.secondsUntilRefreshTime.toString()
      );
    },
    showBlockingButton(): boolean {
      return this.loadId === undefined && this.actorType === ActorType.Client;
    },
    cargoLoadingContainers(): CargoLoadingContainer[] {
      if (!this.load) {
        return [];
      }
      return this.load.cargoLoadingContainers;
    },
    selectedCargoLoadingContainer(): CargoLoadingContainer | undefined {
      return this.cargoLoadingContainers.find(
        c => c.id === this.selectedCargoLoadingContainerId
      );
    },
    valueToWatch(): string {
      let val = this.cargoLoadingContainers
        .map(cc => cc.ignoreFromDb)
        .join(",");
      const selCC = this.selectedCargoLoadingContainer;
      if (selCC) {
        val +=
          "," +
          selCC.id +
          "," +
          selCC.extraSeconds +
          "," +
          selCC.cargoLoading.kilograms +
          "," +
          selCC.cargoLoading.seconds;
      }
      return val;
    },
    allCargoLoadingsBooked(): boolean {
      return this.load!.cargoLoadingContainers.every(c => c.booking);
    },
    noCargoLoadingBooked(): boolean {
      return this.load!.cargoLoadingContainers.every(c => !c.booking);
    },
    saveEnabled(): boolean {
      if (this.allCargoLoadingsBookedToBeginWith === undefined) {
        return false;
      }
      if (!this.allCargoLoadingsBookedToBeginWith) {
        return this.allCargoLoadingsBooked;
      }
      return this.allCargoLoadingsBooked || this.noCargoLoadingBooked;
    },
    workingWithBlocking(): boolean {
      return this.loadId === -1;
    },
    calendarConfiguration(): CalendarConfiguration {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.CalendarConfiguration,
        1
      );
    }
  },
  methods: {
    save() {
      this.saveInProgress = true;
      if (this.workingWithBlocking) {
        const c = this.cargoLoadingContainers[0];
        new LoadingSlotsClient(new ApiConfiguration(this.$store))
          .saveBlocking(
            new BlockingSaveDto({
              loadingDockId: c.booking!.loadingDockId,
              startDate: c.booking!.startDate,
              endDate: c.booking!.endDate,
              now: this.refreshTime,
              note: this.load!.note,
              kilograms: c.cargoLoading.kilograms
            })
          )
          .then(() => {
            this.saveInProgress = false;
            this.editBooking(undefined);
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
        return;
      }
      new LoadingSlotsClient(new ApiConfiguration(this.$store))
        .saveLoadingSlots(
          new LoadingSlotSaveDtoContainer({
            loadId: this.loadId!,
            truckNo: this.load!.truckNo,
            bookingNo: this.load!.bookingNo,
            note: this.load!.note,
            now: this.refreshTime,
            loadingSlots: this.cargoLoadingContainers
              .filter(c => c.booking)
              .map(
                c =>
                  new LoadingSlotSaveDto({
                    cargoLoadingId: c.booking!.cargoLoadingId,
                    loadingDockId: c.booking!.loadingDockId,
                    startDate: c.booking!.startDate,
                    endDate: c.booking!.endDate,
                    kilograms: c.booking!.kilograms
                  })
              )
          })
        )
        .then(() => {
          this.saveInProgress = false;
          this.editBooking(undefined);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    initBlocking() {
      this.editBooking(-1);
    },
    close() {
      this.$emit("close");
    },
    saveConf() {
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.CalendarConfiguration,
        subTypeId: 1
      });
    },
    changeDate(seconds: number) {
      this.calendarDate = this.calendarDate.addSeconds(seconds);
      if (
        seconds > 0 &&
        this.calendarDate.isAfter(this.calendarDate.withHours(16))
      ) {
        this.calendarDate = this.calendarDate.withHours(16);
      }
      if (
        seconds < 0 &&
        this.calendarDate.isAfter(this.calendarDate.withHours(16))
      ) {
        this.calendarDate = this.calendarDate.startOfDay().addDays(1);
      }
    },
    selectedCargoLoadingContainerIdChanged(cargoLoadingContainerId: number) {
      if (cargoLoadingContainerId === 0) {
        this.selectedCargoLoadingContainer!.ignoreFromDb = false;
      }
      this.selectedCargoLoadingContainerId = cargoLoadingContainerId;
      if (cargoLoadingContainerId !== 0) {
        this.selectedCargoLoadingContainer!.ignoreFromDb = true;
      }
    },
    clearBooking(cargoLoadingContainerId: number) {
      const cargoLoadingContainer = this.cargoLoadingContainers.find(
        c => c.id === cargoLoadingContainerId
      )!;
      cargoLoadingContainer.booking = undefined;
      cargoLoadingContainer.ignoreFromDb = true;
    },
    createBooking(loadingDockId: number, startDate: MomentX, endDate: MomentX) {
      const selCC = this.selectedCargoLoadingContainer!;
      selCC.booking = new LoadingSlotDto({
        id: 0,
        type: LoadingSlotType.Booking,
        cargoLoadingId: selCC.id,
        loadingDockId: loadingDockId,
        loadId: selCC.cargoLoading.loadId,
        bookedActorId: 0,
        bookedByLoadUserId: 0,
        startDate: startDate,
        endDate: endDate,
        kilograms: selCC.cargoLoading.kilograms,
        kilogramsBefore: 0,
        kilogramsAfter: 0,
        alterDate: new MomentX(),
        note: this.load!.note,
        truckNo: this.load!.truckNo,
        transportNo: this.load!.transportNo,
        bookingNo: this.load!.bookingNo,
        loadingDockToFillId: undefined,
        contacts: []
      });
      this.selectedCargoLoadingContainerIdChanged(0);
    },
    editBooking(loadId: number | undefined) {
      this.close();
      this.$nextTick(() => {
        this.$router.push(
          `/slots?action=${LoadActionPermission.LoadingSlotEdit}&ids=[${
            loadId !== undefined ? loadId : ""
          }]&date=${this.calendarDate.dateTimePrint()}`
        );
      });
    },
    deleteBooking(cargoLoadingId: number) {
      new LoadingSlotsClient(new ApiConfiguration(this.$store))
        .deleteBlocking(
          new BlockingDeleteDto({
            cargoLoadingId: cargoLoadingId,
            now: this.refreshTime
          })
        )
        .then(() => {
          this.fetchSchedule(false);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLoad() {
      this.refreshTime = new MomentX().loadingSlotNowish();
      if (this.workingWithBlocking) {
        this.load = {
          loadId: -1,
          transportNo: undefined,
          pickupEarliest: MomentX.minDate(),
          pickupLatest: MomentX.maxDate(),
          note: undefined,
          truckNo: undefined,
          bookingNo: undefined,
          clientName: undefined,
          cargoLoadingContainers: [
            {
              id: -1,
              cargoLoading: new CargoLoadingDto({
                id: -1,
                loadId: undefined,
                pickupEarliest: MomentX.minDate(),
                pickupLatest: MomentX.maxDate(),
                seconds: 300,
                kilograms: 0,
                loadingDockIds: [1, 2, 3],
                loadingDockNames: ["Blockering"]
              }),
              extraSeconds: 0,
              booking: undefined,
              ignoreFromDb: false
            }
          ],
          status: undefined
        };
        return;
      }
      new LoadingSlotsClient(new ApiConfiguration(this.$store))
        .loadWithCargoLoadings(this.loadId!)
        .then(res => {
          this.load = {
            loadId: res.loadId,
            transportNo: res.transportNo,
            pickupEarliest: res.pickupEarliest,
            pickupLatest: res.pickupLatest,
            note: undefined,
            truckNo: res.truckNo,
            bookingNo: res.bookingNo,
            clientName: res.clientName,
            cargoLoadingContainers: res.cargoLoadings!.map(c => ({
              id: c.id,
              cargoLoading: c,
              extraSeconds: 0,
              booking: undefined,
              ignoreFromDb: false
            })),
            status: res.loadStatus
          };

          this.identifyBookings();
          this.calendarDate = res.viewDate;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSchedule(playSound: boolean) {
      const unique = nextUnique();
      this.loading = unique;
      let cargoLoadingToBook: CargoLoadingDto | undefined = undefined;
      const selCC = this.selectedCargoLoadingContainer;
      if (selCC) {
        cargoLoadingToBook = new CargoLoadingDto({
          id: selCC.cargoLoading.id,
          loadId: selCC.cargoLoading.loadId,
          pickupEarliest: selCC.cargoLoading.pickupEarliest,
          pickupLatest: selCC.cargoLoading.pickupLatest,
          seconds: selCC.cargoLoading.seconds + selCC.extraSeconds,
          kilograms: selCC.cargoLoading.kilograms,
          loadingDockIds: selCC.cargoLoading.loadingDockIds,
          loadingDockNames: selCC.cargoLoading.loadingDockNames
        });
      }
      new LoadingSlotsClient(new ApiConfiguration(this.$store))
        .calendars(
          new LoadingDockCalendarsSearchCriteria({
            loadId: this.loadId!,
            date: this.calendarDate,
            cargoLoadingToBook: cargoLoadingToBook,
            now: this.refreshTime,
            premisedBookings: this.cargoLoadingContainers
              .filter(cc => cc.booking !== undefined)
              .map(cc => cc.booking!),
            cargoLoadingsFromDbToIgnore: this.cargoLoadingContainers
              .filter(cc => cc.ignoreFromDb || cc.booking)
              .map(cc => cc.id)
          })
        )
        .then(res => {
          if (this.loading === unique) {
            if (playSound && this.schedulesDiffer(this.loadingDocks, res)) {
              this.$store.commit(mutations.pushSound, Sound.Notification1);
            }
            this.loading = 0;
            this.loadingDocks = res;
            this.identifyBookings();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    schedulesDiffer(s1: LoadingDockDto[], s2: LoadingDockDto[]): boolean {
      const ids1 = s1
        .flatMap(d => d.loadingSlots)
        .map(s => s!.id)
        .filter(id => id > 0)
        .sort();
      const ids2 = s2
        .flatMap(d => d.loadingSlots)
        .map(s => s!.id)
        .filter(id => id > 0)
        .sort();
      return JSON.stringify(ids1) !== JSON.stringify(ids2);
    },
    identifyBookings() {
      this.cargoLoadingContainers.map(c => {
        const booking = [...this.loadingDocks.map(d => d.loadingSlots)]
          .flat()
          .find(s => s!.cargoLoadingId === c.id);
        if (booking !== undefined) {
          c.booking = booking;
          if (!this.load!.note) {
            this.load!.note = c.booking.note;
          }
        }
      });
      if (
        this.allCargoLoadingsBookedToBeginWith === undefined &&
        this.loadingDocks.length &&
        this.load
      ) {
        this.allCargoLoadingsBookedToBeginWith = this.allCargoLoadingsBooked;
      }
    },
    calculateSecondsUntilNowish() {
      if (!this.dialog) {
        return;
      }
      this.secondsUntilRefreshTime =
        this.refreshTime.unix() - new MomentX().unix();
      setTimeout(this.calculateSecondsUntilNowish, 1000);
    },
    slideCalendarDate() {
      this.calendarDate = new MomentX().startOfDay();
      this.changeDate((new MomentX().hours() - 1) * 3600);
    }
  },
  data: (): {
    loading: number;
    saveInProgress: boolean;
    load?: LoadWithCargoLoadingContainer;
    selectedCargoLoadingContainerId: number;
    loadingDocks: LoadingDockDto[];
    calendarDate: MomentX;
    refreshTime: MomentX;
    secondsUntilRefreshTime: number;
    allCargoLoadingsBookedToBeginWith?: boolean;
  } => ({
    loading: 0,
    saveInProgress: false,
    load: undefined,
    selectedCargoLoadingContainerId: 0,
    loadingDocks: [],
    calendarDate: new MomentX().startOfDay(),
    refreshTime: new MomentX().loadingSlotNowish(),
    secondsUntilRefreshTime: Infinity,
    allCargoLoadingsBookedToBeginWith: undefined
  })
});
