import {
  AgreementRouteSearchCriteria,
  AgreementRouteWithAgreementStatusDto,
  AgreementsClientClient,
  ApiConfiguration,
  CargoType,
  Country,
  CountyWithZipcodeDto,
  PublishSpeed,
  RankedSupplierDto,
  RankingSearchCriteria,
  RepositoryClient,
  RepositoryDto,
  RoutingClient,
  ItineraryDto,
  SuggestionDto,
  TruckType,
  TruckTypeDto,
  TruckTypeSearchCriteria,
  LoadsClient,
  LoadSearchResultDto,
  AgreementRoutesClient,
  LocationsClient,
  LocationStartingWithDto,
  BaseIndexPeriodDto,
  ActorsClient
} from "@/scripts/cld.api";
import store from "@/scripts/store/store";
import MomentX from "@/scripts/misc/momentX";
import { gets, mutations } from "@/scripts/store/constants";
import translatedDropdown from "./translatedDropdown";
import { isoName } from "./enumNames";

const endpointLocationCache = {
  counties: null,
  countries: null,
  countiesByCargotype: null,
  countiesOnAgreementroutes: null,
  countiesAndZipcode: null,
  countiesStartingWith: null,
  pickupCountiesSupportAdmin: null,
  zipcodesStartingWith: null,
  tags: null
};
const endpointLongTimeCache = {
  baseIndexPeriods: null,
  addressSuggestions: null,
  itinerary: null
};
const endpointShortTimeCache = {
  trucktypesByAgreementrouteid: null,
  rankedSuppliersByAgreementroute: null,
  agreementRoutes: null,
  clients: null,
  suppliers: null,
  suppliersForSupportAdmin: null,
  customers: null,
  terminals: null,
  loadSearch: null,
  units: null
};
const endpoint = {
  ...endpointLocationCache,
  ...endpointLongTimeCache,
  ...endpointShortTimeCache
};

type Endpoint = keyof typeof endpoint;

interface CacheEntry {
  key: string;
  type: Endpoint;
  timestamp: MomentX;
  promise: Promise<any>;
}

type SuppliersInput = [
  /* cargoType */ CargoType | null,
  /* includeOnesExcludedByMe */ boolean,
  /* includeOnesThatExcludeMe */ boolean
];
type ClientsInput = [
  /* includeOnesExcludedByMe */ boolean,
  /* includeOnesThatExcludeMe */ boolean,
  /* includeInactive */ boolean
];
type AddressSuggestionInput = [
  /*country:*/ Country,
  /*county:*/ string,
  /*zipcode:*/ string,
  /*address:*/ string
];
type LoadSearchInput = [string];
type TagInput = [CargoType | null];
type ItineraryInput = [
  /*fromCountry:*/ Country,
  /*fromCounty:*/ string,
  /*fromZipcode:*/ string,
  /*fromAddress:*/ string,
  /*toCountry:*/ Country,
  /*toCounty:*/ string,
  /*toZipcode:*/ string,
  /*toAddress:*/ string,
  /*agreementRouteId:*/ number | null
];
type RankedSuppliersByAgreementRouteInput = [
  /*agreementRouteId:*/ number,
  /*truckType:*/ TruckType,
  /*agreementDate:*/ MomentX,
  /*publishSpeed:*/ PublishSpeed,
  /*loadId:*/ number | undefined
];

type LocationsStartingWithInput = [/* Str */ string, /* Country */ Country];

let cache: CacheEntry[] = [];

const isLocationType = (endpoint: Endpoint): boolean => {
  return Object.keys(endpointLocationCache).includes(endpoint);
};

const isShortTimeType = (endpoint: Endpoint): boolean => {
  return Object.keys(endpointShortTimeCache).includes(endpoint);
};

const isLongTimeType = (endpoint: Endpoint): boolean => {
  return Object.keys(endpointLongTimeCache).includes(endpoint);
};

const clearCacheIfNeeded = (endpoint: Endpoint) => {
  if (isLocationType(endpoint)) {
    if (store.getters[gets.locationCacheOutdated]) {
      store.commit(mutations.setLocationCacheOutdated, false);
      cache = cache.filter(c => !isLocationType(c.type));
    }
  } else if (isShortTimeType(endpoint)) {
    cache = cache.filter(
      c => !isShortTimeType(c.type) || c.timestamp.isLessThanSecondsOld(30)
    );
  } else if (isLongTimeType(endpoint)) {
    cache = cache.filter(
      c => !isLongTimeType(c.type) || c.timestamp.isLessThanSecondsOld(7200)
    );
  }
};

const addToCache = (entry: CacheEntry) => {
  cache.push(entry);
};

const createKey = (endpoint: Endpoint, args: any[]) => {
  let key = endpoint;
  for (const arg of args) {
    key += !arg ? "null" : arg.toString();
  }
  return key;
};

const createEntry = (endpoint: Endpoint, args: any[]): CacheEntry => {
  return {
    key: createKey(endpoint, args),
    type: endpoint,
    timestamp: new MomentX(),
    promise: createPromise(endpoint, args)
  };
};

const getEntry = (endpoint: Endpoint, args: any[]) => {
  const key = createKey(endpoint, args);
  clearCacheIfNeeded(endpoint);
  return cache.find(c => c.key === key);
};

const createPromise = (endpoint: Endpoint, args: any[]): Promise<any> => {
  if (endpoint === "countiesOnAgreementroutes") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      args[0],
      args[1]
    );
  }
  if (endpoint === "countiesByCargotype") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      args[0],
      args[1]
    );
  }
  if (endpoint === "suppliersForSupportAdmin") {
    return new ActorsClient(new ApiConfiguration(store))[endpoint](args[0]);
  }
  if (endpoint === "suppliers") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as SuppliersInput)
    );
  }
  if (endpoint === "clients") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as ClientsInput)
    );
  }
  if (endpoint === "trucktypesByAgreementrouteid") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      new TruckTypeSearchCriteria({
        cargoType: args[0],
        agreementDate: args[1],
        agreementRouteId: args[2]
      })
    );
  }
  if (endpoint === "counties") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](args[0]);
  }
  if (endpoint === "countiesAndZipcode") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](args[0]);
  }
  if (endpoint === "agreementRoutes") {
    return new RepositoryClient(new ApiConfiguration(store))[endpoint](
      new AgreementRouteSearchCriteria({
        cargoType: args[0],
        agreementDate: args[1]
      })
    );
  }
  if (endpoint === "rankedSuppliersByAgreementroute") {
    const input = args[0] as RankedSuppliersByAgreementRouteInput;
    return new AgreementsClientClient(new ApiConfiguration(store))[endpoint](
      new RankingSearchCriteria({
        agreementRouteId: input[0],
        truckType: input[1],
        agreementDate: input[2],
        publishSpeed: input[3],
        loadId: input[4]
      })
    );
  }
  if (endpoint === "addressSuggestions") {
    return new RoutingClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as AddressSuggestionInput)
    );
  }
  if (endpoint === "itinerary") {
    return new RoutingClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as ItineraryInput)
    );
  }
  if (endpoint === "loadSearch") {
    return new LoadsClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as LoadSearchInput)
    );
  }
  if (endpoint === "tags") {
    return new AgreementRoutesClient(new ApiConfiguration(store))[endpoint](
      ...(args[0] as TagInput)
    );
  }
  if (
    endpoint === "countiesStartingWith" ||
    endpoint === "zipcodesStartingWith"
  ) {
    const input = args[0] as LocationsStartingWithInput;
    return new LocationsClient(new ApiConfiguration(store))[endpoint](
      new LocationStartingWithDto({
        str: input[0],
        country: input[1]
      })
    );
  }
  return new RepositoryClient(new ApiConfiguration(store))[endpoint]();
};

const applyTranslationIfNeeded = (entry: CacheEntry, endpoint: Endpoint) => {
  if (endpoint === "countries") {
    entry.promise = entry.promise.then((res: RepositoryDto[]) => {
      res = translatedDropdown(res);
      res.sort((r1, r2) => {
        return (r1.text as String).localeCompare(r2.text!);
      });
      res.map(c => {
        if (c.id !== null) {
          c.text = `(${isoName(c.id)}) ${c.text}`;
        }
      });
      return res;
    });
  }
};

const getPromise = (endpoint: Endpoint, args: any[]): Promise<any> => {
  let entry = getEntry(endpoint, args);
  if (entry === undefined) {
    entry = createEntry(endpoint, args);
    applyTranslationIfNeeded(entry, endpoint);
    addToCache(entry);
  }
  return entry.promise;
};

function cached(
  endpoint:
    | "countries"
    | "customers"
    | "pickupCountiesSupportAdmin"
    | "terminals"
): Promise<RepositoryDto[]>;

function cached(endpoint: "baseIndexPeriods"): Promise<BaseIndexPeriodDto[]>;

function cached(endpoint: "units"): Promise<string[]>;

function cached(endpoint: "tags", input: TagInput): Promise<string[]>;

function cached(
  endpoint: "counties",
  country: Country | null
): Promise<RepositoryDto[]>;

function cached(
  endpoint: "suppliersForSupportAdmin",
  cargoType: CargoType | null
): Promise<RepositoryDto[]>;

function cached(
  endpoint: "clients",
  input: ClientsInput
): Promise<RepositoryDto[]>;

function cached(
  endpoint: "suppliers",
  input: SuppliersInput
): Promise<RepositoryDto[]>;

function cached(
  endpoint: "countiesAndZipcode",
  country: Country
): Promise<CountyWithZipcodeDto[]>;

function cached(
  endpoint: "countiesStartingWith" | "zipcodesStartingWith",
  input: LocationsStartingWithInput
): Promise<string[]>;

function cached(
  endpoint: "countiesByCargotype" | "countiesOnAgreementroutes",
  cargoType: CargoType,
  pickup: boolean
): Promise<RepositoryDto[]>;

function cached(
  endpoint: "agreementRoutes",
  cargoType: CargoType,
  agreementDate: MomentX
): Promise<AgreementRouteWithAgreementStatusDto[]>;

function cached(
  endpoint: "trucktypesByAgreementrouteid",
  cargoType: CargoType,
  agreementDate: MomentX,
  agreementRouteId: number
): Promise<TruckTypeDto[]>;

function cached(
  endpoint: "rankedSuppliersByAgreementroute",
  input: RankedSuppliersByAgreementRouteInput
): Promise<RankedSupplierDto[]>;

function cached(
  endpoint: "addressSuggestions",
  input: AddressSuggestionInput
): Promise<SuggestionDto[]>;

function cached(
  endpoint: "itinerary",
  input: ItineraryInput
): Promise<ItineraryDto>;

function cached(
  endpoint: "loadSearch",
  input: LoadSearchInput
): Promise<LoadSearchResultDto[]>;

function cached(endpoint: Endpoint, ...args: any[]): Promise<any> {
  return getPromise(endpoint, args);
}

export default cached;
