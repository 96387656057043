

























































import Vue from "vue";
import {
  RankingTruckTypesDto,
  CargoType,
  ClientAgreementSearchCriteria,
  UserConfigurationType
} from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import money from "@/scripts/misc/money";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    cargoTypeId: Number,
    truckType: RankingTruckTypesDto
  },
  computed: {
    searchCriteria(): ClientAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientProcurement,
        this.cargoTypeId
      );
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        { value: "expand", width: 1, sortable: false } as any,
        {
          value: "truckType",
          text: truckTypeName(this.truckType.id),
          sortValue: true,
          width: 145
        },
        {
          value: "supplierName",
          text: t("Supplier"),
          sortValue: true,
          width: 380
        },
        { value: "ranking", text: t("Ranking"), sortValue: true, width: 120 }
      ];
      if (this.searchCriteria.unitBasePrice) {
        headers.push({
          value: "unitBasePrice",
          text: t("UnitBasePrice"),
          sortValue: true,
          width: 130
        });
      } else {
        headers.push({
          value: "basePrice",
          text: t("OfferedPrice"),
          sortValue: true,
          width: 100
        });
      }
      switch (this.cargoTypeId) {
        case CargoType.Road:
          headers.push({
            value: "freightPrice",
            text: t("FreightPrice"),
            sortValue: true,
            width: 100
          });
          headers.push({
            value: "stopPrice",
            text: t("StopPrice"),
            sortValue: true,
            width: 140
          });
          break;
        case CargoType.Container:
          headers.push({
            value: "serviceFee",
            text: t("ServiceFee"),
            sortValue: true,
            width: 140
          });
          break;
        case CargoType.MachineTransport:
          headers.push({
            value: "initialFee",
            text: t("TruckTypeFee"),
            sortValue: true,
            width: 140
          });
          break;
      }
      headers.push({
        value: "note",
        text: t("Note"),
        sortValue: true,
        width: 110
      });
      headers.push({
        value: "share",
        text: t("Share"),
        sortValue: true,
        width: 100
      });
      return headers;
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName
  },
  data: function(): {
    expanded: boolean;
  } {
    return {
      expanded: false
    };
  }
});
