

















import Vue from "vue";

export default Vue.extend({
  props: {
    text: String,
    icon: String,
    color: String,
    disableOnEmpty: Boolean,
    small: Boolean
  }
});
