











import {
  ActiveUsersNowDto,
  ActorType,
  ApiConfiguration,
  StatisticsClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Vue from "vue";

export default Vue.extend({
  computed: {
    activeClients(): number {
      return this.activeUsers!.filter(
        user => !user.integrationUser && user.actorType === ActorType.Client
      ).length;
    },
    activeSuppliers(): number {
      return this.activeUsers!.filter(
        user => !user.integrationUser && user.actorType === ActorType.Supplier
      ).length;
    },
    activeCustomers(): number {
      return this.activeUsers!.filter(
        user => !user.integrationUser && user.actorType === ActorType.Customer
      ).length;
    },
    activeTerminals(): number {
      return this.activeUsers!.filter(
        user => !user.integrationUser && user.actorType === ActorType.Terminal
      ).length;
    },
    activeWebUsers(): number {
      return (
        this.activeClients +
        this.activeSuppliers +
        this.activeCustomers +
        this.activeTerminals
      );
    },
    activeIntegrationUsers(): number {
      return this.activeUsers!.filter(user => user.integrationUser).length;
    },
    tooltip(): string {
      let tooltip = "Webanvändare / Integrationsanvändare\n\n";
      tooltip += "Beställare: " + this.activeClients + "\n";
      tooltip += "Leverantörer: " + this.activeSuppliers + "\n";
      tooltip += "Kunder: " + this.activeCustomers + "\n";
      tooltip += "Terminaler: " + this.activeTerminals + "\n";
      tooltip += "Integratörer: " + this.activeIntegrationUsers + "\n\n";
      tooltip += this.activeUsers!.map(user => user.name).join("\n");
      return tooltip;
    }
  },
  methods: {
    fetchActiveUsers() {
      new StatisticsClient(new ApiConfiguration(this.$store))
        .activeUsersNow()
        .then(res => {
          this.activeUsers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchActiveUsers();
    this.$nextTick(function() {
      this.intervalId = setInterval(() => {
        this.fetchActiveUsers();
      }, 14000);
    });
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  data: (): {
    intervalId?: number;
    activeUsers: ActiveUsersNowDto[] | undefined;
  } => ({
    intervalId: undefined,
    activeUsers: undefined
  })
});
