






















































































































































import Vue from "vue";
import {
  ActorSigningClient,
  ActorSigningDto,
  ApiConfiguration,
  TerminatedStatus
} from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import { actions, gets } from "@/scripts/store/constants";
import { ActorListDialogType } from "../actor/ActorTable.vue";

enum SigningStatus {
  Declined = "Nekad",
  Withdrawn = "Återkallad",
  Expired = "Förfallen",
  Faulty = "Felaktig",
  NotStarted = "Ej startad",
  InProgress = "Pågående",
  Signed = "Signerad"
}

export default Vue.extend({
  props: {
    actors: Array as () => ActorSigningDto[],
    loading: Boolean
  },
  components: {
    EllipsisTableItem,
    CustomTableHeader,
    CustomFilter
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 190);
    },
    filteredActors(): ActorSigningDto[] {
      return this.actors
        .filter(a => stringSearchHit(this.actorNameFilter, a.actorName, false))
        .filter(
          a =>
            this.activeFilter === undefined ||
            this.activeFilter === null ||
            a.active === this.activeFilter
        )
        .filter(a =>
          stringSearchHit(this.cNameFilter, a.contractManagerName, false)
        )
        .filter(a =>
          stringSearchHit(this.cEmailFilter, a.contractManagerEmail, false)
        )
        .filter(a =>
          stringSearchHit(this.cPhoneFilter, a.contractManagerPhone, false)
        )
        .filter(a => stringSearchHit(this.statusFilter, this.status(a), true));
    }
  },
  methods: {
    editActor(id: number) {
      window.open(
        `/support-admin/actors?id=${id}&dialog=${ActorListDialogType.ActorDialog}`,
        "_blank"
      );
    },
    init(item: ActorSigningDto) {
      this.actionInProgress = item.id;
      new ActorSigningClient(new ApiConfiguration(this.$store))
        .initialize(item.id)
        .then(() => {
          this.$emit("search");
          this.actionInProgress = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    terminate(item: ActorSigningDto) {
      this.actionInProgress = item.id;
      new ActorSigningClient(new ApiConfiguration(this.$store))
        .terminate(item.id)
        .then(() => {
          this.$emit("search");
          this.actionInProgress = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    sendReminder(item: ActorSigningDto) {
      this.actionInProgress = item.id;
      new ActorSigningClient(new ApiConfiguration(this.$store))
        .sendReminder(item.id)
        .then(() => {
          this.$emit("search");
          this.actionInProgress = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    signingInProgress(item: ActorSigningDto): boolean {
      return this.status(item) === SigningStatus.InProgress;
    },
    isSigned(item: ActorSigningDto): boolean {
      return this.status(item) === SigningStatus.Signed;
    },
    status(item: ActorSigningDto): SigningStatus {
      if (item.terminatedStatus === TerminatedStatus.Declined) {
        return SigningStatus.Declined;
      } else if (item.terminatedStatus === TerminatedStatus.Expired) {
        return SigningStatus.Expired;
      } else if (item.terminatedStatus === TerminatedStatus.Faulty) {
        return SigningStatus.Faulty;
      } else if (item.terminatedStatus === TerminatedStatus.Withdrawn) {
        return SigningStatus.Withdrawn;
      } else if (item.signingStarted === undefined) {
        return SigningStatus.NotStarted;
      } else if (item.signingCompleted === undefined) {
        return SigningStatus.InProgress;
      } else {
        return SigningStatus.Signed;
      }
    },
    customSort(
      items: ActorSigningDto[],
      sortByArr: string[],
      sortDescArr: boolean[]
    ) {
      const sortBy = sortByArr[0];
      const sortDesc = sortDescArr[0];
      let c: { (a: ActorSigningDto, b: ActorSigningDto): number };
      switch (sortBy) {
        case "status":
          c = (a: ActorSigningDto, b: ActorSigningDto) =>
            stringCompare(this.status(a), this.status(b));
          break;
        default:
          c = (a: ActorSigningDto, b: ActorSigningDto) =>
            stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      items = items.sort((a, b) => (sortDesc ? -1 : 1) * c(a, b));
      return items;
    }
  },
  data: (): {
    actionInProgress: number;
    actorNameFilter: string;
    activeFilter: boolean | undefined;
    cNameFilter: string;
    cEmailFilter: string;
    cPhoneFilter: string;
    statusFilter: string;
    headers: TableHeader[];
  } => ({
    actionInProgress: 0,
    actorNameFilter: "",
    activeFilter: undefined,
    cNameFilter: "",
    cEmailFilter: "",
    cPhoneFilter: "",
    statusFilter: "",
    headers: [
      {
        value: "id",
        text: "Id",
        untranslated: true,
        sortValue: true
      },
      {
        value: "edit",
        text: "",
        untranslated: true
      },
      {
        value: "actorName",
        text: "Actor",
        sortValue: true,
        width: 210
      },
      {
        value: "active",
        text: "Status",
        sortValue: true,
        width: 1
      },
      {
        value: "contractManagerName",
        text: "ContractManagerName",
        sortValue: true
      },
      {
        value: "contractManagerEmail",
        text: "ContractManagerEmail",
        sortValue: true
      },
      {
        value: "contractManagerPhone",
        text: "ContractManagerPhone",
        sortValue: true
      },
      {
        value: "signingStarted",
        text: "Signering start",
        untranslated: true,
        sortValue: true
      },
      {
        value: "signingCompleted",
        text: "Signering klar",
        untranslated: true,
        sortValue: true
      },
      {
        value: "lastReminder",
        text: "Påmind",
        untranslated: true,
        sortValue: true
      },
      {
        value: "pendingSignatoryParty",
        text: "Just nu hos",
        untranslated: true,
        sortValue: true
      },
      {
        value: "noOfAttempts",
        text: "Försök",
        untranslated: true,
        sortValue: true
      },
      {
        value: "status",
        text: "Status",
        untranslated: true,
        sortValue: true
      },
      {
        value: "action1",
        width: 1
      },
      {
        value: "action2",
        width: 1
      }
    ]
  })
});
