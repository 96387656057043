import { render, staticRenderFns } from "./CreateProcurementReview.vue?vue&type=template&id=1762b697&scoped=true&"
import script from "./CreateProcurementReview.vue?vue&type=script&lang=ts&"
export * from "./CreateProcurementReview.vue?vue&type=script&lang=ts&"
import style0 from "./CreateProcurementReview.vue?vue&type=style&index=0&id=1762b697&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1762b697",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VDataTable,VDivider,VList,VListItem,VListItemContent,VRow,VSheet})
