





































































import Vue from "vue";

export default Vue.extend({
  computed: {
    text(): string {
      let res = "";
      res +=
        "With the release of 1.45 the authentication server has been replaced.";
      res +=
        " The average user should experience little to no change in his everyday work, but one can never be certain.";
      res +=
        " If you have trouble logging in don't hesitate to contact our support.";
      res +=
        "\n\nIn the meantime, great changes are in the works. Developers are working away effortlessly in the shadows.";
      res +=
        " Features unheard of are lurking around the corner, biding their time, seeing when their release will come...";
      return res;
    }
  }
});
