

































































import Vue from "vue";
import { Culture, FileParameter } from "@/scripts/cld.api";
import { cultures } from "@/scripts/misc/enumLists";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { langs, t } from "@/scripts/language/i18n";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";
import FileInput from "@/components/shared/input/FileInput.vue";
import DocumentDropZone from "@/components/web/documents/DocumentDropZone.vue";
import { cultureName } from "@/scripts/misc/enumNames";

export type DocumentFile = {
  culture: Culture;
  diskName: string | undefined;
  fileFormat: string | undefined;
  fileParameter: FileParameter;
};

export default Vue.extend({
  components: {
    CheckboxTiny,
    FileInput,
    DocumentDropZone
  },
  props: {
    files: Array as () => DocumentFile[],
    editable: Boolean
  },
  computed: {
    cultures(): Culture[] {
      return cultures()
        .map(c => c.id as Culture)
        .sort((a, b) => (a === Culture.English ? -99 : a - b));
    }
  },
  methods: {
    fileWithCulture(c: Culture): FileParameter | undefined {
      const res = this.files.find(f => f.culture === c);
      if (res) {
        return res.fileParameter;
      }
      return undefined;
    },
    fileNameWithCulture(c: Culture): string | undefined {
      const file = this.fileWithCulture(c);
      if (!file) {
        return undefined;
      }
      return file.fileName;
    },
    diskNameWithCulture(c: Culture): string | undefined {
      return this.files.find(f => f.culture === c)?.diskName;
    },
    removeFile(c: Culture) {
      const index = this.files.findIndex(f => f.culture === c);
      if (index !== -1) {
        this.files.splice(index, 1);
      }
    },
    langImg(c: Culture): any {
      return langs.find(l => l.locale === c)!.image;
    },
    viewDocument(c: Culture) {
      const file = this.files.find(f => f.culture === c)!;
      viewDocument(file.diskName, file.fileFormat);
    },
    addFile(file: FileParameter) {
      if (this.files.some(f => f.culture === this.culture)) {
        popupDialog({
          title: t("ReplaceFile"),
          body: t("ReplaceFileDescription").replaceAll(
            "{0}",
            cultureName(this.culture)
          ),
          btnText1: t("Replace"),
          btnColor1: "warning",
          btnCallback1: () => {
            this.actuallyAddFile(file);
          },
          btnText2: t("Cancel"),
          btnCallback2: () => {}
        });
      } else {
        this.actuallyAddFile(file);
      }
    },
    actuallyAddFile(file: FileParameter) {
      this.removeFile(this.culture);
      this.files.push({
        culture: this.culture,
        diskName: undefined,
        fileFormat: undefined,
        fileParameter: file
      });
    }
  },
  data: (): {
    culture: Culture;
  } => ({
    culture: Culture.English
  })
});
