



















































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";

export default Vue.extend({
  components: {
    TextSingle
  },
  props: {
    value: String,
    texts: Array as () => string[],
    label: String,
    placeholder: String,
    clearable: Boolean,
    disabled: Boolean,
    onlyOnBlur: Boolean,
    tabindex: String,
    rules: Array,
    asterisk: Boolean,
    autofocus: Boolean,
    readonly: Boolean,
    compact: Boolean,
    maxLength: Number,
    loading: Boolean
  },
  methods: {
    input(val: string | null) {
      this.$emit("input", val);
      this.suggestions = true;
    },
    clickOutside(event: any) {
      this.suggestions = false;
    },
    clicked(text: string) {
      this.input(text);
    }
  },
  data: (): { suggestions: boolean } => ({
    suggestions: false
  })
});
