
















































































































































import Vue from "vue";
import {
  UserConfigurationType,
  CostReportSearchCriteria,
  CostReportContainerDto,
  CostReportOrderBy,
  CargoType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { currencyName, truckTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: CostReportContainerDto
  },
  computed: {
    tableHeight(): number {
      const numCurrencies = this.reportContainer.prices!.length;
      return Math.max(
        400,
        this.$store.getters[gets.dimensions].height - 300 - 52 * numCurrencies
      );
    },
    searchCriteria(): CostReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.CostReport,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: CostReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: CostReportOrderBy.DeliveryLocation
      });
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: CostReportOrderBy.TruckType
      });
      headers.push({
        value: "unit",
        text: "Unit",
        sortValue: CostReportOrderBy.Unit
      });
      headers.push({
        value: "numLoads",
        text: "NumberOfLoads",
        sortValue: CostReportOrderBy.NumLoads
      });
      if (this.searchCriteria.cargoType === CargoType.Container) {
        headers.push({
          value: "numContainers",
          text: "NoOfContainers",
          sortValue: CostReportOrderBy.NumContainers
        });
      }
      headers.push({
        value: "numBroadcasts",
        text: "Broadcast",
        sortValue: CostReportOrderBy.NumBroadcasts
      });
      headers.push({
        value: "avgRanking",
        text: "AverageRank",
        sortValue: CostReportOrderBy.AvgRanking
      });
      headers.push({
        value: "totalPrice",
        text: "TotalCost",
        sortValue: CostReportOrderBy.TotalPrice
      });
      headers.push({
        value: "avgPrice",
        text: "AveragePrice",
        sortValue: CostReportOrderBy.AvgPrice
      });
      headers.push({
        value: "pricePerVolume",
        text: "PricePerM3",
        sortValue: CostReportOrderBy.PricePerVolume
      });
      if (this.searchCriteria.cargoType === CargoType.Road) {
        headers.push({
          value: "pricePerKm",
          text: "PricePerKm",
          sortValue: CostReportOrderBy.PricePerKm
        });
      }
      headers.push({
        value: "currencyType",
        text: "Currency",
        sortValue: CostReportOrderBy.CurrencyType
      });
      return headers;
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    }
  }
});
