



























































































































































































import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Vue from "vue";
import {
  ApiConfiguration,
  ContainerDto,
  LoadsSupplierClient,
  LoadSupplierEditContainerDto,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import {
  errorDialog,
  saveBeforeContinueDialog
} from "@/scripts/misc/popupDialogs";
import { ble } from "@/scripts/misc/apiErrors";
import objectsEqual from "@/scripts/misc/objectsEqual";
import { currencies } from "@/scripts/misc/enumLists";
export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    NumberInput,
    Dropdown,
    DatePicker
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.loading = true;
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      return `${this.$t("EditContainers")} - ${this.$t("Load")} ${this.loadId}`;
    },
    pendingChanges(): boolean {
      return !objectsEqual(this.saveDto, this.editContainerDraft);
    },
    containers(): ContainerDto[] {
      return this.saveDto!.containers!;
    },
    loadId(): number {
      return this.loadIds[0]!;
    },
    currencies(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: "JPY" as any,
          text: "JPY"
        }),
        ...currencies().map(
          c =>
            new RepositoryDto({
              id: c.text as any,
              text: c.text
            })
        )
      ];
    }
  },
  methods: {
    close() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.save();
          },
          () => {
            this.closeCallback();
          }
        );
      } else {
        this.closeCallback();
      }
    },
    closeCallback() {
      this.$emit("close");
    },
    save() {
      this.saveInProgress = true;
      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .saveContainerDetails(this.saveDto!)
        .then(() => {
          this.saveInProgress = false;
          this.closeCallback();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.closeCallback();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    init() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .terminals()
        .then(res => {
          this.terminals = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });

      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .preEditContainerDetails(this.loadId)
        .then(res => {
          this.saveDto = res;
          this.editContainerDraft = this.copy(this.saveDto);
          this.loading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    copy(obj: LoadSupplierEditContainerDto): LoadSupplierEditContainerDto {
      return new LoadSupplierEditContainerDto({
        bookingNo: obj.bookingNo,
        eta: obj.eta,
        etd: obj.etd,
        dateForClosing: obj.dateForClosing,
        terminalId: obj.terminalId,
        stuffingLocation: obj.stuffingLocation,
        shipName: obj.shipName,
        blNo: obj.blNo,
        loadId: obj.loadId,
        thcValue: obj.thcValue,
        thcCurrency: obj.thcCurrency,
        containers: obj.containers!.map(
          c =>
            new ContainerDto({
              id: c.id,
              containerNo: c.containerNo,
              sealNo: c.sealNo,
              weight: c.weight,
              volume: c.volume,
              packages: c.packages,
              blMark: c.blMark,
              externalContainerId: c.externalContainerId
            })
        )
      });
    }
  },
  data: (): {
    loading: boolean;
    terminals: RepositoryDto[];
    saveDto?: LoadSupplierEditContainerDto;
    editContainerDraft?: LoadSupplierEditContainerDto;
    saveInProgress: boolean;
  } => ({
    loading: true,
    terminals: [],
    saveDto: undefined,
    saveInProgress: false,
    editContainerDraft: undefined
  })
});
