var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"table-sheet",attrs:{"elevation":"1","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.truckTypeRow.supplierRows,"mobile-breakpoint":0,"items-per-page":-1,"custom-sort":_vm.customSort,"must-sort":"","sort-by":"rank","hide-default-header":"","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.truckType",fn:function(){return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.truckTypeName(_vm.truckTypeRow.truckType)))])]},proxy:true},{key:"item.supplierName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.supplierName))])]}},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.rank ? item.rank : "-"))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.price ? _vm.money(item.price, 0) : "-"))])]}},{key:"item.prevRank",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.prevRank ? item.prevRank : "-"))])]}},{key:"item.prevPrice",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.prevPrice ? _vm.money(item.prevPrice, 0) : "-"))])]}},{key:"item.priceDiff",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines",class:_vm.diffColor(item)},[_vm._v(_vm._s(item.priceDiff === null ? "-" : _vm.money(item.priceDiff, 0)))])]}},{key:"item.priceDiffShare",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines",class:_vm.diffColor(item)},[_vm._v(_vm._s(item.priceDiffShare === null ? "-" : _vm.percentage(item.priceDiffShare, 1)))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }