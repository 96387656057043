




































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import {
  AddSupplierToProcurementsDto,
  AgreementsClient,
  ApiConfiguration,
  CargoType,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { cargoTypes } from "@/scripts/misc/enumLists";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    SearchCard,
    Dropdown,
    DropdownMulti,
    Autocomplete
  },
  watch: {
    cargoType: {
      handler() {
        this.fetchSuppliers();
        this.fetchClients();
      },
      immediate: true
    }
  },
  computed: {
    supplierName(): string {
      const supplier = this.suppliers.find(s => s.id === this.supplierId);
      if (!supplier) {
        return "<INGEN>";
      }
      return supplier.text!;
    },
    numProcurements(): number {
      return this.clientIds.length;
    }
  },
  methods: {
    fetchSuppliers() {
      this.suppliers = [];
      this.supplierId = undefined;
      cached("suppliersForSupportAdmin", this.cargoType)
        .then(res => {
          this.suppliers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      this.clients = [];
      this.clientIds = [];
      new AgreementsClient(new ApiConfiguration(this.$store))
        .clientsWithProcurements(this.cargoType)
        .then(res => {
          this.clients = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    click() {
      this.saveInProgress = true;
      new AgreementsClient(new ApiConfiguration(this.$store))
        .addSupplierToProcurements(
          new AddSupplierToProcurementsDto({
            cargoType: this.cargoType,
            supplierId: this.supplierId!,
            clientIds: this.clientIds
          })
        )
        .then(() => {
          this.saveInProgress = false;
          this.supplierId = undefined;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    cargoType: CargoType;
    supplierId: number | undefined;
    clientIds: number[];
    cargoTypes: RepositoryDto[];
    suppliers: RepositoryDto[];
    clients: RepositoryDto[];
    saveInProgress: boolean;
  } => ({
    cargoType: CargoType.Road,
    supplierId: undefined,
    clientIds: [],
    cargoTypes: cargoTypes(),
    suppliers: [],
    clients: [],
    saveInProgress: false
  })
});
