

























































































import Vue from "vue";
import {
  UserConfigurationType,
  LoadStatisticsReportSearchCriteria,
  LoadStatisticsReportContainerDto,
  RepositoryDto,
  LoadStatisticsReportSplit,
  LoadStatisticsReportTab,
  ActorType
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import SwitchSingle from "@/components/shared/input/SwitchSingle.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    DropdownMulti,
    SwitchSingle,
    SwitchDouble
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    showPercentage: Boolean,
    reportContainer: LoadStatisticsReportContainerDto
  },
  computed: {
    searchCriteria(): LoadStatisticsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadStatisticsReport,
        1
      );
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    mergeItems(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: LoadStatisticsReportSplit.Load,
          text: t("Load")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.Actors,
          text:
            this.actorType === ActorType.Client ? t("Supplier") : t("Client")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.Units,
          text: t("Unit")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.TruckTypes,
          text: t("TruckType")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.PickupLocations,
          text: t("PickupLocation")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.PickupCountries,
          text: t("PickupCountry")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.DeliveryLocations,
          text: t("DeliveryLocation")
        }),
        new RepositoryDto({
          id: LoadStatisticsReportSplit.DeliveryCountries,
          text: t("DeliveryCountry")
        })
      ];
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  },
  data: (): {
    tabs: { id: LoadStatisticsReportTab; text: string; icon: string }[];
  } => ({
    tabs: [
      {
        id: LoadStatisticsReportTab.Bookings,
        text: t("BookingStatistics"),
        icon: "mdi-book-check"
      },
      {
        id: LoadStatisticsReportTab.Deliveries,
        text: t("DeliveryPrecision"),
        icon: "mdi-truck-fast"
      }
    ]
  })
});
