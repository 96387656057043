



















































import Vue from "vue";
import {
  ApiConfiguration,
  RepositoryDto,
  StatisticsClient,
  ActiveUsersStatisticsSearchCriteria,
  ActiveUsersStatisticsDto,
  ActiveUsersStatisticsDatePrecision
} from "@/scripts/cld.api";
import { ChartData, ChartDataset } from "chart.js";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import BezierChart, {
  AxisUnit,
  chartColors
} from "@/components/shared/charts/BezierChart.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import { localeCode } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";
import neverHappens from "@/scripts/misc/neverHappens";

enum UserType {
  All = 0,
  Web = 1,
  Integration = 2,
  Client = 3,
  Supplier = 4,
  Customer = 5,
  Terminal = 6
}

const createCriteria = () => {
  return new ActiveUsersStatisticsSearchCriteria({
    fromDate: new MomentX().addHours(-24),
    toDate: new MomentX(),
    precision: ActiveUsersStatisticsDatePrecision.Hours
  });
};

export default Vue.extend({
  components: {
    SearchCard,
    BezierChart,
    DatePicker,
    Dropdown,
    DropdownMulti,
    SkeletonLoader
  },
  watch: {
    criteria: {
      handler() {
        this.fetchStatistics();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 290);
    },
    yAxisUnit(): AxisUnit {
      return AxisUnit.Number;
    },
    chartData(): ChartData<"line", number[], string> {
      let labels: string[] = [];
      let datasets: ChartDataset<"line", number[]>[] = [];
      if (this.statistics.length > 0) {
        labels = this.statistics.map(d =>
          this.criteria.precision === ActiveUsersStatisticsDatePrecision.Hours
            ? d.date.shortTimePrint().toString()
            : this.criteria.precision ===
              ActiveUsersStatisticsDatePrecision.Days
            ? d.date.dateTextPrint(localeCode())
            : d.date.monthNamePrint(localeCode())
        );
        const userTypesToDraw =
          this.userTypes.length > 0 ? this.userTypes : [UserType.All];
        datasets = userTypesToDraw.map((t, index) => ({
          label: this.label(t),
          cubicInterpolationMode: "monotone",
          borderColor: chartColors(index),
          data: this.statistics.map(d => this.getValue(d, t))
        }));
      }
      return {
        labels: labels,
        datasets: datasets
      };
    }
  },
  methods: {
    fetchStatistics() {
      this.loading = true;
      new StatisticsClient(new ApiConfiguration(this.$store))
        .activeUsersStatistics(this.criteria)
        .then(res => {
          this.statistics = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.criteria = createCriteria();
    },
    label(userType: number): string {
      return (
        this.userTypesItems.find(t => t.id === userType)?.text || "Sammanlagt"
      );
    },
    getValue(stat: ActiveUsersStatisticsDto, userType: UserType): number {
      switch (userType) {
        case UserType.All:
          stat.activeUsers;
        case UserType.Web:
          return (
            stat.activeClients +
            stat.activeSuppliers +
            stat.activeCustomers +
            stat.activeTerminals
          );
        case UserType.Integration:
          return stat.activeIntegrationUsers;
        case UserType.Client:
          return stat.activeClients;
        case UserType.Supplier:
          return stat.activeSuppliers;
        case UserType.Customer:
          return stat.activeCustomers;
        case UserType.Terminal:
          return stat.activeTerminals;
        default:
          return neverHappens(userType);
      }
    }
  },
  data: (): {
    loading: boolean;
    statistics: ActiveUsersStatisticsDto[];
    criteria: ActiveUsersStatisticsSearchCriteria;
    userTypes: number[];
    userTypesItems: RepositoryDto[];
    precisionItems: RepositoryDto[];
  } => ({
    loading: false,
    statistics: [],
    criteria: createCriteria(),
    userTypes: [],
    userTypesItems: [
      new RepositoryDto({ id: UserType.Web, text: "Webb" }),
      new RepositoryDto({ id: UserType.Integration, text: "Integration" }),
      new RepositoryDto({ id: UserType.Client, text: "Beställare" }),
      new RepositoryDto({ id: UserType.Supplier, text: "Leverantör" }),
      new RepositoryDto({ id: UserType.Customer, text: "Kund" }),
      new RepositoryDto({ id: UserType.Terminal, text: "Terminal" })
    ],
    precisionItems: [
      new RepositoryDto({
        id: ActiveUsersStatisticsDatePrecision.Hours,
        text: "Per timme"
      }),
      new RepositoryDto({
        id: ActiveUsersStatisticsDatePrecision.Days,
        text: "Per dygn"
      }),
      new RepositoryDto({
        id: ActiveUsersStatisticsDatePrecision.Months,
        text: "Per månad"
      })
    ]
  })
});
