
























































































































































































































































































































import Vue from "vue";
import {
  SupplierProcurementContainerDto,
  SupplierProcurementSearchCriteria,
  UserConfigurationType,
  CargoType,
  RepositoryDto,
  SupplierProcurementRowDto,
  TruckType,
  SupplierProcurementOrderBy,
  DocumentGroupDto
} from "@/scripts/cld.api";
import {
  SupplierProcurementConfig,
  TableHeader,
  TableOptions
} from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import { currencyName } from "@/scripts/misc/enumNames";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { bestLang, t } from "@/scripts/language/i18n";
import { ProcurementRowAction } from "@/views/supplier/Procurements.vue";
import ProcurementDateTooltip from "@/components/web/agreements/supplier/ProcurementDateTooltip.vue";
import ProcurementTablePriceInputs from "@/components/web/agreements/supplier/ProcurementTablePriceInputs.vue";
import neverHappens from "@/scripts/misc/neverHappens";
import { gets } from "@/scripts/store/constants";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";
import { popupDialog } from "@/scripts/misc/popupDialogs";

interface ProcurementMenuOption {
  id: ProcurementRowAction;
  text: string;
  icon: string;
  enabled: boolean;
  tooltip: string;
}

export default Vue.extend({
  components: {
    CustomTableHeader,
    EllipsisTableItem,
    NumberInput,
    TextMulti,
    ProcurementDateTooltip,
    ProcurementTablePriceInputs
  },
  props: {
    cargoType: Number as () => CargoType,
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    procurementContainer: SupplierProcurementContainerDto,
    hasChanges: Function,
    editable: Boolean,
    interactable: Boolean
  },
  computed: {
    tableHeight(): number {
      return Math.max(
        400,
        this.$store.getters[gets.dimensions].height -
          (this.interactable ? 365 : 282)
      );
    },
    searchCriteria(): SupplierProcurementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurement,
        this.cargoType
      );
    },
    conf(): SupplierProcurementConfig {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurementConfig,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    basePriceName(): string {
      switch (this.cargoType) {
        case CargoType.Road:
          return t("OfferedPrice");
        case CargoType.Railroad:
          return t("OfferedPrice");
        case CargoType.Container:
          return t("PricePerContainer");
        case CargoType.MachineTransport:
          return t("PricePerKm");
        default:
          return neverHappens(this.cargoType);
      }
    },
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.cargoType);
    },
    routeNameColspan(): number {
      switch (this.cargoType) {
        case CargoType.Road:
          return 2;
        case CargoType.Railroad:
          return 1;
        case CargoType.Container:
          return 2;
        case CargoType.MachineTransport:
          return 2;
        default:
          return neverHappens(this.cargoType);
      }
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "actions",
          text: "",
          width: 1
        },
        {
          value: "clientName",
          text: "Client",
          width: 1,
          sortValue: !this.interactable
            ? undefined
            : SupplierProcurementOrderBy.Client
        },
        {
          value: "truckTypes",
          text: "",
          customHeader: true,
          width: 500
        }
      ];
      if (this.isRoad || this.isMachineTransport) {
        headers.push({
          value: "stopPrice",
          text: "StopPrice",
          tooltip: "StopPriceDesc",
          width: 1
        });
      }
      if (this.isContainer) {
        headers.push({
          value: "serviceFee",
          text: "ServiceFee",
          width: 1
        });
      }
      headers.push({
        value: "currency",
        text: "Currency",
        width: 1,
        sortValue: !this.interactable
          ? undefined
          : SupplierProcurementOrderBy.CurrencyType
      });
      if (this.isRoad || this.isMachineTransport) {
        headers.push({
          value: "distance",
          text: "km",
          untranslated: true,
          width: 1,
          sortValue: !this.interactable
            ? undefined
            : SupplierProcurementOrderBy.Distance
        });
      }
      headers.push(
        ...[
          {
            value: "estimate",
            text: "Estimation",
            tooltip: "EstimatedLoadDuringAgreementPeriod",
            width: 1
          },
          {
            value: "share",
            text: "Share",
            tooltip: "EstimatedShareOfLoads",
            width: 1
          },
          { value: "note", text: "Note", width: 180 }
        ]
      );
      return headers;
    },
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    },
    isContainer(): boolean {
      return this.cargoType === CargoType.Container;
    },
    isMachineTransport(): boolean {
      return this.cargoType === CargoType.MachineTransport;
    }
  },
  methods: {
    currencyName: currencyName,
    shorten(s: string): string {
      const max = 800;
      const newline22 = s.split("\n", 22).join("\n").length;
      const limitingIndex = Math.min(max, newline22);
      if (s.length > limitingIndex) {
        return (
          s.substring(0, limitingIndex) + "...\n\n" + t("ClickToReadTheRest")
        );
      }
      return s;
    },
    showTooltipDialog(tooltip: string) {
      popupDialog({
        title: t("MessageToSuppliers"),
        body: tooltip,
        btnText1: "OK",
        btnColor1: "success",
        width: 800,
        btnCallback1: () => {}
      });
    },
    viewDocument(docGroup: DocumentGroupDto) {
      const locales = docGroup.documents!.map(d => d.culture);
      const best = bestLang(locales).locale;
      const document = docGroup.documents!.find(d => d.culture === best)!;
      viewDocument(document.diskName, document.fileFormat);
    },
    search() {
      this.$emit("search");
    },
    menuOptions(item: SupplierProcurementRowDto): ProcurementMenuOption[] {
      const options: ProcurementMenuOption[] = [];

      const importPrevEnabled = item.prevPriceRows!.some(p =>
        item.priceRows!.some(x => p.basePrice && x.truckType === p.truckType)
      );
      options.push({
        id: ProcurementRowAction.ImportPrev,
        text: t("ImportFromLatestAgreement"),
        icon: "mdi-history",
        enabled: importPrevEnabled,
        tooltip: importPrevEnabled
          ? t("ImportPrevAgreementTooltip")
          : t("NoPricesFound")
      });

      if (this.isRoad) {
        const percentageTooltip = !item.priceRows!.some(
          p => p.truckType === TruckType.TruckAndWagon && !!p.basePrice
        )
          ? t("YouMustFirstEnterAPriceFor").replace(
              "{0}",
              t("TruckTypeTruckAndWagon").toLowerCase()
            )
          : !this.conf.truckPercentage && !this.conf.wagonPercentage
          ? t("ToUseThisFeature")
              .replace("{0}", t("Tools"))
              .replace("{1}", t("PriceSettings"))
          : "";
        options.push({
          id: ProcurementRowAction.CalculatePercentage,
          text: t("CalculateBasedOnTW"),
          icon: "mdi-percent",
          enabled: !percentageTooltip,
          tooltip: percentageTooltip
        });

        const distanceTooltip = !item.distance
          ? t("NoDistance")
          : !this.conf.kmPrices.some(
              c => c.currency === item.currency && c.value
            )
          ? t("ToUseThisFeature")
              .replace("{0}", t("Tools"))
              .replace("{1}", t("PriceSettings"))
          : "";
        options.push({
          id: ProcurementRowAction.CalculateDistance,
          text: t("CalculateBasedOnDist"),
          icon: "mdi-calculator-variant",
          enabled: !distanceTooltip,
          tooltip: distanceTooltip
        });

        const distPercentageTooltip = !item.distance
          ? t("NoDistance")
          : !this.conf.kmPrices.some(
              c => c.currency === item.currency && c.value
            ) ||
            (!this.conf.truckPercentage && !this.conf.wagonPercentage)
          ? t("ToUseThisFeature")
              .replace("{0}", t("Tools"))
              .replace("{1}", t("PriceSettings"))
          : "";
        options.push({
          id: ProcurementRowAction.CalculateDistanceAndPercentage,
          text: t("CalculateBasedOnDistTW"),
          icon: "mdi-percent",
          enabled: !distPercentageTooltip,
          tooltip: distPercentageTooltip
        });
      }
      if (this.isRoad || this.isMachineTransport) {
        const stopPriceTooltip = !this.conf.stopPrices.some(
          c => c.currency === item.currency && c.value
        )
          ? t("ToUseThisFeature")
              .replace("{0}", t("Tools"))
              .replace("{1}", t("PriceSettings"))
          : "";
        options.push({
          id: ProcurementRowAction.StopPrice,
          text: t("GetStopPrice"),
          icon: "mdi-routes-clock",
          enabled: !stopPriceTooltip,
          tooltip: stopPriceTooltip
        });
      }
      return options;
    },
    optionClick(id: ProcurementRowAction, item: SupplierProcurementRowDto) {
      switch (id) {
        case ProcurementRowAction.Export:
        case ProcurementRowAction.ImportExcel:
        case ProcurementRowAction.Settings:
          //Dessa finns ej på radbasis
          break;
        case ProcurementRowAction.ImportPrev:
          this.$emit("importPrev", [item]);
          break;
        case ProcurementRowAction.StopPrice:
          this.$emit("setStopPrice", [item]);
          break;
        case ProcurementRowAction.CalculateDistance:
          this.$emit("calculateDistance", [item]);
          break;
        case ProcurementRowAction.CalculatePercentage:
          this.$emit("calculatePercentage", [item]);
          break;
        case ProcurementRowAction.CalculateDistanceAndPercentage:
          this.$emit("calculateDistanceAndPercentage", [item]);
          break;
        default:
          neverHappens(id);
      }
    }
  }
});
