


















































import Vue from "vue";
import {
  AgreementRouteStandardPhraseDto,
  StandardPhraseType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    loading: Boolean,
    standardPhrases: Array as () => AgreementRouteStandardPhraseDto[],
    phraseType: Number
  },
  components: {
    CustomTableHeader,
    EllipsisTableItem
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 143);
    },
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "icon",
          text: ""
        },
        {
          value: "usageCount",
          text: "NumberOfRoutes",
          sortValue: true
        },
        {
          value: "subject",
          text: "InternalName",
          sortValue: true
        },
        {
          value: "body",
          text: "Text",
          sortValue: true
        },
        {
          value: "regDate",
          text: "RegDate",
          sortValue: true
        },
        {
          value: "userDisplayName",
          text: "User",
          sortValue: true
        },
        {
          value: "trash-icon",
          text: ""
        }
      ];
      return headers;
    }
  },
  watch: {
    standardPhrases: {
      handler() {
        this.updateUsageCount();
      },
      immediate: true
    }
  },
  methods: {
    clicked(row: AgreementRouteStandardPhraseDto) {
      this.$emit("click", row.id);
    },
    updateUsageCount() {
      this.standardPhrases.forEach(
        sf => (sf.usageCount = this.getNumberOfConnectedRoutes(sf))
      );
    },
    getNumberOfConnectedRoutes(row: AgreementRouteStandardPhraseDto): number {
      switch (this.phraseType) {
        case StandardPhraseType.PickupInfo:
          return row.agreementRoutesPickupInfos!.length;
        case StandardPhraseType.DestinationInfo:
          return row.agreementRoutesDeliveryInfos!.length;
        case StandardPhraseType.SpecialRequirement:
          return row.agreementRoutesSpecialRequirements!.length;
        default:
          return 0;
      }
    },
    canBeDeleted(item: AgreementRouteStandardPhraseDto) {
      return (
        item.agreementRoutesDeliveryInfos?.length === 0 &&
        item.agreementRoutesPickupInfos?.length === 0 &&
        item.agreementRoutesSpecialRequirements?.length === 0
      );
    }
  },
  data: (): { tableOptions: TableOptions } => ({
    tableOptions: new TableOptions({
      itemsPerPage: -1,
      sortBy: ["regDate"],
      sortDesc: [true]
    })
  })
});
