























import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle
  },
  props: {
    dialog: Boolean
  },
  methods: {
    update() {
      this.$emit("update", this.spec);
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    spec: string | null;
    valid: boolean;
  } => ({
    spec: null,
    valid: true
  })
});
