








































































import Vue from "vue";
import MobileSlotBookingCalendar from "@/components/mobile/slots/MobileSlotBookingCalendar.vue";
import MobileSlotBookingExpansionPanels from "@/components/mobile/slots/MobileSlotBookingExpansionPanels.vue";
import MobileSlotBookingNotAvailable from "@/components/mobile/slots/MobileSlotBookingNotAvailable.vue";
import {
  AnonymousLoadingDockCalendarsSearchCriteria,
  AnonymousLoadingSlotSaveDtoContainer,
  AnonymousLoadingSlotsClient,
  ApiConfiguration,
  CargoLoadingDto,
  LoadingDockDto,
  LoadingSlotDto,
  LoadingSlotSaveDto,
  LoadingSlotType,
  LoadStatus,
  RepositoryDto
} from "@/scripts/cld.api";
import { localeCode } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";
import { actions, gets } from "@/scripts/store/constants";
import {
  CargoLoadingContainer,
  CargoLoadingMobileEventsContainer,
  LoadingDockCategory,
  LoadWithCargoLoadingContainer
} from "@/scripts/types";
import { alertDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  components: {
    MobileSlotBookingExpansionPanels,
    MobileSlotBookingCalendar,
    MobileSlotBookingNotAvailable
  },
  watch: {
    "$route.query": {
      handler() {
        const query = { ...this.$route.query };
        const encodedLoadId = query.id ? String(query.id) : undefined;

        this.encodedLoadId = encodedLoadId;
        this.calculateSecondsUntilNowish();
        this.fetchLoad();
        this.fetchSchedule();
      },
      immediate: true
    },
    currentDate() {
      this.fetchSchedule();
    },
    selectedCargoLoadingContainer() {
      this.fetchSchedule();
    },
    valueToWatch: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchSchedule();
        }
      },
      deep: true
    },
    secondsUntilRefreshTime() {
      if (this.secondsUntilRefreshTime <= 0) {
        this.fetchSchedule();
        this.refreshTime = new MomentX().loadingSlotNowish();
      }
    }
  },
  computed: {
    height() {
      return (
        this.$store.getters[gets.dimensions].height -
        205 -
        50 * this.eventsNotBooked.length
      );
    },
    extraSecondsDropdownItems(): RepositoryDto[] {
      return [...Array(7).keys()].map(
        x =>
          new RepositoryDto({
            id: x * 300,
            text: `${x * 5} ${this.$t("LoweredMinutes")}`
          })
      );
    },
    locale(): string {
      return localeCode();
    },
    cargoLoadingContainers(): CargoLoadingContainer[] {
      if (!this.load) {
        return [];
      }
      return this.load.cargoLoadingContainers;
    },
    saveDisabled(): boolean {
      return (
        this.cargoLoadingContainers.every(
          x => x.booking !== undefined && x.booking!.bookedByLoadUserId !== 0
        ) || this.cargoLoadingContainers.some(x => x.booking === undefined)
      );
    },
    selectedCargoLoadingContainer(): CargoLoadingContainer | undefined {
      return this.cargoLoadingContainers.find(
        c => c.id === this.selectedCargoLoadingContainerId
      );
    },
    selectedCategory(): LoadingDockCategory | undefined {
      if (this.selectedCargoLoadingContainerId !== 0) {
        return this.eventsNotBooked.find(
          x => x.cargoLoading!.id === this.selectedCargoLoadingContainerId
        )!.category;
      } else {
        return undefined;
      }
    },
    valueToWatch(): string {
      let val = this.cargoLoadingContainers
        .map(cc => cc.ignoreFromDb)
        .join(",");
      const selCC = this.selectedCargoLoadingContainer;
      if (selCC) {
        val +=
          "," +
          selCC.id +
          "," +
          selCC.extraSeconds +
          "," +
          selCC.cargoLoading.kilograms +
          "," +
          selCC.cargoLoading.seconds;
      }
      return val;
    },
    annulledOrCanceled() {
      if (this.load) {
        return this.load.status === LoadStatus.Annulled;
      }
      return false;
    },
    loadedOrDelivered() {
      if (this.load) {
        return (
          this.load.status === LoadStatus.Loaded ||
          this.load.status === LoadStatus.Delivered
        );
      }
      return false;
    }
  },
  methods: {
    selectedCargoLoadingContainerIdChanged(cargoLoadingContainerId: number) {
      if (cargoLoadingContainerId === 0) {
        this.selectedCargoLoadingContainer!.ignoreFromDb = false;
      }
      this.selectedCargoLoadingContainerId = cargoLoadingContainerId;
      if (cargoLoadingContainerId !== 0) {
        this.selectedCargoLoadingContainer!.ignoreFromDb = true;
      }
    },
    chooseTime(date: MomentX) {
      if (date !== undefined) {
        this.currentDate = date;
      }
    },
    addSeconds(seconds: number) {
      if (seconds !== undefined) {
        this.selectedCargoLoadingContainer!.extraSeconds = seconds;
      }
    },
    addTempSlot(event: CargoLoadingMobileEventsContainer) {
      if (this.currentDate !== undefined) {
        if (event !== undefined) {
          this.events = this.events.filter(x => x !== event);
        }
        event.color = "orange";
        const free = this.timeIsFree(
          this.toMoment(event.start!),
          this.toMoment(event.end!),
          this.events.filter(e => e.category === event.category)
        );
        if (free) {
          this.events.push(event);
        } else {
          event.start = undefined;
          event.end = undefined;
          alertDialog(
            this.$t("NotAvailable").toString(),
            this.$t("CantAddOnBusyTime").toString()
          );
        }
      }
    },
    doBooking(event: CargoLoadingMobileEventsContainer) {
      this.selectedCargoLoadingContainer!.booking = new LoadingSlotDto({
        id: 0,
        type: LoadingSlotType.Booking,
        cargoLoadingId: this.selectedCargoLoadingContainer!.id,
        loadingDockId: event.category.id,
        loadId: this.selectedCargoLoadingContainer!.cargoLoading.loadId,
        bookedActorId: 0,
        bookedByLoadUserId: 0,
        startDate: this.toMoment(event.start!),
        endDate: this.toMoment(event.end!),
        kilograms: this.selectedCargoLoadingContainer!.cargoLoading.kilograms,
        kilogramsBefore: 0,
        kilogramsAfter: 0,
        alterDate: new MomentX(),
        note: this.load!.note,
        truckNo: this.load!.truckNo,
        transportNo: this.load!.transportNo,
        bookingNo: this.load!.bookingNo,
        loadingDockToFillId: undefined,
        contacts: []
      });
      this.selectedCargoLoadingContainerIdChanged(0);
    },
    removeBooking(cargoLoadingContainerId: number) {
      const cargoLoadingContainer = this.cargoLoadingContainers.find(
        c => c.id === cargoLoadingContainerId
      )!;
      cargoLoadingContainer.booking = undefined;
      cargoLoadingContainer.ignoreFromDb = true;
    },
    timeIsFree(
      start: MomentX,
      end: MomentX,
      events: CargoLoadingMobileEventsContainer[]
    ): boolean {
      return events.every(
        x =>
          this.toMoment(x.start!).isSameOrAfter(end) ||
          this.toMoment(x.end!).isSameOrBefore(start)
      );
    },
    setDate(dstring: string | null) {
      if (dstring) {
        this.currentDate = this.toMoment(dstring);
        dstring = this.toDateString(this.toMoment(dstring));
        this.fetchSchedule();
      }
    },
    toMoment(dstring: string): MomentX {
      return new MomentX(dstring, "YYYY-MM-DD HH:mm");
    },
    toDateString(date: MomentX): string | null {
      if (date) {
        return date.datePrint();
      }
      return null;
    },
    fetchLoad() {
      if (!this.encodedLoadId) {
        return;
      }
      this.refreshTime = new MomentX().loadingSlotNowish();
      new AnonymousLoadingSlotsClient(new ApiConfiguration(this.$store))
        .encodedLoadWithCargoLoadings(this.encodedLoadId)
        .then(res => {
          this.load = {
            loadId: res.loadId,
            transportNo: res.transportNo,
            pickupEarliest: res.pickupEarliest,
            pickupLatest: res.pickupLatest,
            note: undefined,
            truckNo: res.truckNo,
            bookingNo: res.bookingNo,
            clientName: res.clientName,
            cargoLoadingContainers: res.cargoLoadings!.map(c => ({
              id: c.id,
              cargoLoading: c,
              extraSeconds: 0,
              booking: undefined,
              ignoreFromDb: false
            })),
            status: res.loadStatus
          };
          this.identifyBookings();
          this.currentDate = res.viewDate;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSchedule() {
      let cargoLoadingToBook: CargoLoadingDto | undefined = undefined;
      this.loadingDocks = [];
      const selCC = this.selectedCargoLoadingContainer;
      if (selCC) {
        cargoLoadingToBook = new CargoLoadingDto({
          id: selCC.cargoLoading.id,
          loadId: selCC.cargoLoading.loadId,
          pickupEarliest: selCC.cargoLoading.pickupEarliest,
          pickupLatest: selCC.cargoLoading.pickupLatest,
          seconds: selCC.cargoLoading.seconds + selCC.extraSeconds,
          kilograms: selCC.cargoLoading.kilograms,
          loadingDockIds: selCC.cargoLoading.loadingDockIds,
          loadingDockNames: selCC.cargoLoading.loadingDockNames
        });
      }
      new AnonymousLoadingSlotsClient(new ApiConfiguration(this.$store))
        .calendars(
          new AnonymousLoadingDockCalendarsSearchCriteria({
            loadId: undefined,
            encodedLoadId: this.encodedLoadId,
            date: this.currentDate,
            cargoLoadingToBook: cargoLoadingToBook,
            now: this.refreshTime,
            premisedBookings: this.cargoLoadingContainers
              .filter(cc => cc.booking !== undefined)
              .map(cc => cc.booking!),
            cargoLoadingsFromDbToIgnore: this.cargoLoadingContainers
              .filter(cc => cc.ignoreFromDb || cc.booking)
              .map(cc => cc.id)
          })
        )
        .then(res => {
          this.loadingDocks = res;
          this.identifyBookings();
          this.populateCalendarData();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    identifyBookings() {
      this.cargoLoadingContainers.map(c => {
        const booking = [...this.loadingDocks.map(d => d.loadingSlots)]
          .flat()
          .find(s => s!.cargoLoadingId === c.id);
        if (booking !== undefined) {
          c.booking = booking;
          if (!this.load!.note) {
            this.load!.note = c.booking.note;
          }
        }
      });
    },
    populateCalendarData() {
      this.categories = [];
      this.events = [];
      this.eventsNotBooked = [];
      this.loadingDocks.forEach(x => {
        let category: LoadingDockCategory = { id: x.id, name: x.name! };
        this.categories.push(category);
        let loadingSlots = x.loadingSlots;

        loadingSlots!.forEach(slot => {
          if (
            !this.cargoLoadingContainers.find(
              x => x.cargoLoading.id === slot.cargoLoadingId
            )
          ) {
            this.populateBookedByAnotherEvents(slot, category);
          }
        });
      });
      this.populateEventsToBook();
    },
    populateEventsToBook() {
      this.cargoLoadingContainers.forEach(cargoLoading => {
        let category = this.categories.find(
          x => x.id === cargoLoading.cargoLoading.loadingDockIds![0]
        );
        let hasBooking = cargoLoading.booking !== undefined;
        let event = {
          name: this.load!.truckNo === null ? "" : this.load!.truckNo!,
          start: hasBooking
            ? cargoLoading.booking!.startDate.shortDateTimePrintSeparated()
            : undefined,
          end: hasBooking
            ? cargoLoading.booking!.endDate.shortDateTimePrintSeparated()
            : undefined,
          color:
            hasBooking && cargoLoading.booking!.bookedByLoadUserId !== 0
              ? "green"
              : "orange",
          category: category!,
          cargoLoading: cargoLoading,
          type: LoadingSlotType.Booking
        };
        if (hasBooking) {
          this.events.push(event);
        }
        this.eventsNotBooked.push(event);
      });
    },
    populateBookedByAnotherEvents(
      slot: LoadingSlotDto,
      category: LoadingDockCategory
    ) {
      this.events.push({
        name: slot.truckNo === null ? "" : slot.truckNo!,
        start: slot.startDate.shortDateTimePrintSeparated(),
        end: slot.endDate.shortDateTimePrintSeparated(),
        color: slot.truckNo === null ? "grey darken-1" : "cyan",
        category: category,
        type:
          slot.truckNo === null
            ? LoadingSlotType.AutoBlocking
            : LoadingSlotType.Open,
        cargoLoading: undefined
      });
    },
    save() {
      this.saveInProgress = true;
      new AnonymousLoadingSlotsClient(new ApiConfiguration(this.$store))
        .saveLoadingSlots(
          new AnonymousLoadingSlotSaveDtoContainer({
            encodedLoadId: this.encodedLoadId,
            loadId: 0,
            truckNo: this.load!.truckNo,
            bookingNo: this.load!.bookingNo,
            note: this.load!.note,
            now: this.refreshTime,
            loadingSlots: this.cargoLoadingContainers.map(
              c =>
                new LoadingSlotSaveDto({
                  cargoLoadingId: c.booking!.cargoLoadingId,
                  loadingDockId: c.booking!.loadingDockId,
                  startDate: c.booking!.startDate,
                  endDate: c.booking!.endDate,
                  kilograms: c.booking!.kilograms
                })
            )
          })
        )
        .then(() => {
          this.load = undefined;
          this.saveInProgress = false;
          this.fetchLoad();
          this.fetchSchedule();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    calculateSecondsUntilNowish() {
      this.secondsUntilRefreshTime =
        this.refreshTime.unix() - new MomentX().unix();
      setTimeout(this.calculateSecondsUntilNowish, 1000);
    }
  },
  data: (): {
    events: CargoLoadingMobileEventsContainer[];
    eventsNotBooked: CargoLoadingMobileEventsContainer[];
    categories: LoadingDockCategory[];
    bookedSiloOne: MomentX | undefined;
    siloEvent: any;
    extraMinutesSiloOne: number;
    currentDate: MomentX;
    loadingDocks: LoadingDockDto[];
    load?: LoadWithCargoLoadingContainer;
    loadId: number;
    encodedLoadId: string | undefined;
    saveInProgress: Boolean;
    selectedCargoLoadingContainerId: number;
    refreshTime: MomentX;
    secondsUntilRefreshTime: number;
  } => ({
    events: [],
    eventsNotBooked: [],
    categories: [],
    bookedSiloOne: undefined,
    siloEvent: undefined,
    extraMinutesSiloOne: 0,
    currentDate: new MomentX().startOfDay(),
    loadingDocks: [],
    load: undefined,
    loadId: 412329,
    encodedLoadId: undefined,
    saveInProgress: false,
    selectedCargoLoadingContainerId: 0,
    refreshTime: new MomentX().loadingSlotNowish(),
    secondsUntilRefreshTime: Infinity
  })
});
