






































































import Vue from "vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { BackgroundTaskStatusDto } from "@/scripts/cld.api";
import { triggerStateName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    InfoTooltip
  },
  props: {
    loading: Boolean,
    jobs: Array as () => BackgroundTaskStatusDto[],
    isNightJobs: Boolean
  },
  methods: {
    tooltip(job: BackgroundTaskStatusDto) {
      let tooltip = "";
      tooltip += `State: ${triggerStateName(job.triggerState)}\n`;
      tooltip += `Start: ${job.startAt}\n`;
      if (!this.isNightJobs) {
        tooltip += `Prev fire: ${job.previousFireTime}\n`;
        tooltip += `Times fired: ${job.timesTriggered}\n`;
      }
      return tooltip;
    }
  }
});
