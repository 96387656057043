import { mutations } from "@/scripts/store/constants";
import store from "@/scripts/store/store";
import { LoadActionPermission, LoadActionResponse } from "@/scripts/cld.api";
import neverHappens from "@/scripts/misc/neverHappens";
import { t } from "@/scripts/language/i18n";
import Auth from "@/scripts/auth";
import nextUnique from "./nextUnique";

export interface Notification {
  id: string;
  text: string;
  icon: string;
  timeout: number;
  routeTo: string | undefined;
  color: string;
}

export const pushNotification = (
  text: string,
  icon: string,
  routeTo?: string,
  warning?: boolean
) => {
  const n: Notification = {
    id: nextUnique().toString(),
    text: text,
    icon: icon,
    timeout: 20000,
    routeTo: routeTo,
    color: warning ? "warning" : "success"
  };
  store.commit(mutations.addNotification, n);
};

export const pushLoadNotification = (
  action:
    | "create"
    | "reorder"
    | "create-spot"
    | "delete-spot"
    | "supplier-spot"
    | "annul"
    | "edit"
    | "book"
    | "unbook"
    | "load"
    | "deliver",
  lar: LoadActionResponse
) => {
  let text = "";
  switch (action) {
    case "create":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenCreated")}`;
      break;
    case "reorder":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenReordered")}`;
      break;
    case "create-spot":
      text = `${t("Load")} ${lar.transportNo} ${t("IsNowBeingTendered")}`;
      break;
    case "delete-spot":
      text = `${t("Load")} ${lar.transportNo} ${t(
        "SpotTenderHasBeenCancelled"
      )}`;
      break;
    case "supplier-spot":
      text = t("PricesHaveBeenSubmitted");
      break;
    case "annul":
      text = `${t("Load")} ${lar.transportNo} ${t("LoadHasBeenAnnulled")}`;
      break;
    case "edit":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenAltered")}`;
      break;
    case "book":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenBooked")}`;
      break;
    case "unbook":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenUnbooked")}`;
      break;
    case "load":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenLoaded")}`;
      break;
    case "deliver":
      text = `${t("Load")} ${lar.transportNo} ${t("HasBeenDelivered")}`;
      break;
    default:
      neverHappens(action);
  }
  text += "\n" + lar.routeName;
  pushNotification(
    text,
    "mdi-check-circle",
    `/${Auth.browsingMobile() ? "mobile" : "loads"}?action=${
      LoadActionPermission.Details
    }&ids=[${lar.loadId}]`
  );
};
