






































































import Vue from "vue";
import {
  ProcurementAnalyticsSupplierRowDto,
  ProcurementAnalyticsTruckTypeDto
} from "@/scripts/cld.api";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import { TableHeader } from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { stringCompare } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    CustomTableHeader
  },
  props: {
    truckTypeRow: ProcurementAnalyticsTruckTypeDto
  },
  computed: {
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "truckType",
          text: "",
          untranslated: true,
          width: 140
        },
        {
          value: "supplierName",
          text: "Supplier",
          width: 350
        },
        {
          value: "rank",
          text: "Ranking",
          width: 81,
          sortValue: true
        },
        {
          value: "price",
          text: "UnitBasePrice",
          width: 100,
          sortValue: true
        },
        {
          value: "prevRank",
          text: `${t("Previous")[0]}. ${t("Ranking").toLowerCase()}`,
          tooltip: `${t("Previous")} ${t("Ranking").toLowerCase()}`,
          untranslated: true,
          width: 100,
          sortValue: true
        },
        {
          value: "prevPrice",
          text: `${t("Previous")[0]}. ${t("UnitBasePrice").toLowerCase()}`,
          tooltip: `${t("Previous")} ${t("UnitBasePrice").toLowerCase()}`,
          untranslated: true,
          width: 81,
          sortValue: true
        },
        {
          value: "priceDiff",
          text: "Difference",
          width: 81,
          sortValue: true
        },
        {
          value: "priceDiffShare",
          text: `${t("Difference")} (%)`,
          width: 81,
          sortValue: true,
          untranslated: true
        }
      ];
      return headers;
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    truckTypeName: truckTypeName,
    diffColor(item: ProcurementAnalyticsSupplierRowDto): string {
      if (!item.priceDiff) {
        return "";
      } else if (item.priceDiff > 0) {
        return "red-text";
      } else {
        return "green-text";
      }
    },
    customSort(
      items: ProcurementAnalyticsSupplierRowDto[],
      sortByArr: string[],
      sortDescArr: boolean[]
    ) {
      const sortBy = sortByArr[0];
      const sortDesc = sortDescArr[0];
      items = items.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) *
          stringCompare(
            (a as any)[sortBy] ? (a as any)[sortBy] : Number.MAX_VALUE,
            (b as any)[sortBy] ? (b as any)[sortBy] : Number.MAX_VALUE
          )
      );
      return items;
    }
  }
});
