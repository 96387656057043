



























import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import { actions } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";
import Auth from "@/scripts/auth";
import {
  ApiConfiguration,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    DialogCard,
    DropdownMulti
  },
  props: {
    dialog: Boolean,
    filter: Array as () => string[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchUnits() {
      if (Auth.browsingSupportAdmin()) {
        new RepositoryClient(new ApiConfiguration(this.$store))
          .unitsSupportAdmin(null)
          .then(res => {
            this.units = this.createUnitList(res);
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      } else {
        cached("units")
          .then(res => {
            this.units = this.createUnitList(res);
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    },
    createUnitList(units: string[]) {
      return [
        new RepositoryDto({ id: "NULL" as any, text: t("LoadsWithoutUnits") }),
        ...units.map(r => new RepositoryDto({ id: r as any, text: r }))
      ];
    },
    init() {
      this.draft = [...this.filter];
      this.fetchUnits();
    },
    keepChangesAndClose() {
      this.filter.length = 0;
      this.filter.push(...this.draft);
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    draft: string[];
    units: RepositoryDto[];
  } => ({
    draft: [],
    units: []
  })
});
