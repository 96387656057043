


































































































import Vue from "vue";
import {
  ProcurementPeriodDto,
  RankingMethod,
  RepositoryDto
} from "@/scripts/cld.api";
import { rankingMethods } from "@/scripts/misc/enumLists";
import MomentX from "@/scripts/misc/momentX";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    procurementPeriods: Array as () => ProcurementPeriodDto[],
    baseIndexText: String
  },
  components: {
    DatePicker,
    Dropdown
  },
  watch: {
    procurementPeriods: {
      handler() {
        this.$emit("change", this.valid, this.procurementPeriods.length);
        this.fixDates();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    sheetHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 278);
    },
    lastPeriod(): ProcurementPeriodDto {
      return this.procurementPeriods[this.procurementPeriods.length - 1];
    },
    valid(): boolean {
      return this.procurementPeriods.every(
        p => p.rankingMethod !== undefined && p.from && p.to
      );
    },
    today(): MomentX {
      return new MomentX().startOfDay();
    }
  },
  methods: {
    pauseDuration(period: number): number {
      if (period === 1) {
        return 0;
      }
      const pauseStart = this.procurementPeriods[period - 2].to;
      const pauseEnd = this.procurementPeriods[period - 1].from;
      if (pauseStart && pauseEnd) {
        return pauseEnd.diff(pauseStart) - 1;
      }
      return 0;
    },
    pauseText(period: number): string {
      const pauseDuration = this.pauseDuration(period);
      if (pauseDuration > 0) {
        return t("PauseXDays").replace("{0}", pauseDuration.toString());
      }
      return "";
    },
    fromGte(period: number): MomentX | undefined {
      if (period === 1) {
        return undefined;
      }
      return this.procurementPeriods[period - 2].to.endOfDay();
    },
    qpRanking(period: ProcurementPeriodDto): boolean {
      return period.rankingMethod === RankingMethod.QualityPoints;
    },
    addPeriod() {
      this.procurementPeriods.push(
        new ProcurementPeriodDto({
          number: this.lastPeriod.number + 1,
          from: undefined as any,
          to: undefined as any,
          rankingMethod: undefined as any,
          qualityPointsFrom: undefined as any,
          qualityPointsTo: undefined as any
        })
      );
    },
    removePeriod() {
      this.procurementPeriods.pop();
    },
    fixDates() {
      this.procurementPeriods.map(p => {
        const prevPeriod = this.procurementPeriods[p.number - 2];
        if (prevPeriod && (!p.from || p.from.isSameOrBefore(prevPeriod.to))) {
          p.from = prevPeriod.to.addDays(1);
        }
        if (p.from) {
          if (!p.to || p.to.isBefore(p.from)) {
            p.to = p.from;
          }
          if (!p.qualityPointsFrom) {
            p.qualityPointsFrom = p.to.addYears(-1);
          }
          if (
            !p.qualityPointsTo ||
            p.qualityPointsTo.isBefore(p.qualityPointsFrom)
          ) {
            p.qualityPointsTo = p.qualityPointsFrom.addYears(1);
          }
        }
      });
    }
  },
  data: (): {
    rankingMethods: RepositoryDto[];
  } => ({
    rankingMethods: rankingMethods()
  })
});
