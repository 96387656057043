


























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  ReportsClient,
  ProcurementAnalyticsSearchCriteriaContainer,
  ProcurementAnalyticsSearchCriteria,
  ProcurementAnalyticsContainerDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import ProcurementAnalyticsSearch from "@/components/web/reports/client/ProcurementAnalyticsSearch.vue";
import ProcurementAnalyticsTable from "@/components/web/reports/client/ProcurementAnalyticsTable.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    ProcurementAnalyticsSearch,
    ProcurementAnalyticsTable
  },
  computed: {
    searchCriteria(): ProcurementAnalyticsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ProcurementAnalytics,
        1
      );
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.ProcurementAnalytics,
          subTypeId: 1
        })
        .then(() => {
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new ReportsClient(new ApiConfiguration(this.$store))
        .procurementAnalytics(
          new ProcurementAnalyticsSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.ProcurementAnalytics,
        subTypeId: 1
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .produrementAnalyticsExportToExcel(
          new ProcurementAnalyticsSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    exportLoading: boolean;
    tableOptions: TableOptions;
    reportContainer: ProcurementAnalyticsContainerDto;
  } => ({
    loading: 0,
    exportLoading: false,
    tableOptions: new TableOptions({
      itemsPerPage: 10
    }),
    reportContainer: new ProcurementAnalyticsContainerDto({
      count: 0,
      rows: []
    })
  })
});
