


















































































import Vue from "vue";
import money, { percentage } from "@/scripts/misc/money";
import { actions, gets } from "@/scripts/store/constants";
import {
  ActorType,
  ApiConfiguration,
  CargoType,
  LoadsClient,
  LoadStatus,
  PriceRowDto
} from "@/scripts/cld.api";
import {
  currencyName,
  freightPriceFormula,
  freightPriceFormulaOtherPrice,
  invoicePriceFormulaLong,
  invoicePriceFormulaOtherPrice
} from "@/scripts/misc/enumNames";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { t } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";

interface PriceLine {
  key: string;
  value: any;
  hide?: boolean;
  invalid?: boolean;
}

export const indexDateTooltip = (
  indexFactor: number,
  baseIndexName: string,
  indexDate: MomentX | undefined
): string => {
  let text = `${t("Index")}: ${percentage(indexFactor - 1, 2)}`;
  text += `\n${t("BaseIndex")}: ${baseIndexName}`;
  if (indexDate) {
    text += `\n${t("IndexDate")}: ${indexDate.datePrint()}`;
  }
  text += `\n${t("ClickToReadMoreAboutIndex")}`;
  return text;
};

export default Vue.extend({
  props: {
    loadId: Number as () => number | undefined,
    cargoType: Number as () => CargoType,
    loadStatus: Number as () => LoadStatus,
    expanded: Boolean
  },
  components: {
    InfoTooltip
  },
  watch: {
    loadId: {
      handler() {
        this.fetchPrices();
      },
      immediate: true
    }
  },
  computed: {
    title(): PriceLine {
      const price = this.valueLines.find(
        l => l.key === this.invoicePriceText || l.key === this.freightPriceText
      );
      if (!price) {
        return {
          key: "key",
          value: undefined
        };
      }
      return price;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    valueLines(): PriceLine[] {
      if (!this.price) {
        return [];
      }
      return [
        {
          key: "AgreedPrice",
          value: money(this.price.basePrice, 2),
          invalid: !this.hideOtherPrice
        },
        {
          key: "StopPrice",
          value: money(this.price.stopPrice || 0, 2),
          hide: this.hideStops,
          invalid: !this.hideOtherPrice
        },
        {
          key: "ExtraStops",
          value: this.price.numExtraStops,
          hide: this.hideStops,
          invalid: !this.hideOtherPrice
        },
        {
          key: "ServiceFee",
          value: money(this.price.serviceFee, 2),
          hide: this.hideContainers,
          invalid: !this.hideOtherPrice
        },
        {
          key: "Containers",
          value: this.price.numContainers,
          hide: this.hideContainers,
          invalid: !this.hideOtherPrice
        },
        {
          key: "TruckTypeFee",
          value: money(this.price.initialFee, 2),
          hide: this.hideMachineTransport,
          invalid: !this.hideOtherPrice
        },
        {
          key: "VTLPrice",
          value: money(this.price.vtlPrice, 2),
          hide: this.hideMachineTransport,
          invalid: !this.hideOtherPrice
        },
        {
          key: "EscortCarPriceKM",
          value: money(this.price.escortCarPrice, 2),
          hide: this.hideMachineTransport,
          invalid: !this.hideOtherPrice
        },
        {
          key: "DistanceInKM",
          value: this.price.distance,
          hide: this.hideMachineTransport,
          invalid: !this.hideOtherPrice
        },
        {
          key: "Index",
          value:
            this.price.indexFactor === 1.0
              ? ""
              : money(this.price.indexPrice, 2),
          hide: this.hideIndex,
          invalid: !this.hideOtherPrice
        },
        {
          key: "OtherPrice",
          value: money(this.price.otherPrice, 2),
          hide: this.hideOtherPrice
        },
        { key: "BookingFee", value: money(this.price.bookingFee, 2) },
        {
          key: this.freightPriceText,
          value: money(this.price.freightPrice, 2),
          hide: this.hideFreightPrice
        },
        {
          key: this.invoicePriceText,
          value: money(this.price.invoicePrice, 2),
          hide: this.hideInvoicePrice
        }
      ].filter(l => !l.hide);
    },
    invoicePriceText(): string {
      return this.loadStatus === LoadStatus.Loaded ||
        this.loadStatus === LoadStatus.Delivered
        ? "InvoicePrices"
        : "PreliminaryPrice";
    },
    freightPriceText(): string {
      return this.loadStatus === LoadStatus.Loaded ||
        this.loadStatus === LoadStatus.Delivered
        ? "FreightPrice"
        : "PreliminaryFreightPrice";
    },
    hideStops(): boolean {
      return (
        this.cargoType !== CargoType.Road &&
        this.cargoType !== CargoType.MachineTransport
      );
    },
    hideContainers(): boolean {
      return this.cargoType !== CargoType.Container;
    },
    hideMachineTransport(): boolean {
      return this.cargoType !== CargoType.MachineTransport;
    },
    hideOtherPrice(): boolean {
      return !this.price.otherPrice;
    },
    hideIndex(): boolean {
      return this.cargoType !== CargoType.Road || !this.hideOtherPrice;
    },
    hideInvoicePrice(): boolean {
      return this.actorType === ActorType.Client || !this.price.invoicePrice;
    },
    hideFreightPrice(): boolean {
      return this.actorType === ActorType.Supplier || !this.price.freightPrice;
    }
  },
  methods: {
    currencyName: currencyName,
    tooltip(line: PriceLine): string {
      if (line.key === "Index") {
        return indexDateTooltip(
          this.price.indexFactor,
          this.price.baseIndexName!,
          this.price.indexDate
        );
      }
      let text = "";
      if (line.key === this.freightPriceText) {
        if (this.hideOtherPrice === false) {
          text = freightPriceFormulaOtherPrice(
            (this.cargoType as number) as CargoType
          );
        } else {
          text = freightPriceFormula((this.cargoType as number) as CargoType);
        }
      }
      if (line.key === this.invoicePriceText) {
        if (this.hideOtherPrice === false) {
          text = invoicePriceFormulaOtherPrice(
            (this.cargoType as number) as CargoType
          );
        } else {
          text = invoicePriceFormulaLong(
            (this.cargoType as number) as CargoType
          );
        }
      }
      if (text) {
        text += `\n${t("ClickToReadMoreAboutPrices")}`;
      }
      return text;
    },
    clicked(line: PriceLine) {
      if (line.key === "Index") {
        window.open(
          `/indexes?from=${this.price.baseIndexDate!.datePrint()}&to=${this.price.indexDate!.datePrint()}`,
          "_blank"
        );
      } else {
        window.open(`/price-list`, "_blank");
      }
    },
    fetchPrices() {
      this.price = undefined as any;
      if (!this.loadId) {
        return;
      }
      return new LoadsClient(new ApiConfiguration(this.$store))
        .price(this.loadId)
        .then(res => {
          this.price = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { price: PriceRowDto } => ({
    price: undefined as any
  })
});
