

















import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import ActorDialogTabs from "@/components/web/actor/ActorDialogTabs.vue";
import { ActorType, RepositoryDto } from "@/scripts/cld.api";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    ActorDialogTabs
  },
  computed: {
    actorId(): number {
      return this.$store.getters[gets.userInfo].actorId;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  data: (): {
    countries: RepositoryDto[];
  } => ({
    countries: []
  })
});
