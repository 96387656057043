import MomentX from "@/scripts/misc/momentX";
import {
  CargoType,
  AgreementDataDto,
  ActorType,
  LoadStatus,
  LoadActionPermission,
  LoadOrderBy,
  CargoLoadingDto,
  LoadingSlotDto,
  LoadingSlotType,
  Country,
  Currency,
  TruckType,
  ProfileDto,
  MegaReportSearchCriteria,
  UserLevel
} from "@/scripts/cld.api";
import { t } from "@/scripts/language/i18n";
import neverHappens from "@/scripts/misc/neverHappens";

export interface UserInfo {
  loadUserId: number | undefined;
  actorId: number | undefined;
  actorName: string;
  actorType: ActorType | undefined;
  userLevel: UserLevel | undefined;
  supportAdminLevel: number;
  actorCargoTypes: CargoType[];
}

export const userInfoFromProfileDto = (profile: ProfileDto): UserInfo => {
  return {
    loadUserId: profile.loadUserId,
    actorId: profile.actorId,
    actorName: profile.actorName!,
    actorType: profile.actorType,
    userLevel: profile.userLevel,
    actorCargoTypes: profile.actorCargoTypes!,
    supportAdminLevel: profile.supportAdminLevel
  };
};

export class AgreementDataDtoX extends AgreementDataDto {
  selectable: boolean;
  constructor(data: AgreementDataDto) {
    super(data);
    this.selectable = procurementEditable(
      this.procurementFromDate,
      this.procurementToDate
    );
  }
}

export const procurementEditable = (
  procurementFromDate: MomentX,
  procurementToDate: MomentX
): boolean => {
  return !new MomentX().isBetween(
    procurementFromDate,
    procurementToDate.endOfDay()
  );
};

export interface ConfirmationOptions {
  dialog?: boolean;
  width?: number;
  warning?: boolean;
  noCloseButton?: boolean;
  closeButtonCallback?: Function;
  title?: string;
  body?: string;
  btnText1?: string;
  btnColor1?: string;
  btnCallback1: Function;
  btnText2?: string;
  btnColor2?: string;
  btnCallback2?: Function;
}

export interface InfoMenuObject {
  visible: boolean;
  x: number;
  y: number;
}

export enum Sound {
  Notification1 = 1,
  Countdown = 2
}

export class CalendarConfiguration implements ICalendarConfiguration {
  calendarAutoDateSliding: boolean;
  constructor(vals: ICalendarConfiguration) {
    this.calendarAutoDateSliding = vals.calendarAutoDateSliding;
  }
}

interface ICalendarConfiguration {
  calendarAutoDateSliding: boolean;
}

export class ReadDocuments implements IReadDocuments {
  agreements: number[];
  constructor(vals: IReadDocuments) {
    this.agreements = vals.agreements;
  }
}

interface IReadDocuments {
  agreements: number[];
}

export class MobilePopupConfiguration implements IMobilePopupConfiguration {
  dismissedDates: MomentX[];
  constructor(vals: IMobilePopupConfiguration) {
    this.dismissedDates = vals.dismissedDates;
  }
}

interface IMobilePopupConfiguration {
  dismissedDates: MomentX[];
}

export class SupplierProcurementConfig implements ISupplierProcurementConfig {
  kmPrices: SupplierProcurementConfigEntry[];
  stopPrices: SupplierProcurementConfigEntry[];
  truckPercentage: number | undefined;
  wagonPercentage: number | undefined;
  constructor(vals: ISupplierProcurementConfig) {
    this.kmPrices = vals.kmPrices;
    this.stopPrices = vals.stopPrices;
    this.truckPercentage = vals.truckPercentage;
    this.wagonPercentage = vals.wagonPercentage;
  }
}

interface ISupplierProcurementConfig {
  kmPrices: SupplierProcurementConfigEntry[];
  stopPrices: SupplierProcurementConfigEntry[];
  truckPercentage: number | undefined;
  wagonPercentage: number | undefined;
}

export interface SupplierProcurementConfigEntry {
  truckType?: TruckType;
  currency: Currency;
  value: number | undefined;
}

export enum ChartType {
  Loads,
  Containers,
  BookingFees,
  FreightPrices,
  AvgFreightPrice,
  AvgFreightPricePerVolume,
  PercentDelivered,
  PercentBookedPickup,
  PercentBookedDelivery,
  PercentHasDistanceLoad,
  PercentHasDistanceRoute,
  PercentEconomy,
  PercentBroadcast,
  PercentHasOtherPrice
}

export interface MegaPreset {
  title: string;
  creator: string;
  chartType: ChartType;
  criteria: MegaReportSearchCriteria;
}

export const createMegaReportCriteria = () =>
  new MegaReportSearchCriteria({
    fromDate: new MomentX().addYears(-3).startOfYear(),
    toDate: new MomentX().endOfYear(),
    fromCountries: [],
    toCountries: [],
    fromCounties: [],
    units: [],
    accumulate: false,
    monthsInGroup: 3,
    clientIds: [],
    supplierIds: [],
    cargoTypes: [],
    truckTypes: [],
    loadStatuses: [],
    deviationCauses: [],
    otherPriceCauses: [],
    numExtraStops: [],
    splitBookingFeeType: false,
    stackYears: false
  });

export class TableOptions {
  page: number = 1;
  itemsPerPage: number = 100;
  sortBy: string[] | number[] = [];
  sortDesc: boolean[] = [false];
  groupBy: string[] = [];
  groupDesc: boolean[] = [false];
  multiSort: boolean = false;
  mustSort: boolean = true;
  constructor(data: {
    itemsPerPage?: number;
    sortBy?: string[] | number[];
    sortDesc?: boolean[];
    groupBy?: string[];
  }) {
    this.itemsPerPage =
      data.itemsPerPage === undefined ? this.itemsPerPage : data.itemsPerPage;
    this.sortBy = data.sortBy === undefined ? this.sortBy : data.sortBy;
    this.sortDesc = data.sortDesc === undefined ? this.sortDesc : data.sortDesc;
    this.groupBy = data.groupBy === undefined ? this.groupBy : data.groupBy;
  }
}

export interface TableHeader {
  value: string;
  text?: string;
  id?: number;
  width?: number;
  /*
   * sortValue undefined means it won't be sortable.
   * sortValue true means it will be sorted based on the value field in this interface
   * sortValue as a number means it will be sorted based on that number (which should be an enum). This is used for server side sorting.
   */
  sortValue?: number | boolean;
  untranslated?: boolean;
  customHeader?: boolean;
  multiLine?: boolean;
  tooltip?: string;
}

export interface LoadingSlotBox {
  id: number;
  cargoLoadingId?: number;
  type: LoadingSlotType;
  seconds: number;
  startDate: MomentX;
  endDate: MomentX;
  hovered: boolean;
  color?: string;
  roundedLeft: boolean;
  roundedRight: boolean;
  kilograms?: number;
  kilogramsBefore?: number;
  kilogramsAfter?: number;
  loadId?: number;
  truckNo?: string;
  transportNo?: string;
  bookingNo?: string;
  note?: string;
}

export interface LoadingDockSchedule {
  id: number;
  name: string;
  boxes: LoadingSlotBox[];
}

export interface CargoLoadingContainer {
  id: number;
  cargoLoading: CargoLoadingDto;
  extraSeconds: number;
  booking: LoadingSlotDto | undefined;
  ignoreFromDb: boolean;
}

export interface LoadWithCargoLoadingContainer {
  loadId: number;
  transportNo: string | undefined;
  pickupEarliest: MomentX;
  pickupLatest: MomentX;
  bookingNo: string | undefined;
  truckNo: string | undefined;
  note: string | undefined;
  clientName: string | undefined;
  cargoLoadingContainers: CargoLoadingContainer[];
  status: LoadStatus | undefined;
}

export interface CargoLoadingMobileEventsContainer {
  name: string;
  start: string | undefined;
  end: string | undefined;
  color: string;
  category: LoadingDockCategory;
  type: LoadingSlotType;
  cargoLoading: CargoLoadingContainer | undefined;
}

export interface LoadingDockCategory {
  id: number;
  name: String;
}

export interface SupplierDeleted {
  id: number;
  name: string;
  deleted: boolean;
}

export interface AgreementRouteFilter {
  cargoType: CargoType;
  routeId: string;
  pickupCounty: string;
  pickupZipcode: string;
  pickupCountry: Country | undefined;
  pickupInfo: string;
  deliveryCounty: string;
  deliveryZipcode: string;
  deliveryCountries: Country[] | undefined;
  destinationInfo: string;
  currency: Currency | undefined;
  estimate: boolean | undefined;
  specReq: string;
  tag: string | undefined;
  procurement: boolean | undefined;
  agreementEnd: number | undefined;
}

export class LoadAction {
  id: LoadActionPermission;
  text: string;
  icon: string;
  allowed: boolean;
  lockNeeded: boolean;

  constructor(id: LoadActionPermission, text: string, icon: string) {
    this.id = id;
    this.text = t(text);
    this.icon = icon;
    this.allowed = false;
    this.lockNeeded = false;
  }
}

export interface MultiLoad {
  loadId: number;
  routeName: string;
  pickupEarliest: MomentX;
  deliverLatest: MomentX;
  truckType: TruckType | undefined;
  transportNo: string | undefined;
  clientName: string | undefined;
  numExtraStops: number;
  numContainers: number;
  valid: boolean;
  saved: boolean | undefined;
  customsInfoRequired: boolean;
}

export class CreateLoadPresets {
  unit: string | undefined;
  referenceNo: string | undefined;

  constructor(unit: string | undefined, referenceNo: string | undefined) {
    this.unit = unit || undefined;
    this.referenceNo = referenceNo || undefined;
  }
}

export const spotTenderTabId = 99;

export const loadListTabName = (tabId: number): string => {
  switch (tabId) {
    case LoadStatus.Ordered:
      return t("Ordered");
    case LoadStatus.Booked:
      return t("Booked");
    case LoadStatus.Loaded:
      return t("Loaded");
    case LoadStatus.Delivered:
      return t("Delivered");
    case LoadStatus.Annulled:
      return t("Annulled");
    case spotTenderTabId:
      return t("SpotTender");
    default:
      throw Error("Unknown load tab id");
  }
};

export const loadListTabSortOrder = (tabId: number): number => {
  switch (tabId) {
    case LoadStatus.Ordered:
      return 1;
    case LoadStatus.Booked:
      return 2;
    case LoadStatus.Loaded:
      return 3;
    case LoadStatus.Delivered:
      return 4;
    case LoadStatus.Annulled:
      return 5;
    case spotTenderTabId:
      return 6;
    default:
      throw Error("Unknown load tab id");
  }
};

export class LoadListTab {
  id: number;
  headers: number[];
  constructor(id: number, actorType: ActorType) {
    this.id = id;
    this.headers = this.defaultHeaders(id, actorType);
  }
  defaultHeaders(id: number, actorType: ActorType): LoadOrderBy[] {
    switch (actorType) {
      case ActorType.Client:
        return this.defaultHeadersClient(id);
      case ActorType.Supplier:
        return this.defaultHeadersSupplier(id);
      case ActorType.Customer:
        return this.defaultHeadersCustomer(id);
      case ActorType.Terminal:
        return this.defaultHeadersTerminal(id);
      default:
        return neverHappens(actorType);
    }
  }
  defaultHeadersClient(id: number): LoadOrderBy[] {
    switch (id) {
      case LoadStatus.Ordered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Booked:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedPickupDate,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Loaded:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.LoadedDate,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Delivered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.LoadedDate,
          LoadOrderBy.DeliveryDate,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Annulled:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.AlterDate,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case spotTenderTabId:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.SpotTenderEndDate,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Unit,
          LoadOrderBy.Id
        ];
      default:
        return [LoadOrderBy.Id];
    }
  }
  defaultHeadersSupplier(id: number): LoadOrderBy[] {
    switch (id) {
      case LoadStatus.Ordered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.VisibleToNextSupplier,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.ClientName,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Booked:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedPickupDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Loaded:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.LoadedDate,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Delivered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.LoadedDate,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      case spotTenderTabId:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumExtraStops,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.SpotTenderEndDate,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.ClientName,
          LoadOrderBy.ReferenceNo,
          LoadOrderBy.Id
        ];
      default:
        return [LoadOrderBy.Id];
    }
  }
  defaultHeadersCustomer(id: number): LoadOrderBy[] {
    switch (id) {
      case LoadStatus.Ordered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Booked:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedPickupDate,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Loaded:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.LoadedDate,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Delivered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.DeliveryDate,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.ClientName,
          LoadOrderBy.SupplierName,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      default:
        return [LoadOrderBy.Id];
    }
  }
  defaultHeadersTerminal(id: number): LoadOrderBy[] {
    switch (id) {
      case LoadStatus.Ordered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumRelatedLoads,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Booked:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumRelatedLoads,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Loaded:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumRelatedLoads,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      case LoadStatus.Delivered:
        return [
          LoadOrderBy.PickupLocationFormatted,
          LoadOrderBy.DeliveryLocationFormatted,
          LoadOrderBy.NumRelatedLoads,
          LoadOrderBy.TruckTypeName,
          LoadOrderBy.PickupEarliest,
          LoadOrderBy.DeliveryLatest,
          LoadOrderBy.BookedDeliveryDate,
          LoadOrderBy.TransportNo,
          LoadOrderBy.Id
        ];
      default:
        return [LoadOrderBy.Id];
    }
  }
}
