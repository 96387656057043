




























































import Vue from "vue";
import {
  ActorType,
  ApiConfiguration,
  DirectMessageDto,
  DirectMessagingClient
} from "@/scripts/cld.api";
import MessagesTable from "@/components/web/messages/MessagesTable.vue";
import MessagesSearch from "@/components/web/messages/MessagesSearch.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import nextUnique from "@/scripts/misc/nextUnique";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";

export default Vue.extend({
  components: {
    MessagesTable,
    MessagesSearch,
    EllipsisTableItem
  },
  computed: {
    sheetHeight(): number {
      return Math.max(200, this.$store.getters[gets.dimensions].height - 370);
    },
    message(): DirectMessageDto | undefined {
      return this.messages.find(m => m.id === this.selectedId);
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    search() {
      const unique = nextUnique();
      this.loading = unique;
      new DirectMessagingClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.messages = res;
          this.selectedId = this.messages.length ? this.messages[0].id : 0;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    viewDocument(diskName: string | undefined) {
      const document = this.message!.documents!.find(
        d => d.diskName === diskName
      )!;
      viewDocument(document.diskName, document.fileFormat);
    },
    messageClicked(id: number) {
      const prevId = this.selectedId;
      this.selectedId = id;
      if (this.actorType === ActorType.Supplier) {
        this.markOneAsViewed(prevId);
        this.markOneAsViewed(id);
      }
    },
    markOneAsViewed(id: number) {
      const message = this.messages.find(m => m.id === id)!;
      if (message.viewed) {
        return;
      }
      message.viewed = true;
      this.markAsViewedRestCall(id);
    },
    markAllAsViewed() {
      this.messages.map(m => (m.viewed = true));
      this.markAsViewedRestCall(null);
    },
    markAsViewedRestCall(id: number | null) {
      new DirectMessagingClient(new ApiConfiguration(this.$store))
        .markAsViewed(id)
        .then(() => {
          this.$store.commit(
            mutations.setUnviewedMessages,
            this.messages.filter(m => !m.viewed).length
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: number;
    selectedId: number;
    messages: DirectMessageDto[];
  } => ({
    loading: 0,
    selectedId: 0,
    messages: []
  })
});
