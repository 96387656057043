
















































































































import Vue from "vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import AutocompleteMultiCountry from "@/components/shared/input/AutocompleteMultiCountry.vue";
import { CountryISO } from "@/scripts/cld.api";
import { isoName } from "@/scripts/misc/enumNames";
import ifNotTabbing from "@/scripts/misc/ifNotTabbing";

export interface LocationValue {
  county?: string | null;
  zipCode?: string | null;
  countryId?: CountryISO | null;
  countryIds?: CountryISO[];
}

export default Vue.extend({
  components: {
    Autocomplete,
    TextSingle,
    AutocompleteMultiCountry
  },
  props: {
    location: Object as () => LocationValue,
    label: String,
    placeholder: String,
    counties: Array,
    countries: Array,
    multi: Boolean,
    innerClass: String,
    tabindex: String
  },
  watch: {
    menu() {
      //Prevents flickering
      this.closable = false;
      setTimeout(() => {
        this.closable = true;
      }, 50);
    }
  },
  computed: {
    county: {
      get(): string | null | undefined {
        return this.location.county;
      },
      set(county: string | null) {
        county = county || null;
        this.input({ county: county });
      }
    },
    zipCode: {
      get(): string | null | undefined {
        return this.location.zipCode;
      },
      set(zipCode: string | null) {
        zipCode = zipCode || null;
        this.input({ zipCode: zipCode });
      }
    },
    countryId: {
      get(): number | null | undefined {
        return this.location.countryId;
      },
      set(countryId: number | null) {
        if (countryId === undefined) {
          countryId = null;
        }
        this.input({ countryId: countryId });
      }
    },
    countryIds: {
      get(): number[] {
        return this.location.countryIds || [];
      },
      set(countryIds: number[]) {
        this.input({ countryIds: countryIds });
      }
    },
    filterText(): string {
      const iso = [this.location.countryId, ...(this.location.countryIds || [])]
        .filter((id: CountryISO | null | undefined) => !!id)
        .map(id => isoName(id!))
        .join(",");
      return [this.location.county, this.location.zipCode, iso]
        .filter(e => !!e)
        .join();
    }
  },
  methods: {
    ifNotTabbing: ifNotTabbing,
    input(val: LocationValue) {
      if (val.county === undefined) {
        val.county = this.county;
      }
      if (val.zipCode === undefined) {
        val.zipCode = this.zipCode;
      }
      if (val.countryId === undefined) {
        val.countryId = this.countryId;
      }
      if (val.countryIds === undefined) {
        val.countryIds = this.countryIds;
      }
      this.$emit("input", val);
    },
    clearSelection() {
      this.input({
        county: null,
        zipCode: null,
        countryId: null,
        countryIds: []
      });
      this.$emit("search");
    },
    search() {
      this.$emit("search");
    },
    closeMenu() {
      if (this.closable) {
        this.menu = false;
      }
    },
    clickOutside(event: any) {
      if (!event.target.className.includes("v-list")) {
        this.closeMenu();
      }
    }
  },
  data: (): { menu: boolean; closable: boolean } => ({
    menu: false,
    closable: true
  })
});
