













































































































































































































































import Vue from "vue";
import {
  ApiConfiguration,
  CargoType,
  DocumentGroupDto,
  DocumentsClient,
  PreSupplierEditSpotTenderDto,
  SpotTenderSupplierClient,
  SupplierEditSpotTenderDto,
  SupplierEditSpotTenderPriceDto,
  TruckType
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import DocumentTable from "@/components/shared/ui/DocumentTable.vue";
import { MultiLoad } from "@/scripts/types";
import { actions } from "@/scripts/store/constants";
import { currencyName, truckTypeName } from "@/scripts/misc/enumNames";
import money from "@/scripts/misc/money";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import MomentX from "@/scripts/misc/momentX";
import { localeCode, t } from "@/scripts/language/i18n";
import { errorDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import { geq, leq } from "@/scripts/misc/dateUtils";
import { ble } from "@/scripts/misc/apiErrors";

interface SpotTenderPrice {
  truckType: TruckType;
  price: number | undefined;
  originalPrice: number | undefined;
}

interface SpotTenderOtherInfo {
  bookedPickupDate: MomentX | undefined;
  originalBookedPickupDate: MomentX | undefined;
  bookedDeliveryDate: MomentX | undefined;
  originalBookedDeliveryDate: MomentX | undefined;
  note: string | undefined;
  originalNote: string | undefined;
}

export default Vue.extend({
  components: {
    DialogCard,
    ReadOnlyText,
    GreyTitle,
    LoadChips,
    NumberInput,
    Checkbox,
    DatePicker,
    TextMulti,
    InfoTooltip,
    DocumentTable
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    loadId(): number {
      return this.loadIds[0]!;
    },
    cargoType(): CargoType {
      return CargoType.Road;
    },
    deletePossible(): boolean {
      return this.prices.some(p => !!p.originalPrice);
    },
    savePossible(): boolean {
      if (!this.infoDto || !this.valid || !this.prices.some(p => !!p.price)) {
        return false;
      }
      return (
        this.bookedPickupDateModified ||
        this.bookedDeliveryDateModified ||
        this.noteModified ||
        this.prices.some(p => this.priceModified(p))
      );
    },
    bookedPickupDateModified(): boolean {
      if (
        !this.otherInfo.bookedPickupDate &&
        !this.otherInfo.originalBookedPickupDate
      ) {
        return false;
      }
      return (
        this.otherInfo.bookedPickupDate !==
        this.otherInfo.originalBookedPickupDate
      );
    },
    bookedDeliveryDateModified(): boolean {
      if (
        !this.otherInfo.bookedDeliveryDate &&
        !this.otherInfo.originalBookedDeliveryDate
      ) {
        return false;
      }
      return (
        this.otherInfo.bookedDeliveryDate !==
        this.otherInfo.originalBookedDeliveryDate
      );
    },
    noteModified(): boolean {
      if (!this.otherInfo.note && !this.otherInfo.originalNote) {
        return false;
      }
      return this.otherInfo.note !== this.otherInfo.originalNote;
    },
    endDateInfo(): string {
      return new MomentX().shortDateTimePrintWithTo(
        this.infoDto.endDate,
        localeCode()
      );
    },
    bookedPickupRules(): { (date?: MomentX): boolean | string }[] {
      return [
        geq(this.infoDto.pickupEarliest, "PickupEarliest", "BookedPickupDate"),
        geq(this.infoDto.endDate, "SpotTenderEnds", "BookedPickupDate"),
        leq(this.infoDto.deliverLatest, "DeliveryLatest", "BookedPickupDate")
      ];
    },
    bookedDeliveryRules(): { (date?: MomentX): boolean | string }[] {
      return [
        geq(
          this.infoDto.pickupEarliest,
          "PickupEarliest",
          "BookedDeliveryDate"
        ),
        geq(this.infoDto.endDate, "SpotTenderEnds", "BookedDeliveryDate"),
        leq(this.infoDto.deliverLatest, "DeliveryLatest", "BookedDeliveryDate")
      ];
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    currencyName: currencyName,
    money: money,
    close() {
      this.$emit("close");
    },
    priceModified(p: SpotTenderPrice): boolean {
      if (!p.price && !p.originalPrice) {
        return false;
      }
      return p.price !== p.originalPrice;
    },
    showValidationHints() {
      const form: any = this.$refs.form;
      if (form !== undefined) {
        form.validate();
      }
      if (!this.accept) {
        this.validationHints = true;
      }
    },
    deletePrices() {
      popupDialog({
        title: t("DeletePrice"),
        body: t("SpotTenderDeletePriceText"),
        btnText1: t("DeletePrice"),
        btnColor1: "error",
        btnCallback1: this.actuallyDeletePrice
      });
    },
    actuallyDeletePrice() {
      this.prices.map(p => (p.price = undefined));
      this.actuallySave();
    },
    save() {
      if (this.otherInfo.bookedDeliveryDate! > this.load.deliverLatest) {
        popupDialog({
          title: t("BookedDeliveryDate"),
          body: t("ConfirmDateErrorDelivery"),
          btnText1: "OK",
          btnColor1: "success",
          btnCallback1: () => {
            this.actuallySave();
          },
          btnText2: t("Cancel"),
          btnColor2: "error",
          btnCallback2: () => {}
        });
      } else {
        this.actuallySave();
      }
    },
    actuallySave() {
      this.saveInProgress = true;
      new SpotTenderSupplierClient(new ApiConfiguration(this.$store))
        .editSpotTender(
          new SupplierEditSpotTenderDto({
            loadId: this.loadId,
            bookedPickupDate: this.otherInfo.bookedPickupDate,
            bookedDeliveryDate: this.otherInfo.bookedDeliveryDate,
            note: this.otherInfo.note,
            prices: this.prices
              .filter(p => !!p.price)
              .map(
                p =>
                  new SupplierEditSpotTenderPriceDto({
                    price: p.price!,
                    truckType: p.truckType
                  })
              )
          })
        )
        .then(res => {
          pushLoadNotification("supplier-spot", res);
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchPreInfo() {
      new SpotTenderSupplierClient(new ApiConfiguration(this.$store))
        .preEditSpotTender(this.loadId)
        .then(res => {
          this.infoDto = res;
          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: res.pickupEarliest,
            deliverLatest: res.deliverLatest,
            truckType: undefined,
            transportNo: undefined,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: 0,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          };
          this.prices = this.infoDto.prices!.map(p => ({
            truckType: p.truckType,
            price: p.price,
            originalPrice: p.price
          }));
          this.prices.push(
            ...this.infoDto
              .truckTypes!.filter(
                tt => !this.prices.some(p => p.truckType === tt)
              )
              .map(tt => ({
                truckType: tt,
                price: undefined,
                originalPrice: undefined
              }))
          );
          const bookedPickupDate = res.prices!.length
            ? res.prices![0].bookedPickupDate
            : undefined;
          const bookedDeliveryDate = res.prices!.length
            ? res.prices![0].bookedDeliveryDate
            : undefined;
          const note = res.prices!.length ? res.prices![0].note : undefined;
          this.otherInfo = {
            bookedPickupDate: bookedPickupDate,
            originalBookedPickupDate: bookedPickupDate,
            bookedDeliveryDate: bookedDeliveryDate,
            originalBookedDeliveryDate: bookedDeliveryDate,
            note: note,
            originalNote: note
          };
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocumentGroups() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .spotTender(this.loadId)
        .then(res => {
          this.documentGroup = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.fetchPreInfo();
      this.fetchDocumentGroups();
    }
  },
  data: (): {
    valid: boolean;
    accept: boolean;
    validationHints: boolean;
    saveInProgress: boolean;
    documentGroup: DocumentGroupDto | undefined;
    infoDto: PreSupplierEditSpotTenderDto;
    load: MultiLoad;
    prices: SpotTenderPrice[];
    otherInfo: SpotTenderOtherInfo;
  } => ({
    valid: false,
    accept: false,
    validationHints: false,
    saveInProgress: false,
    documentGroup: undefined,
    infoDto: undefined as any,
    load: undefined as any,
    prices: [],
    otherInfo: undefined as any
  })
});
