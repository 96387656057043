





























































































































































































































































































































































































































































































































































import Vue from "vue";
import {
  ExplorerClient,
  ExplorerNodeDto,
  ApiConfiguration,
  LoadCompleteDto,
  UpdateLoadPriceDto,
  PublishInfoDto,
  LoadStatus
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import {
  currencyName,
  deviationCauseName,
  otherPriceCauseName,
  publishSpeedName,
  truckTypeName
} from "@/scripts/misc/enumNames";
import money, { percentage } from "@/scripts/misc/money";
import { ActorListDialogType } from "../actor/ActorTable.vue";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto
  },
  components: {
    Spinner,
    ReadOnlyText,
    Checkbox,
    GreyTitle,
    InfoTooltip
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        this.fetchLoad();
        this.fetchPriceRow();
        this.fetchPublishInfo();
      }
    }
  },
  computed: {
    loading(): boolean {
      return !this.load || !this.priceRow;
    },
    isStatusOrdered(): boolean {
      return this.load.statusId === LoadStatus.Ordered;
    },
    truckTypeName(): string {
      return truckTypeName(this.load.truckTypeId!);
    },
    publishSpeedName(): string {
      return publishSpeedName(this.load.publishSpeed);
    },
    deviationCauseName(): string {
      if (!this.load.deviationCauseId) {
        return "";
      }
      return deviationCauseName(this.load.deviationCauseId);
    },
    otherPriceCauseName(): string {
      if (!this.load.otherPriceCauseId) {
        return "";
      }
      return otherPriceCauseName(this.load.otherPriceCauseId);
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    currencyName: currencyName,
    viewActor(actorId: number | undefined) {
      if (!actorId) {
        return;
      }
      window.open(
        "/support-admin/actors?dialog=" +
          ActorListDialogType.ActorDialog +
          "&id=" +
          actorId,
        "_blank"
      );
    },
    fetchLoad() {
      new ExplorerClient(new ApiConfiguration(this.$store))
        .load(this.item.id)
        .then(res => {
          this.load = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPriceRow() {
      new ExplorerClient(new ApiConfiguration(this.$store))
        .pricerowByLoadid(this.item.id)
        .then(res => {
          this.priceRow = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPublishInfo() {
      new ExplorerClient(new ApiConfiguration(this.$store))
        .publishInfo(this.item.id)
        .then(res => {
          this.publishInfo = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data(): {
    load: LoadCompleteDto;
    priceRow: UpdateLoadPriceDto;
    publishInfo: PublishInfoDto[];
  } {
    return {
      load: undefined as any,
      priceRow: undefined as any,
      publishInfo: []
    };
  }
});
