











































































































import Vue from "vue";
import { actions } from "@/scripts/store/constants";
import {
  ApiConfiguration,
  CargoType,
  CreateSpotTenderDto,
  DocumentGroupDto,
  DocumentsClient,
  DocType,
  LoadActionPermission,
  LoadClientEditDatesDto,
  QualityPointsClientClient,
  QualityPointsPastYearDto,
  RepositoryDto,
  SpotTenderClientClient
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import CreateSpotTenderDates from "@/components/web/loads/client/spot/CreateSpotTenderDates.vue";
import CreateSpotTenderOtherInfo from "@/components/web/loads/client/spot/CreateSpotTenderOtherInfo.vue";
import CreateSpotTenderSuppliers from "@/components/web/loads/client/spot/CreateSpotTenderSuppliers.vue";
import CreateSpotTenderReview from "@/components/web/loads/client/spot/CreateSpotTenderReview.vue";
import { MultiLoad } from "@/scripts/types";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import cached from "@/scripts/misc/cached";
import { t } from "@/scripts/language/i18n";
import { discardChangesDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  components: {
    DialogCard,
    LoadLock,
    LoadChips,
    CreateSpotTenderDates,
    CreateSpotTenderOtherInfo,
    CreateSpotTenderSuppliers,
    CreateSpotTenderReview
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    loadId(): number {
      return this.loadIds[0]!;
    }
  },
  methods: {
    change(step: number, completed: boolean, info: string | undefined) {
      this.steps[step - 1].completed = completed;
      this.steps[step - 1].info = info;
    },
    nextEnabled(step: number): boolean {
      return this.steps.every(s => s.id > step || s.completed);
    },
    completed(step: number) {
      return this.steps[step - 1].completed && this.currentStep > step;
    },
    color(step: number): string {
      return this.completed(step) ? "success" : "cyan darken-1";
    },
    save() {
      this.saveInProgress = true;
      new SpotTenderClientClient(new ApiConfiguration(this.$store))
        .createSpotTender(this.saveDto)
        .then(res => {
          pushLoadNotification("create-spot", res);
          this.actuallyClose();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      discardChangesDialog(this.actuallyClose);
    },
    actuallyClose() {
      this.$emit("close");
    },
    fetchSuppliers() {
      cached("suppliers", [CargoType.Road, false, true])
        .then(res => {
          this.suppliers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPreInfo() {
      new SpotTenderClientClient(new ApiConfiguration(this.$store))
        .preCreateSpotTender(this.loadId)
        .then(res => {
          this.saveDto = new CreateSpotTenderDto({
            loadId: this.loadId,
            endDate: undefined as any,
            supplierIds: [],
            dates: new LoadClientEditDatesDto({
              pickupEarliest: res.pickupEarliest,
              pickupLatest: res.pickupLatest,
              deliverEarliest: res.deliveryEarliest,
              deliverLatest: res.deliveryLatest
            }),
            mandatoryBookedPickupDate: true,
            mandatoryBookedDeliveryDate: res.mandatoryBookedDeliveryDate,
            note: undefined,
            documentGroupId: undefined as any,
            truckTypes: [res.truckType]
          });
          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: res.pickupEarliest,
            deliverLatest: res.deliveryLatest,
            truckType: res.truckType,
            transportNo: res.transportNo,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: res.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          };
          this.rankedSupplierIds = res.rankedSupplierIds!;
          this.fetchDocumentGroups();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchQualityPoints() {
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .qualityPointsPastYear(CargoType.Road)
        .then(res => {
          this.qualityPoints = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocumentGroups() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .list(DocType.TermsOfAgreement, false)
        .then(res => {
          this.documentGroups = res;
          this.saveDto.documentGroupId = this.documentGroups[0].id;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.fetchSuppliers();
      this.fetchPreInfo();
      this.fetchQualityPoints();
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    saveDto: CreateSpotTenderDto;
    load: MultiLoad;
    suppliers: RepositoryDto[];
    rankedSupplierIds: number[];
    qualityPoints: QualityPointsPastYearDto[];
    documentGroups: DocumentGroupDto[];
    currentStep: number;
    steps: {
      id: number;
      name: string;
      info: string | undefined;
      completed: boolean;
    }[];
  } => ({
    valid: false,
    saveInProgress: false,
    saveDto: undefined as any,
    load: undefined as any,
    suppliers: [],
    rankedSupplierIds: [],
    qualityPoints: [],
    documentGroups: [],
    currentStep: 1,
    steps: [
      { id: 1, name: t("Dates"), info: undefined, completed: false },
      {
        id: 2,
        name: t("SpotTender"),
        info: undefined,
        completed: false
      },
      { id: 3, name: t("Suppliers"), info: undefined, completed: false },
      { id: 4, name: t("Review"), info: undefined, completed: false }
    ]
  })
});
