








import Vue from "vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    DatePicker
  },
  methods: {},
  data: (): {
    date: MomentX;
  } => ({
    date: new MomentX()
  })
});
