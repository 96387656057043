














































import Vue from "vue";
import cached from "@/scripts/misc/cached";
import { t } from "@/scripts/language/i18n";
import { Country, LoadStatus, RoutingExactness } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import neverHappens from "@/scripts/misc/neverHappens";
import { routingExactnessName } from "@/scripts/misc/enumNames";

interface StarInfo {
  stars: number;
  color: string;
}

export default Vue.extend({
  components: {
    NumberInput
  },
  props: {
    value: Number,
    label: String,
    fromCountry: Number as () => Country | undefined,
    fromCounty: String as () => string | undefined,
    fromZipcode: String as () => string | undefined,
    fromAddress: String as () => string | undefined,
    toCountry: Number as () => Country | undefined,
    toCounty: String as () => string | undefined,
    toZipcode: String as () => string | undefined,
    toAddress: String as () => string | undefined,
    agreementRouteId: Number as () => number | undefined,
    loadStatus: Number as () => LoadStatus | undefined,
    disabled: Boolean,
    tabindex: String
  },
  watch: {
    fromAddress() {
      this.recalculateDistanceIfNotManual();
    },
    toAddress() {
      this.recalculateDistanceIfNotManual();
    },
    fromZipcode() {
      this.recalculateDistanceIfNotManual();
    },
    toZipcode() {
      this.recalculateDistanceIfNotManual();
    },
    value: {
      handler() {
        if (!this.value) {
          this.markAsManual(false);
        }
      },
      immediate: true
    }
  },
  computed: {
    icons(): string[] {
      if (this.manual) {
        return ["mdi-star-off-outline"];
      }
      return [
        this.starIcon(this.starInfo.stars),
        this.starIcon(this.starInfo.stars - 1),
        this.starIcon(this.starInfo.stars - 2)
      ];
    },
    starInfo(): StarInfo {
      switch (this.lowestExactness) {
        case RoutingExactness.Unknown:
          return {
            stars: 0,
            color: "grey"
          };
        case RoutingExactness.Manual:
          return {
            stars: 0,
            color: "cyan"
          };
        case RoutingExactness.Fail:
          return {
            stars: 0,
            color: "deep-orange accent-3"
          };
        case RoutingExactness.AgreementRoute:
          return {
            stars: 1,
            color: "deep-orange accent-2"
          };
        case RoutingExactness.Country:
          return {
            stars: 1,
            color: "deep-orange accent-2"
          };
        case RoutingExactness.ZipcodeArea:
          return {
            stars: 1.5,
            color: "warning"
          };
        case RoutingExactness.County:
          return {
            stars: 2,
            color: "warning lighten-1"
          };
        case RoutingExactness.Zipcode:
          return {
            stars: 2.5,
            color: "warning lighten-2"
          };
        case RoutingExactness.Address:
          return {
            stars: 3,
            color: "warning lighten-2"
          };
        default:
          return neverHappens(this.lowestExactness);
      }
    },
    tooltip(): string {
      const fromText = routingExactnessName(this.fromExactness);
      const toText = routingExactnessName(this.toExactness);
      return `${t("DistancePrecisionTooltip")}
      ${t("Pickup")}: ${fromText}
      ${t("Delivery")}: ${toText}`;
    },
    lowestExactness(): RoutingExactness {
      return Math.min(this.fromExactness, this.toExactness);
    },
    manual(): boolean {
      return this.lowestExactness === RoutingExactness.Manual;
    }
  },
  methods: {
    markAsManual(manual: boolean) {
      this.fromExactness = manual
        ? RoutingExactness.Manual
        : RoutingExactness.Unknown;
      this.toExactness = manual
        ? RoutingExactness.Manual
        : RoutingExactness.Unknown;
    },
    input(val?: number) {
      this.$emit("input", val);
    },
    starIcon(stars: number): string {
      if (stars <= 0) {
        return "mdi-star-outline";
      }
      if (stars <= 0.5) {
        return "mdi-star-half-full";
      }
      return "mdi-star";
    },
    recalculateDistanceIfNotManual() {
      if (
        this.lowestExactness !== RoutingExactness.Manual &&
        !this.loadStatus
      ) {
        this.recalculateDistance();
      }
    },
    recalculateDistance() {
      this.fromExactness = RoutingExactness.Unknown;
      this.toExactness = RoutingExactness.Unknown;
      if (
        !this.fromCountry ||
        !this.fromCounty ||
        !this.fromZipcode ||
        !this.toCountry ||
        !this.toCounty ||
        !this.toZipcode
      ) {
        return;
      }
      this.loading = true;
      cached("itinerary", [
        this.fromCountry,
        this.fromCounty,
        this.fromZipcode,
        this.fromAddress || "",
        this.toCountry,
        this.toCounty,
        this.toZipcode,
        this.toAddress || "",
        this.agreementRouteId || null
      ])
        .then(res => {
          this.input(res.distance);
          this.fromExactness = res.fromExactness;
          this.toExactness = res.toExactness;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    fromExactness: RoutingExactness;
    toExactness: RoutingExactness;
  } => ({
    loading: false,
    fromExactness: RoutingExactness.Unknown,
    toExactness: RoutingExactness.Unknown
  })
});
