























import Vue from "vue";
import {
  AgreementListDto,
  AgreementsClient,
  AgreementSearchCriteriaDto,
  ApiConfiguration,
  CargoType
} from "@/scripts/cld.api";
import AgreementListSearch from "@/components/support-admin/agreements/AgreementListSearch.vue";
import AgreementListTable from "@/components/support-admin/agreements/AgreementListTable.vue";
import { actions } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import MomentX from "@/scripts/misc/momentX";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import nextUnique from "@/scripts/misc/nextUnique";

export interface Toggles {
  rank: boolean;
  arow: boolean;
}

export default Vue.extend({
  components: {
    AgreementListSearch,
    AgreementListTable
  },
  watch: {
    criteria: {
      handler() {
        this.fetchAgreements(false);
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    scrollToTop() {
      scrollTableToTop(this);
    },
    fetchAgreements(scrollToTop: boolean) {
      if (!this.criteria.supplierId && !this.criteria.clientId) {
        return;
      }
      const unique = nextUnique();
      this.loading = unique;
      if (scrollToTop) {
        scrollTableToTop(this);
      }
      new AgreementsClient(new ApiConfiguration(this.$store))
        .agreements(this.criteria)
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.agreements = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    exportToExcel() {
      this.exporting = true;
      new AgreementsClient(new ApiConfiguration(this.$store))
        .exportToExcel(this.criteria)
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: number;
    exporting: boolean;
    agreements: AgreementListDto[];
    criteria: AgreementSearchCriteriaDto;
    toggles: Toggles;
  } => ({
    loading: 0,
    exporting: false,
    agreements: [],
    criteria: new AgreementSearchCriteriaDto({
      clientId: undefined,
      supplierId: undefined,
      cargoType: CargoType.Road,
      invoicePrice: false,
      includeStatistics: false,
      date: new MomentX().startOfDay()
    }),
    toggles: {
      rank: false,
      arow: false
    }
  })
});
