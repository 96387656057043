















































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import ActorTable from "@/components/web/actor/ActorTable.vue";

import {
  ActorListDto,
  ActorsClient,
  ActorType,
  ApiConfiguration,
  UserLevel
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions, gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";
import neverHappens from "@/scripts/misc/neverHappens";
import { excelPrompt } from "@/scripts/misc/filePrompts";

export default Vue.extend({
  components: {
    SearchCard,
    ActorTable
  },
  watch: {
    actorTypeToView: {
      handler() {
        this.actors = [];
        this.search();
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      switch (this.actorTypeToView) {
        case ActorType.Client:
          return t("Clients");
        case ActorType.Supplier:
          return t("Suppliers");
        case ActorType.Customer:
          return t("Customers");
        default:
          return neverHappens(this.actorTypeToView);
      }
    },
    userLevel(): UserLevel | undefined {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    atleastUser(): boolean {
      return (
        this.userLevel !== undefined &&
        [UserLevel.User, UserLevel.Admin, UserLevel.SuperAdmin].includes(
          this.userLevel
        )
      );
    },
    actorTypeToView():
      | ActorType.Client
      | ActorType.Supplier
      | ActorType.Customer {
      return Number(this.$route.query.actorType);
    },
    documentsVisible(): boolean {
      return this.actorTypeToView === ActorType.Client && this.atleastUser;
    }
  },
  methods: {
    openDocuments() {
      this.$router.push("/documents");
    },
    search() {
      this.loading = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .list(this.actorTypeToView)
        .then(res => {
          this.actors = res;
          this.loading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    exportToExcel() {
      this.exporting = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .listExport(this.actorTypeToView, this.filteredActorIds)
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    exporting: boolean;
    actors: ActorListDto[];
    filteredActorIds: number[];
  } => ({
    loading: true,
    exporting: false,
    actors: [],
    filteredActorIds: []
  })
});
