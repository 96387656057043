














































































import Vue from "vue";
import { ActorType, LoadSearchResultDto, LoadStatus } from "@/scripts/cld.api";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { loadStatusName, truckTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    EllipsisTableItem
  },
  props: {
    items: Array as () => LoadSearchResultDto[]
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    loadStatusName: loadStatusName,
    truckTypeName: truckTypeName,
    transportNoOkToShow(load: LoadSearchResultDto): boolean {
      return (
        this.actorType !== ActorType.Supplier ||
        load.status !== LoadStatus.Ordered
      );
    }
  }
});
