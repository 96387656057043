











































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import UserSettingsForm from "@/components/web/settings/UserSettingsForm.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import {
  ApiConfiguration,
  BasicUserSettingsDto,
  NotificationSettingsDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    UserSettingsForm,
    Spinner
  },
  computed: {
    title(): string {
      let title = t("MySettings");
      if (this.basicUserSettings) {
        title += " (" + this.basicUserSettings.username + ")";
      }
      return title;
    },
    pendingChanges(): boolean {
      return (
        this.originalBasicUserSettings !==
          JSON.stringify(this.basicUserSettings) ||
        this.originalNotificationSettings !==
          JSON.stringify(this.notificationSettings)
      );
    },
    loading(): boolean {
      return (
        this.basicUserSettings === undefined ||
        this.notificationSettings === undefined
      );
    },
    loadUserId(): number {
      return this.$store.getters[gets.userInfo].loadUserId;
    }
  },
  methods: {
    fetchBasicSettings() {
      new UsersClient(new ApiConfiguration(this.$store))
        .basicUserSettings(this.loadUserId)
        .then(res => {
          this.basicUserSettings = res;
          this.originalBasicUserSettings = JSON.stringify(
            this.basicUserSettings
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchNotificationSettings() {
      new UsersClient(new ApiConfiguration(this.$store))
        .notificationSettings(this.loadUserId)
        .then(res => {
          this.notificationSettings = res;
          this.originalNotificationSettings = JSON.stringify(
            this.notificationSettings
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = 2;
      new UsersClient(new ApiConfiguration(this.$store))
        .saveBasicUserSettings(this.basicUserSettings!)
        .then(() => {
          this.saveInProgress--;
          if (this.saveInProgress === 0) {
            this.init();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      new UsersClient(new ApiConfiguration(this.$store))
        .saveNotificationSettings(this.notificationSettings!)
        .then(() => {
          this.saveInProgress--;
          if (this.saveInProgress === 0) {
            this.init();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.basicUserSettings = undefined;
      this.notificationSettings = undefined;
      this.fetchBasicSettings();
      this.fetchNotificationSettings();
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    valid: boolean;
    saveInProgress: number;
    basicUserSettings: BasicUserSettingsDto | undefined;
    originalBasicUserSettings: string;
    notificationSettings: NotificationSettingsDto | undefined;
    originalNotificationSettings: string;
  } => ({
    valid: false,
    saveInProgress: 0,
    basicUserSettings: undefined,
    originalBasicUserSettings: "",
    notificationSettings: undefined,
    originalNotificationSettings: ""
  })
});
