var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogCard',{attrs:{"dialog":_vm.dialog,"loading":_vm.loading,"title":_vm.agreement.agreementRouteName,"full-height":""},on:{"close":_vm.closeDialog},scopedSlots:_vm._u([{key:"head-right",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","text":"","disabled":_vm.selectedAgreementIndex <= 0 || _vm.loading},on:{"click":function($event){return _vm.changeAgreement(false)}}},on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-left")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Previous")))])]),_c('v-tooltip',{attrs:{"bottom":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"large":"","text":"","disabled":_vm.selectedAgreementIndex >= _vm.selectedAgreementIds.length - 1 ||
              _vm.loading},on:{"click":function($event){return _vm.changeAgreement(true)}}},on),[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-chevron-right")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("Next")))])])]},proxy:true},{key:"body",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"no-padding-bottom no-padding-right",attrs:{"cols":"auto"}},[_c('Chip',{attrs:{"text":_vm.agreement.currencyName}})],1),_c('v-col',{staticClass:"no-padding-bottom no-padding-right",attrs:{"cols":"auto"}},[_c('Chip',{attrs:{"text":_vm.$t('ProcurementPeriodText'),"tooltip":_vm.procurementPeriods,"icon":"mdi-calendar-blank"}})],1),_c('v-col',{staticClass:"no-padding-bottom",attrs:{"cols":"auto"}},[_c('Chip',{attrs:{"text":((_vm.$t('BaseIndex')) + ": " + (_vm.agreement.baseIndexName)),"icon":"mdi-calendar-blank"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"no-padding-top no-padding-bottom",attrs:{"cols":"auto"}},[_c('v-form',{ref:"form",model:{value:(_vm.datesValid),callback:function ($$v) {_vm.datesValid=$$v},expression:"datesValid"}},[_c('v-row',[_c('v-col',{staticClass:"no-padding-bottom",attrs:{"cols":"auto"}},[_c('NumberInput',{attrs:{"value":_vm.agreement.estimatedNoOfLoads,"label":_vm.$t('EstimatedNoOfLoads'),"rules":[function (v) { return v === undefined || v >= 0; }],"placeholder":_vm.$t('NotAvailable'),"allow-null":""},on:{"input":function (v) {
                    _vm.beforeModification();
                    _vm.agreement.estimatedNoOfLoads = v;
                  }}})],1),_c('v-col',{staticClass:"no-padding-bottom",attrs:{"cols":"auto"}},[_c('DatePicker',{attrs:{"value":_vm.agreement.agreementFrom,"label":_vm.$t('AgreementStart'),"gteDates":[_vm.limitingDates.prevAgreementEnd],"gteText":_vm.$t('DateOverlapsOtherAgreement'),"compact":""},on:{"input":function (date) {
                    _vm.beforeModification();
                    _vm.agreement.agreementFrom = date;
                    _vm.validateDateForm();
                  }}})],1),_c('v-col',{staticClass:"no-padding-bottom",attrs:{"cols":"auto"}},[_c('DatePicker',{attrs:{"value":_vm.agreement.agreementTo,"label":_vm.$t('AgreementEnd'),"gteDates":[_vm.agreement.agreementFrom],"gteText":_vm.$t('DatePeriodGreaterThanEqual'),"lteDate":_vm.limitingDates.nextAgreementStart,"lteText":_vm.$t('DateOverlapsOtherAgreement'),"compact":""},on:{"input":function (date) {
                    _vm.beforeModification();
                    _vm.agreement.agreementTo = date;
                    _vm.validateDateForm();
                  }}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"no-padding-top"},[_c('RankingDialogPrevAgreement',{attrs:{"cargoTypeId":_vm.cargoTypeId,"agreement":_vm.prevAgreement,"loading":_vm.prevAgreementLoading,"secondaryValue":_vm.secondaryValue}})],1)],1),_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.agreement.truckTypes || []),function(truckType){return _c('v-col',{key:truckType.id,staticClass:"ranking-column"},[_c('v-sheet',{staticClass:"tiny-padding-sides small-padding-bottom",attrs:{"elevation":"1","color":"background-pale"}},[_c('div',[_c('v-card-actions',{staticClass:"subtitle-2"},[_vm._v(" "+_vm._s(_vm.truckTypeName(truckType.id))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.rankByPrice(truckType.id)}}},on),[_c('v-icon',{attrs:{"color":"icon-grey"}},[_vm._v(" mdi-currency-usd ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("RankSuppliersByPriceAndTruckType")))])]),_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","disabled":_vm.qualityPointsLoading},on:{"click":function($event){return _vm.rankByQP(truckType.id)}}},on),[_c('v-icon',{attrs:{"color":"icon-grey"}},[_vm._v(" mdi-flag ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("RankSuppliersByQualityPointsAndTruckType")))])]),_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeAllAgreementRows(truckType.id)}}},on),[_c('v-icon',{attrs:{"color":"icon-grey"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("DeleteAllPriceByTruckType"))+" ")])])],1)],1),_c('div',[_c('drop',{attrs:{"get-child-payload":function () { return truckType.id; },"drag-handle-selector":".draggable","non-drag-area-selector":".not-draggable","drag":"","drag-class":"supplier-card-dragging","drop-class":"supplier-card-dropping"},on:{"drop":_vm.handleDrop}},_vm._l((truckType.agreementRows || []),function(agreementRow,agreementRowIndex){return _c('drag',{key:agreementRow.supplierName},[_c('RankingDialogCard',{attrs:{"agreementRow":agreementRow,"agreementRowIndex":agreementRowIndex,"truckTypeId":truckType.id,"cargoTypeId":_vm.cargoTypeId,"secondaryValue":_vm.secondaryValue,"removeAgreementRow":_vm.removeAgreementRow,"restoreAgreementRow":_vm.restoreAgreementRow,"explode":_vm.explode,"implode":_vm.implode}})],1)}),1)],1)])],1)}),1)]},proxy:true},{key:"actions",fn:function(){return [_c('RankingDialogActions',{attrs:{"type":_vm.type,"loading":_vm.loading,"pendingChanges":_vm.pendingChanges,"qualityPointsLoading":_vm.qualityPointsLoading,"datesValid":_vm.datesValid,"supplierList":_vm.supplierList},on:{"removeAgreement":_vm.removeAgreement,"removeSupplier":_vm.removeSupplier,"rankByPrice":_vm.rankByPrice,"rankByQP":_vm.rankByQP,"undo":_vm.undo,"finishProcurement":_vm.finishProcurement,"saveAgreement":_vm.saveAgreement}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }