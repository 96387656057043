export const gets = {
  //Root
  userInfo: "userInfo",
  unviewedMessages: "unviewedMessages",
  currentClientVersion: "currentClientVersion",
  clientOutdated: "clientOutdated",
  locationCacheOutdated: "locationCacheOutdated",
  broadcast: "broadcast",
  globalError: "globalError",
  dimensions: "dimensions",
  activityDate: "activityDate",

  //Auth
  username: "username",
  token: "token",
  pathBeforeAuthentication: "pathBeforeAuthentication",
  autoRenewing: "autoRenewing",

  //Feedback
  confirmationOptions: "confirmationOptions",
  notifications: "notifications",
  sounds: "sounds",
  preventLeaving: "preventLeaving",
  tryingToLeave: "tryingToLeave",

  //Load
  lock: "lock",
  lockNeeded: "lockNeeded",
  loadActions: "loadActions",

  //Conf
  userConf: "userConf",
  allUserConfs: "allUserConfs",
  actorConf: "actorConf",
  allActorConfs: "allActorConfs"
};

export const mutations = {
  //Root
  setUserInfo: "setUserInfo",
  setUnviewedMessages: "setUnviewedMessages",
  setClientOutdated: "setClientOutdated",
  setLocationCacheOutdated: "setLocationCacheOutdated",
  setBroadcast: "setBroadcast",
  setGlobalError: "setGlobalError",
  setDimensions: "setDimensions",
  setVisible: "setVisible",
  setMouseActivityDate: "setMouseActivityDate",

  //Auth
  setUsername: "setUsername",
  setToken: "setToken",
  setPathBeforeAuthentication: "setPathBeforeAuthentication",
  setAutoRenewing: "setAutoRenewing",

  //Feedback
  setConfirmationOptions: "setConfirmationOptions",
  addNotification: "addNotification",
  removeNotification: "removeNotification",
  pushSound: "pushSound",
  popSound: "popSound",
  setPreventLeaving: "setPreventLeaving",
  setTryingToLeave: "setTryingToLeave",

  //Load
  setLocks: "setLocks",
  updateLock: "updateLock",
  removeLock: "removeLock",
  tickLocks: "tickLocks",
  setPermissions: "setPermissions",

  //Conf
  setUserConf: "setUserConf",
  emptyUserConf: "emptyUserConf",
  setActorConf: "setActorConf",
  emptyActorConf: "emptyActorConf"
};

export const actions = {
  //Root
  handleLoginError: "handleLoginError",
  handleApiError: "handleApiError",
  handleUnhandledError: "handleUnhandledError",
  handleBasicError: "handleBasicError",
  handleBasicErrorSilently: "handleBasicErrorSilently",
  handleBrowserWarning: "handleBrowserWarning",

  //Auth
  autoTokenRenew: "autoTokenRenew",
  startSignin: "startSignin",
  endSignin: "endSignin",
  startSignout: "startSignout",
  endSignout: "endSignout",
  startSigninSilent: "startSigninSilent",
  endSigninSilent: "endSigninSilent",

  //Feedback

  //Load
  fetchLocks: "fetchLocks",
  lock: "lock",
  unlock: "unlock",
  fetchPermissions: "fetchPermissions",

  //Conf
  resetUserConf: "resetUserConf",
  initUserConf: "initUserConf",
  saveUserConf: "saveUserConf",

  resetActorConf: "resetActorConf",
  initActorConf: "initActorConf",
  saveActorConf: "saveActorConf",
  deleteActorConf: "deleteActorConf"
};
