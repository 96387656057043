














































import Vue from "vue";
import Language from "@/components/shared/ui/Language.vue";
import { menu } from "@/scripts/misc/menuLayout";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: { Language },
  props: {
    menu: Array as () => menu[],
    title: String,
    actorName: String,
    home: String,
    unauthenticatedPage: Boolean
  },
  methods: {
    text(item: menu): string | undefined {
      if (!item.text || item.untranslated) {
        return item.text;
      }
      return t(item.text);
    },
    routeTo(path: string | undefined) {
      this.$emit("routeTo", path);
    },
    signout() {
      this.$emit("signout");
    }
  }
});
