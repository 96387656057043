var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"id","headers":_vm.filteredHeaders,"items":_vm.sortedRoutes,"height":_vm.height,"loading":_vm.loading,"options":_vm.tableOptions,"item-class":function (r) { return (_vm.agreementRouteIds.includes(r.id) ? 'green-row' : ''); },"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","hide-default-footer":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.checkbox",fn:function(){return [_c('v-simple-checkbox',{attrs:{"value":_vm.checkAllState === true,"indeterminate":_vm.checkAllState === undefined},on:{"click":_vm.checkAll}})]},proxy:true},{key:"filter.routeId",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('RouteID')},model:{value:(_vm.filters.routeId),callback:function ($$v) {_vm.$set(_vm.filters, "routeId", $$v)},expression:"filters.routeId"}})]},proxy:true},{key:"filter.pickup",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
            subtype: 'locationClient',
            pickup: true,
            cargoType: _vm.cargoType
          },"placeholder":_vm.$t('PickupLocation'),"value":{
            county: _vm.filters.pickupCounty,
            zipCode: _vm.filters.pickupZipcode,
            countryId: _vm.filters.pickupCountry
          }},on:{"input":function (val) {
              _vm.filters.pickupCounty = val.county;
              _vm.filters.pickupZipcode = val.zipCode;
              _vm.filters.pickupCountry = val.countryId;
            }}})]},proxy:true},{key:"filter.delivery",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
            subtype: 'locationClient',
            pickup: false,
            cargoType: _vm.cargoType
          },"placeholder":_vm.$t('DeliveryLocation'),"value":{
            county: _vm.filters.deliveryCounty,
            zipCode: _vm.filters.deliveryZipcode,
            countryId: _vm.filters.deliveryCountry
          }},on:{"input":function (val) {
              _vm.filters.deliveryCounty = val.county;
              _vm.filters.deliveryZipcode = val.zipCode;
              _vm.filters.deliveryCountry = val.countryId;
            }}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},model:{value:(_vm.filters.currency),callback:function ($$v) {_vm.$set(_vm.filters, "currency", $$v)},expression:"filters.currency"}})]},proxy:true},{key:"filter.tag",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'tag', cargoType: _vm.cargoType },"placeholder":_vm.$t('Tag')},model:{value:(_vm.filters.tag),callback:function ($$v) {_vm.$set(_vm.filters, "tag", $$v)},expression:"filters.tag"}})]},proxy:true}],null,true)})]}},{key:"item.checkbox",fn:function(ref){
            var item = ref.item;
return [_c('v-simple-checkbox',{attrs:{"value":_vm.agreementRouteIds.includes(item.id)},on:{"click":function($event){return _vm.checkOne(item.id)}}})]}},{key:"item.pickup",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.pickup(item))+" ")]}},{key:"item.delivery",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.delivery(item))+" ")]}},{key:"item.currency",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}},{key:"item.agreementTo",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(item.agreementTo ? item.agreementTo.datePrint() : "")+" ")]}},{key:"item.specialRequirements",fn:function(ref){
            var item = ref.item;
return [_c('InfoTooltip',{attrs:{"text":item.specialRequirements,"icon":"mdi-exclamation-thick","color":"red"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }