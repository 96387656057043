





























































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  ProcurementAnalyticsSearchCriteria,
  ProcurementAnalyticsContainerDto,
  ProcurementAnalyticsAgreementRouteDto,
  ProcurementAnalyticsTruckTypeDto
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import ProcurementAnalyticsTableExpansion from "@/components/web/reports/client/ProcurementAnalyticsTableExpansion.vue";
import { currencyName, isoName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    ProcurementAnalyticsTableExpansion
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: ProcurementAnalyticsContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 250);
    },
    searchCriteria(): ProcurementAnalyticsSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ProcurementAnalytics,
        1
      );
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [
        {
          value: "pickupLocation",
          text: "PickupLocation"
        },
        {
          value: "deliveryLocation",
          text: "DeliveryLocation"
        },
        {
          value: "currency",
          text: "Currency",
          width: 80
        },
        {
          value: "tag",
          text: "Tag",
          width: 130
        },
        {
          value: "agreementPeriod",
          text: "AgreementPeriod",
          width: 165
        },
        {
          value: "estimate",
          text: "NumberOfEstimatedLoads"
        },
        {
          value: "prevAgreementPeriod",
          text: `${t("Previous")[0]}. ${t("AgreementPeriod").toLowerCase()}`,
          tooltip: `${t("Previous")} ${t("AgreementPeriod").toLowerCase()}`,
          untranslated: true,
          width: 165
        },
        {
          value: "prevEstimate",
          text: `${t("Previous")[0]}. ${t(
            "NumberOfEstimatedLoads"
          ).toLowerCase()}`,
          tooltip: `${t("Previous")} ${t(
            "NumberOfEstimatedLoads"
          ).toLowerCase()}`,
          untranslated: true
        }
      ];
      return headers;
    }
  },
  methods: {
    currencyName: currencyName,
    pickup(item: ProcurementAnalyticsAgreementRouteDto): string {
      return (
        item.pickupCounty +
        ", " +
        item.pickupZipCode +
        ", " +
        isoName(item.pickupCountry)
      );
    },
    delivery(item: ProcurementAnalyticsAgreementRouteDto): string {
      return (
        item.deliveryCounty +
        ", " +
        item.deliveryZipCode +
        ", " +
        isoName(item.deliveryCountry)
      );
    },
    truckTypeRows(
      item: ProcurementAnalyticsAgreementRouteDto
    ): ProcurementAnalyticsTruckTypeDto[] {
      return item.truckTypes!;
    },
    search() {
      this.$emit("search");
    }
  }
});
