





































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import {
  ApiConfiguration,
  LoadActionPermission,
  LoadLoadDto,
  LoadsClientClient
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import Auth from "@/scripts/auth";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import LoadDialogMultiTable from "@/components/web/loads/shared/LoadDialogMultiTable.vue";
import { MultiLoad } from "@/scripts/types";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    DialogCard,
    DatePicker,
    TextSingle,
    LoadLock,
    LoadChips,
    LoadDialogMultiTable
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    mobile() {
      return Auth.browsingMobile();
    },
    multi(): boolean {
      return this.loadIds.length > 1;
    },
    allLoadsValid(): boolean {
      return this.loads.length > 0 && this.loads.every(l => l.valid);
    },
    load(): MultiLoad {
      return this.loads.find(l => l.loadId === this.selectedLoadId)!;
    },
    saveDto(): LoadLoadDto {
      return this.saveDtos.find(s => s.loadId === this.selectedLoadId)!;
    },
    tooLongText(): string {
      return t("MaxXCharacters").replace("{0}", "20");
    }
  },
  methods: {
    async loadLoad() {
      this.saveInProgress = true;
      let saveOk = true;
      for (const saveDto of this.saveDtos) {
        await new LoadsClientClient(new ApiConfiguration(this.$store))
          .load(saveDto)
          .then(res => {
            pushLoadNotification("load", res);
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = true;
          })
          .catch(error => {
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = false;
            saveOk = false;
            if (ble(error)) {
              errorDialog(error, () => {});
            } else {
              this.$store.dispatch(actions.handleApiError, error);
            }
          });
      }
      if (saveOk) {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    init() {
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .preLoad(this.loadIds)
        .then(res => {
          this.saveDtos = res.map(
            x =>
              new LoadLoadDto({
                loadId: x.loadId,
                loadedDate: new MomentX(),
                regNo: x.truckNo
              })
          );
          this.loads = res.map(x => ({
            loadId: x.loadId,
            routeName: x.routeName!,
            pickupEarliest: x.pickupEarliest,
            deliverLatest: x.deliverLatest,
            truckType: x.truckType,
            transportNo: x.transportNo,
            clientName: undefined,
            numExtraStops: x.numExtraStops,
            numContainers: x.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          }));
          this.selectedLoadId = this.loads[0].loadId;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    saveInProgress: boolean;
    selectedLoadId: number | undefined;
    loads: MultiLoad[];
    saveDtos: LoadLoadDto[];
  } => ({
    saveInProgress: false,
    selectedLoadId: undefined,
    loads: [],
    saveDtos: []
  })
});
