







































import Vue from "vue";
import {
  ApiConfiguration,
  CustomsInfoDto,
  LoadsClient,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { actions } from "@/scripts/store/constants";
import { isoName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    loadId: Number
  },
  components: {
    ReadOnlyText
  },
  computed: {
    passage(): string | undefined {
      if (!this.customsInfo) {
        return undefined;
      }
      const passage = this.passages.find(
        p => p.id === this.customsInfo!.passageId
      );
      return passage ? passage.text : undefined;
    },
    truckCountry(): string | undefined {
      if (!this.customsInfo) {
        return undefined;
      }
      return this.customsInfo.truckCountry
        ? isoName(this.customsInfo.truckCountry)
        : undefined;
    },
    disabled(): boolean {
      if (!this.customsInfo) {
        return true;
      }
      return (
        !this.customsInfo.passageId &&
        !this.customsInfo.passageDate &&
        !this.customsInfo.truckCountry &&
        !this.customsInfo.driverPhone &&
        !this.customsInfo.permitNo
      );
    }
  },
  methods: {
    fetchCustomsInfo() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .customsInfo(this.loadId)
        .then(res => {
          this.customsInfo = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPassages() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .customsPassages()
        .then(res => {
          this.passages = res.map(
            r =>
              new RepositoryDto({
                id: r.id,
                text: `${r.name} (${isoName(r.country)})`
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchCustomsInfo();
    this.fetchPassages();
  },
  data: (): {
    customsInfo: CustomsInfoDto | undefined;
    passages: RepositoryDto[];
  } => ({
    customsInfo: undefined,
    passages: []
  })
});
