





















import Vue from "vue";

export default Vue.extend({
  watch: {
    qsMessage: {
      handler() {
        if (this.qsMessage) {
          this.message = String(this.qsMessage);
          this.$router.push(this.$route.path);
        }
      },
      immediate: true
    }
  },
  computed: {
    qsMessage() {
      return this.$route.query.message;
    }
  },
  methods: {
    routeTo(path: string) {
      this.$router.push(path);
    }
  },
  data: (): { message: string } => ({
    message: "Unexpected error!"
  })
});
