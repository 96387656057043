















































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import { SurveySubmissionSaveDto } from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    TextSingle,
    DialogCard
  },
  props: {
    dialog: Boolean,
    submission: SurveySubmissionSaveDto
  },
  methods: {
    submitSurvey() {
      this.loading = true;
      this.$emit("submitSurvey");
    },
    lengthRule(value?: string | null): boolean {
      return !!value && value.length <= 50;
    }
  },
  data: (): { valid: boolean; loading: boolean } => ({
    valid: false,
    loading: false
  })
});
