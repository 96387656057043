































































import Vue from "vue";
import {
  UserConfigurationType,
  AgreementStatisticsReportSearchCriteria,
  AgreementStatisticsReportContainerDto,
  RepositoryDto,
  ActorType
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import SwitchSingle from "@/components/shared/input/SwitchSingle.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Dropdown,
    SwitchSingle,
    SwitchDouble
  },
  props: {
    exportDisabled: Boolean,
    exportLoading: Boolean,
    showPercentage: Boolean,
    reportContainer: AgreementStatisticsReportContainerDto
  },
  computed: {
    searchCriteria(): AgreementStatisticsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementStatisticsReport,
        1
      );
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    clearSelection() {
      this.$emit("clearSelection");
    }
  }
});
