import "@mdi/font/css/materialdesignicons.min.css";
import Vue from "vue";
import Vuetify, { Ripple, ClickOutside } from "vuetify/lib";
Vue.use(Vuetify, {
  directives: {
    Ripple,
    ClickOutside
  }
});

export const colors = {
  primary: "#05afc5",

  "btn-cyan": "#80deea",
  "card-cyan": "#80deea",
  "icon-cyan": "#4dd0e1",

  "background-red": "#ffcdd2",
  "card-red": "#e03825",
  "icon-red": "#d92730",
  "cld-red": "#c62828",

  "icon-green": "#2f9e3a",

  "icon-yellow": "#fdd033",

  "icon-gold": "#a69f8b",

  "card-grey": "#e0e0e0",
  "icon-grey": "#616161",

  "btn-outlined": "#546e7a",
  "background-pale": "#f6f5f5",
  "background-grey": "#ebebeb"
};

export default new Vuetify({
  icons: {
    iconfont: "mdi"
  },
  theme: {
    themes: {
      light: colors
    }
  }
});
