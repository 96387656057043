

















import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  props: {
    text: ([String, Number, MomentX] as any) as () => string | number | MomentX,
    width: String,
    clickable: Boolean
  }
});
