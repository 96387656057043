

















































































































































































import Vue from "vue";
import {
  CargoType,
  UserConfigurationType,
  SupplierProcurementSearchCriteria,
  RepositoryDto,
  PriceFilter
} from "@/scripts/cld.api";
import { cargoTypeName, isoName } from "@/scripts/misc/enumNames";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import LocationFilter from "@/components/shared/input/LocationFilter.vue";
import ProcurementConfigDialog from "@/components/web/agreements/supplier/ProcurementConfigDialog.vue";
import ProcurementExcelImportDialog from "@/components/web/agreements/supplier/ProcurementExcelImportDialog.vue";
import ProcurementImportPrevDialog from "@/components/web/agreements/supplier/ProcurementImportPrevDialog.vue";
import { t } from "@/scripts/language/i18n";
import neverHappens from "@/scripts/misc/neverHappens";
import { currencies, truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { ProcurementRowAction } from "@/views/supplier/Procurements.vue";
import cached from "@/scripts/misc/cached";
import { SupplierProcurementConfig } from "@/scripts/types";

export default Vue.extend({
  components: {
    SearchCard,
    DropdownMulti,
    Dropdown,
    Autocomplete,
    NumberInput,
    LocationFilter,
    ProcurementConfigDialog,
    ProcurementExcelImportDialog,
    ProcurementImportPrevDialog
  },
  props: {
    cargoType: Number as () => CargoType,
    optionsDisabled: Boolean,
    optionsLoading: Boolean,
    editable: Boolean,
    interactable: Boolean,
    unsavedChanges: Boolean
  },
  computed: {
    title(): string {
      return `${t("ProcurementList")} - ${cargoTypeName(this.cargoType)}`;
    },
    searchCriteria(): SupplierProcurementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurement,
        this.cargoType
      );
    },
    conf(): SupplierProcurementConfig {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurementConfig,
        1
      );
    },
    menuOptions(): {
      id: ProcurementRowAction;
      text: string;
      icon: string;
      tooltip?: string;
    }[] {
      return [
        {
          id: ProcurementRowAction.Export,
          text: t("ExportToExcel"),
          icon: "mdi-microsoft-excel",
          blacklist: [CargoType.MachineTransport]
        },
        {
          id: ProcurementRowAction.ImportExcel,
          text: t("ImportFromExcel"),
          icon: "mdi-microsoft-excel",
          blacklist: [CargoType.MachineTransport]
        },
        {
          id: ProcurementRowAction.ImportPrev,
          text: t("ImportFromLatestAgreement"),
          icon: "mdi-history"
        },
        {
          id: ProcurementRowAction.CalculatePercentage,
          text: t("CalculateBasedOnTW"),
          icon: "mdi-percent",
          blacklist: [
            CargoType.Railroad,
            CargoType.Container,
            CargoType.MachineTransport
          ],
          tooltip:
            !this.conf.truckPercentage && !this.conf.wagonPercentage
              ? t("ToUseThisFeature")
                  .replace("{0}", t("Tools"))
                  .replace("{1}", t("PriceSettings"))
              : ""
        },
        {
          id: ProcurementRowAction.CalculateDistance,
          text: t("CalculateBasedOnDist"),
          icon: "mdi-calculator-variant",
          blacklist: [
            CargoType.Railroad,
            CargoType.Container,
            CargoType.MachineTransport
          ],
          tooltip: !this.conf.kmPrices.some(c => c.value)
            ? t("ToUseThisFeature")
                .replace("{0}", t("Tools"))
                .replace("{1}", t("PriceSettings"))
            : ""
        },
        {
          id: ProcurementRowAction.CalculateDistanceAndPercentage,
          text: t("CalculateBasedOnDistTW"),
          icon: "mdi-calculator-variant",
          blacklist: [
            CargoType.Railroad,
            CargoType.Container,
            CargoType.MachineTransport
          ],
          tooltip: !this.conf.kmPrices.some(c => c.value)
            ? t("ToUseThisFeature")
                .replace("{0}", t("Tools"))
                .replace("{1}", t("PriceSettings"))
            : ""
        },
        {
          id: ProcurementRowAction.StopPrice,
          text: t("GetStopPrice"),
          icon: "mdi-routes-clock",
          blacklist: [CargoType.Railroad, CargoType.Container],
          tooltip: !this.conf.stopPrices.some(c => c.value)
            ? t("ToUseThisFeature")
                .replace("{0}", t("Tools"))
                .replace("{1}", t("PriceSettings"))
            : ""
        },
        {
          id: ProcurementRowAction.Settings,
          text: t("PriceSettings"),
          icon: "mdi-cog",
          blacklist: [CargoType.Railroad, CargoType.Container]
        }
      ].filter(x => !(x.blacklist || []).includes(this.cargoType));
    },
    closedOngoingItems(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: false as any,
          text: t("Ongoing")
        }),
        new RepositoryDto({
          id: true as any,
          text: t("Closed")
        })
      ];
    },
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.cargoType);
    },
    currencies(): RepositoryDto[] {
      return currencies();
    },
    showRoutesItems(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: PriceFilter.WithPrices,
          text: t("ShowRoutesWithPrices")
        }),
        new RepositoryDto({
          id: PriceFilter.WithoutPrices,
          text: t("ShowRoutesWithoutPrices")
        })
      ];
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    optionClick(id: ProcurementRowAction) {
      switch (id) {
        case ProcurementRowAction.Export:
          this.$emit("exportToExcel");
          break;
        case ProcurementRowAction.ImportExcel:
          this.excelImportDialog = true;
          break;
        case ProcurementRowAction.ImportPrev:
          this.importPrevDialog = true;
          break;
        case ProcurementRowAction.StopPrice:
          this.$emit("setStopPrice");
          break;
        case ProcurementRowAction.CalculateDistance:
          this.$emit("calculateDistance");
          break;
        case ProcurementRowAction.CalculatePercentage:
          this.$emit("calculatePercentage");
          break;
        case ProcurementRowAction.CalculateDistanceAndPercentage:
          this.$emit("calculateDistanceAndPercentage");
          break;
        case ProcurementRowAction.Settings:
          this.configDialog = true;
          break;
        default:
          neverHappens(id);
      }
    }
  },
  mounted() {
    cached("clients", [true, true, false])
      .then(res => {
        this.clients = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    cached("countiesByCargotype", this.cargoType, true)
      .then(res => {
        res.sort((r1, r2) => {
          return (r1.text as String).localeCompare(r2.text!);
        });
        this.pickupCounties = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    cached("countiesByCargotype", this.cargoType, false)
      .then(res => {
        res.sort((r1, r2) => {
          return (r1.text as String).localeCompare(r2.text!);
        });
        this.deliveryCounties = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
    cached("countries")
      .then(res => {
        this.countries = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    menu: boolean;
    configDialog: boolean;
    excelImportDialog: boolean;
    importPrevDialog: boolean;
    clients: RepositoryDto[];
    pickupCounties: RepositoryDto[];
    deliveryCounties: RepositoryDto[];
    countries: RepositoryDto[];
  } => ({
    menu: false,
    configDialog: false,
    excelImportDialog: false,
    importPrevDialog: false,
    clients: [],
    pickupCounties: [],
    deliveryCounties: [],
    countries: []
  })
});
