

























import Vue from "vue";
import { LoadClientEditAssortedValuesDto, CargoType } from "@/scripts/cld.api";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    step: Number,
    assortedValues: LoadClientEditAssortedValuesDto,
    cargoType: Number as () => CargoType
  },
  components: {
    NumberInput,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return this.cargoType === CargoType.MachineTransport;
    }
  }
});
