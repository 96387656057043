




































































import Vue from "vue";
import money from "@/scripts/misc/money";
import { actions } from "@/scripts/store/constants";
import {
  ApiConfiguration,
  LoadsClient,
  LoadDetailsHistoryDto
} from "@/scripts/cld.api";
import LoadHistoryDialog from "@/components/web/loads/shared/details/LoadHistoryDialog.vue";

export default Vue.extend({
  props: {
    loadId: Number as () => number | undefined,
    expanded: Boolean
  },
  components: {
    LoadHistoryDialog
  },
  watch: {
    loadId: {
      handler() {
        this.fetchHistory();
      },
      immediate: true
    }
  },
  computed: {
    numHistoryRows(): string {
      const displayCount = Math.min(
        this.historyRows.length,
        this.subSectionSize
      );
      return ` (${displayCount} ${this.$t("Of")} ${this.historyRows.length})`;
    },
    historySubSection(): LoadDetailsHistoryDto[] {
      return this.historyRows.slice(0, this.subSectionSize);
    }
  },
  methods: {
    money: money,
    fetchHistory() {
      if (!this.loadId) {
        return;
      }
      return new LoadsClient(new ApiConfiguration(this.$store))
        .history(this.loadId)
        .then(res => {
          this.historyRows = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    editClicked(e: any) {
      this.dialog = true;
      if (this.expanded) {
        e.stopPropagation();
      }
    }
  },
  data: (): {
    dialog: boolean;
    subSectionSize: number;
    historyRows: LoadDetailsHistoryDto[];
  } => ({
    historyRows: [],
    dialog: false,
    subSectionSize: 5
  })
});
