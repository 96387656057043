















































































import Vue from "vue";

export default Vue.extend({
  props: {
    value: Number,
    disabled: Boolean
  }
});
