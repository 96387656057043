






































































































import Vue from "vue";
import { actions, gets } from "@/scripts/store/constants";
import {
  ActorType,
  DocType,
  ApiConfiguration,
  DocumentsClient,
  PreEditSpotTenderPriceDto,
  SpotTenderAccordionDto,
  SpotTenderClient,
  DocumentGroupDto
} from "@/scripts/cld.api";
import { currencyName, truckTypeName } from "@/scripts/misc/enumNames";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";

export default Vue.extend({
  components: {
    ReadOnlyText,
    InfoTooltip,
    DocumentDropdown
  },
  props: {
    loadId: Number as () => number | undefined,
    expanded: Boolean
  },
  watch: {
    loadId: {
      handler() {
        this.fetchSpotTender();
        this.fetchDocumentGroup();
      },
      immediate: true
    }
  },
  computed: {
    isClient(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    },
    currencyName(): string {
      if (!this.tender) {
        return "";
      }
      return currencyName(this.tender.currency);
    }
  },
  methods: {
    bookedRow(price: PreEditSpotTenderPriceDto): boolean {
      return (
        price.truckType === this.tender.truckType &&
        price.supplierId === this.tender.supplierId
      );
    },
    truckTypeName: truckTypeName,
    fetchSpotTender() {
      if (!this.loadId) {
        return;
      }
      return new SpotTenderClient(new ApiConfiguration(this.$store))
        .spotTenderAccordionDetails(this.loadId)
        .then(res => {
          this.tender = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocumentGroup() {
      if (!this.loadId) {
        return;
      }
      new DocumentsClient(new ApiConfiguration(this.$store))
        .spotTender(this.loadId)
        .then(res => {
          this.documentGroups = [res];
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    tender: SpotTenderAccordionDto;
    documentGroups: DocumentGroupDto[];
  } => ({
    tender: undefined as any,
    documentGroups: []
  })
});
