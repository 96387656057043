




























































































import Vue from "vue";
import {
  ApiConfiguration,
  LoadStatus,
  LoadsClient,
  LoadDetailsBaseValuesDto,
  LoadDetailsDatesDto,
  LoadDetailsShippingInfoDto,
  LoadDetailsContentDto,
  LoadClientEditSubLocations,
  LoadClientEditContainersDto,
  RelatedLoadsDto,
  LoadActionPermission,
  LoadDetailsDeviationDto,
  ActorType,
  DocumentsClient,
  LoadDocumentDto
} from "@/scripts/cld.api";
import { loadStatusName } from "@/scripts/misc/enumNames";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions, gets } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadDialogBaseValues from "@/components/web/loads/shared/details/LoadDialogBaseValues.vue";
import LoadDialogDates from "@/components/web/loads/shared/details/LoadDialogDates.vue";
import LoadDialogEventDates from "@/components/web/loads/shared/details/LoadDialogEventDates.vue";
import LoadDialogShippingInfo from "@/components/web/loads/shared/details/LoadDialogShippingInfo.vue";
import Accordion from "@/components/web/loads/client/accordion/Accordion.vue";
import LoadDialogContainerInfo from "@/components/web/loads/shared/details/LoadDialogContainerInfo.vue";
import LoadDialogMachineTransportInfo from "@/components/web/loads/shared/details/LoadDialogMachineTransportInfo.vue";
import { LoadAction } from "@/scripts/types";
import { pdfPrompt } from "@/scripts/misc/filePrompts";
export default Vue.extend({
  components: {
    DialogCard,
    LoadLock,
    LoadDialogBaseValues,
    LoadDialogDates,
    LoadDialogEventDates,
    LoadDialogShippingInfo,
    LoadDialogContainerInfo,
    LoadDialogMachineTransportInfo,
    Accordion
  },
  props: {
    loadIds: Array as () => number[],
    dialog: Boolean,
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      if (this.loadStatus) {
        return `${this.$t("Details")} - ${this.$t("LoadID")}: ${
          this.loadId
        } (${loadStatusName(this.loadStatus)})`;
      }
      return `${this.$t("Details")}`;
    },
    actions(): LoadAction[] {
      return this.$store.getters
        .loadActions(this.loadId)
        .filter((a: LoadAction) => a.id !== LoadActionPermission.Details);
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    loadId(): number {
      return this.loadIds[0]!;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    init() {
      this.loading = true;
      this.fetchLoadPermissions();
      new LoadsClient(new ApiConfiguration(this.$store))
        .details(this.loadId)
        .then(res => {
          this.baseValues = res.baseValues;
          this.dates = res.dates;
          this.shippingInfo = res.shippingInfo;
          this.content = res.content;
          this.loadStatus = res.loadStatus;
          this.deviationValues = res.deviation;
          this.loading = false;
          this.customsInfoRequired = res.customsInfoRequired;
        })
        .then(() => {
          this.fetchRelatedLoads();
          this.fetchSubLocations();
          this.fetchContainers();
          this.fetchDocuments();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, this.close);
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchLoadPermissions() {
      this.$store
        .dispatch(actions.fetchPermissions, [this.loadId])
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchRelatedLoads() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .relatedLoads(this.loadId)
        .then(res => {
          this.relatedLoads = res;
        });
    },
    fetchSubLocations() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .sublocations(this.loadId)
        .then(res => {
          this.subLocations = res;
        });
    },
    fetchContainers() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .containers(this.loadId)
        .then(res => {
          this.containerValues = res;
        });
    },
    fetchDocuments() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .loadDocuments(this.loadId)
        .then(res => {
          this.documents = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    allowed(actionId: LoadActionPermission): Boolean {
      return this.actions.find(a => a.id === actionId)!.allowed;
    },
    exportContainerSpec() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .exportContainerPdf(this.loadId)
        .then(res => {
          pdfPrompt(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    actionClick(actionId: LoadActionPermission) {
      if (actionId === LoadActionPermission.ContainerExport) {
        this.exportContainerSpec();
      } else {
        this.$router.push({
          path: this.$route.path,
          query: {
            ...this.$route.query,
            action: actionId.toString(),
            ids: "[" + this.loadId.toString() + "]"
          }
        });
      }
    }
  },
  data: (): {
    baseValues: LoadDetailsBaseValuesDto | undefined;
    dates: LoadDetailsDatesDto | undefined;
    shippingInfo: LoadDetailsShippingInfoDto | undefined;
    content: LoadDetailsContentDto | undefined;
    loading: boolean;
    valid: boolean;
    loadStatus?: LoadStatus;
    subLocations?: LoadClientEditSubLocations;
    relatedLoads?: RelatedLoadsDto;
    containerValues?: LoadClientEditContainersDto;
    deviationValues?: LoadDetailsDeviationDto | undefined;
    documents?: LoadDocumentDto[];
    actionEnum: typeof LoadActionPermission;
    customsInfoRequired: Boolean;
  } => ({
    baseValues: undefined,
    dates: undefined,
    shippingInfo: undefined,
    content: undefined,
    loading: true,
    valid: false,
    loadStatus: undefined,
    subLocations: undefined,
    relatedLoads: undefined,
    containerValues: undefined,
    deviationValues: undefined,
    documents: undefined,
    actionEnum: LoadActionPermission,
    customsInfoRequired: false
  })
});
