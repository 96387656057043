























































































import Vue from "vue";
import Spinner from "@/components/shared/ui/Spinner.vue";

export default Vue.extend({
  components: {
    Spinner
  },
  props: {
    dialog: Boolean,
    title: String,
    loading: Boolean,
    fullHeight: Boolean,
    semiFullHeight: Boolean,
    noPaddingBottom: Boolean,
    noPaddingSides: Boolean,
    width: [Number, String],
    noCloseButton: Boolean,
    warning: Boolean,
    grey: Boolean
  }
});
