
























import Vue from "vue";
import { ApiConfiguration, AgreementsClientClient } from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import MomentX from "@/scripts/misc/momentX";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker
  },
  methods: {
    exportToExcel() {
      this.exportLoading = true;
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .exportSupplierRoutePrices(this.exportDate)
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    exportLoading: boolean;
    exportDate: MomentX;
  } => ({
    exportLoading: false,
    exportDate: new MomentX()
  })
});
