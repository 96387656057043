












import Vue from "vue";
import { Culture, DocumentDto } from "@/scripts/cld.api";
import { langs } from "@/scripts/language/i18n";
import Config from "@/scripts/config/config";

export const viewDocument = (
  diskName: string | undefined,
  fileFormat?: string //if not already included in diskName
) => {
  window.open(
    `${Config.getDocsUrl()}/e/${diskName}${fileFormat ? "." + fileFormat : ""}`
  );
};

export default Vue.extend({
  props: {
    document: DocumentDto,
    small: Boolean
  },
  methods: {
    langImg(culture: Culture): any {
      return langs.find(l => l.locale === culture)!.image;
    },
    documentClicked(document: DocumentDto) {
      viewDocument(document.diskName, document.fileFormat);
      this.$emit("click");
    }
  }
});
