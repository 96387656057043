import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { UserInfo } from "@/scripts/types";
import { authModule } from "@/scripts/store/authModule";
import { feedbackModule } from "@/scripts/store/feedbackModule";
import { loadModule } from "@/scripts/store/loadModule";
import { userConfModule } from "@/scripts/store/userConfModule";
import { actorConfModule } from "@/scripts/store/actorConfModule";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { BroadcastDto } from "@/scripts/cld.api";
import {
  ble,
  clientOutdated,
  endpointNotFound,
  frameWindowTimedOutError,
  internalServerError,
  networkError,
  unauthorized
} from "@/scripts/misc/apiErrors";
import MomentX from "@/scripts/misc/momentX";
import Auth from "@/scripts/auth";

Vue.use(Vuex);

export interface RootState {
  userInfo: UserInfo;
  unviewedMessages: number | undefined;
  clientOutdated: boolean;
  currentClientVersion: string;
  locationCacheOutdated: boolean;
  broadcast: BroadcastDto | undefined;
  globalError: {
    show: boolean;
    message: String;
    closable: boolean;
  };
  dimensions: {
    width: number;
    height: number;
  };
  visible: boolean;
  mouseActivityDate: MomentX;
  activityDate: MomentX;
}

const store: StoreOptions<RootState> = {
  state: {
    userInfo: {
      loadUserId: undefined,
      actorName: "",
      actorId: undefined,
      actorType: undefined,
      userLevel: undefined,
      actorCargoTypes: [],
      supportAdminLevel: 0
    },
    unviewedMessages: undefined,
    clientOutdated: false,
    currentClientVersion: getScriptName(),
    locationCacheOutdated: false,
    broadcast: undefined,
    globalError: {
      show: false,
      message: "",
      closable: true
    },
    dimensions: {
      width: 1000,
      height: 1000
    },
    visible: true,
    mouseActivityDate: new MomentX(),
    activityDate: new MomentX()
  },
  modules: {
    authModule,
    feedbackModule,
    loadModule,
    userConfModule,
    actorConfModule
  },
  getters: {
    [gets.userInfo]: (state): UserInfo => {
      return state.userInfo;
    },
    [gets.unviewedMessages]: (state): number | undefined => {
      return state.unviewedMessages;
    },
    [gets.currentClientVersion]: (state): string => {
      return state.currentClientVersion;
    },
    [gets.clientOutdated]: (state): boolean => {
      return state.clientOutdated;
    },
    [gets.locationCacheOutdated]: (state): boolean => {
      return state.locationCacheOutdated;
    },
    [gets.broadcast]: (state): BroadcastDto | undefined => {
      return state.broadcast;
    },
    [gets.globalError]: state => {
      return state.globalError;
    },
    [gets.dimensions]: state => {
      return {
        width: state.dimensions.width,
        height:
          state.dimensions.height - (state.broadcast === undefined ? 0 : 43)
      };
    },
    [gets.activityDate]: (state): MomentX => {
      return state.activityDate;
    }
  },
  mutations: {
    [mutations.setUserInfo]: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    [mutations.setUnviewedMessages]: (state, unviewedMessages) => {
      state.unviewedMessages = unviewedMessages;
    },
    [mutations.setClientOutdated]: state => {
      state.clientOutdated = true;
    },
    [mutations.setLocationCacheOutdated]: (state, outdated) => {
      state.locationCacheOutdated = outdated;
    },
    [mutations.setBroadcast]: (state, broadcast) => {
      state.broadcast = broadcast;
    },
    [mutations.setGlobalError]: (state, error) => {
      state.globalError = error;
    },
    [mutations.setDimensions]: (state, dim) => {
      state.dimensions = dim;
    },
    [mutations.setVisible]: (state, visible) => {
      state.visible = visible;
      if (visible) {
        state.activityDate = new MomentX();
      }
    },
    [mutations.setMouseActivityDate]: state => {
      state.mouseActivityDate = new MomentX();
      state.activityDate = new MomentX();
    }
  },
  actions: {
    [actions.handleLoginError]: (context, error) => {
      console.error("Loginserver error", error);
      if (endpointNotFound(error)) {
        context.dispatch(
          actions.handleBasicError,
          "Loginserver endpoint not found."
        );
      } else if (internalServerError(error)) {
        context.dispatch(actions.handleBasicError, "Loginserver error.");
      } else if (frameWindowTimedOutError(error)) {
        context.dispatch(
          actions.handleBasicErrorSilently,
          "Frame window timed out."
        );
      } else {
        context.dispatch(actions.handleUnhandledError, error);
      }
    },
    [actions.handleApiError]: (context, error) => {
      console.error("API error", error);
      if (clientOutdated(error)) {
        context.commit(mutations.setClientOutdated);
      } else if (ble(error)) {
        context.dispatch(actions.handleBasicError, "Business logic exception.");
      } else if (unauthorized(error)) {
        context.dispatch(
          actions.handleBasicErrorSilently,
          "Unauthorized request."
        );
      } else if (endpointNotFound(error)) {
        context.dispatch(actions.handleBasicError, "Endpoint not found.");
      } else if (internalServerError(error)) {
        context.dispatch(actions.handleBasicError, "Internal server error.");
      } else if (networkError(error)) {
        context.dispatch(actions.handleBasicErrorSilently, "Network error.");
      } else {
        context.dispatch(actions.handleUnhandledError, error);
      }
    },
    [actions.handleUnhandledError]: (context, error) => {
      console.error("Unhandled error", error);
      const formattedMessage =
        error.message +
        " | HTTP-Status: " +
        error.status +
        " | Headers: " +
        JSON.stringify(error.headers);
      context.dispatch(actions.handleBasicError, formattedMessage);
    },
    [actions.handleBasicError]: (context, message: string) => {
      message =
        "ERROR: " +
        message +
        "\n" +
        "Please try again or contact our support if the issue remains." +
        "\n" +
        new MomentX().dateTimePrint();
      console.error(message);
      context.commit(mutations.setGlobalError, {
        show: true,
        message: message,
        closable: true
      });
    },
    [actions.handleBasicErrorSilently]: (context, message: string) => {
      message = "ERROR: " + message + "\n" + new MomentX().dateTimePrint();
      console.error(message);
      if (!Auth.browsingProd()) {
        message += "\nJUST DET HÄR MEDDELANDET DYKER INTE UPP I PROD!";
        context.commit(mutations.setGlobalError, {
          show: true,
          message: message,
          closable: true
        });
      }
    }
  }
};

function getScriptName(): string {
  const scriptTags = document.getElementsByTagName("script");
  const scriptPath = scriptTags[scriptTags.length - 1].src;
  const scriptNameStart = scriptPath.indexOf("/app.") + 1;
  const scriptName = scriptPath.substr(scriptNameStart);
  return scriptName;
}

export default new Vuex.Store<RootState>(store);
