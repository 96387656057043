





















































































import Vue from "vue";
import {
  ApiConfiguration,
  LoadActionPermission,
  LoadsSupplierClient,
  ResignLoadDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import LoadDialogMultiTable from "@/components/web/loads/shared/LoadDialogMultiTable.vue";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { MultiLoad } from "@/scripts/types";

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    LoadLock,
    LoadChips,
    LoadDialogMultiTable
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    },
    valid() {
      this.loads.map(l => (l.valid = this.valid));
    }
  },
  computed: {
    multi(): boolean {
      return this.loadIds.length > 1;
    }
  },
  methods: {
    async resign() {
      this.saveInProgress = true;
      let saveOk = true;
      for (const loadId of this.loadIds) {
        const saveDto = new ResignLoadDto({
          loadId: loadId,
          description: this.description
        });
        await new LoadsSupplierClient(new ApiConfiguration(this.$store))
          .resign(saveDto)
          .then(() => {
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = true;
          })
          .catch(error => {
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = false;
            saveOk = false;
            if (ble(error)) {
              errorDialog(error, () => {});
            } else {
              this.$store.dispatch(actions.handleApiError, error);
            }
          });
      }
      if (saveOk) {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    init() {
      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .preResignDetails(this.loadIds)
        .then(res => {
          this.loads = res.map(x => ({
            loadId: x.loadId,
            routeName: x.routeName!,
            pickupEarliest: x.pickupEarliest,
            deliverLatest: x.deliverLatest,
            truckType: x.truckType,
            transportNo: undefined,
            clientName: x.clientName!,
            numExtraStops: x.numExtraStops,
            numContainers: x.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          }));
          this.selectedLoadId = this.loads[0].loadId;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    loads: MultiLoad[];
    selectedLoadId: number | undefined;
    description: string;
  } => ({
    valid: false,
    saveInProgress: false,
    loads: [],
    selectedLoadId: undefined,
    description: ""
  })
});
