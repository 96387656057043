











































import Vue from "vue";
import {
  ApiConfiguration,
  AppendixListItemDto,
  LoadAppendixType,
  LoadsClient
} from "@/scripts/cld.api";
import { appendixName } from "@/scripts/misc/enumNames";
import AppendixPackageSpecDialog from "@/components/web/loads/shared/appendix/AppendixPackageSpecDialog.vue";
import AppendixConsigneeDialog from "@/components/web/loads/shared/appendix/AppendixConsigneeDialog.vue";

export default Vue.extend({
  props: {
    loadId: Number as () => number | undefined,
    expanded: Boolean
  },
  components: {
    AppendixPackageSpecDialog,
    AppendixConsigneeDialog
  },
  watch: {
    loadId: {
      handler() {
        this.fetchAppendices();
      },
      immediate: true
    }
  },
  computed: {
    disabled(): boolean {
      return !this.appendices || this.appendices.length === 0;
    },
    numAppendices(): string {
      if (!this.appendices) {
        return "";
      }
      return `(${this.appendices.length})`;
    }
  },
  methods: {
    appendixName: appendixName,
    fetchAppendices() {
      if (!this.loadId) {
        this.appendices = [];
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .appendices(this.loadId)
        .then(res => {
          this.appendices = res;
        });
    },
    displayAppendixDialog(type: LoadAppendixType, appendixId: Number, e: any) {
      if (type === LoadAppendixType.PackageSpec) {
        this.selectedPackageSpecId = appendixId;
      } else if (type === LoadAppendixType.ConsigneeInfo) {
        this.selectedConsigneeId = appendixId;
      }
      if (this.expanded) {
        e.stopPropagation();
      }
    },
    closeConsignee() {
      this.selectedConsigneeId = undefined;
    },
    closePackageSpec() {
      this.selectedPackageSpecId = undefined;
    }
  },
  data: (): {
    appendices?: AppendixListItemDto[];
    selectedConsigneeId?: Number | undefined;
    selectedPackageSpecId?: Number | undefined;
  } => ({
    appendices: undefined,
    selectedConsigneeId: undefined,
    selectedPackageSpecId: undefined
  })
});
