var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.agreementContainer.agreementRows,"item-key":"id","loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"custom-group":_vm.customGroup,"server-items-length":_vm.agreementContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [50, 200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationCargotype',
              pickup: true,
              cargoType: _vm.cargoType
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupLocationCounty,
              zipCode: _vm.searchCriteria.pickupLocationZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupLocationCounty = val.county;
                _vm.searchCriteria.pickupLocationZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationCargotype',
              pickup: false,
              cargoType: _vm.cargoType
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryLocationCounty,
              zipCode: _vm.searchCriteria.deliveryLocationZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryLocationCounty = val.county;
                _vm.searchCriteria.deliveryLocationZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.currencyName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currencyType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currencyType", $$v)},expression:"searchCriteria.currencyType"}})]},proxy:true},{key:"filter.truckTypeName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'truckType', cargoType: _vm.cargoType },"placeholder":_vm.$t('TruckType')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.truckType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "truckType", $$v)},expression:"searchCriteria.truckType"}})]},proxy:true},{key:"filter.baseIndexName",fn:function(){return [_c('CustomFilter',{attrs:{"value":_vm.searchCriteria.baseIndexDate
                ? _vm.searchCriteria.baseIndexDate.dateTimePrint()
                : undefined,"config":{ subtype: 'baseIndexDate' },"placeholder":_vm.$t('BaseIndex')},on:{"input":function (v) { return (_vm.searchCriteria.baseIndexDate = _vm.momentFromDateString(v)); },"search":_vm.search}})]},proxy:true}],null,true)})]}},{key:"group.header",fn:function(ref){
                var group = ref.group;
return [_c('td',{staticClass:"font-weight-bold group-header",attrs:{"colspan":"99"}},[_vm._v(" "+_vm._s(group)+" ")])]}},{key:"item.pickupLocation",fn:function(ref){
                var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.pickupText(item),"width":"170"}})]}},{key:"item.deliveryLocation",fn:function(ref){
                var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.deliveryText(item),"width":"190"}})]}},{key:"item.specialRequirements",fn:function(ref){
                var item = ref.item;
return [(!!item.specialRequirements)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"group-header-icon",attrs:{"color":"red","size":"16"}},on),[_vm._v(" mdi-exclamation-thick ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.specialRequirements))])]):_vm._e()]}},{key:"item.agreementPeriod",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.agreementFromDate.datePrint())+" - "+_vm._s(item.agreementToDate.datePrint())+" ")])]}},{key:"item.truckTypeName",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(item.truckTypeName))+" ")]}},{key:"item.ranking",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.ranking === 0 ? "" : item.ranking)+" ")]}},{key:"item.basePrice",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.basePrice, 0))+" ")])]}},{key:"item.invoicePrice",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines clickable",on:{"click":function($event){return _vm.goToIndexes(item)}}},on),[_vm._v(" "+_vm._s(_vm.money(item.invoicePrice, 0))+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(item))+" ")])]}},{key:"item.stopPrice",fn:function(ref){
                var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.stopPrice, 0))+" ")])]}},{key:"item.baseIndexName",fn:function(ref){
                var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines clickable",on:{"click":function($event){return _vm.goToIndexes(item)}}},on),[_vm._v(" "+_vm._s(item.baseIndexName)+" ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(item))+" ")])]}},{key:"item.note",fn:function(ref){
                var item = ref.item;
return [(item.note)?_c('v-tooltip',{attrs:{"top":"","open-delay":0},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"icon-yellow"}},on),[_vm._v(" mdi-note ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.note))])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }