



























import Vue from "vue";
import {
  ApiConfiguration,
  DocumentGroupDto,
  DocumentsClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import Spinner from "@/components/shared/ui/Spinner.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DocumentDialog from "@/components/web/documents/DocumentDialog.vue";
import { stringCompare } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    Spinner,
    SearchCard,
    DocumentDialog
  },
  computed: {
    height(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 135);
    }
  },
  methods: {
    fetchDocuments() {
      this.loading = true;
      new DocumentsClient(new ApiConfiguration(this.$store))
        .supportAdminList()
        .then(res => {
          this.documentGroups = res.sort((a, b) =>
            stringCompare(a.actorName, b.actorName)
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchDocuments();
  },
  data: (): {
    loading: boolean;
    documentGroups: DocumentGroupDto[];
    documentGroupId: number | undefined;
  } => ({
    loading: true,
    documentGroups: [],
    documentGroupId: undefined
  })
});
