var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.agreementRowIndex !== 0)?_c('div',{staticClass:"ranking-column-margins"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"no-padding-top no-padding-bottom",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ transparent: _vm.agreementRow.ranking <= 0 },attrs:{"block":"","depressed":"","height":"12","disabled":_vm.agreementRow.ranking <= 0},on:{"click":function($event){return _vm.explode(_vm.truckTypeId, _vm.agreementRowIndex)}}},false || on),[(hover)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-unfold-more-horizontal ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("ExplodeRanking")))])])],1),_c('v-col',{staticClass:"no-padding-top no-padding-bottom",attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{ transparent: _vm.agreementRow.ranking <= 0 },attrs:{"block":"","depressed":"","height":"12","disabled":_vm.agreementRow.ranking <= 0},on:{"click":function($event){return _vm.implode(_vm.truckTypeId, _vm.agreementRowIndex)}}},on),[(hover)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-unfold-less-horizontal ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("ImplodeRanking")))])])],1)],1)]}}],null,false,3092719794)})],1):_vm._e(),_c('v-sheet',{staticClass:"supplier-card ranking-column-margins draggable",attrs:{"elevation":"3","color":_vm.agreementRow.ranking === -1 ? 'background-red' : '',"rounded":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{staticClass:"no-padding-top no-padding-bottom no-padding-right",attrs:{"cols":"6","lg":"7","xl":"8"}},[_c('v-tooltip',{attrs:{"top":"","open-delay":_vm.agreementRow.supplierId !== -1 ? 250 : 99999},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"text-truncate draggable font-weight-medium",class:{
                    transparent: _vm.agreementRow.supplierId === -1
                  }},on),[_vm._v(" "+_vm._s(_vm.agreementRow.supplierName)+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.agreementRow.supplierName))])])],1),_c('v-spacer'),_c('v-col',{staticClass:"share-text no-vertical-padding",attrs:{"cols":"auto"}},[_c('v-badge',{attrs:{"color":_vm.rankingColor,"content":_vm.agreementRow.ranking > 0 ? _vm.agreementRow.ranking : '',"offset-x":"-3","offset-y":"12"}},[_c('span',{class:{
                  transparent:
                    _vm.agreementRow.supplierId === -1 || !_vm.agreementRow.share
                }},[_vm._v(" "+_vm._s(_vm.agreementRow.share)+"% ")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"price-text text-no-wrap font-weight-bold",class:{
              transparent: _vm.agreementRow.supplierId === -1
            },attrs:{"cols":"auto"}},[_vm._v(" "+_vm._s(_vm.money(_vm.agreementRow.freightPrice, 0))+" ")]),_c('v-col',{staticClass:"price-text text-no-wrap",class:{
              transparent: _vm.agreementRow.supplierId === -1
            },attrs:{"cols":"auto"}},[_vm._v("   "+_vm._s(_vm.secondaryValue(_vm.agreementRow))+" ")]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.agreementRow.note)?_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"icon-yellow"}},'v-icon',attrs,false),on),[_vm._v(" mdi-note ")])]}}],null,false,4149076731)},[_c('span',[_vm._v(_vm._s(_vm.agreementRow.note))])]):_vm._e(),(
                _vm.agreementRow.ranking > 0 &&
                  _vm.agreementRow.supplierId !== -1 &&
                  _vm.agreementRow.currentRankingType === _vm.rankingType.Manual
              )?_c('v-icon',[_vm._v(" mdi-altimeter ")]):_vm._e(),(
                _vm.agreementRow.ranking > 0 &&
                  _vm.agreementRow.supplierId !== -1 &&
                  _vm.agreementRow.currentRankingType === _vm.rankingType.Price
              )?_c('v-icon',[_vm._v(" mdi-currency-usd ")]):_vm._e(),(
                _vm.agreementRow.ranking > 0 &&
                  _vm.agreementRow.supplierId !== -1 &&
                  _vm.agreementRow.currentRankingType !== _vm.rankingType.Manual &&
                  _vm.agreementRow.currentRankingType !== _vm.rankingType.Price
              )?_c('v-tooltip',{attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.flagColor}},'v-icon',attrs,false),on),[_vm._v(" mdi-flag ")])]}}],null,false,1437592884)},[_c('span',[_vm._v(_vm._s(_vm.qualityPointsTooltip))])]):_vm._e(),(_vm.agreementRow.ranking === -1)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.restoreAgreementRow(_vm.truckTypeId, _vm.agreementRowIndex)}}},[_c('v-icon',{attrs:{"color":"icon-grey"}},[_vm._v(" mdi-undo ")])],1):_c('v-tooltip',{staticClass:"not-draggable",attrs:{"top":"","open-delay":"250"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"not-draggable",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeAgreementRow(_vm.truckTypeId, _vm.agreementRowIndex)}}},on),[_c('v-icon',{attrs:{"color":"icon-grey"}},[_vm._v(" mdi-trash-can-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.agreementRow.supplierId === -1 ? _vm.$t("DeleteRankingposition") : _vm.$t("DeletePrice")))])])],1)],1)],1),(_vm.cargoTypeId === 4)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" Stop price: ")])]),_c('v-col',{staticClass:"text-no-wrap no-padding-top",attrs:{"cols":"auto"}},[_vm._v("  "+_vm._s(_vm.money(_vm.agreementRow.stopPrice, 0))+" ")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }