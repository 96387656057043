















































































































import Vue from "vue";
import { ContainerDto, LoadClientEditContainersDto } from "@/scripts/cld.api";
import { saveBeforeContinueDialog } from "@/scripts/misc/popupDialogs";
import objectsEqual from "@/scripts/misc/objectsEqual";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    NumberInput
  },
  props: {
    dialog: Boolean,
    containerValues: Object as () => LoadClientEditContainersDto
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.containerValuesDraft = this.copy(this.containerValues);
        }
      },
      immediate: true
    }
  },
  computed: {
    pendingChanges(): boolean {
      return !objectsEqual(this.containerValuesDraft, this.containerValues);
    }
  },
  methods: {
    close() {
      if (this.pendingChanges) {
        saveBeforeContinueDialog(
          () => {
            this.saveDraft();
          },
          () => {
            this.closeCallback();
          }
        );
      } else {
        this.closeCallback();
      }
    },
    closeCallback() {
      this.$emit("close");
    },
    saveDraft() {
      this.containerValues.incoterms = this.containerValuesDraft!.incoterms;
      this.containerValues.knCurrency = this.containerValuesDraft!.knCurrency;
      this.containerValues.knValue = this.containerValuesDraft!.knValue;
      this.containerValues.knProductCode = this.containerValuesDraft!.knProductCode;
      this.containerValues.containers = [
        ...this.containerValuesDraft!.containers!
      ];
      this.closeCallback();
    },
    copy(obj: LoadClientEditContainersDto): LoadClientEditContainersDto {
      return new LoadClientEditContainersDto({
        eta: obj.eta,
        etd: obj.etd,
        dateForClosing: obj.dateForClosing,
        stuffingLocation: obj.stuffingLocation,
        shipName: obj.shipName,
        incoterms: obj.incoterms,
        knCurrency: obj.knCurrency,
        knValue: obj.knValue,
        knProductCode: obj.knProductCode,
        sumPackages: obj.sumPackages,
        sumVolume: obj.sumVolume,
        sumWeight: obj.sumWeight,
        thcValue: obj.thcValue,
        thcCurrency: obj.thcCurrency,
        containers: obj.containers!.map(
          c =>
            new ContainerDto({
              id: c.id,
              containerNo: c.containerNo,
              sealNo: c.sealNo,
              weight: c.weight,
              volume: c.volume,
              packages: c.packages,
              blMark: c.blMark,
              externalContainerId: c.externalContainerId
            })
        )
      });
    },
    addContainer() {
      this.containerValuesDraft!.containers!.push(
        new ContainerDto({
          id: 0,
          containerNo: undefined,
          sealNo: undefined,
          weight: undefined,
          volume: undefined,
          packages: undefined,
          blMark: undefined,
          externalContainerId: undefined
        })
      );
    },
    removeContainer(index: number) {
      this.containerValuesDraft!.containers!.splice(index, 1);
    }
  },
  data: (): {
    containerValuesDraft?: LoadClientEditContainersDto;
  } => ({
    containerValuesDraft: undefined
  })
});
