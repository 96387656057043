






















































































































































































































































































































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextWithAutocomplete from "@/components/shared/input/TextWithAutocomplete.vue";
import {
  AddLocationDto,
  ApiConfiguration,
  RepositoryDto,
  LocationsClient,
  CloseMatchesContainerDto,
  ItineraryDto,
  Country,
  ExactMatchesContainerDto,
  SuggestionDto,
  RemoveLocationDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";
import { routingExactnessName } from "@/scripts/misc/enumNames";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    TextWithAutocomplete
  },
  watch: {
    countryId: {
      handler() {
        this.fetchCountiesWithZipcodes();
      },
      immediate: true
    },
    county() {
      this.fetchCountiesWithZipcodes();
    },
    zipCode() {
      this.fetchCountiesWithZipcodes();
    }
  },
  methods: {
    routingExactnessName: routingExactnessName,
    fetchCountiesWithZipcodes() {
      if (!this.countryId) {
        return;
      }
      const unique = nextUnique();
      this.activeRequest = unique;
      this.countyTexts = undefined;
      this.zipCodeTexts = undefined;
      cached("countiesStartingWith", [this.county, this.countryId])
        .then(res => {
          if (this.activeRequest === unique) {
            this.countyTexts = res;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      cached("zipcodesStartingWith", [this.zipCode, this.countryId])
        .then(res => {
          if (this.activeRequest === unique) {
            this.zipCodeTexts = res;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    check() {
      if (!this.county || !this.zipCode || !this.countryId) {
        return;
      }
      this.checkMade = true;
      this.loadingSemaphore = 4;
      this.closeMatches.countyMatches = [];
      this.closeMatches.zipCodeMatches = [];
      this.closeMatches.overallMatches = [];
      this.exactMatches.countyMatches = [];
      this.exactMatches.zipCodeMatches = [];
      this.exactMatches.exactMatch = undefined;
      this.itinerary = undefined;
      this.suggestions = [];
      new LocationsClient(new ApiConfiguration(this.$store))
        .closeMatches(
          new AddLocationDto({
            county: this.county,
            zipCode: this.zipCode,
            country: this.countryId
          })
        )
        .then(res => {
          this.closeMatches = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      new LocationsClient(new ApiConfiguration(this.$store))
        .exactMatches(
          new AddLocationDto({
            county: this.county,
            zipCode: this.zipCode,
            country: this.countryId
          })
        )
        .then(res => {
          this.exactMatches = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      cached("itinerary", [
        Country.Sweden,
        "Järfälla",
        "17547",
        "Tavelvägen 16",
        this.countryId as Country,
        this.county,
        this.zipCode,
        "",
        null
      ])
        .then(res => {
          this.itinerary = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      cached("addressSuggestions", [
        this.countryId as Country,
        this.county,
        this.zipCode,
        ""
      ])
        .then(res => {
          this.suggestions = res;
          this.suggestions.map(s => (s.county = s.county!.toUpperCase()));
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    add() {
      this.loadingSemaphore++;
      new LocationsClient(new ApiConfiguration(this.$store))
        .add(
          new AddLocationDto({
            county: this.county,
            zipCode: this.zipCode,
            country: this.countryId as Country
          })
        )
        .then(() => {
          this.loadingSemaphore--;
          this.county = "";
          this.zipCode = "";
          this.fetchRemovable();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchRemovable() {
      new LocationsClient(new ApiConfiguration(this.$store))
        .listRemovable()
        .then(res => {
          this.removable = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    remove(locationId: number) {
      this.loadingSemaphore++;
      new LocationsClient(new ApiConfiguration(this.$store))
        .remove(locationId)
        .then(() => {
          this.loadingSemaphore--;
          this.fetchRemovable();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchCountries();
    this.fetchRemovable();
  },
  data: (): {
    valid: boolean;
    county: string;
    zipCode: string;
    countryId: number | undefined;
    countries: RepositoryDto[];
    countyTexts: string[] | undefined;
    zipCodeTexts: string[] | undefined;
    closeMatches: CloseMatchesContainerDto;
    exactMatches: ExactMatchesContainerDto;
    itinerary: ItineraryDto | undefined;
    suggestions: SuggestionDto[];
    loadingSemaphore: number;
    checkMade: boolean;
    removable: RemoveLocationDto[];
    activeRequest: number;
  } => ({
    valid: false,
    county: "",
    zipCode: "",
    countryId: undefined,
    countries: [],
    countyTexts: [],
    zipCodeTexts: [],
    closeMatches: new CloseMatchesContainerDto({
      overallMatches: [],
      countyMatches: [],
      zipCodeMatches: []
    }),
    exactMatches: new ExactMatchesContainerDto({
      countyMatches: [],
      zipCodeMatches: [],
      exactMatch: undefined
    }),
    itinerary: undefined,
    suggestions: [],
    loadingSemaphore: 0,
    checkMade: false,
    removable: [],
    activeRequest: -1
  })
});
