import Oidc, { UserManagerSettings } from "oidc-client";
import Config from "@/scripts/config/config";

const _browsingMobile = window.location.pathname.startsWith("/mobile");
const _browsingSupportAdmin = window.location.pathname.startsWith(
  "/support-admin"
);
const signinEndpoint =
  (_browsingSupportAdmin
    ? "/support-admin"
    : _browsingMobile
    ? "/mobile"
    : "") + "/auth-signin";
const signoutEndpoint =
  (_browsingSupportAdmin
    ? "/support-admin"
    : _browsingMobile
    ? "/mobile"
    : "") + "/auth-signout";
const silentEndpoint =
  (_browsingSupportAdmin
    ? "/support-admin"
    : _browsingMobile
    ? "/mobile"
    : "") + "/auth-silent";
const settings: UserManagerSettings = {
  authority: Config.getAuthority(),
  client_id: Config.getClientId(),
  redirect_uri: Config.getRedirectUriBase() + signinEndpoint,
  post_logout_redirect_uri: Config.getRedirectUriBase() + signoutEndpoint,
  response_type: "id_token token",
  scope: Config.getScope(),
  silent_redirect_uri: Config.getRedirectUriBase() + silentEndpoint,
  silentRequestTimeout: 10000,
  filterProtocolClaims: true,
  loadUserInfo: true
};
const userManager = new Oidc.UserManager(settings);
Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.WARN;
//Oidc.Log.level = Oidc.Log.DEBUG; //Ger mer utskrifter
userManager.events.addUserSignedOut(() => {
  userManager.removeUser();
  console.error("User is no longer signed into the loginserver.");
});

export default {
  browsingSupportAdmin() {
    return _browsingSupportAdmin;
  },
  browsingMobile() {
    return _browsingMobile;
  },
  browsingProd() {
    return (
      window.location.hostname && window.location.hostname.startsWith("www")
    );
  },
  getUser() {
    return userManager.getUser();
  },
  removeUser() {
    return userManager.removeUser();
  },
  startSigninSilent() {
    userManager.clearStaleState();
    return userManager.signinSilent();
  },
  endSigninSilent() {
    return userManager.signinSilentCallback();
  },
  startSigninMainWindow(path: string) {
    userManager.clearStaleState();
    return userManager.signinRedirect({
      state: { pathBeforeAuthentication: path }
    });
  },
  endSigninMainWindow() {
    return userManager.signinRedirectCallback();
  },
  startSignoutMainWindow(path: string) {
    userManager.clearStaleState();
    return userManager.signoutRedirect({
      state: { pathBeforeAuthentication: path }
    });
  },
  endSignoutMainWindow() {
    return userManager.signoutRedirectCallback();
  }
};
