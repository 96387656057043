






































import { gets } from "@/scripts/store/constants";
import Vue from "vue";
export default Vue.extend({
  computed: {
    actorName(): string {
      return this.$store.getters[gets.userInfo].actorName;
    },
    trionaWeb(): string {
      return "https://www.triona.se";
    }
  },
  methods: {
    routeTo(path: string) {
      this.$router.push(path);
    },
    open(path: string) {
      window.open(path, "_blank");
    }
  }
});
