import { bestLang, localeCode, t } from "@/scripts/language/i18n";
import { ActorType, Culture } from "../cld.api";
import { gets } from "../store/constants";
import store from "../store/store";
import neverHappens from "./neverHappens";

export const stringCompare = (
  a: string | number | boolean | undefined | null,
  b: string | number | boolean | undefined | null
): number => {
  if (a === undefined) {
    a = null;
  }
  if (b === undefined) {
    b = null;
  }
  const aNum = Number(a !== null ? a : -Infinity);
  const bNum = Number(b !== null ? b : -Infinity);
  if (!isNaN(aNum) && !isNaN(bNum)) {
    return aNum - bNum;
  } else {
    if (!a) {
      a = "";
    }
    if (!b) {
      b = "";
    }
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }
};

export const stringSearchHit = (
  query: string | null | undefined,
  text: string | null | undefined,
  mustStartWith: boolean
): boolean => {
  if (!query) {
    return true;
  }
  if (!text) {
    return false;
  }

  const terms = transform(query);
  const texts = transform(text);

  const termsNotInText = terms
    .map(term =>
      texts.some(
        t => t.startsWith(term) || (!mustStartWith && t.includes(term))
      )
    )
    .filter(termIsInText => !termIsInText);
  return termsNotInText.length === 0;
};

const transform = (s: string): string[] => {
  s = s.toLocaleLowerCase();
  if (localeCode() !== "sv-SE") {
    s = s
      .replaceAll("ö", "o")
      .replaceAll("ä", "a")
      .replaceAll("å", "a");
  }
  return s
    .replaceAll("(", "")
    .replaceAll("[", "")
    .replaceAll("ã", "a")
    .replaceAll("í", "i")
    .replaceAll("é", "e")
    .split(" ")
    .filter(s => s.trim() !== "");
};

export const linkWithLocale = (link: string): string => {
  let res = link;
  while (res.includes("USERLANG")) {
    res = res.replace(
      "USERLANG",
      bestLang([Culture.English, Culture.Swedish]).shortCode
    );
  }
  const actorType = actorTypeText(store.getters[gets.userInfo].actorType);
  while (res.includes("ACTORTYPE")) {
    res = res.replace("ACTORTYPE", actorType);
  }
  return res;
};

const actorTypeText = (actorType: ActorType): string => {
  switch (actorType) {
    case ActorType.Client:
      return "client";
    case ActorType.Supplier:
      return "supplier";
    case ActorType.Customer:
      return "customer";
    case ActorType.Terminal:
      return "terminal";
    default:
      return neverHappens(actorType);
  }
};

export const validEmail = (email: string | undefined): boolean => {
  if (!email) {
    return false;
  }
  return /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const usernameErrors = (
  username: string | undefined
): string | undefined => {
  const allowedChars = "abcdefghijklmnopqrstuvwxyz0123456789";
  if (!username || username.length <= 3) {
    return t("TooShort");
  }
  if (username.toLocaleLowerCase() !== username) {
    return t("NoUpperCaseCharacters");
  }
  if (username.split("").some(c => !allowedChars.includes(c))) {
    return t("MayOnlyContain") + " " + allowedChars;
  }
  return undefined;
};
