














































import Vue from "vue";
import {
  PerformanceQPWeightsDto,
  PerformanceQPParameter
} from "@/scripts/cld.api";

export default Vue.extend({
  props: {
    loading: Boolean,
    performanceWeights: Array as () => PerformanceQPWeightsDto[]
  },
  computed: {
    performanceWeightGroups(): {
      id: number;
      name: string;
      weights: PerformanceQPWeightsDto[];
    }[] {
      const params = [
        ...new Set(this.performanceWeights.map(x => x.parameter))
      ];
      const res = [];
      for (const param of params) {
        const weights = this.performanceWeights.filter(
          x => x.parameter === param
        );
        weights.sort((a, b) => a.valueFrom - b.valueFrom);
        const name = weights[0].parameterName!;
        res.push({ id: param, name: name, weights: weights });
      }
      res.sort((a, b) => a.id - b.id);
      return res;
    }
  },
  methods: {
    classes(index: number, length: number): string {
      return [
        index % 2 === 0 ? "blue-grey lighten-2" : "grey lighten-1",
        index === 0 ? "rounded-left" : "",
        index === length - 1 ? "rounded-right" : ""
      ].join(" ");
    },
    flexWidth(weight: PerformanceQPWeightsDto): string {
      let width = weight.valueTo - weight.valueFrom;
      if (weight.parameter === PerformanceQPParameter.Price) {
        width += 1;
      }
      return `flex-grow: ${width * 100}`;
    },
    valueLabel(weight: PerformanceQPWeightsDto): string {
      if (weight.parameter === PerformanceQPParameter.Price) {
        return `#${weight.valueFrom}`;
      }
      return `${weight.valueFrom * 100}%`;
    }
  }
});
