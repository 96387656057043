



















import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import CreateProcurementAgreementRoutesTable from "@/components/web/agreements/client/CreateProcurementAgreementRoutesTable.vue";
import { AgreementRouteForProcurementDto, CargoType } from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    DialogCard,
    CreateProcurementAgreementRoutesTable
  },
  props: {
    dialog: Boolean,
    cargoType: Number as () => CargoType,
    agreementRoutes: Array as () => AgreementRouteForProcurementDto[],
    height: Number
  }
});
