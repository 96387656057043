var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding table-wrapper",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"item-key":"routeId","headers":_vm.headers,"items":_vm.reportContainer.rows,"expanded":_vm.reportContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.reportContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"item-class":function () { return 'light-grey-row header-row'; },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: true,
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'locationClient',
              pickup: false,
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.supplierName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'supplier',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Supplier')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.supplierId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "supplierId", $$v)},expression:"searchCriteria.supplierId"}})]},proxy:true},{key:"filter.truckType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'truckType',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('TruckType')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.truckType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "truckType", $$v)},expression:"searchCriteria.truckType"}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currency),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currency", $$v)},expression:"searchCriteria.currency"}})]},proxy:true},{key:"filter.tag",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'tag', cargoType: _vm.searchCriteria.cargoType },"placeholder":_vm.$t('Tag')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.tag),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "tag", $$v)},expression:"searchCriteria.tag"}})]},proxy:true}],null,true)})]}},{key:"item.pickupLocation",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_c('EllipsisTableItem',{attrs:{"text":_vm.pickup(item),"width":"220"}})],1)]}},{key:"item.deliveryLocation",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_c('EllipsisTableItem',{attrs:{"text":_vm.delivery(item),"width":"260"}})],1)]}},{key:"item.currency",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_vm._v(_vm._s(_vm.currencyName(item.currency)))])]}},{key:"item.tag",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_vm._v(_vm._s(item.tag))])]}},{key:"item.agreementPeriod",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.fromDate.datePrint())+" - "+_vm._s(item.toDate.datePrint())+" ")])]}},{key:"item.estimate",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_vm._v(_vm._s(item.estimate))])]}},{key:"item.prevAgreementPeriod",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.prevFromDate ? item.prevFromDate.datePrint() : "")+" - "+_vm._s(item.prevToDate ? item.prevToDate.datePrint() : ""))])]}},{key:"item.prevEstimate",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold dont-render-newlines"},[_vm._v(_vm._s(item.prevEstimate))])]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
return [_c('td',{staticClass:"inner-table background-grey",attrs:{"colspan":99}},[(item.truckTypes)?_c('div',[(item.truckTypes.length === 0)?_c('v-progress-linear',{attrs:{"height":"3","indeterminate":""}}):_vm._e(),_vm._l((_vm.truckTypeRows(item)),function(truckTypeRow){return _c('ProcurementAnalyticsTableExpansion',{key:truckTypeRow.truckType,attrs:{"truckTypeRow":truckTypeRow}})})],2):_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-spacer'),_vm._v(" "+_vm._s(_vm.$t("NothingToShow"))+" "),_c('v-spacer')],1)],1)])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }