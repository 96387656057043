import MomentX from "@/scripts/misc/momentX";

export default (obj: Object): any[][] => {
  const tmp: any[][] = [];
  for (const entry of Object.entries(obj)) {
    const key = entry[0];
    const val = entry[1];
    tmp.push([
      makeCamelCaseReadable(key),
      val === null || val === undefined || val === ""
        ? " "
        : MomentX.isMoment(val)
        ? val.dateTimePrint()
        : val
    ]);
  }
  return tmp.sort((a, b) => {
    return a[0].localeCompare(b[0]);
  });
};

const makeCamelCaseReadable = (cc: string) => {
  const result = cc.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};
