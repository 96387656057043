













































































import Vue from "vue";
import {
  SupplierProcurementOrderBy,
  ApiConfiguration,
  SupplierProcurementContainerDto,
  SupplierProcurementSearchCriteria,
  SupplierProcurementSearchCriteriaContainer,
  UserConfigurationType,
  ActorType,
  ProcurementsSupplierClient,
  SupplierProcurementRowDto,
  TruckType,
  CargoType,
  SupplierProcurementPriceDto,
  SupplierGivenPriceDto
} from "@/scripts/cld.api";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { SupplierProcurementConfig, TableOptions } from "@/scripts/types";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import ProcurementSearch from "@/components/web/agreements/supplier/ProcurementSearch.vue";
import ProcurementTable from "@/components/web/agreements/supplier/ProcurementTable.vue";
import ProcurementActions from "@/components/web/agreements/supplier/ProcurementActions.vue";
import {
  discardChangesDialog,
  errorDialog,
  popupDialog,
  saveBeforeContinueDialog
} from "@/scripts/misc/popupDialogs";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { t } from "@/scripts/language/i18n";

export enum ProcurementRowAction {
  Export,
  ImportExcel,
  ImportPrev,
  StopPrice,
  CalculateDistance,
  CalculatePercentage,
  CalculateDistanceAndPercentage,
  Settings
}

export default Vue.extend({
  components: {
    ProcurementSearch,
    ProcurementTable,
    ProcurementActions
  },
  watch: {
    cargoType(newVal, oldVal) {
      if (oldVal !== undefined) {
        this.setInteractable(true);
        this.search(true, false, false);
      }
    },
    unsavedChanges: {
      handler() {
        this.$store.commit(mutations.setPreventLeaving, this.unsavedChanges);
      },
      immediate: true
    },
    tryingToLeave() {
      if (this.tryingToLeave) {
        const tryingToLeaveFunction = this.tryingToLeave;
        this.$store.commit(mutations.setTryingToLeave, undefined);
        discardChangesDialog(() => {
          this.$store.commit(mutations.setPreventLeaving, false);
          tryingToLeaveFunction();
        });
      }
    }
  },
  computed: {
    cargoType(): CargoType {
      return Number(this.$route.query.cargoType);
    },
    searchCriteria(): SupplierProcurementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurement,
        this.cargoType
      );
    },
    sorting(): { orderBy: SupplierProcurementOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as SupplierProcurementOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    procurementConfig(): SupplierProcurementConfig {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurementConfig,
        1
      );
    },
    unsavedChanges(): boolean {
      return this.changedRows.length > 0;
    },
    tryingToLeave(): Function | undefined {
      return this.$store.getters[gets.tryingToLeave];
    },
    changedRows(): SupplierProcurementRowDto[] {
      return this.procurementContainer.rows!.filter(r => this.hasChanges(r));
    },
    editable(): boolean {
      return !this.searchCriteria.closedProcurements && this.interactable;
    }
  },
  methods: {
    hasChanges(row: SupplierProcurementRowDto): boolean {
      return row.priceRows!.some(
        p =>
          p.basePrice !== p.originalBasePrice ||
          p.initialFee !== p.originalInitialFee ||
          row.share !== row.originalShare ||
          row.stopPrice !== row.originalStopPrice ||
          row.serviceFee !== row.originalServiceFee ||
          row.note !== row.originalNote
      );
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      if (!this.interactable) {
        return;
      }
      this.search(false, false, false);
    },
    search(
      goToFirstPage: boolean,
      saveUserConf: boolean,
      ignoreUnsavedChanges: boolean
    ): Promise<void> {
      let promise = new Promise<void>(resolve => resolve());
      this.loading = true;
      if (goToFirstPage) {
        scrollTableToTop(this);
        this.tableOptions.page = 1;
      }
      if (this.unsavedChanges && !ignoreUnsavedChanges) {
        saveBeforeContinueDialog(
          () => {
            this.save();
          },
          () => {
            this.search(goToFirstPage, saveUserConf, true);
          }
        );
        return promise;
      }
      promise = new ProcurementsSupplierClient(
        new ApiConfiguration(this.$store)
      )
        .bySearchCriteria(
          new SupplierProcurementSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          this.procurementContainer = res;
          this.loading = false;
          this.saveInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      if (saveUserConf) {
        this.$store.dispatch(actions.saveUserConf, {
          type: UserConfigurationType.SupplierProcurement,
          subTypeId: this.cargoType
        });
      }
      return promise;
    },
    undo() {
      const callback = () => {
        this.setInteractable(true);
        this.search(false, false, true);
      };
      if (!this.unsavedChanges) {
        callback();
        return;
      }
      popupDialog({
        title: t("DiscardChanges"),
        body: t("XUnsavedChanges").replace(
          "{0}",
          this.changedRows.length.toString()
        ),
        btnText1: t("Survey_Yes"),
        btnColor1: "warning",
        btnCallback1: callback,
        btnText2: t("Survey_No"),
        btnColor2: "grey lighten-3",
        btnCallback2: () => {}
      });
    },
    save() {
      const data = this.changedRows.map(
        r =>
          new SupplierProcurementPriceDto({
            agreementId: r.agreementId,
            stopPrice: r.stopPrice,
            serviceFee: r.serviceFee,
            note: r.note,
            estimatedShareOfLoads: r.share,
            prices: r.priceRows!.map(
              p =>
                new SupplierGivenPriceDto({
                  truckType: p.truckType,
                  price: p.basePrice,
                  initialFee: p.initialFee
                })
            )
          })
      );
      this.saveInProgress = true;
      new ProcurementsSupplierClient(new ApiConfiguration(this.$store))
        .savePrices(data)
        .then(() => {
          this.setInteractable(true);
          this.search(false, false, true);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.SupplierProcurement,
          subTypeId: this.cargoType,
          actorType: ActorType.Supplier
        })
        .then(() => {
          this.tableOptions.sortBy = [SupplierProcurementOrderBy.Client];
          this.tableOptions.sortDesc = [false];
          this.search(true, true, false);
        });
    },
    exportToExcel() {
      this.optionsLoading = true;
      new ProcurementsSupplierClient(new ApiConfiguration(this.$store))
        .exportToExcel(
          new SupplierProcurementSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.optionsLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    setInteractable(val: boolean) {
      this.interactable = val;
      this.tableOptions.itemsPerPage = val ? 20 : 10000;
      if (val) {
        this.procurementContainer.rows = [];
      }
    },
    importFromExcel(res: SupplierProcurementContainerDto) {
      this.procurementContainer = res;
      this.setInteractable(false);
      this.loading = false;
    },
    importPrevAll() {
      this.setInteractable(false);
      this.search(true, false, false).then(() => {
        this.importPrev(this.procurementContainer.rows!);
      });
    },
    importPrev(items: SupplierProcurementRowDto[]) {
      for (const item of items) {
        if (item.prevNote) {
          item.note = item.prevNote;
        }
        if (item.prevStopPrice) {
          item.stopPrice = item.prevStopPrice;
        }
        if (item.prevShare) {
          item.share = item.prevShare;
        }
        item.priceRows!.map(p => {
          const prevRow = item.prevPriceRows!.find(
            r => r.truckType === p.truckType
          );
          if (prevRow && prevRow.basePrice) {
            p.basePrice = prevRow.basePrice;
            p.initialFee = prevRow.initialFee;
          }
        });
      }
    },
    setStopPriceAll() {
      this.setInteractable(false);
      this.search(true, false, false).then(() => {
        this.setStopPrice(this.procurementContainer.rows!);
      });
    },
    setStopPrice(items: SupplierProcurementRowDto[]) {
      for (const item of items) {
        const stopPrice = this.procurementConfig.stopPrices.find(
          c => c.currency === item.currency
        )!.value;
        if (!stopPrice) {
          return;
        }
        item.stopPrice = stopPrice;
      }
    },
    calculateDistanceAll() {
      this.setInteractable(false);
      this.search(true, false, false).then(() => {
        this.calculateDistance(this.procurementContainer.rows!);
      });
    },
    calculateDistance(items: SupplierProcurementRowDto[]) {
      for (const item of items) {
        item.priceRows!.map(p => {
          const conf = this.procurementConfig.kmPrices.find(
            c => c.truckType === p.truckType && c.currency === item.currency
          );
          if (conf && conf.value && item.distance) {
            p.basePrice = Math.round(item.distance! * conf.value);
          }
        });
      }
    },
    calculatePercentageAll() {
      this.setInteractable(false);
      this.search(true, false, false).then(() => {
        this.calculatePercentage(this.procurementContainer.rows!);
      });
    },
    calculatePercentage(items: SupplierProcurementRowDto[]) {
      for (const item of items) {
        const r = item.priceRows!;
        const basePrice = r!.find(p => p.truckType === TruckType.TruckAndWagon)!
          .basePrice;
        const truck = r.find(p => p.truckType === TruckType.Truck);
        const truckConf = this.procurementConfig.truckPercentage;
        if (basePrice && truck !== undefined && truckConf) {
          truck.basePrice = Math.round((basePrice * truckConf) / 100);
        }
        const wagon = r.find(p => p.truckType === TruckType.Wagon);
        const wagonConf = this.procurementConfig.wagonPercentage;
        if (basePrice && wagon !== undefined && wagonConf) {
          wagon.basePrice = Math.round((basePrice * wagonConf) / 100);
        }
      }
    },
    calculateDistanceAndPercentage(items: SupplierProcurementRowDto[]) {
      this.calculateDistance(items);
      this.calculatePercentage(items);
    },
    calculateDistanceAndPercentageAll() {
      this.setInteractable(false);
      this.search(true, false, false).then(() => {
        this.calculateDistance(this.procurementContainer.rows!);
        this.calculatePercentage(this.procurementContainer.rows!);
      });
    }
  },
  data: (): {
    loading: boolean;
    saveInProgress: boolean;
    optionsLoading: boolean;
    interactable: boolean;
    validPrices: boolean;
    procurementContainer: SupplierProcurementContainerDto;
    tableOptions: TableOptions;
  } => ({
    loading: true,
    saveInProgress: false,
    optionsLoading: false,
    interactable: true,
    validPrices: false,
    procurementContainer: new SupplierProcurementContainerDto({
      count: 0,
      rows: [],
      importInfo: undefined
    }),
    tableOptions: new TableOptions({
      itemsPerPage: 20,
      sortBy: [SupplierProcurementOrderBy.Client],
      sortDesc: [false],
      groupBy: ["oviktigt"]
    })
  })
});
