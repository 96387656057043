























import Vue from "vue";

export default Vue.extend({
  props: {
    noSupplierLeftToNotifyCount: Number,
    missingAgreementCount: Number
  }
});
