





































































































































import Vue from "vue";
import { actions, gets } from "@/scripts/store/constants";
import {
  ApiConfiguration,
  ExplorerAgreementQualityPoints,
  ExplorerClient,
  QualityPointsVisualisation,
  RankingType,
  SupportAdminQualityPointsClient
} from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    CustomTableHeader,
    CustomFilter
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 140);
    },
    agreementId(): number {
      return Number(this.$route.query.agreementid);
    },
    filteredVisRows(): QualityPointsVisualisation[] {
      return this.visRows.filter(
        s =>
          !this.supplierFilter ||
          s
            .supplierName!.toLowerCase()
            .includes(this.supplierFilter.toLowerCase())
      );
    },
    title(): string {
      let res = "Man måste gå till den här sidan via utforskaren";
      if (this.agreement) {
        res = `${this.agreement.clientName}`;
        res += ` | ${this.agreement.agreementRouteName}`;
        res += ` | avt. ${this.agreement.agreementFrom.datePrint()} - ${this.agreement.agreementTo.datePrint()}`;
        res += ` | upp. ${this.agreement.procurementFrom.datePrint()} - ${this.agreement.procurementTo.datePrint()}`;
        res += ` | kva. ${this.agreement.qualityPointsFrom.datePrint()} - ${this.agreement.qualityPointsTo.datePrint()}`;
      }
      return res;
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    triforce(qp: QualityPointsVisualisation, key: string): string {
      let res = "";
      if (qp.routeQP) {
        res += (qp.routeQP as any)[key];
      }
      res += "\n";
      if (qp.clientQP) {
        res += (qp.clientQP as any)[key];
      }
      res += "\n";
      if (qp.systemQP) {
        res += (qp.systemQP as any)[key];
      }
      return res;
    },
    fetchQP(agreementId: number, clientId: number) {
      this.loading = true;
      new SupportAdminQualityPointsClient(new ApiConfiguration(this.$store))
        .visualisation(agreementId, clientId)
        .then(res => {
          this.visRows = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchAgreement(agreementId: number) {
      this.loading = true;
      new ExplorerClient(new ApiConfiguration(this.$store))
        .agreementqualitypoints(agreementId)
        .then(res => {
          this.agreement = res;
          this.fetchQP(this.agreement.agreementId, this.agreement.clientId);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    rankingTypeName(rankingType: RankingType): string {
      switch (rankingType) {
        case RankingType.Route:
          return t("Route");
        case RankingType.Client:
          return t("Client");
        case RankingType.System:
          return "C-Load";
        default:
          return t("NoLoadsFound");
      }
    },
    gotoQPWeights() {
      this.$router.push(
        `/support-admin/qp-weights?clientid=${this.agreement!.clientId}`
      );
    }
  },
  mounted() {
    if (this.agreementId) {
      this.fetchAgreement(this.agreementId);
    }
  },
  data: (): {
    loading: boolean;
    visRows: QualityPointsVisualisation[];
    agreement?: ExplorerAgreementQualityPoints;
    supplierFilter: string;
    headers: TableHeader[];
  } => ({
    loading: false,
    visRows: [],
    agreement: undefined,
    supplierFilter: "",
    headers: [
      {
        value: "truckType",
        text: "TruckType"
      },
      {
        value: "ranking",
        text: "Ranking"
      },
      {
        value: "supplierName",
        text: "Supplier"
      },
      {
        value: "rankingType",
        text: "SourceForStatistics"
      },
      {
        value: "totalPoints",
        text: "Totalp.",
        untranslated: true
      },
      {
        value: "factoredParameterPoints",
        text: "Parameterp.",
        untranslated: true
      },
      {
        value: "subjectivePoints",
        text: "Affärsp.",
        untranslated: true
      },
      {
        value: "pricePoints",
        text: "Prisp.",
        untranslated: true
      },
      {
        value: "priceForRanking",
        text: "Pris (*Komplex sak)",
        untranslated: true
      },
      {
        value: "rankingTypeNames",
        text: "",
        untranslated: true
      },
      {
        value: "points",
        text: "Parameterp.",
        untranslated: true
      },
      {
        value: "numLoads",
        text: "Lassunderlag",
        untranslated: true
      },
      {
        value: "offered",
        text: "Erbjudna",
        untranslated: true
      },
      {
        value: "rejected",
        text: "Avböjda",
        untranslated: true
      },
      {
        value: "loaded",
        text: "Lastade",
        untranslated: true
      },
      {
        value: "loadedWithinRequestedTime",
        text: "Inom begärd",
        untranslated: true
      },
      {
        value: "loadedWithStatedTime",
        text: "Med utlovad",
        untranslated: true
      },
      {
        value: "loadedWithinStatedTime",
        text: "Inom utlovad",
        untranslated: true
      },
      {
        value: "delivered",
        text: "Lossade",
        untranslated: true
      },
      {
        value: "deliveredUsingApp",
        text: "Applossade",
        untranslated: true
      }
    ]
  })
});
