





































































































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  InvoiceReportSearchCriteria,
  InvoiceReportContainerDto,
  InvoiceReportOrderBy,
  ActorType,
  CargoType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import neverHappens from "@/scripts/misc/neverHappens";
import { currencyName } from "@/scripts/misc/enumNames";
import { InvoiceReportRowDtoX } from "@/views/shared/reports/InvoiceReport.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import { gets } from "@/scripts/store/constants";
import { indexDateTooltip } from "../../loads/client/accordion/AccordionPrices.vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    TextSingle,
    CheckboxTiny
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: InvoiceReportContainerDto,
    rows: Array as () => InvoiceReportRowDtoX[]
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 360);
    },
    searchCriteria(): InvoiceReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoiceReport,
        1
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    allTreated(): boolean | undefined {
      return this.rows.every(r => !r.treated)
        ? false
        : this.rows.every(r => r.treated)
        ? true
        : undefined;
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        width: 1,
        sortValue: InvoiceReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        width: 1,
        sortValue: InvoiceReportOrderBy.DeliveryLocation
      });
      if (this.actorType === ActorType.Client) {
        headers.push({
          value: "supplierName",
          text: "Supplier",
          width: 1,
          sortValue: InvoiceReportOrderBy.Supplier
        });
      } else if (this.actorType === ActorType.Supplier) {
        headers.push({
          value: "clientName",
          text: "Client",
          width: 1,
          sortValue: InvoiceReportOrderBy.Client
        });
      }
      headers.push({
        value: "unit",
        text: "Unit",
        sortValue: InvoiceReportOrderBy.Unit
      });
      headers.push({
        value: "loadedDate",
        text: "LoadedDate",
        sortValue: InvoiceReportOrderBy.LoadedDate,
        width: 120
      });
      headers.push({
        value: "transportNo",
        text: "TransportNo",
        width: 85
      });
      headers.push({
        value: "loadId",
        text: "LoadID",
        width: 1,
        sortValue: InvoiceReportOrderBy.LoadId
      });
      headers.push({
        value: "basePrice",
        text:
          this.searchCriteria.cargoType === CargoType.Container
            ? "PricePerContainer"
            : "AgreedPrice",
        width: 1
      });
      headers.push(...this.cargoTypeColumns);
      headers.push({
        value: "bookingFee",
        text: "BookingFeeShortened",
        width: 1
      });
      headers.push({
        value: "otherPrice",
        text: "OtherPrice",
        width: 1
      });
      headers.push({
        value: "invoicePrice",
        text: "InvoicePrices",
        width: 1,
        sortValue: InvoiceReportOrderBy.InvoicePrice
      });
      headers.push({
        value: "currency",
        text: "Currency",
        sortValue: InvoiceReportOrderBy.Currency,
        width: 70
      });
      headers.push({
        value: "treated",
        text: "",
        untranslated: true,
        width: 1
      });
      headers.push({
        value: "refNo",
        text: "ReferenceNo",
        sortValue: InvoiceReportOrderBy.RefNo,
        width: 100
      });
      return headers;
    },
    cargoTypeColumns(): TableHeader[] {
      const cargoType = this.searchCriteria.cargoType!;
      switch (cargoType) {
        case CargoType.Road:
          return [
            {
              value: "stopPrice",
              text: "StopPrice"
            },
            {
              value: "indexPrice",
              text: "Index"
            }
          ];
        case CargoType.Railroad:
          return [];
        case CargoType.Container:
          return [
            {
              value: "numContainers",
              text: "NoOfContainers"
            },
            {
              value: "serviceFee",
              text: "ServiceFee"
            }
          ];
        case CargoType.MachineTransport:
          return [
            {
              value: "initialFee",
              text: "TruckTypeFee"
            },
            {
              value: "distance",
              text: "Distance"
            },
            {
              value: "stopPrice",
              text: "StopPrice"
            },
            {
              value: "vtlPrice",
              text: "VTL"
            },
            {
              value: "escortCarPrice",
              text: "EscortCar"
            }
          ];
        default:
          return neverHappens(cargoType);
      }
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    },
    rowsModified(items: InvoiceReportRowDtoX[]) {
      this.$emit("rowsModified", items);
    },
    treatAll() {
      const value = this.allTreated !== true;
      this.rows.map(r => (r.treated = value));
      this.rowsModified(this.rows);
    },
    history(item: InvoiceReportRowDtoX): string {
      return item
        .history!.map(h => `${h.date} ${h.loadUserName} ${h.description}`)
        .join("\n\n");
    },
    indexTooltip(item: InvoiceReportRowDtoX): string {
      return indexDateTooltip(
        item.indexFactor,
        item.baseIndexName!,
        item.indexDate
      );
    },
    goToIndexes(item: InvoiceReportRowDtoX) {
      window.open(
        `/indexes?from=${item.baseIndexDate!.datePrint()}&to=${item.indexDate!.datePrint()}`,
        "_blank"
      );
    }
  }
});
