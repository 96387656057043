


















































import DialogCard from "@/components/shared/ui/DialogCard.vue";
import MobileBaseValues from "@/components/mobile/details/MobileBaseValues.vue";
import MobileDates from "@/components/mobile/details/MobileDates.vue";
import MobileShippingInfo from "@/components/mobile/details/MobileShippingInfo.vue";
import MobileEventDates from "@/components/mobile/details/MobileEventDates.vue";
import MobileContainerInfo from "@/components/mobile/details/MobileContainerInfo.vue";
import MobileMachineTransportInfo from "@/components/mobile/details/MobileMachineTransportInfo.vue";
import MobileSubLocations from "@/components/mobile/details/MobileSubLocations.vue";
import {
  ApiConfiguration,
  LoadClientEditSubLocations,
  LoadDetailsBaseValuesDto,
  LoadDetailsContentDto,
  LoadDetailsDatesDto,
  LoadDetailsShippingInfoDto,
  LoadsClient,
  LoadStatus
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { loadStatusName } from "@/scripts/misc/enumNames";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import Vue from "vue";
export default Vue.extend({
  components: {
    DialogCard,
    MobileBaseValues,
    MobileDates,
    MobileEventDates,
    MobileShippingInfo,
    MobileContainerInfo,
    MobileMachineTransportInfo,
    MobileSubLocations
  },
  props: {
    loadIds: Array as () => number[],
    dialog: Boolean
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      if (this.loadStatus) {
        return `${this.$t("Details")} - ${loadStatusName(this.loadStatus)}`;
      }
      return `${this.$t("Details")}`;
    },
    ordered(): LoadStatus {
      return LoadStatus.Ordered;
    },
    loadId(): number {
      return this.loadIds[0]!;
    }
  },
  methods: {
    init() {
      this.loading = true;
      new LoadsClient(new ApiConfiguration(this.$store))
        .details(this.loadId)
        .then(res => {
          this.baseValues = res.baseValues;
          this.dates = res.dates;
          this.shippingInfo = res.shippingInfo;
          this.loadStatus = res.loadStatus;
          this.content = res.content;
          this.loading = false;
        })
        .then(() => {
          this.fetchSubLocations();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchSubLocations() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .sublocations(this.loadId)
        .then(res => {
          this.subLocations = res;
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    baseValues: LoadDetailsBaseValuesDto | undefined;
    dates: LoadDetailsDatesDto | undefined;
    shippingInfo: LoadDetailsShippingInfoDto | undefined;
    loading: boolean;
    content: LoadDetailsContentDto | undefined;
    loadStatus?: LoadStatus;
    valid: boolean;
    subLocations?: LoadClientEditSubLocations;
  } => ({
    baseValues: undefined,
    dates: undefined,
    shippingInfo: undefined,
    content: undefined,
    loading: true,
    loadStatus: undefined,
    valid: false,
    subLocations: undefined
  })
});
