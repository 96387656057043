



























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  DeviationReportOrderBy,
  ReportsClient,
  DeviationReportSearchCriteriaContainer,
  DeviationReportSearchCriteria,
  DeviationReportContainerDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import DeviationReportSearch from "@/components/web/reports/client/DeviationReportSearch.vue";
import DeviationReportTable from "@/components/web/reports/client/DeviationReportTable.vue";
import LoadDialogs from "@/components/web/loads/shared/LoadDialogs.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    DeviationReportSearch,
    DeviationReportTable,
    LoadDialogs
  },
  computed: {
    sorting(): { orderBy: DeviationReportOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as DeviationReportOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): DeviationReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DeviationReport,
        1
      );
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.DeviationReport,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [DeviationReportOrderBy.BookingDate];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.fromDate || !this.searchCriteria.toDate) {
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new ReportsClient(new ApiConfiguration(this.$store))
        .deviation(
          new DeviationReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.DeviationReport,
        subTypeId: 1
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .deviationExportToExcel(
          new DeviationReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    exportLoading: boolean;
    tableOptions: TableOptions;
    reportContainer: DeviationReportContainerDto;
  } => ({
    loading: 0,
    exportLoading: false,
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: [DeviationReportOrderBy.BookingDate],
      sortDesc: [false]
    }),
    reportContainer: new DeviationReportContainerDto({
      count: 0,
      rows: [],
      sums: []
    })
  })
});
