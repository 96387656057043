























































































































import Vue from "vue";
import {
  UserConfigurationType,
  DistanceReportSearchCriteria,
  DistanceReportContainerDto,
  DistanceReportOrderBy,
  ActorType
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { gets } from "@/scripts/store/constants";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    reportContainer: DistanceReportContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 300);
    },
    searchCriteria(): DistanceReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.DistanceReport,
        1
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "routeId",
        text: "RouteID",
        sortValue: DistanceReportOrderBy.RouteId
      });
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: DistanceReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: DistanceReportOrderBy.DeliveryLocation
      });
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: DistanceReportOrderBy.TruckType
      });
      headers.push({
        value: "numLoads",
        text: "NumberOfLoads",
        sortValue: DistanceReportOrderBy.NumLoads
      });
      headers.push({
        value: "totalDistance",
        text: "TotalDistance",
        sortValue: DistanceReportOrderBy.TotalDistance
      });
      headers.push({
        value: "avgDistance",
        text: "AverageDistance",
        sortValue: DistanceReportOrderBy.AvgDistance
      });
      headers.push({
        value: "totalEmission",
        text: "TotalEmission",
        sortValue: DistanceReportOrderBy.TotalEmission
      });
      headers.push({
        value: "avgEmission",
        text: "AverageEmission",
        sortValue: DistanceReportOrderBy.AvgEmission
      });
      if (this.actorType === ActorType.Client) {
        headers.push({
          value: "numLoadsFT",
          text: "FairTransportLoads",
          multiLine: true,
          sortValue: DistanceReportOrderBy.NumLoadsFT
        });
        headers.push({
          value: "distanceFT",
          text: "FairTransportDistance",
          multiLine: true,
          sortValue: DistanceReportOrderBy.DistanceFT
        });
      }
      return headers;
    }
  },
  methods: {
    money: money,
    truckTypeName: truckTypeName,
    search() {
      this.$emit("search");
    }
  }
});
