

































































































































import Vue from "vue";
import {
  ApiConfiguration,
  IndexClient,
  IndexComponent,
  IndexPeriodWithComponents,
  IndexType
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { TableHeader } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import nextUnique from "@/scripts/misc/nextUnique";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  components: {
    CustomTableHeader,
    SearchCard,
    NumberInput,
    DatePicker
  },
  computed: {
    saveDisabled(): boolean {
      return !this.valid || !this.periods.some(p => p.id < 0 || p.id > 10000);
    },
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 265);
    }
  },
  methods: {
    nordic(item: IndexPeriodWithComponents): IndexComponent {
      return item.components!.find(c => c.indexType === IndexType.NordicFuel)!;
    },
    cont(item: IndexPeriodWithComponents): IndexComponent {
      return item.components!.find(
        c => c.indexType === IndexType.ContinentalFuel
      )!;
    },
    euro(item: IndexPeriodWithComponents): IndexComponent {
      return item.components!.find(
        c => c.indexType === IndexType.EuroCurrency
      )!;
    },
    add() {
      const newest = this.periods[0];
      const from = newest.to.addDays(1);
      this.periods.unshift(
        new IndexPeriodWithComponents({
          id: nextUnique() + 10000000,
          from: from,
          to: from.addMonths(1).addDays(-1),
          components: [
            new IndexComponent({
              indexType: IndexType.NordicFuel,
              value: undefined as any,
              share: newest.components!.find(
                c => c.indexType === IndexType.NordicFuel
              )!.share
            }),
            new IndexComponent({
              indexType: IndexType.ContinentalFuel,
              value: undefined as any,
              share: newest.components!.find(
                c => c.indexType === IndexType.ContinentalFuel
              )!.share
            }),
            new IndexComponent({
              indexType: IndexType.EuroCurrency,
              value: undefined as any,
              share: newest.components!.find(
                c => c.indexType === IndexType.EuroCurrency
              )!.share
            })
          ]
        })
      );
    },
    fetchIndexPeriods() {
      this.periods = [];
      new IndexClient(new ApiConfiguration(this.$store))
        .indexComponents()
        .then(res => {
          this.periods = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      popupDialog({
        title: "Jag hoppas du vet vad du gör",
        body: `Du kommer ta bort ${
          this.periods.filter(p => p.id < 0).length
        } rad(er).\n\nDu har lagt till eller redigerat ${
          this.periods.filter(p => p.id > 10000).length
        } rad(er).\n\nVill du spara?`,
        btnText1: "JA",
        btnColor1: "success",
        btnCallback1: () => {
          this.actuallySave();
        },
        btnText2: "NEEEEEJ! TA MIG HÄRIFRÅN!!!!!!!!!!",
        btnColor2: "error",
        btnCallback2: () => {}
      });
    },
    actuallySave() {
      this.saveInProgress = true;
      new IndexClient(new ApiConfiguration(this.$store))
        .saveIndexComponents(this.periods)
        .then(() => {
          this.fetchIndexPeriods();
          this.saveInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchIndexPeriods();
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    periods: IndexPeriodWithComponents[];
    headers: TableHeader[];
  } => ({
    valid: false,
    saveInProgress: false,
    periods: [],
    headers: [
      {
        value: "from",
        text: "Fr.o.m",
        untranslated: true,
        width: 170
      },
      {
        value: "to",
        text: "T.o.m",
        untranslated: true,
        width: 170
      },
      {
        value: "nordic",
        text: "Nordic",
        untranslated: true
      },
      {
        value: "nordicShare",
        text: "Nordic%",
        untranslated: true
      },
      {
        value: "cont",
        text: "Continental",
        untranslated: true
      },
      {
        value: "contShare",
        text: "Continental%",
        untranslated: true
      },
      {
        value: "euro",
        text: "Euro",
        untranslated: true
      },
      {
        value: "euroShare",
        text: "Euro%",
        untranslated: true
      },
      {
        value: "actions",
        text: "",
        untranslated: true,
        width: 1
      }
    ]
  })
});
