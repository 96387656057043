














































































































































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import TimePickerDuration from "@/components/shared/input/TimePickerDuration.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import {
  ActorsClient,
  ApiConfiguration,
  WorkingHoursDto,
  HolidayDto,
  DayType,
  ClientScheduleDto,
  IHolidayDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import { dayTypes } from "@/scripts/misc/enumLists";

class HolidayDtoX extends HolidayDto {
  originalDate: string;
  hasPassed: boolean;
  constructor(dto: IHolidayDto, hasPassed: boolean) {
    super(dto);
    this.originalDate = dto.date.datePrint();
    this.hasPassed = hasPassed;
  }
}

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    CheckboxTiny,
    TimePickerDuration,
    DatePicker,
    InfoTooltip,
    ReadOnlyText
  },
  props: {
    dialog: Boolean
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.fetch();
        }
      },
      immediate: true
    }
  },
  computed: {
    actorId(): number {
      return this.$store.getters[gets.userInfo].actorId;
    },
    holidaysDuringYear(): HolidayDtoX[] {
      return [
        ...this.holidays!.filter(h => h.date.year() === this.selectedYear)
      ].sort((a, b) => a.date.unix() - b.date.unix());
    },
    valid(): boolean {
      return (
        this.dayTypeIncluded.some(v => !!v) &&
        this.workingHours.every(
          w =>
            !this.dayTypeIncluded[w.dayType - 1] ||
            w.stop.toMomentX() > w.start.toMomentX()
        ) &&
        this.holidays.every(
          h => this.isAllDay(h) || h.stop!.toMomentX() > h.start!.toMomentX()
        ) &&
        this.holidays.every(h => this.holidayRules(h.date, h))
      );
    },
    pendingChanges(): boolean {
      return (
        JSON.stringify(this.dayTypeIncluded) !== this.originalDayTypeIncluded ||
        JSON.stringify(this.workingHours) !== this.originalWorkingHours ||
        JSON.stringify(this.holidays) !== this.originalHolidays
      );
    }
  },
  methods: {
    dayTypes: dayTypes,
    holidayRules(newDate: MomentX, dto: HolidayDtoX | undefined): boolean {
      if (dto && dto.hasPassed) {
        return newDate.datePrint() === dto.originalDate;
      }
      const today = new MomentX().unix();
      if (newDate.unix() <= today) {
        //New dates have to be in the future
        return false;
      }
      if (!dto || newDate.datePrint() !== dto.date.datePrint()) {
        return (
          this.holidays.filter(h => h.date.datePrint() === newDate.datePrint())
            .length === 0
        );
      }
      return (
        this.holidays.filter(h => h.date.datePrint() === newDate.datePrint())
          .length <= 1
      );
    },
    timeOk(start: MomentX.Duration | undefined): { (date: MomentX): boolean } {
      return (date: MomentX) => !start || date > start.toMomentX();
    },
    getTime(date: MomentX): MomentX.Duration {
      return date.toDuration();
    },
    getWorkingHours(t: DayType): WorkingHoursDto {
      return this.workingHours.find(w => w.dayType === t)!;
    },
    isAllDay(holiday: HolidayDto): boolean {
      return (
        holiday.start!.hours() === 0 &&
        holiday.start!.minutes() === 0 &&
        holiday.stop!.hours() === 0 &&
        holiday.stop!.minutes() === 0
      );
    },
    toggleAllDay(holiday: HolidayDto) {
      if (this.isAllDay(holiday)) {
        holiday.start = MomentX.durationFromTime(7, 0);
        holiday.stop = MomentX.durationFromTime(13, 0);
      } else {
        holiday.start = MomentX.durationFromTime(0, 0);
        holiday.stop = MomentX.durationFromTime(0, 0);
      }
    },
    removeHoliday(index: number) {
      const holidayToRemove = this.holidaysDuringYear[index];
      this.holidays = this.holidays.filter(h => h !== holidayToRemove);
    },
    firstOkDay(year: number): MomentX | undefined {
      let firstOkDay = new MomentX(year + "-01-01");
      const todayUnix = new MomentX().unix();
      while (!this.holidayRules(firstOkDay, undefined)) {
        firstOkDay = firstOkDay.addDays(1);
        if (firstOkDay.year() > year) {
          return undefined;
        }
      }
      return firstOkDay;
    },
    addHoliday() {
      const firstOkDay = this.firstOkDay(this.selectedYear);
      if (!firstOkDay) {
        return;
      }
      this.holidays.push(
        new HolidayDtoX(
          {
            id: 0,
            date: firstOkDay,
            start: MomentX.durationFromTime(0, 0),
            stop: MomentX.durationFromTime(0, 0),
            description: undefined
          },
          false
        )
      );
    },
    save() {
      this.saveInProgress = true;
      const saveDto = new ClientScheduleDto({
        clientId: this.actorId,
        workingHours: this.workingHours.filter(
          w => this.dayTypeIncluded[w.dayType - 1]
        ),
        holidays: this.holidays.map(
          h =>
            new HolidayDto({
              ...h,
              start: this.isAllDay(h) ? undefined : h.start,
              stop: this.isAllDay(h) ? undefined : h.stop
            })
        )
      });
      new ActorsClient(new ApiConfiguration(this.$store))
        .saveClientSchedule(saveDto)
        .then(() => {
          this.saveInProgress = false;
          this.fetch();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetch() {
      this.loading = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .clientSchedule(this.actorId)
        .then(res => {
          this.workingHours = res.workingHours!;
          this.dayTypeIncluded = [true, true, true];
          dayTypes()
            .filter(d => !this.workingHours.some(w => w.dayType === d.id))
            .map(d => {
              this.dayTypeIncluded[d.id - 1] = false;
              this.workingHours.push(
                new WorkingHoursDto({
                  id: 0,
                  dayType: d.id,
                  start: MomentX.durationFromTime(7, 0),
                  stop: MomentX.durationFromTime(13, 0)
                })
              );
            });
          res.holidays!.map(h => {
            if (!h.start) {
              h.start = MomentX.durationFromTime(0, 0);
            }
            if (!h.stop) {
              h.stop = MomentX.durationFromTime(0, 0);
            }
          });
          this.holidays = res.holidays!.map(
            h =>
              new HolidayDtoX(
                h,
                h.date.unix() <= new MomentX().startOfDay().unix()
              )
          );
          this.originalWorkingHours = JSON.stringify(this.workingHours);
          this.originalDayTypeIncluded = JSON.stringify(this.dayTypeIncluded);
          this.originalHolidays = JSON.stringify(this.holidays);
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    loading: boolean;
    saveInProgress: boolean;
    dayTypeIncluded: [boolean, boolean, boolean];
    selectedYear: number;
    workingHours: WorkingHoursDto[];
    holidays: HolidayDtoX[];
    originalDayTypeIncluded: string;
    originalWorkingHours: string;
    originalHolidays: string;
  } => ({
    loading: true,
    saveInProgress: false,
    dayTypeIncluded: [true, true, true],
    selectedYear: new MomentX().year(),
    workingHours: [],
    holidays: [],
    originalDayTypeIncluded: "",
    originalWorkingHours: "",
    originalHolidays: ""
  })
});
