

















































import Vue from "vue";
import {
  ApiConfiguration,
  DocType,
  DocumentDto,
  DocumentGroupDto,
  DocumentsClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Spinner from "@/components/shared/ui/Spinner.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DocumentButton from "@/components/shared/ui/DocumentButton.vue";
import nextUnique from "@/scripts/misc/nextUnique";
import { t } from "@/scripts/language/i18n";
import { docTypeName } from "@/scripts/misc/enumNames";
import neverHappens from "@/scripts/misc/neverHappens";

type ClientNodeType = "client";
type DocNodeType = "doc";
type RouteNodeType = "route";
type NodeType = ClientNodeType | DocNodeType | RouteNodeType;

interface ClientNode {
  id: number;
  text: string;
  children: DocumentNode[];
  nodeType: ClientNodeType;
}

interface DocumentNode {
  id: number;
  text: string;
  documentType: DocType;
  documentGroup: DocumentGroupDto;
  children: RouteNode[] | undefined;
  nodeType: DocNodeType;
}

interface RouteNode {
  id: number;
  text: string;
  nodeType: RouteNodeType;
}

export default Vue.extend({
  components: {
    Spinner,
    SearchCard,
    DocumentButton
  },
  methods: {
    documents(item: DocumentNode): DocumentDto[] {
      return item.documentGroup ? item.documentGroup.documents! : [];
    },
    docName(item: DocumentNode): string {
      return docTypeName(item.documentType);
    },
    icon(nodeType: NodeType, docType: DocType): string {
      switch (nodeType) {
        case "client":
          return "mdi-domain";
        case "doc":
          return docType === DocType.TermsOfAgreement
            ? "mdi-file-sign"
            : "mdi-currency-usd";
        case "route":
          return "mdi-routes";
        default:
          return neverHappens(nodeType);
      }
    }
  },
  mounted() {
    this.loading = true;
    new DocumentsClient(new ApiConfiguration(this.$store))
      .clientDocumentsForSuppliers()
      .then(res => {
        this.clients = res.map(c => ({
          id: c.clientId,
          text: c.clientName!,
          children: c.termsOfAgreement!.map(d => ({
            id: nextUnique(),
            text: `${d.documentGroup!.description} (${d.routes!.length} ${t(
              "AgreementRoutes"
            )})`,
            documentType: d.documentGroup!.docType,
            documentGroup: d.documentGroup!,
            children: d.routes!.map(r => ({
              id: nextUnique(),
              text: `${r.pickupLocation} - ${
                r.deliveryLocation
              } (${r.from.datePrint()} - ${r.to.datePrint()})`,
              nodeType: "route"
            })),
            nodeType: "doc"
          })),
          nodeType: "client"
        }));
        this.clients.map(client => {
          client.children.unshift(
            res
              .find(r => r.clientId === client.id)!
              .invoicingInformation!.sort((a, b) => b.id - a.id)
              .map(x => ({
                id: nextUnique(),
                text: `${x.description}`,
                documentType: x.docType,
                documentGroup: x,
                children: undefined,
                nodeType: "doc" as DocNodeType
              }))[0]
          );
        });
        const clientId = Number(this.$route.query.clientId);
        if (clientId && !isNaN(clientId)) {
          this.expanded = [clientId];
          this.$router.push(this.$route.path);
        }
        this.loading = false;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    loading: boolean;
    clients: ClientNode[];
    expanded: number[];
  } => ({
    loading: true,
    clients: [],
    expanded: []
  })
});
