import { Module } from "vuex";
import { RootState } from "@/scripts/store/store";
import { gets, mutations } from "@/scripts/store/constants";
import { ConfirmationOptions, Sound } from "@/scripts/types";
import { Notification } from "@/scripts/misc/notifications";

export interface FeedbackState {
  confirmationOptions: ConfirmationOptions;
  notifications: Notification[];
  sounds: Sound[];
  preventLeaving: boolean;
  tryingToLeave: Function | undefined;
}

const state: FeedbackState = {
  confirmationOptions: {
    btnCallback1: () => {}
  },
  notifications: [],
  sounds: [],
  preventLeaving: false,
  tryingToLeave: undefined
};

export const feedbackModule: Module<FeedbackState, RootState> = {
  state: state,
  getters: {
    [gets.confirmationOptions]: (state): ConfirmationOptions => {
      return state.confirmationOptions;
    },
    [gets.notifications]: (state): Notification[] => {
      return state.notifications;
    },
    [gets.sounds]: (state): Sound[] => {
      return state.sounds;
    },
    [gets.preventLeaving]: (state): boolean => {
      return state.preventLeaving;
    },
    [gets.tryingToLeave]: (state): Function | undefined => {
      return state.tryingToLeave;
    }
  },
  mutations: {
    [mutations.setConfirmationOptions]: (state, options) => {
      state.confirmationOptions = options;
    },
    [mutations.addNotification]: (state, notification) => {
      state.notifications = [...state.notifications, notification];
    },
    [mutations.removeNotification]: (state, id) => {
      state.notifications = state.notifications.filter(n => n.id !== id);
    },
    [mutations.pushSound]: (state, sound: Sound) => {
      state.sounds = [...state.sounds, sound];
    },
    [mutations.popSound]: state => {
      state.sounds = state.sounds = state.sounds.slice(1);
    },
    [mutations.setPreventLeaving]: (state, val) => {
      state.preventLeaving = val;
    },
    [mutations.setTryingToLeave]: (state, val) => {
      state.tryingToLeave = val;
    }
  },
  actions: {}
};
