





















































































































































import Vue from "vue";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { currencyName, isoName } from "@/scripts/misc/enumNames";
import {
  AgreementListDto,
  AgreementSearchCriteriaDto,
  Country,
  Currency
} from "@/scripts/cld.api";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { Toggles } from "@/views/support-admin/AgreementList.vue";
import AgreementListEditDialog from "@/components/support-admin/agreements/AgreementListEditDialog.vue";
import { gets } from "@/scripts/store/constants";
import { ActorListDialogType } from "../actor/ActorTable.vue";

export default Vue.extend({
  props: {
    loading: Boolean,
    agreements: Array as () => AgreementListDto[],
    criteria: AgreementSearchCriteriaDto,
    toggles: Object as () => Toggles
  },
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    AgreementListEditDialog
  },
  watch: {
    tableOptions() {
      this.$emit("tableOptionsChanged");
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 255);
    },
    megaUserTooltip(): string {
      return this.$store.getters[gets.userInfo].supportAdminLevel >= 2
        ? ""
        : "Endast megaanvändare kan redigera priser";
    },
    invoicePriceTooltip(): string {
      return this.criteria.invoicePrice
        ? "Kan inte göras när fakturapris visas"
        : "";
    },
    editingAgreements(): AgreementListDto[] {
      return this.agreements.filter(
        a => a.agreementId === this.editingAgreementId
      );
    },
    augmentedAgreements(): AgreementListDto[] {
      const agreements = this.sortedAgreements;
      agreements.map(a =>
        a.prices!.map(
          p =>
            ((a as any)[`tt${p.truckType}price`] =
              p.price && !p.ranking ? "@" + p.price : p.price)
        )
      );
      agreements.map(a =>
        a.prices!.map(p => ((a as any)[`tt${p.truckType}rank`] = p.ranking))
      );
      agreements.map(a =>
        a.prices!.map(
          p => ((a as any)[`tt${p.truckType}arow`] = p.agreementRowId)
        )
      );
      return agreements;
    },
    filteredAgreements(): AgreementListDto[] {
      return this.agreements
        .filter(
          a => !this.filters.currency || a.currency === this.filters.currency
        )
        .filter(
          a =>
            this.filters.procurement === undefined ||
            this.filters.procurement === null ||
            a.procurement === this.filters.procurement
        )
        .filter(
          a =>
            !this.filters.pickupCounty ||
            a.pickupCounty === this.filters.pickupCounty
        )
        .filter(
          a =>
            !this.filters.deliveryCounty ||
            a.deliveryCounty === this.filters.deliveryCounty
        )
        .filter(
          a =>
            !this.filters.pickupZipCode ||
            stringSearchHit(this.filters.pickupZipCode, a.pickupZipCode, true)
        )
        .filter(
          a =>
            !this.filters.deliveryZipCode ||
            stringSearchHit(
              this.filters.deliveryZipCode,
              a.deliveryZipCode,
              true
            )
        )
        .filter(
          a =>
            !this.filters.pickupCountry ||
            a.pickupCountry === this.filters.pickupCountry
        )
        .filter(
          a =>
            !this.filters.deliveryCountry ||
            a.deliveryCountry === this.filters.deliveryCountry
        );
    },
    sortedAgreements(): AgreementListDto[] {
      let res = [...this.filteredAgreements];
      let c: { (a: AgreementListDto, b: AgreementListDto): number };
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      switch (sortBy) {
        case "pickup":
          c = (a, b) => stringCompare(this.pickup(a), this.pickup(b));
          break;
        case "delivery":
          c = (a, b) => stringCompare(this.delivery(a), this.delivery(b));
          break;
        default:
          c = (a, b) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      res = res.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(this.pickup(a), this.pickup(b)) ||
          stringCompare(this.delivery(a), this.delivery(b)) ||
          stringCompare(a.supplierName, b.supplierName)
      );
      return res;
    },
    headers(): TableHeader[] {
      const ttHeaders: TableHeader[][] = truckTypesByCargoType(
        this.criteria.cargoType
      ).map(tt => {
        const h = [
          {
            value: `tt${tt.id}price`,
            text: tt.text,
            untranslated: true,
            width: 1
          }
        ];
        if (this.toggles.rank) {
          h.push({
            value: `tt${tt.id}rank`,
            text: "<-Rank",
            untranslated: true,
            width: 1
          });
        }
        if (this.toggles.arow) {
          h.push({
            value: `tt${tt.id}arow`,
            text: "<-Arow",
            untranslated: true,
            width: 1
          });
        }
        return h;
      });
      const statHeaders = [
        {
          value: "numLoadsOffered",
          text: "Erbjudna",
          untranslated: true,
          sortValue: true,
          width: 1
        },
        {
          value: "numLoadsDeclined",
          text: "Avböjda",
          untranslated: true,
          sortValue: true,
          width: 1
        },
        {
          value: "numLoadsLoaded",
          text: "Bokade",
          untranslated: true,
          sortValue: true,
          width: 1
        },
        {
          value: "numLoadsTotal",
          text: "Hela avtalet",
          untranslated: true,
          sortValue: true,
          width: 1
        }
      ].filter(_ => this.criteria.includeStatistics);
      return [
        ...this.baseHeaders,
        ...ttHeaders.flatMap(x => x),
        ...statHeaders
      ];
    }
  },
  methods: {
    currencyName: currencyName,
    pickup(a: AgreementListDto): string {
      return `${a.pickupCounty}, ${a.pickupZipCode}, ${isoName(
        a.pickupCountry
      )}`;
    },
    delivery(a: AgreementListDto): string {
      return `${a.deliveryCounty}, ${a.deliveryZipCode}, ${isoName(
        a.deliveryCountry
      )}`;
    },
    openActorDialog(id: number) {
      window.open(
        `/support-admin/actors?id=${id}&dialog=${ActorListDialogType.ActorDialog}`,
        "_blank"
      );
    },
    search() {
      this.$emit("search");
    },
    edit(item: AgreementListDto) {
      this.editingAgreementId = item.agreementId;
      this.editingSupplierId = item.supplierId;
    }
  },
  data: (): {
    editingAgreementId: number | undefined;
    editingSupplierId: number | undefined;
    baseHeaders: TableHeader[];
    filters: {
      procurement: boolean | undefined;
      pickupCounty: string;
      pickupZipCode: string;
      pickupCountry: Country | undefined;
      deliveryCounty: string;
      deliveryZipCode: string;
      deliveryCountry: Country | undefined;
      currency: Currency | undefined;
    };
    tableOptions: TableOptions;
  } => ({
    editingAgreementId: undefined,
    editingSupplierId: undefined,
    baseHeaders: [
      { value: "edit", width: 1 },
      {
        value: "agreementId",
        text: "AvtalsId",
        sortValue: true,
        width: 1,
        untranslated: true
      },
      {
        value: "procurement",
        text: "Status",
        sortValue: true,
        width: 1,
        untranslated: true
      },
      { value: "pickup", text: "PickupLocation", sortValue: true, width: 1 },
      {
        value: "delivery",
        text: "DeliveryLocation",
        sortValue: true,
        width: 1
      },
      { value: "clientName", text: "Client", sortValue: true, width: 1 },
      { value: "supplierName", text: "Supplier", sortValue: true, width: 1 },
      {
        value: "agreementPeriod",
        text: "Avtalsperiod",
        sortValue: true,
        width: 1,
        untranslated: true
      },
      {
        value: "procurementPeriod",
        text: "Upph. period",
        sortValue: true,
        width: 1,
        untranslated: true
      },
      { value: "currency", text: "Currency", sortValue: true, width: 1 },
      { value: "stopPrice", text: "StopPrice", sortValue: true, width: 1 }
    ],
    filters: {
      procurement: undefined,
      pickupCounty: "",
      pickupZipCode: "",
      pickupCountry: undefined,
      deliveryCounty: "",
      deliveryZipCode: "",
      deliveryCountry: undefined,
      currency: undefined
    },
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: ["clientName"],
      sortDesc: [false]
    })
  })
});
