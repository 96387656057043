





































































































































































import Vue from "vue";
import {
  ActorType,
  ApiConfiguration,
  MobileClient,
  MobileLoadListDto,
  CargoType,
  LoadActionPermission,
  LoadStatus
} from "@/scripts/cld.api";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import MomentX from "@/scripts/misc/momentX";
import { actions, gets } from "@/scripts/store/constants";
import { LoadAction, LoadListTab } from "@/scripts/types";
import LoadDialogs from "@/components/web/loads/shared/LoadDialogs.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import { t } from "@/scripts/language/i18n";
import { truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    selectedTab: LoadListTab
  },
  components: {
    LoadDialogs,
    Spinner,
    SkeletonLoader
  },
  watch: {
    selectedTab: {
      handler() {
        this.loads = [];
        this.populateLoads();
      },
      immediate: true
    }
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    clientVisible(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    transportNoVisible(): boolean {
      return (
        this.actorType !== ActorType.Supplier ||
        this.selectedTab.id !== LoadStatus.Ordered
      );
    },
    noResultText(): string {
      if (this.selectedTab.id === LoadStatus.Ordered) {
        return t("MobileOrderedOldLoadsWillNotBeShown");
      }
      if (this.selectedTab.id === LoadStatus.Booked) {
        return t("MobileBookedOldLoadsWillNotBeShown");
      }
      if (this.selectedTab.id === LoadStatus.Loaded) {
        return t("MobileLoadedOldLoadsWillNotBeShown");
      }
      if (this.selectedTab.id === LoadStatus.Delivered) {
        return t("MobileDeliveredOldLoadsWillNotBeShown");
      } else {
        return "";
      }
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    populateLoads() {
      this.loading = true;
      new MobileClient(new ApiConfiguration(this.$store))
        .loadlist(this.selectedTab.id)
        .then(res => {
          this.loads = res;
          this.fetchLoadLocks();
          this.fetchLoadPermissions();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLoadPermissions() {
      this.$store
        .dispatch(
          actions.fetchPermissions,
          this.loads.map(x => x.loadId)
        )
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchLoadLocks() {
      const actorType: ActorType = this.$store.getters[gets.userInfo].actorType;
      if (actorType === ActorType.Client || actorType === ActorType.Supplier) {
        this.$store
          .dispatch(
            actions.fetchLocks,
            this.loads.map(x => x.loadId)
          )
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    },
    isContainerLoad(cargoType: CargoType): boolean {
      return cargoType === CargoType.Container;
    },
    presentDate(d?: MomentX): string {
      return d === undefined ? "" : d.shortDateTimePrint();
    },
    open(actionId: LoadActionPermission, loadId: Number) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: actionId.toString(),
          ids: "[" + loadId.toString() + "]"
        }
      });
    },
    resigned(loadId: number): boolean {
      return (
        this.actorType === ActorType.Supplier &&
        this.selectedTab.id === LoadStatus.Ordered &&
        this.actions(loadId).find(a => a.id === LoadActionPermission.Resign) ===
          undefined
      );
    },
    missingAgreement(load: MobileLoadListDto): boolean {
      return load.status === LoadStatus.MissingAgreement;
    },
    actions(loadId: number): LoadAction[] {
      let loadActions: LoadAction[] = this.$store.getters[gets.loadActions](
        loadId
      );
      return loadActions
        .filter((a: LoadAction) => a.allowed)
        .filter(a =>
          this.actionsVisibleInMobile.map(x => x.action).includes(a.id)
        );
    },
    color(a: LoadActionPermission): string {
      return this.actionsVisibleInMobile.find(x => x.action === a)!.color;
    }
  },
  data: (): {
    loads: MobileLoadListDto[];
    loading: boolean;
    actionsVisibleInMobile: { action: LoadActionPermission; color: string }[];
  } => ({
    loads: [],
    loading: true,
    actionsVisibleInMobile: [
      { action: LoadActionPermission.Details, color: "btn-cyan" },
      { action: LoadActionPermission.Book, color: "success" },
      { action: LoadActionPermission.SupplierEdit, color: "btn-cyan" },
      { action: LoadActionPermission.Resign, color: "error" },
      { action: LoadActionPermission.Load, color: "success" },
      { action: LoadActionPermission.Deliver, color: "success" }
    ]
  })
});
