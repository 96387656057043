



















import Vue from "vue";

export default Vue.extend({
  props: {
    reason: String
  },
  computed: {
    path(): string {
      return this.$route.path;
    }
  },
  methods: {
    routeTo(path: string) {
      this.$router.push(path);
    }
  }
});
