














































































import Vue from "vue";
import {
  ApiConfiguration,
  PowerbuttonsClient,
  UserDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions, mutations } from "@/scripts/store/constants";
import { Sound } from "@/scripts/types";
import { validEmail } from "@/scripts/misc/stringUtils";
import { alertDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  methods: {
    logging() {
      this.loading = true;
      new PowerbuttonsClient(new ApiConfiguration(this.$store))
        .logTest()
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    toll() {
      this.$store.commit(mutations.pushSound, Sound.Notification1);
    },
    checkEmails() {
      this.loading = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .byActorId(null)
        .then(res => {
          const emails = res.map(u => u.email);
          const badEmails = emails.filter(e => !validEmail(e));
          alertDialog(
            badEmails.length > 0
              ? "Dåliga mailadresser"
              : "Inga dåliga mailadresser",
            badEmails.join("\n")
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    getUsersToFix() {
      this.loading = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .byActorId(null)
        .then(res => {
          this.usersLeftToFix = res;
          this.fixUsers();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fixUsers() {
      const user = this.usersLeftToFix.pop();
      if (!user) {
        this.loading = false;
        return;
      }
      new UsersClient(new ApiConfiguration(this.$store))
        .notificationSettings(user.loadUserId)
        .then(res => {
          return new UsersClient(
            new ApiConfiguration(this.$store)
          ).saveNotificationSettings(res);
        })
        .then(() => {
          this.fixUsers();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    earthquakeMode: boolean;
    usersLeftToFix: UserDto[];
  } => ({
    loading: false,
    earthquakeMode: false,
    usersLeftToFix: []
  })
});
