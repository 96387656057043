














































import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  InvoiceReportOrderBy,
  ReportsClient,
  InvoiceReportSearchCriteriaContainer,
  InvoiceReportSearchCriteria,
  InvoiceReportContainerDto,
  InvoiceTreatedRefNoDto,
  InvoiceReportRowDto,
  IInvoiceReportRowDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import InvoiceReportSearch from "@/components/web/reports/shared/InvoiceReportSearch.vue";
import InvoiceReportTable from "@/components/web/reports/shared/InvoiceReportTable.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export class InvoiceReportRowDtoX extends InvoiceReportRowDto {
  modified: boolean;
  originalTreated: boolean;
  originalRefNo: string | undefined;

  constructor(data: IInvoiceReportRowDto) {
    super(data);
    this.modified = false;
    this.originalTreated = this.treated;
    this.originalRefNo = this.refNo;
  }
}

export default Vue.extend({
  components: {
    InvoiceReportSearch,
    InvoiceReportTable
  },
  computed: {
    sorting(): { orderBy: InvoiceReportOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as InvoiceReportOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): InvoiceReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoiceReport,
        1
      );
    },
    pendingChanges(): boolean {
      return this.rowsX.some(r => r.modified);
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.InvoiceReport,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [InvoiceReportOrderBy.PickupLocation];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    rowsModified(items: InvoiceReportRowDtoX[]) {
      items.map(item => {
        item.modified =
          item.treated !== item.originalTreated ||
          item.refNo !== item.originalRefNo;
      });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.fromDate || !this.searchCriteria.toDate) {
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new ReportsClient(new ApiConfiguration(this.$store))
        .invoice(
          new InvoiceReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.rowsX = res.rows!.map(r => new InvoiceReportRowDtoX(r));
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.InvoiceReport,
        subTypeId: 1
      });
    },
    save() {
      this.saveInProgress = true;
      const items = this.rowsX
        .filter(r => r.modified)
        .map(
          r =>
            new InvoiceTreatedRefNoDto({
              loadId: r.loadId,
              treated: r.treated,
              referenceNo: r.refNo
            })
        );
      new ReportsClient(new ApiConfiguration(this.$store))
        .invoiceTreatedStatus(items)
        .then(res => {
          this.saveInProgress = false;
          this.search();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .invoiceExportToExcel(
          new InvoiceReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    saveInProgress: boolean;
    exportLoading: boolean;
    tableOptions: TableOptions;
    rowsX: InvoiceReportRowDtoX[];
    reportContainer: InvoiceReportContainerDto;
  } => ({
    loading: 0,
    saveInProgress: false,
    exportLoading: false,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [InvoiceReportOrderBy.PickupLocation],
      sortDesc: [false]
    }),
    rowsX: [],
    reportContainer: new InvoiceReportContainerDto({
      count: 0,
      rows: [],
      priceSums: []
    })
  })
});
