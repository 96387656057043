






































































import Vue from "vue";
import {
  LoadClientEditAssortedValuesDto,
  LoadClientEditInfoTextsDto,
  LoadDetailsContentDto
} from "@/scripts/cld.api";
import TextSaved from "@/components/shared/input/TextSaved.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    assortedValues: [LoadClientEditAssortedValuesDto, LoadDetailsContentDto],
    infoTexts: [LoadClientEditInfoTextsDto, LoadDetailsContentDto],
    content: LoadDetailsContentDto,
    editable: Boolean
  },
  components: {
    TextSaved,
    NumberInput,
    ReadOnlyText
  },
  computed: {
    disabled(): boolean {
      return !this.editable && this.values.length === 0;
    },
    values(): string[] {
      if (!this.assortedValues || !this.infoTexts) {
        return [];
      }
      const res: string[] = [];
      if (this.assortedValues.volume) {
        res.push(this.assortedValues.volume + " m3");
      }
      if (this.assortedValues.weight) {
        res.push(this.assortedValues.weight + " kg");
      }
      if (this.assortedValues.packages) {
        res.push(this.assortedValues.packages + " " + t("Packages"));
      }
      if (this.infoTexts.contentInfo) {
        res.push(this.ellipsis(this.infoTexts.contentInfo, 20));
      }
      return res;
    },
    summary(): string {
      const values = this.values;
      if (values.length === 0) {
        values.push(t("NotGiven"));
      }
      return "(" + values.join(", ") + ")";
    }
  },
  methods: {
    ellipsis(text: string | undefined | null, maxLength: number): string {
      if (!text) {
        return "";
      }
      if (text.length > maxLength) {
        return text.substr(0, maxLength - 3) + "...";
      }
      return text;
    }
  }
});
