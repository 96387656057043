








import { AgreementRouteStatus } from "@/scripts/cld.api";
import Vue from "vue";

export default Vue.extend({
  props: {
    status: Number,
    disabled: Boolean,
    innerClass: String,
    radio: Boolean,
    size: Number
  },
  computed: {
    value: {
      get(): boolean {
        return this.status === AgreementRouteStatus.Hidden;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue ? 1 : 0);
      }
    },
    icon(): string {
      return this.value ? this.iconTrue : this.iconFalse;
    },
    iconTrue(): string {
      return this.radio ? "mdi-radiobox-marked" : "mdi-checkbox-marked";
    },
    iconFalse(): string {
      return this.radio ? "mdi-radiobox-blank" : "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    click() {
      this.value = !this.value;
    }
  }
});
