import * as cfg from "@/scripts/config/values.json";

interface ConfigParameter {
  default: string | null;
  localhost: string | null;
  "alpha.c-load.com": string | null;
  "beta.c-load.com": string | null;
  "test.c-load.com": string | null;
  "acceptance.c-load.com": string | null;
  "demo.c-load.com": string | null;
  "www.c-load.com": string | null;
}

interface ConfigInterface {
  auth: {
    authority: ConfigParameter;
    clientId: ConfigParameter;
    redirectUriBase: ConfigParameter;
    scope: ConfigParameter;
  };
  docsUrl: ConfigParameter;
  title: ConfigParameter;
}

export default class Config {
  private static instance: Config | undefined = undefined;
  private config: ConfigInterface | undefined = undefined;

  private static getInstance = (): Config => {
    if (Config.instance === undefined) {
      Config.instance = new Config();
    }
    return Config.instance;
  };

  private constructor() {
    this.config = <ConfigInterface>(<any>cfg.default);
  }

  private static getParameter = (cp: ConfigParameter): string => {
    const hostname = window.location.hostname;
    let value: string | null = null;
    switch (hostname) {
      case "localhost":
        value = cp["localhost"];
        break;
      case "alpha.c-load.com":
        value = cp["alpha.c-load.com"];
        break;
      case "beta.c-load.com":
        value = cp["beta.c-load.com"];
        break;
      case "test.c-load.com":
        value = cp["test.c-load.com"];
        break;
      case "acceptance.c-load.com":
        value = cp["acceptance.c-load.com"];
        break;
      case "demo.c-load.com":
        value = cp["demo.c-load.com"];
        break;
      case "www.c-load.com":
        value = cp["www.c-load.com"];
        break;
      default:
        throw new Error("Could not get parameter for " + hostname);
    }
    if (value === null) {
      value = cp["default"];
    }
    if (value === null) {
      throw new Error("All values where null for " + hostname);
    }
    return value;
  };

  static getAuthority = (): string =>
    Config.getParameter(Config.getInstance().config!.auth.authority);
  static getClientId = (): string =>
    Config.getParameter(Config.getInstance().config!.auth.clientId);
  static getRedirectUriBase = (): string =>
    Config.getParameter(Config.getInstance().config!.auth.redirectUriBase);
  static getScope = (): string =>
    Config.getParameter(Config.getInstance().config!.auth.scope);
  static getDocsUrl = (): string =>
    Config.getParameter(Config.getInstance().config!.docsUrl);
  static getTitle = (): string =>
    Config.getParameter(Config.getInstance().config!.title);
}
