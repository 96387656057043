






























































import Vue from "vue";
import {
  ApiConfiguration,
  CargoType,
  RepositoryClient,
  UserConfigurationType,
  SupplierAgreementSearchCriteria,
  RepositoryDto
} from "@/scripts/cld.api";
import { cargoTypeName } from "@/scripts/misc/enumNames";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import SwitchDouble from "@/components/shared/input/SwitchDouble.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { t } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";
import { stringCompare } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Autocomplete,
    Dropdown,
    SwitchDouble,
    InfoTooltip
  },
  props: {
    cargoType: Number as () => CargoType,
    exportDisabled: Boolean,
    exportLoading: Boolean
  },
  computed: {
    title(): string {
      return `${t("AgreementList")} - ${cargoTypeName(this.cargoType)}`;
    },
    searchCriteria(): SupplierAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierAgreement,
        this.cargoType
      );
    },
    dateTooltip(): string {
      let text = t("AgreementDateSearchExplanation1");
      if (this.isRoad) {
        text += " " + t("AgreementDateSearchExplanation3");
      }
      return text;
    },
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    },
    unitBasePriceDate: {
      get(): string {
        return this.searchCriteria.unitBasePriceDate.datePrint();
      },
      set(s: string) {
        this.searchCriteria.unitBasePriceDate = new MomentX(s);
      }
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    exportToExcel() {
      this.$emit("exportToExcel");
    },
    fetchIndexPeriods() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .baseIndexPeriods()
        .then(res => {
          this.baseIndexDates = res
            .map(
              i =>
                new RepositoryDto({
                  id: i.indexDate.datePrint() as any,
                  text: i.displayName
                })
            )
            .sort((a, b) => -stringCompare(a.id, b.id));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .clientsWithAgreements()
        .then(res => {
          this.clients = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchIndexPeriods();
    this.fetchClients();
  },
  data: (): {
    clients: RepositoryDto[];
    baseIndexDates: RepositoryDto[];
  } => ({
    clients: [],
    baseIndexDates: []
  })
});
