

































import Vue from "vue";
import Auth from "@/scripts/auth";
import Config from "@/scripts/config/config";
import ToolbarMenu from "@/components/shared/ui/ToolbarMenu.vue";
import MobileToolbarMenu from "@/components/mobile/MobileToolbarMenu.vue";
import { menu } from "@/scripts/misc/menuLayout";
import { gets } from "@/scripts/store/constants";
import { linkWithLocale } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  props: {
    username: String,
    menu: Array as () => menu[],
    unauthenticatedPage: Boolean
  },
  components: {
    ToolbarMenu,
    MobileToolbarMenu
  },
  computed: {
    actorName(): string {
      return this.$store.getters[gets.userInfo].actorName;
    },
    home(): string {
      if (Auth.browsingSupportAdmin()) {
        return "/support-admin";
      } else if (Auth.browsingMobile()) {
        return "/mobile";
      } else {
        return "/";
      }
    },
    title(): string {
      return Auth.browsingSupportAdmin() ? "C-Admin" : Config.getTitle();
    },
    mobile(): boolean {
      return Auth.browsingMobile();
    },
    toolbarColor(): string {
      return Auth.browsingSupportAdmin() ? "#555555" : "cld-red";
    }
  },
  methods: {
    signout() {
      this.$emit("signout");
    },
    routeTo(path: string) {
      path = linkWithLocale(path);
      if (path.indexOf(".pdf") > 0) {
        window.open(Config.getDocsUrl() + path);
      } else if (path.startsWith("/documentation")) {
        window.open(Config.getDocsUrl() + path);
      } else {
        this.$router.push(path);
      }
    }
  }
});
