




































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import TimePickerDialog from "@/components/shared/input/TimePickerDialog.vue";

export default Vue.extend({
  components: {
    TimePickerDialog
  },
  props: {
    value: Object as () => MomentX.Duration,
    timeOk: (Function as any) as () => { (date: MomentX): boolean } | undefined,
    disabled: Boolean
  },
  computed: {
    color(): string {
      if (this.selectedTimeOk) {
        return "btn-cyan";
      }
      return "error";
    },
    selectedTimeOk(): boolean {
      return !this.timeOk || this.timeOk(this.value.toMomentX());
    }
  },
  methods: {},
  data: (): {
    menu: boolean;
  } => ({
    menu: false
  })
});
