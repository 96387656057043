




























































import Vue from "vue";
import { LoadDetailsDatesDto, CargoType, ActorType } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    values: LoadDetailsDatesDto,
    cargoType: Number as () => CargoType,
    actorType: Number as () => ActorType
  },
  components: {
    ReadOnlyText,
    Checkbox,
    GreyTitle
  },
  computed: {
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    }
  }
});
