













































import Vue from "vue";
import { LoadClientEditContainersDto, CargoType } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    terminalName: String,
    blNo: String,
    containerValues: LoadClientEditContainersDto,
    cargoType: Number as () => CargoType
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return this.cargoType === CargoType.Container;
    }
  }
});
