













































































































import Vue from "vue";
import { LoadClientEditContainersDto } from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    dialog: Boolean,
    containerValues: Object as () => LoadClientEditContainersDto
  },
  methods: {
    close() {
      this.closeCallback();
    },
    closeCallback() {
      this.$emit("close");
    }
  }
});
