import {
  UserLevel,
  ActorType,
  CargoType,
  ContactType,
  Country,
  CountryISO,
  Culture,
  Currency,
  DeviationCause,
  DocType,
  FeeType,
  FuelIndexType,
  InvoiceReportTreatedStatus,
  LoadAppendixType,
  LoadFilterDaysBack,
  LoadFilterDaysForward,
  LoadStatus,
  MessageType,
  OtherPriceCause,
  PublishSpeed,
  RankingMethod,
  RankingType,
  RoutingExactness,
  TriggerState,
  TruckType,
  Notification,
  NotificationGroup,
  NotificationUserInterfaceGroup,
  LoadFilterDateType,
  PublishRuleType,
  DayType,
  CostForecastRankMethod,
  StandardPhraseType,
  AgreementHistoryType
} from "@/scripts/cld.api";
import { t } from "@/scripts/language/i18n";
import neverHappens from "@/scripts/misc/neverHappens";

export const actorTypeName = (x: ActorType): string => {
  switch (x) {
    case ActorType.Client:
      return t("Client");
    case ActorType.Supplier:
      return t("Supplier");
    case ActorType.Terminal:
      return t("Terminal");
    case ActorType.Customer:
      return t("Customer");
    default:
      return neverHappens(x);
  }
};

export const actorTypeIcon = (x: ActorType): string => {
  switch (x) {
    case ActorType.Client:
      return "mdi-saw-blade";
    case ActorType.Supplier:
      return "mdi-truck-fast";
    case ActorType.Terminal:
      return "mdi-lifebuoy";
    case ActorType.Customer:
      return "mdi-store";
    default:
      return neverHappens(x);
  }
};

export const cargoTypeName = (x: CargoType): string => {
  return t(cargoTypeNameTag(x));
};

export const cargoTypeNameTag = (x: CargoType): string => {
  switch (x) {
    case CargoType.Road:
      return "Road";
    case CargoType.Railroad:
      return "Railroad";
    case CargoType.Container:
      return "Container";
    case CargoType.MachineTransport:
      return "MachineTransport";
    default:
      return neverHappens(x);
  }
};

export const cargoTypeIcon = (x: CargoType): string => {
  switch (x) {
    case CargoType.Road:
      return "mdi-truck";
    case CargoType.Railroad:
      return "mdi-train";
    case CargoType.Container:
      return "mdi-ferry";
    case CargoType.MachineTransport:
      return "mdi-excavator";
    default:
      return neverHappens(x);
  }
};

export const standardPhraseTypeName = (x: StandardPhraseType): string => {
  return t(standardPhraseTypeNameTag(x));
};

export const standardPhraseTypeNameTag = (x: StandardPhraseType): string => {
  switch (x) {
    case StandardPhraseType.Note:
      return "Note";
    case StandardPhraseType.PickupInfo:
      return "PickupInformation";
    case StandardPhraseType.DestinationInfo:
      return "DestinationInformation";
    case StandardPhraseType.ContentInfo:
      return "ContentInformation";
    case StandardPhraseType.SpecialRequirement:
      return "SpecialRequirements";
    default:
      return neverHappens(x);
  }
};

export const standardPhraseTypeIcon = (x: StandardPhraseType): string => {
  switch (x) {
    case StandardPhraseType.Note:
      return "mdi-truck";
    case StandardPhraseType.PickupInfo:
      return "mdi-tray-arrow-up";
    case StandardPhraseType.DestinationInfo:
      return "mdi-tray-arrow-down";
    case StandardPhraseType.ContentInfo:
      return "mdi-ferry";
    case StandardPhraseType.SpecialRequirement:
      return "mdi-exclamation-thick";
    default:
      return neverHappens(x);
  }
};

export const truckTypeName = (x: TruckType): string => {
  switch (x) {
    case TruckType.TruckAndWagon:
      return t("TruckTypeTruckAndWagon");
    case TruckType.Truck:
      return t("TruckTypeTruck");
    case TruckType.Wagon:
      return t("TruckTypeWagon");
    case TruckType.Trailer25:
      return t("TruckTypeTrailer");
    case TruckType.Trailer30:
      return t("TruckTypeTrailer30");

    case TruckType.TwoShaft:
      return t("TruckTypeRailRoad2Shaft");
    case TruckType.FourShaft:
      return t("TruckTypeRailRoad4Shaft");
    case TruckType.FourShaftOpen:
      return t("TruckTypeRailRoad4ShaftOpen");

    case TruckType.Container20:
      return t("TruckTypeContainer20");
    case TruckType.Container40:
      return t("TruckTypeContainer40");
    case TruckType.Container40HC:
      return t("TruckTypeContainer40HC");
    case TruckType.Container45HC:
      return t("TruckTypeContainer45HC");

    case TruckType.TruckType10:
      return t("TruckType10");
    case TruckType.TruckType20:
      return t("TruckType20");
    case TruckType.TruckType30:
      return t("TruckType30");
    case TruckType.TruckType40:
      return t("TruckType40");
    case TruckType.TruckType50:
      return t("TruckType50");
    case TruckType.TruckType60:
      return t("TruckType60");
    case TruckType.TruckType70:
      return t("TruckType70");
    default:
      return neverHappens(x);
  }
};

export const loadStatusName = (x: LoadStatus): string => {
  switch (x) {
    case LoadStatus.Ordered:
      return t("Ordered");
    case LoadStatus.Booked:
      return t("Booked");
    case LoadStatus.Annulled:
      return t("Annulled");
    case LoadStatus.Loaded:
      return t("Loaded");
    case LoadStatus.MissingAgreement:
      return t("MissingAgreement");
    case LoadStatus.Delivered:
      return t("Delivered");
    default:
      return neverHappens(x);
  }
};

export const loadStatusIcon = (x: LoadStatus, spotTender?: boolean): string => {
  switch (x) {
    case LoadStatus.Ordered:
      if (spotTender) {
        return "mdi-book-search-outline";
      }
      return "mdi-plus";
    case LoadStatus.Booked:
      return "mdi-book-check-outline";
    case LoadStatus.Annulled:
      return "mdi-trash-can-outline";
    case LoadStatus.Loaded:
      return "mdi-tray-arrow-down";
    case LoadStatus.MissingAgreement:
      return "mdi-alert-octagon";
    case LoadStatus.Delivered:
      return "mdi-tray-arrow-up";
    default:
      return neverHappens(x);
  }
};

export const currencyName = (x: Currency): string => {
  switch (x) {
    case Currency.SEK:
      return "SEK";
    case Currency.EUR:
      return "EUR";
    case Currency.GBP:
      return "GBP";
    case Currency.NOK:
      return "NOK";
    case Currency.USD:
      return "USD";
    default:
      return neverHappens(x);
  }
};

export const isoName = (country: CountryISO | Country): string => {
  return CountryISO[(country as any) as CountryISO];
};

export const appendixName = (x: LoadAppendixType): string => {
  switch (x) {
    case LoadAppendixType.PackageSpec:
      return t("PackageSpecification");
    case LoadAppendixType.ConsigneeInfo:
      return t("ConsigneeInformation");
    default:
      return neverHappens(x);
  }
};

export const deviationCauseName = (x: DeviationCause): string => {
  switch (x) {
    case DeviationCause.Forced:
      return t("Forced");
    case DeviationCause.Price:
      return t("Price");
    case DeviationCause.DeliveryTime:
      return t("DeliveryTime");
    case DeviationCause.SpotTender:
      return t("SpotTender");
    default:
      return neverHappens(x);
  }
};

export const otherPriceCauseName = (x: OtherPriceCause): string => {
  switch (x) {
    case OtherPriceCause.Express:
      return t("Express");
    case OtherPriceCause.ReturnShipping:
      return t("ReturnShipping");
    case OtherPriceCause.MissingAgreement:
      return t("MissingAgreement");
    case OtherPriceCause.SpotTender:
      return t("SpotTender");
    default:
      return neverHappens(x);
  }
};

export const cultureName = (x: Culture): string => {
  switch (x) {
    case Culture.English:
      return t("English");
    case Culture.Finnish:
      return t("FinnishLanguage");
    case Culture.Norwegian:
      return t("Norwegian");
    case Culture.Swedish:
      return t("Swedish");
    default:
      return neverHappens(x);
  }
};

export const contactTypeName = (x: ContactType): string => {
  switch (x) {
    case ContactType.Email:
      return t("Email");
    case ContactType.Sms:
      return "SMS";
    default:
      return neverHappens(x);
  }
};

export const feeTypeName = (x: FeeType): string => {
  switch (x) {
    case FeeType.RoadShort:
      return t("FeeTypeRoadShort");
    case FeeType.RoadLong:
      return t("FeeTypeRoadLong");
    case FeeType.Railroad:
      return t("FeeTypeRailroad");
    case FeeType.Container:
      return t("FeeTypeContainer");
    case FeeType.MachineTransport:
      return t("FeeTypeMachineTransport");
    case FeeType.SupplierEntranceFee:
      return t("FeeTypeSupplierEntranceFee");
    case FeeType.SupplierFirstUser:
      return t("FeeTypeSupplierFirstUser");
    case FeeType.SupplierAdditionalUser:
      return t("FeeTypeSupplierAdditionalUser");
    case FeeType.ClientLight:
      return t("FeeTypeClientLight");
    case FeeType.ClientSmall:
      return t("FeeTypeClientSmall");
    case FeeType.ClientMedium:
      return t("FeeTypeClientMedium");
    case FeeType.ClientLarge:
      return t("FeeTypeClientLarge");
    case FeeType.ClientPremium:
      return t("FeeTypeClientPremium");
    case FeeType.AdditionalServices:
      return t("AdditionalServices");
    case FeeType.TravelTime:
      return t("TravelTime");
    default:
      return neverHappens(x);
  }
};

export const rankingMethodName = (x: RankingMethod): string => {
  switch (x) {
    case RankingMethod.NoAutomaticRanking:
      return t("NoAutomaticRanking");
    case RankingMethod.Price:
      return t("Price");
    case RankingMethod.QualityPoints:
      return t("QualityPoints");
    default:
      return neverHappens(x);
  }
};

export const dayTypeName = (x: DayType): string => {
  switch (x) {
    case DayType.WeekDay:
      return t("WeekDays");
    case DayType.Saturday:
      return t("Saturdays");
    case DayType.Sunday:
      return t("Sundays");
    default:
      return neverHappens(x);
  }
};

export const userLevelName = (x: UserLevel): string => {
  switch (x) {
    case UserLevel.Integration:
      return "Integration";
    case UserLevel.Viewer:
      return "Viewer";
    case UserLevel.User:
      return "User";
    case UserLevel.Admin:
      return "Admin";
    case UserLevel.SuperAdmin:
      return "SuperAdmin";
    default:
      return neverHappens(x);
  }
};

export const notificationGroupName = (x: NotificationGroup): string => {
  switch (x) {
    case NotificationGroup.Procurement:
      return t("Procurements");
    case NotificationGroup.Agreement:
      return t("Agreements");
    case NotificationGroup.Load:
      return t("LoadsWithUppercaseL");
    case NotificationGroup.Index:
      return t("Index");
    default:
      return neverHappens(x);
  }
};

export const notificationName = (x: Notification): string => {
  switch (x) {
    case Notification.ProcurementCreatedExtended:
      return t("NewAndAlteredProcurements");
    case Notification.ProcurementNewPrice:
      return t("PriceOffered");
    case Notification.ProcurementDeclinedPrice:
      return t("PriceDeclined");
    case Notification.AgreementCreated:
      return t("AgreementCreated");
    case Notification.AgreementEdited:
      return t("AgreementEdited");
    case Notification.LoadOrdered:
      return t("NewLoads");
    case Notification.LoadBooked:
      return t("BookedLoads");
    case Notification.LoadUnbooked:
      return t("CanceledLoads");
    case Notification.LoadLoaded:
      return t("LoadedLoads");
    case Notification.LoadDelivered:
      return t("DeliveredLoads");
    case Notification.LoadSpotTender:
      return t("SpotTenders");
    case Notification.LoadAnnulled:
      return t("AnnulledLoads");
    case Notification.LoadMissingAgreement:
      return t("LoadsWithoutAgreements");
    case Notification.LoadNotLoaded:
      return t("LoadNotLoaded");
    case Notification.LoadRelatedOrdered:
      return `${t("NewLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedBooked:
      return `${t("BookedLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedUnbooked:
      return `${t("CanceledLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedLoaded:
      return `${t("LoadedLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedDelivered:
      return `${t("DeliveredLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedEdit:
      return `${t("EditedLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadRelatedAnnulled:
      return `${t("AnnulledLoads")} (${t("RelatedLoads")})`;
    case Notification.LoadEditPickupEarliest:
      return t("PickupEarliest");
    case Notification.LoadEditPickupLatest:
      return t("PickupLatest");
    case Notification.LoadEditDeliverEarliest:
      return t("DeliveryEarliest");
    case Notification.LoadEditDeliverLatest:
      return t("DeliveryLatest");
    case Notification.LoadEditConsignmentNote:
      return t("ConsignmentNote");
    case Notification.LoadEditUnit:
      return t("Unit");
    case Notification.LoadEditLoadOrderNo:
      return t("LoadOrderNo");
    case Notification.LoadEditReferenceNo:
      return t("ReferenceNo");
    case Notification.LoadEditTransportNo:
      return t("TransportNo");
    case Notification.LoadEditBookingNo:
      return t("BookingNo");
    case Notification.LoadEditTruckNo:
      return t("TruckNo");
    case Notification.LoadEditBookedPickupDate:
      return t("BookedPickupDate");
    case Notification.LoadEditBookedDeliveryDate:
      return t("BookedDeliveryDate");
    case Notification.LoadEditDeliveryAddress:
      return t("DeliveryAddress");
    case Notification.LoadEditPickupAddress:
      return t("PickupAddress");
    case Notification.LoadEditDestinationInfo:
      return t("DestinationInformation");
    case Notification.LoadEditNote:
      return t("Note");
    case Notification.LoadEditPickupInfo:
      return t("PickupInformation");
    case Notification.LoadEditCustomer:
      return t("Customer");
    case Notification.LoadEditSubDeliveryLocations:
      return t("SubDeliveryLocations");
    case Notification.LoadEditSubPickupLocations:
      return t("SubPickupLocations");
    case Notification.LoadEditDocuments:
      return t("Documents");
    case Notification.LoadEditBLNo:
      return t("BLNo");
    case Notification.LoadEditTerminal:
      return t("Terminal");
    case Notification.LoadEditIncoterms:
      return t("Incoterms");
    case Notification.LoadEditContainerCount:
      return t("NumberOfContainers");
    case Notification.LoadEditKNValue:
      return t("KNValue");
    case Notification.LoadEditKNCurrency:
      return t("KNCurrency");
    case Notification.LoadEditKNProductCode:
      return t("KNProductCode");
    case Notification.LoadEditVolume:
      return t("Volume");
    case Notification.LoadEditWeight:
      return t("Weight");
    case Notification.LoadEditPackages:
      return t("Packages");
    case Notification.LoadEditContentInformation:
      return t("ContentInformation");
    case Notification.IndexUpdate:
      return t("Index");
    case Notification.LoadEditBookedPickupInfo:
      return t("BookedPickupInfo");
    case Notification.LoadEditBookedDeliveryInfo:
      return t("BookedDeliveryInfo");
    case Notification.LoadEditDistance:
      return t("Distance");
    case Notification.LoadEditOtherPrice:
      return t("OtherPrice");
    case Notification.LoadEditDeviation:
      return t("Deviations");
    case Notification.LoadEditShipName:
      return t("ShipName");
    case Notification.LoadEditETD:
      return t("ETD");
    case Notification.LoadEditETA:
      return t("ETA");
    case Notification.LoadEditIncoTerms:
      return t("Incoterms");
    default:
      return neverHappens(x);
  }
};

export const notificationUserInterfaceGroupName = (
  x: NotificationUserInterfaceGroup
): string => {
  switch (x) {
    case NotificationUserInterfaceGroup.LoadEdit:
      return t("EditedLoads");
    case NotificationUserInterfaceGroup.LoadRelated:
      return t("RelatedLoads");
    default:
      return neverHappens(x);
  }
};

export const messageTypeName = (x: MessageType): string => {
  switch (x) {
    case MessageType.General:
      return t("GeneralMessageType");
    case MessageType.Agreements:
      return t("ProcurementMessageType");
    case MessageType.Loads:
      return t("LoadMessageType");
    default:
      return neverHappens(x);
  }
};

export const treatedStatusName = (x: InvoiceReportTreatedStatus): string => {
  switch (x) {
    case InvoiceReportTreatedStatus.Treated:
      return t("ShowTreated");
    case InvoiceReportTreatedStatus.Untreated:
      return t("ShowNotTreated");
    default:
      return neverHappens(x);
  }
};

export const docTypeName = (x: DocType): string => {
  switch (x) {
    case DocType.TrionaDocument:
      return "Triona Document";
    case DocType.TermsOfAgreement:
      return t("TermsOfAgreement");
    case DocType.InvoicingInformation:
      return t("InvoicingInformation");
    case DocType.LoadDocument:
      return t("LoadDocument");
    case DocType.MessageDocument:
      return t("MessageDocument");
    case DocType.NewsDocument:
      return t("NewsDocument");
    default:
      return neverHappens(x);
  }
};

export const rankingTypeName = (x: RankingType): string => {
  switch (x) {
    case RankingType.Manual:
      return t("QualityPointsCalcDeviationTypes_0");
    case RankingType.Route:
      return t("Route");
    case RankingType.Client:
      return t("Client");
    case RankingType.System:
      return "C-Load";
    case RankingType.NoLoads:
      return t("NoLoadsFound");
    case RankingType.Price:
      return t("PriceRanking");
    default:
      return neverHappens(x);
  }
};

export const daysBackName = (x: LoadFilterDaysBack): string => {
  switch (x) {
    case LoadFilterDaysBack.Today:
      return t("Today");
    case LoadFilterDaysBack.Yesterday:
      return t("Yesterday");
    case LoadFilterDaysBack.OneWeek:
      return t("LoadListFilterWeekBack");
    case LoadFilterDaysBack.TwoWeeks:
      return t("LoadListFilterWeeksBack").replace("{0}", "2");
    case LoadFilterDaysBack.OneMonth:
      return t("LoadListFilterMonthBack");
    case LoadFilterDaysBack.TwoMonths:
      return t("LoadListFilterMonthsBack").replace("{0}", "2");
    case LoadFilterDaysBack.OneYear:
      return t("LoadListFilterYearBack");
    default:
      return neverHappens(x);
  }
};

export const daysForwardName = (x: LoadFilterDaysForward): string => {
  switch (x) {
    case LoadFilterDaysForward.Today:
      return t("Today");
    case LoadFilterDaysForward.Tomorrow:
      return t("Tomorrow");
    case LoadFilterDaysForward.OneWeek:
      return t("LoadListFilterWeekForward");
    case LoadFilterDaysForward.TwoWeeks:
      return t("LoadListFilterWeeksForward").replace("{0}", "2");
    case LoadFilterDaysForward.OneMonth:
      return t("LoadListFilterMonthForward");
    case LoadFilterDaysForward.TwoMonths:
      return t("LoadListFilterMonthsForward").replace("{0}", "2");
    case LoadFilterDaysForward.Infinite:
      return t("LoadListFilterIndefinitely");
    default:
      return neverHappens(x);
  }
};

export const filterDateTypeName = (x: LoadFilterDateType): string => {
  switch (x) {
    case LoadFilterDateType.RegDate:
      return t("Ordered");
    case LoadFilterDateType.PickupEarliest:
      return t("PickupEarliest");
    case LoadFilterDateType.BookedDate:
      return t("Booked");
    case LoadFilterDateType.LoadedDate:
      return t("Loaded");
    case LoadFilterDateType.DeliverDate:
      return t("Delivered");
    case LoadFilterDateType.PickupLatest:
      return t("PickupLatest");
    case LoadFilterDateType.DeliveryEarliest:
      return t("DeliveryEarliest");
    case LoadFilterDateType.DeliveryLatest:
      return t("DeliveryLatest");
    case LoadFilterDateType.BookedPickupDate:
      return t("BookedPickupDate");
    case LoadFilterDateType.BookedDeliveryDate:
      return t("BookedDeliveryDate");
    case LoadFilterDateType.SpotTenderEndDate:
      return t("SpotTenderEnds");
    case LoadFilterDateType.AlterDate:
      return t("LastAltered");
    default:
      return neverHappens(x);
  }
};

export const agreementHistoryTypeName = (x: AgreementHistoryType): string => {
  switch (x) {
    case AgreementHistoryType.PriceRemoved:
      return t("PriceRemoved");
    case AgreementHistoryType.FromToEstimateChanged:
      return t("EstimatedNoOfLoads");
    case AgreementHistoryType.ProcurementCreated:
      return t("ProcurementCreated");
    case AgreementHistoryType.ProcurementPeriodAdded:
      return t("AdditionalProcurementPeriod");
    case AgreementHistoryType.RankedByEndUser:
      return t("RankedByEndUser");
    case AgreementHistoryType.RankedByBackgroundService:
      return "¤RankedByBackgroundService";
    case AgreementHistoryType.AgreementCreated:
      return t("AgreementCreated");
    case AgreementHistoryType.PriceUpdated:
      return "¤PriceUpdated";
    default:
      return neverHappens(x);
  }
};

export const triggerStateName = (x: TriggerState): string => {
  switch (x) {
    case TriggerState.Normal:
      return "Normal";
    case TriggerState.Paused:
      return "Paused";
    case TriggerState.Complete:
      return "Complete";
    case TriggerState.Error:
      return "Error";
    case TriggerState.Blocked:
      return "Blocked";
    case TriggerState.None:
      return "None";
    default:
      return neverHappens(x);
  }
};

export const routingExactnessName = (x: RoutingExactness): string => {
  switch (x) {
    case RoutingExactness.Unknown:
      return t("Unknown");
    case RoutingExactness.Manual:
      return t("Manual");
    case RoutingExactness.Fail:
      return t("Unknown");
    case RoutingExactness.AgreementRoute:
      return t("AgreementRoute");
    case RoutingExactness.Country:
      return t("Country");
    case RoutingExactness.ZipcodeArea:
      return t("ZipCodeArea");
    case RoutingExactness.County:
      return t("CountyAddress");
    case RoutingExactness.Zipcode:
      return t("ZipCode");
    case RoutingExactness.Address:
      return t("Address");
    default:
      return neverHappens(x);
  }
};

export const costForecastRankMethodName = (
  x: CostForecastRankMethod
): string => {
  switch (x) {
    case CostForecastRankMethod.All:
      return t("AllGivenPrices");
    case CostForecastRankMethod.Smart:
      return t("LastAgreementAverageRank");
    default:
      return neverHappens(x);
  }
};

export const fuelIndexTypeName = (x: FuelIndexType): string => {
  switch (x) {
    case FuelIndexType.NordicFuelIndex:
      return t("DMT_SE");
    case FuelIndexType.ContinentalFuelIndex:
      return t("VLT_DMT");
    case FuelIndexType.ContinentalFuelInternalIndex:
      return t("DMT_EU");
    default:
      return neverHappens(x);
  }
};

export const publishSpeedName = (x: PublishSpeed): string => {
  switch (x) {
    case PublishSpeed.Economy:
      return t("Economy");
    case PublishSpeed.Normal:
      return t("LoadPublishSpeedNormal");
    case PublishSpeed.Broadcast:
      return t("Broadcast");
    default:
      return neverHappens(x);
  }
};

export const publishRuleTypeName = (x: PublishRuleType): string => {
  switch (x) {
    case PublishRuleType.Road:
      return t("Road");
    case PublishRuleType.Railroad:
      return t("Railroad");
    case PublishRuleType.Container:
      return t("Container");
    case PublishRuleType.MachineTransport:
      return t("MachineTransport");
    case PublishRuleType.Broadcast:
      return t("Broadcast");
    case PublishRuleType.Economy:
      return t("Economy");
    default:
      return neverHappens(x);
  }
};

/****************** Price formulas *******************/

const freightPriceFormulaPart = (x: CargoType): string => {
  switch (x) {
    case CargoType.Road:
      return `${t("AgreedPrice")} * ${t("Index")} + ${t("StopPrice")} * ${t(
        "ExtraStops"
      )}`;
    case CargoType.Railroad:
      return `${t("AgreedPrice")}`;
    case CargoType.Container:
      return `${t("AgreedPrice")} * ${t("NoOfContainers")} + ${t(
        "ServiceFee"
      )}`;
    case CargoType.MachineTransport:
      return `${t("AgreedPrice")} * ${t("Kilometer")} + ${t("EscortCar")} * ${t(
        "Kilometer"
      )} + ${t("StopPrice")} * ${t("ExtraStops")} + ${t("TruckTypeFee")} + ${t(
        "VTL"
      )}`;
    default:
      return neverHappens(x);
  }
};

const invoicePriceFormulaPart = (x: CargoType): string => {
  switch (x) {
    case CargoType.Road:
      return `${t("BookingFee")}`;
    case CargoType.Railroad:
      return `${t("BookingFee")}`;
    case CargoType.Container:
      return `${t("BookingFee")} * ${t("NoOfContainers")}`;
    case CargoType.MachineTransport:
      return `${t("BookingFee")}`;
    default:
      return neverHappens(x);
  }
};

export const freightPriceFormula = (x: CargoType): string => {
  return `${t("FreightPrice")} = ${freightPriceFormulaPart(x)}`;
};

export const freightPriceFormulaOtherPrice = (x: CargoType): string => {
  return `${t("FreightPrice")} = ${t("OtherPrice")}`;
};

export const invoicePriceFormulaShort = (x: CargoType): string => {
  return `${t("InvoicePrices")} = ${t(
    "FreightPrice"
  )} - ${invoicePriceFormulaPart(x)}`;
};

export const invoicePriceFormulaLong = (x: CargoType): string => {
  return `${t("InvoicePrices")} = ${freightPriceFormulaPart(
    x
  )} - ${invoicePriceFormulaPart(x)}`;
};

export const invoicePriceFormulaOtherPrice = (x: CargoType): string => {
  return `${t("InvoicePrices")} = ${t(
    "OtherPrice"
  )} - ${invoicePriceFormulaPart(x)}`;
};
