









































































































































































































import Vue from "vue";
import {
  ActorsClient,
  ApiConfiguration,
  MegaReportDatasetDto,
  MegaReportSearchCriteria,
  LoadStatus,
  RepositoryClient,
  RepositoryDto,
  StatisticsClient,
  MegaReportExportDto,
  MegaReportExportColumnDto
} from "@/scripts/cld.api";
import { ChartData, ChartDataset } from "chart.js";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import PieChart from "@/components/shared/charts/PieChart.vue";
import BezierChart, {
  AxisUnit,
  chartColors
} from "@/components/shared/charts/BezierChart.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import MegaReportPresetThing from "@/components/support-admin/MegaReportPresetThing.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import {
  cargoTypes,
  deviationCauses,
  otherPriceCauses,
  truckTypes
} from "@/scripts/misc/enumLists";
import {
  cargoTypeName,
  deviationCauseName,
  feeTypeName,
  loadStatusName,
  otherPriceCauseName,
  truckTypeName
} from "@/scripts/misc/enumNames";
import cached from "@/scripts/misc/cached";
import neverHappens from "@/scripts/misc/neverHappens";
import { localeCode } from "@/scripts/language/i18n";
import { ChartType, createMegaReportCriteria } from "@/scripts/types";
import { excelPrompt } from "@/scripts/misc/filePrompts";

const deviationNull = "<INGEN AVVIKELSE>";
const unitNull = "<ENHET NULL>";
const unitEmpty = "<ENHET TOM>";

export default Vue.extend({
  components: {
    SearchCard,
    BezierChart,
    DatePicker,
    Dropdown,
    DropdownMulti,
    SkeletonLoader,
    MegaReportPresetThing,
    DialogCard,
    PieChart,
    InfoTooltip
  },
  watch: {
    criteria: {
      handler() {
        this.fetchStatistics();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 290);
    },
    title(): string {
      return this.chartTypeItems.find(t => t.id === this.chartType)!.text!;
    },
    yAxisUnit(): AxisUnit {
      switch (this.chartType) {
        case ChartType.Loads:
        case ChartType.Containers:
          return AxisUnit.Number;
        case ChartType.BookingFees:
        case ChartType.FreightPrices:
        case ChartType.AvgFreightPrice:
          return AxisUnit.SEK;
        case ChartType.AvgFreightPricePerVolume:
          return AxisUnit.SEKM3;
        case ChartType.PercentDelivered:
        case ChartType.PercentBookedPickup:
        case ChartType.PercentBookedDelivery:
        case ChartType.PercentHasDistanceLoad:
        case ChartType.PercentHasDistanceRoute:
        case ChartType.PercentEconomy:
        case ChartType.PercentBroadcast:
        case ChartType.PercentHasOtherPrice:
          return AxisUnit.Percentage;
        default:
          return neverHappens(this.chartType);
      }
    },
    chartData(): ChartData<"line", number[], string> {
      let labels: string[] = [];
      let datasets: ChartDataset<"line", number[]>[] = [];
      if (this.statistics.length > 0) {
        labels = this.statistics[0].data!.map(d =>
          this.criteria.stackYears && this.criteria.monthsInGroup === 1
            ? d.date.monthNamePrint(localeCode())
            : this.criteria.stackYears && this.criteria.monthsInGroup === 3
            ? d.date.quarterPrint()
            : this.criteria.monthsInGroup === 1
            ? d.date.yearMonthNamePrint(localeCode())
            : this.criteria.monthsInGroup === 3
            ? d.date.yearQuarterPrint()
            : d.date.yearPrint()
        );
        datasets = this.statistics.map((s, index) => ({
          label: this.label(s),
          cubicInterpolationMode: "monotone",
          borderColor: chartColors(index),
          data: s.data!.map(d => {
            switch (this.chartType) {
              case ChartType.Loads:
                return d.loads;
              case ChartType.Containers:
                return d.containers;
              case ChartType.BookingFees:
                return d.bookingFees;
              case ChartType.FreightPrices:
                return d.freightPrices;
              case ChartType.AvgFreightPrice:
                return d.avgFreightPrice;
              case ChartType.AvgFreightPricePerVolume:
                return d.avgFreightPricePerVolume;
              case ChartType.PercentDelivered:
                return d.percentDelivered;
              case ChartType.PercentBookedPickup:
                return d.percentBookedPickup;
              case ChartType.PercentBookedDelivery:
                return d.percentBookedDelivery;
              case ChartType.PercentHasDistanceLoad:
                return d.percentHasDistanceLoad;
              case ChartType.PercentHasDistanceRoute:
                return d.percentHasDistanceRoute;
              case ChartType.PercentEconomy:
                return d.percentEconomy;
              case ChartType.PercentBroadcast:
                return d.percentBroadcast;
              case ChartType.PercentHasOtherPrice:
                return d.percentHasOtherPrice;
              default:
                return neverHappens(this.chartType);
            }
          })
        }));
      }
      return {
        labels: labels,
        datasets: datasets
      };
    },
    cargoTypes(): RepositoryDto[] {
      return cargoTypes();
    },
    truckTypes(): RepositoryDto[] {
      return truckTypes();
    }
  },
  methods: {
    fetchStatistics() {
      this.loading = true;
      const criteria = new MegaReportSearchCriteria(this.criteria);
      criteria.units = this.criteria.units!.map(u =>
        u === unitEmpty ? "" : u === unitNull ? (undefined as any) : u
      );
      criteria.deviationCauses = this.criteria.deviationCauses!.map(x =>
        x === (deviationNull as any) ? undefined : x
      );
      criteria.otherPriceCauses = this.criteria.otherPriceCauses!.map(x =>
        x === (deviationNull as any) ? undefined : x
      );
      new StatisticsClient(new ApiConfiguration(this.$store))
        .megaReport(criteria)
        .then(res => {
          this.statistics = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .clientsForSupportAdmin(true)
        .then(res => {
          this.clients = res.filter(c => c.id !== 49);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuppliers() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .suppliersForSupportAdmin(null)
        .then(res => {
          this.suppliers = res.filter(s => s.id !== 48);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countryItems = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCounties() {
      cached("pickupCountiesSupportAdmin")
        .then(res => {
          this.countyItems = res.map(
            c => new RepositoryDto({ id: c.text as any, text: c.text })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchUnits() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .unitsSupportAdmin(null)
        .then(res => {
          this.units = res
            .map(u => (u === "" ? unitEmpty : !u ? unitNull : u))
            .map(u => new RepositoryDto({ id: u as any, text: u }));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    label(s: MegaReportDatasetDto): string {
      let res = "";
      const clientName = s.clientName ? s.clientName : "";
      if (clientName) {
        res += clientName + " ";
      }
      const unit =
        this.criteria.units!.length === 0
          ? ""
          : s.unit === ""
          ? unitEmpty
          : !s.unit
          ? unitNull
          : s.unit;
      if (unit) {
        res += unit + " ";
      }
      const supplierName = s.supplierId
        ? this.suppliers.find(c => c.id === s.supplierId)!.text
        : "";
      if (supplierName) {
        res += supplierName + " ";
      }
      const cargoType = s.cargoType ? cargoTypeName(s.cargoType) : "";
      if (cargoType) {
        res += cargoType + " ";
      }
      const truckType = s.truckType ? truckTypeName(s.truckType) : "";
      if (truckType) {
        res += truckType + " ";
      }
      if (s.fromCounty) {
        res += s.fromCounty + " ";
      }
      const fromCountry = s.fromCountry
        ? this.countryItems.find(c => c.id === s.fromCountry)!.text
        : "";
      const toCountry = s.toCountry
        ? this.countryItems.find(c => c.id === s.toCountry)!.text
        : "";
      if (fromCountry || toCountry) {
        res += fromCountry ? fromCountry : "ALLA";
        res += " - ";
        res += toCountry ? toCountry : "ALLA";
      }
      const loadStatus = s.loadStatus ? loadStatusName(s.loadStatus) : "";
      if (loadStatus) {
        res += loadStatus + " ";
      }
      const deviationCause =
        this.criteria.deviationCauses!.length === 0
          ? ""
          : s.deviationCause
          ? deviationCauseName(s.deviationCause)
          : deviationNull;
      if (deviationCause) {
        res += deviationCause + " ";
      }
      const otherPriceCause =
        this.criteria.otherPriceCauses!.length === 0
          ? ""
          : s.otherPriceCause
          ? otherPriceCauseName(s.otherPriceCause)
          : deviationNull;
      if (otherPriceCause) {
        res += otherPriceCause + " ";
      }
      const bookingFeeName = s.bookingFeeType
        ? feeTypeName(s.bookingFeeType)
        : "";
      if (bookingFeeName) {
        res += bookingFeeName + " ";
      }
      const year = s.yearForDisplay ? s.yearForDisplay : "";
      if (year) {
        res += year + " ";
      }
      const extraStops =
        s.numExtraStops !== null ? `${s.numExtraStops} extra stopp` : "";
      if (extraStops) {
        res += extraStops + " ";
      }
      return res;
    },
    click(_: number, index: number) {
      this.piealog = true;
      this.pieChartTitle = this.title + " " + this.chartData.labels![index];
      const dataAndLabels = this.chartData.datasets
        .map(x => ({ data: x.data[index], label: x.label! }))
        .sort((a, b) => (a.data > b.data ? -1 : 1));
      this.$nextTick(() => {
        this.pieChartData = {
          labels: dataAndLabels.map(x => x.label),
          datasets: [
            {
              data: dataAndLabels.map(x => x.data)
            }
          ]
        };
      });
    },
    clearSelection() {
      this.criteria = createMegaReportCriteria();
    },
    useCriteria(criteria: MegaReportSearchCriteria, chartType: ChartType) {
      this.criteria = criteria;
      this.chartType = chartType;
    },
    exportToExcel() {
      this.exportLoading = true;
      new StatisticsClient(new ApiConfiguration(this.$store))
        .megaReportExport(
          new MegaReportExportDto({
            title: this.title,
            rowHeaders: this.chartData.labels,
            columns: this.chartData.datasets.map(
              d =>
                new MegaReportExportColumnDto({
                  heading: !d.label ? "Alla" : d.label,
                  values: d.data
                })
            )
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchClients();
    this.fetchSuppliers();
    this.fetchCountries();
    this.fetchCounties();
    this.fetchUnits();
  },
  data: (): {
    loading: boolean;
    exportLoading: boolean;
    piealog: boolean;
    pieChartData: ChartData<"pie", number[], string>;
    pieChartTitle: string;
    statistics: MegaReportDatasetDto[];
    criteria: MegaReportSearchCriteria;
    units: RepositoryDto[];
    clients: RepositoryDto[];
    suppliers: RepositoryDto[];
    monthInGroupItems: RepositoryDto[];
    countryItems: RepositoryDto[];
    countyItems: RepositoryDto[];
    extraStopsItems: RepositoryDto[];
    yesNoItems: RepositoryDto[];
    loadStatuses: RepositoryDto[];
    chartTypeItems: RepositoryDto[];
    deviationCauses: RepositoryDto[];
    otherPriceCauses: RepositoryDto[];
    chartType: ChartType;
  } => ({
    loading: false,
    exportLoading: false,
    piealog: false,
    pieChartData: {
      labels: [],
      datasets: [
        {
          data: []
        }
      ]
    },
    pieChartTitle: "",
    statistics: [],
    criteria: createMegaReportCriteria(),
    units: [],
    clients: [],
    suppliers: [],
    monthInGroupItems: [
      new RepositoryDto({ id: 1, text: "Månadsvis" }),
      new RepositoryDto({ id: 3, text: "Kvartalsvis" }),
      new RepositoryDto({ id: 12, text: "Årsvis" })
    ],
    countryItems: [],
    countyItems: [],
    extraStopsItems: [
      new RepositoryDto({ id: 0, text: "0" }),
      new RepositoryDto({ id: 1, text: "1" }),
      new RepositoryDto({ id: 2, text: "2" }),
      new RepositoryDto({ id: 3, text: "3" }),
      new RepositoryDto({ id: 4, text: "4" }),
      new RepositoryDto({ id: 5, text: "5" }),
      new RepositoryDto({ id: 6, text: "6" }),
      new RepositoryDto({ id: 7, text: "7" }),
      new RepositoryDto({ id: 8, text: "8" }),
      new RepositoryDto({ id: 9, text: "9" }),
      new RepositoryDto({ id: 10, text: "10" })
    ],
    yesNoItems: [
      new RepositoryDto({ id: true as any, text: "Ja" }),
      new RepositoryDto({ id: false as any, text: "Nej" })
    ],
    loadStatuses: [
      new RepositoryDto({
        id: LoadStatus.Loaded,
        text: "Lastade"
      }),
      new RepositoryDto({
        id: LoadStatus.Delivered,
        text: "Lossade"
      })
    ],
    deviationCauses: [
      new RepositoryDto({ id: deviationNull as any, text: deviationNull }),
      ...deviationCauses()
    ],
    otherPriceCauses: [
      new RepositoryDto({ id: deviationNull as any, text: deviationNull }),
      ...otherPriceCauses()
    ],
    chartTypeItems: [
      new RepositoryDto({ id: ChartType.Loads, text: "Antal lass" }),
      new RepositoryDto({ id: ChartType.Containers, text: "Antal containrar" }),
      new RepositoryDto({
        id: ChartType.BookingFees,
        text: "Bokningsavgifter"
      }),
      new RepositoryDto({
        id: ChartType.FreightPrices,
        text: "Fraktpris (total)"
      }),
      new RepositoryDto({
        id: ChartType.AvgFreightPrice,
        text: "Fraktpris (snitt)"
      }),
      new RepositoryDto({
        id: ChartType.AvgFreightPricePerVolume,
        text: "Fraktpris (per m3)"
      }),
      new RepositoryDto({
        id: ChartType.PercentDelivered,
        text: "Lossade (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentBookedPickup,
        text: "Utl. lastning (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentBookedDelivery,
        text: "Utl. lossning (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentHasDistanceLoad,
        text: "Med avstånd på lass (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentHasDistanceRoute,
        text: "På rutt med avstånd (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentEconomy,
        text: "Ekonomilass (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentBroadcast,
        text: "Broadcastlass (%)"
      }),
      new RepositoryDto({
        id: ChartType.PercentHasOtherPrice,
        text: "Med annat pris (%)"
      })
    ],
    chartType: ChartType.Loads
  })
});
