import {
  Question,
  QuestionGroupDto,
  SurveyAnswerRowDto
} from "@/scripts/cld.api";

export interface SurveyQuestion {
  id: number;
  question: string;
  options?: Record<number, string>;
  freetext?: boolean;
}

export class SurveyAnswerRowDtoX extends SurveyAnswerRowDto {
  valid: boolean;
  constructor(data: SurveyAnswerRowDto) {
    super(data);
    this.valid = false;
  }
}

export interface SurveyAnswerRowDtoXGroup {
  name: string;
  answers: SurveyAnswerRowDtoX[];
}

export const getAnswerGroups = (
  answerRows: SurveyAnswerRowDto[],
  questionGroups: QuestionGroupDto[]
) => {
  return questionGroups.map(g => {
    return {
      name: g.name,
      answers: g.questions!.map(q => {
        const row = new SurveyAnswerRowDtoX(
          new SurveyAnswerRowDto({
            questionId: q,
            selectedOption: undefined,
            text: undefined
          })
        );
        const matches = answerRows.filter(r => r.questionId === q);
        if (matches.length > 0) {
          row.selectedOption = matches[0].selectedOption!;
          row.text = matches[0].text;
        }
        return row;
      })
    } as SurveyAnswerRowDtoXGroup;
  });
};

export default (questionId: number): SurveyQuestion => {
  return questions.filter(q => q.id === questionId)[0];
};

const questions: SurveyQuestion[] = [
  {
    id: Question.Question1,
    question: "Survey_Q1",
    options: {
      0: "",
      1: "",
      2: "",
      3: ""
    }
  },
  {
    id: Question.Question2,
    question: "Survey_Q2",
    freetext: true
  },
  {
    id: Question.Question3,
    question: "Survey_Q3",
    options: {
      0: "0-2",
      1: "3-5",
      2: "6-10",
      3: "11-25",
      4: "26-100",
      5: "101-500",
      6: ""
    }
  },
  {
    id: Question.Question4,
    question: "Survey_Q4",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question5,
    question: "Survey_Q5",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question6,
    question: "Survey_Q6",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question7,
    question: "Survey_Q7",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question8,
    question: "Survey_Q8",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question9,
    question: "Survey_Q9",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question10,
    question: "Survey_Q10",
    options: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: ""
    }
  },
  {
    id: Question.Question11,
    question: "Survey_Q11",
    options: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: ""
    }
  },
  {
    id: Question.Question12,
    question: "Survey_Q12",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question13,
    question: "Survey_Q13",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question14,
    question: "Survey_Q14",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question15,
    question: "Survey_Q15",
    options: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: ""
    }
  },
  {
    id: Question.Question16,
    question: "Survey_Q16",
    options: {
      0: "",
      1: "",
      2: "",
      3: "",
      4: ""
    }
  },
  {
    id: Question.Question17,
    question: "Survey_Q17",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question18,
    question: "Survey_Q18",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question19,
    question: "Survey_Q19",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question20,
    question: "Survey_Q20",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question21,
    question: "Survey_Q21",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question22,
    question: "Survey_Q22",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question23,
    question: "Survey_Q23",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question24,
    question: "Survey_Q24",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  },
  {
    id: Question.Question25,
    question: "Survey_Q25",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    },
    freetext: true
  },
  {
    id: Question.Question26,
    question: "Survey_Q26",
    options: {
      0: "Survey_Yes",
      1: "Survey_No"
    }
  }
];
