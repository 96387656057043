





























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  AgreementStatisticsReportOrderBy,
  ReportsClient,
  AgreementStatisticsReportSearchCriteriaContainer,
  AgreementStatisticsReportSearchCriteria,
  AgreementStatisticsReportContainerDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import AgreementStatisticsReportSearch from "@/components/web/reports/shared/AgreementStatisticsReportSearch.vue";
import AgreementStatisticsReportTable from "@/components/web/reports/shared/AgreementStatisticsReportTable.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    AgreementStatisticsReportSearch,
    AgreementStatisticsReportTable
  },
  computed: {
    sorting(): {
      orderBy: AgreementStatisticsReportOrderBy;
      orderByDesc: boolean;
    } {
      return {
        orderBy: this.tableOptions
          .sortBy[0] as AgreementStatisticsReportOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): AgreementStatisticsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementStatisticsReport,
        1
      );
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.AgreementStatisticsReport,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [AgreementStatisticsReportOrderBy.Actor];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.fromDate || !this.searchCriteria.toDate) {
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new ReportsClient(new ApiConfiguration(this.$store))
        .agreementStatistics(
          new AgreementStatisticsReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.AgreementStatisticsReport,
        subTypeId: 1
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    },
    exportToExcel() {
      this.exportLoading = true;
      new ReportsClient(new ApiConfiguration(this.$store))
        .agreementStatisticsExportToExcel(
          new AgreementStatisticsReportSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: 0,
            pageNumber: 0,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportLoading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    loading: number;
    exportLoading: boolean;
    showPercentage: boolean;
    tableOptions: TableOptions;
    reportContainer: AgreementStatisticsReportContainerDto;
  } => ({
    loading: 0,
    exportLoading: false,
    showPercentage: false,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [AgreementStatisticsReportOrderBy.Actor],
      sortDesc: [false],
      groupBy: ["oviktigt"]
    }),
    reportContainer: new AgreementStatisticsReportContainerDto({
      count: 0,
      rows: [],
      total: undefined
    })
  })
});
