


























import Vue from "vue";
import {
  ApiConfiguration,
  PerformanceQPWeightsDto,
  QualityPointsClientClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import QualityPointsWeightsTable from "@/components/web/qp/QualityPointsWeightsTable.vue";
import { cargoTypes } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    SearchCard,
    Dropdown,
    QualityPointsWeightsTable
  },
  watch: {
    cargoType: {
      handler() {
        this.fetchPerformanceWeights();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    cargoType: {
      get(): number {
        return Number(this.$route.query.cargoType);
      },
      set(cargoType: number) {
        this.$router.push({
          path: this.$route.path,
          query: {
            cargoType: cargoType.toString()
          }
        });
      }
    }
  },
  methods: {
    cargoTypes: cargoTypes,
    fetchPerformanceWeights() {
      if (!this.cargoType) {
        return;
      }
      this.loading = true;
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .performanceWeights(this.cargoType)
        .then(res => {
          this.performanceWeights = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    performanceWeights: PerformanceQPWeightsDto[];
  } => ({
    loading: false,
    performanceWeights: []
  })
});
