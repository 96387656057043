





















import Vue from "vue";
import {
  AgreementsClient,
  ApiConfiguration,
  ExplorerNodeDto
} from "@/scripts/cld.api";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto
  },
  methods: {
    setProcurementDate() {
      this.actionInProgress = true;
      new AgreementsClient(new ApiConfiguration(this.$store))
        .setProcurementEnd(this.item.id)
        .then(() => {
          this.$emit("search");
          this.actionInProgress = false;
          popupDialog({
            noCloseButton: true,
            title: "Backa upphandlingsdatum",
            body: `Backa upphandlingsdatum gick bra!`,
            btnColor1: "success",
            btnText1: "OK",
            btnCallback1: () => {}
          });
        });
    }
  },
  data: (): {
    actionInProgress: boolean;
  } => ({
    actionInProgress: false
  })
});
