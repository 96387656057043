



















import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import ActorSigningTable from "@/components/support-admin/signing/ActorSigningTable.vue";
import {
  ActorSigningClient,
  ActorSigningDto,
  ApiConfiguration
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    ActorSigningTable
  },
  methods: {
    search() {
      this.loading = true;
      new ActorSigningClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.actors = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: boolean;
    actors: ActorSigningDto[];
  } => ({
    loading: true,
    actors: []
  })
});
