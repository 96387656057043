
















































































import Vue from "vue";
import { MultiLoad } from "@/scripts/types";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import { LoadActionPermission } from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    EllipsisTableItem,
    LoadLock
  },
  props: {
    loads: Array as () => MultiLoad[],
    selectedLoadId: Number,
    actionId: Number as () => LoadActionPermission,
    loading: Boolean
  },
  computed: {
    transportNo(): boolean {
      return this.loads.some(l => l.transportNo);
    },
    routeName(): boolean {
      return this.loads.some(l => l.routeName);
    }
  },
  methods: {
    color(load: MultiLoad) {
      if (load.saved === undefined) {
        return "btn-cyan";
      } else if (load.saved === true) {
        return "success";
      } else {
        return "error";
      }
    }
  }
});
