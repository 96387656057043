



















































































































import Vue from "vue";
import { RepositoryDto } from "@/scripts/cld.api";
import { AgreementDataDtoX } from "@/scripts/types";

export default Vue.extend({
  props: {
    suppliers: Array as () => RepositoryDto[],
    selectedAgreements: Array as () => AgreementDataDtoX[]
  },
  methods: {
    openRankingDialog() {
      this.$emit("openRankingDialog");
    },
    openNewProcurementPeriodDialog() {
      this.$emit("openNewProcurementPeriodDialog");
    },
    rankByQP() {
      this.$emit("rankByQP");
    },
    rankByPrice() {
      this.$emit("rankByPrice");
    },
    createAgreement() {
      this.$emit("createAgreement");
    },
    removeSupplier(supplier: RepositoryDto) {
      this.$emit("removeSupplier", supplier);
    }
  }
});
