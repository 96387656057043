





























import Vue from "vue";
import {
  ApiConfiguration,
  DocumentsClient,
  LoadActionPermission,
  LoadDocumentDto,
  LoadsSupplierClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadDocumentsTable from "@/components/web/loads/shared/LoadDocumentsTable.vue";

export default Vue.extend({
  components: {
    DialogCard,
    LoadLock,
    LoadDocumentsTable
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.documents = undefined;
          this.fetchDocuments();
        }
      },
      immediate: true
    }
  },
  computed: {
    loadId(): number {
      return this.loadIds[0];
    },
    loading(): boolean {
      return this.documents === undefined;
    },
    pendingChanges(): boolean {
      if (!this.documents) {
        return false;
      }
      const documentIds = this.documents.map(d => d.id);
      if (this.originalDocumentIds.length !== documentIds.length) {
        return true;
      }
      return this.originalDocumentIds.some(id => !documentIds.includes(id));
    }
  },
  methods: {
    save() {
      this.saveInProgress = true;
      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .saveDocuments(
          this.loadId,
          this.documents!.map(d => d.id)
        )
        .then(() => {
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocuments() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .loadDocuments(this.loadId)
        .then(res => {
          this.documents = res;
          this.originalDocumentIds = res.map(d => d.id);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    saveInProgress: boolean;
    documents: LoadDocumentDto[] | undefined;
    originalDocumentIds: number[];
  } => ({
    saveInProgress: false,
    documents: undefined,
    originalDocumentIds: []
  })
});
