var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.procurementContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.procurementContainer.count,"options":_vm.options,"custom-group":function (items) { return items.map(function (i) { return ({ name: i.agreementId, items: [i] }); }); },"item-class":function (i) { return (_vm.hasChanges(i) ? 'blue-row no-hover' : ''); },"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [20],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"header.truckTypes",fn:function(){return [_c('div',{staticClass:"flex-container"},_vm._l((_vm.truckTypes),function(tt){return _c('div',{key:tt.id,staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(tt.text)+" ")])}),0)]},proxy:true}],null,true)})]}},{key:"group.header",fn:function(ref){
    var items = ref.items;
return [_c('td',{staticClass:"font-weight-bold",class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"1"}},[(_vm.editable)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"height":"23","width":"23","icon":""}},on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',{staticClass:"no-padding",attrs:{"dense":""}},_vm._l((_vm.menuOptions(items[0])),function(option,index){return _c('v-tooltip',{key:index,attrs:{"top":"","disabled":!option.tooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-list-item',{attrs:{"disabled":!option.enabled},on:{"click":function($event){return _vm.optionClick(option.id, items[0])}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"size":"20","disabled":!option.enabled}},[_vm._v(" "+_vm._s(option.icon)+" ")])],1),_c('v-list-item-title',[_vm._v(_vm._s(option.text))])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(option.tooltip)+" ")])}),1)],1):_vm._e()],1),_c('td',{staticClass:"font-weight-bold",class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"1"}},[_c('EllipsisTableItem',{attrs:{"text":items[0].clientName,"width":"155"}})],1),_c('td',{class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":_vm.routeNameColspan}},[_c('span',{staticClass:"dont-render-newlines font-weight-bold"},[_c('span',{staticClass:"small-padding-right"},[_vm._v(" "+_vm._s(items[0].pickupLocation)+" - "+_vm._s(items[0].deliveryLocation)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tiny-padding-bottom clickable",attrs:{"color":"cyan","size":"16"},on:{"click":function($event){return _vm.viewDocument(items[0].documentGroup)}}},on),[_vm._v(" mdi-file-document ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("TermsOfAgreement"))+" ")]),(!!items[0].specialRequirements)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tiny-padding-bottom unselectable",attrs:{"color":"red","size":"16"}},on),[_vm._v(" mdi-exclamation-thick ")])]}}],null,true)},[_vm._v(" "+_vm._s(items[0].specialRequirements)+" ")]):_vm._e(),_c('span',{on:{"click":function($event){return _vm.showTooltipDialog(items[0].messageToSuppliers)}}},[(!!items[0].messageToSuppliers)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tiny-padding-bottom unselectable clickable",attrs:{"color":"warning","size":"16"}},on),[_vm._v(" mdi-message-text ")])]}}],null,true)},[_vm._v(" "+_vm._s(((_vm.$t("MessageToSuppliers")) + ":\n\n" + (_vm.shorten( items[0].messageToSuppliers ))))+" ")]):_vm._e()],1)],1),_c('span',{staticClass:"dont-render-newlines tiny-padding-left"},[_c('ProcurementDateTooltip',{attrs:{"item":items[0]}})],1)]),_c('td',{staticClass:"font-weight-bold",class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.currencyName(items[0].currency))+" ")]),(_vm.isRoad || _vm.isMachineTransport)?_c('td',{class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(items[0].distance)+" ")]):_vm._e(),_c('td',{class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(items[0].estimate)+" ")]),_c('td',{class:{ 'blue-row no-hover': _vm.hasChanges(items[0]) },attrs:{"colspan":"99"}})]}},{key:"item.clientName",fn:function(){return [_c('span',{staticClass:"unselectable blackish-text caption font-weight-medium"},[_c('div',{staticClass:"dont-render-newlines small-margin-bottom",class:{
            'single-price-input': !_vm.isRoad && !_vm.isMachineTransport
          }},[_vm._v(" "+_vm._s(_vm.basePriceName)+" ")]),_c('div',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.isRoad ? _vm.$t("FreightPrice") : _vm.isMachineTransport ? _vm.$t("TruckTypeFee") : "")+" ")])])]},proxy:true},{key:"item.truckTypes",fn:function(ref){
          var item = ref.item;
return [_c('ProcurementTablePriceInputs',{attrs:{"item":item,"cargoType":_vm.cargoType,"editable":_vm.editable}})]}},{key:"item.stopPrice",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('div',_vm._g({staticClass:"price-input single-price-input"},on),[(!_vm.editable)?_c('div',{staticClass:"readonly-div readonly-div-single"},[_vm._v(" "+_vm._s(item.stopPrice)+" ")]):_c('NumberInput',{attrs:{"rules":[function (v) { return v === null || v > 0; }],"compact":"","allow-null":"","only-integers":"","tabindex":"1"},model:{value:(item.stopPrice),callback:function ($$v) {_vm.$set(item, "stopPrice", $$v)},expression:"item.stopPrice"}})],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("StopPriceDesc"))+" ")])]}},{key:"item.serviceFee",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"price-input single-price-input"},[(!_vm.editable)?_c('div',{staticClass:"readonly-div readonly-div-single"},[_vm._v(" "+_vm._s(item.serviceFee)+" ")]):_c('NumberInput',{attrs:{"rules":[function (v) { return v === null || v > 0; }],"compact":"","allow-null":"","only-integers":"","tabindex":"1"},model:{value:(item.serviceFee),callback:function ($$v) {_vm.$set(item, "serviceFee", $$v)},expression:"item.serviceFee"}})],1)]}},{key:"item.currency",fn:function(){return undefined},proxy:true},{key:"item.distance",fn:function(){return undefined},proxy:true},{key:"item.estimate",fn:function(){return undefined},proxy:true},{key:"item.share",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"price-input single-price-input share-input"},[(!_vm.editable)?_c('div',{staticClass:"readonly-div readonly-div-single"},[_vm._v(" "+_vm._s(item.share)+"% ")]):_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0 && v <= 100; }],"compact":"","allow-null":"","only-integers":"","tabindex":"1"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"tiniest-padding-top tiniest-margin-top tiny-padding-right font-weight-bold body-2"},[_vm._v(" % ")])]},proxy:true}],null,true),model:{value:(item.share),callback:function ($$v) {_vm.$set(item, "share", $$v)},expression:"item.share"}})],1)]}},{key:"item.note",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"tiniest-padding-top"},[(!_vm.editable)?_c('div',{staticClass:"readonly-div readonly-div-double"},[_vm._v(" "+_vm._s(item.note)+" ")]):_c('TextMulti',{attrs:{"rows":2,"compact":"","tabindex":"1"},model:{value:(item.note),callback:function ($$v) {_vm.$set(item, "note", $$v)},expression:"item.note"}})],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }