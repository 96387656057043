var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.reportContainer.count,"options":_vm.options,"item-class":function (i) { return i.modified && 'blue-row'; },"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location'
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.unit",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Unit')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.unit),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "unit", $$v)},expression:"searchCriteria.unit"}})]},proxy:true},{key:"filter.supplierName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'supplier',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Supplier')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.actorId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "actorId", $$v)},expression:"searchCriteria.actorId"}})]},proxy:true},{key:"filter.clientName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'client',
              cargoType: _vm.searchCriteria.cargoType
            },"placeholder":_vm.$t('Client')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.actorId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "actorId", $$v)},expression:"searchCriteria.actorId"}})]},proxy:true},{key:"filter.treated",fn:function(){return [_c('div',{staticClass:"large-padding-top"},[_c('CheckboxTiny',{attrs:{"value":_vm.allTreated},on:{"input":_vm.treatAll}})],1)]},proxy:true},{key:"filter.transportNo",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('TransportNo')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.transportNo),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "transportNo", $$v)},expression:"searchCriteria.transportNo"}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.currency),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "currency", $$v)},expression:"searchCriteria.currency"}})]},proxy:true}],null,true)})]}},{key:"item.pickupLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.pickupLocation,"width":"150"}})]}},{key:"item.deliveryLocation",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.deliveryLocation,"width":"160"}})]}},{key:"item.unit",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.unit,"width":"80"}})]}},{key:"item.clientName",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.clientName,"width":"120"}})]}},{key:"item.supplierName",fn:function(ref){
            var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.supplierName,"width":"120"}})]}},{key:"item.loadedDate",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.loadedDate))])]}},{key:"item.basePrice",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.basePrice, 0)))])]}},{key:"item.numExtraStops",fn:function(ref){
            var item = ref.item;
return [(item.numExtraStops)?_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.numExtraStops))]):_vm._e()]}},{key:"item.stopPrice",fn:function(ref){
            var item = ref.item;
return [(item.stopPrice)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines"},on),[_vm._v(" "+_vm._s(_vm.money(item.stopPrice, 0))+" ("+_vm._s(item.numExtraStops)+") ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t("TotalStopPrice"))+" ("+_vm._s(_vm.$t("ExtraStops"))+") ")]):_vm._e()]}},{key:"item.indexPrice",fn:function(ref){
            var item = ref.item;
return [(item.indexPrice)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({staticClass:"dont-render-newlines",on:{"click":function($event){return _vm.goToIndexes(item)}}},on),[_vm._v(_vm._s(_vm.money(item.indexPrice, 0)))])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.indexTooltip(item))+" ")]):_vm._e()]}},{key:"item.serviceFee",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.serviceFee, 0)))])]}},{key:"item.initialFee",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.initialFee, 0)))])]}},{key:"item.distance",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.distance, 0)))])]}},{key:"item.bookingFee",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.bookingFee, 2)))])]}},{key:"item.otherPrice",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.otherPrice, 2)))])]}},{key:"item.invoicePrice",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.money(item.invoicePrice, 2)))])]}},{key:"item.currency",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}},{key:"item.treated",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_c('CheckboxTiny',{on:{"input":function($event){return _vm.rowsModified([item])}},model:{value:(item.treated),callback:function ($$v) {_vm.$set(item, "treated", $$v)},expression:"item.treated"}}),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":!item.history.length,"size":"22","color":"grey darken-3"}},on),[_vm._v(" mdi-history ")])]}}],null,true)},[_vm._v(" "+_vm._s(_vm.history(item))+" ")])],1)]}},{key:"item.refNo",fn:function(ref){
            var item = ref.item;
return [_c('TextSingle',{attrs:{"allow-null":"","compact":"","only-on-blur":""},on:{"input":function($event){return _vm.rowsModified([item])}},model:{value:(item.refNo),callback:function ($$v) {_vm.$set(item, "refNo", $$v)},expression:"item.refNo"}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }