



















































import Vue from "vue";
import { UserDto } from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import { userLevelName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  props: {
    users: Array as () => UserDto[],
    selectedLoadUserId: Number
  },
  methods: {
    userLevelName: userLevelName
  },
  computed: {
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "username",
          text: "Username"
        },
        {
          value: "name",
          text: "Name"
        },
        {
          value: "userLevel",
          text: "Nivå",
          untranslated: true
        },
        {
          value: "icons"
        }
      ];
      return headers;
    }
  }
});
