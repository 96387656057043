
















































import Vue from "vue";
import { LoadClientEditSupplierBookingInfoDto } from "@/scripts/cld.api";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";

export default Vue.extend({
  props: {
    supplierBookingInfo: LoadClientEditSupplierBookingInfoDto
  },
  components: {
    TextMulti,
    DatePicker
  }
});
