import MomentX from "@/scripts/misc/momentX";
import { t } from "../language/i18n";

export const pickupDeliveryRules = (
  pickupEarliest: MomentX,
  pickupLatest: MomentX | undefined,
  deliverEarliest: MomentX | undefined
): PickupDeliveryRules => {
  return {
    pickupLatest: [geq(pickupEarliest, "PickupEarliest", "PickupLatest")],
    deliverEarliest: [
      geq(pickupEarliest, "PickupEarliest", "DeliveryEarliest")
    ],
    deliverLatest: [
      geq(pickupEarliest, "PickupEarliest", "DeliveryLatest"),
      geq(pickupLatest, "PickupLatest", "DeliveryLatest"),
      geq(deliverEarliest, "DeliveryEarliest", "DeliveryLatest")
    ]
  };
};

export interface PickupDeliveryRules {
  pickupLatest: { (date?: MomentX): boolean | string }[];
  deliverEarliest: { (date?: MomentX): boolean | string }[];
  deliverLatest: { (date?: MomentX): boolean | string }[];
}

export const geq = (
  d1: MomentX | undefined,
  s1: string,
  s2: string
): { (date?: MomentX): boolean | string } => {
  return (d2?: MomentX) =>
    !d1 ||
    !d2 ||
    !d1.isValid() ||
    !d2.isValid() ||
    d2.isSameOrAfter(d1) ||
    t("RangeValidationGreaterOrEqual")
      .toString()
      .replace("{0}", t(s2).toString())
      .replace(
        "{1}",
        t(s1)
          .toString()
          .toLowerCase()
      );
};

export const leq = (
  d1: MomentX | undefined,
  s1: string,
  s2: string
): { (date?: MomentX): boolean | string } => {
  return (d2?: MomentX) =>
    !d1 ||
    !d2 ||
    !d1.isValid() ||
    !d2.isValid() ||
    d2.isSameOrBefore(d1) ||
    t("RangeValidationLessOrEqual")
      .toString()
      .replace("{0}", t(s2).toString())
      .replace(
        "{1}",
        t(s1)
          .toString()
          .toLowerCase()
      );
};
