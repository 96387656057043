var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogCard',{attrs:{"dialog":_vm.agreementId !== undefined,"title":_vm.title,"width":900},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"head-right",fn:function(){return [_c('v-icon',{attrs:{"color":"error","large":""}},[_vm._v(" mdi-skull ")])]},proxy:true},{key:"body",fn:function(){return [(_vm.agreementId)?_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('Autocomplete',{attrs:{"value":_vm.supplierId,"label":_vm.$t('Supplier'),"items":_vm.suppliers},on:{"input":function (id) { return _vm.$emit('changeSupplierId', id); }}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('ReadOnlyText',{attrs:{"value":_vm.clientName,"label":_vm.$t('Client')}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('ReadOnlyText',{attrs:{"value":_vm.currencyName,"label":_vm.$t('Currency')}})],1)],1),_vm._l((_vm.updateModels),function(m,index){return _c('v-row',{key:index},[_c('v-col',[_c('NumberInput',{attrs:{"label":((_vm.truckTypeName(m.truckType)) + " [Tidigare pris: " + (m.prevPrice) + "]"),"rules":[
              function (v) { return v === undefined ||
                v === null ||
                (v > 0 && v < 200000 && v % 1 === 0) ||
                '0 < Värde < 200000'; }
            ],"disabled":!m.checked,"allow-null":""},model:{value:(m.price),callback:function ($$v) {_vm.$set(m, "price", $$v)},expression:"m.price"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('Checkbox',{attrs:{"label":"Ändra"},model:{value:(m.checked),callback:function ($$v) {_vm.$set(m, "checked", $$v)},expression:"m.checked"}})],1)],1)}),_c('v-row',[(_vm.stopPriceEnabled)?_c('v-col',{attrs:{"cols":"5"}},[_c('NumberInput',{attrs:{"label":_vm.$t('StopPrice'),"rules":[
              function (v) { return v === undefined ||
                v === null ||
                (v > 0 && v < 200000 && v % 1 === 0) ||
                '0 < Värde < 200000'; }
            ],"allow-null":""},model:{value:(_vm.stopPrice),callback:function ($$v) {_vm.stopPrice=$$v},expression:"stopPrice"}})],1):_vm._e(),(_vm.serviceFeeEnabled)?_c('v-col',{attrs:{"cols":"5"}},[_c('NumberInput',{attrs:{"label":_vm.$t('ServiceFee'),"rules":[
              function (v) { return v === undefined ||
                v === null ||
                (v > 0 && v < 200000 && v % 1 === 0) ||
                '0 < Värde < 200000'; }
            ],"allow-null":""},model:{value:(_vm.serviceFee),callback:function ($$v) {_vm.serviceFee=$$v},expression:"serviceFee"}})],1):_vm._e(),(_vm.initialFeeEnabled)?_c('v-col',{attrs:{"cols":"5"}},[_c('NumberInput',{attrs:{"label":_vm.$t('TruckTypeFee'),"rules":[
              function (v) { return v === undefined ||
                v === null ||
                (v > 0 && v < 200000 && v % 1 === 0) ||
                '0 < Värde < 200000'; }
            ],"allow-null":""},model:{value:(_vm.initialFee),callback:function ($$v) {_vm.initialFee=$$v},expression:"initialFee"}})],1):_vm._e()],1)],2):_vm._e()]},proxy:true},{key:"actions",fn:function(){return [_c('v-spacer'),_c('v-btn',{staticClass:"warning",attrs:{"disabled":!_vm.valid || !_vm.updateModels.some(function (m) { return m.checked; }),"loading":_vm.saveInProgress},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-skull")]),_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }