




































































































import Vue from "vue";
import {
  ActorType,
  LoadDetailsShippingInfoDto,
  LoadStatus
} from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import ActorDialog from "@/components/web/actor/ActorDialog.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    ReadOnlyText,
    GreyTitle,
    InfoTooltip,
    ActorDialog
  },
  props: {
    values: LoadDetailsShippingInfoDto,
    loadId: Number,
    loadStatus: Number as () => LoadStatus
  },
  computed: {
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    transportNoVisible(): boolean {
      return (
        this.actorType !== ActorType.Supplier ||
        this.loadStatus !== LoadStatus.Ordered
      );
    }
  },
  methods: {
    viewClient(clientId: number) {
      this.actorIdToView = clientId;
      this.actorTypeToView = ActorType.Client;
    },
    viewSupplier(supplierId: number | undefined) {
      if (!supplierId) {
        return;
      }
      this.actorIdToView = supplierId;
      this.actorTypeToView = ActorType.Supplier;
    },
    viewCustomer(customerId: number | undefined) {
      if (!customerId) {
        return;
      }
      this.actorIdToView = customerId;
      this.actorTypeToView = ActorType.Customer;
    }
  },
  data: (): {
    actorIdToView: number | undefined;
    actorTypeToView: ActorType | undefined;
  } => ({
    actorIdToView: undefined,
    actorTypeToView: undefined
  })
});
