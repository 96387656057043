



































import Vue from "vue";

export default Vue.extend({
  props: {
    value: String,
    label: String,
    items: Array as () => string[],
    rules: Array,
    tabindex: String,
    disabled: Boolean
  },
  computed: {
    existingItem(): boolean {
      if (!this.searchInput) {
        return true;
      }
      return this.items.includes(this.searchInput);
    }
  },
  methods: {
    input(val: any) {
      this.$emit("input", val);
    },
    addItem() {
      this.$emit("addItem", this.searchInput!.toLocaleUpperCase());
      this.input(this.searchInput!.toLocaleUpperCase());
    }
  },
  data: (): { searchInput: string | null } => ({ searchInput: null })
});
