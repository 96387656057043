





































































import Vue from "vue";

export default Vue.extend({
  props: {
    value: Boolean,
    label: String,
    tabindex: String,
    disabled: Boolean,
    readonly: Boolean,
    tooltipText: String,
    toolTip: Boolean,
    smallFont: Boolean
  }
});
