















































































































































import Vue from "vue";
import { LoadingSlotType } from "@/scripts/cld.api";
import { localeCode } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";
import {
  CargoLoadingMobileEventsContainer,
  LoadingDockCategory
} from "@/scripts/types";

export default Vue.extend({
  props: {
    categories: Array as () => LoadingDockCategory[],
    events: Array as () => CargoLoadingMobileEventsContainer[],
    scheduleInProgress: Boolean,
    date: MomentX,
    pickupEarliest: MomentX,
    selectedCategory: Object as () => LoadingDockCategory | undefined
  },
  watch: {
    date: {
      handler() {
        this.focus = this.toDateString(this.date)!;
      },
      immediate: true
    },
    events: {
      handler() {
        if (this.events.length > 0) {
          this.scrollToTime();
        }
      }
    }
  },
  computed: {
    locale(): string {
      return localeCode();
    },
    prevDisabled(): boolean {
      let today = new MomentX().startOfDay();
      return (
        this.date
          .addDays(-1)
          .startOfDay()
          .isBefore(this.pickupEarliest.startOfDay()) ||
        this.date
          .addDays(-1)
          .startOfDay()
          .isBefore(today.startOfDay())
      );
    },
    nowY() {
      let cal = this.$refs.calendar as any;
      return cal ? cal.timeToY(cal.times.now) + "px" : "-10px";
    },
    isToday() {
      return this.date.startOfDay().isSame(new MomentX().startOfDay());
    },
    categoryArray(): String[] {
      let categoriesStrings = this.categories.map(x => {
        return x.name;
      });
      return categoriesStrings;
    }
  },
  methods: {
    firstCategory(category: string): boolean {
      return category === this.categories[0].name;
    },
    getCurrentTime() {
      let cal = this.$refs.calendar as any;
      return cal ? cal.times.now.hour * 60 + cal.times.now.minute : 0;
    },
    scrollToTime() {
      if (this.isToday) {
        const time = this.getCurrentTime();
        const first = Math.max(0, time - (time % 30) - 30);
        let cal = this.$refs.calendar as any;
        cal.scrollToTime(first);
      } else if (
        this.events.find(
          x =>
            x.cargoLoading !== undefined && x.cargoLoading.booking !== undefined
        )
      ) {
        let bookedEvents = this.events.filter(
          x =>
            x.cargoLoading !== undefined && x.cargoLoading.booking !== undefined
        );
        if (bookedEvents) {
          let sortedEvents = bookedEvents.sort(
            (a, b) =>
              a.cargoLoading!.booking!.startDate.unix() -
              b.cargoLoading!.booking!.startDate.unix()
          );
          let firstTime =
            sortedEvents[0].cargoLoading!.booking!.startDate.hours() * 60 +
            sortedEvents[0].cargoLoading!.booking!.startDate.minutes();
          let time = Math.max(0, firstTime - (firstTime % 30) - 30);
          let cal = this.$refs.calendar as any;
          cal.scrollToTime(time);
        }
      }
    },
    calenderBadDate(dstring: string): boolean {
      const time = this.toMoment(dstring);
      const yesterday = new MomentX().startOfDay();
      return time.isBefore(this.pickupEarliest) || time.isBefore(yesterday);
    },
    weekDayText() {
      return this.date.dateTextPrint(this.locale);
    },
    getEventColor(event: any) {
      return event.color;
    },
    getEventCategory(event: any) {
      return event.category.name;
    },
    getEvents() {
      return this.events;
    },
    prev() {
      let calender = this.$refs.calendar as any;
      calender.prev();
      this.$nextTick().then(() => {
        this.$emit("setDate", calender.value);
      });
    },
    next() {
      let calender = this.$refs.calendar as any;
      calender.next();
      this.$nextTick().then(() => {
        this.$emit("setDate", calender.value);
      });
    },
    openDate() {
      this.datePicker = true;
    },
    closeDate() {
      this.datePicker = false;
    },
    setDate(dstring: string | null) {
      this.focus = dstring!;
      this.$emit("setDate", dstring);
    },
    toMoment(dstring: string): MomentX {
      return new MomentX(dstring, "YYYY-MM-DD HH:mm");
    },
    toDateString(date: MomentX): string | null {
      if (date) {
        return date.datePrint();
      }
      return null;
    },
    isBlocking(event: CargoLoadingMobileEventsContainer): boolean {
      return event.type === LoadingSlotType.AutoBlocking;
    }
  },
  mounted() {
    //TODO: säkra den här koden mot att nån ligger o pollar oss i flera dagar. Kalenderskottet är ganska dyrt
    let cal = this.$refs.calendar as any;
    setInterval(() => cal.updateTimes(), 60000);
    this.scrollToTime();
  },
  data: (): {
    focus: string;
    datePicker: Boolean;
  } => ({
    focus: "",
    datePicker: false
  })
});
