

















































































































import Vue from "vue";
import { actions } from "@/scripts/store/constants";
import {
  ActorsClient,
  AgreementListDto,
  AgreementRowPriceUpdateDto,
  AgreementsClient,
  ApiConfiguration,
  CargoType,
  RepositoryDto,
  TruckType
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { currencyName, truckTypeName } from "@/scripts/misc/enumNames";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";

interface PriceUpdateModel {
  agreementId: number;
  supplierId: number;
  truckType: TruckType;
  price: number | undefined;
  prevPrice: string;
  checked: boolean;
}

export default Vue.extend({
  components: {
    DialogCard,
    NumberInput,
    Autocomplete,
    Checkbox,
    ReadOnlyText
  },
  props: {
    agreementId: Number,
    supplierId: Number,
    agreements: Array as () => AgreementListDto[]
  },
  watch: {
    valuesToWatch: {
      handler() {
        if (this.agreementId && this.supplierId) {
          this.init();
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    valuesToWatch(): any[] {
      return [this.agreementId, this.supplierId];
    },
    stopPriceEnabled(): boolean {
      return [CargoType.Road, CargoType.MachineTransport].includes(
        this.cargoType
      );
    },
    serviceFeeEnabled(): boolean {
      return [CargoType.Container].includes(this.cargoType);
    },
    initialFeeEnabled(): boolean {
      return [CargoType.MachineTransport].includes(this.cargoType);
    },
    cargoType(): CargoType {
      return this.agreements[0].cargoType;
    },
    clientName(): string {
      if (this.agreements.length === 0) {
        return "";
      }
      return this.agreements[0].clientName!;
    },
    currencyName(): string {
      if (this.agreements.length === 0) {
        return "";
      }
      return currencyName(this.agreements[0].currency);
    },
    title(): string {
      if (this.agreements.length === 0) {
        return "";
      }
      const a = this.agreements[0];
      return `${a.pickupCounty} ${a.pickupZipCode} - ${a.deliveryCounty} ${a.deliveryZipCode}`;
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    save() {
      this.saveInProgress = true;
      this.actuallySave();
    },
    actuallySave() {
      const saveDtos = this.updateModels
        .filter(m => m.checked)
        .map(
          m =>
            new AgreementRowPriceUpdateDto({
              agreementId: m.agreementId,
              supplierId: m.supplierId,
              truckType: m.truckType,
              price: m.price,
              stopPrice: this.stopPrice,
              serviceFee: this.serviceFee,
              initialFee: this.initialFee
            })
        );
      new AgreementsClient(new ApiConfiguration(this.$store))
        .updatePrice(saveDtos)
        .then(() => {
          this.saveInProgress = false;
          this.$emit("search");
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    },
    init() {
      this.fetchSuppliers();
      const agreement = this.agreements.find(
        a => a.supplierId === this.supplierId
      );
      this.stopPrice = agreement ? agreement.stopPrice : undefined;
      this.serviceFee = agreement ? agreement.serviceFee : undefined;
      this.initialFee = agreement ? agreement.initialFee : undefined;
      this.updateModels = truckTypesByCargoType(this.cargoType).map(tt => {
        const p = agreement
          ? agreement.prices!.find(p => p.truckType === tt.id)
          : undefined;
        const price = p ? p.price : undefined;
        return {
          agreementId: this.agreementId,
          supplierId: this.supplierId,
          truckType: tt.id,
          price: price,
          prevPrice: price ? price.toString() : "null",
          checked: false
        };
      });
    },
    fetchSuppliers() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .suppliersForSupportAdmin(this.cargoType)
        .then(res => {
          this.suppliers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    stopPrice: number | undefined;
    serviceFee: number | undefined;
    initialFee: number | undefined;
    updateModels: PriceUpdateModel[];
    suppliers: RepositoryDto[];
  } => ({
    valid: false,
    saveInProgress: false,
    stopPrice: undefined,
    serviceFee: undefined,
    initialFee: undefined,
    updateModels: [],
    suppliers: []
  })
});
