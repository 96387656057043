



































import Vue from "vue";
import { ApiConfiguration, AgreementsClient } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    agreementRouteId: Number
  },
  watch: {
    agreementRouteId: {
      handler() {
        this.fetchSpecialRequirements();
      },
      immediate: true
    }
  },
  methods: {
    fetchSpecialRequirements() {
      if (!this.agreementRouteId) {
        return;
      }
      new AgreementsClient(new ApiConfiguration(this.$store))
        .specialRequirements(this.agreementRouteId)
        .then(res => {
          this.specialRequirements = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { specialRequirements?: string } => ({
    specialRequirements: undefined
  })
});
