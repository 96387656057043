
















































































































































































































import Vue from "vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import {
  ActorDetailsDto,
  ActorsClient,
  ActorType,
  ApiConfiguration,
  Country,
  DayType,
  HolidayDto,
  PublishRuleDto,
  RepositoryDto,
  SuperAdminDto,
  WorkingHoursDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { dayTypes, publishRuleTypes } from "@/scripts/misc/enumLists";
import MomentX from "@/scripts/misc/momentX";
import cached from "@/scripts/misc/cached";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    ReadOnlyText,
    TextMulti,
    Spinner,
    GreyTitle
  },
  props: {
    actorIdToView: Number as () => number | undefined,
    actorTypeToView: Number as () => ActorType
  },
  watch: {
    actorIdToView: {
      handler() {
        if (this.actorIdToView) {
          this.init();
        } else {
          this.actor = undefined;
        }
      },
      immediate: true
    }
  },
  computed: {
    viewingClient(): boolean {
      return this.actorTypeToView === ActorType.Client;
    },
    viewingSupplier(): boolean {
      return this.actorTypeToView === ActorType.Supplier;
    },
    tabs(): { id: number; name: string; visible: boolean }[] {
      return [
        {
          id: 0,
          name: t("GeneralValues"),
          visible: true
        },
        {
          id: 1,
          name: t("PublishRules"),
          visible: this.showClientSpecificData
        },
        {
          id: 2,
          name: t("WorkingHours"),
          visible: this.showClientSpecificData
        },
        {
          id: 3,
          name: t("SuperAdmin"),
          visible: this.actorIdToView === this.actorId
        }
      ];
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    actorId(): number {
      return this.$store.getters[gets.userInfo].actorId;
    },
    showClientSpecificData(): boolean {
      return this.viewingClient && this.actorType === ActorType.Supplier;
    },
    superAdminDescription(): string {
      return t("MyActorSuperAdminsText").replace(
        "{0}",
        this.$store.getters[gets.userInfo].actorName
      );
    }
  },
  methods: {
    publishRuleTypes: publishRuleTypes,
    dayTypes: dayTypes,
    startTime(dayType: DayType): string {
      const d = this.workingHours.find(w => w.dayType === dayType);
      return d ? d.start.hoursAndMinutes() : "";
    },
    endTime(dayType: DayType): string {
      const d = this.workingHours.find(w => w.dayType === dayType);
      return d ? d.stop.hoursAndMinutes() : "";
    },
    tabIndexById(id: number): number {
      return this.tabs.filter(t => t.visible).findIndex(t => t.id === id);
    },
    countryName(country: Country): string | undefined {
      const c = this.countries.find(c => c.id === country);
      if (!c) {
        return undefined;
      }
      return c.text;
    },
    init() {
      this.selectedTab = 0;
      this.fetchActor();
      this.fetchCountries();
      if (this.showClientSpecificData) {
        this.fetchPublishRules();
        this.fetchSchedule();
      }
      if (this.actorIdToView === this.actorId) {
        this.fetchSuperAdmins();
      }
    },
    fetchActor() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .details(this.actorIdToView!)
        .then(res => {
          this.actor = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPublishRules() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .publishRules(this.actorIdToView!)
        .then(res => {
          this.publishRules = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSchedule() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .clientSchedule(this.actorIdToView!)
        .then(res => {
          this.workingHours = res.workingHours!;
          this.holidays = res
            .holidays!.filter(
              h =>
                h.date > new MomentX().addDays(-1) &&
                h.date < new MomentX().addYears(1)
            )
            .sort((a, b) => a.date.unix() - b.date.unix());
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSuperAdmins() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .superAdmins()
        .then(res => {
          this.superAdmins = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    selectedTab: number;
    actor: ActorDetailsDto | undefined;
    publishRules: PublishRuleDto[];
    workingHours: WorkingHoursDto[];
    holidays: HolidayDto[];
    countries: RepositoryDto[];
    superAdmins: SuperAdminDto[];
  } => ({
    selectedTab: 0,
    actor: undefined,
    publishRules: [],
    workingHours: [],
    holidays: [],
    countries: [],
    superAdmins: []
  })
});
