















import Vue from "vue";
import {
  ApiConfiguration,
  LoadingSlotListDto,
  LoadingSlotsClient
} from "@/scripts/cld.api";
import SlotTable from "@/components/web/slots/SlotTable.vue";
import SlotTableSearch from "@/components/web/slots/SlotTableSearch.vue";
import nextUnique from "@/scripts/misc/nextUnique";
export default Vue.extend({
  components: {
    SlotTable,
    SlotTableSearch
  },
  methods: {
    search() {
      const unique = nextUnique();
      this.loading = unique;
      new LoadingSlotsClient(new ApiConfiguration(this.$store))
        .loadingSlots()
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.slots = res;
          this.loading = 0;
        });
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: number;
    slots: LoadingSlotListDto[];
  } => ({
    loading: 0,
    slots: []
  })
});
