










































































































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import {
  ActorsClient,
  ActorType,
  ApiConfiguration,
  PreEditActorDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";

export default Vue.extend({
  components: {
    SearchCard,
    Spinner,
    ReadOnlyText,
    Autocomplete,
    TextSingle,
    TextMulti
  },
  computed: {
    pendingChanges(): boolean {
      return this.originalActor !== JSON.stringify(this.actor);
    },
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchActor() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .preEdit()
        .then(res => {
          this.actor = res;
          this.originalActor = JSON.stringify(this.actor);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .edit(this.actor!)
        .then(() => {
          this.saveInProgress = false;
          this.init();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.actor = undefined;
      this.fetchActor();
      this.fetchCountries();
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    actor: PreEditActorDto | undefined;
    originalActor: string;
    countries: RepositoryDto[];
  } => ({
    valid: false,
    saveInProgress: false,
    actor: undefined,
    originalActor: "",
    countries: []
  })
});
