


























import Vue from "vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import BasicUserSettings from "@/components/web/settings/BasicUserSettings.vue";
import NotificationSettings from "@/components/web/settings/NotificationSettings.vue";
import {
  BasicUserSettingsDto,
  NotificationSettingsDto
} from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    BasicUserSettings,
    NotificationSettings,
    GreyTitle
  },
  props: {
    basicUserSettings: BasicUserSettingsDto,
    notificationSettings: NotificationSettingsDto
  }
});
