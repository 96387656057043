






















import Vue from "vue";
import {
  ExplorerNodeDto,
  ExplorerClient,
  ApiConfiguration,
  UserConfigurationType,
  ExplorerSearchCriteria
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ExplorerSearch from "@/components/support-admin/explorer/ExplorerSearch.vue";
import ExplorerTree from "@/components/support-admin/explorer/ExplorerTree.vue";
import ExplorerDialog from "@/components/support-admin/explorer/ExplorerDialog.vue";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    Spinner,
    ExplorerSearch,
    ExplorerTree,
    ExplorerDialog
  },
  computed: {
    searchCriteria(): ExplorerSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.Explorer,
        1
      );
    }
  },
  methods: {
    itemClicked(item: ExplorerNodeDto): void {
      this.selectedItem = item;
    },
    search() {
      const unique = nextUnique();
      this.loading = unique;
      const id = this.selectedItem ? this.selectedItem.id : undefined;
      this.selectedItem = undefined;
      new ExplorerClient(new ApiConfiguration(this.$store))
        .nodes(this.searchCriteria)
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.items = res;
          if (id) {
            this.selectedItem = this.items.find(i => i.id === id);
          }
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    selectedItem: ExplorerNodeDto | undefined;
    loading: number;
    items: ExplorerNodeDto[];
  } => ({
    selectedItem: undefined,
    loading: 0,
    items: []
  })
});
