
































import Vue from "vue";
import { ApiConfiguration, LoadsClient } from "@/scripts/cld.api";
import { appendixName } from "@/scripts/misc/enumNames";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    appendixId: Number
  },
  watch: {
    appendixId: {
      handler() {
        this.fetchAppendixData();
      },
      immediate: true
    }
  },
  computed: {
    keys(): string[] {
      return Object.keys(this.data!).filter(
        k => !["type", "consigneeName"].includes(k)
      );
    }
  },
  methods: {
    appendixName: appendixName,
    close() {
      this.$emit("close");
    },
    fetchAppendixData() {
      if (!this.appendixId) {
        this.data = undefined;
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .appendixData(this.appendixId)
        .then(res => {
          this.data = JSON.parse(res.json!);
        });
    }
  },
  data: (): {
    data?: any;
    exporting: boolean;
  } => ({
    data: undefined,
    exporting: false
  })
});
