






































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import {
  ApiConfiguration,
  RepositoryDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DialogCard,
    Dropdown
  },
  props: {
    dialog: Boolean,
    loadUsersForSettingsImport: Array as () => RepositoryDto[]
  },
  methods: {
    fetchNotificationSettings() {
      this.fetchInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .notificationSettings(this.loadUserId!)
        .then(res => {
          this.$emit("notificationSettings", res);
          this.close();
          this.fetchInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    loadUserId: number | undefined;
    fetchInProgress: boolean;
  } => ({
    loadUserId: undefined,
    fetchInProgress: false
  })
});
