

















































































import Vue from "vue";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  props: {
    value: Number,
    label: String,
    placeholder: String,
    rules: Array,
    allowNull: Boolean,
    onlyIntegers: Boolean,
    onlyOnBlur: Boolean,
    disabled: Boolean,
    asterisk: Boolean,
    tabindex: String,
    readonly: Boolean,
    compact: Boolean,
    grey: Boolean
  },
  methods: {
    input(val: string) {
      if (this.onlyIntegers && val) {
        const decimalPos = Math.max(val.indexOf("."), val.indexOf(","));
        if (decimalPos !== -1) {
          val = val.substring(0, decimalPos);
        }
      }
      this.newValue(val, !this.onlyOnBlur);
    },
    newValue(valString: string | null, instant: boolean) {
      let val = valString ? Number(valString) : this.allowNull ? null : 0;

      if (instant) {
        this.emitValue(val);
      } else {
        this.emitValueWhenFinished(val);
      }
    },
    emitValue(val: number | null) {
      this.$emit("input", val);
    },
    emitValueWhenFinished(val: number | null) {
      const unique = nextUnique();
      this.inputTracker = unique;
      setTimeout(() => {
        if (this.inputTracker === unique) {
          this.emitValue(val);
        }
      }, 400);
    },
    keydown(e: any): boolean {
      if (this.onlyIntegers) {
        e = e ? e : window.event;
        const key = e.key.toString();
        if (key === "." || key === ",") {
          e.preventDefault();
          return false;
        }
      }
      this.$emit("keydown");
      return true;
    }
  },
  data: (): { inputTracker: number } => ({
    inputTracker: 0
  })
});
