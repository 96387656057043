








































































import Vue from "vue";
import {
  ExplorerClient,
  ExplorerSelectionOptionsDto,
  ExplorerSelectionMethod,
  ApiConfiguration,
  UserConfigurationType,
  ExplorerSearchCriteria
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";

export default Vue.extend({
  components: {
    SearchCard,
    DatePicker,
    Autocomplete,
    Dropdown,
    TextSingle
  },
  props: {
    loading: Boolean
  },
  watch: {
    "criteria.text"() {
      this.search();
    }
  },
  computed: {
    criteria(): ExplorerSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.Explorer,
        1
      );
    },
    advancedSelection(): boolean {
      return this.criteria.method === ExplorerSelectionMethod.LoadByAdvanced;
    }
  },
  methods: {
    search() {
      if (this.valid) {
        this.$emit("search");
      }
    }
  },
  mounted() {
    new ExplorerClient(new ApiConfiguration(this.$store))
      .selectionOptions()
      .then(res => {
        this.options = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    valid: boolean;
    options: ExplorerSelectionOptionsDto;
  } => ({
    valid: false,
    options: new ExplorerSelectionOptionsDto()
  })
});
