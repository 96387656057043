






















import Vue from "vue";
import Auth from "@/scripts/auth";

export default Vue.extend({
  computed: {
    mobile() {
      return Auth.browsingMobile();
    },
    supportAdmin() {
      return Auth.browsingSupportAdmin();
    }
  }
});
