










































import Vue from "vue";
import {
  ApiConfiguration,
  LoadsClient,
  AdditionalDeliveryInfoDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";

export default Vue.extend({
  components: {
    ReadOnlyText
  },
  props: {
    loadId: Number
  },
  watch: {
    loadId: {
      handler() {
        this.fetchAdditionalDeliveryInfo();
      },
      immediate: true
    }
  },
  computed: {
    disabled(): boolean {
      return this.info === undefined || !this.info.lat;
    },
    deliveryCoords(): string {
      return `${this.info!.lat} ${this.info!.lon}`;
    },
    mapLink(): string {
      return `https://www.openstreetmap.org/?mlat=${this.info!.lat}&mlon=${
        this.info!.lon
      }#map=13/${this.info!.lat}/${this.info!.lon}`;
    }
  },
  methods: {
    fetchAdditionalDeliveryInfo() {
      if (!this.loadId) {
        return;
      }
      new LoadsClient(new ApiConfiguration(this.$store))
        .additionalDeliveryInfo(this.loadId)
        .then(res => {
          this.info = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { info?: AdditionalDeliveryInfoDto } => ({
    info: undefined
  })
});
