





























































































































import { SupplierDeleted } from "@/scripts/types";
import Vue from "vue";

export default Vue.extend({
  props: {
    type: String,
    loading: Boolean,
    pendingChanges: Boolean,
    qualityPointsLoading: Boolean,
    datesValid: Boolean,
    supplierList: Array as () => SupplierDeleted[]
  },
  methods: {
    removeAgreement() {
      this.$emit("removeAgreement");
    },
    removeSupplier(supplierId: number) {
      this.$emit("removeSupplier", supplierId);
    },
    rankByPrice() {
      this.$emit("rankByPrice");
    },
    rankByQP() {
      this.$emit("rankByQP");
    },
    undo() {
      this.$emit("undo");
    },
    finishProcurement() {
      this.$emit("finishProcurement");
    },
    saveAgreement() {
      this.$emit("saveAgreement");
    }
  },
  data: (): { types: { agreements: string; procurements: string } } => ({
    types: {
      agreements: "agreements",
      procurements: "procurements"
    }
  })
});
