




































import Vue from "vue";
import cached from "@/scripts/misc/cached";
import { actions } from "@/scripts/store/constants";
import { Country, SuggestionDto } from "@/scripts/cld.api";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  props: {
    value: String,
    label: String,
    country: Number as () => Country,
    county: String,
    zipcode: String,
    tabindex: String,
    disabled: Boolean
  },
  methods: {
    input(val?: SuggestionDto | string) {
      if (!!val && (val as SuggestionDto).address) {
        this.$emit("input", (val as SuggestionDto).address);
        this.$emit("zipcode", (val as SuggestionDto).zipcode);
      } else {
        this.$emit("input", val);
      }
    },
    filter(_: any, queryText: string, itemText: string) {
      return stringSearchHit(queryText, itemText, true);
    },
    searchInput(val?: string | null) {
      if (!val) {
        this.items = [];
      }
      if (
        !val ||
        val.length < 3 ||
        !this.country ||
        !this.county ||
        !this.zipcode
      ) {
        return;
      }
      cached("addressSuggestions", [
        this.country,
        this.county,
        this.zipcode,
        val
      ])
        .then(res => {
          this.items = this.combine(this.items, res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    combine(l1: SuggestionDto[], l2: SuggestionDto[]): SuggestionDto[] {
      let res: SuggestionDto[] = l1;
      for (const i of l2) {
        if (
          !res.find(r => r.address === i.address && r.zipcode === i.zipcode)
        ) {
          res.push(i);
        }
      }
      res.sort((a, b) => stringCompare(a.address!, b.address!));
      return res;
    }
  },
  data: (): { items: SuggestionDto[] } => ({
    items: []
  })
});
