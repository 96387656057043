












































































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import IndexAccordion from "@/components/web/index/IndexAccordion.vue";
import {
  ApiConfiguration,
  BaseIndexPeriodDto,
  FuelIndexDto,
  FuelIndexType,
  IndexPeriodDto,
  IndexType,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import BezierChart, {
  AxisUnit,
  chartColors
} from "@/components/shared/charts/BezierChart.vue";
import { actions } from "@/scripts/store/constants";
import { percentage } from "@/scripts/misc/money";
import Config from "@/scripts/config/config";
import { linkWithLocale, stringCompare } from "@/scripts/misc/stringUtils";
import { ChartData, ChartDataset, TooltipItem } from "chart.js";
import { t } from "@/scripts/language/i18n";
import { fuelIndexTypes } from "@/scripts/misc/enumLists";

export type IndexComponents = {
  share: IndexComponentsInner;
  base: IndexComponentsInner;
  curr: IndexComponentsInner;
};

type IndexComponentsInner = {
  nord: number;
  cont: number;
  euro: number;
};

export default Vue.extend({
  components: {
    SearchCard,
    Dropdown,
    DatePicker,
    BezierChart,
    IndexAccordion
  },
  watch: {
    fromToForWatch: {
      handler() {
        this.fetchFuelIndexes();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    from(): string | undefined {
      const val = this.$route.query.from;
      if (!val) {
        return undefined;
      }
      return val as string;
    },
    to(): MomentX | undefined {
      const val = this.$route.query.to;
      if (!val) {
        return undefined;
      }
      return this.toMomentX(val as string);
    },
    noIndexYetText(): string {
      if (!this.to || !this.from || this.indexComponents) {
        return "";
      }
      return t("NoIndexYet").replace("{0}", this.to.datePrint());
    },
    fromToForWatch(): any {
      return [this.from, this.to];
    },
    indexDates(): RepositoryDto[] {
      return this.baseIndexPeriods
        .map(
          i =>
            new RepositoryDto({
              id: i.indexDate.datePrint() as any,
              text: i.displayName
            })
        )
        .sort((a, b) => -stringCompare(a.id, b.id));
    },
    baseIndexPeriod(): IndexPeriodDto | undefined {
      return this.indexPeriods.find(p => p.from.datePrint() === this.from);
    },
    indexPeriod(): IndexPeriodDto | undefined {
      if (!this.to) {
        return;
      }
      return this.indexPeriods.find(p => this.to!.isBetween(p.from, p.to));
    },
    indexComponents(): IndexComponents | undefined {
      if (!this.baseIndexPeriod || !this.indexPeriod) {
        return undefined;
      }
      const baseValues = this.baseIndexPeriod!.values;
      const currValues = this.indexPeriod!.values;
      if (!baseValues || !currValues) {
        return undefined;
      }
      return {
        share: {
          nord: baseValues.find(v => v.type === IndexType.NordicFuel)!.share,
          cont: baseValues.find(v => v.type === IndexType.ContinentalFuel)!
            .share,
          euro: baseValues.find(v => v.type === IndexType.EuroCurrency)!.share
        },
        base: {
          nord: baseValues.find(v => v.type === IndexType.NordicFuel)!.value,
          cont: baseValues.find(v => v.type === IndexType.ContinentalFuel)!
            .value,
          euro: baseValues.find(v => v.type === IndexType.EuroCurrency)!.value
        },
        curr: {
          nord: currValues.find(v => v.type === IndexType.NordicFuel)!.value,
          cont: currValues.find(v => v.type === IndexType.ContinentalFuel)!
            .value,
          euro: currValues.find(v => v.type === IndexType.EuroCurrency)!.value
        }
      };
    },
    documentLocation(): string {
      return linkWithLocale(
        Config.getDocsUrl() +
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index_guide/index.html"
      );
    },
    chartData(): ChartData<"line", number[], string> {
      let labels: string[] = [];
      let datasets: ChartDataset<"line", number[]>[] = [];
      if (this.fuelIndexes.length > 0) {
        const fromAsDate = new MomentX(this.from);
        const points = this.fuelIndexes
          .filter(i => i.date > new MomentX("2012-01-01"))
          .filter(
            i =>
              i.date > fromAsDate.addMonths(-6) ||
              i.date > this.to!.addMonths(-6)
          )
          .filter(i => i.date <= fromAsDate || i.date <= this.to!)
          .filter(i => i.type === this.fuelIndexType);
        labels = points.map(p => p.date.datePrint());
        datasets = [
          {
            label: t("Index"),
            cubicInterpolationMode: "monotone",
            borderColor: chartColors(3),
            data: points.map(p => p.factor - 1)
          },
          {
            label: `${t("BaseIndex")} ${this.from}`,
            cubicInterpolationMode: "monotone",
            borderColor: chartColors(0),
            data: points.map(
              _ =>
                this.fuelIndexes.find(p =>
                  p.date.isSame(new MomentX(this.from))
                )!.factor - 1
            ),
            pointRadius: 0
          }
        ];
      }
      return {
        labels: labels,
        datasets: datasets
      };
    },
    yAxisUnit(): AxisUnit {
      return AxisUnit.Percentage;
    }
  },
  methods: {
    labelCallback(x: TooltipItem<"line">): string {
      return percentage(x.raw as number, 2);
    },
    toMomentX(val: string): MomentX | undefined {
      return new MomentX(val);
    },
    fromChanged(val: string | undefined) {
      this.changeDates(val, this.to);
    },
    toChanged(val: MomentX | undefined) {
      this.changeDates(this.from, val);
    },
    changeDates(from: string | undefined, to: MomentX | undefined) {
      this.$router.push({
        path: this.$route.path,
        query: {
          from: from,
          to: to ? to.datePrint() : undefined
        }
      });
    },
    fetchIndexPeriods() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .indexPeriods()
        .then(res => {
          this.indexPeriods = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchBaseIndexPeriods() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .baseIndexPeriods()
        .then(res => {
          this.baseIndexPeriods = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchFuelIndexes() {
      const from = new MomentX(this.from);
      const to = this.to;
      if (!from.isValid || !to) {
        this.fuelIndexesForDate = [];
        this.fuelIndexes = [];
        return;
      }
      new RepositoryClient(new ApiConfiguration(this.$store))
        .fuelIndexesForDate(from, to)
        .then(res => {
          this.fuelIndexesForDate = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      new RepositoryClient(new ApiConfiguration(this.$store))
        .fuelIndexes(from)
        .then(res => {
          this.fuelIndexes = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    if (!this.to) {
      this.toChanged(new MomentX());
    }
    this.fetchIndexPeriods();
    this.fetchBaseIndexPeriods();
  },
  data: (): {
    indexPeriods: IndexPeriodDto[];
    baseIndexPeriods: BaseIndexPeriodDto[];
    fuelIndexesForDate: FuelIndexDto[];
    fuelIndexes: FuelIndexDto[];
    fuelIndexType: FuelIndexType;
    fuelIndexTypes: RepositoryDto[];
  } => ({
    indexPeriods: [],
    baseIndexPeriods: [],
    fuelIndexesForDate: [],
    fuelIndexes: [],
    fuelIndexType: FuelIndexType.NordicFuelIndex,
    fuelIndexTypes: fuelIndexTypes()
  })
});
