
























































































import TextSingle from "@/components/shared/input/TextSingle.vue";
import {
  ApiConfiguration,
  DeliverLoadMobileDto,
  MobileClient
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import {
  errorDialog,
  alertDialog,
  popupDialog
} from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import {
  BarcodeFormat,
  DecodeHintType,
  BrowserMultiFormatReader
} from "@zxing/library";
import Vue from "vue";
export default Vue.extend({
  components: {
    TextSingle
  },
  computed: {
    coordinates() {
      return (
        this.saveDto.deliveryLocationLat +
        " , " +
        this.saveDto.deliveryLocationLng
      );
    }
  },
  methods: {
    packageScan() {
      this.scanPackage = true;
      this.scanBarcode();
    },
    consigneeScan() {
      this.scanConsignee = true;
      this.scanBarcode();
    },
    transportNoScan() {
      this.scanTransportNo = true;
      this.scanBarcode();
    },
    scanBarcode() {
      const hints = new Map();
      const formats = [
        BarcodeFormat.CODE_128,
        BarcodeFormat.EAN_8,
        BarcodeFormat.EAN_13
      ];
      hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);

      const codeReader = new BrowserMultiFormatReader();
      codeReader.hints = hints;
      let deviceId = "";
      codeReader.listVideoInputDevices().then(devices => {
        deviceId = devices[0].deviceId;
      });
      codeReader
        .decodeOnceFromVideoDevice(deviceId, "video")
        .then(result => {
          if (this.scanPackage) {
            this.saveDto.packageNo = result.getText();
            this.scanPackage = false;
          }
          if (this.scanTransportNo) {
            this.saveDto.transportNo = result.getText();
            this.scanTransportNo = false;
          }
          if (this.scanConsignee) {
            this.saveDto.consigneeNote = result.getText();
            this.scanConsignee = false;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    deliver() {
      if (
        this.saveDto.deliveryLocationLng !== undefined &&
        this.saveDto.deliveryLocationLat !== undefined
      ) {
        this.doDeliver();
      } else {
        this.showContinueWitoutPositionDialog();
      }
    },
    async resetFormValidation() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        if (form !== undefined) {
          form.reset();
        }
      });
    },
    showContinueWitoutPositionDialog() {
      popupDialog({
        noCloseButton: true,
        title: this.$t("CouldNotGetPosition").toString(),
        body: this.$t("ContinueWithoutPosition").toString(),
        btnText1: this.$t("Deliver").toString(),
        btnColor1: "success",
        btnCallback1: () => {
          this.doDeliver();
        },
        btnText2: this.$t("Cancel").toString(),
        btnColor2: "error",
        btnCallback2: () => {}
      });
    },
    doDeliver() {
      this.deliverInProgress = true;
      new MobileClient(new ApiConfiguration(this.$store))
        .deliver(this.saveDto)
        .then(res => {
          if (res) {
            pushLoadNotification("deliver", res);
            this.saveDto = new DeliverLoadMobileDto();
            this.position = false;
            this.delivered = true;
            this.resetFormValidation();
            this.getLocation(false);
          } else {
            alertDialog(
              this.$t("LoadNotFound").toString(),
              this.$t("LoadNotFoundWithTransportNo").toString()
            );
          }
          this.deliverInProgress = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.deliverInProgress = false;
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
      this.delivered = false;
    },
    getLocation(showError: boolean) {
      navigator.geolocation.getCurrentPosition(
        position => {
          this.saveDto.deliveryLocationLat = position.coords.latitude.toString();
          this.saveDto.deliveryLocationLng = position.coords.longitude.toString();
          this.position = true;
        },
        () => {
          if (showError) {
            alertDialog(
              this.$t("LocationBlocked").toString(),
              this.$t("LocationSettings").toString()
            );
          }
        }
      );
    }
  },
  mounted() {
    this.getLocation(false);
    if (this.$route.query.tn) {
      this.saveDto.transportNo = this.$route.query.tn as string;
    }
  },
  data: (): {
    valid: boolean;
    loading: boolean;
    deliverInProgress: boolean;
    scanPackage: boolean;
    scanTransportNo: boolean;
    scanConsignee: boolean;
    position: boolean;
    saveDto: DeliverLoadMobileDto;
    delivered: boolean;
  } => ({
    valid: false,
    loading: true,
    deliverInProgress: false,
    scanPackage: false,
    scanTransportNo: false,
    scanConsignee: false,
    position: false,
    saveDto: new DeliverLoadMobileDto(),
    delivered: false
  })
});
