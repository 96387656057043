



























































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { TableHeader } from "@/scripts/types";
import { stringSearchHit } from "@/scripts/misc/stringUtils";
import { DocumentGroupRouteDto } from "@/scripts/cld.api";

export default Vue.extend({
  components: {
    DialogCard,
    CustomTableHeader,
    CustomFilter
  },
  props: {
    dialog: Boolean,
    routes: Array as () => DocumentGroupRouteDto[]
  },
  computed: {
    filteredRoutes(): DocumentGroupRouteDto[] {
      return this.routes
        .filter(r => stringSearchHit(this.pickupFilter, r.pickupLocation, true))
        .filter(r =>
          stringSearchHit(this.deliveryFilter, r.deliveryLocation, true)
        );
    }
  },
  data: (): {
    headers: TableHeader[];
    pickupFilter: string;
    deliveryFilter: string;
  } => ({
    headers: [
      {
        value: "pickupLocation",
        text: "PickupLocation",
        width: 500
      },
      {
        value: "deliveryLocation",
        text: "DeliveryLocation",
        width: 500
      },
      {
        value: "from",
        text: "AgreementStart",
        width: 1
      },
      {
        value: "to",
        text: "AgreementEnds",
        width: 1
      }
    ],
    pickupFilter: "",
    deliveryFilter: ""
  })
});
