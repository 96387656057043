





















































import Vue from "vue";
export default Vue.extend({
  data: (): { name: string; email: string; phone: string } => ({
    name: "Susanne Brundell Wahren",
    email: "susanne.brundell-wahren@triona.se",
    phone: "+46 70 258 12 21"
  })
});
