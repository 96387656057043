var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"no-vertical-padding",attrs:{"cols":"12"}},[_c('SearchCard',{attrs:{"title":"Indexuppdatering"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"tiniest-padding",attrs:{"elevation":"2","rounded":""}},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-data-table',{attrs:{"item-key":"idx","headers":_vm.headers,"items":_vm.periods,"height":_vm.tableHeight,"loading":_vm.periods.length === 0,"item-class":function (i) { return (i.id < 0 ? 'red-row' : i.id > 10000 ? 'green-row' : ''); },"items-per-page":17,"footer-props":{
            'items-per-page-text': _vm.$t('Quantity') + ':',
            'items-per-page-all-text': _vm.$t('MobileAll'),
            'items-per-page-options': [17, -1],
            'page-text': _vm.$t('ShowingItemsXofY')
          },"mobile-breakpoint":"0","fixed-header":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var props = ref.props;
          var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.from",fn:function(ref){
          var item = ref.item;
return [_c('DatePicker',{on:{"input":function($event){item.id += 11000}},model:{value:(item.from),callback:function ($$v) {_vm.$set(item, "from", $$v)},expression:"item.from"}})]}},{key:"item.to",fn:function(ref){
          var item = ref.item;
return [_c('DatePicker',{on:{"input":function($event){item.id += 11000}},model:{value:(item.to),callback:function ($$v) {_vm.$set(item, "to", $$v)},expression:"item.to"}})]}},{key:"item.nordic",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.nordic(item).value),callback:function ($$v) {_vm.$set(_vm.nordic(item), "value", $$v)},expression:"nordic(item).value"}})]}},{key:"item.nordicShare",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0 && v <= 1; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.nordic(item).share),callback:function ($$v) {_vm.$set(_vm.nordic(item), "share", $$v)},expression:"nordic(item).share"}})]}},{key:"item.cont",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.cont(item).value),callback:function ($$v) {_vm.$set(_vm.cont(item), "value", $$v)},expression:"cont(item).value"}})]}},{key:"item.contShare",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0 && v <= 1; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.cont(item).share),callback:function ($$v) {_vm.$set(_vm.cont(item), "share", $$v)},expression:"cont(item).share"}})]}},{key:"item.euro",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.euro(item).value),callback:function ($$v) {_vm.$set(_vm.euro(item), "value", $$v)},expression:"euro(item).value"}})]}},{key:"item.euroShare",fn:function(ref){
          var item = ref.item;
return [_c('NumberInput',{attrs:{"rules":[function (v) { return v > 0 && v <= 1; }],"only-on-blur":""},on:{"input":function($event){item.id += 11000}},model:{value:(_vm.euro(item).share),callback:function ($$v) {_vm.$set(_vm.euro(item), "share", $$v)},expression:"euro(item).share"}})]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"small-vertical-padding"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){item.id = -item.id}}},[_c('v-icon',{attrs:{"large":"","color":item.id > 0 ? 'error darken-1' : 'success'}},[_vm._v(" "+_vm._s(item.id > 0 ? "mdi-close" : "mdi-undo")+" ")])],1)],1)]}}],null,true)})],1)],1)],1),_c('v-col',{staticClass:"no-vertical-padding",attrs:{"cols":"12"}},[_c('v-sheet',{attrs:{"rounded":"","elevation":"2"}},[_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"btn-cyan","loading":_vm.saveInProgress},on:{"click":_vm.add}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-plus")]),_vm._v(" Ny period ")],1)],1),_c('v-col',{staticClass:"large-padding-right",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"warning","loading":_vm.saveInProgress,"disabled":_vm.saveDisabled},on:{"click":_vm.save}},[_c('v-icon',{attrs:{"left":"","size":"20"}},[_vm._v("mdi-floppy")]),_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }