
















































import Vue from "vue";
import {
  ConfirmationOptions,
  MobilePopupConfiguration,
  userInfoFromProfileDto
} from "@/scripts/types";
import menuLayout, {
  menu,
  pageForbiddenReason
} from "@/scripts/misc/menuLayout";
import { i18n, t } from "@/scripts/language/i18n";
import Auth from "@/scripts/auth";
import {
  UsersClient,
  ApiConfiguration,
  ActorType,
  RepositoryClient,
  BroadcastDto,
  ProfileDto,
  UserConfigurationType,
  UserLevel,
  DirectMessagingClient,
  ReportScreenDimensionsDto
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import { popupDialog } from "@/scripts/misc/popupDialogs";
import { actions, gets, mutations } from "@/scripts/store/constants";
import Toolbar from "@/components/shared/ui/Toolbar.vue";
import ContentContainer from "@/components/shared/ui/ContentContainer.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import Forbidden from "@/components/shared/ui/Forbidden.vue";
import Confirmation from "@/components/shared/ui/Confirmation.vue";
import Snackbars from "@/components/shared/ui/Snackbars.vue";
import Alert from "@/components/shared/ui/Alert.vue";
import MobileFooter from "@/components/mobile/MobileFooter.vue";
import Sound from "@/components/shared/ui/Sound.vue";

export default Vue.extend({
  components: {
    Toolbar,
    ContentContainer,
    Spinner,
    Forbidden,
    Confirmation,
    Snackbars,
    Alert,
    MobileFooter,
    Sound
  },
  watch: {
    username: {
      handler() {
        if (this.username) {
          new UsersClient(new ApiConfiguration(this.$store))
            .profile()
            .then(profile => {
              i18n.locale = profile.culture.toString();
              this.prevStatusCheck = MomentX.minDate();
              this.setupConf(profile).then(() => {
                this.checkSystemStatus();
                this.checkForMobileDevice();
                this.checkUnviewedMessages();
              });
            })
            .catch(error => {
              this.$store.dispatch(actions.handleApiError, error);
            });
        }
      },
      immediate: true
    },
    userLevel: {
      handler(userLevel) {
        if (userLevel === UserLevel.Integration) {
          this.$router.push("/integration-user");
        }
      },
      immediate: true
    },
    clientOutdated(outdated) {
      if (outdated) {
        popupDialog({
          noCloseButton: true,
          title: t("NewVersion"),
          body: t("NewVersionDialogText"),
          btnText1: t("Reload"),
          btnColor1: "success",
          btnCallback1: () => {
            location.reload();
          }
        });
      }
    },
    activityDate() {
      let secondsSinceLastCheck =
        new MomentX().unix() - this.prevStatusCheck.unix();
      if (secondsSinceLastCheck > 10) {
        this.checkSystemStatus();
      }
      let secondsSinceLastDimensionsReport =
        new MomentX().unix() - this.prevStatusDimensionsReport.unix();
      if (secondsSinceLastDimensionsReport > 300) {
        this.reportCurrentDimensions();
      }
    }
  },
  computed: {
    activityDate(): MomentX {
      return this.$store.getters[gets.activityDate];
    },
    username(): string {
      return this.$store.getters[gets.username];
    },
    clientOutdated(): boolean {
      return this.$store.getters[gets.clientOutdated];
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    actorTypeString(): string {
      if (this.actorType === undefined) {
        return "not-yet-logged-in";
      } else {
        return ActorType[this.actorType];
      }
    },
    userLevel(): UserLevel | undefined {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    confirmationOptions(): ConfirmationOptions {
      return this.$store.getters[gets.confirmationOptions];
    },
    unauthenticatedPage(): boolean {
      return this.$route.meta !== undefined && this.$route.meta.unauthenticated;
    },
    pageForbiddenReason(): string | undefined {
      if (this.unauthenticatedPage) {
        return undefined;
      }
      return pageForbiddenReason(
        this.$store.getters[gets.userInfo],
        this.$route.fullPath
      );
    },
    globalError(): any {
      return this.$store.getters[gets.globalError];
    },
    broadcast: {
      get(): BroadcastDto | undefined {
        return this.$store.getters[gets.broadcast];
      },
      set(value: BroadcastDto | undefined) {
        this.$store.commit(mutations.setBroadcast, value);
      }
    },
    broadcastMessage(): string {
      if (!this.broadcast) {
        return "";
      }
      let message = this.broadcast!.message![i18n.locale];
      if (!message) {
        message = this.broadcast!.message!["2"]; //Eng
      }
      return message;
    },
    broadcastSecondsLeft(): number | undefined {
      if (!this.broadcast) {
        return undefined;
      }
      return this.broadcast.secondsLeft;
    },
    mobile() {
      return Auth.browsingMobile();
    },
    mobilePopupConf(): MobilePopupConfiguration {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.MobilePopup,
        1
      );
    },
    atleastUser(): boolean {
      return (
        this.userLevel !== undefined &&
        [UserLevel.User, UserLevel.Admin, UserLevel.SuperAdmin].includes(
          this.userLevel
        )
      );
    }
  },
  methods: {
    signout() {
      this.$store.dispatch(actions.startSignout, window.location.pathname);
    },
    hideGlobalError() {
      this.$store.commit(mutations.setGlobalError, { show: false });
    },
    saveCurrentDimensions() {
      this.$store.commit(mutations.setDimensions, {
        width: window.innerWidth,
        height: window.innerHeight
      });
    },
    reportCurrentDimensions() {
      if (this.username) {
        const currentWidth = this.$store.getters[gets.dimensions].width;
        const currentHeight = this.$store.getters[gets.dimensions].height;
        if (currentWidth > 100 && currentHeight > 100) {
          this.prevStatusDimensionsReport = new MomentX();
          new UsersClient(new ApiConfiguration(this.$store))
            .reportScreenDimensions(
              new ReportScreenDimensionsDto({
                width: currentWidth,
                height: currentHeight
              })
            )
            .catch(error => {
              this.$store.dispatch(actions.handleApiError, error);
            });
        }
      }
    },
    checkSystemStatus() {
      this.prevStatusCheck = new MomentX();
      if (this.username) {
        new RepositoryClient(new ApiConfiguration(this.$store))
          .systemStatus()
          .then(res => {
            this.broadcast = res.broadcast;
            if (
              this.prevCacheDate &&
              !this.prevCacheDate.isSame(
                res.loacationAndAgreementRouteLastModified
              )
            ) {
              this.$store.commit(mutations.setLocationCacheOutdated, true);
            }
            this.prevCacheDate = res.loacationAndAgreementRouteLastModified;
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    },
    checkUnviewedMessages() {
      if (this.actorType === ActorType.Supplier && this.atleastUser) {
        new DirectMessagingClient(new ApiConfiguration(this.$store))
          .numUnviewed()
          .then(res => {
            this.$store.commit(mutations.setUnviewedMessages, res);
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      } else if (this.actorType === ActorType.Client) {
        this.$store.commit(mutations.setUnviewedMessages, 0);
      }
    },
    async setupConf(profile: ProfileDto) {
      return this.$store
        .dispatch(actions.initUserConf, {
          actorType: profile.actorType,
          confs: profile.userConfigurations
        })
        .then(() => {
          return this.$store.dispatch(actions.initActorConf, {
            actorType: profile.actorType,
            confs: profile.actorConfigurations
          });
        })
        .then(() => {
          this.$store.commit(
            mutations.setUserInfo,
            userInfoFromProfileDto(profile)
          );
        })
        .then(() => {
          this.menu = menuLayout(this.$store.getters[gets.userInfo]);
        });
    },
    checkForMobileDevice() {
      try {
        if (Auth.browsingMobile()) {
          return;
        }
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
          )
        ) {
          const dis = this.mobilePopupConf.dismissedDates;
          //2,3,4 dismisses hide dialog for 4,8,16 weeks resp. and so on
          if (
            dis.length < 2 ||
            dis.every(d =>
              d.isBefore(new MomentX().addDays(Math.pow(2, dis.length) * -7))
            )
          ) {
            popupDialog({
              title: `C-Load ${t("Mobile")}`,
              width: 400,
              body: t("YouAreUsingAMobileDevice"),
              btnText1: `C-Load ${t("Mobile")}`,
              btnColor1: "success",
              btnCallback1: () => {
                this.mobilePopupConf.dismissedDates.length = 0;
                return this.$store
                  .dispatch(actions.saveUserConf, {
                    type: UserConfigurationType.MobilePopup,
                    subTypeId: 1
                  })
                  .then(() => {
                    this.$router.push("/mobile");
                  });
              },
              closeButtonCallback: () => {
                this.mobilePopupConf.dismissedDates.push(new MomentX());
                return this.$store.dispatch(actions.saveUserConf, {
                  type: UserConfigurationType.MobilePopup,
                  subTypeId: 1
                });
              }
            });
          }
        }
      } catch (e) {
        console.error("Mobile popup check failed", e);
      }
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.saveCurrentDimensions();
      (window as any).visualViewport.addEventListener(
        "resize",
        this.saveCurrentDimensions
      );
      document.addEventListener("visibilitychange", () => {
        this.$store.commit(mutations.setVisible, !document.hidden);
      });
      document.addEventListener("mousemove", () => {
        this.$store.commit(mutations.setMouseActivityDate);
      });
      document.addEventListener("touchstart", () => {
        this.$store.commit(mutations.setMouseActivityDate);
      });
      window.addEventListener("beforeunload", e => {
        if (this.$store.getters[gets.preventLeaving]) {
          e.preventDefault();
          e.returnValue = "";
        }
      });
      setInterval(() => {
        if (
          this.broadcast !== undefined &&
          this.broadcast.secondsLeft !== null &&
          this.broadcast.secondsLeft! > 0
        ) {
          this.broadcast.secondsLeft!--;
        }
      }, 1000);
      setInterval(() => {
        this.$store.commit(mutations.tickLocks);
      }, 1000);
    });
  },
  data(): {
    menu: menu[];
    prevStatusCheck: MomentX;
    prevStatusDimensionsReport: MomentX;
    prevCacheDate?: MomentX;
  } {
    return {
      menu: [],
      prevStatusCheck: new MomentX(),
      prevStatusDimensionsReport: MomentX.minDate(),
      prevCacheDate: undefined
    };
  }
});
