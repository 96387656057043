












import Vue from "vue";
import NewsSheet from "@/components/shared/ui/NewsSheet.vue";
import {
  ApiConfiguration,
  Culture,
  NewsClient,
  NewsDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    NewsSheet
  },
  methods: {
    fetchNews() {
      new NewsClient(new ApiConfiguration(this.$store))
        .listSimple()
        .then(res => {
          this.news = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    updateLastRead() {
      new NewsClient(new ApiConfiguration(this.$store))
        .updateLastNewsRead()
        .then(() => {})
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.fetchNews();
      this.updateLastRead();
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    saveInProgress: boolean;
    news: NewsDto[];
    cultures: { id: number; culture: Culture }[];
  } => ({
    saveInProgress: false,
    news: [],
    cultures: []
  })
});
