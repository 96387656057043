



























































import Vue from "vue";
import {
  ActorType,
  AgreementRouteDto,
  AgreementRoutesClient,
  AgreementRouteStandardPhraseSaveDto,
  ApiConfiguration,
  CargoType,
  ConnectedTruckTypesDto,
  DefaultMandatoryFlagsDto,
  EstimateDto,
  SaveAgreementRouteStatusHiddenDto,
  StatusDto,
  TagDto,
  UserConfigurationType
} from "@/scripts/cld.api";
import AgreementRouteSearch from "@/components/web/agreements/client/AgreementRouteSearch.vue";
import AgreementRouteTable from "@/components/web/agreements/client/AgreementRouteTable.vue";
import { actions, gets, mutations } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import CreateAgreementRouteDialog from "@/components/web/agreements/client/CreateAgreementRouteDialog.vue";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import neverHappens from "@/scripts/misc/neverHappens";
import { t } from "@/scripts/language/i18n";
import { AgreementRouteFilter } from "@/scripts/types";
import nextUnique from "@/scripts/misc/nextUnique";

export enum RouteTab {
  List = 0,
  Tag,
  Visibility,
  TruckTypes,
  Estimate,
  StandardPhrases,
  MandatoryFlags
}

export interface AgreementRouteEditTracker {
  tabId: RouteTab;
  editingTab: RouteTab | undefined;
  editingRoutes: { id: number; edited: boolean; originalValue: any[] }[];
}

export default Vue.extend({
  components: {
    AgreementRouteSearch,
    AgreementRouteTable,
    CreateAgreementRouteDialog
  },
  watch: {
    filter: {
      handler() {
        this.saveFilter();
      },
      deep: true
    },
    cargoType: {
      handler(newVal: CargoType, oldVal?: CargoType) {
        if (newVal !== oldVal) {
          if (oldVal !== undefined) {
            this.clearSelection().then(() => {
              this.fetchAgreementRoutes();
              this.saveFilter();
            });
          } else {
            this.fetchAgreementRoutes();
          }
        }
      },
      immediate: true
    },
    changeCount() {
      if (!this.changeCount && !this.loading) {
        this.cancelEdit();
      }
    }
  },
  computed: {
    changeCountText(): string {
      if (this.changeCount > 0) {
        return ` (${this.changeCount})`;
      }
      return "";
    },
    changeCount(): number {
      return this.tracker.editingRoutes.filter(r => r.edited).length;
    },
    filter(): AgreementRouteFilter {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementRoute,
        1
      );
    },
    cargoType(): CargoType {
      return this.filter.cargoType;
    }
  },
  methods: {
    clearSelection(): Promise<void> {
      return this.$store.dispatch(actions.resetUserConf, {
        type: UserConfigurationType.AgreementRoute,
        subTypeId: 1,
        actorType: ActorType.Client
      });
    },
    saveFilter() {
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.AgreementRoute,
        subTypeId: 1
      });
    },
    createRoute(cargoType: number) {
      this.selectedCreateAgreementCargoType = cargoType;
      this.createAgreementRouteDialog = true;
    },
    closeDialog() {
      this.createAgreementRouteDialog = false;
      this.selectedCreateAgreementCargoType = undefined;
      this.fetchAgreementRoutes();
    },
    fetchAgreementRoutes() {
      this.tracker.editingTab = undefined;
      this.tracker.editingRoutes = [];
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .list(this.filter.cargoType)
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.routes = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    cancelEdit() {
      this.fetchAgreementRoutes();
    },
    save() {
      this.saveInProgress = true;
      const actuallySave = this.getSaveFunction(this.tracker.editingTab);
      if (this.changeCount <= 1) {
        actuallySave();
      } else {
        popupDialog({
          title: t("Save"),
          body: t("XAgreementRoutesWillBeAffected").replace(
            "{0}",
            this.changeCount.toString()
          ),
          btnText1: t("Save"),
          btnColor1: "success",
          btnCallback1: () => {
            actuallySave();
          },
          btnText2: t("Cancel"),
          btnColor2: "warning",
          btnCallback2: () => {
            this.saveInProgress = false;
          }
        });
      }
    },
    getSaveFunction(tab: RouteTab | undefined): Function {
      switch (tab) {
        case undefined:
        case RouteTab.List:
          throw new Error("Unhandled tab save"); //Kommer inte ske
        case RouteTab.Tag:
          return () => {
            this.saveTag();
          };
        case RouteTab.Visibility:
          return () => {
            this.saveVisibility(false);
          };
        case RouteTab.TruckTypes:
          return () => {
            this.saveTruckTypes();
          };
        case RouteTab.Estimate:
          return () => {
            this.saveEstimate();
          };
        case RouteTab.StandardPhrases:
          return () => {
            this.saveStandardPhrases();
          };
        case RouteTab.MandatoryFlags:
          return () => {
            this.saveMandatoryFlags();
          };
        default:
          return neverHappens(tab);
      }
    },
    saveVisibility(ignoreVisibilityProblems: boolean) {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new StatusDto({
              agreementRouteId: r.id,
              status: r.status
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveHiddenStatus(
          new SaveAgreementRouteStatusHiddenDto({
            hideShowAgreementRoutes: arrayToSave,
            saveAnyway: ignoreVisibilityProblems
          })
        )
        .then(() => {
          this.saveInProgress = false;
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    saveTruckTypes() {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new ConnectedTruckTypesDto({
              agreementRouteId: r.id,
              truckTypes: r.truckTypes
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveConnectedTrucktypes(arrayToSave)
        .then(() => {
          this.saveInProgress = false;
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    saveEstimate() {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new EstimateDto({
              agreementRouteId: r.id,
              estimate: r.estimatedNoOfLoads
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveEstimateAgreementRoutes(arrayToSave)
        .then(() => {
          this.saveInProgress = false;
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    saveStandardPhrases() {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new AgreementRouteStandardPhraseSaveDto({
              agreementRouteId: r.id,
              specialRequirementId: r.specialRequirementId,
              destinationInfoId: r.destinationInfoId,
              pickupInfoId: r.pickupInfoId
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveStandardPhrases(arrayToSave)
        .then(() => {
          this.saveInProgress = false;
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    saveMandatoryFlags() {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new DefaultMandatoryFlagsDto({
              agreementRouteId: r.id,
              mandatoryBookedDeliveryDate: r.mandatoryBookedDeliveryDate,
              mandatoryBookedPickupDate: r.mandatoryBookedPickupDate,
              customsInfoRequired: r.customsInfoRequired
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveDefaultMandatoryFlags(arrayToSave)
        .then(() => {
          this.saveInProgress = false;
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    saveTag() {
      const idsToSave = this.tracker.editingRoutes
        .filter(r => r.edited)
        .map(r => r.id);
      const arrayToSave = this.routes
        .filter(r => idsToSave.includes(r.id))
        .map(
          r =>
            new TagDto({
              agreementRouteId: r.id,
              tag: r.tag
            })
        );
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .saveTags(arrayToSave)
        .then(() => {
          this.saveInProgress = false;
          this.$store.commit(mutations.setLocationCacheOutdated, true);
          this.fetchAgreementRoutes();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: number;
    saveInProgress: boolean;
    routes: AgreementRouteDto[];
    tracker: AgreementRouteEditTracker;
    createAgreementRouteDialog: boolean;
    selectedCreateAgreementCargoType: number | undefined;
  } => ({
    loading: 0,
    saveInProgress: false,
    routes: [],
    tracker: {
      tabId: RouteTab.List,
      editingTab: undefined,
      editingRoutes: []
    },
    createAgreementRouteDialog: false,
    selectedCreateAgreementCargoType: undefined
  })
});
