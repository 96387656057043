import { render, staticRenderFns } from "./DocumentInput.vue?vue&type=template&id=610a81ae&scoped=true&"
import script from "./DocumentInput.vue?vue&type=script&lang=ts&"
export * from "./DocumentInput.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentInput.vue?vue&type=style&index=0&id=610a81ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "610a81ae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VBtn,VCol,VIcon,VImg,VRow,VSimpleTable})
