var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DialogCard',{attrs:{"dialog":_vm.dialog,"title":"Lass","width":1600},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sortedRows,"height":_vm.tableHeight,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-text': _vm.$t('Quantity') + ':',
        'items-per-page-all-text': _vm.$t('MobileAll'),
        'items-per-page-options': [100, 500, -1],
        'page-text': _vm.$t('ShowingItemsXofY')
      },"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.loadStatus",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'loadStatus' },"placeholder":"Status"},model:{value:(_vm.filters.loadStatus),callback:function ($$v) {_vm.$set(_vm.filters, "loadStatus", $$v)},expression:"filters.loadStatus"}})]},proxy:true},{key:"filter.loadId",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"Id"},model:{value:(_vm.filters.loadId),callback:function ($$v) {_vm.$set(_vm.filters, "loadId", $$v)},expression:"filters.loadId"}})]},proxy:true},{key:"filter.transportNo",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":"TrpNo"},model:{value:(_vm.filters.transportNo),callback:function ($$v) {_vm.$set(_vm.filters, "transportNo", $$v)},expression:"filters.transportNo"}})]},proxy:true},{key:"filter.clientName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Actor')},model:{value:(_vm.filters.clientName),callback:function ($$v) {_vm.$set(_vm.filters, "clientName", $$v)},expression:"filters.clientName"}})]},proxy:true},{key:"filter.truckType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'truckType' },"placeholder":"Status"},model:{value:(_vm.filters.truckType),callback:function ($$v) {_vm.$set(_vm.filters, "truckType", $$v)},expression:"filters.truckType"}})]},proxy:true}],null,true)})]}},{key:"item.viewDetails",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.selectedLoad = item}}},[_c('v-icon',[_vm._v(" mdi-magnify ")])],1)]}},{key:"item.loadStatus",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.loadStatusName(item.loadStatus)))])]}},{key:"item.loadId",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.loadId))])]}},{key:"item.transportNo",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.transportNo))])]}},{key:"item.clientName",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.clientName))])]}},{key:"item.truckType",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.truckTypeName(item.truckType)))])]}},{key:"item.pickupLocation",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.pickupLocation))])]}},{key:"item.deliveryLocation",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.deliveryLocation))])]}},{key:"item.regDate",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.regDate))])]}},{key:"item.pickupEarliest",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.pickupEarliest))])]}},{key:"item.deliverLatest",fn:function(ref){
      var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.deliverLatest))])]}}],null,true)})]},proxy:true},{key:"dialogs",fn:function(){return [_c('DialogCard',{attrs:{"dialog":_vm.selectedLoadAsExplorerNodeDto !== undefined,"title":"Lass","width":1600},on:{"close":function($event){_vm.selectedLoad = undefined}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ExplorerDialogLoadDetails',{attrs:{"item":_vm.selectedLoadAsExplorerNodeDto}})]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }