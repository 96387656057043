
















































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import {
  ApiConfiguration,
  RepositoryDto,
  StandardPhraseDto,
  StandardPhrasesClient,
  StandardPhraseType
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    DialogCard,
    Dropdown
  },
  props: {
    dialog: Boolean,
    dropDownItems: Array as () => StandardPhraseDto[],
    selectedPhraseType: Number,
    changeCount: Number
  },
  computed: {
    label(): string {
      if (this.selectedPhraseType === StandardPhraseType.PickupInfo) {
        return `${t("PickupInformation")} ${t("InternalName").toLowerCase()}`;
      } else if (
        this.selectedPhraseType === StandardPhraseType.DestinationInfo
      ) {
        return `${t("DestinationInformation")} ${t(
          "InternalName"
        ).toLowerCase()}`;
      } else if (
        this.selectedPhraseType === StandardPhraseType.SpecialRequirement
      ) {
        return `${t("SpecialRequirements")} ${t("InternalName").toLowerCase()}`;
      } else {
        return "";
      }
    },
    title(): string {
      if (this.selectedPhraseType === StandardPhraseType.PickupInfo) {
        return `${t("PickupInformation")}`;
      } else if (
        this.selectedPhraseType === StandardPhraseType.DestinationInfo
      ) {
        return `${t("DestinationInformation")}`;
      } else if (
        this.selectedPhraseType === StandardPhraseType.SpecialRequirement
      ) {
        return `${t("SpecialRequirements")}`;
      } else {
        return "";
      }
    },
    setDisclaimerText(): string {
      switch (this.selectedPhraseType) {
        case StandardPhraseType.DestinationInfo:
          return t("StandardPhraseBulkEditDisclaimer");
        case StandardPhraseType.PickupInfo:
          return t("StandardPhraseBulkEditDisclaimer");
        case StandardPhraseType.SpecialRequirement:
          return t("SpecialRequirementBulkEditDisclaimer");
        default:
          return ""; // Ska inte hända
      }
    }
  },
  methods: {
    update() {
      const phraseId =
        this.selectedPhraseId === undefined ? null : this.selectedPhraseId;
      this.$emit("update", phraseId, this.selectedPhraseType);
      this.close();
    },
    close() {
      this.$emit("close");
    },
    async fetchStandardPhrases(): Promise<void> {
      return new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          this.spec = res
            .filter(p => p.phraseType === StandardPhraseType.SpecialRequirement)
            .map(
              s =>
                new RepositoryDto({
                  id: s.id,
                  text: s.subject
                })
            );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchStandardPhrases();
  },
  data: (): {
    spec: RepositoryDto[];
    selectedPhraseId: number | undefined;
  } => ({
    spec: [],
    selectedPhraseId: undefined
  })
});
