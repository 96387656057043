




import Vue from "vue";
import NotificationSound1 from "@/assets/sound/notification.mp3";
import CountdownSound from "@/assets/sound/countdown.mp3";
import neverHappens from "@/scripts/misc/neverHappens";
import { Sound } from "@/scripts/types";
import { gets, mutations } from "@/scripts/store/constants";

export default Vue.extend({
  watch: {
    sounds: {
      handler() {
        if (this.sounds.length === 0) {
          return;
        }
        const sound = this.sounds[0];
        this.$store.commit(mutations.popSound);
        const audio = new Audio(this.sound(sound));
        audio.play();
      },
      deep: true
    }
  },
  computed: {
    sounds(): Sound[] {
      return this.$store.getters[gets.sounds];
    }
  },
  methods: {
    sound(sound: Sound): string {
      switch (sound) {
        case Sound.Notification1:
          return NotificationSound1;
        case Sound.Countdown:
          return CountdownSound;
        default:
          return neverHappens(sound);
      }
    }
  }
});
