

















































































































































































































































































































import Vue from "vue";
import {
  UserConfigurationType,
  LoadStatisticsReportSearchCriteria,
  LoadStatisticsReportContainerDto,
  LoadStatisticsReportOrderBy,
  ActorType,
  TruckType,
  LoadStatisticsReportTab,
  LoadStatisticsReportSplit,
  LoadStatisticsReportRowDto,
  LoadActionPermission
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import money, { percentage } from "@/scripts/misc/money";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem
  },
  props: {
    loading: Boolean,
    tableOptions: Object as () => TableOptions,
    showPercentage: Boolean,
    reportContainer: LoadStatisticsReportContainerDto
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 295);
    },
    searchCriteria(): LoadStatisticsReportSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.LoadStatisticsReport,
        1
      );
    },
    isClient(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    },
    options: {
      get(): TableOptions {
        return this.tableOptions;
      },
      set(options: TableOptions) {
        this.$emit("tableOptionsChanged", options);
      }
    },
    uniqueLoadIds(): boolean {
      return this.searchCriteria.splitColumns!.includes(
        LoadStatisticsReportSplit.Load
      );
    },
    valueHeaders(): string[] {
      const excluded = [
        "loadInfo",
        "pickupLocation",
        "deliveryLocation",
        "truckType",
        "unit",
        "supplierName",
        "clientName"
      ];
      return this.headers.map(h => h.value).filter(h => !excluded.includes(h));
    },
    headers(): TableHeader[] {
      const p = this.showPercentage;
      const headers: TableHeader[] = [];
      headers.push({
        value: "loadInfo",
        text: "",
        untranslated: true
      });
      headers.push({
        value: "pickupLocation",
        text: "PickupLocation",
        sortValue: LoadStatisticsReportOrderBy.PickupLocation
      });
      headers.push({
        value: "deliveryLocation",
        text: "DeliveryLocation",
        sortValue: LoadStatisticsReportOrderBy.DeliveryLocation
      });
      headers.push({
        value: "truckType",
        text: "TruckType",
        sortValue: LoadStatisticsReportOrderBy.TruckType
      });
      headers.push({
        value: "unit",
        text: "Unit",
        sortValue: LoadStatisticsReportOrderBy.Unit
      });
      headers.push({
        value: this.isClient ? "supplierName" : "clientName",
        text: this.isClient ? "Supplier" : "Client",
        sortValue: LoadStatisticsReportOrderBy.Actor
      });

      if (this.searchCriteria.tab === LoadStatisticsReportTab.Bookings) {
        headers.push({
          value: "offered",
          text: "LoadStatisticsOfferedShort",
          tooltip: "LoadStatisticsOfferedLong",
          sortValue: LoadStatisticsReportOrderBy.Offered
        });
        headers.push({
          value: p ? "shareLoaded" : "loaded",
          text: "LoadStatisticsBookedShort",
          tooltip: "LoadStatisticsBookedLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareLoaded
            : LoadStatisticsReportOrderBy.Loaded
        });
        headers.push({
          value: p ? "shareRejected" : "rejected",
          text: "LoadStatisticsDeclinedShort",
          tooltip: "LoadStatisticsDeclinedLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareRejected
            : LoadStatisticsReportOrderBy.Rejected
        });
        headers.push({
          value: p ? "shareIgnored" : "ignored",
          text: "LoadStatisticsIgnoredShort",
          tooltip: "LoadStatisticsIgnoredLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareIgnored
            : LoadStatisticsReportOrderBy.Ignored
        });
        headers.push({
          value: p ? "shareBroadcastOffered" : "broadcastOffered",
          text: "LoadStatisticsOfferedBroadcastShort",
          tooltip: "LoadStatisticsOfferedBroadcastLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareBroadcastOffered
            : LoadStatisticsReportOrderBy.BroadcastOffered
        });
        headers.push({
          value: p ? "shareBroadcastLoaded" : "broadcastLoaded",
          text: "LoadStatisticsBookedBroadcastShort",
          tooltip: "LoadStatisticsBookedBroadcastLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareBroadcastLoaded
            : LoadStatisticsReportOrderBy.BroadcastLoaded
        });
      }

      if (this.searchCriteria.tab === LoadStatisticsReportTab.Deliveries) {
        headers.push({
          value: "loaded",
          text: "LoadStatisticsLoadedShort",
          tooltip: "LoadStatisticsLoadedLong",
          sortValue: LoadStatisticsReportOrderBy.Loaded
        });
        headers.push({
          value: p
            ? "shareLoadedWithinRequestedTime"
            : "loadedWithinRequestedTime",
          text: "LoadStatisticsLoadedWithinReqShort",
          tooltip: "LoadStatisticsLoadedWithinReqLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareLoadedWithinRequestedTime
            : LoadStatisticsReportOrderBy.LoadedWithinRequestedTime
        });
        headers.push({
          value: p ? "shareLoadedWithStatedTime" : "loadedWithStatedTime",
          text: "LoadStatisticsLoadedWithStatedShort",
          tooltip: "LoadStatisticsLoadedWithStatedLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareLoadedWithStatedTime
            : LoadStatisticsReportOrderBy.LoadedWithStatedTime
        });
        headers.push({
          value: p ? "shareLoadedWithinStatedTime" : "loadedWithinStatedTime",
          text: "LoadStatisticsLoadedWithinStatedShort",
          tooltip: "LoadStatisticsLoadedWithinStatedLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareLoadedWithinStatedTime
            : LoadStatisticsReportOrderBy.LoadedWithinStatedTime
        });
        headers.push({
          value: p ? "shareDelivered" : "delivered",
          text: "LoadStatisticsDeliveredShort",
          tooltip: "LoadStatisticsDeliveredLong",
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareDelivered
            : LoadStatisticsReportOrderBy.Delivered
        });
        headers.push({
          value: p ? "shareDeliveredUsingApp" : "deliveredUsingApp",
          text: "LoadStatisticsDeliveredUsingAppShort",
          tooltip: "LoadStatisticsDeliveredUsingAppLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareDeliveredUsingApp
            : LoadStatisticsReportOrderBy.DeliveredUsingApp
        });
        headers.push({
          value: p
            ? "shareDeliveredWithinRequestedTime"
            : "deliveredWithinRequestedTime",
          text: "LoadStatisticsDeliveredWithinReqShort",
          tooltip: "LoadStatisticsDeliveredWithinReqLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareDeliveredWithinRequestedTime
            : LoadStatisticsReportOrderBy.DeliveredWithinRequestedTime
        });
        headers.push({
          value: p ? "shareDeliveredWithStatedTime" : "deliveredWithStatedTime",
          text: "LoadStatisticsDeliveredWithStatedShort",
          tooltip: "LoadStatisticsDeliveredWithStatedLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareDeliveredWithStatedTime
            : LoadStatisticsReportOrderBy.DeliveredWithStatedTime
        });
        headers.push({
          value: p
            ? "shareDeliveredWithinStatedTime"
            : "deliveredWithinStatedTime",
          text: "LoadStatisticsDeliveredWithinStatedShort",
          tooltip: "LoadStatisticsDeliveredWithinStatedLong",
          multiLine: true,
          sortValue: p
            ? LoadStatisticsReportOrderBy.ShareDeliveredWithinStatedTime
            : LoadStatisticsReportOrderBy.DeliveredWithinStatedTime
        });
      }
      return headers;
    }
  },
  methods: {
    money: money,
    percentage: percentage,
    truckTypeName(truckType: TruckType): string {
      if (!truckType) {
        return "---";
      }
      return truckTypeName(truckType);
    },
    loadInfo(item: LoadStatisticsReportRowDto): string {
      return `${t("LoadID")}: ${item.loadId}\n${t("TransportNo")}: ${
        item.transportNo
      }`;
    },
    search() {
      this.$emit("search");
    },
    totalValue(h: string): string {
      const val = (this.reportContainer.total as any)[h] as number;
      return this.showPercentage && h !== "loaded" && h !== "offered"
        ? percentage(val, 0)
        : money(val, 0);
    },
    loadClick(loadId: number) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.Details.toString(),
          ids: `[${loadId}]`
        }
      });
    }
  }
});
