var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"no-padding",attrs:{"cols":"12","lg":"10"}},[_c('SearchCard',{attrs:{"title":_vm.$t('Users')},scopedSlots:_vm._u([{key:"head-left",fn:function(){return [_c('v-switch',{attrs:{"label":_vm.$t('ShowInactivated'),"hide-details":""},model:{value:(_vm.showInactivated),callback:function ($$v) {_vm.showInactivated=$$v},expression:"showInactivated"}})]},proxy:true},{key:"head-right",fn:function(){return [_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"no-vertical-padding small-padding-right",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"loading":_vm.exporting,"disabled":_vm.loading},on:{"click":_vm.exportToExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" "+_vm._s(_vm.$t("Export"))+" ")],1)],1),_c('v-col',{staticClass:"no-vertical-padding",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"btn-cyan","disabled":_vm.loading},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t("AddNewUser"))+" ")],1)],1)],1)]},proxy:true}])})],1),_c('v-spacer')],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"no-padding",attrs:{"cols":"12","lg":"10"}},[_c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"loading":_vm.loading,"items":_vm.filteredUsers,"height":_vm.tableHeight,"item-key":"loadUserId","sort-by":"name","footer-props":{
            'items-per-page-text': _vm.$t('Quantity') + ':',
            'items-per-page-all-text': _vm.$t('MobileAll'),
            'items-per-page-options': [-1],
            'page-text': _vm.$t('ShowingItemsXofY')
          },"hide-default-header":"","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
          var props = ref.props;
          var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.action",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{attrs:{"size":"20"},on:{"click":function($event){_vm.selectedSettings = item}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.enabled",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.enabled ? _vm.$t("Activated") : _vm.$t("Inactivated"))+" ")]}},{key:"item.userLevel",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.userLevelName(item.userLevel))+" ")]}}],null,true)}),_c('AdministrateUserDialog',{attrs:{"basicUserSettings":_vm.selectedSettings,"loadUsersForSettingsImport":_vm.loadUsersForSettingsImport},on:{"close":_vm.close,"reopen":_vm.reopen}})],1)],1),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }