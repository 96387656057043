var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"full-height",attrs:{"elevation":"1","rounded":""}},[_c('v-calendar',{ref:"calendar",attrs:{"color":"primary","interval-height":"35","interval-width":"35","type":"category","locale":_vm.locale,"events":_vm.events,"event-category":_vm.getEventCategory,"categories":_vm.categoryArray,"event-color":_vm.getEventColor,"weekday-format":_vm.weekDayText},on:{"change":_vm.getEvents},scopedSlots:_vm._u([{key:"day-label-header",fn:function(ref){
var day = ref.day;
return [_c('span',{staticClass:"dont-render-newlines"},[_c('v-btn',{attrs:{"icon":"","disabled":_vm.prevDisabled,"small":""},on:{"click":_vm.prev}},[_c('v-icon',[_vm._v(" mdi-arrow-left ")])],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"slide-y-transition","nudge-left":"130","bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"round-button",attrs:{"color":"primary","height":"40","min-width":"40","max-width":"40"}},on),[_vm._v(" "+_vm._s(day)+" ")])]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"locale":_vm.locale,"events":_vm.calenderBadDate,"show-week":"","event-color":"icon-red","first-day-of-week":"1"},on:{"input":function (v) {
                _vm.setDate(v);
                _vm.closeDate();
              }}})],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.next}},[_c('v-icon',[_vm._v(" mdi-arrow-right ")])],1)],1)]}},{key:"event",fn:function(ref){
              var event = ref.event;
return [_c('div',{staticClass:"small-padding-left",class:{ blocking: _vm.isBlocking(event) }},[(event.name)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(event.name)+", ")]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(_vm.toMoment(event.start).shortTimePrint())+" - "+_vm._s(_vm.toMoment(event.end).shortTimePrint())+" ")])])]}},{key:"day-body",fn:function(ref){
              var category = ref.category;
return [(_vm.selectedCategory && _vm.selectedCategory.name !== category)?_c('v-sheet',{staticClass:"translucent full-height"}):_vm._e()]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }