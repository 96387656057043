var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding-top",attrs:{"elevation":"2","rounded":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.augmentedAgreements,"height":_vm.tableHeight,"loading":_vm.loading,"options":_vm.tableOptions,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [100, 500, -1],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.procurement",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'withWithout'
            },"placeholder":"Upph"},model:{value:(_vm.filters.procurement),callback:function ($$v) {_vm.$set(_vm.filters, "procurement", $$v)},expression:"filters.procurement"}})]},proxy:true},{key:"filter.pickup",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location',
              pickup: true,
              cargoTypeId: _vm.criteria.cargoType
            },"placeholder":"Hämtort","value":{
              county: _vm.filters.pickupCounty,
              zipCode: _vm.filters.pickupZipCode,
              countryId: _vm.filters.pickupCountry
            }},on:{"input":function (val) {
                _vm.filters.pickupCounty = val.county;
                _vm.filters.pickupZipCode = val.zipCode;
                _vm.filters.pickupCountry = val.countryId;
              }}})]},proxy:true},{key:"filter.delivery",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location',
              pickup: false,
              cargoTypeId: _vm.criteria.cargoType
            },"placeholder":"Leveransort","value":{
              county: _vm.filters.deliveryCounty,
              zipCode: _vm.filters.deliveryZipCode,
              countryId: _vm.filters.deliveryCountry
            }},on:{"input":function (val) {
                _vm.filters.deliveryCounty = val.county;
                _vm.filters.deliveryZipCode = val.zipCode;
                _vm.filters.deliveryCountry = val.countryId;
              }}})]},proxy:true},{key:"filter.currency",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'currency' },"placeholder":_vm.$t('Currency')},model:{value:(_vm.filters.currency),callback:function ($$v) {_vm.$set(_vm.filters, "currency", $$v)},expression:"filters.currency"}})]},proxy:true}],null,true)})]}},{key:"item.edit",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.invoicePriceTooltip && !_vm.megaUserTooltip},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('span',_vm._g({},on),[_c('v-btn',{attrs:{"icon":"","x-small":"","disabled":!!_vm.invoicePriceTooltip || !!_vm.megaUserTooltip},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v(" mdi-pencil ")])],1)],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.invoicePriceTooltip)+" "+_vm._s(_vm.megaUserTooltip)+" ")])]}},{key:"item.procurement",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.procurement ? "Upph" : "Avtal")+" ")]}},{key:"item.supplierName",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.supplierName,"width":"120","clickable":""},on:{"click":function($event){return _vm.openActorDialog(item.supplierId)}}})]}},{key:"item.clientName",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.clientName,"width":"120","clickable":""},on:{"click":function($event){return _vm.openActorDialog(item.clientId)}}})]}},{key:"item.pickup",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.pickup(item),"width":"150"}})]}},{key:"item.delivery",fn:function(ref){
              var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":_vm.delivery(item),"width":"180"}})]}},{key:"item.agreementPeriod",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.agreementPeriod))])]}},{key:"item.procurementPeriod",fn:function(ref){
              var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.procurementPeriod))])]}},{key:"item.currency",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyName(item.currency))+" ")]}}],null,true)}),_c('AgreementListEditDialog',{attrs:{"agreementId":_vm.editingAgreementId,"supplierId":_vm.editingSupplierId,"agreements":_vm.editingAgreements},on:{"close":function($event){_vm.editingAgreementId = undefined},"changeSupplierId":function (id) { return (_vm.editingSupplierId = id); },"search":_vm.search}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }