













































































import Vue from "vue";
import { ActorType, RepositoryDto } from "@/scripts/cld.api";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { t } from "@/scripts/language/i18n";
import { TableHeader } from "@/scripts/types";
import { stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    SearchCard,
    CustomTableHeader,
    CustomFilter
  },
  props: {
    included: Boolean,
    actors: Array as () => RepositoryDto[],
    filter: String
  },
  computed: {
    title(): string {
      let title = "";
      if (this.included) {
        title =
          this.actorType === ActorType.Client
            ? t("IncludedSuppliers")
            : t("IncludedClients");
      } else {
        title =
          this.actorType === ActorType.Client
            ? t("ExcludedSuppliers")
            : t("ExcludedClients");
      }
      return `${title} (${this.actors.length})`;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    filteredActors(): RepositoryDto[] {
      return this.actors.filter(a =>
        stringSearchHit(this.filter, a.text, false)
      );
    },
    headers(): TableHeader[] {
      return [
        {
          value: "include",
          text: "",
          width: 1,
          untranslated: true
        },
        {
          value: "text",
          text: "Supplier"
        },
        {
          value: "exclude",
          text: "",
          width: 1,
          untranslated: true
        }
      ].filter(h => h.value !== (this.included ? "include" : "exclude"));
    }
  }
});
