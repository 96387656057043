



















































import Vue from "vue";
import DashboardLoads from "@/components/web/dashboard/DashboardLoads.vue";
import DashboardContainers from "@/components/web/dashboard/DashboardContainers.vue";
import DashboardRelatedLoads from "@/components/web/dashboard/DashboardRelatedLoads.vue";
import DashboardProcurements from "@/components/web/dashboard/DashboardProcurements.vue";
import DashboardSpotTenders from "@/components/web/dashboard/DashboardSpotTenders.vue";
import DashboardNews from "@/components/web/dashboard/DashboardNews.vue";
import { ActorType } from "@/scripts/cld.api";
import { gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
export default Vue.extend({
  components: {
    DashboardLoads,
    DashboardContainers,
    DashboardRelatedLoads,
    DashboardProcurements,
    DashboardSpotTenders,
    DashboardNews
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    isCustomer(): boolean {
      return this.actorType === ActorType.Customer;
    },
    isTerminal(): boolean {
      return this.actorType === ActorType.Terminal;
    }
  },
  data: (): { secondsLeft: number } => ({
    secondsLeft:
      new MomentX("2023-03-27 07:30:00").unix() - new MomentX().unix()
  })
});
