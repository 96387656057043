





























































import Vue from "vue";
import {
  ApiConfiguration,
  DeliverLoadDto,
  LoadActionPermission,
  LoadsClient,
  LoadValuePermission,
  LoadValuePermissionDto,
  TruckType
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import DatePickerHolder from "@/components/shared/ui/DatePickerHolder.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import { t } from "@/scripts/language/i18n";
import { MultiLoad } from "@/scripts/types";

export default Vue.extend({
  components: {
    DatePickerHolder,
    DialogCard,
    DatePicker,
    NumberInput,
    LoadLock,
    LoadChips
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    distanceEnabled(): boolean {
      return this.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.Distance
      )!.enabled;
    },
    deliverDateTooEarlyErrorMessage(): string {
      return (
        t("DeliverDateMustBeLaterThanLoadedDate") +
        " " +
        this.loadedDate!.datePrint()
      );
    },
    loadId(): number {
      return this.loadIds[0]!;
    }
  },
  methods: {
    deliver() {
      this.deliveryInProgress = true;
      new LoadsClient(new ApiConfiguration(this.$store))
        .deliver(this.saveDto!)
        .then(res => {
          pushLoadNotification("deliver", res);
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    close() {
      this.$emit("close");
    },
    init() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .preDeliverDetails(this.loadId)
        .then(res => {
          this.saveDto = new DeliverLoadDto({
            loadId: this.loadId,
            deliverDate: new MomentX(),
            distance: res.distance
          });
          this.routeName = res.routeName;
          this.truckType = res.truckType;
          this.transportNo = res.transportNo;
          this.deliveryEarliest = res.deliveryEarliest;
          this.deliveryLatest = res.deliveryLatest;
          this.loadPermissions = res.permissions!;
          this.loadedDate = res.loadedDate;

          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: new MomentX(),
            deliverLatest: res.deliveryLatest,
            truckType: res.truckType,
            transportNo: res.transportNo,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: res.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          };
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    valid: boolean;
    load: MultiLoad | undefined;
    deliveryInProgress: boolean;
    loadPermissions: LoadValuePermissionDto[];
    saveDto?: DeliverLoadDto;
    routeName?: string;
    truckType?: TruckType;
    transportNo?: string;
    deliveryEarliest?: MomentX;
    deliveryLatest?: MomentX;
    loadedDate?: MomentX;
  } => ({
    valid: false,
    load: undefined,
    deliveryInProgress: false,
    loadPermissions: [],
    saveDto: undefined,
    routeName: undefined,
    truckType: undefined,
    transportNo: undefined,
    deliveryEarliest: undefined,
    deliveryLatest: undefined,
    loadedDate: undefined
  })
});
