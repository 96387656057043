


















































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import UserSettingsForm from "@/components/web/settings/UserSettingsForm.vue";
import ImportSettingsDialog from "@/components/web/settings/ImportSettingsDialog.vue";
import {
  ActorType,
  ApiConfiguration,
  BasicUserSettingsDto,
  NotificationSettingsDto,
  RepositoryDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    DialogCard,
    UserSettingsForm,
    ImportSettingsDialog
  },
  props: {
    basicUserSettings: Object as () => BasicUserSettingsDto | undefined,
    loadUsersForSettingsImport: Array as () => RepositoryDto[]
  },
  computed: {
    title(): string {
      let title = t("Settings");
      if (this.basicUserSettings && this.basicUserSettings.username) {
        title += " (" + this.basicUserSettings.username + ")";
      }
      return title;
    },
    loading(): boolean {
      if (!this.basicUserSettings) {
        return true;
      }
      return (
        this.basicUserSettings.loadUserId !== 0 && !this.notificationSettings
      );
    },
    pendingChanges(): boolean {
      return (
        this.originalBasicUserSettings !==
          JSON.stringify(this.basicUserSettings) ||
        this.originalNotificationSettings !==
          JSON.stringify(this.notificationSettings)
      );
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    }
  },
  watch: {
    basicUserSettings: {
      handler() {
        if (this.basicUserSettings) {
          this.init();
        }
      },
      immediate: true
    }
  },
  methods: {
    fetchNotificationSettings(loadUserId: number) {
      new UsersClient(new ApiConfiguration(this.$store))
        .notificationSettings(loadUserId)
        .then(res => {
          this.notificationSettings = res;
          this.originalNotificationSettings = JSON.stringify(
            this.notificationSettings
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .saveBasicUserSettings(this.basicUserSettings!)
        .then(() => {
          if (this.basicUserSettings!.loadUserId === 0) {
            this.saveInProgress = false;
            this.reopen();
          } else {
            this.saveNotifications();
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    saveNotifications() {
      new UsersClient(new ApiConfiguration(this.$store))
        .saveNotificationSettings(this.notificationSettings!)
        .then(() => {
          this.saveInProgress = false;
          this.init();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.originalBasicUserSettings = JSON.stringify(this.basicUserSettings);
      this.notificationSettings = undefined;
      if (this.basicUserSettings!.loadUserId) {
        this.fetchNotificationSettings(this.basicUserSettings!.loadUserId);
      }
    },
    importNotificationSettings(settings: NotificationSettingsDto) {
      settings.loadUserId = this.notificationSettings!.loadUserId;
      this.notificationSettings = settings;
    },
    close() {
      this.$emit("close");
    },
    reopen() {
      this.$emit("reopen");
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    importSettingsDialog: boolean;
    originalBasicUserSettings: string;
    notificationSettings: NotificationSettingsDto | undefined;
    originalNotificationSettings: string;
  } => ({
    valid: false,
    saveInProgress: false,
    importSettingsDialog: false,
    originalBasicUserSettings: "",
    notificationSettings: undefined,
    originalNotificationSettings: ""
  })
});
