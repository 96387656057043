var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"mobile-breakpoint":0,"loading":_vm.loading,"items":_vm.filteredActors,"height":_vm.tableHeight,"custom-sort":_vm.customSort,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [50],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"sort-by":"signingStarted","sort-desc":"","hide-default-header":"","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.actorName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Search')},model:{value:(_vm.actorNameFilter),callback:function ($$v) {_vm.actorNameFilter=$$v},expression:"actorNameFilter"}})]},proxy:true},{key:"filter.active",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'activity' },"placeholder":_vm.$t('Status')},model:{value:(_vm.activeFilter),callback:function ($$v) {_vm.activeFilter=$$v},expression:"activeFilter"}})]},proxy:true},{key:"filter.contractManagerName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Search')},model:{value:(_vm.cNameFilter),callback:function ($$v) {_vm.cNameFilter=$$v},expression:"cNameFilter"}})]},proxy:true},{key:"filter.contractManagerEmail",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Search')},model:{value:(_vm.cEmailFilter),callback:function ($$v) {_vm.cEmailFilter=$$v},expression:"cEmailFilter"}})]},proxy:true},{key:"filter.contractManagerPhone",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Search')},model:{value:(_vm.cPhoneFilter),callback:function ($$v) {_vm.cPhoneFilter=$$v},expression:"cPhoneFilter"}})]},proxy:true},{key:"filter.status",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' },"placeholder":_vm.$t('Search')},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})]},proxy:true}],null,true)})]}},{key:"item.edit",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"size":"20"},on:{"click":function($event){return _vm.editActor(item.id)}}},[_vm._v("mdi-pencil")])]}},{key:"item.actorName",fn:function(ref){
    var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.actorName,"width":"200"}})]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('span',{class:item.active ? 'green-text' : 'red-text'},[_vm._v(_vm._s(item.active ? "Aktiv" : "Inaktiv"))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.status(item))+" ")]}},{key:"item.action1",fn:function(ref){
    var item = ref.item;
return [(_vm.actionInProgress === item.id)?_c('div',{staticClass:"spin"},[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-biohazard ")])],1):(_vm.signingInProgress(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"error","disabled":_vm.actionInProgress !== 0},on:{"click":function($event){return _vm.terminate(item)}}},on),[_vm._v(" mdi-trash-can-outline ")])]}}],null,true)},[_c('span',[_vm._v("Avbryt signering")])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"22","color":"success","disabled":_vm.actionInProgress !== 0},on:{"click":function($event){return _vm.init(item)}}},on),[_vm._v(" mdi-play ")])]}}],null,true)},[_c('span',[_vm._v("Starta signering")])])]}},{key:"item.action2",fn:function(ref){
    var item = ref.item;
return [(_vm.signingInProgress(item) && !_vm.actionInProgress)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"warning","disabled":_vm.actionInProgress !== 0},on:{"click":function($event){return _vm.sendReminder(item)}}},on),[_vm._v(" mdi-email-sync-outline ")])]}}],null,true)},[_c('span',[_vm._v("Skicka påminnelse")])]):(_vm.isSigned(item))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"tiny-padding-left",attrs:{"size":"18","color":"grey darken-3"}},on),[_vm._v(" mdi-file-sign ")])]}}],null,true)},[_c('span',[_vm._v("Avtal signerat")])]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }