





































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import {
  AgreementRouteDistanceSearchCriteria,
  ApiConfiguration,
  DistanceClient,
  UserConfigurationType,
  AgreementRouteDistanceOrderBy,
  AgreementRouteDistanceSearchCriteriaContainer,
  AgreementRouteDistanceContainerDto,
  RoutingExactness
} from "@/scripts/cld.api";
import DistanceTable from "@/components/support-admin/DistanceTable.vue";
import { actions, gets } from "@/scripts/store/constants";
import { TableOptions } from "@/scripts/types";

enum UpdateRes {
  Waiting,
  Fail,
  Changed,
  Unchanged
}

export interface UpdatingRoute {
  id: number;
  distance: number | undefined;
  res: UpdateRes;
  color: string;
}

export default Vue.extend({
  components: {
    SearchCard,
    DistanceTable
  },
  computed: {
    searchCriteria(): AgreementRouteDistanceSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.Distance,
        1
      );
    },
    sorting(): {
      orderBy: AgreementRouteDistanceOrderBy;
      orderByDesc: boolean;
    } {
      return {
        orderBy: this.tableOptions.sortBy[0] as AgreementRouteDistanceOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    finishedUpdating(): number {
      return this.updating.filter(x => x.res !== UpdateRes.Waiting).length;
    }
  },
  methods: {
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search();
    },
    toggle(id: number) {
      if (this.toggled.includes(id)) {
        this.toggled = this.toggled.filter(x => x !== id);
      } else {
        this.toggled.push(id);
      }
    },
    toggleAll(state: boolean | undefined) {
      if (state) {
        this.toggled = [];
      } else {
        this.toggled = this.routesContainer.rows!.map(r => r.id);
      }
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.Distance,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [
            AgreementRouteDistanceOrderBy.PickupLocation
          ];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search() {
      this.loading = true;
      new DistanceClient(new ApiConfiguration(this.$store))
        .list(
          new AgreementRouteDistanceSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          this.routesContainer = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    async update() {
      this.updating = this.toggled.map(id => ({
        id: id,
        distance: this.routesContainer.rows!.find(r => r.id === id)!.distance,
        res: UpdateRes.Waiting,
        color: ""
      }));
      this.toggled = [];
      for (let u of this.updating) {
        const route = this.routesContainer.rows!.find(r => r.id === u.id)!;
        await new DistanceClient(new ApiConfiguration(this.$store))
          .update(route)
          .then(res => {
            if (res.routingExactnessPickup === RoutingExactness.Fail) {
              u.res = UpdateRes.Fail;
              u.color = "red-row";
            } else if (
              res.distance !== route.distance ||
              res.routingExactnessPickup !== route.routingExactnessPickup ||
              res.routingExactnessDelivery !== route.routingExactnessDelivery
            ) {
              u.res = UpdateRes.Changed;
              u.color = "green-row";
              route.distance = res.distance;
              route.routingExactnessPickup = res.routingExactnessPickup;
              route.routingExactnessDelivery = res.routingExactnessDelivery;
            } else {
              u.res = UpdateRes.Unchanged;
              u.color = "blue-row";
            }
          })
          .catch(error => {
            this.$store.dispatch(actions.handleApiError, error);
          });
      }
    }
  },
  data: (): {
    loading: boolean;
    updating: UpdatingRoute[];
    toggled: number[];
    routesContainer: AgreementRouteDistanceContainerDto;
    tableOptions: TableOptions;
  } => ({
    loading: true,
    updating: [],
    toggled: [],
    routesContainer: new AgreementRouteDistanceContainerDto({
      rows: [],
      count: 0
    }),
    tableOptions: new TableOptions({
      itemsPerPage: 500,
      sortBy: [AgreementRouteDistanceOrderBy.PickupLocation],
      sortDesc: [false]
    })
  })
});
