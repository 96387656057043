








import Vue from "vue";
import { CargoType, TruckType } from "@/scripts/cld.api";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";

export default Vue.extend({
  components: {
    CheckboxTiny
  },
  props: {
    cargoType: Number as () => CargoType,
    truckTypes: Array as () => TruckType[],
    indeterminates: Array as () => TruckType[]
  },
  computed: {
    visibleTruckTypes(): TruckType[] {
      return truckTypesByCargoType(this.cargoType).map(
        tt => tt.id as TruckType
      );
    }
  },
  methods: {
    state(tt: TruckType): boolean | undefined {
      return this.indeterminates.includes(tt)
        ? undefined
        : this.truckTypes.includes(tt);
    }
  }
});
