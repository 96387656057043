
























































































import Vue from "vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import DashboardNewsBox from "@/components/web/dashboard/DashboardNewsBox.vue";
import NewsSheet from "@/components/shared/ui/NewsSheet.vue";
import {
  ApiConfiguration,
  Culture,
  NewsClient,
  NewsDto,
  NewsSupportAdminDto,
  NewsRowDto,
  RepositoryDto,
  FileParameter,
  FilesClient,
  DocumentsClient,
  SaveDiskFileToDocumentDto
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import { actorTypes, cultures } from "@/scripts/misc/enumLists";
import { actions } from "@/scripts/store/constants";
import FileInput from "@/components/shared/input/FileInput.vue";
import NewsHelpIcon from "@/components/support-admin/news/NewsHelpIcon.vue";

export default Vue.extend({
  components: {
    TextMulti,
    DatePicker,
    DropdownMulti,
    DashboardNewsBox,
    NewsSheet,
    FileInput,
    NewsHelpIcon
  },
  computed: {
    dashboardItem(): NewsDto {
      return new NewsDto({
        displayDate: this.newsDto.displayDate,
        summary: this.newsRowDto.summary,
        body: "",
        read: false
      });
    },
    actorTypes(): RepositoryDto[] {
      return actorTypes();
    },
    newsRowDto(): NewsRowDto {
      return this.newsDto.rows!.find(r => r.culture === this.culture)!;
    },
    cultures(): RepositoryDto[] {
      return cultures();
    }
  },
  methods: {
    getTextSelection(): [number, number] {
      const textarea = document.getElementsByTagName("textarea")[1];
      return [textarea.selectionStart, textarea.selectionEnd];
    },
    insertFormatting(formatting: string) {
      const [start, end] = this.getTextSelection();
      if (!this.newsRowDto) {
        return;
      }
      if (!this.newsRowDto.body) {
        this.newsRowDto.body = "";
      }
      if (end !== start) {
        this.newsRowDto.body =
          this.newsRowDto.body.slice(0, end) +
          "\\\\" +
          this.newsRowDto.body.slice(end);
      }
      this.newsRowDto.body =
        this.newsRowDto.body.slice(0, start) +
        formatting +
        this.newsRowDto.body.slice(start);
    },
    hasData(c: Culture): boolean {
      return this.newsDto.rows!.some(
        r => r.culture === c && (!!r.body || !!r.summary)
      );
    },
    save() {
      this.saveInProgress = true;
      this.newsDto.rows = this.newsDto.rows!.filter(r =>
        this.hasData(r.culture)
      );
      new NewsClient(new ApiConfiguration(this.$store))
        .save(this.newsDto)
        .then(() => {
          this.saveInProgress = false;
          this.$router.push("/support-admin/news");
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    createExample(): NewsSupportAdminDto {
      return new NewsSupportAdminDto({
        id: 0,
        displayDate: new MomentX().startOfDay(),
        visibleDate: undefined,
        actorTypes: [],
        rows: [
          new NewsRowDto({
            culture: Culture.Swedish,
            summary: "Skriv sammanfattning här och välj aktörstyp(er)",
            body:
              "1. Lägg in svensk text här\n" +
              "2. \\bKlicka sedan på engelska för att skriva engelsk sammanfattning och brödtext.\n" +
              "3. Slutligen klickar du på spara\n\n" +
              "\\iNyheten börjar som dold och måste manuellt göras synlig innan användare kan ta del av den.\n" +
              "\\iDu kan markera text och klicka på knapparna ovan för att formattera den.\n" +
              "\\iHovra över frågetecknet uppe till vänster för mer hjälp."
          })
        ]
      });
    },
    uploadFile(file: FileParameter) {
      new FilesClient(new ApiConfiguration(this.$store))
        .upload(file as any)
        .then(diskName => {
          return new DocumentsClient(new ApiConfiguration(this.$store))
            .saveToNewsGroup(
              new SaveDiskFileToDocumentDto({
                diskName: diskName,
                displayName: file.fileName
              })
            )
            .then(() => {
              const [start, _] = this.getTextSelection();
              if (!this.newsRowDto.body) {
                this.newsRowDto.body = "";
              }
              this.newsRowDto.body =
                this.newsRowDto.body.slice(0, start) +
                "\\p" +
                diskName +
                "\n" +
                this.newsRowDto.body.slice(start);
            });
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  async mounted() {
    const id = Number(this.$route.query.id);
    let news: NewsSupportAdminDto[] = [];
    if (!isNaN(id) && id > 0) {
      await new NewsClient(new ApiConfiguration(this.$store))
        .list()
        .then(res => {
          news = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
    this.newsDto = news.find(n => n.id === id) as any;
    if (!this.newsDto) {
      this.newsDto = this.createExample();
    }
    const missingCultures = this.cultures
      .map(c => c.id)
      .filter(c => !this.newsDto.rows!.map(r => r.culture).includes(c));
    this.newsDto.rows!.push(
      ...missingCultures.map(
        c =>
          new NewsRowDto({
            culture: c,
            summary: "",
            body: ""
          })
      )
    );
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    newsDto: NewsSupportAdminDto;
    culture: Culture;
  } => ({
    valid: false,
    saveInProgress: false,
    newsDto: undefined as any,
    culture: Culture.Swedish
  })
});
