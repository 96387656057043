

















import Vue from "vue";
import { CargoType } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    escortCarPrice: Number,
    vtlPrice: Number,
    cargoType: Number as () => CargoType
  },
  components: {
    ReadOnlyText,
    GreyTitle
  },
  computed: {
    visible(): boolean {
      return this.cargoType === CargoType.MachineTransport;
    }
  }
});
