





















































































































import Vue from "vue";
import {
  ActorType,
  SubjectiveQPDto,
  SubjectiveQPParameter
} from "@/scripts/cld.api";
import Spinner from "@/components/shared/ui/Spinner.vue";
import SurveyDialog from "@/components/web/survey/client/SurveyDialog.vue";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    Spinner,
    SurveyDialog
  },
  props: {
    qualityPoints: Array as () => SubjectiveQPDto[],
    changed: Array as () => { id: number; changed: boolean }[],
    supplierId: Number,
    loading: Boolean
  },
  watch: {
    qualityPoints: {
      handler() {
        this.calculateMaxPoints();
        this.calculateTotalPoints();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    surveyParameterId() {
      return SubjectiveQPParameter.SustainabilitySurvey;
    },
    fairTransportParameterId() {
      return SubjectiveQPParameter.FairTransport;
    },
    isClient(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    }
  },
  methods: {
    showLeaf(item: SubjectiveQPDto): boolean {
      return (
        this.isClient &&
        item.parameter === this.surveyParameterId &&
        !!item.points
      );
    },
    showLogo(item: SubjectiveQPDto): boolean {
      return (
        this.isClient &&
        item.parameter === this.fairTransportParameterId &&
        !!item.points
      );
    },
    rules(item: SubjectiveQPDto, value?: number): boolean | string {
      let valid =
        value !== undefined && value >= item.minValue && value <= item.maxValue;
      return valid;
    },
    changeValue(valueString: string, item: SubjectiveQPDto) {
      let value = Number(valueString);
      if (value > item.maxValue) {
        value = item.maxValue;
      } else if (value < item.minValue) {
        value = item.minValue;
      }
      if (value !== item.points) {
        this.changed.filter(x => x.id === item.parameter)[0].changed = true;
      }
      item.points = -999999;
      this.$nextTick(() => {
        item.points = value;
        this.calculateTotalPoints();
      });
    },
    calculateTotalPoints() {
      this.total = 0;
      for (let row of this.qualityPoints) {
        if (row.points) {
          this.total = Number(this.total) + Number(row.points);
        }
      }
    },
    calculateMaxPoints() {
      this.max = 0;
      for (let row of this.qualityPoints) {
        if (row.maxValue) {
          this.max += row.maxValue;
        }
      }
    }
  },
  data: (): {
    surveySupplierId?: number;
    max: number;
    total: number;
    ftLogo: any;
  } => ({
    surveySupplierId: undefined,
    max: 0,
    total: 0,
    ftLogo: require("@/assets/img/fair-transport-logo.png")
  })
});
