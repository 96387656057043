



























































import Vue from "vue";
import {
  ApiConfiguration,
  ProcurementsClientClient,
  AgreementsClientClient,
  ClientAgreementSearchCriteria,
  RankRequest,
  RepositoryDto,
  ProcurementSupplierRemoveDto,
  UserConfigurationType,
  ActorType
} from "@/scripts/cld.api";
import { AgreementDataDtoX, TableOptions } from "@/scripts/types";
import { ble } from "@/scripts/misc/apiErrors";
import { popupDialog, errorDialog } from "@/scripts/misc/popupDialogs";
import AgreementSearch from "@/components/web/agreements/client/AgreementSearch.vue";
import ProcurementTable from "@/components/web/agreements/client/ProcurementTable.vue";
import ProcurementActions from "@/components/web/agreements/client/ProcurementActions.vue";
import RankingDialog from "@/components/web/agreements/client/RankingDialog.vue";
import NewProcurementPeriodDialog from "@/components/web/agreements/client/NewProcurementPeriodDialog.vue";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    AgreementSearch,
    ProcurementTable,
    ProcurementActions,
    RankingDialog,
    NewProcurementPeriodDialog
  },
  watch: {
    "$route.query.cargoType": {
      handler(newVal) {
        this.cargoTypeId = Number(newVal);
        this.search();
      },
      immediate: true
    }
  },
  computed: {
    selectedAgreementIds() {
      return this.selectedAgreements.map(a => a.id);
    },
    searchCriteria(): ClientAgreementSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.ClientProcurement,
        this.cargoTypeId
      );
    }
  },
  methods: {
    search() {
      this.loadingText = "PleaseWait";
      this.dirtyTable = false;
      this.agreements = [];
      this.expandedAgreements = [];
      this.selectedAgreements = [];
      this.selectedAgreementIndex = -1;
      const unique = nextUnique();
      this.loading = unique;
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .bySearchCriteria(
          new ClientAgreementSearchCriteria(
            this.$store.getters[gets.userConf](
              UserConfigurationType.ClientProcurement,
              this.cargoTypeId
            )
          )
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.agreements = res.map(r => new AgreementDataDtoX(r));
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.ClientProcurement,
        subTypeId: this.cargoTypeId
      });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      scrollTableToTop(this);
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.ClientProcurement,
          subTypeId: this.cargoTypeId,
          actorType: ActorType.Client
        })
        .then(() => {
          this.tableOptions.sortBy = ["pickupLocation"];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    agreementExpanded(agreement: AgreementDataDtoX) {
      let index = this.expandedAgreements.indexOf(agreement);
      if (index === -1) {
        this.expandedAgreements.push(agreement);
      } else {
        this.expandedAgreements.splice(index, 1);
      }
      if (
        index === 1 ||
        agreement.truckTypes === undefined ||
        agreement.truckTypes.length > 0
      ) {
        return;
      }
      new AgreementsClientClient(new ApiConfiguration(this.$store))
        .trucktypesByAgreementId(
          agreement.id,
          this.searchCriteria.truckType || null,
          this.searchCriteria.unitBasePriceDate
        )
        .then(res => {
          if (res.length > 0) {
            agreement.truckTypes = res;
          } else {
            agreement.truckTypes = undefined;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    selectedAgreementsChanged(
      selected: boolean,
      agreements: AgreementDataDtoX[]
    ) {
      if (selected) {
        this.selectedAgreements = this.selectedAgreements.concat(agreements);
      } else {
        this.selectedAgreements = this.selectedAgreements.filter(
          a => !agreements.find(x => x === a)
        );
      }
      this.selectedAgreements = this.selectedAgreements.filter(
        a => a.selectable
      );
    },
    changeDialogAgreement(forward: boolean) {
      if (
        forward &&
        this.selectedAgreementIndex < this.selectedAgreements.length - 1
      ) {
        this.selectedAgreementIndex++;
      } else if (!forward && this.selectedAgreementIndex > 0) {
        this.selectedAgreementIndex--;
      }
    },
    removeAgreementFromSelection() {
      this.dirtyTable = true;
      this.selectedAgreements.splice(this.selectedAgreementIndex, 1);
      let newIndex = Math.min(
        this.selectedAgreementIndex,
        this.selectedAgreements.length - 1
      );
      this.selectedAgreementIndex = -1;
      this.$nextTick(() => {
        this.selectedAgreementIndex = newIndex;
        if (this.selectedAgreementIndex === -1) {
          this.closeRankingDialog();
        }
      });
    },
    openRankingDialog(agreementId?: number) {
      if (agreementId !== undefined) {
        this.selectedAgreements = this.agreements.filter(
          a => a.id === agreementId
        );
      }
      this.selectedAgreementIndex = 0;
      this.rankingDialog = true;
    },
    closeRankingDialog() {
      this.rankingDialog = false;
      this.selectedAgreements = [];
      this.selectedAgreementIndex = -1;
      if (this.dirtyTable) {
        this.search();
      }
    },
    openNewProcurementPeriodDialog() {
      this.newProcurementPeriodDialog = true;
    },
    closeNewProcurementPeriodDialog(reload: boolean) {
      this.newProcurementPeriodDialog = false;
      if (reload) {
        this.search();
      }
    },
    rankByQP() {
      this.loading = 1;
      this.agreements = [];
      this.loadingText = "QualityRanking";
      let agreementIds = this.selectedAgreementIds;
      this.selectedAgreements = [];
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .rankByQualitypoints(
          new RankRequest({
            agreementIds: agreementIds
          })
        )
        .then(() => {
          this.search();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    rankByPrice() {
      this.loading = 1;
      this.agreements = [];
      this.loadingText = "PriceRanking";
      let agreementIds = this.selectedAgreementIds;
      this.selectedAgreements = [];
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .rankByPrice(
          new RankRequest({
            agreementIds: agreementIds
          })
        )
        .then(() => {
          this.search();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    createAgreement() {
      popupDialog({
        title: this.$t("CreateAgreement").toString(),
        body: this.$t("CreateAgreementDialogText").toString(),
        btnCallback1: this.createAgreementCallback
      });
    },
    createAgreementCallback() {
      this.loading = 1;
      this.agreements = [];
      this.loadingText = "CreateAgreement";
      let agreementIds = this.selectedAgreementIds;
      this.selectedAgreements = [];
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .finishProcurement(agreementIds)
        .then(res => {
          this.search();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.search();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    removeSupplier(supplier: RepositoryDto) {
      popupDialog({
        title: this.$t("DeleteSupplier").toString(),
        body: `${this.$t("DeleteSupplierDialogText").toString()} ${
          supplier.text
        }?`,
        btnText1: this.$t("Delete").toString(),
        btnColor1: "error",
        btnCallback1: () => {
          this.removeSupplierCallback(supplier.id);
        }
      });
    },
    removeSupplierCallback(supplierId: number) {
      this.loading = 1;
      this.agreements = [];
      this.loadingText = "DeleteSupplier";
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .removeSupplier(
          new ProcurementSupplierRemoveDto({
            supplierId: supplierId,
            agreementId: this.selectedAgreementIds
          })
        )
        .then(res => {
          this.search();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    new AgreementsClientClient(new ApiConfiguration(this.$store))
      .highestRank()
      .then(res => {
        this.maxRanking = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });

    new ProcurementsClientClient(new ApiConfiguration(this.$store))
      .suppliersInProcurements()
      .then(res => {
        res.sort((r1, r2) => {
          return (r1.text as String).localeCompare(r2.text!);
        });
        this.suppliers = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    cargoTypeId: number;
    rankingDialog: boolean;
    newProcurementPeriodDialog: boolean;
    dirtyTable: boolean;
    loading: number;
    loadingText: string;
    expandedAgreements: AgreementDataDtoX[];
    selectedAgreements: AgreementDataDtoX[];
    selectedAgreementIndex: number;
    maxRanking: number;
    suppliers: RepositoryDto[];
    agreements: AgreementDataDtoX[];
    tableOptions: TableOptions;
  } => ({
    cargoTypeId: NaN,
    rankingDialog: false,
    newProcurementPeriodDialog: false,
    dirtyTable: false,
    loading: 0,
    loadingText: "",
    expandedAgreements: [],
    selectedAgreements: [],
    selectedAgreementIndex: 0,
    maxRanking: 99,
    suppliers: [],
    agreements: [],
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: ["pickupLocation"],
      sortDesc: [false]
    })
  })
});
