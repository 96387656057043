var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"675"}},[_c('v-data-table',{attrs:{"headers":_vm.historyHeaders,"items":_vm.historyItems,"loading":_vm.loading,"custom-sort":_vm.customSort,"mobile-breakpoint":0,"items-per-page":12,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [12],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"height":"624"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.date.dateTimePrint())+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }