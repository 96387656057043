

























































































































import Vue from "vue";
import SlotCalendarHeader from "@/components/web/slots/SlotCalendarHeader.vue";
import SlotCalendarDay from "@/components/web/slots/SlotCalendarDay.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import InfoMenu from "@/components/shared/ui/InfoMenu.vue";
import {
  CargoLoadingContainer,
  InfoMenuObject,
  LoadingDockSchedule,
  LoadingSlotBox
} from "@/scripts/types";
import {
  LoadingDockDto,
  LoadingSlotDto,
  LoadingSlotType
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SlotCalendarHeader,
    SlotCalendarDay,
    ReadOnlyText,
    InfoMenu
  },
  props: {
    loadingDocks: Array as () => LoadingDockDto[],
    loading: Boolean,
    readonly: Boolean,
    selectedCargoLoadingContainer: Object as () =>
      | CargoLoadingContainer
      | undefined,
    windowStart: MomentX,
    windowEnd: MomentX
  },
  watch: {
    loadingDocks: {
      handler() {
        if (this.loadingDocks.length === 0) {
          return;
        }
        this.infoMenu.visible = false;
        this.selectedBox = undefined;
        this.deleteInProgress = false;
        this.populateDockSchedules();
      },
      immediate: true
    }
  },
  computed: {
    boxesNeededForBooking(): number {
      if (this.readonly) {
        return 1;
      }
      return (
        (this.selectedCargoLoadingContainer!.cargoLoading.seconds +
          this.selectedCargoLoadingContainer!.extraSeconds) /
        this.secondsPerBox
      );
    }
  },
  methods: {
    loadingDockDisabled(schedule: LoadingDockSchedule): boolean {
      if (this.readonly) {
        return false;
      }
      return !this.selectedCargoLoadingContainer!.cargoLoading.loadingDockIds!.includes(
        schedule.id
      );
    },
    boxStyle(seconds: number): string {
      return `width:calc(1.2% * ${seconds /
        this.secondsPerBox} - 1px * ${seconds / this.secondsPerBox})`;
    },
    boxColor(slot: LoadingSlotDto): string {
      if (slot.type === LoadingSlotType.Booking) {
        return "card-cyan";
      }
      return "icon-gold lighten-1";
    },
    asTon(kilograms: number): string {
      return new Intl.NumberFormat(localeCode(), {
        maximumFractionDigits: 1,
        minimumFractionDigits: 0
      }).format(kilograms / 1000);
    },
    tonIcon(box: LoadingSlotBox): string {
      return box.kilogramsBefore! > box.kilogramsAfter!
        ? "mdi-arrow-bottom-right"
        : "mdi-arrow-top-right";
    },
    populateDockSchedules(): void {
      this.dockSchedules = [];
      for (const dock of this.loadingDocks) {
        const boxes: LoadingSlotBox[] = [];
        let time = this.windowStart;
        const earlySlot = dock.loadingSlots!.find(
          s => s.startDate.isBefore(time) && s.endDate.isAfter(time)
        );
        if (earlySlot) {
          time = time.addSeconds(this.addBusyBox(earlySlot, boxes));
        }
        while (time.isBefore(this.windowEnd)) {
          const slot = dock.loadingSlots!.find(s => s.startDate.isSame(time));
          if (slot) {
            time = time.addSeconds(this.addBusyBox(slot, boxes));
          } else {
            if (boxes.slice(-1).some(b => b.type === LoadingSlotType.Open)) {
              boxes.slice(-1)[0].roundedRight = false;
            }
            time = time.addSeconds(this.addEmptyBox(time, boxes));
          }
        }
        this.dockSchedules.push({
          id: dock.id,
          name: dock.name!,
          boxes: boxes
        });
      }
    },
    addBusyBox(slot: LoadingSlotDto, boxes: LoadingSlotBox[]): number {
      const startDateWithinWindow =
        slot.startDate >= this.windowStart ? slot.startDate : this.windowStart;
      const endDateWithinWindow =
        slot.endDate < this.windowEnd ? slot.endDate : this.windowEnd;
      const seconds = endDateWithinWindow.unix() - startDateWithinWindow.unix();
      boxes.push({
        id: slot.id,
        cargoLoadingId: slot.cargoLoadingId,
        type: slot.type,
        seconds: seconds,
        startDate: slot.startDate,
        endDate: slot.endDate,
        hovered: false,
        color: this.boxColor(slot),
        kilograms: slot.kilograms,
        kilogramsBefore: slot.kilogramsBefore,
        kilogramsAfter: slot.kilogramsAfter,
        roundedLeft: true,
        roundedRight: true,
        loadId: slot.loadId,
        truckNo: slot.truckNo,
        transportNo: slot.transportNo,
        bookingNo: slot.bookingNo,
        note: slot.note
      });
      return seconds;
    },
    addEmptyBox(time: MomentX, boxes: LoadingSlotBox[]): number {
      boxes.push({
        id: -1,
        type: LoadingSlotType.Open,
        seconds: this.secondsPerBox,
        startDate: time,
        endDate: time.addSeconds(this.secondsPerBox),
        hovered: false,
        color: undefined,
        roundedLeft: !boxes
          .slice(-1)
          .some(b => b.type === LoadingSlotType.Open),
        roundedRight: true
      });
      return this.secondsPerBox;
    },
    boxClicked(
      x: number,
      y: number,
      loadingDockId: number,
      startDate: MomentX,
      endDate: MomentX,
      box: LoadingSlotBox,
      error: boolean
    ) {
      if (this.readonly && box.type !== LoadingSlotType.Open) {
        this.infoMenu.visible = true;
        this.infoMenu.x = x;
        this.infoMenu.y = y - 20;
        this.selectedBox = box;
      } else if (!error && !this.readonly) {
        this.$emit("createBooking", loadingDockId, startDate, endDate);
      }
    },
    editBooking(box: LoadingSlotBox | undefined) {
      if (!box) {
        return;
      }
      this.$emit("editBooking", box.loadId);
    },
    deleteBooking(box: LoadingSlotBox | undefined) {
      if (!box) {
        return;
      }
      this.deleteInProgress = true;
      this.$emit("deleteBooking", box.cargoLoadingId);
    },
    isManualBlocking(box: LoadingSlotBox): boolean {
      return box.type === LoadingSlotType.ManualBlocking;
    },
    infoTitle(box: LoadingSlotBox | undefined): string {
      if (!box) {
        return "";
      }
      return `${box.startDate.shortTimePrint()} - ${box.endDate.shortTimePrint()}`;
    }
  },
  data: (): {
    dockSchedules: LoadingDockSchedule[];
    secondsPerBox: number;
    infoMenu: InfoMenuObject;
    selectedBox?: LoadingSlotBox;
    deleteInProgress: boolean;
  } => ({
    dockSchedules: [],
    secondsPerBox: 300,
    infoMenu: {
      visible: false,
      x: 0,
      y: 0
    },
    selectedBox: undefined,
    deleteInProgress: false
  })
});
