















































































import Vue from "vue";
import { RelatedLoadsDto } from "@/scripts/cld.api";
import AccordionRelatedLoadsDialog from "@/components/web/loads/client/accordion/AccordionRelatedLoadsDialog.vue";
import LoadRelatedTableCompact from "@/components/web/loads/shared/details/LoadRelatedTableCompact.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  components: {
    AccordionRelatedLoadsDialog,
    LoadRelatedTableCompact,
    GreyTitle
  },
  props: {
    relatedLoads: Object as () => RelatedLoadsDto | undefined,
    expanded: Boolean,
    editable: Boolean
  },
  computed: {
    disabled(): boolean {
      return !this.anyRelatedLoads && !this.editable;
    },
    numRelatedLoads(): string {
      if (!this.relatedLoads) {
        return "";
      }
      return `(${this.relatedLoads!.parentLoads!.length +
        this.relatedLoads!.childLoads!.length})`;
    },
    anyRelatedLoads(): boolean {
      return (
        !!this.relatedLoads &&
        (this.relatedLoads.parentLoads!.length > 0 ||
          this.relatedLoads.childLoads!.length > 0)
      );
    }
  },
  methods: {
    editClicked(e: any) {
      this.dialog = true;
      if (this.expanded) {
        e.stopPropagation();
      }
    }
  },
  data: (): { dialog: boolean } => ({
    dialog: false
  })
});
