

























import Vue from "vue";
import { LoadStatus } from "@/scripts/cld.api";
import { LoadWithCargoLoadingContainer } from "@/scripts/types";

export default Vue.extend({
  props: {
    load: Object as () => LoadWithCargoLoadingContainer
  },
  watch: {
    loadedOrDelivered: {
      handler() {
        if (this.loadedOrDelivered) {
          this.secondsLeft = 15;
          this.tick();
        }
      },
      immediate: true
    },
    secondsLeft() {
      if (this.secondsLeft === 0) {
        this.$router.push("/mobile/anon-dashboard?id=" + this.$route.query.id);
      }
    }
  },
  computed: {
    dots(): string {
      if (!this.secondsLeft) {
        return "";
      }
      return ".".repeat(3 - (this.secondsLeft % 3));
    },
    loadedOrDelivered(): Boolean {
      return (
        this.load.status === LoadStatus.Loaded ||
        this.load.status === LoadStatus.Delivered
      );
    },
    annulled(): Boolean {
      return this.load.status === LoadStatus.Annulled;
    },
    loadedOrDeliveredText(): string {
      let returnText: string;
      returnText = this.$t("LoadWithTransportNr") + " " + this.load.transportNo;

      if (this.load.bookingNo) {
        returnText +=
          " (" + this.$t("BookingNo") + ":" + this.load.bookingNo + ")";
      }
      returnText += " " + this.$t("IsAlreadyLoaded") + ".";

      return returnText;
    },
    annulledText(): string {
      let returnText: string;
      returnText = this.$t("LoadWithTransportNr") + " " + this.load.transportNo;

      if (this.load.bookingNo) {
        returnText +=
          " (" + this.$t("BookingNo") + ":" + this.load.bookingNo + ")";
      }
      returnText += " " + this.$t("HasBeenAnnulledOrCanceled");

      return returnText;
    }
  },
  methods: {
    tick() {
      if (!this.secondsLeft) {
        return;
      }
      this.secondsLeft--;
      setTimeout(this.tick, 1000);
    }
  },
  data: (): { secondsLeft: number | undefined } => ({ secondsLeft: undefined })
});
