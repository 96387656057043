

















import Vue from "vue";
import {
  ApiConfiguration,
  FileParameter,
  RepositoryClient
} from "@/scripts/cld.api";
import nextUnique from "@/scripts/misc/nextUnique";
import { actions } from "@/scripts/store/constants";
import { alertDialog } from "@/scripts/misc/popupDialogs";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    limitExtensionsTo: Array as () => string[] | undefined
  },
  methods: {
    chooseFile() {
      (this.$refs.fileUpload as any).click();
    },
    fileAllowed(file: any): boolean {
      if (file.size > 4 * 1024 * 1024) {
        this.uploadFailed(t("UploadSizeTooLarge"));
        return false;
      }
      if (!this.hasAllowedExtension(file)) {
        this.uploadFailed(
          t("UploadBadExtension").replaceAll(
            "{0}",
            this.allowedExtensions.join(", ")
          )
        );
        return false;
      }
      return true;
    },
    fileDropped(e: any) {
      if (!this.fileAllowed(e.dataTransfer.files[0])) {
        return;
      }
      this.addFile(e.dataTransfer.files[0]);
    },
    fileSelected(e: any) {
      if (!this.fileAllowed(e.target.files[0])) {
        return;
      }
      this.addFile(e.target.files[0]);
    },
    uploadFailed(reason: string) {
      alertDialog(t("UploadFailed"), reason);
      this.uploadKey = nextUnique();
    },
    addFile(file: any) {
      if (!file) {
        return;
      }
      const fileParam: FileParameter = {
        data: file,
        fileName: file.name
      };
      this.uploadKey = nextUnique();
      this.$emit("file", fileParam);
    },
    hasAllowedExtension(file: any): boolean {
      const fileName: string = file.name;
      return this.allowedExtensions.some(e => fileName.endsWith(e));
    },
    fetchAllowedExtensions() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .allowedUploadFileExtensions()
        .then(res => {
          this.allowedExtensions = res.filter(
            e => !this.limitExtensionsTo || this.limitExtensionsTo.includes(e)
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchAllowedExtensions();
    const span = document.getElementById(`file-input-span-${this.uploadKey}`);
    span?.addEventListener("paste", e => {
      if (e.clipboardData && e.clipboardData.files.length > 0) {
        this.addFile(e.clipboardData.files[0]);
      }
    });
  },
  data: (): {
    uploadKey: number;
    allowedExtensions: string[];
  } => ({
    uploadKey: nextUnique(),
    allowedExtensions: []
  })
});
