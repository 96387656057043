


































import Vue from "vue";
import {
  ApiConfiguration,
  PerformanceQPWeightsDto,
  RepositoryClient,
  RepositoryDto,
  QualityPointsSupplierClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import QualityPointsWeightsTable from "@/components/web/qp/QualityPointsWeightsTable.vue";
import { cargoTypes } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    Dropdown,
    QualityPointsWeightsTable
  },
  watch: {
    "$route.query": {
      handler() {
        this.fetchPerformanceWeights();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    clientId: {
      get(): number {
        return Number(this.$route.query.clientid);
      },
      set(clientId: number) {
        this.$router.push({
          path: this.$route.path,
          query: {
            clientid: clientId.toString(),
            cargoType: this.cargoType.toString()
          }
        });
      }
    },
    cargoType: {
      get(): number {
        return Number(this.$route.query.cargoType);
      },
      set(cargoType: number) {
        this.$router.push({
          path: this.$route.path,
          query: {
            clientid: this.clientId.toString(),
            cargoType: cargoType.toString()
          }
        });
      }
    }
  },
  methods: {
    cargoTypes: cargoTypes,
    fetchPerformanceWeights() {
      if (!this.cargoType || !this.clientId) {
        return;
      }
      this.loading = true;
      new QualityPointsSupplierClient(new ApiConfiguration(this.$store))
        .performanceWeights(this.cargoType, this.clientId)
        .then(res => {
          this.performanceWeights = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    new RepositoryClient(new ApiConfiguration(this.$store))
      .clients(true, true, false)
      .then(res => {
        this.clients = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    loading: boolean;
    clients: RepositoryDto[];
    performanceWeights: PerformanceQPWeightsDto[];
  } => ({
    loading: false,
    clients: [],
    performanceWeights: []
  })
});
