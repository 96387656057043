

































import Vue from "vue";
import {
  ApiConfiguration,
  SubjectiveQPDto,
  SubjectiveQualityPointsHistoryDto,
  RepositoryDto,
  QualityPointsSupplierClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import SubjectiveQualityPointsTable from "@/components/web/qp/SubjectiveQualityPointsTable.vue";
import SubjectiveQualityPointsHistory from "@/components/web/qp/SubjectiveQualityPointsHistory.vue";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    SubjectiveQualityPointsTable,
    SubjectiveQualityPointsHistory
  },
  methods: {
    fetchQualitypoints() {
      if (!this.clientId) {
        return;
      }
      this.loading = true;
      new QualityPointsSupplierClient(new ApiConfiguration(this.$store))
        .subjectivePoints(this.clientId)
        .then(res => {
          this.qualityPoints = res;
          this.fetchHistory();
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchHistory() {
      new QualityPointsSupplierClient(new ApiConfiguration(this.$store))
        .subjectiveHistory(this.clientId!)
        .then(res => {
          this.historyItems = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    cached("clients", [true, true, false])
      .then(res => {
        this.clients = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    clients: RepositoryDto[];
    clientId?: number;
    qualityPoints: SubjectiveQPDto[];
    historyItems: SubjectiveQualityPointsHistoryDto[];
    loading: boolean;
  } => ({
    clients: [],
    clientId: undefined,
    qualityPoints: [],
    historyItems: [],
    loading: false
  })
});
