






































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import { LoadDetailsDatesDto } from "@/scripts/cld.api";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";

export default Vue.extend({
  props: {
    values: LoadDetailsDatesDto
  },
  components: {
    ReadOnlyText,
    GreyTitle
  }
});
