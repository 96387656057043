




















































import Vue from "vue";
import {
  CargoType,
  CreateSpotTenderDto,
  DocumentGroupDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import MomentX from "@/scripts/misc/momentX";
import { leq } from "@/scripts/misc/dateUtils";

export default Vue.extend({
  components: {
    DropdownMulti,
    DocumentDropdown,
    TextMulti,
    DatePicker
  },
  props: {
    saveDto: CreateSpotTenderDto,
    documentGroups: Array as () => DocumentGroupDto[]
  },
  watch: {
    saveDto: {
      handler() {
        this.$nextTick(() => {
          let form: any = this.$refs.form;
          if (form !== undefined) {
            form.validate();
          }
          this.$nextTick(() => {
            this.$emit("change", this.valid);
          });
        });
      },
      deep: true
    }
  },
  computed: {
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(CargoType.Road);
    },
    spotTenderEndRules(): { (date?: MomentX): boolean | string }[] {
      return [
        leq(
          this.saveDto.dates!.deliverLatest,
          "SpotTenderEnds",
          "DeliveryLatest"
        )
      ];
    }
  },
  data: (): {
    valid: boolean;
  } => ({
    valid: false
  })
});
