























import Vue from "vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import {
  ExplorerClient,
  ExplorerNodeDto,
  ApiConfiguration,
  ExplorerAgreementQualityPoints
} from "@/scripts/cld.api";
import objectToArray from "@/scripts/misc/objectToArray";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto
  },
  components: {
    Spinner,
    ReadOnlyText
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        new ExplorerClient(new ApiConfiguration(this.$store))
          .agreementqualitypoints(this.item.id)
          .then(res => {
            this.agreement = res;
            this.loading = false;
          });
      }
    }
  },
  computed: {
    entries(): any[][] {
      if (!this.agreement) {
        return [];
      }
      return objectToArray(this.agreement);
    }
  },
  methods: {
    qpVis() {
      this.$router.push(
        `/support-admin/qp-vis?agreementid=${this.agreement!.agreementId}`
      );
    }
  },
  data: (): {
    loading: boolean;
    agreement?: ExplorerAgreementQualityPoints;
  } => ({
    loading: true,
    agreement: undefined
  })
});
