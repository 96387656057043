












































































































































import Vue from "vue";
import {
  LoadClientEditContainersDto,
  LoadStatus,
  LoadsClient,
  ApiConfiguration
} from "@/scripts/cld.api";
import AccordionContainersDialog from "@/components/web/loads/client/accordion/AccordionContainersDialog.vue";
import AccordionContainersDetailsDialog from "@/components/web/loads/client/accordion/AccordionContainersDetailsDialog.vue";
import { actions } from "@/scripts/store/constants";
import { excelPrompt } from "@/scripts/misc/filePrompts";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";

export default Vue.extend({
  components: {
    AccordionContainersDialog,
    ReadOnlyText,
    AccordionContainersDetailsDialog
  },
  props: {
    loadStatus: Number as () => LoadStatus | undefined,
    containerValues: Object as () => LoadClientEditContainersDto | undefined,
    expanded: Boolean,
    editable: Boolean,
    loadId: Number
  },
  computed: {
    disabled(): boolean {
      return !this.containerValues;
    },
    numContainers(): string {
      if (!this.containerValues) {
        return "";
      }
      return `(${this.containerValues.containers!.length})`;
    },
    hasAtleastOneContainer(): boolean {
      return (
        (!!this.containerValues &&
          this.containerValues.containers!.length > 0) ||
        !this.editable
      );
    },
    valueLines(): { key: any; value: any }[] {
      return [
        { key: this.$t("Incoterms"), value: this.containerValues!.incoterms },
        { key: this.$t("KNCurrency"), value: this.containerValues!.knCurrency },
        { key: this.$t("KNValue"), value: this.containerValues!.knValue },
        {
          key: this.$t("KNProductCode"),
          value: this.containerValues!.knProductCode
        }
      ];
    },
    thcLabel(): string {
      let res = "THC";
      if (this.containerValues!.thcCurrency) {
        res += " (" + this.containerValues!.thcCurrency + ")";
      }
      return res;
    }
  },
  methods: {
    editClicked(e: any) {
      this.dialog = true;
      if (this.expanded) {
        e.stopPropagation();
      }
    },
    detailsClicked(e: any) {
      this.detailsDialog = true;
      if (this.expanded) {
        e.stopPropagation();
      }
    },
    exportToPDF() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .exportContainerPdf(this.loadId)
        .then(res => {
          excelPrompt(res);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): { dialog: boolean; detailsDialog: boolean } => ({
    dialog: false,
    detailsDialog: false
  })
});
