






























































































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import {
  ProcurementsClientClient,
  ApiConfiguration,
  RankingMethod,
  AddProcurementPeriodDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { AgreementDataDtoX } from "@/scripts/types";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { rankingMethods } from "@/scripts/misc/enumLists";

export default Vue.extend({
  components: {
    DialogCard,
    DatePicker,
    Checkbox,
    TextMulti,
    Dropdown
  },
  props: {
    dialog: Boolean,
    selectedAgreements: Array
  },
  watch: {
    selectedAgreements: {
      handler() {
        let selectedAgreements = this.selectedAgreements as AgreementDataDtoX[];
        this.newPeriod.agreementIds = selectedAgreements.map(a => a.id);
        this.newPeriod.fromDate = new MomentX().startOfDay();
        this.newPeriod.toDate = new MomentX().startOfDay();
        this.newPeriod.qualityPointsFromDate = new MomentX()
          .addYears(-1)
          .startOfDay();
        this.newPeriod.qualityPointsToDate = new MomentX().startOfDay();
        this.newPeriod.rankingMethod = RankingMethod.NoAutomaticRanking;
        this.newPeriod.messageToSupplier = "";
      },
      immediate: true
    },
    newPeriod: {
      handler() {
        this.$nextTick(() => {
          let form: any = this.$refs.form;
          if (form !== undefined) {
            form.validate();
          }
        });
      },
      deep: true
    }
  },
  computed: {
    qualityPointsMethodSelected(): boolean {
      return this.newPeriod.rankingMethod === RankingMethod.QualityPoints;
    }
  },
  methods: {
    save() {
      this.loading = true;
      new ProcurementsClientClient(new ApiConfiguration(this.$store))
        .addProcurementPeriod(this.newPeriod)
        .then(() => {
          this.loading = false;
          this.closeDialog(true);
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.loading = false;
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    closeDialog(reload: boolean) {
      this.$emit("closeDialog", reload);
    }
  },
  data: (): {
    loading: boolean;
    valid: boolean;
    rankingMethods: RepositoryDto[];
    newPeriod: AddProcurementPeriodDto;
  } => ({
    loading: false,
    valid: true,
    rankingMethods: rankingMethods(),
    newPeriod: new AddProcurementPeriodDto({
      agreementIds: [],
      fromDate: new MomentX(),
      toDate: new MomentX(),
      qualityPointsFromDate: new MomentX(),
      qualityPointsToDate: new MomentX(),
      rankingMethod: RankingMethod.NoAutomaticRanking,
      messageToSupplier: "",
      sendEmails: true
    })
  })
});
