
























































































































































import Vue from "vue";
import Auth from "@/scripts/auth";
import {
  ApiConfiguration,
  CustomsInfoDto,
  LoadActionPermission,
  LoadsSupplierClient,
  LoadSupplierEditDto,
  RepositoryClient,
  RepositoryDto,
  TruckType
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import DatePickerHolder from "@/components/shared/ui/DatePickerHolder.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import { t } from "@/scripts/language/i18n";
import { MultiLoad } from "@/scripts/types";
import cached from "@/scripts/misc/cached";
import { isoName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    DatePickerHolder,
    DialogCard,
    TextMulti,
    TextSingle,
    Autocomplete,
    DatePicker,
    LoadLock,
    LoadChips,
    GreyTitle
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    mobile() {
      return Auth.browsingMobile();
    },
    loadId(): number {
      return this.loadIds[0]!;
    },
    tooLongText(): string {
      return t("MaxXCharacters").replace("{0}", "20");
    },
    anyCustomsInfo(): boolean {
      if (!this.customsInfo) {
        return false;
      }
      return (
        !!this.customsInfo.passageId ||
        !!this.customsInfo.passageDate ||
        !!this.customsInfo.truckCountry ||
        !!this.customsInfo.driverPhone ||
        !!this.customsInfo.permitNo
      );
    }
  },
  methods: {
    save() {
      if (this.saveDto!.bookedPickupDate! > this.deliveryLatest!) {
        popupDialog({
          title: t("BookedPickupDate"),
          body: t("ConfirmDateErrorBooking"),
          btnText1: "OK",
          btnColor1: "success",
          btnCallback1: () => {
            this.actuallySave();
          },
          btnText2: t("Cancel"),
          btnColor2: "error",
          btnCallback2: () => {}
        });
      } else if (this.saveDto!.bookedDeliveryDate! > this.deliveryLatest!) {
        popupDialog({
          title: t("BookedDeliveryDate"),
          body: t("ConfirmDateErrorDelivery"),
          btnText1: "OK",
          btnColor1: "success",
          btnCallback1: () => {
            this.actuallySave();
          },
          btnText2: t("Cancel"),
          btnColor2: "error",
          btnCallback2: () => {}
        });
      } else {
        this.actuallySave();
      }
    },
    actuallySave() {
      this.editInProgress = true;
      if (this.customsInfo) {
        this.saveDto!.customsInfo = this.customsInfo;
      }
      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .editLoad(this.saveDto!)
        .then(() => {
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchPreEdit() {
      new LoadsSupplierClient(new ApiConfiguration(this.$store))
        .preEditLoadDetails(this.loadId)
        .then(res => {
          this.saveDto = new LoadSupplierEditDto({
            loadId: this.loadId,
            bookedPickupDate: res.bookedPickupDate,
            bookedDeliveryDate: res.bookedDeliveryDate,
            bookedPickupInfo: res.bookedPickupInfo,
            bookedDeliveryInfo: res.bookedDeliveryInfo,
            bookingNo: res.bookingNo,
            regNo: res.regNo,
            customsInfo: undefined
          });
          this.routeName = res.routeName;
          this.truckType = res.truckType;
          this.transportNo = res.transportNo;
          this.pickupEarliest = res.pickupEarliest;
          this.pickupLatest = res.pickupLatest;
          this.deliveryEarliest = res.deliveryEarliest;
          this.deliveryLatest = res.deliveryLatest;
          this.mandatoryBookedPickupDate = res.mandatoryBookedPickupDate;
          this.mandatoryBookedDeliveryDate = res.mandatoryBookedDeliveryDate;
          this.bookedPickupDateEnabled = res.bookedPickupDateEnabled;
          this.bookedDeliveryDateEnabled = res.bookedDeliveryDateEnabled;
          this.bookedPickupTimeEnabled = res.bookedPickupTimeEnabled;
          this.bookedDeliveryTimeEnabled = res.bookedDeliveryTimeEnabled;
          this.alreadyHadBookedDelivery = res.bookedDeliveryDate !== undefined;
          this.regNoEnabled = res.regNoEnabled;

          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: new MomentX(),
            deliverLatest: res.deliveryLatest,
            truckType: res.truckType,
            transportNo: res.transportNo,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: res.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: res.customsInfoRequired
          };

          if (res.customsInfoRequired) {
            this.customsInfo = new CustomsInfoDto({
              passageId: res.customsInfo
                ? res.customsInfo.passageId
                : (undefined as any),
              passageDate: res.customsInfo
                ? res.customsInfo.passageDate
                : (undefined as any),
              truckCountry: res.customsInfo
                ? res.customsInfo.truckCountry
                : (undefined as any),
              driverPhone: res.customsInfo
                ? res.customsInfo.driverPhone
                : undefined,
              permitNo: res.customsInfo ? res.customsInfo.permitNo : undefined
            });
          }
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchCountries() {
      cached("countries")
        .then(res => {
          this.countries = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPassages() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .customsPassages()
        .then(res => {
          this.customsPassages = res.map(
            r =>
              new RepositoryDto({
                id: r.id,
                text: `${r.name} (${isoName(r.country)})`
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    },
    init() {
      this.editInProgress = false;
      this.fetchPreEdit();
      this.fetchCountries();
      this.fetchPassages();
    }
  },
  data: (): {
    valid: boolean;
    load: MultiLoad | undefined;
    editInProgress: boolean;
    saveDto?: LoadSupplierEditDto;
    routeName?: string;
    truckType?: TruckType;
    transportNo?: string;
    pickupEarliest?: MomentX;
    pickupLatest?: MomentX;
    deliveryEarliest?: MomentX;
    deliveryLatest?: MomentX;
    mandatoryBookedPickupDate: boolean;
    mandatoryBookedDeliveryDate: boolean;
    bookedPickupDateEnabled: boolean;
    bookedDeliveryDateEnabled: boolean;
    bookedPickupTimeEnabled: boolean;
    bookedDeliveryTimeEnabled: boolean;
    alreadyHadBookedDelivery: boolean;
    regNoEnabled: boolean;
    customsInfo: CustomsInfoDto | undefined;
    customsPassages: RepositoryDto[];
    countries: RepositoryDto[];
  } => ({
    valid: false,
    load: undefined,
    editInProgress: false,
    saveDto: undefined,
    routeName: undefined,
    truckType: undefined,
    transportNo: undefined,
    pickupEarliest: undefined,
    pickupLatest: undefined,
    deliveryEarliest: undefined,
    deliveryLatest: undefined,
    mandatoryBookedPickupDate: true,
    mandatoryBookedDeliveryDate: true,
    bookedPickupDateEnabled: true,
    bookedDeliveryDateEnabled: false,
    bookedPickupTimeEnabled: true,
    bookedDeliveryTimeEnabled: false,
    alreadyHadBookedDelivery: false,
    regNoEnabled: false,
    customsInfo: undefined,
    customsPassages: [],
    countries: []
  })
});
