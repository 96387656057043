























import neverHappens from "@/scripts/misc/neverHappens";
import Vue from "vue";

type lines = "0" | "1" | "2" | "3" | "many" | "table";

export default Vue.extend({
  props: {
    lines: String as () => lines,
    height: [String, Number],
    width: String
  },
  computed: {
    computedType(): string {
      switch (this.lines) {
        case "0":
          return "image@5";
        case "1":
          return "list-item";
        case "2":
          return "list-item-two-line";
        case "3":
          return "list-item-three-line";
        case "many":
          return "article";
        case "table":
          return "table";
        default:
          return neverHappens(this.lines);
      }
    }
  }
});
