












































import Vue from "vue";
import {
  UserConfigurationType,
  InvoicedFeesSearchCriteria,
  RepositoryDto,
  InvoicedFeesClient,
  ApiConfiguration
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import money from "@/scripts/misc/money";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import { currencyName } from "@/scripts/misc/enumNames";
import { actions, gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    Dropdown
  },
  props: {
    exportLoading: Boolean,
    exportDisabled: Boolean
  },
  watch: {
    "searchCriteria.actorId": {
      handler() {
        this.fetchDates();
      },
      immediate: true
    },
    "searchCriteria.invoiceDate": {
      handler() {
        if (this.searchCriteria.invoiceDate) {
          const dateAsString = this.searchCriteria.invoiceDate.dateTimePrint();
          if (dateAsString !== this.selectedDate) {
            this.selectedDate = dateAsString;
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    searchCriteria(): InvoicedFeesSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoicedFees,
        1
      );
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    },
    dateOptions(): RepositoryDto[] {
      return this.dates.map(
        d =>
          new RepositoryDto({
            id: d.dateTimePrint() as any,
            text:
              d.dateTextPrint(localeCode()) + " (" + d.shortTimePrint() + ")"
          })
      );
    },
    selectedDate(): string | undefined {
      if (!this.searchCriteria.invoiceDate) {
        return undefined;
      }
      return this.searchCriteria.invoiceDate.dateTimePrint();
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    search() {
      this.$emit("search");
    },
    clearSelection() {
      this.$emit("clearSelection");
    },
    setSelectedDate(dateAsString: string | undefined) {
      if (!dateAsString) {
        this.searchCriteria.invoiceDate = undefined as any;
      } else {
        this.searchCriteria.invoiceDate = new MomentX(dateAsString);
      }
      this.search();
    },
    fetchDates() {
      if (!this.searchCriteria.actorId) {
        this.dates = [];
        return;
      }
      new InvoicedFeesClient(new ApiConfiguration(this.$store))
        .dates(this.searchCriteria.actorId)
        .then(res => {
          this.dates = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchActors() {
      new InvoicedFeesClient(new ApiConfiguration(this.$store))
        .actors()
        .then(res => {
          this.actors = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetchActors();
  },
  data: (): { actors: RepositoryDto[]; dates: MomentX[] } => ({
    actors: [],
    dates: []
  })
});
