

























import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import {
  ApiConfiguration,
  ExplorerClient,
  ExplorerNodeDto,
  ExplorerNodeType
} from "@/scripts/cld.api";
import ExplorerDialogLoadDetails from "@/components/support-admin/explorer/ExplorerDialogLoadDetails.vue";
import ExplorerDialogPrice from "@/components/support-admin/explorer/ExplorerDialogPrice.vue";
import ExplorerDialogGeneric from "@/components/support-admin/explorer/ExplorerDialogGeneric.vue";
import ExplorerDialogLoadActions from "@/components/support-admin/explorer/ExplorerDialogLoadActions.vue";
import ExplorerDialogAgreementActions from "@/components/support-admin/explorer/ExplorerDialogAgreementActions.vue";
import ExplorerDialogQualityPoints from "@/components/support-admin/explorer/ExplorerDialogQualityPoints.vue";
import { truckTypeName } from "@/scripts/misc/enumNames";
import { actions, gets } from "@/scripts/store/constants";
import { ActorListDialogType } from "../actor/ActorTable.vue";

export type ExplorerApiMethod =
  | undefined
  | "loadhistory"
  | "visibility"
  | "resigns"
  | "suppliernotified"
  | "supplierunbookedloads"
  | "pricerowByLoadid"
  | "pricerowByPricerowid"
  | "agreement"
  | "agreementhistory"
  | "agreementrow"
  | "agreementroute"
  | "agreementroutetrucktypes"
  | "agreementroutehistory"
  | "location";

interface TabItem {
  text: string;
  forType: ExplorerNodeType;
  component: any;
  apiMethod: ExplorerApiMethod;
  onlyForMegaUsers?: boolean;
}

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    dialog: Boolean,
    item: ExplorerNodeDto
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        this.dialogType = this.item.type;
        this.additionalTitleInfo = "";
        if (this.item.type === ExplorerNodeType.AgreementRow) {
          new ExplorerClient(new ApiConfiguration(this.$store))
            .agreementrow(this.item.id)
            .then(res => {
              this.additionalTitleInfo = truckTypeName(res.truckType);
            })
            .catch(error => {
              this.$store.dispatch(actions.handleApiError, error);
            });
        }
        if (
          [ExplorerNodeType.LoadClient, ExplorerNodeType.LoadSupplier].includes(
            this.item.type
          )
        ) {
          window.open(
            `/support-admin/actors?id=${this.item.id}&dialog=${ActorListDialogType.ActorDialog}`,
            "_blank"
          );
          this.close();
        }
      }
    }
  },
  computed: {
    title(): string {
      if (this.item === undefined) {
        return "";
      }
      return (
        ExplorerNodeType[this.item.type] +
        " : " +
        this.item.id +
        " " +
        this.additionalTitleInfo
      );
    },
    isMegaUser(): boolean {
      return this.$store.getters[gets.userInfo].supportAdminLevel >= 2;
    }
  },
  methods: {
    close() {
      this.dialogType = -1;
      this.selectedTab = "";
      this.$emit("close");
    },
    showOnlySelected(items: TabItem[], node: ExplorerNodeDto) {
      if (node === undefined) {
        return [];
      }
      return items
        .filter(item => item.forType === node.type)
        .filter(item => !item.onlyForMegaUsers || this.isMegaUser);
    }
  },
  data: (): {
    additionalTitleInfo: string;
    dialogType: number;
    selectedTab: string;
    tabItems: TabItem[];
  } => ({
    additionalTitleInfo: "",
    dialogType: -1,
    selectedTab: "",
    tabItems: [
      {
        text: "Lass",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogLoadDetails,
        apiMethod: undefined
      },
      {
        text: "Historik",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogGeneric,
        apiMethod: "loadhistory"
      },
      {
        text: "Synlighet",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogGeneric,
        apiMethod: "visibility"
      },
      {
        text: "Nekanden",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogGeneric,
        apiMethod: "resigns"
      },
      {
        text: "Notifieringar",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogGeneric,
        apiMethod: "suppliernotified"
      },
      {
        text: "Avbokningar",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogGeneric,
        apiMethod: "supplierunbookedloads"
      },
      {
        text: "Pris",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogPrice,
        apiMethod: "pricerowByLoadid"
      },
      {
        text: "Pris",
        forType: ExplorerNodeType.PriceRow,
        component: ExplorerDialogPrice,
        apiMethod: "pricerowByPricerowid"
      },
      {
        text: "Åtgärder",
        forType: ExplorerNodeType.Load,
        component: ExplorerDialogLoadActions,
        apiMethod: undefined
      },
      {
        text: "Avtal",
        forType: ExplorerNodeType.Agreement,
        component: ExplorerDialogGeneric,
        apiMethod: "agreement"
      },
      {
        text: "Historik",
        forType: ExplorerNodeType.Agreement,
        component: ExplorerDialogGeneric,
        apiMethod: "agreementhistory"
      },
      {
        text: "Kvalitetspoäng",
        forType: ExplorerNodeType.Agreement,
        component: ExplorerDialogQualityPoints,
        apiMethod: undefined
      },
      {
        text: "Åtgärder",
        forType: ExplorerNodeType.Agreement,
        component: ExplorerDialogAgreementActions,
        apiMethod: undefined,
        onlyForMegaUsers: true
      },
      {
        text: "Avtalsrad",
        forType: ExplorerNodeType.AgreementRow,
        component: ExplorerDialogGeneric,
        apiMethod: "agreementrow"
      },
      {
        text: "Avtalsrutt",
        forType: ExplorerNodeType.AgreementRoute,
        component: ExplorerDialogGeneric,
        apiMethod: "agreementroute"
      },
      {
        text: "Kopplade Lastbärare",
        forType: ExplorerNodeType.AgreementRoute,
        component: ExplorerDialogGeneric,
        apiMethod: "agreementroutetrucktypes"
      },
      {
        text: "Historik",
        forType: ExplorerNodeType.AgreementRoute,
        component: ExplorerDialogGeneric,
        apiMethod: "agreementroutehistory"
      },
      {
        text: "Ort",
        forType: ExplorerNodeType.Location,
        component: ExplorerDialogGeneric,
        apiMethod: "location"
      }
    ]
  })
});
