














































































































































import Vue from "vue";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import { QualityPointsPastYearDto, RepositoryDto } from "@/scripts/cld.api";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { t } from "@/scripts/language/i18n";
import { rankingTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    suppliers: Array as () => RepositoryDto[],
    subscribedSuppliers: Array as () => number[],
    qualityPoints: Array as () => QualityPointsPastYearDto[],
    filter: String,
    mode: String as () => "excluded" | "included",
    small: Boolean
  },
  components: {
    CustomTableHeader,
    CustomFilter
  },
  computed: {
    tableHeight(): number {
      return Math.max(
        400,
        this.$store.getters[gets.dimensions].height - (this.small ? 500 : 333)
      );
    },
    filteredHeaders(): TableHeader[] {
      return this.headers
        .filter(h => !h.id || (h.id as any) === this.mode)
        .filter(
          h =>
            this.subscribedSuppliers !== undefined || h.value !== "unsubscribed"
        )
        .filter(
          h =>
            this.qualityPoints !== undefined ||
            (h.value !== "survey" && h.value !== "qp")
        );
    },
    sortedSuppliers(): RepositoryDto[] {
      let res = [...this.suppliers];
      let c: {
        (a: RepositoryDto, b: RepositoryDto): number;
      };
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      switch (sortBy) {
        case "qp":
          c = (a, b) => this.total(a.id) - this.total(b.id);
          break;
        default:
          c = (a, b) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      res = res.sort((a, b) => (sortDesc ? -1 : 1) * c(a, b));
      return res;
    }
  },
  methods: {
    surveyDone(id: number): boolean {
      return this.qualityPoints.some(
        x => x.supplierId === id && x.surveyPoints > 0
      );
    },
    ftCertified(id: number): boolean {
      return this.qualityPoints.some(
        x => x.supplierId === id && x.fairTransportPoints > 0
      );
    },
    subscribed(id: number): boolean {
      return this.subscribedSuppliers.includes(id);
    },
    qp(id: number): QualityPointsPastYearDto | undefined {
      return this.qualityPoints.find(q => q.supplierId === id);
    },
    total(id: number): number {
      const qp = this.qp(id);
      return qp ? qp.totalPoints : 0;
    },
    tooltip(id: number): string {
      const qp = this.qp(id);
      if (!qp) {
        return t("NotAvailable");
      }
      let tooltip = `${t("SourceForStatistics")}: ${rankingTypeName(
        qp.rankingType
      )}`;
      tooltip += `\n${t("NumberOfOfferedLoads")}: ${qp.numLoadsOffered}`;
      tooltip += `\n${t("BookedLoads")}: ${qp.numLoadsLoaded}`;
      tooltip += `\n${t("Survey_SustainabilitySurvey")}: ${qp.surveyPoints} ${t(
        "Points"
      )}`;
      tooltip += `\n${t("FairTransport")}: ${qp.fairTransportPoints} ${t(
        "Points"
      )}`;
      tooltip += `\n${t("MeasuredQualityPoints")} (${qp.factor! * 100}% ${t(
        "Of"
      )} ${qp.baseParameterPoints}): ${qp.factoredParameterPoints}`;
      tooltip += `\n${t("BusinessQualitypoints")}: ${qp.subjectivePoints}`;
      tooltip += `\n${t("TotalPoints")}: ${qp.totalPoints}`;
      return tooltip;
    }
  },
  data: (): {
    headers: TableHeader[];
    tableOptions: TableOptions;
    ftLogo: any;
  } => ({
    headers: [
      {
        value: "exclude",
        text: "",
        untranslated: true,
        id: "included" as any,
        width: 52
      },
      {
        value: "text",
        text: "Supplier",
        sortValue: true
      },
      {
        value: "unsubscribed",
        text: "",
        untranslated: true
      },
      {
        value: "survey",
        text: "",
        untranslated: true
      },
      {
        value: "qp",
        text: "QualityPoints",
        sortValue: true
      },
      {
        value: "include",
        text: "",
        untranslated: true,
        id: "excluded" as any,
        width: 75
      }
    ],
    tableOptions: new TableOptions({
      itemsPerPage: -1,
      sortBy: ["supplier"],
      sortDesc: [false]
    }),
    ftLogo: require("@/assets/img/fair-transport-logo.png")
  })
});
