var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiny-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.documentGroups,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"options":_vm.tableOptions,"hide-default-header":"","hide-default-footer":"","disable-pagination":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.tableOptions=$event},"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on}})]}},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.$emit('click', item.id)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.allowedToEditDocumentGroups ? "mdi-pencil" : "mdi-text-box-search-outline")+" ")])],1)]}},{key:"item.docType",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(_vm.docTypeName(item.docType)))])]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(_vm._s(item.description))])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }