




















































import nextUnique from "@/scripts/misc/nextUnique";
import Vue from "vue";

export default Vue.extend({
  props: {
    value: String,
    label: String,
    placeholder: String,
    rows: Number,
    readonly: Boolean,
    disabled: Boolean,
    rules: Array,
    asterisk: Boolean,
    maxLength: Number,
    refString: String,
    autofocus: Boolean,
    tabindex: String,
    onlyOnBlur: Boolean,
    compact: Boolean
  },
  methods: {
    input(val: string) {
      this.$emit("change");
      this.newValue(val, !this.onlyOnBlur);
    },
    newValue(val: string | null, instant: boolean) {
      if (!val) {
        val = null;
      }
      if (instant) {
        this.emitValue(val);
      } else {
        this.emitValueWhenFinished(val);
      }
    },
    emitValue(val: string | null) {
      this.$emit("input", val);
    },
    emitValueWhenFinished(val: string | null) {
      const unique = nextUnique();
      this.inputTracker = unique;
      setTimeout(() => {
        if (this.inputTracker === unique) {
          this.emitValue(val);
        }
      }, 400);
    }
  },
  data: (): { inputTracker: number } => ({
    inputTracker: 0
  })
});
