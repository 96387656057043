
































































































































































import Vue from "vue";
import {
  actorCargoTypes,
  cargoTypes,
  standardPhraseTypes
} from "@/scripts/misc/enumLists";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import MenuButton, {
  MenuButtonOption
} from "@/components/shared/input/MenuButton.vue";
import {
  AgreementRouteEditTracker,
  RouteTab
} from "@/views/client/AgreementRoutes.vue";
import { t } from "@/scripts/language/i18n";
import {
  AgreementRouteDto,
  AgreementRoutesClient,
  ApiConfiguration,
  CargoType,
  FileParameter,
  RepositoryDto,
  UserLevel,
  UserConfigurationType,
  StandardPhraseType
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import { alertDialog } from "@/scripts/misc/popupDialogs";
import {
  cargoTypeIcon,
  cargoTypeName,
  standardPhraseTypeIcon
} from "@/scripts/misc/enumNames";
import MomentX from "@/scripts/misc/momentX";
import { AgreementRouteFilter } from "@/scripts/types";

export default Vue.extend({
  props: {
    routes: Array as () => AgreementRouteDto[],
    tracker: Object as () => AgreementRouteEditTracker
  },
  components: {
    SearchCard,
    Dropdown,
    MenuButton
  },
  computed: {
    exportVisible(): boolean {
      return this.tracker.tabId === RouteTab.Estimate;
    },
    editTagVisible(): boolean {
      return this.tracker.tabId === RouteTab.Tag;
    },
    editSpecReqVisible(): boolean {
      return this.tracker.tabId === RouteTab.StandardPhrases;
    },
    filter(): AgreementRouteFilter {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.AgreementRoute,
        1
      );
    },
    cargoTypes(): MenuButtonOption[] {
      return cargoTypes().map(x => ({ ...x, icon: cargoTypeIcon(x.id) }));
    },
    standardPhraseTypes(): MenuButtonOption[] {
      return standardPhraseTypes()
        .filter(
          x =>
            x.id === StandardPhraseType.PickupInfo ||
            x.id === StandardPhraseType.DestinationInfo ||
            x.id === StandardPhraseType.SpecialRequirement
        )
        .map(x => ({ ...x, icon: standardPhraseTypeIcon(x.id) }));
    },
    actorCargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    },
    userLevel(): UserLevel {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    atleastAdmin(): boolean {
      return (
        this.userLevel === UserLevel.Admin ||
        this.userLevel === UserLevel.SuperAdmin
      );
    },
    visibleTabs(): any[] {
      return this.tabs.filter(t => this.atleastAdmin || t.id === RouteTab.List);
    },
    withWithout(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: true as any,
          text: t("With")
        }),
        new RepositoryDto({
          id: false as any,
          text: t("Without")
        })
      ];
    },
    agreementEndItems(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: 0,
          text: t("NotOngoing")
        }),
        ...this.routes
          .map(r => r.agreementPeriods!.map(p => p.to.startOfDay().unix()))
          .flatMap(x => x)
          .map(
            d =>
              new RepositoryDto({
                id: d,
                text: `***** - ${MomentX.unix(d).datePrint()}`
              })
          )
      ].sort((a, b) => a.id - b.id);
    },
    bulkUpdateMenuButtonText() {
      return `${t("BulkUpdate")} ${t("StandardPhrases")}`;
    }
  },
  methods: {
    importEstimate(e: any) {
      const uploadedFile = e.target.files[0];
      if (!uploadedFile) {
        return;
      }
      const fileParam: FileParameter = {
        data: uploadedFile,
        fileName: uploadedFile.name
      };
      new AgreementRoutesClient(new ApiConfiguration(this.$store))
        .importEstimateFromExcel(fileParam as any)
        .then(res => {
          if (res.cargoType !== this.filter.cargoType) {
            let message = t("ImportEstimateFailedMessage")
              .replace("{0}", cargoTypeName(res.cargoType).toLowerCase())
              .replace(
                "{1}",
                cargoTypeName(this.filter.cargoType).toLowerCase()
              );
            alertDialog(t("ImportEstimateFailed"), message);
          } else {
            this.$root.$emit("import-estimate", res.estimates);
            this.uploadKey++;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    exportEstimateToExcel() {
      this.exporting = true;
      this.$root.$emit("export-estimate", () => {
        this.exporting = false;
      });
    },
    exportAllToExcel() {
      this.exporting = true;
      this.$root.$emit("export-all", () => {
        this.exporting = false;
      });
    },
    chooseFile() {
      (this.$refs.fileUpload as any).click();
    },
    editTag() {
      this.$root.$emit("editTag");
    },
    openHistoryDialog() {
      this.$root.$emit("openHistoryDialog");
    },
    createRoute(cargoType: CargoType) {
      this.filter.cargoType = cargoType;
      this.$emit("createRoute", cargoType);
    },
    editStandardPhrasesBulk(standardPhraseType: StandardPhraseType) {
      this.$root.$emit("editStandardPhrasesBulk", standardPhraseType);
    }
  },
  data: (): {
    tabs: { id: RouteTab; text: string; icon: string; color?: string }[];
    exporting: Boolean;
    uploadKey: number;
  } => ({
    tabs: [
      {
        id: RouteTab.List,
        text: t("ListView"),
        icon: "mdi-format-list-bulleted"
      },
      {
        id: RouteTab.Tag,
        text: t("Tag"),
        icon: "mdi-tag-text"
      },
      {
        id: RouteTab.Visibility,
        text: t("ShowHide"),
        icon: "mdi-eye-off"
      },
      {
        id: RouteTab.TruckTypes,
        text: t("TruckType"),
        icon: "mdi-connection"
      },
      {
        id: RouteTab.Estimate,
        text: t("Estimation"),
        icon: "mdi-calculator-variant"
      },
      {
        id: RouteTab.StandardPhrases,
        text: t("StandardPhrases"),
        icon: "mdi-note"
      },
      {
        id: RouteTab.MandatoryFlags,
        text: t("AdditionalRequirements"),
        icon: "mdi-flag-variant-outline"
      }
    ],
    exporting: false,
    uploadKey: 1
  })
});
