





















import Vue from "vue";
import { ApiConfiguration, LoadsClient } from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import money from "@/scripts/misc/money";

export default Vue.extend({
  props: {
    distance: Number,
    truckType: Number,
    disabled: Boolean
  },
  watch: {
    distance: {
      handler() {
        this.fetchEmission();
      },
      immediate: true
    },
    truckType: {
      handler() {
        this.fetchEmission();
      },
      immediate: true
    }
  },
  methods: {
    money: money,
    fetchEmission() {
      if (!this.distance || !this.truckType) {
        this.emission = 0;
        return;
      }
      this.loading = true;
      new LoadsClient(new ApiConfiguration(this.$store))
        .emission(this.truckType, this.distance)
        .then(res => {
          this.emission = res;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    loading: boolean;
    emission: number;
  } => ({
    loading: false,
    emission: 0
  })
});
