












































































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import {
  CargoType,
  Currency,
  RepositoryDto,
  TruckType,
  UserConfigurationType
} from "@/scripts/cld.api";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import {
  SupplierProcurementConfig,
  SupplierProcurementConfigEntry
} from "@/scripts/types";
import { currencies, truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { actions, gets } from "@/scripts/store/constants";
import { t } from "@/scripts/language/i18n";
export default Vue.extend({
  props: {
    dialog: Boolean,
    cargoType: Number as () => CargoType
  },
  components: {
    DialogCard,
    GreyTitle,
    NumberInput
  },
  computed: {
    conf(): SupplierProcurementConfig {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.SupplierProcurementConfig,
        1
      );
    },
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.cargoType);
    },
    road(): boolean {
      return this.cargoType === CargoType.Road;
    },
    machineTransport(): boolean {
      return this.cargoType === CargoType.MachineTransport;
    },
    percentagePriceText(): string {
      return t("PercentageSettingsDesc").replace(
        "{0}",
        t("CalculateBasedOnTW").toLowerCase()
      );
    },
    stopPriceText(): string {
      return t("StopPriceSettingsDesc").replace(
        "{0}",
        t("GetStopPrice").toLowerCase()
      );
    },
    kmPriceText(): string {
      return t("PricePerKmSettingsDesc").replace(
        "{0}",
        t("CalculateBasedOnDist").toLowerCase()
      );
    }
  },
  methods: {
    currencies: currencies,
    closeDialog() {
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.SupplierProcurementConfig,
        subTypeId: 1
      });
      this.$emit("close");
    },
    km(tt: TruckType, c: Currency): SupplierProcurementConfigEntry {
      return this.conf.kmPrices.find(
        x => x.truckType === tt && x.currency === c
      )!;
    },
    stopPrice(c: Currency): SupplierProcurementConfigEntry {
      return this.conf.stopPrices.find(x => x.currency === c)!;
    }
  },
  data: (): { valid: boolean } => ({ valid: false })
});
