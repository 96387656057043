


















































































































































import Vue from "vue";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import InvoiceGeneratorLoadIdsDialog from "@/components/support-admin/invoice/InvoiceGeneratorLoadIdsDialog.vue";
import {
  actorTypeName,
  currencyName,
  feeTypeName
} from "@/scripts/misc/enumNames";
import { InvoiceRowSupportAdminDto } from "@/scripts/cld.api";
import { gets } from "@/scripts/store/constants";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";
import money from "@/scripts/misc/money";
import { InvoiceGeneratorFilter } from "@/views/support-admin/InvoiceGenerator.vue";

export default Vue.extend({
  props: {
    loading: Boolean,
    rows: Array as () => InvoiceRowSupportAdminDto[],
    filter: Object as () => InvoiceGeneratorFilter
  },
  components: {
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    InvoiceGeneratorLoadIdsDialog
  },
  watch: {
    tableOptions() {
      this.$emit("tableOptionsChanged");
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 310);
    },
    sortedRows(): InvoiceRowSupportAdminDto[] {
      let res = [...this.filteredRows];
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      let c = (a: InvoiceRowSupportAdminDto, b: InvoiceRowSupportAdminDto) =>
        stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      res = res.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(a.actorName, b.actorName) ||
          a.fromDate.unix() - b.fromDate.unix()
      );
      return res;
    },
    filteredRows(): InvoiceRowSupportAdminDto[] {
      return this.rows
        .filter(
          r => !this.filter.actorType || r.actorType === this.filter.actorType
        )
        .filter(
          r =>
            !this.filter.actorId ||
            stringSearchHit(this.filter.actorId, r.actorId.toString(), true)
        )
        .filter(
          r =>
            !this.filter.actorName ||
            stringSearchHit(this.filter.actorName, r.actorName, true)
        )
        .filter(r => !this.filter.feeType || r.feeType === this.filter.feeType)
        .filter(
          r => !this.filter.currency || r.currency === this.filter.currency
        )
        .filter(
          r =>
            !this.filter.invoiceRecipient ||
            stringSearchHit(
              this.filter.invoiceRecipient,
              r.actorInvoiceInformationName,
              false
            )
        );
    }
  },
  methods: {
    money: money,
    currencyName: currencyName,
    feeTypeName: feeTypeName,
    actorTypeName: actorTypeName,
    search() {
      this.$emit("search");
    },
    invoiceRecipientName(row: InvoiceRowSupportAdminDto): string {
      return (
        row.customerNo +
        " / " +
        row.projectNo +
        (row.actorInvoiceInformationName
          ? " (" + row.actorInvoiceInformationName + ")"
          : "")
      );
    }
  },
  data: (): {
    selectedRow: InvoiceRowSupportAdminDto | undefined;
    tableOptions: TableOptions;
    headers: TableHeader[];
  } => ({
    selectedRow: undefined,
    tableOptions: new TableOptions({
      itemsPerPage: 100,
      sortBy: ["actorId"],
      sortDesc: [false]
    }),
    headers: [
      { value: "loadIds", width: 1 },
      {
        value: "actorType",
        text: "Aktörstyp",
        sortValue: true,
        untranslated: true,
        width: 85
      },
      {
        value: "actorId",
        text: "AktörsId",
        sortValue: true,
        untranslated: true,
        width: 75
      },
      {
        value: "actorName",
        text: "Aktör",
        sortValue: true,
        untranslated: true,
        width: 150
      },
      {
        value: "invoiceRecipient",
        text: "Kundnr/Projektnr (Mottagare)",
        sortValue: true,
        untranslated: true,
        width: 180
      },
      {
        value: "unit",
        text: "Enhet",
        sortValue: true,
        untranslated: true,
        width: 150
      },
      {
        value: "feeType",
        text: "Typ",
        sortValue: true,
        untranslated: true,
        width: 200
      },
      {
        value: "discountText",
        text: "Rabatt",
        sortValue: true,
        untranslated: true,
        width: 120
      },
      {
        value: "period",
        text: "Period",
        untranslated: true,
        width: 1
      },
      {
        value: "numberOfItems",
        text: "Antal",
        sortValue: true,
        untranslated: true,
        width: 65
      },
      {
        value: "priceWithDiscount",
        text: "Pris",
        sortValue: true,
        untranslated: true,
        width: 65
      },
      {
        value: "total",
        text: "Total",
        untranslated: true,
        width: 90
      },
      {
        value: "currency",
        text: "Valuta",
        sortValue: true,
        untranslated: true,
        width: 100
      }
    ]
  })
});
