
































































import Vue from "vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import { LoadClientEditAssortedValuesDto } from "@/scripts/cld.api";

export default Vue.extend({
  props: {
    assortedValues: LoadClientEditAssortedValuesDto,
    duplicateTransportNos: Array as () => string[],
    expanded: Boolean
  },
  watch: {
    expanded() {
      this.checkIfThereAreErrors(false);
    }
  },
  components: {
    TextSingle
  },
  computed: {
    numLoadsText(): string {
      if (this.duplicateTransportNos.length === 0) {
        return "";
      }
      return `(${this.duplicateTransportNos.length + 1})`;
    }
  },
  methods: {
    addLoad() {
      this.duplicateTransportNos.push("");
    },
    removeLoad(index: number) {
      this.duplicateTransportNos.splice(index, 1);
    },
    input(index: number, value: string) {
      this.duplicateTransportNos[index] = value;
      this.checkIfThereAreErrors(true);
    },
    checkIfThereAreErrors(onlyRemoveError: boolean) {
      if (!onlyRemoveError || this.error) {
        this.error = this.duplicateTransportNos.some(d => !d);
      }
    }
  },
  data: (): { error: boolean } => ({
    error: false
  })
});
