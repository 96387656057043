var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"mobile-breakpoint":0,"loading":_vm.loading,"items":_vm.filteredActors,"height":_vm.tableHeight,"item-key":"actorId","sort-by":"actorName","footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [-1],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"hide-default-header":"","fixed-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.actorName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' }},model:{value:(_vm.actorNameFilter),callback:function ($$v) {_vm.actorNameFilter=$$v},expression:"actorNameFilter"}})]},proxy:true},{key:"filter.country",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'country' }},model:{value:(_vm.countryFilter),callback:function ($$v) {_vm.countryFilter=$$v},expression:"countryFilter"}})]},proxy:true},{key:"filter.orgNo",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' }},model:{value:(_vm.orgNoFilter),callback:function ($$v) {_vm.orgNoFilter=$$v},expression:"orgNoFilter"}})]},proxy:true},{key:"filter.mainContactName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' }},model:{value:(_vm.mainContactNameFilter),callback:function ($$v) {_vm.mainContactNameFilter=$$v},expression:"mainContactNameFilter"}})]},proxy:true},{key:"filter.mainContactEmail",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' }},model:{value:(_vm.mainContactEmailFilter),callback:function ($$v) {_vm.mainContactEmailFilter=$$v},expression:"mainContactEmailFilter"}})]},proxy:true},{key:"filter.mainContactPhone",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'text' }},model:{value:(_vm.mainContactPhoneFilter),callback:function ($$v) {_vm.mainContactPhoneFilter=$$v},expression:"mainContactPhoneFilter"}})]},proxy:true},{key:"filter.cargoTypes",fn:function(){return [_c('CustomFilter',{attrs:{"config":{ subtype: 'cargoType' }},model:{value:(_vm.cargoTypeFilter),callback:function ($$v) {_vm.cargoTypeFilter=$$v},expression:"cargoTypeFilter"}})]},proxy:true}],null,true)})]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"size":"20"},on:{"click":function($event){return _vm.viewActor(item.actorId)}}},[_vm._v(" mdi-magnify ")])]}},{key:"item.regDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.regDate)+" ")]}},{key:"item.mainContactName",fn:function(ref){
    var item = ref.item;
return [_c('EllipsisTableItem',{attrs:{"text":item.mainContactName,"width":"120"}})]}},{key:"item.country",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.isoName(item.country))+" ")]}},{key:"item.cargoTypes",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.cargoTypesText(item.cargoTypes))+" ")]}}],null,true)}),_c('ActorDialog',{attrs:{"actorIdToView":_vm.actorId,"actorTypeToView":_vm.actorTypeToView},on:{"close":_vm.closeDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }