import { FileResponse } from "@/scripts/cld.api";

export const excelPrompt = (res: FileResponse) => {
  filePrompt(res, "application/vnd.ms-excel");
};

export const pdfPrompt = (res: FileResponse) => {
  filePrompt(res, "application/pdf");
};

export const textPrompt = (res: FileResponse) => {
  filePrompt(res, "text/csv");
};

const filePrompt = (res: FileResponse, mimeType: string) => {
  const blob = new Blob([res.data], {
    type: mimeType
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = res.fileName as string;
  link.click();
};
