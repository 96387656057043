


























































































import Vue from "vue";
import { ActorListDto, ActorType, CargoType, Country } from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import ActorDialog from "@/components/web/actor/ActorDialog.vue";
import { stringSearchHit } from "@/scripts/misc/stringUtils";
import { cargoTypeName, isoName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    actors: Array as () => ActorListDto[],
    actorTypeToView: Number as () => ActorType,
    loading: Boolean
  },
  components: {
    EllipsisTableItem,
    CustomTableHeader,
    CustomFilter,
    ActorDialog
  },
  watch: {
    filteredActors: {
      handler() {
        this.$emit(
          "filteredActorIds",
          this.filteredActors.map(a => a.actorId)
        );
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 175);
    },
    actorId(): number | undefined {
      const id = Number(this.$route.query.actorId);
      if (!id || isNaN(id)) {
        return undefined;
      }
      return id;
    },
    filteredActors(): ActorListDto[] {
      return this.actors
        .filter(a => stringSearchHit(this.actorNameFilter, a.actorName, false))
        .filter(a => !this.countryFilter || a.country === this.countryFilter)
        .filter(a => stringSearchHit(this.orgNoFilter, a.orgNo, false))
        .filter(a =>
          stringSearchHit(this.mainContactNameFilter, a.mainContactName, false)
        )
        .filter(a =>
          stringSearchHit(
            this.mainContactEmailFilter,
            a.mainContactEmail,
            false
          )
        )
        .filter(a =>
          stringSearchHit(
            this.mainContactPhoneFilter,
            a.mainContactPhone,
            false
          )
        )
        .filter(
          a =>
            !this.cargoTypeFilter ||
            a.cargoTypes!.includes(this.cargoTypeFilter)
        );
    },
    headers(): TableHeader[] {
      const headers: TableHeader[] = [];
      headers.push({
        value: "action",
        width: 1
      });
      headers.push({
        value: "actorName",
        text: "Actor",
        sortValue: true
      });
      if (
        this.actorTypeToView === ActorType.Supplier ||
        this.actorTypeToView === ActorType.Client
      ) {
        headers.push({
          value: "regDate",
          text: "EnteredLasset",
          sortValue: true
        });
      }
      if (this.actorTypeToView === ActorType.Customer) {
        headers.push({
          value: "orgNo",
          text: "OrgNo",
          sortValue: true
        });
      }
      headers.push({
        value: "country",
        text: "Country",
        sortValue: true,
        width: 100
      });
      headers.push({
        value: "mainContactName",
        text: "MainContact",
        sortValue: true
      });
      headers.push({
        value: "mainContactEmail",
        text: "MainContactEmail",
        multiLine: true
      });
      headers.push({
        value: "mainContactPhone",
        text: "MainContactPhone",
        multiLine: true
      });
      if (this.actorTypeToView === ActorType.Supplier) {
        headers.push({
          value: "cargoTypes",
          text: "CargoType"
        });
      }
      return headers;
    }
  },
  methods: {
    isoName: isoName,
    cargoTypesText(cargoTypes: CargoType[]): string {
      return cargoTypes.map(ct => cargoTypeName(ct).slice(0, 4)).join(", ");
    },
    viewActor(id: number) {
      this.$router.push({
        query: { ...this.$route.query, actorId: id.toString() },
        path: this.$route.path
      });
    },
    closeDialog() {
      this.$router.push({
        query: { ...this.$route.query, actorId: undefined },
        path: this.$route.path
      });
    }
  },
  data: (): {
    actorNameFilter: string;
    cargoTypeFilter: CargoType | undefined;
    countryFilter: Country | undefined;
    orgNoFilter: string;
    mainContactNameFilter: string;
    mainContactEmailFilter: string;
    mainContactPhoneFilter: string;
  } => ({
    actorNameFilter: "",
    cargoTypeFilter: undefined,
    countryFilter: undefined,
    orgNoFilter: "",
    mainContactNameFilter: "",
    mainContactEmailFilter: "",
    mainContactPhoneFilter: ""
  })
});
