



















































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import ActorEditForm from "@/components/support-admin/actor/ActorEditForm.vue";
import {
  ActorDto,
  ActorsClient,
  ActorType,
  ApiConfiguration,
  Country,
  Culture,
  UserDto,
  UserLevel,
  UsersClient
} from "@/scripts/cld.api";
import { actorTypeName } from "@/scripts/misc/enumNames";
import MomentX from "@/scripts/misc/momentX";
import nextUnique from "@/scripts/misc/nextUnique";
import { actions } from "@/scripts/store/constants";
import { ActorListDialogType } from "./ActorTable.vue";
import { usernameErrors } from "@/scripts/misc/stringUtils";
import { t } from "@/scripts/language/i18n";

const emptyActor = (actorType: ActorType): ActorDto =>
  new ActorDto({
    id: 0,
    name: "",
    orgNo: "",
    regDate: new MomentX(),
    activatedDate: new MomentX(),
    inactivatedDate: undefined,
    lastActive: new MomentX(),
    mainContact: "",
    mainContactEmail: "",
    mainContactPhone: "",
    contractManager: "",
    contractManagerMail: "",
    contractManagerPhone: "",
    contractAddress: "",
    country: Country.Sweden,
    cargoTypes: [],
    active: true,
    internalNote: "",
    actorType: actorType,
    connectedClients: []
  });

export default Vue.extend({
  components: {
    DialogCard,
    ActorEditForm,
    TextSingle
  },
  props: {
    actorType: Number as () => ActorType | undefined
  },
  watch: {
    actorType: {
      handler() {
        if (this.actorType) {
          this.key = nextUnique();
          this.actor = emptyActor(this.actorType);
        }
      },
      immediate: true
    },
    username() {
      this.checkUsername();
    }
  },
  computed: {
    title(): string {
      if (!this.actorType) {
        return "";
      }
      return "Ny " + actorTypeName(this.actorType).toLocaleLowerCase();
    }
  },
  methods: {
    createActor() {
      this.saveInProgress = true;
      this.actor.actorType = this.actorType!;
      new ActorsClient(new ApiConfiguration(this.$store))
        .editForSupportAdmin(this.actor!)
        .then(actorId => {
          this.createUser(actorId);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    createUser(actorId: number) {
      new UsersClient(new ApiConfiguration(this.$store))
        .edit(
          new UserDto({
            actorId: actorId,
            loadUserId: 0,
            username: this.username,
            name: this.username,
            isEnabled: false,
            lockoutEnd: undefined,
            accessFailedCount: 0,
            email: "info@c-load.com",
            mobileNo: "",
            culture:
              this.actor.country === Country.Sweden
                ? Culture.Swedish
                : Culture.English,
            lastActivityDate: new MomentX(),
            createdDate: new MomentX(),
            internalNote: "",
            userLevel: UserLevel.SuperAdmin,
            actorInvoiceInformationId: undefined
          })
        )
        .then(() => {
          window.location.href =
            "/support-admin/actors?dialog=" +
            ActorListDialogType.UsersDialog +
            "&id=" +
            actorId;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    },
    checkUsername() {
      const usernameBeingChecked = this.username;
      const errors = usernameErrors(usernameBeingChecked);
      if (errors) {
        this.usernameError = errors;
        return;
      }
      this.checkingUsername = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .checkUsername(usernameBeingChecked!)
        .then(taken => {
          if (this.username === usernameBeingChecked) {
            this.usernameError = taken ? t("UsernameNotAvailable") : undefined;
            this.checkingUsername = false;
          }
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    actor: ActorDto;
    saveInProgress: boolean;
    validChanges: boolean;
    key: number;
    usernameError: string | undefined;
    checkingUsername: boolean;
    username: string;
  } => ({
    actor: emptyActor(ActorType.Client),
    saveInProgress: false,
    validChanges: false,
    key: nextUnique(),
    usernameError: undefined,
    checkingUsername: false,
    username: ""
  })
});
