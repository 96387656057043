













































































































































import Vue from "vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import {
  CargoLoadingContainer,
  LoadWithCargoLoadingContainer
} from "@/scripts/types";
import { RepositoryDto } from "@/scripts/cld.api";
import neverHappens from "@/scripts/misc/neverHappens";

export default Vue.extend({
  components: {
    ReadOnlyText,
    Dropdown,
    TextSingle,
    TextMulti,
    NumberInput
  },
  props: {
    load: Object as () => LoadWithCargoLoadingContainer,
    cargoLoadingContainers: Array as () => CargoLoadingContainer[],
    selectedCargoLoadingContainer: Object as () => CargoLoadingContainer,
    workingWithBlocking: Boolean
  },
  computed: {
    extraSecondsDropdownItems(): RepositoryDto[] {
      return [...Array(10).keys()].map(
        x =>
          new RepositoryDto({
            id: x * 300,
            text: `${x * 5} ${this.$t("LoweredMinutes")}`
          })
      );
    },
    blockingTon: {
      get(): number | undefined {
        const ton =
          this.cargoLoadingContainers[0].cargoLoading.kilograms / 1000;
        if (!ton) {
          return undefined;
        }
        return ton;
      },
      set(val: number | undefined) {
        if (!val) {
          val = 0;
        }
        if (val > 50) {
          val = 50;
        }
        if (val < -50) {
          val = -50;
        }
        this.cargoLoadingContainers[0].cargoLoading.loadingDockIds = [
          1,
          ...(val === 0 ? [2] : []),
          3
        ];
        this.cargoLoadingContainers[0].cargoLoading.kilograms = val * 1000;
      }
    },
    blockingMinutes: {
      get(): number {
        return this.cargoLoadingContainers[0].cargoLoading.seconds;
      },
      set(val: number) {
        this.cargoLoadingContainers[0].cargoLoading.seconds = val;
      }
    },
    blockingTimeItems(): RepositoryDto[] {
      return [
        ...[5, 30, 60].map(
          x =>
            new RepositoryDto({
              id: x * 60,
              text: `${x} ${this.$t("LoweredMinutes")}`
            })
        ),
        ...[2, 3, 4, 5, 6, 7, 8].map(
          x =>
            new RepositoryDto({
              id: x * 3600,
              text: `${x} timmar`
            })
        )
      ];
    }
  },
  methods: {
    buttonState(cc: CargoLoadingContainer): 1 | 2 | 3 | 4 {
      if (this.selectedCargoLoadingContainer === undefined) {
        if (cc.booking) {
          return 1;
        }
        return 2;
      }
      if (cc.id === this.selectedCargoLoadingContainer.id) {
        return 3;
      }
      return 4;
    },
    buttonText(cc: CargoLoadingContainer): string {
      const buttonState = this.buttonState(cc);
      switch (buttonState) {
        case 1:
          return this.$t("ChooseNewTime").toString();
        case 2:
          return this.$t("ChooseTime").toString();
        case 3:
          return this.$t("Cancel").toString();
        case 4:
          return this.$t("ChooseTime").toString();
        default:
          return neverHappens(buttonState);
      }
    },
    buttonColor(cc: CargoLoadingContainer): string {
      const buttonState = this.buttonState(cc);
      switch (buttonState) {
        case 1:
          return "warning";
        case 2:
          return "success";
        case 3:
          return "warning";
        case 4:
          return "";
        default:
          return neverHappens(buttonState);
      }
    },
    buttonDisabled(cc: CargoLoadingContainer): boolean {
      return this.buttonState(cc) === 4;
    },
    deleteDisabled(cc: CargoLoadingContainer): boolean {
      return this.buttonState(cc) !== 1;
    },
    okDisabled(cc: CargoLoadingContainer): boolean {
      return this.confirmTimeTouchCallback === undefined;
    },
    okVisible(cc: CargoLoadingContainer): boolean {
      return this.buttonState(cc) === 3;
    },
    buttonClicked(cc: CargoLoadingContainer) {
      const buttonState = this.buttonState(cc);
      switch (buttonState) {
        case 1:
          this.$emit("selectedCargoLoadingContainerIdChanged", cc.id);
          this.$emit("clearBooking", cc.id);
          break;
        case 2:
          this.$emit("selectedCargoLoadingContainerIdChanged", cc.id);
          break;
        case 3:
          this.$emit("selectedCargoLoadingContainerIdChanged", 0);
        case 4:
          break;
        default:
          neverHappens(buttonState);
      }
    },
    deleteClicked(cc: CargoLoadingContainer) {
      this.$emit("clearBooking", cc.id);
    },
    okClicked(cc: CargoLoadingContainer) {
      if (this.confirmTimeTouchCallback) {
        this.confirmTimeTouchCallback();
      } else {
        console.error("confirmTimeTouchCallback was undefined");
      }
    },
    isSelectedCC(id: number): boolean {
      return (
        this.selectedCargoLoadingContainer &&
        this.selectedCargoLoadingContainer.id === id
      );
    },
    loadingTimeText(cc: CargoLoadingContainer): string {
      return cc.booking
        ? `${cc.booking.startDate} - ${cc.booking.endDate.shortTimePrint()}`
        : "";
    }
  },
  mounted() {
    this.$root.$on(
      "confirmTimeTouchCallback",
      (callback: Function) => (this.confirmTimeTouchCallback = callback)
    );
  },
  data: (): { confirmTimeTouchCallback: Function | undefined } => ({
    confirmTimeTouchCallback: undefined
  })
});
