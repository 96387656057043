


















































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import ActorTable from "@/components/support-admin/actor/ActorTable.vue";
import MenuButton from "@/components/shared/input/MenuButton.vue";
import ActorCreateDialog from "@/components/support-admin/actor/ActorCreateDialog.vue";
import {
  SupportAdminActorListDto,
  ActorsClient,
  ActorType,
  ApiConfiguration
} from "@/scripts/cld.api";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { actions } from "@/scripts/store/constants";
import { actorTypes } from "@/scripts/misc/enumLists";
import { actorTypeIcon } from "@/scripts/misc/enumNames";
import { MenuButtonOption } from "@/components/shared/input/MenuButton.vue";
import { excelPrompt } from "@/scripts/misc/filePrompts";

export default Vue.extend({
  components: {
    SearchCard,
    ActorTable,
    MenuButton,
    ActorCreateDialog
  },
  computed: {
    actorTypes(): MenuButtonOption[] {
      return actorTypes().map(x => ({ ...x, icon: actorTypeIcon(x.id) }));
    }
  },
  methods: {
    search() {
      this.loading = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .listForSupportAdmin()
        .then(res => {
          this.actors = res;
          this.loading = false;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    setFilteredActorsList(filteredList: SupportAdminActorListDto[]) {
      this.filteredActorsList = filteredList.map(x => x.actorId);
    },
    exportSelectionToExcel() {
      this.exporting = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .exportSelectionForSupportAdmin(this.filteredActorsList)
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    exportToExcel() {
      this.exporting = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .exportForSupportAdmin()
        .then(res => {
          excelPrompt(res);
          this.exporting = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    createNewActor(actorType: ActorType) {
      this.createActorActorType = actorType;
    }
  },
  mounted() {
    this.search();
  },
  data: (): {
    loading: boolean;
    exporting: boolean;
    actors: SupportAdminActorListDto[];
    filteredActorsList: number[];
    createActorActorType: ActorType | undefined;
  } => ({
    loading: true,
    exporting: false,
    actors: [],
    filteredActorsList: [],
    createActorActorType: undefined
  })
});
