






















import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  props: {
    text1: String,
    text2: String,
    date1: MomentX,
    date2: MomentX
  }
});
