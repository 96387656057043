



























































































import Vue from "vue";
import {
  CargoType,
  RepositoryDto,
  SupplierProcurementPriceRowDto,
  SupplierProcurementRowDto,
  TruckType
} from "@/scripts/cld.api";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import { truckTypesByCargoType } from "@/scripts/misc/enumLists";
import { t } from "@/scripts/language/i18n";
import { percentage } from "@/scripts/misc/money";

export default Vue.extend({
  components: {
    NumberInput
  },
  props: {
    item: SupplierProcurementRowDto,
    cargoType: Number as () => CargoType,
    editable: Boolean
  },
  watch: {
    item: {
      handler() {
        this.item.priceRows!.map(p => this.updateFreightPrice(p));
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    priceRows(): (SupplierProcurementPriceRowDto | undefined)[] {
      return this.truckTypes.map(tt => this.priceRow(tt.id));
    },
    truckTypes(): RepositoryDto[] {
      return truckTypesByCargoType(this.cargoType);
    },
    isRoad(): boolean {
      return this.cargoType === CargoType.Road;
    },
    isMachineTransport(): boolean {
      return this.cargoType === CargoType.MachineTransport;
    }
  },
  methods: {
    prevRank(tt: TruckType): string {
      const prevRow = this.item.prevPriceRows!.find(p => p.truckType === tt);
      if (!prevRow || !prevRow.rank) {
        return "";
      }
      return prevRow.rank.toString();
    },
    priceRow(tt: TruckType): SupplierProcurementPriceRowDto | undefined {
      return this.item.priceRows!.find(p => p.truckType === tt);
    },
    prevPriceRow(tt: TruckType): SupplierProcurementPriceRowDto | undefined {
      return this.item.prevPriceRows!.find(x => x.truckType === tt);
    },
    updateFreightPrice(priceRow: SupplierProcurementPriceRowDto) {
      if (!priceRow.basePrice) {
        priceRow.freightPrice = undefined;
      } else {
        priceRow.freightPrice = Math.round(
          priceRow.basePrice * this.item.indexFactor
        );
      }
    },
    basePriceTooltip(priceRow: SupplierProcurementPriceRowDto): string {
      if (!priceRow.basePrice) {
        return "";
      }
      return `${
        this.isMachineTransport ? t("PricePerKm") : t("OfferedPrice")
      }: ${priceRow.basePrice}`;
    },
    freightPriceTooltip(priceRow: SupplierProcurementPriceRowDto): string {
      if (!priceRow.freightPrice) {
        return "";
      }
      return `${t("FreightPrice")}: ${priceRow.freightPrice} (${percentage(
        this.item.indexFactor - 1,
        2
      )})`;
    },
    currentRankingTooltip(priceRow: SupplierProcurementPriceRowDto): string {
      if (!priceRow.rank) {
        return "";
      }
      return `${t("RankingCurrentProcurement")}: ${priceRow.rank}`;
    },
    prevRankingTooltip(priceRow: SupplierProcurementPriceRowDto): string {
      const prevRank = this.prevRank(priceRow.truckType);
      if (!prevRank) {
        return "";
      }
      return `${t("PreviousAgreementRanking")}: ${prevRank}`;
    }
  }
});
