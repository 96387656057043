




import Vue from "vue";
import { Chart, ChartData, ChartItem, registerables } from "chart.js";
import money, { percentage } from "@/scripts/misc/money";
Chart.register(...registerables);

export enum AxisUnit {
  Number,
  SEK,
  SEKM3,
  Percentage
}

export const chartColors = (index: number): string => {
  const colors = [
    "#c62107",
    "#4d4d4d",
    "#dfd5bb",
    "#60b0e0",
    "#ffa500",
    "#43a047",
    "#e040fb",
    "#16dddd",
    "#457575",
    "#807060"
  ];
  return colors[index % colors.length];
};

export default Vue.extend({
  props: {
    chartData: Object as () => ChartData<"line", number[], string>,
    title: String,
    labelCallback: Function,
    yAxisUnit: Number as () => AxisUnit
  },
  watch: {
    chartData() {
      this.key++;
      this.$nextTick(() => {
        this.render();
      });
    }
  },
  methods: {
    render() {
      const ctx: ChartItem = document.getElementById("chart") as any;
      this.chartData.datasets.map(d => {
        d.borderWidth = d.borderWidth === undefined ? 2.5 : d.borderWidth;
        d.pointRadius = d.pointRadius === undefined ? 2 : d.pointRadius;
        d.pointBorderWidth =
          d.pointBorderWidth === undefined ? 4 : d.pointBorderWidth;
        d.hoverBorderWidth =
          d.hoverBorderWidth === undefined ? 7 : d.hoverBorderWidth;
      });
      new Chart(ctx, {
        type: "line",
        data: this.chartData,
        options: {
          plugins: {
            title: {
              text: this.title,
              display: !!this.title
            },
            tooltip: {
              callbacks: {
                label: this.labelCallback as any
              }
            }
          },
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: "Datum"
              }
            },
            y: {
              display: true,
              ticks: {
                callback: this.yAxisValue
              },
              title: {
                display: true,
                text: this.yAxisLabel()
              }
            }
          },
          onClick: (_, v) => {
            this.$emit("click", v[0].datasetIndex, v[0].index);
          }
        }
      });
    },
    yAxisValue(text: any): string {
      if (this.yAxisUnit === AxisUnit.Percentage) {
        return percentage(Number(text), 2);
      }
      return money(text, 0);
    },
    yAxisLabel(): string {
      switch (this.yAxisUnit) {
        case AxisUnit.Number:
          return "Antal";
        case AxisUnit.SEK:
          return "SEK";
        case AxisUnit.SEKM3:
          return "SEK/m3";
        case AxisUnit.Percentage:
          return "Procentuell andel";
      }
    }
  },
  data: (): { key: number } => ({ key: 1 })
});
