

























import Vue from "vue";
import { TableOptions } from "@/scripts/types";
import {
  ApiConfiguration,
  UserConfigurationType,
  InvoicedFeesClient,
  InvoicedFeesOrderBy,
  InvoicedFeesSearchCriteriaContainer,
  InvoicedFeesSearchCriteria,
  InvoicedFeesContainerDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import scrollTableToTop from "@/scripts/misc/scrollTableToTop";
import InvoicedFeesReportSearch from "@/components/support-admin/invoice/InvoicedFeesReportSearch.vue";
import InvoicedFeesReportTable from "@/components/support-admin/invoice/InvoicedFeesReportTable.vue";
import nextUnique from "@/scripts/misc/nextUnique";
import { excelPrompt } from "@/scripts/misc/filePrompts";

export default Vue.extend({
  components: {
    InvoicedFeesReportSearch,
    InvoicedFeesReportTable
  },
  computed: {
    sorting(): { orderBy: InvoicedFeesOrderBy; orderByDesc: boolean } {
      return {
        orderBy: this.tableOptions.sortBy[0] as InvoicedFeesOrderBy,
        orderByDesc: this.tableOptions.sortDesc[0]
      };
    },
    searchCriteria(): InvoicedFeesSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.InvoicedFees,
        1
      );
    }
  },
  methods: {
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.InvoicedFees,
          subTypeId: 1
        })
        .then(() => {
          this.tableOptions.sortBy = [InvoicedFeesOrderBy.FeeType];
          this.tableOptions.sortDesc = [false];
          this.search();
        });
    },
    search(tableOptionsTriggered?: boolean) {
      if (!this.searchCriteria.invoiceDate || !this.searchCriteria.actorId) {
        this.reportContainer = new InvoicedFeesContainerDto({
          count: 0,
          rows: []
        });
        return;
      }
      scrollTableToTop(this);
      const unique = nextUnique();
      this.loading = unique;
      new InvoicedFeesClient(new ApiConfiguration(this.$store))
        .report(
          new InvoicedFeesSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          if (!tableOptionsTriggered) {
            this.tableOptions.page = 1;
          }
          this.reportContainer = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.InvoicedFees,
        subTypeId: 1
      });
    },
    exportToExcel() {
      this.exportInProgress = true;
      new InvoicedFeesClient(new ApiConfiguration(this.$store))
        .export(
          new InvoicedFeesSearchCriteriaContainer({
            searchCriteria: this.searchCriteria,
            itemsPerPage: this.tableOptions.itemsPerPage,
            pageNumber: this.tableOptions.page,
            ...this.sorting
          })
        )
        .then(res => {
          excelPrompt(res);
          this.exportInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    tableOptionsChanged(options: TableOptions) {
      this.tableOptions = options;
      this.search(true);
    }
  },
  data: (): {
    loading: number;
    saveInProgress: boolean;
    exportInProgress: boolean;
    tableOptions: TableOptions;
    reportContainer: InvoicedFeesContainerDto;
  } => ({
    loading: 0,
    saveInProgress: false,
    exportInProgress: false,
    tableOptions: new TableOptions({
      itemsPerPage: 200,
      sortBy: [InvoicedFeesOrderBy.FeeType],
      sortDesc: [false]
    }),
    reportContainer: new InvoicedFeesContainerDto({
      count: 0,
      rows: []
    })
  })
});
