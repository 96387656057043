






















































































































import Vue from "vue";
import {
  AgreementRouteWithAgreementStatusDto,
  ApiConfiguration,
  DocumentsClient,
  LoadActionPermission,
  LoadClientCreateDto,
  LoadClientEditAssortedValuesDto,
  LoadClientEditContainersDto,
  LoadClientEditDateRequirementsDto,
  LoadClientEditDatesDto,
  LoadClientEditDocumentsDto,
  LoadClientEditDto,
  LoadClientEditInfoTextsDto,
  LoadClientEditLocationDto,
  LoadClientEditRelatedLoadsSaveDto,
  LoadClientEditSubLocations,
  LoadClientEditSupplierBookingInfoDto,
  LoadClientPublishValues,
  LoadDetailsDeviationDto,
  LoadDocumentDto,
  LoadsClient,
  LoadsClientClient,
  LoadStatus,
  LoadValuePermissionDto,
  CargoType,
  PublishSpeed,
  RelatedLoadsDto,
  TruckType,
  UserConfigurationType
} from "@/scripts/cld.api";
import {
  errorDialog,
  popupDialog,
  saveBeforeContinueDialog
} from "@/scripts/misc/popupDialogs";
import { cargoTypeName, loadStatusName } from "@/scripts/misc/enumNames";
import { actions, gets, mutations } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import MomentX from "@/scripts/misc/momentX";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Accordion from "@/components/web/loads/client/accordion/Accordion.vue";
import LoadEditBaseValues from "@/components/web/loads/client/edit/LoadEditBaseValues.vue";
import LoadEditDates from "@/components/web/loads/client/edit/LoadEditDates.vue";
import LoadEditShippingInfo from "@/components/web/loads/client/edit/LoadEditShippingInfo.vue";
import LoadEditContainerInfo from "@/components/web/loads/client/edit/LoadEditContainerInfo.vue";
import LoadEditMachineTransportInfo from "@/components/web/loads/client/edit/LoadEditMachineTransportInfo.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import { CreateLoadPresets } from "@/scripts/types";
import { UserConfigurationEntry } from "@/scripts/store/userConfModule";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import { t } from "@/scripts/language/i18n";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    DialogCard,
    Accordion,
    LoadEditBaseValues,
    LoadEditDates,
    LoadEditShippingInfo,
    LoadEditContainerInfo,
    LoadEditMachineTransportInfo,
    LoadLock
  },
  props: {
    dialog: Boolean,
    loadCreateCargoType: Number as () => CargoType,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    },
    changeTrackingObject: {
      handler() {
        if (!this.dialog || this.pendingChangesSemaphore > 0) {
          return;
        }
        const changeTrackingString = JSON.stringify(this.changeTrackingObject);
        if (!this.pendingChangesTrackingString) {
          this.pendingChangesTrackingString = changeTrackingString;
        }
        this.pendingChanges =
          changeTrackingString !== this.pendingChangesTrackingString;
      },
      deep: true
    }
  },
  computed: {
    title(): string {
      if (this.loadStatus && this.loadId) {
        return `${t("EditLoad")} - ${t("LoadID")}: ${
          this.loadId
        } (${loadStatusName(this.loadStatus)})`;
      }
      return `${t("CreateNewLoad")} (${this.cargoTypeName})`;
    },
    loadId(): number {
      return this.loadIds[0]!;
    },
    cargoTypeName(): string {
      if (this.cargoType === undefined) {
        return "";
      }
      return cargoTypeName(this.cargoType as number);
    },
    step(): number {
      if (!this.agreementRouteId) {
        return 1;
      }
      if (!this.truckType) {
        return 2;
      }
      if (
        !this.location ||
        !this.location.pickupLocationId ||
        !this.location.deliveryLocationId
      ) {
        return 3;
      }
      return 4;
    },
    cargoType(): CargoType | undefined {
      return this.publishValues !== undefined
        ? this.publishValues.cargoType
        : undefined;
    },
    agreementRouteId(): number | undefined {
      return this.publishValues !== undefined
        ? this.publishValues.agreementRouteId
        : undefined;
    },
    truckType(): TruckType | undefined {
      return this.publishValues !== undefined
        ? this.publishValues.truckType
        : undefined;
    },
    publishSpeed(): PublishSpeed | undefined {
      return this.publishValues !== undefined
        ? this.publishValues.publishSpeed
        : undefined;
    },
    agreementDate(): MomentX {
      let date: MomentX;
      if (this.dates && this.dates.pickupEarliest) {
        date = this.dates.pickupEarliest;
      } else {
        date = new MomentX();
      }
      return date.startOfDay();
    },
    changeTrackingObject(): any[] {
      return [
        this.dates,
        this.dateRequirements,
        this.location,
        this.infoTexts,
        this.assortedValues,
        this.publishValues,
        this.supplierBookingInfo,
        this.subLocations,
        this.relatedLoads,
        this.containerValues,
        this.documents
      ];
    },
    createLoadText(): string {
      let text = t("CreateNewLoad");
      if (this.duplicateTransportNos.length > 0) {
        text += "(" + (this.duplicateTransportNos.length + 1) + ")";
      }
      return text;
    },
    agreementRoute(): AgreementRouteWithAgreementStatusDto | undefined {
      if (this.publishValues === undefined) {
        return undefined;
      }
      return this.agreementRoutes.find(
        ar => ar.id === this.publishValues!.agreementRouteId
      );
    }
  },
  methods: {
    close() {
      if (this.pendingChanges) {
        if (this.loadId === undefined) {
          popupDialog({
            title: t("UnsavedChanges"),
            body: t("LoadNotCreatedYet"),
            btnText1: t("Edit"),
            btnColor1: "success",
            btnCallback1: () => {},
            btnText2: t("DontSave"),
            btnColor2: "error",
            btnCallback2: () => {
              this.actuallyClose();
            }
          });
        } else {
          saveBeforeContinueDialog(
            async () => {
              await this.editLoad();
            },
            () => {
              this.actuallyClose();
            }
          );
        }
      } else {
        this.actuallyClose();
      }
    },
    actuallyClose() {
      this.$emit("close");
      this.actionInProgress = false;
      this.pendingChangesTrackingString = undefined;
      this.pendingChangesSemaphore = Number.POSITIVE_INFINITY;
      this.pendingChanges = false;
      this.resetFormValidation();
    },
    async validateForm() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        if (form !== undefined) {
          form.validate();
        }
      });
    },
    async resetFormValidation() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        if (form !== undefined) {
          form.reset();
        }
      });
    },
    init() {
      if (this.loadId) {
        this.initEdit();
      } else {
        this.initCreation();
        this.initPresets();
        this.pendingChangesSemaphore = 0;
      }
    },
    initEdit() {
      this.pendingChangesSemaphore = 4;
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .preEdit(this.loadId)
        .then(res => {
          this.loadStatus = res.loadStatus;
          this.dates = res.dates;
          this.dateRequirements = res.dateRequirements;
          this.location = res.location;
          this.infoTexts = res.infoTexts;
          this.assortedValues = res.assortedValues;
          this.publishValues = res.publishValues;
          this.supplierBookingInfo = res.supplierBookingInfo;
          this.valuePermissions = res.permissions!;
          this.deviation = res.deviation;
          this.customsInfoRequired = res.customsInfoRequired;
        })
        .then(() => {
          this.fetchRelatedLoads();
          this.fetchSubLocations();
          this.fetchContainers();
          this.fetchDocuments();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    initCreation() {
      this.loadStatus = undefined;
      this.publishValues = new LoadClientPublishValues({
        agreementRouteId: undefined as any,
        truckType: undefined as any,
        publishSpeed: PublishSpeed.Normal,
        cargoType: this.loadCreateCargoType
      });
      this.location = new LoadClientEditLocationDto({
        pickupLocationId: undefined as any,
        deliveryLocationId: undefined as any,
        pickupStreetAddress: undefined as any,
        deliveryStreetAddress: undefined as any
      });
      this.infoTexts = new LoadClientEditInfoTextsDto({
        pickupInfo: undefined,
        destinationInfo: undefined,
        note: undefined,
        contentInfo: undefined
      });
      this.dates = new LoadClientEditDatesDto({
        pickupEarliest: undefined as any,
        pickupLatest: undefined,
        deliverEarliest: undefined,
        deliverLatest: undefined as any
      });
      this.dateRequirements = new LoadClientEditDateRequirementsDto({
        bookedPickup: false,
        bookedDelivery: false
      });
      this.assortedValues = new LoadClientEditAssortedValuesDto({
        truckNo: undefined,
        loadOrderNo: undefined,
        blNo: undefined,
        transportNo: undefined,
        consignmentNote: undefined,
        volume: undefined,
        weight: undefined,
        packages: undefined,
        unit: undefined,
        referenceNo: undefined,
        terminalId: undefined,
        customerId: undefined,
        distance: undefined,
        vtlPrice: undefined,
        escortCarPrice: undefined,
        bookingNo: undefined
      });
      this.subLocations = new LoadClientEditSubLocations({
        subPickupLocations: [],
        subDeliveryLocations: []
      });
      this.relatedLoads = new RelatedLoadsDto({
        parentLoads: [],
        childLoads: []
      });
      this.containerValues = new LoadClientEditContainersDto({
        incoterms: undefined,
        knCurrency: undefined,
        knValue: undefined,
        knProductCode: undefined,
        eta: undefined,
        etd: undefined,
        dateForClosing: undefined,
        stuffingLocation: undefined,
        shipName: undefined,
        sumPackages: undefined,
        sumVolume: undefined,
        sumWeight: undefined,
        thcValue: undefined,
        thcCurrency: undefined,
        containers: []
      });
      this.documents = [];
    },
    getLoadClientCreateDtos(): LoadClientCreateDto[] {
      return [
        this.assortedValues!.transportNo,
        ...this.duplicateTransportNos
      ].map(
        transportNo =>
          new LoadClientCreateDto({
            dates: this.dates,
            dateRequirements: this.dateRequirements,
            location: this.location,
            infoTexts: this.infoTexts,
            assortedValues: new LoadClientEditAssortedValuesDto({
              truckNo: this.assortedValues!.truckNo,
              loadOrderNo: this.assortedValues!.loadOrderNo,
              blNo: this.assortedValues!.blNo,
              transportNo: transportNo,
              consignmentNote: this.assortedValues!.consignmentNote,
              volume: this.assortedValues!.volume,
              weight: this.assortedValues!.weight,
              packages: this.assortedValues!.packages,
              unit: this.assortedValues!.unit,
              referenceNo: this.assortedValues!.referenceNo,
              terminalId: this.assortedValues!.terminalId,
              customerId: this.assortedValues!.customerId,
              distance: this.assortedValues!.distance,
              vtlPrice: this.assortedValues!.vtlPrice,
              escortCarPrice: this.assortedValues!.escortCarPrice,
              bookingNo: this.assortedValues!.bookingNo
            }),
            publishValues: this.publishValues,
            subLocations: this.subLocations,
            relatedLoads: new LoadClientEditRelatedLoadsSaveDto({
              parents: this.relatedLoads!.parentLoads!.map(x => x.loadId),
              children: this.relatedLoads!.childLoads!.map(x => x.loadId)
            }),
            containerValues: this.containerValues,
            documents: new LoadClientEditDocumentsDto({
              documentIds: this.documents!.map(d => d.id)
            })
          })
      );
    },
    createLoad() {
      this.actionInProgress = true;
      this.savePresets();
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .create(this.getLoadClientCreateDtos())
        .then(results => {
          results.map(res => {
            pushLoadNotification("create", res);
          });
          this.actuallyClose();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    editLoad(): Promise<void> {
      this.actionInProgress = true;
      return new LoadsClientClient(new ApiConfiguration(this.$store))
        .edit(
          new LoadClientEditDto({
            loadId: this.loadId,
            dates: this.dates,
            dateRequirements: this.dateRequirements,
            location: this.location,
            infoTexts: this.infoTexts,
            assortedValues: this.assortedValues,
            supplierBookingInfo: this.supplierBookingInfo,
            subLocations: this.subLocations,
            relatedLoads: new LoadClientEditRelatedLoadsSaveDto({
              parents: this.relatedLoads!.parentLoads!.map(x => x.loadId),
              children: this.relatedLoads!.childLoads!.map(x => x.loadId)
            }),
            containerValues: this.containerValues,
            documents: new LoadClientEditDocumentsDto({
              documentIds: this.documents!.map(d => d.id)
            })
          })
        )
        .then(res => {
          pushLoadNotification("edit", res);
          this.actuallyClose();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {});
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    fetchRelatedLoads() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .relatedLoads(this.loadId)
        .then(res => {
          this.relatedLoads = res;
          this.pendingChangesSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchSubLocations() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .sublocations(this.loadId)
        .then(res => {
          this.subLocations = res;
          this.pendingChangesSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchContainers() {
      new LoadsClient(new ApiConfiguration(this.$store))
        .containers(this.loadId)
        .then(res => {
          this.containerValues = res;
          this.pendingChangesSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocuments() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .loadDocuments(this.loadId)
        .then(res => {
          this.documents = res;
          this.pendingChangesSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    initPresets() {
      let conf = this.$store.getters[gets.userConf](
        UserConfigurationType.CreateLoad,
        1
      );
      this.assortedValues!.unit = conf.unit;
      this.assortedValues!.referenceNo = conf.referenceNo;
    },
    savePresets() {
      const c = new CreateLoadPresets(
        this.assortedValues!.unit,
        this.assortedValues!.referenceNo
      );
      this.$store.commit(mutations.setUserConf, {
        type: UserConfigurationType.CreateLoad,
        subTypeId: 1,
        conf: c
      } as UserConfigurationEntry);
      this.$store.dispatch(actions.saveUserConf, {
        type: UserConfigurationType.CreateLoad,
        subTypeId: 1
      });
    },
    fetchAgreementRoutes() {
      cached("agreementRoutes", this.cargoType!, this.agreementDate)
        .then(res => {
          this.agreementRoutes = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    actionInProgress: boolean;
    valid: boolean;
    loadStatus?: LoadStatus;
    dates?: LoadClientEditDatesDto;
    dateRequirements?: LoadClientEditDateRequirementsDto;
    location?: LoadClientEditLocationDto;
    infoTexts?: LoadClientEditInfoTextsDto;
    assortedValues?: LoadClientEditAssortedValuesDto;
    publishValues?: LoadClientPublishValues;
    supplierBookingInfo?: LoadClientEditSupplierBookingInfoDto;
    subLocations?: LoadClientEditSubLocations;
    relatedLoads?: RelatedLoadsDto;
    containerValues?: LoadClientEditContainersDto;
    documents?: LoadDocumentDto[];
    pendingChangesTrackingString?: string;
    pendingChangesSemaphore: number;
    pendingChanges: boolean;
    valuePermissions: LoadValuePermissionDto[];
    deviation: LoadDetailsDeviationDto | undefined;
    duplicateTransportNos: string[];
    customsInfoRequired: boolean;
    agreementRoutes: AgreementRouteWithAgreementStatusDto[];
  } => ({
    actionInProgress: false,
    valid: false,
    loadStatus: undefined,
    dates: undefined,
    dateRequirements: undefined,
    location: undefined,
    infoTexts: undefined,
    assortedValues: undefined,
    publishValues: undefined,
    supplierBookingInfo: undefined,
    subLocations: undefined,
    relatedLoads: undefined,
    containerValues: undefined,
    documents: undefined,
    pendingChangesTrackingString: undefined,
    pendingChangesSemaphore: Number.POSITIVE_INFINITY,
    pendingChanges: false,
    valuePermissions: [],
    deviation: undefined,
    duplicateTransportNos: [],
    customsInfoRequired: false,
    agreementRoutes: []
  })
});
