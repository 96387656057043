



























































































import Vue from "vue";
import {
  ApiConfiguration,
  ReorderLoadDto,
  LoadsClientClient,
  LoadActionPermission
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import PublishSpeedSlider from "@/components/shared/input/PublishSpeedSlider.vue";
import LoadDialogMultiTable from "@/components/web/loads/shared/LoadDialogMultiTable.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import { MultiLoad } from "@/scripts/types";
import {
  PickupDeliveryRules,
  pickupDeliveryRules
} from "@/scripts/misc/dateUtils";

export default Vue.extend({
  components: {
    DialogCard,
    DatePicker,
    PublishSpeedSlider,
    LoadDialogMultiTable,
    LoadLock,
    LoadChips
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    },
    selectedLoadId: {
      handler() {
        this.$nextTick(() => {
          let form: any = this.$refs.form;
          if (form !== undefined) {
            form.validate();
          }
        });
      },
      immediate: true
    }
  },
  computed: {
    load(): MultiLoad {
      return this.loads.find(l => l.loadId === this.selectedLoadId)!;
    },
    multi(): boolean {
      return this.loadIds.length > 1;
    },
    saveDto(): ReorderLoadDto {
      return this.saveDtos.find(s => s.loadId === this.selectedLoadId)!;
    },
    allLoadsValid(): boolean {
      return this.loads.length > 0 && this.loads.every(l => l.valid);
    },
    dateRules(): PickupDeliveryRules {
      return pickupDeliveryRules(
        this.saveDto.pickupEarliest,
        this.saveDto.pickupLatest,
        this.saveDto.deliveryEarliest
      );
    }
  },
  methods: {
    async reorder() {
      this.saveInProgress = true;
      let saveOk = true;
      for (const saveDto of this.saveDtos) {
        await new LoadsClientClient(new ApiConfiguration(this.$store))
          .reorder(saveDto)
          .then(res => {
            pushLoadNotification("reorder", res);
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = true;
          })
          .catch(error => {
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = false;
            saveOk = false;
            if (ble(error)) {
              errorDialog(error, () => {});
            } else {
              this.$store.dispatch(actions.handleApiError, error);
            }
          });
      }
      if (saveOk) {
        this.close();
      }
    },
    close() {
      this.$emit("close");
    },
    init() {
      new LoadsClientClient(new ApiConfiguration(this.$store))
        .preReorder(this.loadIds)
        .then(res => {
          this.saveDtos = res.map(
            x =>
              new ReorderLoadDto({
                loadId: x.loadId,
                pickupEarliest: x.pickupEarliest,
                pickupLatest: x.pickupLatest,
                deliveryEarliest: x.deliveryEarliest,
                deliveryLatest: x.deliveryLatest,
                publishSpeed: x.publishSpeed
              })
          );
          this.loads = res.map(x => ({
            loadId: x.loadId,
            routeName: x.routeName!,
            pickupEarliest: x.pickupEarliest,
            deliverLatest: x.deliveryLatest,
            truckType: x.truckType,
            transportNo: x.transportNo,
            clientName: undefined,
            numExtraStops: x.numExtraStops,
            numContainers: x.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          }));
          this.selectedLoadId = this.loads[0].loadId;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    saveInProgress: boolean;
    selectedLoadId: number | undefined;
    loads: MultiLoad[];
    saveDtos: ReorderLoadDto[];
  } => ({
    saveInProgress: false,
    selectedLoadId: undefined,
    loads: [],
    saveDtos: []
  })
});
