import { Module } from "vuex";
import { RootState } from "@/scripts/store/store";
import { actions, gets, mutations } from "@/scripts/store/constants";
import {
  ApiConfiguration,
  LoadActionPermission,
  LoadActionPermissionContainerDto,
  LoadLockDto,
  LoadsClient,
  LoadStatus,
  LockStatus
} from "@/scripts/cld.api";
import { LoadAction } from "@/scripts/types";
import { loadStatusIcon } from "../misc/enumNames";

export interface LoadState {
  locks: LoadLockDto[];
  permissions: LoadActionPermissionContainerDto[];
}

const state: LoadState = {
  locks: [],
  permissions: []
};

export const loadModule: Module<LoadState, RootState> = {
  state: state,
  getters: {
    [gets.lock]: (state): { (loadId: number): LoadLockDto | undefined } => {
      return (loadId: number) => {
        return state.locks.find(l => l.loadId === loadId);
      };
    },
    [gets.lockNeeded]: (
      state,
      getters
    ): {
      (actionId: LoadActionPermission | undefined, loadIds: number[]): boolean;
    } => {
      return (
        actionId: LoadActionPermission | undefined,
        loadIds: number[]
      ) => {
        if (!actionId || !loadIds.length) {
          return false;
        }
        return loadIds.some(
          id =>
            getters[gets.loadActions](id).find(
              (a: LoadAction) => a.id === actionId
            )!.lockNeeded
        );
      };
    },
    [gets.loadActions]: (state): { (loadId: number): LoadAction[] } => {
      return (loadId: number) => {
        const loadActions: LoadAction[] = [
          new LoadAction(
            LoadActionPermission.Details,
            "Details",
            "mdi-text-box-search-outline"
          ),
          new LoadAction(LoadActionPermission.ClientEdit, "Edit", "mdi-pencil"),
          new LoadAction(
            LoadActionPermission.Book,
            "Book",
            "mdi-book-check-outline"
          ),
          new LoadAction(
            LoadActionPermission.Load,
            "SetLoaded",
            "mdi-tray-arrow-down"
          ),
          new LoadAction(
            LoadActionPermission.Deliver,
            "Deliver",
            "mdi-tray-arrow-up"
          ),
          new LoadAction(
            LoadActionPermission.Unbook,
            "UnBook",
            "mdi-book-remove-outline"
          ),
          new LoadAction(
            LoadActionPermission.CreateSpotTender,
            "StartSpotTender",
            loadStatusIcon(LoadStatus.Ordered, true)
          ),
          new LoadAction(
            LoadActionPermission.EditSpotTender,
            "ManageSpotTender",
            loadStatusIcon(LoadStatus.Ordered, true)
          ),
          new LoadAction(
            LoadActionPermission.SupplierSpotTender,
            "ManageSpotTender",
            loadStatusIcon(LoadStatus.Ordered, true)
          ),
          new LoadAction(
            LoadActionPermission.Reorder,
            "ReorderLoad",
            "mdi-undo-variant"
          ),
          new LoadAction(
            LoadActionPermission.Resign,
            "Resign",
            "mdi-book-cancel-outline"
          ),
          new LoadAction(
            LoadActionPermission.SupplierEdit,
            "Edit",
            "mdi-pencil"
          ),
          new LoadAction(
            LoadActionPermission.Annul,
            "Annul",
            "mdi-trash-can-outline"
          ),
          new LoadAction(
            LoadActionPermission.Deviation,
            "Deviations",
            "mdi-sticker-alert-outline"
          ),
          new LoadAction(
            LoadActionPermission.ContainerExport,
            "ContainerExport",
            "mdi-file-pdf-box"
          ),
          new LoadAction(
            LoadActionPermission.ContainerEdit,
            "EditContainers",
            "mdi-playlist-edit"
          ),
          new LoadAction(
            LoadActionPermission.SupplierEditDocuments,
            "Documents",
            "mdi-file-document-edit-outline"
          ),
          new LoadAction(
            LoadActionPermission.LoadingSlotEdit,
            "EditLoadingSlot",
            "mdi-calendar-edit"
          )
        ];
        const permission = state.permissions.find(p => p.loadId === loadId);
        const allowedActions = permission ? permission.allowedActions! : [];

        loadActions.map(a => {
          a.allowed =
            allowedActions.find(aa => aa.action === a.id && aa.allowed) !==
            undefined;
          a.lockNeeded =
            allowedActions.find(aa => aa.action === a.id && aa.lockNeeded) !==
            undefined;
        });
        return loadActions;
      };
    }
  },
  mutations: {
    [mutations.setLocks]: (state, locks: LoadLockDto[]) => {
      state.locks = locks;
    },
    [mutations.updateLock]: (state, locks: LoadLockDto[]) => {
      state.locks = [
        ...state.locks.filter(l => !locks.some(ll => ll.loadId === l.loadId)),
        ...locks
      ];
    },
    [mutations.removeLock]: (state, loadIds: number[]) => {
      state.locks = state.locks.filter(l => !loadIds.includes(l.loadId));
    },
    [mutations.tickLocks]: state => {
      state.locks.map(l => l.secondsLeft--);
    },
    [mutations.setPermissions]: (
      state,
      permissions: LoadActionPermissionContainerDto[]
    ) => {
      state.permissions = [
        ...permissions,
        ...state.permissions.filter(
          p => !permissions.some(x => x.loadId === p.loadId)
        )
      ];
    }
  },
  actions: {
    [actions.fetchLocks]: (context, loadIds: number[]) => {
      return new LoadsClient(new ApiConfiguration(context))
        .lockStatus(loadIds)
        .then(res => {
          context.commit(mutations.setLocks, res);
        });
    },
    [actions.lock]: (context, loadIds: number[]): Promise<boolean> => {
      return new LoadsClient(new ApiConfiguration(context))
        .lock(loadIds)
        .then(res => {
          context.commit(mutations.updateLock, res);
          return loadIds.every(id =>
            res.some(
              r =>
                r.loadId === id &&
                r.lockStatus !== LockStatus.LockedBySomeoneElse
            )
          );
        });
    },
    [actions.unlock]: (context, loadIds: number[]) => {
      return new LoadsClient(new ApiConfiguration(context))
        .unlock(loadIds)
        .then(() => {
          context.commit(mutations.removeLock, loadIds);
        });
    },
    [actions.fetchPermissions]: (context, loadIds: number[]) => {
      context.commit(mutations.setPermissions, []);
      return new LoadsClient(new ApiConfiguration(context))
        .allowedActions(loadIds)
        .then(res => {
          context.commit(mutations.setPermissions, res);
        });
    }
  }
};
