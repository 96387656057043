
































































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import { t } from "@/scripts/language/i18n";
import {
  AnonymousLoadDetailsDto,
  ApiConfiguration,
  LoadStatus,
  MobileClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    SearchCard,
    ReadOnlyText
  },
  watch: {
    encodedLoadId: {
      handler() {
        this.fetchDetails();
      },
      immediate: true
    }
  },
  computed: {
    encodedLoadId(): string | undefined {
      const id = this.$route.query.id;
      if (!id) {
        return undefined;
      }
      return this.$route.query.id as string;
    },
    title(): string {
      if (!this.details) {
        return "";
      }
      return `${t("TransportNo")}: ${this.details.transportNo}`;
    },
    pickup(): string {
      return `${this.details!.pickupEarliest} - ${this.details!.pickupLatest ||
        this.details!.deliverEarliest ||
        this.details!.deliverLatest}`;
    },
    delivery(): string {
      return `${this.details!.deliverEarliest ||
        this.details!.pickupLatest ||
        this.details!.pickupEarliest} - ${this.details!.deliverLatest}`;
    },
    slotVisible(): boolean {
      if (!this.details) {
        return false;
      }
      return (
        this.details.loadStatus === LoadStatus.Booked &&
        this.details.slotTimeBooking
      );
    },
    deliverVisible(): boolean {
      if (!this.details) {
        return false;
      }
      return this.details.loadStatus === LoadStatus.Loaded;
    }
  },
  methods: {
    deliver() {
      this.$router.push("/mobile/deliver?tn=" + this.details!.transportNo);
    },
    slot() {
      this.$router.push("/mobile/slots?id=" + this.$route.query.id);
    },
    fetchDetails() {
      if (!this.encodedLoadId) {
        this.details = undefined;
        return;
      }
      new MobileClient(new ApiConfiguration(this.$store))
        .details(this.encodedLoadId)
        .then(res => {
          this.details = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    details: AnonymousLoadDetailsDto | undefined;
  } => ({
    details: undefined
  })
});
