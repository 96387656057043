




























































import Vue from "vue";
import {
  ApiConfiguration,
  RepositoryDto,
  StatisticsClient,
  HeatMapSearchCriteria,
  UserConfigurationType,
  ActorsClient,
  RepositoryClient
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import BezierChart, {
  AxisUnit,
  chartColors
} from "@/components/shared/charts/BezierChart.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DropdownMulti from "@/components/shared/input/DropdownMulti.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import cached from "@/scripts/misc/cached";
import { ChartData, ChartDataset } from "chart.js";

export default Vue.extend({
  components: {
    SearchCard,
    BezierChart,
    DatePicker,
    Autocomplete,
    Dropdown,
    DropdownMulti,
    SkeletonLoader
  },
  watch: {
    criteria: {
      handler() {
        this.search();
      },
      immediate: true,
      deep: true
    },
    "criteria.clientId": {
      handler() {
        this.fetchUnits();
      },
      immediate: true
    }
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 290);
    },
    criteria(): HeatMapSearchCriteria {
      return this.$store.getters[gets.userConf](
        UserConfigurationType.HeatMap,
        1
      );
    },
    labels(): string[] {
      const days = ["M", "T", "O", "T", "F", "L", "S"];
      const hours = [...Array(24 / this.criteria.hoursPerGroup).keys()];
      const res: string[] = [];
      days.map(d =>
        res.push(
          ...hours.map(
            h =>
              d +
              "." +
              (h * this.criteria.hoursPerGroup).toString().padStart(2, "0")
          )
        )
      );
      return res;
    },
    chartData(): ChartData<"line", number[], string> {
      let labels: string[] = [];
      let datasets: ChartDataset<"line", number[]>[] = [];
      if (this.statistics.length > 0) {
        labels = this.labels;
        datasets = [
          {
            label: "Lastningar",
            cubicInterpolationMode: "monotone",
            borderColor: chartColors(0),
            data: this.statistics
          }
        ];
      }
      return {
        labels: labels,
        datasets: datasets
      };
    }
  },
  methods: {
    search() {
      this.loading = true;
      new StatisticsClient(new ApiConfiguration(this.$store))
        .heatMap(this.criteria)
        .then(res => {
          this.statistics = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchClients() {
      new ActorsClient(new ApiConfiguration(this.$store))
        .clientsForSupportAdmin(true)
        .then(res => {
          this.clients = res.filter(c => c.id !== 49);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchUnits() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .unitsSupportAdmin(this.criteria.clientId)
        .then(res => {
          this.units = res.map(
            u =>
              new RepositoryDto({
                id: u as any,
                text: u
              })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    clearSelection() {
      this.$store
        .dispatch(actions.resetUserConf, {
          type: UserConfigurationType.DistanceReport,
          subTypeId: 1
        })
        .then(() => {
          this.search();
        });
    }
  },
  mounted() {
    this.fetchClients();
  },
  data: (): {
    loading: boolean;
    statistics: number[];
    yAxisUnit: AxisUnit;
    clients: RepositoryDto[];
    units: RepositoryDto[];
    hoursPerGroupItems: RepositoryDto[];
  } => ({
    loading: false,
    statistics: [],
    yAxisUnit: AxisUnit.Number,
    clients: [],
    units: [],
    hoursPerGroupItems: [1, 3, 6, 24].map(
      h =>
        new RepositoryDto({
          id: h,
          text: `Per ${h}h`
        })
    )
  })
});
