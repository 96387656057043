













































import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";

export default Vue.extend({
  components: {
    SearchCard
  }
});
