









































































import Vue from "vue";
import {
  CreateSpotTenderDto,
  QualityPointsPastYearDto,
  RepositoryDto
} from "@/scripts/cld.api";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import SupplierSelectTable from "@/components/web/agreements/client/SupplierSelectTable.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import { stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    SearchCard,
    Checkbox,
    SupplierSelectTable
  },
  props: {
    saveDto: CreateSpotTenderDto,
    suppliers: Array as () => RepositoryDto[],
    rankedSupplierIds: Array as () => number[],
    qualityPoints: Array as () => QualityPointsPastYearDto[]
  },
  watch: {
    supplierIds: {
      handler() {
        this.$nextTick(() => {
          this.$emit(
            "change",
            this.supplierIds.length > 0,
            this.supplierIds.length
          );
        });
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    supplierIds(): number[] {
      return this.saveDto.supplierIds!;
    },
    included(): RepositoryDto[] {
      return this.suppliers.filter(s => this.supplierIds.includes(s.id));
    },
    excluded(): RepositoryDto[] {
      return this.suppliers
        .filter(s => !this.supplierIds.includes(s.id))
        .filter(
          s =>
            this.suppliersWithAgreements ||
            !this.rankedSupplierIds.includes(s.id)
        )
        .filter(
          s =>
            this.suppliersWithoutAgreements ||
            this.rankedSupplierIds.includes(s.id)
        );
    },
    filteredIncluded(): RepositoryDto[] {
      return this.included.filter(s =>
        stringSearchHit(this.includedFilter, s.text, false)
      );
    },
    filteredExcluded(): RepositoryDto[] {
      return this.excluded.filter(s =>
        stringSearchHit(this.excludedFilter, s.text, false)
      );
    }
  },
  methods: {
    include(id: number) {
      this.supplierIds.push(id);
    },
    exclude(id: number) {
      this.supplierIds.splice(this.supplierIds.indexOf(id), 1);
    },
    includeFiltered() {
      this.supplierIds.push(...this.filteredExcluded.map(s => s.id));
    },
    excludeFiltered() {
      const keep = this.supplierIds.filter(
        s => !this.filteredIncluded.map(x => x.id).includes(s)
      );
      this.supplierIds.splice(0);
      this.supplierIds.push(...keep);
    }
  },
  data: (): {
    suppliersWithAgreements: boolean;
    suppliersWithoutAgreements: boolean;
    includedFilter: string;
    excludedFilter: string;
  } => ({
    suppliersWithAgreements: true,
    suppliersWithoutAgreements: true,
    includedFilter: "",
    excludedFilter: ""
  })
});
