





















import Vue from "vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import {
  ApiConfiguration,
  MailviewerClient,
  RenderTemplateDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    TextMulti
  },
  methods: {
    renderTemplate() {
      this.renderInProgress = true;
      new MailviewerClient(new ApiConfiguration(this.$store))
        .renderEmail(
          new RenderTemplateDto({
            template: this.template,
            data: {}
          })
        )
        .then(res => {
          this.result = res;
          this.renderInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    renderInProgress: boolean;
    template: string;
    result: string;
  } => ({
    renderInProgress: false,
    template: `<span notnull="m.BookedPickupDate">Hej1</span>
    <span notnull="m.StatusId">Hej1.2</span>
<span>Hej2<span if="m.Broadcast">Hej3</span></span>
Hej

r.PickupEarliest: <minutes value="m.PickupEarliest"/>
<a href="m.Link">r.ToCload</a>
m.ClientName
<c-location value="m.PickupLocation" />
<c-location value="m.DeliveryLocation" />
<c-location loop="m.SubPickupLocations" />

<c-load-info value="m.LoadInfo" value2="m" />

<c-deep value="m.PickupLocation" />

<table>
r.blablabla: m.StatusId
r.pla: m.StatusId
r.fla: m.StatusId
<span>r.kla: m.StatusId</span>
<span notnull="m.BookedPickupDate">r.jla: m.StatusId</span>
</table>`,
    result: ""
  })
});
