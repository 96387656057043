



















































































import Vue from "vue";
import { FileParameter, LoadDocumentDto } from "@/scripts/cld.api";
import AccordionDocumentAddDialog, {
  DocumentIdDiskNameDisplayName
} from "@/components/web/loads/client/accordion/AccordionDocumentAddDialog.vue";
import FileInput from "@/components/shared/input/FileInput.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import DocumentDropZone from "@/components/web/documents/DocumentDropZone.vue";
import { viewDocument } from "@/components/shared/ui/DocumentButton.vue";
import MomentX from "@/scripts/misc/momentX";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    AccordionDocumentAddDialog,
    FileInput,
    GreyTitle,
    EllipsisTableItem,
    DocumentDropZone
  },
  props: {
    editable: Boolean,
    documents: Array as () => LoadDocumentDto[] | undefined
  },
  computed: {
    actorName(): string {
      return this.$store.getters[gets.userInfo].actorName;
    },
    documentsByActor(): { actorName: string; documents: LoadDocumentDto[] }[] {
      if (!this.documents) {
        return [];
      }
      const actorNames = [...new Set(this.documents.map(d => d.actorName!))];
      if (!actorNames.includes(this.actorName)) {
        actorNames.push(this.actorName);
      }
      actorNames.sort((a, b) =>
        a === this.actorName ? 1 : b === this.actorName ? -1 : 0
      );
      return actorNames.map(a => ({
        actorName: a,
        documents: this.documents!.filter(d => d.actorName === a)
      }));
    }
  },
  methods: {
    documentText(document: LoadDocumentDto): string {
      return document.displayName! + "." + document.fileFormat!;
    },
    removeDocument(id: number) {
      this.documents!.splice(
        this.documents!.findIndex(d => d.id === id),
        1
      );
    },
    viewDocument(id: number) {
      const document = this.documents!.find(d => d.id === id)!;
      viewDocument(document.diskName, document.fileFormat);
    },
    addDocument(document: DocumentIdDiskNameDisplayName) {
      this.documents!.push(
        new LoadDocumentDto({
          id: document.id,
          owner: true,
          displayName: document.displayName,
          diskName: document.diskName,
          fileFormat: document.diskName.split(".")[
            document.diskName.split(".").length - 1
          ], //FIXME: ...diskName.split('.').at(-1) ----- string[].at kräver ES2022 som är tillgängligt i typescript v >= 4.6
          actorName: this.$store.getters[gets.userInfo].actorName,
          regDate: new MomentX()
        })
      );
    }
  },
  data: (): {
    uploadedFile: FileParameter | undefined;
  } => ({
    uploadedFile: undefined
  })
});
