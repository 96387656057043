










































































import Vue from "vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import DocumentTable from "@/components/shared/ui/DocumentTable.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import { ActorType, DocumentGroupDto, RepositoryDto } from "@/scripts/cld.api";
import { docTypeName } from "@/scripts/misc/enumNames";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    Dropdown,
    ReadOnlyText,
    DocumentTable,
    DialogCard
  },
  props: {
    value: Number,
    label: String,
    documentGroups: Array as () => DocumentGroupDto[],
    tabindex: String,
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    clearable: Boolean,
    rules: Array,
    asterisk: Boolean
  },
  computed: {
    items(): RepositoryDto[] {
      return this.documentGroups.map(
        g => new RepositoryDto({ id: g.id, text: g.description })
      );
    },
    selectedDocumentGroup(): DocumentGroupDto | undefined {
      if (!this.value) {
        return undefined;
      }
      return this.documentGroups.find(g => g.id === this.value);
    },
    text(): string | undefined {
      if (!this.selectedDocumentGroup) {
        return undefined;
      }
      if (this.actorType === ActorType.Supplier) {
        return docTypeName(this.selectedDocumentGroup.docType);
      }
      return this.selectedDocumentGroup.description;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    input(val: any) {
      this.$emit("input", val);
    }
  },
  data: (): { dialog: boolean } => ({
    dialog: false
  })
});
