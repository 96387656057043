
























































































































import Vue from "vue";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import InfoTooltip from "@/components/shared/ui/InfoTooltip.vue";
import { currencyName, isoName } from "@/scripts/misc/enumNames";
import {
  AgreementRouteForProcurementDto,
  CargoType,
  Country,
  Currency
} from "@/scripts/cld.api";
import { stringCompare, stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  props: {
    loading: Boolean,
    agreementRoutes: Array as () => AgreementRouteForProcurementDto[],
    cargoType: Number as () => CargoType,
    agreementRouteIds: Array as () => number[],
    height: Number,
    mode: Number
  },
  components: {
    CustomTableHeader,
    CustomFilter,
    InfoTooltip
  },
  computed: {
    filteredHeaders(): TableHeader[] {
      return this.headers.filter(h => (h.id as any).includes(this.mode));
    },
    filteredRoutes(): AgreementRouteForProcurementDto[] {
      return this.agreementRoutes
        .filter(
          r =>
            !this.filters.routeId ||
            r.routeId.toString().includes(this.filters.routeId)
        )
        .filter(
          r => !this.filters.currency || r.currency === this.filters.currency
        )
        .filter(
          r =>
            !this.filters.pickupCounty ||
            r.pickupCounty === this.filters.pickupCounty
        )
        .filter(
          r =>
            !this.filters.deliveryCounty ||
            r.deliveryCounty === this.filters.deliveryCounty
        )
        .filter(
          r =>
            !this.filters.pickupZipcode ||
            stringSearchHit(this.filters.pickupZipcode, r.pickupZipcode, true)
        )
        .filter(
          r =>
            !this.filters.deliveryZipcode ||
            stringSearchHit(
              this.filters.deliveryZipcode,
              r.deliveryZipcode,
              true
            )
        )
        .filter(
          r =>
            !this.filters.pickupCountry ||
            r.pickupCountry === this.filters.pickupCountry
        )
        .filter(
          r =>
            !this.filters.deliveryCountry ||
            r.deliveryCountry === this.filters.deliveryCountry
        )
        .filter(
          r =>
            !this.filters.tag ||
            (this.filters.tag === "null" && !r.tag) ||
            this.filters.tag === r.tag
        );
    },
    sortedRoutes(): AgreementRouteForProcurementDto[] {
      let res = [...this.filteredRoutes];
      let c: {
        (
          a: AgreementRouteForProcurementDto,
          b: AgreementRouteForProcurementDto
        ): number;
      };
      const sortBy = this.tableOptions.sortBy[0];
      const sortDesc = this.tableOptions.sortDesc[0];
      switch (sortBy) {
        case "pickup":
          c = (a, b) => stringCompare(this.pickup(a), this.pickup(b));
          break;
        case "delivery":
          c = (a, b) => stringCompare(this.delivery(a), this.delivery(b));
          break;
        default:
          c = (a, b) => stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      }
      res = res.sort(
        (a, b) =>
          (sortDesc ? -1 : 1) * c(a, b) ||
          stringCompare(this.pickup(a), this.pickup(b)) ||
          stringCompare(this.delivery(a), this.delivery(b))
      );
      return res;
    },
    checkAllState(): boolean | undefined {
      if (this.agreementRouteIds.length === 0) {
        return false;
      }
      if (this.sortedRoutes.some(r => !this.agreementRouteIds.includes(r.id))) {
        return undefined;
      }
      return true;
    }
  },
  methods: {
    currencyName: currencyName,
    pickup(route: AgreementRouteForProcurementDto): string {
      return `${route.pickupCounty}, ${route.pickupZipcode}, ${isoName(
        route.pickupCountry
      )}`;
    },
    delivery(route: AgreementRouteForProcurementDto): string {
      return `${route.deliveryCounty}, ${route.deliveryZipcode}, ${isoName(
        route.deliveryCountry
      )}`;
    },
    checkOne(id: number) {
      if (this.agreementRouteIds.includes(id)) {
        this.agreementRouteIds.splice(this.agreementRouteIds.indexOf(id), 1);
      } else {
        this.agreementRouteIds.push(id);
      }
    },
    checkAll() {
      const idsVisible = this.sortedRoutes.map(r => r.id);
      const selectedIdsNotVisible = this.agreementRouteIds.filter(
        id => !idsVisible.includes(id)
      );
      const state = this.checkAllState;
      this.agreementRouteIds.splice(0);
      this.agreementRouteIds.push(...selectedIdsNotVisible);
      if (state !== true) {
        this.agreementRouteIds.push(...idsVisible);
      }
    }
  },
  data: (): {
    headers: TableHeader[];
    filters: {
      routeId: string;
      pickupCounty: string;
      pickupZipcode: string;
      pickupCountry: Country | undefined;
      deliveryCounty: string;
      deliveryZipcode: string;
      deliveryCountry: Country | undefined;
      currency: Currency | undefined;
      tag: string | undefined;
    };
    tableOptions: TableOptions;
  } => ({
    headers: [
      {
        value: "checkbox",
        text: "",
        untranslated: true,
        width: 50,
        id: [1] as any
      },
      {
        value: "routeId",
        text: "RouteID",
        sortValue: true,
        width: 90,
        id: [1, 2] as any
      },
      {
        value: "pickup",
        text: "PickupLocation",
        sortValue: true,
        id: [1, 2, 3] as any
      },
      {
        value: "delivery",
        text: "DeliveryLocation",
        sortValue: true,
        id: [1, 2, 3] as any
      },
      {
        value: "currency",
        text: "Currency",
        sortValue: true,
        width: 90,
        id: [1, 2] as any
      },
      {
        value: "estimatedNoOfLoads",
        text: "NumberOfEstimatedLoads",
        sortValue: true,
        width: 1,
        id: [1, 2] as any
      },
      {
        value: "specialRequirements",
        text: "",
        untranslated: true,
        width: 50,
        id: [1, 2] as any
      },
      { value: "agreementTo", text: "AgreementEnds", id: [1] as any },
      {
        value: "tag",
        text: "Tag",
        sortValue: true,
        width: 120,
        id: [1] as any
      }
    ],
    filters: {
      routeId: "",
      pickupCounty: "",
      pickupZipcode: "",
      pickupCountry: undefined,
      deliveryCounty: "",
      deliveryZipcode: "",
      deliveryCountry: undefined,
      currency: undefined,
      tag: undefined
    },
    tableOptions: new TableOptions({
      itemsPerPage: -1,
      sortBy: ["pickup"],
      sortDesc: [false]
    })
  })
});
