





































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import SupplierSelectTable from "@/components/web/agreements/client/SupplierSelectTable.vue";
import {
  ApiConfiguration,
  CargoType,
  DirectMessagingClient,
  RecipientDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { cargoTypes } from "@/scripts/misc/enumLists";
import { cargoTypeName } from "@/scripts/misc/enumNames";
import { stringSearchHit } from "@/scripts/misc/stringUtils";

export default Vue.extend({
  components: {
    DialogCard,
    Checkbox,
    SearchCard,
    SupplierSelectTable
  },
  props: {
    dialog: Boolean,
    recipients: Array as () => number[],
    subscribedSuppliers: Array as () => number[]
  },
  watch: {
    dialog: {
      handler() {
        this.includedFilter = "";
        this.excludedFilter = "";
      }
    }
  },
  computed: {
    included(): RepositoryDto[] {
      return this.recipientBaseList(false).filter(s =>
        this.recipients.includes(s.id)
      );
    },
    excluded(): RepositoryDto[] {
      return this.recipientBaseList(true).filter(
        s => !this.recipients.includes(s.id)
      );
    },
    filteredIncluded(): RepositoryDto[] {
      return this.included.filter(s =>
        stringSearchHit(this.includedFilter, s.text, false)
      );
    },
    filteredExcluded(): RepositoryDto[] {
      return this.excluded.filter(s =>
        stringSearchHit(this.excludedFilter, s.text, false)
      );
    }
  },
  methods: {
    cargoTypeName: cargoTypeName,
    cargoTypeVisible(cargoType: CargoType): boolean {
      return this.allRecipients.some(r =>
        r.cargoTypesWithAgreement!.includes(cargoType)
      );
    },
    recipientBaseList(
      applyCargoTypeAndAgreementFilters: boolean
    ): RepositoryDto[] {
      let recipients = this.allRecipients;
      if (applyCargoTypeAndAgreementFilters) {
        recipients = recipients.filter(s =>
          (this.requireAgreement
            ? s.cargoTypesWithAgreement!
            : s.cargoTypes!
          ).some(c => this.cargoTypes[c - 1])
        );
      }
      return recipients.map(
        s => new RepositoryDto({ id: s.actorId, text: s.actorName })
      );
    },
    include(id: number) {
      this.recipients.push(id);
    },
    exclude(id: number) {
      this.recipients.splice(this.recipients.indexOf(id), 1);
    },
    includeFiltered() {
      this.recipients.push(...this.filteredExcluded.map(s => s.id));
    },
    excludeFiltered() {
      const keep = this.recipients.filter(
        s => !this.filteredIncluded.map(x => x.id).includes(s)
      );
      this.recipients.splice(0);
      this.recipients.push(...keep);
    },
    fetchRecipients() {
      this.loading = true;
      new DirectMessagingClient(new ApiConfiguration(this.$store))
        .recipients()
        .then(res => {
          this.allRecipients = res;
          this.cargoTypes = cargoTypes().map(c => this.cargoTypeVisible(c.id));
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  mounted() {
    this.fetchRecipients();
  },
  data: (): {
    loading: boolean;
    excludedFilter: string;
    includedFilter: string;
    cargoTypes: boolean[];
    requireAgreement: boolean;
    allRecipients: RecipientDto[];
  } => ({
    loading: false,
    excludedFilter: "",
    includedFilter: "",
    cargoTypes: cargoTypes().map(_ => true),
    requireAgreement: false,
    allRecipients: []
  })
});
