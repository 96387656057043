












































































































































































































































































































import Vue from "vue";
import {
  ApiConfiguration,
  SupportAdminDashboardAgreements,
  SupportAdminDashboardClient,
  SupportAdminDashboardCurrentLoads,
  SupportAdminDashboardLoadDto,
  SupportAdminDashboardProcurements
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Snow from "@/components/shared/ui/Snow.vue";
import DashboardLoadsDialog from "@/components/support-admin/dashboard/DashboardLoadsDialog.vue";
import MomentX from "@/scripts/misc/momentX";
import { localeCode } from "@/scripts/language/i18n";
import { cargoTypeName } from "@/scripts/misc/enumNames";
import { popupDialog } from "@/scripts/misc/popupDialogs";

interface BuildInfo {
  Branch: string;
  ArtefactBuildID: number;
  DeploymentDate: string;
  ArtefactBuildDate: string;
}

export default Vue.extend({
  components: {
    SearchCard,
    Snow,
    DashboardLoadsDialog
  },
  computed: {
    totalOrdered(): number {
      return this.loadStatusInfo.reduce((t, e) => t + e.ordered.length, 0);
    },
    totalMissingAgreement(): number {
      return this.loadStatusInfo.reduce(
        (t, e) => t + e.missingAgreement.length,
        0
      );
    },
    totalBooked(): number {
      return this.loadStatusInfo.reduce((t, e) => t + e.booked.length, 0);
    },
    totalLoaded(): number {
      return this.loadStatusInfo.reduce((t, e) => t + e.loaded.length, 0);
    },
    totalDelivered(): number {
      return this.loadStatusInfo.reduce((t, e) => t + e.delivered.length, 0);
    },
    totalPricePhase(): number {
      return this.procurementStatusInfo.reduce((t, e) => t + e.pricePhase, 0);
    },
    totalRankingPhase(): number {
      return this.procurementStatusInfo.reduce((t, e) => t + e.rankingPhase, 0);
    },
    totalNotStarted(): number {
      return this.procurementStatusInfo.reduce((t, e) => t + e.notStarted, 0);
    },
    totalFarAhead(): number {
      return this.procurementStatusInfo.reduce((t, e) => t + e.farAhead, 0);
    },
    totalFarBack(): number {
      return this.procurementStatusInfo.reduce((t, e) => t + e.farBack, 0);
    },
    totalEndingSoon(): number {
      return this.agreementStatusInfo.reduce(
        (t, e) => t + e.endingSoon.length,
        0
      );
    },
    totalEndingSoonWithoutSuccessors(): number {
      return this.agreementStatusInfo.reduce(
        (t, e) => t + e.endingSoonWithoutSuccessors.length,
        0
      );
    },
    totalEndingSoonish(): number {
      return this.agreementStatusInfo.reduce(
        (t, e) => t + e.endingSoonish.length,
        0
      );
    },
    totalEndingSoonishWithoutSuccessors(): number {
      return this.agreementStatusInfo.reduce(
        (t, e) => t + e.endingSoonishWithoutSuccessors.length,
        0
      );
    },
    totalEndingLater(): number {
      return this.agreementStatusInfo.reduce(
        (t, e) => t + e.endingLater.length,
        0
      );
    }
  },
  methods: {
    cargoTypeName: cargoTypeName,
    refresh() {
      this.loadingSemaphore = 3;
      this.refreshDate = new MomentX();
      this.fetchCurrentLoads();
      this.fetchProcurements();
      this.fetchAgreements();
      this.fetchBuildInfo();
    },
    fetchCurrentLoads() {
      new SupportAdminDashboardClient(new ApiConfiguration(this.$store))
        .currentLoads()
        .then(res => {
          this.loadStatusInfo = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchProcurements() {
      new SupportAdminDashboardClient(new ApiConfiguration(this.$store))
        .procurements()
        .then(res => {
          this.procurementStatusInfo = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchAgreements() {
      new SupportAdminDashboardClient(new ApiConfiguration(this.$store))
        .agreements()
        .then(res => {
          this.agreementStatusInfo = res;
          this.loadingSemaphore--;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    viewAgreementRoutes(agreementRouteIds: number[]) {
      popupDialog({
        title: "AgreementRouteIds",
        body: agreementRouteIds.join(", "),
        btnText1: "OK",
        btnCallback1: () => {}
      });
    },
    async fetchBuildInfo() {
      const scriptTags = document.getElementsByTagName("script");
      const scriptPath = scriptTags[scriptTags.length - 1].src;
      const cldVersionPath = scriptPath.replace(
        /\/[^\/]*$/,
        "/CLDVersion.json"
      );

      try {
        const response = await fetch(cldVersionPath);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch CLDVersion.json: ${response.status}`
          );
        }
        const versionData: BuildInfo = await response.json();
        this.cldVersionData = versionData;
        return versionData;
      } catch (error) {
        console.error("Unable to fetch data:", error);
        return null;
      }
    }
  },
  mounted() {
    this.refresh();
    this.$nextTick(function() {
      this.intervalId = setInterval(() => {
        this.timeSinceRefresh = new MomentX().to(
          this.refreshDate,
          localeCode()
        );
        if (this.refreshDate.addMinutes(15) < new MomentX()) {
          this.refresh();
        }
      }, 1000);
    });
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  data: (): {
    loadingSemaphore: number;
    intervalId: number;
    refreshDate: MomentX;
    timeSinceRefresh: string;
    loadStatusInfo: SupportAdminDashboardCurrentLoads[];
    procurementStatusInfo: SupportAdminDashboardProcurements[];
    agreementStatusInfo: SupportAdminDashboardAgreements[];
    loadsBeingViewed: SupportAdminDashboardLoadDto[];
    cldVersionData: BuildInfo | undefined;
  } => ({
    loadingSemaphore: 0,
    intervalId: 0,
    refreshDate: new MomentX(),
    timeSinceRefresh: "",
    loadStatusInfo: [],
    procurementStatusInfo: [],
    agreementStatusInfo: [],
    loadsBeingViewed: [],
    cldVersionData: undefined
  })
});
