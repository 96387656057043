





























import Vue from "vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import CreateMessageDialog from "@/components/web/messages/CreateMessageDialog.vue";
import { gets } from "@/scripts/store/constants";
import { ActorType, UserLevel } from "@/scripts/cld.api";
export default Vue.extend({
  components: {
    SearchCard,
    CreateMessageDialog
  },
  props: {
    loading: Boolean
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    userLevel(): UserLevel {
      return this.$store.getters[gets.userInfo].userLevel;
    },
    atleastUser(): boolean {
      return [UserLevel.User, UserLevel.Admin, UserLevel.SuperAdmin].includes(
        this.userLevel
      );
    },
    allowedToCreateMessage(): boolean {
      return this.actorType === ActorType.Client && this.atleastUser;
    }
  },
  methods: {
    search() {
      this.$emit("search");
    },
    markAllAsViewed() {
      this.$emit("markAllAsViewed");
    },
    closeDialog() {
      this.dialog = false;
      this.search();
    }
  },
  data: (): { dialog: boolean } => ({ dialog: false })
});
