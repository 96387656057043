













import Vue from "vue";
import {
  LoadActionPermission,
  LoadLockDto,
  LockStatus
} from "@/scripts/cld.api";
import { LoadAction } from "@/scripts/types";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    loadId: Number,
    actionId: Number as () => LoadActionPermission,
    small: Boolean
  },
  computed: {
    lock(): LoadLockDto | undefined {
      return this.$store.getters[gets.lock](this.loadId);
    },
    action(): LoadAction {
      return this.$store.getters
        .loadActions(this.loadId)
        .find((a: LoadAction) => a.id === this.actionId);
    },
    lockNeeded(): boolean {
      return this.action.lockNeeded;
    },
    color(): string {
      return this.lock!.lockStatus === LockStatus.Unlocked ? "error" : "black";
    },
    icon(): string {
      return this.lock!.lockStatus === LockStatus.Unlocked
        ? "mdi-lock-open-variant"
        : "mdi-lock";
    },
    time(): string {
      let seconds = this.lock!.secondsLeft;
      if (seconds <= 0 || seconds >= 3600) {
        return "";
      }
      if (seconds >= 180) {
        seconds = Math.ceil(seconds / 60) * 60;
      }
      return new Date(seconds * 1000).toISOString().substr(14, 5);
    }
  }
});
