































import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";
import {
  ExplorerNodeType,
  ExplorerNodeDto,
  ExplorerClient,
  ExplorerSelectionMethod,
  ApiConfiguration,
  ExplorerSearchCriteria
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import neverHappens from "@/scripts/misc/neverHappens";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    items: Array
  },
  methods: {
    guid(): string {
      return Math.random().toString();
    },
    text(text: string) {
      while (text.indexOf("$") >= 0) {
        const start = text.indexOf("$");
        const sub = text.substring(start, text.indexOf("$", start + 1) + 1);
        text = text.replaceAll(sub, t(sub.substring(1, sub.length - 1)));
      }
      return text;
    },
    icon(type: ExplorerNodeType) {
      switch (type) {
        case ExplorerNodeType.Load:
        case ExplorerNodeType.AgreementLoads:
          return "mdi-dolly";
        case ExplorerNodeType.Agreement:
        case ExplorerNodeType.AgreementRouteAgreements:
          return "mdi-text-box";
        case ExplorerNodeType.AgreementRow:
        case ExplorerNodeType.AgreementAgreementRows:
          return "mdi-card-text";
        case ExplorerNodeType.TruckType:
          return "mdi-train-car";
        case ExplorerNodeType.AgreementRoute:
          return "mdi-map-marker-distance";
        case ExplorerNodeType.Route:
        case ExplorerNodeType.LocationPickupRoutes:
        case ExplorerNodeType.LocationDeliveryRoutes:
          return "mdi-sign-direction";
        case ExplorerNodeType.Location:
          return "mdi-map-marker";
        case ExplorerNodeType.PriceRow:
          return "mdi-cash-100";
        case ExplorerNodeType.LoadClient:
          return "mdi-saw-blade";
        case ExplorerNodeType.LoadSupplier:
          return "mdi-truck-fast";
        default:
          return neverHappens(type);
      }
    },
    itemClicked(item: ExplorerNodeDto) {
      this.$emit("itemClicked", item);
    },
    loadChildren(item: ExplorerNodeDto) {
      const ps: Promise<ExplorerNodeDto[]>[] = [];
      switch (item.type) {
        case ExplorerNodeType.Load:
          ps.push(
            ...[
              ExplorerSelectionMethod.ClientByLoadId,
              ExplorerSelectionMethod.SupplierByLoadId,
              ExplorerSelectionMethod.PriceRowByLoadId,
              ExplorerSelectionMethod.AgreementByLoadId
            ].map(e => this.getNodesPromise(e, item))
          );
          break;
        case ExplorerNodeType.Agreement:
          ps.push(
            ...[ExplorerSelectionMethod.AgreementRouteByAgreementId].map(e =>
              this.getNodesPromise(e, item)
            ),
            new Promise<ExplorerNodeDto[]>(resolve => {
              resolve([
                new ExplorerNodeDto({
                  type: ExplorerNodeType.AgreementAgreementRows,
                  guid: this.guid(),
                  id: item.id,
                  text: "Avtalsrader",
                  color: "",
                  children: []
                }),
                new ExplorerNodeDto({
                  type: ExplorerNodeType.AgreementLoads,
                  guid: this.guid(),
                  id: item.id,
                  text: "Lass",
                  color: "",
                  children: []
                })
              ]);
            })
          );

          break;
        case ExplorerNodeType.AgreementLoads:
          ps.push(
            ...[ExplorerSelectionMethod.LoadByAgreementId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.AgreementAgreementRows:
          ps.push(
            ...[ExplorerSelectionMethod.AgreementRowByAgreementId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.AgreementRow:
          ps.push(
            ...[ExplorerSelectionMethod.AgreementByAgreementRowId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.AgreementRoute:
          ps.push(
            ...[ExplorerSelectionMethod.RouteByAgreementRouteId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          item.children!.push(
            new ExplorerNodeDto({
              type: ExplorerNodeType.AgreementRouteAgreements,
              guid: this.guid(),
              id: item.id,
              text: "Avtal",
              color: "",
              children: []
            })
          );
          break;
        case ExplorerNodeType.AgreementRouteAgreements:
          ps.push(
            ...[ExplorerSelectionMethod.AgreementByAgreementRouteId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.Route:
          ps.push(
            ...[
              ExplorerSelectionMethod.LocationByRouteId,
              ExplorerSelectionMethod.AgreementRouteByRouteId
            ].map(e => this.getNodesPromise(e, item))
          );
          break;
        case ExplorerNodeType.Location:
          ps.push(...[].map(e => this.getNodesPromise(e, item)));
          item.children!.push(
            new ExplorerNodeDto({
              type: ExplorerNodeType.LocationPickupRoutes,
              guid: this.guid(),
              id: item.id,
              text: "Hämtrutter",
              color: "",
              children: []
            })
          );
          item.children!.push(
            new ExplorerNodeDto({
              type: ExplorerNodeType.LocationDeliveryRoutes,
              guid: this.guid(),
              id: item.id,
              text: "Leveransrutter",
              color: "",
              children: []
            })
          );
          break;
        case ExplorerNodeType.LocationPickupRoutes:
          ps.push(
            ...[ExplorerSelectionMethod.RouteByPickupLocationId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.LocationDeliveryRoutes:
          ps.push(
            ...[ExplorerSelectionMethod.RouteByDeliveryLocationId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.PriceRow:
          ps.push(
            ...[ExplorerSelectionMethod.LoadByPriceRowId].map(e =>
              this.getNodesPromise(e, item)
            )
          );
          break;
        case ExplorerNodeType.TruckType:
          ps.push(...[].map(e => this.getNodesPromise(e, item)));
          break;
        case ExplorerNodeType.LoadClient:
          ps.push(...[].map(e => this.getNodesPromise(e, item)));
          break;
        case ExplorerNodeType.LoadSupplier:
          ps.push(...[].map(e => this.getNodesPromise(e, item)));
          break;
        default:
          neverHappens(item.type);
      }
      return Promise.all(ps)
        .then(responses => {
          item.children!.push(...responses.flatMap(r => r));
        })
        .then(() => {
          const unexpandable = [
            ExplorerNodeType.LoadClient,
            ExplorerNodeType.LoadSupplier
          ];
          item
            .children!.filter(c => unexpandable.includes(c.type))
            .map(c => (c.children = undefined));
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    getNodesPromise(
      method: ExplorerSelectionMethod,
      item: ExplorerNodeDto
    ): Promise<ExplorerNodeDto[]> {
      return new ExplorerClient(new ApiConfiguration(this.$store)).nodes(
        new ExplorerSearchCriteria({
          method: method,
          loadStatuses: [],
          text: item.id.toString(),
          client: undefined,
          fromDate: new MomentX(),
          toDate: new MomentX()
        })
      );
    }
  }
});
