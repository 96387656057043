

























































import Vue from "vue";
import { LoadSearchResultDto } from "@/scripts/cld.api";
import cached from "@/scripts/misc/cached";
import { actions } from "@/scripts/store/constants";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import LoadSearchTable from "@/components/web/loads/shared/search/LoadSearchTable.vue";
import { stringSearchHit } from "@/scripts/misc/stringUtils";
import nextUnique from "@/scripts/misc/nextUnique";

export default Vue.extend({
  components: {
    TextSingle,
    LoadSearchTable
  },
  props: {
    excludedLoadIds: Array as () => number[],
    removeRelatedLoadButton: Boolean,
    shortVersion: Boolean
  },
  computed: {
    visibleItems(): LoadSearchResultDto[] {
      return this.items.filter(i => this.itemVisible(i, this.value || ""));
    }
  },
  methods: {
    input(val?: string | null) {
      if (val !== undefined && val !== null) {
        this.menu = true;
        if (val !== "") {
          this.fetchMatchingLoads(val);
        }
      }
    },
    itemVisible(item: LoadSearchResultDto, text: string): boolean {
      if (this.excludedLoadIds.includes(item.loadId)) {
        return false;
      }
      return stringSearchHit(
        text.trim(),
        item.transportNo + " " + item.loadId.toString() + " " + item.bookingNo,
        false
      );
    },
    fetchMatchingLoads(val: string) {
      const unique = nextUnique();
      this.loading = unique;
      this.items = [];
      cached("loadSearch", [val.trim()])
        .then(res => {
          if (unique !== this.loading) {
            return;
          }
          this.items = res;
          this.loading = 0;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data: (): {
    menu: boolean;
    loading: number;
    value: string | null | undefined;
    items: LoadSearchResultDto[];
  } => ({ menu: false, loading: 0, value: null, items: [] })
});
