




























import Vue from "vue";
import {
  ActorType,
  ApiConfiguration,
  DashboardClient,
  ProcurementDashboardDto
} from "@/scripts/cld.api";
import ProcurementBox from "@/components/web/dashboard/DashboardProcurementsBox.vue";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { actions, gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    ProcurementBox,
    GreyTitle,
    SkeletonLoader
  },
  computed: {
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    }
  },
  methods: {
    init() {
      this.loading = true;
      new DashboardClient(new ApiConfiguration(this.$store))
        .procurements()
        .then(res => {
          this.boxes = res;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.init();
  },
  data: (): {
    loading: boolean;
    boxes: ProcurementDashboardDto[];
  } => ({
    loading: true,
    boxes: []
  })
});
