
















































































































































































































































































































import Vue from "vue";
import {
  ApiConfiguration,
  BookLoadDto,
  RepositoryClient,
  SupplierToBookDto,
  LoadValuePermission,
  LoadsClient,
  ActorType,
  LoadValuePermissionDto,
  RepositoryDto,
  LoadActionPermission,
  Currency,
  SaveDeviationDto,
  OtherPriceCause,
  DeviationCause
} from "@/scripts/cld.api";
import MomentX from "@/scripts/misc/momentX";
import Auth from "@/scripts/auth";
import { actions, gets } from "@/scripts/store/constants";
import { ble } from "@/scripts/misc/apiErrors";
import { errorDialog, popupDialog } from "@/scripts/misc/popupDialogs";
import DatePickerHolder from "@/components/shared/ui/DatePickerHolder.vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import LoadDialogMultiTable from "@/components/web/loads/shared/LoadDialogMultiTable.vue";
import LoadLock from "@/components/web/loads/shared/LoadLock.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import { t } from "@/scripts/language/i18n";
import { currencyName } from "@/scripts/misc/enumNames";
import { MultiLoad } from "@/scripts/types";
import { otherPriceCauses, deviationCauses } from "@/scripts/misc/enumLists";

interface LoadValidationInfo {
  loadId: number;
  mandatoryBookedPickupDate: boolean;
  mandatoryBookedDeliveryDate: boolean;
  mandatoryOtherPrice: boolean;
  otherPriceCauseDisabled: boolean;
  deviationCauseDisabled: boolean;
  loadPermissions: LoadValuePermissionDto[];
  suppliers?: SupplierToBookDto[];
  currency: Currency;
  pickupEarliest?: MomentX;
  pickupLatest?: MomentX;
  deliveryEarliest?: MomentX;
  deliveryLatest?: MomentX;
  specialRequirements: string | undefined;
}

export default Vue.extend({
  components: {
    DatePickerHolder,
    LoadDialogMultiTable,
    DialogCard,
    Dropdown,
    Autocomplete,
    DatePicker,
    TextMulti,
    NumberInput,
    LoadLock,
    LoadChips,
    TextSingle
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[],
    actionId: Number as () => LoadActionPermission
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      return `${this.$t("Book")}`;
    },
    multi(): boolean {
      return this.loadIds.length > 1;
    },
    tooLongText(): string {
      return t("MaxXCharacters").replace("{0}", "20");
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    allLoadsValid(): boolean {
      return this.loads.length > 0 && this.loads.every(l => l.valid);
    },
    load(): MultiLoad {
      return this.loads.find(l => l.loadId === this.selectedLoadId)!;
    },
    loadValidationInfo(): LoadValidationInfo {
      return this.loadValidationInfos.find(
        l => l.loadId === this.selectedLoadId
      )!;
    },
    saveDto(): BookLoadDto {
      return this.saveDtos.find(s => s.loadId === this.selectedLoadId)!;
    },
    otherPrice: {
      get(): number | undefined {
        return this.saveDto!.deviation!.otherPrice;
      },
      set(val: number) {
        this.saveDto!.deviation!.otherPrice = val;
        this.loadValidationInfo.otherPriceCauseDisabled = !val;
        if (this.loadValidationInfo.otherPriceCauseDisabled) {
          this.saveDto.deviation!.otherPriceCauseId = undefined;
          this.saveDto.deviation!.noteOtherPrice = undefined;
        }
        this.validateForm();
      }
    },
    supplierId: {
      get(): number | undefined {
        return this.saveDto!.supplierId;
      },
      set(val: number) {
        this.saveDto!.supplierId = val;
        if (val) {
          const supplier = this.loadValidationInfo.suppliers!.find(
            s => s.id === val
          )!;
          this.loadValidationInfo.mandatoryOtherPrice = !supplier.hasValidAgreement;
          this.loadValidationInfo.deviationCauseDisabled =
            supplier.hasBeenOfferedLoad;
          if (this.loadValidationInfo.deviationCauseDisabled) {
            this.saveDto.deviation!.deviationCauseId = undefined;
            this.saveDto.deviation!.noteDeviation = undefined;
          }
          this.validateForm();
        }
      }
    },
    supplierVisible(): boolean {
      return this.actorType === ActorType.Client;
    },
    terminalEnabled(): boolean {
      return this.loadValidationInfo.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.Terminal
      )!.enabled;
    },
    terminalVisible(): boolean {
      return this.loadValidationInfo.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.Terminal
      )!.visible;
    },
    vtlAndEscortVisible(): boolean {
      return this.loadValidationInfo.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.VtlAndEscort
      )!.visible;
    },
    distanceEnabled(): boolean {
      return this.loadValidationInfo.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.Distance
      )!.enabled;
    },
    distanceVisible(): boolean {
      return this.loadValidationInfo.loadPermissions.find(
        p => p.permissionType === LoadValuePermission.Distance
      )!.visible;
    },
    mobile() {
      return Auth.browsingMobile();
    },
    otherPriceCauses(): RepositoryDto[] {
      return otherPriceCauses().filter(
        x => x.id !== OtherPriceCause.SpotTender
      );
    },
    deviationCauses(): RepositoryDto[] {
      return deviationCauses().filter(x => x.id !== DeviationCause.SpotTender);
    }
  },
  methods: {
    currencyName: currencyName,
    async validateForm() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        if (form !== undefined) {
          form.validate();
        }
      });
    },
    bookLoad() {
      if (
        this.saveDto!.bookedDeliveryDate! >
        this.loadValidationInfo.deliveryLatest!
      ) {
        popupDialog({
          title: t("BookedDeliveryDate"),
          body: t("ConfirmDateErrorDelivery"),
          btnText1: "OK",
          btnColor1: "success",
          btnCallback1: () => {
            this.book();
          },
          btnText2: t("Cancel"),
          btnColor2: "error",
          btnCallback2: () => {}
        });
      } else {
        this.book();
      }
    },
    async book() {
      this.saveInProgress = true;
      await this.validateForm();
      if (!this.allLoadsValid) {
        this.saveInProgress = false;
        return;
      }
      let saveOk = true;
      for (const saveDto of this.saveDtos) {
        await new LoadsClient(new ApiConfiguration(this.$store))
          .book(saveDto)
          .then(res => {
            pushLoadNotification("book", res);
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = true;
          })
          .catch(error => {
            this.loads.find(l => l.loadId === saveDto.loadId)!.saved = false;
            saveOk = false;
            if (ble(error)) {
              errorDialog(error, () => {});
            } else {
              this.$store.dispatch(actions.handleApiError, error);
            }
          });
      }
      if (saveOk) {
        this.close();
      }
    },
    supplierColor(supplier: SupplierToBookDto) {
      if (supplier.hasValidAgreement) {
        if (supplier.hasBeenOfferedLoad) {
          return "green-text";
        } else {
          return "orange-text";
        }
      }
      return "";
    },
    close() {
      this.$emit("close");
    },
    showLoadUnavailableDialog() {
      popupDialog({
        noCloseButton: true,
        title: this.$t("MobileAlreadyBooked").toString(),
        body: this.$t("MobileIsAlreadyBooked").toString(),
        btnText1: "OK",
        btnColor1: "success",
        btnCallback1: () => {
          this.close();
        }
      });
    },
    init() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .terminals()
        .then(res => {
          this.terminals = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });

      new LoadsClient(new ApiConfiguration(this.$store))
        .preBookDetails(this.loadIds)
        .then(res => {
          this.loads = res.map(x => ({
            loadId: x.loadId,
            routeName: x.routeName!,
            pickupEarliest: x.pickupEarliest,
            deliverLatest: x.deliveryLatest,
            truckType: x.truckType,
            transportNo: x.transportNo,
            clientName: x.clientName,
            numExtraStops: x.numExtraStops,
            numContainers: x.numContainers,
            valid: false,
            saved: undefined,
            customsInfoRequired: x.customsInfoRequired
          }));
          this.saveDtos = res.map(
            r =>
              new BookLoadDto({
                loadId: r.loadId,
                supplierId: undefined,
                bookedPickupDate: undefined,
                bookedDeliveryDate: undefined,
                bookedPickupInfo: undefined,
                bookedDeliveryInfo: undefined,
                deviation: new SaveDeviationDto({
                  deviationCauseId: undefined,
                  noteDeviation: undefined,
                  otherPriceCauseId: undefined,
                  noteOtherPrice: undefined,
                  otherPrice: undefined
                }),
                terminalId: r.terminalId,
                distance: r.distance,
                vtlPrice: r.vtlPrice,
                escortCarPrice: r.escortCarPrice,
                bookingNo: r.bookingNo,
                truckNo: undefined
              })
          );
          this.loadValidationInfos = res.map(r => ({
            loadId: r.loadId,
            mandatoryBookedPickupDate: r.mandatoryBookedPickupDate,
            mandatoryBookedDeliveryDate: r.mandatoryBookedDeliveryDate,
            mandatoryOtherPrice: false,
            otherPriceCauseDisabled: true,
            deviationCauseDisabled: true,
            currency: r.currency,
            pickupEarliest: r.pickupEarliest,
            pickupLatest: r.pickupLatest,
            deliveryEarliest: r.deliveryEarliest,
            deliveryLatest: r.deliveryLatest,
            suppliers: r.suppliers,
            loadPermissions: r.permissions!,
            specialRequirements: r.specialRequirements,
            truckNo: r.truckNo
          }));
          this.selectedLoadId = this.loads[0].loadId;
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    }
  },
  data: (): {
    saveInProgress: boolean;
    selectedLoadId: number | undefined;
    loads: MultiLoad[];
    saveDtos: BookLoadDto[];
    loadValidationInfos: LoadValidationInfo[];
    terminals: RepositoryDto[];
  } => ({
    saveInProgress: false,
    selectedLoadId: undefined,
    loads: [],
    saveDtos: [],
    loadValidationInfos: [],
    terminals: []
  })
});
