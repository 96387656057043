
































































































import Vue from "vue";
import {
  ApiConfiguration,
  LoadClientEditAssortedValuesDto,
  LoadClientEditInfoTextsDto,
  RepositoryClient,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextSaved from "@/components/shared/input/TextSaved.vue";
import UnitAutcomplete from "@/components/shared/input/UnitAutocomplete.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  props: {
    step: Number,
    assortedValues: LoadClientEditAssortedValuesDto,
    infoTexts: LoadClientEditInfoTextsDto
  },
  components: {
    Autocomplete,
    TextSingle,
    TextSaved,
    UnitAutcomplete,
    GreyTitle
  },
  methods: {
    fetchCustomers() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .customers()
        .then(res => {
          this.customers = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchUnits() {
      new RepositoryClient(new ApiConfiguration(this.$store))
        .units()
        .then(res => {
          this.units = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    tooLongText(max: number): string {
      return t("MaxXCharacters").replace("{0}", max.toString());
    }
  },
  mounted() {
    this.fetchCustomers();
    this.fetchUnits();
  },
  data: (): {
    customers: RepositoryDto[];
    units: string[];
  } => ({
    customers: [],
    units: []
  })
});
