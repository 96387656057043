




































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import {
  Country,
  ILocationFilterDto,
  LocationFilterDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import cached from "@/scripts/misc/cached";
import { t } from "@/scripts/language/i18n";

enum RowTypeDelivery {
  zipCode = "zipCode",
  county = "county"
}

class LocationFilterDtoPickup extends LocationFilterDto {
  rowType: RowTypeDelivery;
  constructor(data: ILocationFilterDto, rowType: RowTypeDelivery) {
    super(data);
    this.rowType = rowType;
  }
}

export default Vue.extend({
  components: {
    DialogCard,
    Dropdown,
    Autocomplete,
    TextSingle
  },
  props: {
    dialog: Boolean,
    zipCodeFilter: Array as () => LocationFilterDto[],
    countyFilter: Array as () => LocationFilterDto[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    rowTypes(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: RowTypeDelivery.zipCode as any,
          text: t("ZipCodeFilter")
        }),
        new RepositoryDto({
          id: RowTypeDelivery.county as any,
          text: t("CountyFilter")
        })
      ];
    }
  },
  methods: {
    addRow() {
      this.draft.push(
        new LocationFilterDtoPickup(
          {
            country: Country.Sweden,
            filter: ""
          },
          RowTypeDelivery.zipCode
        )
      );
    },
    removeRow(index: number) {
      this.draft.splice(index, 1);
    },
    fetchCounties() {
      cached("counties", Country.Sweden)
        .then(res => {
          this.counties = res.map(r => r.text!);
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.draft = [
        ...this.zipCodeFilter.map(
          f => new LocationFilterDtoPickup(f, RowTypeDelivery.zipCode)
        ),
        ...this.countyFilter.map(
          f => new LocationFilterDtoPickup(f, RowTypeDelivery.county)
        )
      ];
      this.fetchCounties();
    },
    keepChangesAndClose() {
      this.zipCodeFilter.length = 0;
      this.zipCodeFilter.push(
        ...this.draft.filter(
          d => !!d.filter && d.rowType === RowTypeDelivery.zipCode
        )
      );
      this.countyFilter.length = 0;
      this.countyFilter.push(
        ...this.draft.filter(
          d => !!d.filter && d.rowType === RowTypeDelivery.county
        )
      );
      this.close();
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    draft: LocationFilterDtoPickup[];
    counties: string[];
  } => ({
    draft: [],
    counties: []
  })
});
