var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"item-key":"id","headers":_vm.filteredHeaders,"items":_vm.sortedSuppliers,"height":_vm.tableHeight,"options":_vm.tableOptions,"mobile-breakpoint":"0","disable-sort":"","fixed-header":"","hide-default-header":"","hide-default-footer":""},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.text",fn:function(){return [_c('CustomFilter',{attrs:{"value":_vm.filter,"config":{ subtype: 'text' },"placeholder":_vm.$t('Supplier')},on:{"input":function (f) { return _vm.$emit('filter', f); }}})]},proxy:true}],null,true)})]}},{key:"item.exclude",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"clickable medium-padding-right",on:{"click":function () {
          _vm.$emit('click', item.id);
        }}},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v("mdi-chevron-double-left")])],1)]}},{key:"item.text",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"clickable small-vertical-padding",on:{"click":function () {
          _vm.$emit('click', item.id);
        }}},[_vm._v(" "+_vm._s(item.text)+" ")])]}},{key:"item.unsubscribed",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"clickable medium-padding-right",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},on),[(!_vm.subscribed(item.id))?_c('v-icon',{attrs:{"color":"icon-red"}},[_vm._v("mdi-account-alert")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("ActorWontReceiveMessage")))])])]}},{key:"item.survey",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"clickable medium-padding-right",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},on),[(_vm.ftCertified(item.id))?_c('v-img',{attrs:{"src":_vm.ftLogo,"height":"24","width":"24"}}):(_vm.surveyDone(item.id))?_c('v-icon',{attrs:{"color":"icon-green"}},[_vm._v(" mdi-leaf ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip(item.id)))])])]}},{key:"item.qp",fn:function(ref){
            var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({staticClass:"clickable small-vertical-padding",on:{"click":function () {
              _vm.$emit('click', item.id);
            }}},on),[_vm._v(" "+_vm._s(_vm.total(item.id))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip(item.id)))])])]}},{key:"item.include",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"clickable medium-padding-sides",on:{"click":function () {
          _vm.$emit('click', item.id);
        }}},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v("mdi-chevron-double-right")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }