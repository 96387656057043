












































import Vue from "vue";
import {
  ApiConfiguration,
  SubjectiveQPDto,
  SubjectiveQualityPointsHistoryDto,
  RepositoryDto,
  QualityPointsClientClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import Autocomplete from "@/components/shared/input/Autocomplete.vue";
import SubjectiveQualityPointsTable from "@/components/web/qp/SubjectiveQualityPointsTable.vue";
import SubjectiveQualityPointsHistory from "@/components/web/qp/SubjectiveQualityPointsHistory.vue";
import cached from "@/scripts/misc/cached";

export default Vue.extend({
  components: {
    SearchCard,
    Autocomplete,
    SubjectiveQualityPointsTable,
    SubjectiveQualityPointsHistory
  },
  methods: {
    fetchQualitypoints() {
      if (!this.supplierId) {
        return;
      }
      this.loading = true;
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .subjectivePoints(this.supplierId)
        .then(res => {
          this.qualityPoints = res;
          this.changed = this.qualityPoints.map(x => ({
            id: x.parameter!,
            changed: false
          }));
          this.fetchHistory();
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchHistory() {
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .subjectiveHistory(this.supplierId!)
        .then(res => {
          this.historyItems = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.loading = true;
      new QualityPointsClientClient(new ApiConfiguration(this.$store))
        .subjectiveSave(this.qualityPoints)
        .then(() => {
          this.fetchQualitypoints();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    cached("suppliers", [null, true, true])
      .then(res => {
        this.suppliers = res;
      })
      .catch(error => {
        this.$store.dispatch(actions.handleApiError, error);
      });
  },
  data: (): {
    suppliers: RepositoryDto[];
    supplierId?: number;
    qualityPoints: SubjectiveQPDto[];
    changed: { id: number; changed: boolean }[];
    historyItems: SubjectiveQualityPointsHistoryDto[];
    loading: boolean;
  } => ({
    suppliers: [],
    supplierId: undefined,
    changed: [],
    qualityPoints: [],
    historyItems: [],
    loading: false
  })
});
