






































































import Vue from "vue";

export default Vue.extend({
  props: {
    title: String,
    clearable: Boolean,
    closable: Boolean,
    refreshable: Boolean,
    loading: Boolean,
    noPaddingSides: Boolean,
    noPaddingTop: Boolean,
    warning: Boolean,
    width: Number
  }
});
