


























import Vue from "vue";
import {
  ActorType,
  SubjectiveQualityPointsHistoryDto
} from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { t } from "@/scripts/language/i18n";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    historyItems: Array as () => SubjectiveQualityPointsHistoryDto[],
    loading: Boolean
  },
  computed: {
    historyHeaders(): TableHeader[] {
      const headers: TableHeader[] = [
        {
          value: "date",
          text: t("When"),
          sortValue: true,
          width: 170
        }
      ];
      if (this.isClient) {
        headers.push({
          value: "name",
          text: t("Who"),
          sortValue: true
        });
      }
      headers.push({
        value: "description",
        text: t("What")
      });
      return headers;
    },
    isClient(): boolean {
      return this.$store.getters[gets.userInfo].actorType === ActorType.Client;
    }
  },
  methods: {
    customSort(
      items: SubjectiveQualityPointsHistoryDto[],
      sortBy: string[],
      sortDesc: boolean[]
    ) {
      items.sort(
        (
          a: SubjectiveQualityPointsHistoryDto,
          b: SubjectiveQualityPointsHistoryDto
        ) => {
          if (sortBy[0] === "date") {
            return a.date.unix() - b.date.unix();
          } else if (sortBy[0] === "name") {
            return stringCompare(a.name!, b.name!);
          } else {
            return 0;
          }
        }
      );

      if (sortDesc[0] === true) {
        items = items.reverse();
      }
      return items;
    }
  }
});
