





























import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";

export default Vue.extend({
  components: {
    DialogCard
  },
  props: {
    dialog: Boolean
  },
  methods: {
    close() {
      this.$emit("close");
    },
    importPrev() {
      this.$emit("importPrev");
      this.close();
    }
  }
});
