








import Vue from "vue";

export default Vue.extend({
  props: {
    value: Boolean,
    disabled: Boolean,
    innerClass: String,
    radio: Boolean,
    size: Number
  },
  computed: {
    icon(): string {
      return this.value === true
        ? this.iconTrue
        : this.value === false
        ? this.iconFalse
        : this.iconIndeterminate;
    },
    iconTrue(): string {
      return this.radio ? "mdi-radiobox-marked" : "mdi-checkbox-marked";
    },
    iconFalse(): string {
      return this.radio ? "mdi-radiobox-blank" : "mdi-checkbox-blank-outline";
    },
    iconIndeterminate(): string {
      return this.radio ? "mdi-checkbox-blank-circle" : "mdi-minus-box";
    }
  },
  methods: {
    click() {
      this.$emit("input", !this.value);
    }
  }
});
