



































































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import {
  ActorsClient,
  ApiConfiguration,
  EditInvoiceInformationDto,
  Culture,
  Currency,
  RepositoryDto,
  SupportAdminActorListDto,
  ActorType
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { pushNotification } from "@/scripts/misc/notifications";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { cultures, currencies } from "@/scripts/misc/enumLists";
import { errorDialog } from "@/scripts/misc/popupDialogs";
import { ble } from "@/scripts/misc/apiErrors";

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    Dropdown
  },
  props: {
    actor: SupportAdminActorListDto,
    dialog: Boolean
  },
  computed: {
    title(): String {
      return (
        "Skapa faktureringsinformation" +
        " - " +
        this.actor.actorName +
        " (" +
        this.actor.actorId +
        ")"
      );
    },
    info(): EditInvoiceInformationDto {
      return this.actor.invoiceInfos![this.selectedIndex];
    },
    createEnabled(): boolean {
      return (
        [ActorType.Client, ActorType.Supplier].includes(this.actor.actorType) &&
        this.actor.invoiceInfos!.every(x => x.id !== 0)
      );
    }
  },
  methods: {
    create() {
      this.actor.invoiceInfos!.push(
        new EditInvoiceInformationDto({
          id: 0,
          actorId: this.actor.actorId,
          customerNoAffe: undefined,
          projectNoAffe: undefined,
          culture: Culture.Swedish,
          currency: Currency.SEK
        })
      );
      this.selectedIndex = this.actor.invoiceInfos!.length - 1;
    },
    createInvoiceInformation() {
      this.actionInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .editInvoiceInformation(this.info!)
        .then(() => {
          pushNotification(
            "Faktureringsinformation skapad",
            "mdi-check-circle"
          );
          this.actionInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    toggleActorEnabled(enabled: boolean) {
      this.actionInProgress = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .enable(this.actor.actorId, enabled)
        .then(() => {
          pushNotification(
            this.actor.actorName +
              " har " +
              (enabled ? "aktiverats." : "inaktiverats."),
            "mdi-check-circle"
          );
          if (enabled && this.actor!.actorType === ActorType.Supplier) {
            pushNotification(
              'Klicka här för att komma till "Lägg till i pågående upphandling"',
              "mdi-check-circle",
              "/support-admin/add-to-procurements",
              true
            );
          }
          this.actionInProgress = false;
          this.close();
        })
        .catch(error => {
          if (ble(error)) {
            errorDialog(error, () => {
              this.actionInProgress = false;
              this.close();
            });
          } else {
            this.$store.dispatch(actions.handleApiError, error);
          }
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    actionInProgress: boolean;
    valid: boolean;
    currencies: RepositoryDto[];
    cultures: RepositoryDto[];
    selectedIndex: number;
  } => ({
    actionInProgress: false,
    valid: false,
    currencies: currencies(),
    cultures: cultures(),
    selectedIndex: 0
  })
});
