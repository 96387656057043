






































import Vue from "vue";
import { NewsDto } from "@/scripts/cld.api";

export default Vue.extend({
  props: {
    item: NewsDto,
    clickable: Boolean
  },
  methods: {
    routeToNews() {
      this.$router.push("/news");
    }
  }
});
