




































import Vue from "vue";
import {
  ActorType,
  CargoType,
  LocationFilterDto,
  NotificationGroup,
  NotificationSettingsDto,
  NotificationSettingsGroupDto,
  NotificationSettingsRowDto,
  NotificationUserInterfaceGroup,
  RepositoryDto
} from "@/scripts/cld.api";
import { gets } from "@/scripts/store/constants";
import {
  notificationGroupName,
  notificationName,
  notificationUserInterfaceGroupName
} from "@/scripts/misc/enumNames";
import NotificationSettingsNode from "@/components/web/settings/NotificationSettingsNode.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import { cargoTypes } from "@/scripts/misc/enumLists";
import { linkWithLocale } from "@/scripts/misc/stringUtils";
import Config from "@/scripts/config/config";

export type NotificationNode = {
  text: string;
  checked: boolean | undefined;
  toggleChecked: { (checked: boolean): void };
  expanded: boolean;
  children: NotificationNode[];
  pickupCountyFilter?: LocationFilterDto[];
  pickupZipCodeFilter?: LocationFilterDto[];
  deliveryZipCodeFilter?: LocationFilterDto[];
  clientFilter?: number[];
  unitFilter?: string[];
  alteredByLoadUser?: { get: { (): boolean }; set: { (v: boolean): void } };
};

export default Vue.extend({
  components: {
    Dropdown,
    NotificationSettingsNode
  },
  props: {
    notificationSettings: NotificationSettingsDto
  },
  watch: {
    notificationSettings: {
      handler() {
        this.recreateNodes();
      },
      deep: true,
      immediate: true
    },
    cargoType: {
      handler() {
        this.recreateNodes();
      },
      immediate: true
    },
    cargoTypes: {
      handler() {
        this.cargoType = this.cargoTypes()[0].id;
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    treeHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 450);
    },
    loadUserId(): number {
      return this.$store.getters[gets.userInfo].loadUserId;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    actorCargoTypes(): CargoType[] {
      return this.$store.getters[gets.userInfo].actorCargoTypes;
    },
    isCustomer(): boolean {
      return this.actorType === ActorType.Customer;
    },
    documentLocation(): string {
      return linkWithLocale(
        Config.getDocsUrl() +
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/installningar/index.html"
      );
    }
  },
  methods: {
    cargoTypes(): RepositoryDto[] {
      return cargoTypes().filter(
        c =>
          this.actorType !== ActorType.Supplier ||
          this.actorCargoTypes.includes(c.id)
      );
    },
    checkedMulti(rows: NotificationSettingsRowDto[]): boolean | undefined {
      const numChecked = rows.filter(n => n.checked).length;
      return !numChecked
        ? false
        : numChecked === rows.length
        ? true
        : undefined;
    },
    expandedGroup(notificationGroup: NotificationGroup): boolean {
      return this.nodes.some(
        n => n.text === notificationGroupName(notificationGroup) && n.expanded
      );
    },
    expandedUserInterfaceGroup(
      uiGroup: NotificationUserInterfaceGroup
    ): boolean {
      return this.nodes.some(n =>
        n.children.some(
          c =>
            c.text === notificationUserInterfaceGroupName(uiGroup) && c.expanded
        )
      );
    },
    recreateNodes() {
      this.nodes = this.notificationSettings
        .groups!.filter(g => g.cargoType === this.cargoType)
        .map(group => this.createGroupNode(group))
        .filter(n => n.children.length > 0);
    },
    createGroupNode(group: NotificationSettingsGroupDto) {
      return {
        text: notificationGroupName(group.notificationGroup),
        checked: this.checkedMulti(group.rows!),
        toggleChecked: (checked: boolean) => {
          group.rows!.map(r => (r.checked = checked));
        },
        expanded: this.expandedGroup(group.notificationGroup),
        children: this.createGroupChildren(group.rows!),
        pickupCountyFilter: group.pickupCountyFilter,
        pickupZipCodeFilter: group.pickupZipCodeFilter,
        deliveryZipCodeFilter: group.deliveryZipCodeFilter,
        clientFilter: group.clientFilter,
        unitFilter: group.unitFilter,
        alteredByLoadUser:
          group.alteredByLoadUser === null
            ? undefined
            : {
                get() {
                  return group.alteredByLoadUser!;
                },
                set(v: boolean) {
                  group.alteredByLoadUser = v;
                }
              }
      };
    },
    createGroupChildren(
      rows: NotificationSettingsRowDto[]
    ): NotificationNode[] {
      const userInterfaceGroups = [
        ...new Set(
          rows
            .filter(r => !!r.userInterfaceGroup)
            .map(r => r.userInterfaceGroup!)
        )
      ];
      const res = rows
        .filter(r => !r.userInterfaceGroup)
        .map(row => this.createNotificationRowNode(row));
      res.push(
        ...userInterfaceGroups.map(x =>
          this.createNotificationUserInterfaceNode(
            rows.filter(r => r.userInterfaceGroup === x),
            x
          )
        )
      );
      return res;
    },
    createNotificationRowNode(
      row: NotificationSettingsRowDto
    ): NotificationNode {
      return {
        text: notificationName(row.notification),
        checked: row.checked,
        toggleChecked: (checked: boolean) => (row.checked = checked),
        expanded: false,
        children: []
      };
    },
    createNotificationUserInterfaceNode(
      rows: NotificationSettingsRowDto[],
      uiGroup: NotificationUserInterfaceGroup
    ): NotificationNode {
      return {
        text: notificationUserInterfaceGroupName(uiGroup),
        checked: this.checkedMulti(rows),
        toggleChecked: (checked: boolean) => {
          rows.map(r => (r.checked = checked));
        },
        expanded: this.expandedUserInterfaceGroup(uiGroup),
        children: rows.map(row => this.createNotificationRowNode(row))
      };
    }
  },
  data: (): {
    nodes: NotificationNode[];
    cargoType: CargoType;
  } => ({
    nodes: [],
    cargoType: CargoType.Road
  })
});
