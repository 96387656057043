
















































































































import TextMulti from "@/components/shared/input/TextMulti.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import Spinner from "@/components/shared/ui/Spinner.vue";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { pushNotification } from "@/scripts/misc/notifications";

import {
  ApiConfiguration,
  FileDto,
  SupportClient,
  SupportFormDto
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Vue from "vue";
import { alertDialog } from "@/scripts/misc/popupDialogs";
import { linkWithLocale } from "@/scripts/misc/stringUtils";
import Config from "@/scripts/config/config";
export default Vue.extend({
  components: {
    TextMulti,
    TextSingle,
    Spinner,
    SearchCard
  },
  computed: {
    userGuideLink(): string {
      return (
        Config.getDocsUrl() +
        linkWithLocale(
          "/documentation/docs_ACTORTYPE_USERLANG/_site/ACTORTYPE_usermanual_USERLANG/index.html"
        )
      );
    }
  },
  methods: {
    send() {
      this.sendInProgress = true;
      new SupportClient(new ApiConfiguration(this.$store))
        .send(this.sendDto!)
        .then(() => {
          this.sendDto.name = "";
          this.sendDto.email = "";
          this.sendDto.mobile = "";
          this.sendDto.subject = "";
          this.sendDto.description = "";
          this.sendDto.file = [];
          this.attachedFiles = [];
          return this.resetFormValidation();
        })
        .then(() => {
          pushNotification(
            this.$t("SupportSend").toString(),
            "mdi-check-circle"
          );
          this.fetchPreSupport();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    resetFormValidation() {
      return this.$nextTick().then(() => {
        let form: any = this.$refs.form;
        form.reset();
      });
    },
    fetchPreSupport() {
      new SupportClient(new ApiConfiguration(this.$store))
        .preSupport()
        .then(res => {
          this.sendDto.name = res.name;
          this.sendDto.email = res.email;
          this.sendDto.mobile = res.mobile;
          this.sendInProgress = false;
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    chooseFiles() {
      let fileUpload = this.$refs.fileUpload as any;
      fileUpload.click();
    },
    listFiles(e: any) {
      if (e.target.files[0].size < 2 * 1024 * 1024) {
        let importedFile = e.target.files[0];
        this.attachedFiles.push(importedFile);
      } else {
        alertDialog(
          this.$t("ToLargeFile").toString(),
          this.$t("ExceedsMaxFileSize2MB").toString()
        );
      }
      this.updateFormData();
    },
    removeFile(file: any) {
      this.attachedFiles = this.attachedFiles.filter(x => x !== file);
      this.updateFormData();
    },
    updateFormData() {
      this.sendDto.file = [];
      this.attachedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onloadend = () => {
          let b64 = reader.result as string;
          b64 = b64.replace("data:", "").replace(/^.+,/, "");
          this.sendDto.file!.push(
            new FileDto({
              name: file.name,
              contentType: file.type,
              fileData: b64
            })
          );
        };
        reader.readAsDataURL(file);
      });
    }
  },
  mounted() {
    this.sendInProgress = false;
    this.fetchPreSupport();
  },
  data: (): {
    valid: boolean;
    loading: boolean;
    sendInProgress: boolean;
    sendDto: SupportFormDto;
    attachedFiles: File[];
  } => ({
    valid: false,
    loading: true,
    sendInProgress: false,
    sendDto: new SupportFormDto({
      name: "",
      email: "",
      mobile: "",
      subject: "",
      description: "",
      file: []
    }),
    attachedFiles: []
  })
});
