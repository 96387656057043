


















import Vue from "vue";

export default Vue.extend({
  computed: {
    url() {
      return window.location.href;
    }
  },
  methods: {
    routeTo(path: string) {
      this.$router.push(path);
    }
  }
});
