















import Vue from "vue";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  methods: {
    pos(i: number): number {
      return (i / this.numFlakes) * 90 + Math.random() * 10;
    },
    fallDelay(i: number): number {
      return ((i % 7) + (i % 3)) * 1.3 + Math.random();
    },
    shakeDelay(i: number): number {
      return ((i % 9) + (i % 7) + (i % 5)) / 7.5;
    }
  },
  mounted() {
    this.showSnow = [1, 2, 11, 12].includes(new MomentX().month() + 1);
  },
  data: (): { numFlakes: number; showSnow: boolean } => ({
    numFlakes: 43,
    showSnow: false
  })
});
