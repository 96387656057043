




























































































































import Vue from "vue";
import {
  ExplorerClient,
  ExplorerNodeDto,
  ApiConfiguration,
  UpdateLoadPriceDto,
  PriceClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import Spinner from "@/components/shared/ui/Spinner.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import NumberInput from "@/components/shared/input/NumberInput.vue";
import { currencyName } from "@/scripts/misc/enumNames";
import money, { percentage } from "@/scripts/misc/money";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  props: {
    item: ExplorerNodeDto,
    apiMethod: String as () => "pricerowByLoadid" | "pricerowByPricerowid"
  },
  components: {
    Spinner,
    ReadOnlyText,
    NumberInput
  },
  watch: {
    "item.guid": {
      immediate: true,
      handler() {
        if (this.item === undefined) {
          return;
        }
        this.fetchPriceRow();
      }
    }
  },
  methods: {
    currencyName: currencyName,
    percentage: percentage,
    money: money,
    fetchPriceRow() {
      new ExplorerClient(new ApiConfiguration(this.$store))
        [this.apiMethod](this.item.id)
        .then(res => {
          this.priceRow = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      popupDialog({
        title: "Jag hoppas du vet vad du gör",
        body:
          "Det är väldigt viktigt att du är med på vad du håller på att göra här, för man kan ställa till det rejält. Är du säker på att du vill uppdatera lassets pris?",
        btnText1: "JA",
        btnColor1: "success",
        btnCallback1: () => {
          this.actuallySave();
        },
        btnText2: "NEEEEEJ! TA MIG HÄRIFRÅN!!!!!!!!!!",
        btnColor2: "error",
        btnCallback2: () => {}
      });
    },
    actuallySave() {
      this.saveInProgress = true;
      new PriceClient(new ApiConfiguration(this.$store))
        .updateLoadPrice(this.priceRow!)
        .then(() => {
          this.fetchPriceRow();
          this.saveInProgress = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  data(): { priceRow?: UpdateLoadPriceDto; saveInProgress: boolean } {
    return {
      priceRow: undefined,
      saveInProgress: false
    };
  }
});
