









































































































import Vue from "vue";
import { actions } from "@/scripts/store/constants";
import {
  ApiConfiguration,
  DocType,
  DocumentGroupContainerDto,
  DocumentGroupDto,
  DocumentGroupSaveDto,
  DocumentSaveDto,
  DocumentsClient,
  FilesClient,
  RepositoryDto
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import DocumentInput from "@/components/shared/input/DocumentInput.vue";
import DocumentRouteDialog from "@/components/web/documents/DocumentRouteDialog.vue";
import { docTypeName } from "@/scripts/misc/enumNames";
import Auth from "@/scripts/auth";
import MomentX from "@/scripts/misc/momentX";
import { docTypes } from "@/scripts/misc/enumLists";
import { DocumentFile } from "@/components/shared/input/DocumentInput.vue";

export default Vue.extend({
  components: {
    DialogCard,
    ReadOnlyText,
    Dropdown,
    TextSingle,
    Checkbox,
    DocumentInput,
    DocumentRouteDialog,
    GreyTitle
  },
  props: {
    documentGroupId: Number,
    allowedToEditDocumentGroups: Boolean
  },
  watch: {
    documentGroupId: {
      handler() {
        if (this.documentGroupId === 0) {
          this.newDocument();
        } else if (this.documentGroupId !== undefined) {
          this.fetchDocumentDetails();
        } else {
          this.documentDetails = undefined as any;
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string {
      if (!this.documentDetails) {
        return "";
      }
      return this.documentGroup.description!;
    },
    docTypeName(): string {
      return docTypeName(this.documentGroup.docType);
    },
    documentGroup(): DocumentGroupDto {
      return this.documentDetails.documentGroup!;
    },
    routeDialogVisible(): boolean {
      return (
        this.documentDetails !== undefined &&
        this.documentGroup.docType === DocType.TermsOfAgreement &&
        this.documentGroupId !== 0
      );
    },
    docTypes(): RepositoryDto[] {
      return docTypes().filter(
        dt =>
          !!this.documentGroupId ||
          [DocType.InvoicingInformation, DocType.TermsOfAgreement].includes(
            dt.id
          )
      );
    }
  },
  methods: {
    fetchDocumentDetails() {
      let promise: Promise<DocumentGroupContainerDto>;
      if (Auth.browsingSupportAdmin()) {
        promise = new DocumentsClient(
          new ApiConfiguration(this.$store)
        ).supportAdminDetails(this.documentGroupId);
      } else {
        promise = new DocumentsClient(
          new ApiConfiguration(this.$store)
        ).details(this.documentGroupId);
      }
      promise
        .then(res => {
          this.documentDetails = res;
          this.files = this.documentDetails.documentGroup!.documents!.map(
            d => ({
              fileParameter: {
                fileName: d.displayName!,
                data: undefined
              },
              diskName: d.diskName,
              fileFormat: d.fileFormat,
              culture: d.culture
            })
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    newDocument() {
      this.files = [];
      this.documentDetails = new DocumentGroupContainerDto({
        documentGroup: new DocumentGroupDto({
          id: 0,
          docType: undefined as any,
          actorName: undefined,
          actorId: 0,
          description: undefined,
          regDate: new MomentX(),
          alterDate: undefined,
          documents: [],
          hidden: false,
          connectedToAgreement: false
        }),
        routes: []
      });
    },
    async uploadFile(file: DocumentFile) {
      return new FilesClient(new ApiConfiguration(this.$store))
        .upload(file.fileParameter as any)
        .then(res => {
          file.diskName = res;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    async save() {
      this.saveInProgress = true;
      for (let f of this.files) {
        if (f.fileParameter.data !== undefined) {
          await this.uploadFile(f);
        }
      }
      const saveDto = new DocumentGroupSaveDto({
        id: this.documentGroup.id,
        docType: this.documentGroup.docType,
        description: this.documentGroup.description,
        hidden: this.documentGroup.hidden,
        documents: this.files.map(
          f =>
            new DocumentSaveDto({
              culture: f.culture,
              diskName: f.diskName,
              displayName: f.fileParameter.fileName
            })
        )
      });
      new DocumentsClient(new ApiConfiguration(this.$store))
        .save(saveDto)
        .then(() => {
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    routeDialog: boolean;
    documentDetails: DocumentGroupContainerDto;
    files: DocumentFile[];
  } => ({
    valid: false,
    saveInProgress: false,
    routeDialog: false,
    documentDetails: undefined as any,
    files: []
  })
});
