











































































import Vue from "vue";
import {
  AgreementPeriodDto,
  AgreementToDateDto,
  CargoType,
  DocumentGroupDto,
  RepositoryDto
} from "@/scripts/cld.api";
import { actorCargoTypes } from "@/scripts/misc/enumLists";
import DatePicker from "@/components/shared/input/DatePicker.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";
import { t } from "@/scripts/language/i18n";
import MomentX from "@/scripts/misc/momentX";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  components: {
    DatePicker,
    Dropdown,
    DocumentDropdown
  },
  props: {
    cargoType: Number as () => CargoType,
    agreementPeriod: AgreementPeriodDto,
    documentGroupId: Number,
    agreementToDates: Array as () => AgreementToDateDto[],
    documentGroups: Array as () => DocumentGroupDto[]
  },
  watch: {
    agreementPeriod: {
      handler() {
        this.emitValidity();
      },
      deep: true,
      immediate: true
    },
    cargoType() {
      this.emitValidity();
    },
    documentGroupId: {
      handler() {
        this.emitValidity();
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    sheetHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 278);
    },
    cargoTypes(): RepositoryDto[] {
      return actorCargoTypes();
    }
  },
  methods: {
    agreementEndText(date: MomentX, count: number): string {
      return t("AgreementEndDates")
        .replace("{0}", date.datePrint())
        .replace("{1}", count.toString());
    },
    emitValidity() {
      if (
        this.cargoType &&
        this.agreementPeriod.from &&
        this.agreementPeriod.to &&
        this.agreementPeriod.from.isSameOrBefore(this.agreementPeriod.to) &&
        this.documentGroupId
      ) {
        const info = `${this.agreementPeriod.from.datePrint()} - ${this.agreementPeriod.to.datePrint()}`;
        this.$emit("change", true, info);
      } else {
        this.$emit("change", false, undefined);
      }
    }
  }
});
