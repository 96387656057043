





































































































































































































































import Vue from "vue";
import {
  ApiConfiguration,
  CargoType,
  DocType,
  DocumentGroupDto,
  DocumentsClient,
  FinishSpotTenderDto,
  PreClientEditSpotTenderDto,
  PreEditSpotTenderPriceDto,
  SpotTenderClientClient,
  TruckType
} from "@/scripts/cld.api";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import CustomFilter from "@/components/shared/table/CustomFilter.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import ReadOnlyText from "@/components/shared/ui/ReadOnlyText.vue";
import GreyTitle from "@/components/shared/ui/GreyTitle.vue";
import CheckboxTiny from "@/components/shared/input/CheckboxTiny.vue";
import LoadChips from "@/components/web/loads/shared/LoadChips.vue";
import Checkbox from "@/components/shared/input/Checkbox.vue";
import DocumentDropdown from "@/components/shared/input/DocumentDropdown.vue";
import { MultiLoad, TableHeader } from "@/scripts/types";
import { actions } from "@/scripts/store/constants";
import { currencyName, truckTypeName } from "@/scripts/misc/enumNames";
import money from "@/scripts/misc/money";
import { pushLoadNotification } from "@/scripts/misc/notifications";
import MomentX from "@/scripts/misc/momentX";
import { localeCode, t } from "@/scripts/language/i18n";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { popupDialog } from "@/scripts/misc/popupDialogs";

export default Vue.extend({
  components: {
    DialogCard,
    CustomTableHeader,
    CustomFilter,
    EllipsisTableItem,
    ReadOnlyText,
    GreyTitle,
    CheckboxTiny,
    LoadChips,
    Checkbox,
    DocumentDropdown
  },
  props: {
    dialog: Boolean,
    loadIds: Array as () => number[]
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.init();
        }
      },
      immediate: true
    }
  },
  computed: {
    loadId(): number {
      return this.loadIds[0]!;
    },
    cargoType(): CargoType {
      return CargoType.Road;
    },
    pricesWithInvitedSuppliers(): PreEditSpotTenderPriceDto[] {
      return [
        ...this.infoDto.prices!,
        ...this.infoDto.suppliers!.map(
          x => ({ supplierName: x.text } as PreEditSpotTenderPriceDto)
        )
      ];
    },
    suppliers(): PreEditSpotTenderPriceDto[] {
      return this.pricesWithInvitedSuppliers
        .filter(
          s =>
            !this.supplierFilter ||
            s
              .supplierName!.toLowerCase()
              .includes(this.supplierFilter.toLowerCase())
        )
        .filter(
          s =>
            !this.truckTypeFilter.length ||
            this.truckTypeFilter.includes(s.truckType)
        );
    },
    endDateInfo(): string {
      return new MomentX().shortDateTimePrintWithTo(
        this.infoDto.endDate,
        localeCode()
      );
    },
    truckTypeNames(): string {
      return this.infoDto.truckTypes!.map(tt => truckTypeName(tt)).join(", ");
    }
  },
  methods: {
    truckTypeName: truckTypeName,
    currencyName: currencyName,
    money: money,
    close() {
      this.$emit("close");
    },
    itemClass(i: PreEditSpotTenderPriceDto): string {
      return [
        this.selectedId && i.spotTenderPriceRowId === this.selectedId
          ? "green-row"
          : "",
        i.spotTenderPriceRowId === undefined ? "greyish-text" : ""
      ].join(" ");
    },
    deleteSpotTender() {
      this.saveInProgress = true;
      popupDialog({
        title: t("Delete"),
        body: t("DeleteSpotTenderText"),
        btnText1: t("Delete"),
        btnColor1: "error",
        btnCallback1: () => {
          this.actuallyDeleteSpotTender();
        },
        closeButtonCallback: () => {
          this.saveInProgress = false;
        }
      });
    },
    actuallyDeleteSpotTender() {
      new SpotTenderClientClient(new ApiConfiguration(this.$store))
        .deleteSpotTender(this.loadId)
        .then(res => {
          pushLoadNotification("delete-spot", res);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = true;
      new SpotTenderClientClient(new ApiConfiguration(this.$store))
        .finishSpotTender(
          new FinishSpotTenderDto({
            spotTenderPriceRowId: this.selectedId!
          })
        )
        .then(res => {
          pushLoadNotification("book", res);
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchPreInfo() {
      new SpotTenderClientClient(new ApiConfiguration(this.$store))
        .preFinishSpotTender(this.loadId)
        .then(res => {
          this.infoDto = res;
          this.load = {
            loadId: this.loadId,
            routeName: res.routeName!,
            pickupEarliest: res.pickupEarliest,
            deliverLatest: res.deliverLatest,
            truckType: res.loadTruckType,
            transportNo: res.transportNo,
            clientName: undefined,
            numExtraStops: res.numExtraStops,
            numContainers: 0,
            valid: false,
            saved: undefined,
            customsInfoRequired: false
          };
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    fetchDocumentGroups() {
      new DocumentsClient(new ApiConfiguration(this.$store))
        .spotTender(this.loadId)
        .then(res => {
          this.documentGroups = [res];
          this.documentGroupId = res.id;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    init() {
      this.fetchPreInfo();
      this.fetchDocumentGroups();
    },
    customSort(
      items: PreEditSpotTenderPriceDto[],
      sortByArr: string[],
      sortDescArr: boolean[]
    ) {
      const sortBy = sortByArr[0];
      const sortDesc = sortDescArr[0];
      const c = (a: PreEditSpotTenderPriceDto, b: PreEditSpotTenderPriceDto) =>
        (sortDesc ? -1 : 1) *
        stringCompare((a as any)[sortBy], (b as any)[sortBy]);
      items = items.sort(
        (a, b) =>
          stringCompare(
            a.spotTenderPriceRowId === undefined,
            b.spotTenderPriceRowId === undefined
          ) || c(a, b)
      );
      return items;
    }
  },
  data: (): {
    saveInProgress: boolean;
    infoDto: PreClientEditSpotTenderDto;
    documentGroupId: number | undefined;
    documentGroups: DocumentGroupDto[];
    selectedId: number | undefined;
    supplierFilter: string | null;
    truckTypeFilter: TruckType[];
    load: MultiLoad;
    headers: TableHeader[];
  } => ({
    saveInProgress: false,
    infoDto: undefined as any,
    documentGroupId: undefined,
    documentGroups: [],
    selectedId: undefined,
    supplierFilter: null,
    truckTypeFilter: [],
    load: undefined as any,
    headers: [
      {
        value: "checkbox",
        text: "",
        untranslated: true,
        width: 1
      },
      {
        value: "supplierName",
        text: "Supplier",
        sortValue: true,
        width: 1
      },
      {
        value: "truckType",
        text: "TruckType",
        sortValue: true,
        width: 1
      },
      {
        value: "price",
        text: "Price",
        sortValue: true,
        width: 1
      },
      {
        value: "bookedPickupDate",
        text: "BookedPickupDate",
        sortValue: true,
        width: 1
      },
      {
        value: "bookedDeliveryDate",
        text: "BookedDeliveryDate",
        sortValue: true,
        width: 1
      },
      {
        value: "note",
        text: "Note"
      }
    ]
  })
});
