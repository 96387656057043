


























































import Vue from "vue";
import { Notification } from "@/scripts/misc/notifications";
import { gets, mutations } from "@/scripts/store/constants";

export default Vue.extend({
  computed: {
    notifications(): Notification[] {
      return this.$store.getters[gets.notifications];
    }
  },
  methods: {
    close(id: string) {
      this.$store.commit(mutations.removeNotification, id);
    },
    click(notification: Notification) {
      if (notification.routeTo) {
        this.$router.push(notification.routeTo);
        this.close(notification.id);
      }
    },
    padding(index: number) {
      return 30 + 72 * index;
    }
  }
});
