const objectsEqual = (a: any, b: any): boolean => {
  if (
    typeof a !== "object" ||
    typeof b !== "object" ||
    a === null ||
    b === null
  ) {
    return a === b;
  }
  const keysA = Object.keys(a);
  const keysB = Object.keys(b);
  for (const k of keysA) {
    if (!keysB.includes(k) || !objectsEqual(a[k], b[k])) {
      return false;
    }
  }
  for (const k of keysB) {
    if (!keysA.includes(k) || !objectsEqual(a[k], b[k])) {
      return false;
    }
  }
  return true;
};

export default objectsEqual;
