






























































































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import TextSingle from "@/components/shared/input/TextSingle.vue";
import TextMulti from "@/components/shared/input/TextMulti.vue";
import Dropdown from "@/components/shared/input/Dropdown.vue";
import {
  AgreementRouteStandardPhraseDto,
  ApiConfiguration,
  CargoTypeAndRouteInfoDto,
  RepositoryDto,
  StandardPhrasesClient,
  StandardPhraseType
} from "@/scripts/cld.api";
import { t } from "@/scripts/language/i18n";
import { actions, gets } from "@/scripts/store/constants";
import MomentX from "@/scripts/misc/momentX";

export default Vue.extend({
  components: {
    DialogCard,
    TextSingle,
    TextMulti,
    Dropdown
  },
  props: {
    standardPhraseDetails: AgreementRouteStandardPhraseDto
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.standardPhraseDetailsOriginal = JSON.stringify(
            this.standardPhraseDetails
          );
        }
      },
      immediate: true
    }
  },
  computed: {
    title(): string | undefined {
      if (!this.standardPhraseDetails) {
        return "";
      }
      if (this.standardPhraseDetails.id === 0) {
        return t("NewPhrase");
      }
      return this.standardPhraseDetails.subject;
    },
    standardPhraseCategories(): RepositoryDto[] {
      return [
        new RepositoryDto({
          id: StandardPhraseType.DestinationInfo,
          text: t("DestinationInformation")
        }),
        new RepositoryDto({
          id: StandardPhraseType.PickupInfo,
          text: t("PickupInformation")
        }),
        new RepositoryDto({
          id: StandardPhraseType.SpecialRequirement,
          text: t("SpecialRequirements")
        })
      ];
    },
    dialog(): boolean {
      return this.standardPhraseDetails !== undefined;
    },
    pendingChanges(): boolean {
      return (
        this.standardPhraseDetailsOriginal !==
        JSON.stringify(this.standardPhraseDetails)
      );
    },
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 143);
    },
    displayedInfo(): CargoTypeAndRouteInfoDto[] {
      this.setDisclaimerText();
      switch (this.standardPhraseDetails.phraseType) {
        case StandardPhraseType.DestinationInfo:
          return this.standardPhraseDetails.agreementRoutesDeliveryInfos!;
        case StandardPhraseType.PickupInfo:
          return this.standardPhraseDetails.agreementRoutesPickupInfos!;
        case StandardPhraseType.SpecialRequirement:
          return this.standardPhraseDetails.agreementRoutesSpecialRequirements!;
        default:
          return [];
      }
    },
    label() {
      return `${t("InternalName")} (${t("NotVisibleOnLoad").toLowerCase()})`;
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    save() {
      this.saveInProgress = true;
      const saveDto = new AgreementRouteStandardPhraseDto({
        id: this.standardPhraseDetails.id,
        subject: this.standardPhraseDetails.subject,
        body: this.standardPhraseDetails.body,
        usageCount: 0,
        regDate: new MomentX(),
        phraseType: this.standardPhraseDetails.phraseType,
        userDisplayName: this.standardPhraseDetails.userDisplayName,
        agreementRoutesDeliveryInfos: this.standardPhraseDetails
          .agreementRoutesDeliveryInfos,
        agreementRoutesPickupInfos: this.standardPhraseDetails
          .agreementRoutesPickupInfos,
        agreementRoutesSpecialRequirements: this.standardPhraseDetails
          .agreementRoutesSpecialRequirements
      });
      new StandardPhrasesClient(new ApiConfiguration(this.$store))
        .saveAgreementRouteStandardPhrase(saveDto)
        .then(() => {
          this.saveInProgress = false;
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    setDisclaimerText() {
      switch (this.standardPhraseDetails.phraseType) {
        case StandardPhraseType.DestinationInfo:
          if (this.standardPhraseDetails.id === 0) {
            this.disclaimerInfo = t("NewStandardPhraseDisclaimer");
          } else {
            this.disclaimerInfo = t("StandardPhraseDisclaimer");
          }
          break;
        case StandardPhraseType.PickupInfo:
          if (this.standardPhraseDetails.id === 0) {
            this.disclaimerInfo = t("NewStandardPhraseDisclaimer");
          } else {
            this.disclaimerInfo = t("StandardPhraseDisclaimer");
          }
          break;
        case StandardPhraseType.SpecialRequirement:
          if (this.standardPhraseDetails.id === 0) {
            this.disclaimerInfo = t("NewSpecialRequirementDisclaimer");
          } else {
            this.disclaimerInfo = t("SpecialRequirementDisclaimer");
          }
          break;
        default:
          this.disclaimerInfo = ""; // Ska inte hända
          break;
      }
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    standardPhraseDetailsOriginal: string;
    disclaimerInfo: string;
  } => ({
    valid: false,
    saveInProgress: false,
    standardPhraseDetailsOriginal: "",
    disclaimerInfo: ""
  })
});
