



































































































import Vue from "vue";
import {
  ActorType,
  LoadActionPermission,
  LoadingSlotListDto
} from "@/scripts/cld.api";
import { TableHeader, TableOptions } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import EllipsisTableItem from "@/components/shared/table/EllipsisTableItem.vue";
import SlotDots from "@/components/web/slots/SlotDots.vue";
import LoadDialogs from "@/components/web/loads/shared/LoadDialogs.vue";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { gets } from "@/scripts/store/constants";

export default Vue.extend({
  props: {
    loading: Boolean,
    slots: Array as () => LoadingSlotListDto[]
  },
  components: {
    CustomTableHeader,
    EllipsisTableItem,
    SlotDots,
    LoadDialogs
  },
  computed: {
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "dots",
          text: ""
        },
        {
          value: "loadId",
          text: "LoadID",
          sortValue: true
        },
        {
          value: "",
          text: "Booked"
        },
        {
          value: "loadingDockName",
          text: "LoadingDock"
        },
        {
          value: "ton",
          text: "Ton"
        },
        {
          value: "minutes",
          text: "Minutes"
        },
        {
          value: "startDate",
          text: "FromTime",
          sortValue: true
        },
        {
          value: "endDate",
          text: "ToTime",
          sortValue: true
        },
        {
          value: "note",
          text: "Note",
          sortValue: true
        },
        {
          value: "deliveryLatest",
          text: "DeliveryLatest",
          sortValue: true
        },
        {
          value: "route",
          text: "Route",
          sortValue: true
        },
        {
          value: "truckNo",
          text: "TruckNo",
          sortValue: true
        },
        {
          value: "bookingNo",
          text: "BookingNo",
          sortValue: true
        },
        {
          value: "transportNo",
          text: "TransportNo",
          sortValue: true
        },
        {
          value: this.isSupplier ? "clientName" : "supplierName",
          text: this.isSupplier ? "Client" : "Supplier",
          sortValue: true
        }
      ];
      return headers;
    },
    actorType(): ActorType {
      return this.$store.getters[gets.userInfo].actorType;
    },
    isSupplier(): boolean {
      return this.actorType === ActorType.Supplier;
    },
    isClient(): boolean {
      return this.actorType === ActorType.Client;
    },
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 150);
    }
  },
  methods: {
    customGroup(
      slots: LoadingSlotListDto[]
    ): { name: string; sortVal: string; items: LoadingSlotListDto[] }[] {
      let res: {
        name: string;
        sortVal: string;
        items: LoadingSlotListDto[];
      }[] = [];
      for (let slot of slots) {
        const matchingGroup = res.find(x => x.name === slot.loadId.toString());
        if (matchingGroup) {
          matchingGroup.items.push(slot);
        } else {
          res.push({
            name: slot.loadId.toString(),
            sortVal: this.sortValue(slot),
            items: [slot]
          });
        }
      }
      res.sort((a, b) => {
        return stringCompare(a.sortVal, b.sortVal);
      });
      if (this.tableOptions.sortDesc[0]) {
        res.reverse();
      }
      return res;
    },
    sortValue(slot: LoadingSlotListDto): string {
      const sortBy = this.tableOptions.sortBy[0].toString();
      if (sortBy === "route") {
        return this.routeName(slot);
      }
      if (sortBy === "startDate" || sortBy === "endDate") {
        const date = slot[sortBy];
        return date ? date.unix().toString() : "inte en siffra";
      }
      return (slot as any)[sortBy] ? (slot as any)[sortBy].toString() : "";
    },
    routeName(slot: LoadingSlotListDto): string {
      return slot.pickupLocation + " - " + slot.deliveryLocation;
    },
    openSlotTimeCalender(loadId: number) {
      this.$router.push({
        path: this.$route.path,
        query: {
          action: LoadActionPermission.LoadingSlotEdit.toString(),
          ids: "[" + loadId.toString() + "]"
        }
      });
    },
    convertToTon(kg: number) {
      return kg / 1000;
    },
    search() {
      this.$emit("search");
    }
  },
  mounted() {
    this.$nextTick(function() {
      this.intervalId = setInterval(() => {
        this.search();
      }, 60000);
    });
  },
  destroyed() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  },
  data: (): { intervalId?: number; tableOptions: TableOptions } => ({
    intervalId: undefined,
    tableOptions: new TableOptions({
      itemsPerPage: -1,
      sortBy: ["startDate"],
      sortDesc: [false],
      groupBy: ["oviktigt"]
    })
  })
});
