



































import Vue from "vue";
import DialogCard from "@/components/shared/ui/DialogCard.vue";
import NotificationSettings from "@/components/web/settings/NotificationSettings.vue";
import {
  ApiConfiguration,
  NotificationSettingsDto,
  UsersClient
} from "@/scripts/cld.api";
import { actions } from "@/scripts/store/constants";
import { pushNotification } from "@/scripts/misc/notifications";

export default Vue.extend({
  components: {
    DialogCard,
    NotificationSettings
  },
  props: {
    dialog: Boolean,
    loadUserId: Number
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.saveInProgress = false;
          this.notificationSettings = undefined;
          this.fetchNotificationSettings();
        }
      },
      immediate: true
    }
  },
  computed: {
    pendingChanges(): boolean {
      return (
        this.originalNotificationSettings !==
        JSON.stringify(this.notificationSettings)
      );
    }
  },
  methods: {
    fetchNotificationSettings() {
      new UsersClient(new ApiConfiguration(this.$store))
        .notificationSettings(this.loadUserId)
        .then(res => {
          this.notificationSettings = res;
          this.originalNotificationSettings = JSON.stringify(
            this.notificationSettings
          );
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    save() {
      this.saveInProgress = true;
      new UsersClient(new ApiConfiguration(this.$store))
        .saveNotificationSettings(this.notificationSettings!)
        .then(() => {
          pushNotification("Notifieringar har sparats!", "mdi-check-circle");
          this.close();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    },
    close() {
      this.$emit("close");
    }
  },
  data: (): {
    valid: boolean;
    saveInProgress: boolean;
    notificationSettings: NotificationSettingsDto | undefined;
    originalNotificationSettings: string;
  } => ({
    valid: false,
    saveInProgress: false,
    notificationSettings: undefined,
    originalNotificationSettings: ""
  })
});
