var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"tiniest-padding",attrs:{"rounded":"","elevation":"2"}},[_c('v-data-table',{attrs:{"item-key":"routeId","headers":_vm.headers,"items":_vm.routesContainer.rows,"loading":_vm.loading,"height":_vm.tableHeight,"mobile-breakpoint":0,"server-items-length":_vm.routesContainer.count,"options":_vm.options,"footer-props":{
      'items-per-page-text': _vm.$t('Quantity') + ':',
      'items-per-page-all-text': _vm.$t('MobileAll'),
      'items-per-page-options': [200],
      'page-text': _vm.$t('ShowingItemsXofY')
    },"item-class":function (r) { return _vm.itemClass(r); },"hide-default-header":"","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
    var props = ref.props;
    var on = ref.on;
return [_c('CustomTableHeader',{attrs:{"props":props,"on":on},scopedSlots:_vm._u([{key:"filter.action",fn:function(){return [_c('div',{staticClass:"medium-padding-top"},[_c('CheckboxTiny',{attrs:{"value":_vm.allToggled},on:{"input":_vm.toggleAll}})],1)]},proxy:true},{key:"filter.hidden",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'yesNo'
            },"placeholder":_vm.$t('Hidden')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.hidden),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "hidden", $$v)},expression:"searchCriteria.hidden"}})]},proxy:true},{key:"filter.pickupLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location',
              pickup: true
            },"placeholder":_vm.$t('PickupLocation'),"value":{
              county: _vm.searchCriteria.pickupCounty,
              zipCode: _vm.searchCriteria.pickupZipCode,
              countryId: _vm.searchCriteria.pickupCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.pickupCounty = val.county;
                _vm.searchCriteria.pickupZipCode = val.zipCode;
                _vm.searchCriteria.pickupCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.deliveryLocation",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'location',
              pickup: false
            },"placeholder":_vm.$t('DeliveryLocation'),"value":{
              county: _vm.searchCriteria.deliveryCounty,
              zipCode: _vm.searchCriteria.deliveryZipCode,
              countryId: _vm.searchCriteria.deliveryCountry
            }},on:{"input":function (val) {
                _vm.searchCriteria.deliveryCounty = val.county;
                _vm.searchCriteria.deliveryZipCode = val.zipCode;
                _vm.searchCriteria.deliveryCountry = val.countryId;
              },"search":_vm.search}})]},proxy:true},{key:"filter.clientName",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'client',
              includeInactive: true
            },"placeholder":_vm.$t('Client')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.clientId),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "clientId", $$v)},expression:"searchCriteria.clientId"}})]},proxy:true},{key:"filter.cargoType",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'cargoType'
            },"placeholder":_vm.$t('CargoType')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.cargoType),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "cargoType", $$v)},expression:"searchCriteria.cargoType"}})]},proxy:true},{key:"filter.distance",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'withWithout'
            },"placeholder":_vm.$t('Distance')},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.hasDistance),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "hasDistance", $$v)},expression:"searchCriteria.hasDistance"}})]},proxy:true},{key:"filter.routingExactnessPickup",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'routingExactness'
            },"placeholder":"Exakthet"},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.routingExactnessPickup),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "routingExactnessPickup", $$v)},expression:"searchCriteria.routingExactnessPickup"}})]},proxy:true},{key:"filter.routingExactnessDelivery",fn:function(){return [_c('CustomFilter',{attrs:{"config":{
              subtype: 'routingExactness'
            },"placeholder":"Exakthet"},on:{"search":_vm.search},model:{value:(_vm.searchCriteria.routingExactnessDelivery),callback:function ($$v) {_vm.$set(_vm.searchCriteria, "routingExactnessDelivery", $$v)},expression:"searchCriteria.routingExactnessDelivery"}})]},proxy:true}],null,true)})]}},{key:"item.action",fn:function(ref){
            var item = ref.item;
return [_c('CheckboxTiny',{attrs:{"value":_vm.toggled.includes(item.id)},on:{"input":function($event){return _vm.toggle(item.id)}}})]}},{key:"item.hidden",fn:function(ref){
            var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.hidden ? 'error' : 'success'}},[_vm._v(_vm._s(item.hidden ? "mdi-eye-off" : "mdi-eye"))])]}},{key:"item.pickupLocation",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.locationName( item.pickupCounty, item.pickupZipCode, item.pickupCountry ))+" ")])]}},{key:"item.deliveryLocation",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.locationName( item.deliveryCounty, item.deliveryZipCode, item.deliveryCountry ))+" ")])]}},{key:"item.clientName",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(item.clientName)+" ")])]}},{key:"item.cargoType",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.cargoTypeName(item.cargoType))+" ")])]}},{key:"item.distance",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(item.distance, 0))+" ")])]}},{key:"item.oldDistance",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.money(_vm.oldDistance(item.id), 0))+" ")])]}},{key:"item.routingExactnessPickup",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.routingExactnessName(item.routingExactnessPickup))+" ")])]}},{key:"item.routingExactnessDelivery",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"dont-render-newlines"},[_vm._v(" "+_vm._s(_vm.routingExactnessName(item.routingExactnessDelivery))+" ")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }