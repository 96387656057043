




import Vue from "vue";
import { Chart, ChartData, ChartItem, registerables } from "chart.js";
Chart.register(...registerables);

export default Vue.extend({
  props: {
    chartData: Object as () => ChartData<"pie", number[], string>,
    title: String
  },
  watch: {
    chartData() {
      this.key++;
      this.$nextTick(() => {
        this.render();
      });
    }
  },
  methods: {
    render() {
      const ctx: ChartItem = document.getElementById("myChart") as any;
      this.chartData.datasets.map(d => {
        (d.backgroundColor = [
          "#c62107",
          "#4d4d4d",
          "#dfd5bb",
          "#65c8e6",
          "#ffa500"
        ]),
          (d.borderColor = [
            "#c62107",
            "#4d4d4d",
            "#dfd5bb",
            "#65c8e6",
            "#ffa500"
          ]),
          (d.borderWidth = 1);
      });
      new Chart(ctx, {
        type: "pie",
        data: this.chartData,
        options: {
          plugins: {
            title: {
              text: this.title,
              display: true
            }
          },
          maintainAspectRatio: false
        }
      });
    }
  },
  data: (): { key: number } => ({ key: 1 })
});
