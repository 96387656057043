
















































































































import Vue from "vue";
import {
  ApiConfiguration,
  PublishRuleDto,
  ActorsClient,
  PublishRuleType,
  PublishRuleRowDto
} from "@/scripts/cld.api";
import { actions, gets } from "@/scripts/store/constants";
import SearchCard from "@/components/shared/ui/SearchCard.vue";
import { publishRuleTypes } from "@/scripts/misc/enumLists";
import SkeletonLoader from "@/components/shared/ui/SkeletonLoader.vue";
import WorkingHoursEditDialog from "@/components/web/settings/WorkingHoursEditDialog.vue";
import { t } from "@/scripts/language/i18n";

export default Vue.extend({
  components: {
    SearchCard,
    SkeletonLoader,
    WorkingHoursEditDialog
  },
  computed: {
    tableHeight(): number {
      return Math.max(400, this.$store.getters[gets.dimensions].height - 198);
    },
    changedValues(): boolean {
      return this.publishRules.some(
        (r, index) =>
          this.originalRulesAsJSON[index] === undefined ||
          JSON.stringify(r) !== this.originalRulesAsJSON[index]
      );
    },
    newRows(): number[] {
      return this.publishRules
        .map((r, index) => (this.originalRules.includes(r) ? -1 : index))
        .filter(x => x !== -1);
    },
    pendingChanges(): boolean {
      return this.changedValues || this.newRows.length > 0;
    },
    actorId(): number {
      return this.$store.getters[gets.userInfo].actorId;
    }
  },
  methods: {
    publishRuleTypes: publishRuleTypes,
    add(index: number) {
      this.publishRules.splice(
        index + 1,
        0,
        new PublishRuleDto({
          removable: true,
          publishRuleRows: publishRuleTypes().map(
            x =>
              new PublishRuleRowDto({
                publishRuleType: x.id,
                delay: this.publishRules[index].publishRuleRows.find(
                  r => r.publishRuleType === x.id
                )!.delay
              })
          )
        })
      );
    },
    remove(index: number) {
      this.publishRules.splice(index, 1);
    },
    isValid(index: number, type: PublishRuleType): boolean | string {
      const delay = Number(
        this.publishRules[index].publishRuleRows.find(
          x => x.publishRuleType === type
        )!.delay
      );
      if (index === 0) {
        return delay >= 0 || t("MustBeAtLeastX").replace("{0}", "0");
      }
      const delayPrevLevel = Number(
        this.publishRules[index - 1].publishRuleRows.find(
          x => x.publishRuleType === type
        )!.delay
      );
      if (delay >= delayPrevLevel) {
        return true;
      }
      return t("MustBeAtLeastX").replace("{0}", delayPrevLevel.toString());
    },
    validate() {
      this.$nextTick(() => {
        let form: any = this.$refs.form;
        form.validate();
      });
    },
    roundValues() {
      this.publishRules.map(r =>
        r.publishRuleRows.map(x => (x.delay = Math.round(x.delay * 4) / 4))
      );
    },
    save() {
      this.saveInProgress = true;
      this.roundValues();
      new ActorsClient(new ApiConfiguration(this.$store))
        .savePublishRules(this.publishRules)
        .then(() => {
          this.saveInProgress = false;
          this.fetch();
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
          this.loading = false;
        });
    },
    fetch() {
      this.loading = true;
      new ActorsClient(new ApiConfiguration(this.$store))
        .publishRules(this.actorId)
        .then(res => {
          this.publishRules = res;
          this.minNumRules = Math.max(3, res.filter(r => !r.removable).length);
          this.originalRules = [...this.publishRules];
          this.originalRulesAsJSON = this.originalRules.map(r =>
            JSON.stringify(r)
          );
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch(actions.handleApiError, error);
        });
    }
  },
  mounted() {
    this.fetch();
  },
  data: (): {
    loading: boolean;
    saveInProgress: boolean;
    valid: boolean;
    dialog: boolean;
    minNumRules: number;
    publishRules: PublishRuleDto[];
    originalRules: PublishRuleDto[];
    originalRulesAsJSON: string[];
  } => ({
    loading: true,
    saveInProgress: false,
    valid: false,
    dialog: false,
    minNumRules: 0,
    publishRules: [],
    originalRules: [],
    originalRulesAsJSON: []
  })
});
