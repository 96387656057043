





































































































import Vue from "vue";
import { RelatedLoadSummaryDto } from "@/scripts/cld.api";
import { TableHeader } from "@/scripts/types";
import CustomTableHeader from "@/components/shared/table/CustomTableHeader.vue";
import MomentX from "@/scripts/misc/momentX";
import { stringCompare } from "@/scripts/misc/stringUtils";
import { loadStatusName, truckTypeName } from "@/scripts/misc/enumNames";

export default Vue.extend({
  components: {
    CustomTableHeader
  },
  props: {
    items: Array as () => RelatedLoadSummaryDto[],
    editable: Boolean,
    shortVersion: Boolean
  },
  methods: {
    truckTypeName: truckTypeName,
    loadStatusName: loadStatusName,
    customGroup(
      items: RelatedLoadSummaryDto[]
    ): { name: string; sortVal: string; items: RelatedLoadSummaryDto[] }[] {
      let res: {
        name: string;
        sortVal: string;
        items: RelatedLoadSummaryDto[];
      }[] = [];
      for (let ar of items) {
        const groupName =
          this.$t("PickupLocation") +
          ": " +
          ar.pickupLocation! +
          " | " +
          this.$t("DeliveryLocation") +
          ": " +
          ar.deliveryLocation +
          " | " +
          this.$t("DeliveryAddress") +
          ": " +
          ar.deliveryAddress;

        const matchingGroup = res.find(x => x.name === groupName);
        if (matchingGroup) {
          matchingGroup.items.push(ar);
        } else {
          res.push({
            name: groupName,
            sortVal: groupName,
            items: [ar]
          });
        }
      }
      res.sort((a, b) => {
        return stringCompare(a.sortVal, b.sortVal);
      });
      return res;
    },
    presentDate(d?: MomentX): string {
      return d === undefined ? "" : d.shortDateTimePrint();
    },
    dateStyling(dateName: string, item: RelatedLoadSummaryDto) {
      if (dateName === "loadedDate" && item.loadedDate) {
        return "bold-date";
      }
      if (
        dateName === "bookedPickupDate" &&
        !item.loadedDate &&
        item.bookedPickupDate
      ) {
        return "bold-date";
      }

      if (
        dateName === "pickupLatest" &&
        !item.loadedDate &&
        !item.bookedPickupDate &&
        item.pickupLatest
      ) {
        return "bold-date";
      }
      if (
        dateName === "pickupEarliest" &&
        !item.loadedDate &&
        !item.bookedPickupDate &&
        !item.pickupLatest &&
        item.pickupEarliest
      ) {
        return "bold-date";
      }

      if (dateName === "deliveryDate" && item.deliveryDate) {
        return "bold-date";
      }
      if (
        dateName === "bookedDeliveryDate" &&
        !item.deliveryDate &&
        item.bookedDeliveryDate
      ) {
        return "bold-date";
      }

      if (
        dateName === "deliveryLatest" &&
        !item.deliveryDate &&
        !item.bookedDeliveryDate &&
        item.deliveryLatest
      ) {
        return "bold-date";
      }

      return "";
    },
    remove(item: RelatedLoadSummaryDto) {
      this.$emit("remove", item);
    }
  },
  computed: {
    headers(): TableHeader[] {
      let headers: TableHeader[] = [
        {
          value: "loadId",
          text: "LoadID"
        },
        {
          value: "transportNo",
          text: "TransportNo"
        },
        {
          value: "regNo",
          text: "TruckNo"
        },
        {
          value: "loadStatus",
          text: "Status"
        },
        {
          value: "loadedDate",
          text: "Loaded"
        },
        {
          value: "bookedPickupDate",
          text: "BookedPickupDate"
        },
        {
          value: "pickupLatest",
          text: "PickupLatest"
        },
        {
          value: "pickupEarliest",
          text: "PickupEarliest"
        },
        {
          value: "deliveryDate",
          text: "Delivered"
        },
        {
          value: "bookedDeliveryDate",
          text: "BookedDeliveryDate"
        },
        {
          value: "deliveryLatest",
          text: "DeliveryLatest"
        },
        {
          value: "truckType",
          text: "TruckType"
        },
        {
          value: "supplierName",
          text: "Supplier"
        },
        {
          value: "clientName",
          text: "Client"
        },
        {
          value: "remove"
        }
      ];
      return headers;
    }
  }
});
